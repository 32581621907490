import { ActionResponse } from 'api/types/actionResponse'
import axios, { AxiosPromise } from 'axios'

export type EditOrganisationPayload = {
  code: string
  name: string
  description: string
  externalRef?: {
    origin: string
    reference: string
  }
}

export type OrganisationData = {
  id: string
  createdDate: number
  updatedDate: number
  code: string
  name: string
  description: string
  externalRef?: {
    origin: string
    reference: string
  }
}

const organisations = {
  get: () => axios.get<OrganisationData[]>('/api/organisations'),

  add: (
    details: EditOrganisationPayload,
  ): AxiosPromise<ActionResponse | { id: string }> =>
    axios.post('/api/organisations', details),

  edit: (params: {
    orgId: string
    details: EditOrganisationPayload
  }): AxiosPromise<ActionResponse> => {
    const { orgId, details } = params

    return axios.put(`/api/organisations/${orgId}`, details)
  },
  delete: (orgId: string): AxiosPromise<ActionResponse> =>
    axios.delete(`/api/organisations/${orgId}`),
}

export default organisations
