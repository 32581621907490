import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { EntitySkeleton } from 'components/EntitySkeleton'
import { OrganisationTab } from 'view/Organisations/type'

export const OrganisationSkeleton = (props: { tab: OrganisationTab }) => {
  const variant = props.tab === 'details' ? 'details' : 'table'
  const { hasSomePermissions } = usePermissions()
  const hasActions = hasSomePermissions(
    IDAVE_PERMISSIONS.ORGANISATIONS_UPDATE,
    IDAVE_PERMISSIONS.ORGANISATIONS_DELETE,
  )
  return <EntitySkeleton variant={variant} hasActions={hasActions} />
}
