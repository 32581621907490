import { TableColumn, Table } from '@revolut/ui-kit'
import { ResourceLink } from 'components/Links/ResourceLink'
import { Resource } from './types'

export const COLUMNS: TableColumn<Resource>[] = [
  {
    Header: 'Name',
    accessor: 'resourceName',
    Cell: (params: { row: { original: Resource } }) => (
      <Table.Cell>
        {params.row.original.resourceType !== 'UNKNOWN' ? (
          <ResourceLink resource={params.row.original} />
        ) : (
          params.row.original.resourceName
        )}
      </Table.Cell>
    ),
  },
  {
    Header: 'Type',
    accessor: 'resourceType',
  },
]
