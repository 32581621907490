import { Tab as FormTab } from 'components/FormMultiStep'
import { Tab } from './types'

export const TABS: FormTab<Tab>[] = [
  {
    value: 'details',
    label: 'Details',
  },
  {
    value: 'users',
    label: 'Users',
  },
]
