import { SAMPolicyCreateLog } from 'api/sam/auditLogs'
import { DetailsGroup, vstackItems } from 'components/DetailsGroup'
import { SENIORITY } from 'constants/seniority'
import { capitalizeFirst } from 'utils/string'
import { stringifySubjectType } from 'view/Sam/utils'
import { Box, Subheader, Text } from '@revolut/ui-kit'
import { RevolutersSpecialisationLink } from 'components/Links/RevolutersSpecialisationLink'
import { RevolutersTeamLink } from 'components/Links/RevolutersTeamLink'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { joinSingleAndListValues } from './utils'
import { SamPolicyLogResourcesTable } from '../components/SamPolicyLogResourcesTable'
import { SamPolicyLogTrainingsTable } from '../components/SamPolicyLogTrainingsTable'

export const SamPolicyCreateSideLog = ({ log }: { log: SAMPolicyCreateLog }) => {
  const seniorities = joinSingleAndListValues(
    log.data.subject_seniority_id,
    log.data.subject_seniority_ids,
  ).map((id) => SENIORITY[id] || id)

  const employeeTypes = joinSingleAndListValues(
    log.data.subject_employee_type,
    log.data.subject_employee_types,
  )

  const employees = joinSingleAndListValues(
    log.data.subject_employee_id,
    log.data.subject_employee_ids,
  )

  const specs = joinSingleAndListValues(
    log.data.subject_specialisation_id,
    log.data.subject_specialisation_ids,
  )

  const teams = joinSingleAndListValues(
    log.data.subject_team_id,
    log.data.subject_team_ids,
  )

  const resourceIds = (log.data.resource_id || '').split(',').filter((i) => !!i)

  return (
    <>
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Policy</Subheader.Title>
        </Subheader>
        <DetailsGroup
          rows={[
            ['Policy name', log.data.policy_name],
            ['Business reason', log.data.business_reason || EMPTY_VALUE_FALLBACK],
            ['Subject type', stringifySubjectType(log.data.subject_type)],

            vstackItems('Employee types', employeeTypes, (type) => (
              <Text key={type}>{capitalizeFirst(type)}</Text>
            )),

            vstackItems('Employees', employees, (id) => <Text key={id}>{id}</Text>),

            vstackItems('Seniorities', seniorities, (seniority) => (
              <Text key={seniority}>{seniority}</Text>
            )),

            vstackItems('Specialisations', specs, (spec) => (
              <RevolutersSpecialisationLink id={spec} key={spec} />
            )),

            vstackItems('Teams', teams, (teamId) => (
              <RevolutersTeamLink id={teamId} key={teamId} />
            )),
          ]}
        />
      </Box>
      <SamPolicyLogResourcesTable resourceIds={resourceIds} title="Resources" />
      <SamPolicyLogTrainingsTable
        trainingIds={log.data.mandatory_trainings}
        title="Mandatory trainings"
      />
    </>
  )
}
