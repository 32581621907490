import {
  Avatar,
  Box,
  HStack,
  Item,
  Text,
  Token,
  Tooltip,
  VStack,
  Widget,
  abbreviate,
  useTooltip,
} from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import { capitalizeFirst } from 'utils/string'
import { formatDateTime } from 'utils/date'
import { useUserStateTooltip } from 'hooks/useUserStateTooltip'
import { UnstyledLink } from 'components/Links/UnstyledLink'
import { getRevolutersTeamLink } from 'components/Links/RevolutersTeamLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { ReviewerItem, ReviewerStatus } from '../types'
import { getStatusBadge } from '../utils'

export const CrossCheckReviewGroupItem = (props: {
  reviewer: ReviewerItem
  status: ReviewerStatus
}) => {
  const { reviewer, status } = props

  const { anchorProps, avatarProps, titleProps, tooltip } = useUserStateTooltip(
    reviewer.state,
  )

  const avatarBadge = getStatusBadge(status)
  const teamLink = reviewer.teamId ? getRevolutersTeamLink(reviewer.teamId) : undefined
  const reviewerLink = reviewer.userId
    ? generatePath(Url.UserProfile, { userId: reviewer.userId })
    : undefined

  return (
    <Widget>
      <Item use={Box} {...anchorProps}>
        <Item.Avatar>
          <UnstyledLink href={reviewerLink}>
            <Avatar
              image={reviewer.photoUrl}
              label={abbreviate(reviewer.name)}
              uuid={reviewer.reviewerId}
              {...avatarProps}
            >
              {avatarBadge ? (
                <Avatar.Badge
                  bg={avatarBadge.color}
                  position="bottom-right"
                  useIcon={avatarBadge.icon}
                />
              ) : null}
            </Avatar>
          </UnstyledLink>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            <UnstyledLink href={reviewerLink}>
              <Text {...titleProps}>{reviewer.name}</Text>
            </UnstyledLink>
          </Item.Title>
          <Item.Description>
            <VStack>
              <AssignmentInfo
                assignmentByText={reviewer.assignedBy}
                assignmentComment={reviewer.assignmentComment}
              />
              {reviewer.teamName ? (
                <UnstyledLink href={teamLink} target="_blank">
                  {reviewer.teamName}
                </UnstyledLink>
              ) : null}
              {reviewer.reviewComment ? (
                <Text color={Token.color.foreground}>{reviewer.reviewComment}</Text>
              ) : null}
            </VStack>
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <VStack height="100%">
            {status !== 'pending' ? (
              <Text color={avatarBadge?.color}>{capitalizeFirst(status)}</Text>
            ) : null}
            {reviewer.reviewTS ? (
              <Text color={Token.color.greyTone50} variant="caption">
                {formatDateTime(reviewer.reviewTS)}
              </Text>
            ) : null}
          </VStack>
        </Item.Side>
      </Item>
      {tooltip}
    </Widget>
  )
}

const AssignmentInfo = (props: {
  assignmentByText?: string
  assignmentComment?: string
}) => {
  const { assignmentByText, assignmentComment } = props
  const tooltip = useTooltip()
  if (!assignmentByText && !assignmentComment) {
    return null
  }

  return (
    <>
      <HStack space="s-4" align="center">
        <Text>{assignmentByText || 'Assigned by UNKNOWN'}</Text>
        {assignmentComment ? (
          <>
            <InfoOutline
              size={20}
              color={Token.color.grey20}
              {...tooltip.getAnchorProps()}
            />
            <Tooltip {...tooltip.getTargetProps()} m="s-12" maxWidth="400px">
              <VStack>
                <Text color={Token.color.grey50}>Justification</Text>
                <Text>{assignmentComment}</Text>
              </VStack>
            </Tooltip>
          </>
        ) : null}
      </HStack>
    </>
  )
}
