import { Box, Button, Input, TextArea } from '@revolut/ui-kit'
import { useToasts } from 'react-toast-notifications'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import samApi from 'api/sam'
import { SamTaskPayload } from 'api/sam/tasks'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

type FormState = { taskName: string; args: string }

export const SamTasks = () => {
  const { addToast } = useToasts()
  const { register, handleSubmit } = useForm<FormState>()

  const { mutate: sendTask, isLoading } = useMutation({
    mutationFn: (payload: SamTaskPayload) => {
      return samApi.tasks.sendTask(payload)
    },
    onSuccess: () => {
      addToast('Schedule Task: Success', { appearance: 'success', autoDismiss: true })
    },
  })

  const onSubmit = (data: FormState) => {
    sendTask({
      taskName: data.taskName,
      args: JSON.parse(`[${data.args}]`),
    })
  }

  return (
    <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb="s-16">
          <Input {...register('taskName')} label="Task name" />
        </Box>

        <Box mb="s-16">
          <TextArea {...register('args')} label="Args" />
        </Box>

        <Box mb="s-16">
          <Button elevated pending={isLoading} type="submit">
            Send
          </Button>
        </Box>
      </form>
    </Box>
  )
}
