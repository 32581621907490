import { useCallback, useEffect, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { AxiosError } from 'axios'
import { pickBy } from 'lodash'
import querystring from 'qs'
import { CredentialResponse } from 'google-one-tap'
import GoogleAuth from 'services/GoogleAuth'
import { Button, VStack, useTheme, Separator } from '@revolut/ui-kit'
import idaveApi from 'api/idave'
import { AuthErrorCode, Credentials } from 'api/idave/auth'
import { getUrlParams, confirmSignIn } from 'services/auth'
import { isDarkMode } from 'utils/common/isDarkMode'
import { CredentialsForm } from './CredentialsForm'
import { OtpForm } from './OtpForm'

const requestSignIn = async (payload: Credentials) => {
  const p = getUrlParams()

  const _params = querystring.stringify(
    pickBy(
      {
        response_type: p.response_type,
        client_id: p.client_id,
        client_name: p.client_name,
        redirect_uri: p.redirect_uri,
      },
      (e) => e !== undefined,
    ),
  )

  const params = querystring.stringify({
    redirect_uri: `${window.location.origin}/api/auth?${_params}`,
  })

  const response = await idaveApi.auth.submitCredentials(payload, params)
  // Some errors are suppressed in a `configAxios` middleware
  if (response.status >= 200 && response.status < 300) {
    confirmSignIn()
  }
}

export const IdaveLogin = () => {
  const { addToast } = useToasts()
  const [passwordEnabled, setPasswordEnabled] = useState(false)
  const [required2FA, setRequired2FA] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const { mode } = useTheme()

  const onGoogleSignInSuccess = useCallback(async (res: CredentialResponse) => {
    const idToken = res.credential
    try {
      await idaveApi.auth.submitGoogleToken(idToken)
      confirmSignIn()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const googleSignInButtonContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    GoogleAuth.init(onGoogleSignInSuccess)
    if (googleSignInButtonContainerRef.current) {
      GoogleAuth.renderSignInButton(
        googleSignInButtonContainerRef.current,
        isDarkMode(mode),
      )
    }
  }, [onGoogleSignInSuccess, mode])

  const onSubmitCredentials = async (payload: Credentials) => {
    setIsFetching(true)
    try {
      await requestSignIn(payload)
    } catch (e) {
      const error = e as AxiosError<{ code?: number; message?: string }>
      const errorCode = error.response?.data.code
      if (errorCode === AuthErrorCode.OTP_REQUIRED) {
        setRequired2FA(true)
      } else {
        const message =
          error.response?.data.message ?? 'Error while validating credentials'
        addToast(message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      setIsFetching(false)
    }
  }

  const onSubmitOtp = async (code: string) => {
    setIsFetching(true)
    try {
      const response = await idaveApi.auth.submitOtp(code)
      window.location.href = response.data.redirectUri
    } catch (e) {
      const error = e as AxiosError<{ message?: string }>
      const message = error.response?.data.message ?? 'Error while validating OTP code'
      addToast(message, {
        appearance: 'error',
        autoDismiss: true,
      })
      setIsFetching(false)
    }
  }

  return (
    <VStack space="s-24">
      <div ref={googleSignInButtonContainerRef} style={{ colorScheme: 'normal' }} />

      <Separator>Or</Separator>

      {passwordEnabled && !required2FA && (
        <CredentialsForm onSubmit={onSubmitCredentials} isPending={isFetching} />
      )}
      {passwordEnabled && required2FA && (
        <OtpForm onSubmit={onSubmitOtp} isPending={isFetching} />
      )}
      {!passwordEnabled && (
        <Button onClick={() => setPasswordEnabled(true)}>
          Sign in with email and password
        </Button>
      )}
    </VStack>
  )
}
