import axios from 'axios'
import {
  CrossCheck,
  CrossCheckAddReviewerPayload,
  CrossCheckCommentPayload,
  CrossCheckCreatedEvent,
  CrossCheckDeclinePayload,
  CrossCheckDetail,
  CrossCheckGroup,
  CrossCheckReviewPayload,
  CrossJustifyPayload,
} from 'api/types/crossCheck'

export type SamCrossChecks = {
  data: CrossCheck[]
}

export const crossChecks = {
  getCrossChecksList: (params?: { reviewer?: string; requester?: string }) =>
    axios.get<SamCrossChecks>('/sam-api/api/cross-checks-v2/', {
      params,
    }),

  getCrossCheckDetails: (id: string) =>
    axios.get<CrossCheckDetail>(`/sam-api/api/cross-checks-v2/${id}`),

  getReviewGroup: (id: string) =>
    axios.get<CrossCheckGroup>(`/sam-api/api/cross-cheks-v2/groups/${id}`),

  commentCrossCheck: (id: string, payload: CrossCheckCommentPayload) =>
    axios.post<CrossCheckCreatedEvent>(
      `/sam-api/api/cross-checks-v2/${id}/comment`,
      payload,
    ),

  reviewCrossCheck: (id: string, payload: CrossCheckReviewPayload) =>
    axios.post<CrossCheckCreatedEvent>(
      `/sam-api/api/cross-checks-v2/${id}/review`,
      payload,
    ),

  justifyCrossCheck: (id: string, payload: CrossJustifyPayload) =>
    axios.post<CrossCheckCreatedEvent>(
      `/sam-api/api/cross-checks-v2/${id}/justify`,
      payload,
    ),

  addReviewer: (id: string, payload: CrossCheckAddReviewerPayload) =>
    axios.post<CrossCheckCreatedEvent>(
      `/sam-api/api/cross-checks-v2/groups/${id}/reviewers`,
      payload,
    ),

  decline: (id: string, payload: CrossCheckDeclinePayload) =>
    axios.post<CrossCheckCreatedEvent>(
      `/sam-api/api/cross-checks-v2/${id}/decline`,
      payload,
    ),

  retry: (id: string) => axios.post<{}>(`/sam-api/api/cross-checks-v2/${id}/retry`),
}
