import { Flex, TableWidget, Text } from '@revolut/ui-kit'
import { getShowedCountLabel } from 'components/EntitiesTable/utils'
import { ResetShrinkButton } from '../ResetShrinkButton'

export const TableStatus = (props: {
  onShrinkReset: () => void
  isResetShrinkVisible: boolean
  count?: number
  pluralForms?: [string, string]
  entitiesTypeLabel: string
}) => {
  const { onShrinkReset, isResetShrinkVisible, count, entitiesTypeLabel, pluralForms } =
    props

  const label = getShowedCountLabel({
    count,
    pluralForms,
    entitiesTypeLabel,
  })

  return (
    <TableWidget.Status>
      <Flex justifyContent="space-between" pr="s-8">
        <Text>{label}</Text>
        <ResetShrinkButton onClick={onShrinkReset} visible={isResetShrinkVisible} />
      </Flex>
    </TableWidget.Status>
  )
}
