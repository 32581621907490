import {
  AuditLogArrayParam,
  AuditLogStringParam,
  AuditLogTimestampParam,
  AuditParams,
  AuditLogEntityParam as TAuditLogEntityParam,
  AuditLogData as TAuditLogData,
} from 'api/types/auditLog'
import axios, { AxiosPromise } from 'axios'

export type AuditLogEntityParam = TAuditLogEntityParam<Entity>

export type Entity =
  | 'User'
  | 'Role'
  | 'VerifyToken'
  | 'Client'
  | 'RefreshToken'
  | 'Permission'
  | 'Device'
  | 'AuthCode'
  | 'MigrationUnitExecution'
  | 'Organisation'
  | 'OrphanedPermission'
  | 'ToxicPermissionAlert'
  | 'ToxicPermissionCombination'
  | 'UserAccessLogEntry'
  | 'Group'

export type AuditLogData = TAuditLogData<Entity> & {
  'Assigned Until'?: AuditLogTimestampParam
  Role?: AuditLogEntityParam
  'Added Permissions'?: AuditLogArrayParam<AuditLogEntityParam>
  'Removed Permissions'?: AuditLogArrayParam<AuditLogEntityParam>
  Data?: AuditLogStringParam
}

const auditLog = {
  getLogs: (params?: AuditParams): AxiosPromise<AuditLogData[]> =>
    axios.get('/api/audit-log', {
      params,
      headers: {
        'X-Version': 'future',
      },
    }),
}

export default auditLog
