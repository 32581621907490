import { CrossCheckExecutableName } from 'api/types/crossCheck'
import { CrossCheckValueTab } from '../../../types'
import { CREATE_NAMES } from '../../ChangeSwitcher/utils'
import { DetailRow } from '../types'

type IsRowHiddenParams = {
  row: DetailRow
  tab: CrossCheckValueTab
  isFinished: boolean
  executableName: CrossCheckExecutableName
}
export const isRowHidden = (params: IsRowHiddenParams) => {
  return isHiddenAsContext(params) || isHiddenAsEmpty(params)
}

const isHiddenAsContext = ({ tab, isFinished, row, executableName }: IsRowHiddenParams) =>
  !CREATE_NAMES.includes(executableName) &&
  tab === 'requested' &&
  isFinished &&
  row.isContext

const isHiddenAsEmpty = ({ tab, row }: IsRowHiddenParams) => {
  const isTabValueEmpty = !row.value[tab]?.length
  return isTabValueEmpty && row.changeType !== 'Deleted'
}
