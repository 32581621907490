import { Role as TRole } from 'api/idave/roles'
import { SamUserDetails } from 'api/sam/policies'
import { User } from 'api/idave/user'
import { alphabeticComparator } from 'utils/string/alphabeticComparator'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getPolicyAssignStatus } from 'utils/policy/getPolicyAssignStatus'
import { getRoleRelatedPolicies } from 'utils/policy/getRoleRelatedPolicies'
import { Role } from './types'

type GetUserRolesParams = {
  user?: User
  userDetails?: SamUserDetails
  roleMap: Map<string, TRole>
}

export const getUserRoles = (params: GetUserRolesParams) => {
  const { user, roleMap, userDetails } = params
  const activePolicies = getUserActivePolicies({ userDetails })

  return (user?.roles || [])
    .map<Role>((userRole) => {
      const relatedPolicies = getRoleRelatedPolicies({
        roleId: userRole.roleId,
        roleMap,
        policies: activePolicies,
      })

      const hasPolicyInfo = !!userDetails?.policies
      const assignedStatus =
        relatedPolicies.length && !userRole.expiryDate ? 'By policy' : 'Manually'

      const assigned = !hasPolicyInfo ? EMPTY_VALUE_FALLBACK : assignedStatus

      return {
        id: userRole.roleId,
        roleName: userRole.role,
        assigned,
        expirationDate: userRole.expiryDate,
      }
    })
    .sort((aRole, bRole) => alphabeticComparator(aRole.roleName, bRole.roleName))
}

type GetActivePoliciesParams = {
  userDetails?: SamUserDetails
}
export const getUserActivePolicies = (params: GetActivePoliciesParams) => {
  const { userDetails } = params
  if (!userDetails) {
    return []
  }

  return userDetails.policies.filter(
    (policy) => getPolicyAssignStatus(policy).statusLevel === 'success',
  )
}
