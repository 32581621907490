import { StatusCellLevels } from '@revolut/ui-kit'
import { AuditLogAccessModifiersParam } from 'api/dart/auditLog'
import { AccessModifierType } from 'api/dart/types'

export const getItems = (accessModifiersParam: AuditLogAccessModifiersParam) => {
  return accessModifiersParam.value.map(
    (accessModifierParam) => accessModifierParam.value,
  )
}

export const typeToStatusCell = (type: AccessModifierType): StatusCellLevels => {
  switch (type) {
    case 'ALLOWED':
      return 'success'
    case 'DENIED':
      return 'alert'
    default:
      return 'disabled'
  }
}
