import { Box, Subheader, Table } from '@revolut/ui-kit'
import { SamPolicy } from 'api/sam/policies'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { useQueryTrainingMap } from 'queries/sam/resources'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { SamTraining } from 'api/sam/trainings'
import { useMemo, useState } from 'react'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { getColumns } from './columns'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../ChangeSwitcher'
import { CrossCheckValueTab } from '../../../../types'
import { makeGetChangeRowState } from '../../../../utils'
import { getEmptyTrainingsLabel, getTrainingRowsInfo } from './utils'

export const TrainingList = ({
  crossCheck,
  policy,
}: {
  crossCheck: CrossCheckDetail
  policy?: SamPolicy
}) => {
  const {
    data: trainingMap = new Map(),
    status: qs,
    fetchStatus: fs,
    refetch,
  } = useQueryTrainingMap()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={trainingMap}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view trainings" />
      )}
      renderLoading={() => (
        <Inner
          crossCheck={crossCheck}
          policy={policy}
          trainingMap={trainingMap}
          isLoading
        />
      )}
      renderSuccess={() => (
        <Inner crossCheck={crossCheck} policy={policy} trainingMap={trainingMap} />
      )}
    />
  )
}

const Inner = ({
  crossCheck,
  policy,
  isLoading,
  trainingMap,
}: {
  crossCheck: CrossCheckDetail
  policy?: SamPolicy
  isLoading?: boolean
  trainingMap: Map<string, SamTraining>
}) => {
  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])
  const { counters, rows } = useMemo(
    () =>
      getTrainingRowsInfo({
        crossCheck,
        trainingMap,
        policy,
        tabs,
      }),
    [crossCheck, trainingMap, policy, tabs],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState<CrossCheckValueTab>(defaultTab)
  const data = rows[tab]
  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Trainings</Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            currentTab={tab}
            onChange={setTab}
            counters={counters}
            tabs={tabs}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        loadingState={isLoading ? 'pending' : 'ready'}
        getRowState={getRowState}
        labelEmptyState={getEmptyTrainingsLabel(tab)}
      />
    </Box>
  )
}
