import { useQueryMappedAvatars, useQueryEmployeePeopleOpsMap } from 'queries/sam/users'
import { useQueryDepartmentMap, useQueryTeamMap } from 'queries/sam/revoluters'
import { useQueryUserPeopleOpsIdMap } from 'queries/idave/users'
import { EntitiesTable } from 'components/EntitiesTable'
import { getRevoulutersEmployeeLink } from 'components/Links/RevolutersEmployeeLink'
import { useCallback } from 'react'
import { isAllSettled, mergeQueryStatuses } from 'utils/query'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useLoadingToast } from 'hooks/useLoadingToast'
import { getPolicyUsers } from './utils'
import { Employee } from './types'
import { useColumns } from './columns'

const TIMEOUT = 1500

export const SamPolicyEmployeesTable = (props: { policyEmployees: string[] }) => {
  const { policyEmployees } = props
  const {
    data: employeesMap = new Map(),
    status: employeesStatus,
    fetchStatus: employeeFetchStatus,
  } = useQueryEmployeePeopleOpsMap()

  const {
    data: avatars,
    status: avatarsStatus,
    fetchStatus: avatarsFS,
  } = useQueryMappedAvatars()

  const {
    data: peopleOpsUsers = new Map(),
    fetchStatus: usersFS,
    status: usersQS,
  } = useQueryUserPeopleOpsIdMap()

  const {
    data: teams = new Map(),
    status: revolutersStatus,
    fetchStatus: revolutersFetchStatus,
  } = useQueryTeamMap()
  const { data: departments = new Map() } = useQueryDepartmentMap()

  const getLink = useCallback(
    ({ id: employeeId, idaveId }: Employee) =>
      idaveId
        ? generatePath(Url.UserProfile, { userId: idaveId })
        : getRevoulutersEmployeeLink(employeeId),
    [],
  )

  const statuses = [
    {
      fs: revolutersFetchStatus,
      qs: revolutersStatus,
    },
    {
      fs: employeeFetchStatus,
      qs: employeesStatus,
    },
    {
      qs: avatarsStatus,
      fs: avatarsFS,
    },
    {
      fs: usersFS,
      qs: usersQS,
    },
  ]
  const isDataSettled = isAllSettled(...statuses)

  useLoadingToast(statuses, "Just a little longer, we're loading", TIMEOUT)

  const entitites = getPolicyUsers({
    employeesMap,
    policyEmployees,
    departments,
    teams,
    peopleOpsUsers,
  })

  const columns = useColumns(avatars)

  return (
    <EntitiesTable
      entitiesTypeLabel="Employees"
      pluralForms={['employee', 'employees']}
      totalCount={entitites.length}
      key={String(isDataSettled)}
      columns={columns}
      data={entitites}
      loadingState={getLoadingState(mergeQueryStatuses(...statuses), entitites.length)}
      getRowLink={getLink}
    />
  )
}
