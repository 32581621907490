import { BottomSheet, Button, TextArea } from '@revolut/ui-kit'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback, useState } from 'react'

type Props = {
  onSubmit: (justification: string) => void
  onCancel: () => void
}

export const JustifyPopup = (props: Props) => {
  const { onCancel, onSubmit } = props
  const [justification, setJustification] = useState<string>('')
  const changeHandler = useInputStringChange(setJustification)
  const isFilled = !!justification
  const submit = useCallback(() => onSubmit(justification), [onSubmit, justification])

  return (
    <>
      <TextArea
        label="Justification"
        rows={3}
        onChange={changeHandler}
        value={justification}
      />

      <BottomSheet.Actions horizontal>
        <Button elevated onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={submit} disabled={!isFilled} variant="primary">
          Submit
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
