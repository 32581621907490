import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { OrganisationData } from 'api/idave/organisations'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'

export const OrganisationActions = (props: { organisation: OrganisationData }) => {
  const { organisation } = props
  const { setPopup, closePopup } = useConfirmationPopup()
  const { showSuccessToast } = useToasts()
  const { hideLoadingPopup, showLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutate: removeOrg, isLoading: isDeleting } = useMutation({
    mutationFn: idaveApi.organisations.delete,

    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      navigate(Url.Organisations)
      showSuccessToast('Organisation deleted')

      queryClient.invalidateQueries({
        queryKey: [QueryKey.Organisations],
      })
    },
    onError: (error) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Organisation deletion failed',
        error,
      })
      closePopup()
    },
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
  })

  const onClick = useCallback(() => {
    setPopup({
      title: 'Delete organisation',
      message: `Please to confirm to delete organisation ${organisation.name}`,
      confirmButtonText: 'Delete',
      onConfirmClick: () => removeOrg(organisation.id),
    })
  }, [organisation, removeOrg, setPopup])

  const onEditClick = useCallback(
    () =>
      navigate(generatePath(Url.OrganisationEdit, { organisationId: organisation.id })),
    [organisation, navigate],
  )

  return (
    <PermissionsCheck
      somePermissions={[
        IDAVE_PERMISSIONS.ORGANISATIONS_DELETE,
        IDAVE_PERMISSIONS.ORGANISATIONS_UPDATE,
      ]}
    >
      <MoreBar>
        <PermissionsCheck permission={IDAVE_PERMISSIONS.ORGANISATIONS_UPDATE}>
          <MoreBar.Action useIcon="Pencil" onClick={onEditClick}>
            Edit
          </MoreBar.Action>
        </PermissionsCheck>

        <PermissionsCheck permission={IDAVE_PERMISSIONS.ORGANISATIONS_DELETE}>
          <MoreBar.Action
            useIcon="Delete"
            onClick={onClick}
            disabled={isDeleting}
            pending={isDeleting}
            variant="negative"
          >
            Delete
          </MoreBar.Action>
        </PermissionsCheck>
      </MoreBar>
    </PermissionsCheck>
  )
}
