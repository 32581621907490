import {
  GetRowState,
  MoreBar,
  TableData,
  TableLoadingState,
  TableProps,
} from '@revolut/ui-kit'
import { Role } from 'api/idave/roles'
import { useMapify } from 'hooks/useMapify'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { EntitiesTable } from 'components/EntitiesTable'
import { filterSelectedEntities } from 'components/EntitiesTable/utils'
import { useCallback, useMemo, useState } from 'react'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { filterActiveIds } from 'components/EntitiesTable/utils'
import { Client } from 'api/idave/clients'
import { EDIT } from 'view/Roles/permissions'
import { usePermissionPreview } from 'components/previews/PermissionPreview'
import { uniqBy } from 'lodash'
import { PermissionsCheck, usePermissions } from '@revolut-internal/idave-web-auth'
import { getColumns } from './columns'
import { getDataLabels, getRowLink } from './utils'
import { RolePermission } from '../../types'

type RolePermissionsListProps = {
  roleId: string
  roleMap: Map<string, Role>
  clientMap: Map<string, Client>
  isActionsDisabled: boolean
  rows: RolePermission[]
  loadingState: TableLoadingState
  hasToxicPermissions: boolean
  deletePermissions: (permissions: string[]) => void
  openAdding: () => void
  getRowState: (params: {
    value: RolePermission
  }) => ReturnType<GetRowState<RolePermission>>
}
export const RolePermissionsList = ({
  roleId,
  isActionsDisabled,
  roleMap,
  rows,
  loadingState,
  clientMap,
  hasToxicPermissions,
  getRowState,
  deletePermissions,
  openAdding,
}: RolePermissionsListProps) => {
  const columns = useMemo(
    () => getColumns(roleMap, clientMap, roleId),
    [roleMap, clientMap, roleId],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: rows,
  })

  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})

  const tableRowMap = useMapify(rows, (r) => r.id)

  // Prevent delete inherited permissions
  const disableDelete = useMemo(
    () =>
      filterActiveIds(selectedHash).some(
        (id) => tableRowMap.get(id)?.ownerRoleId !== roleId,
      ),
    [selectedHash, tableRowMap, roleId],
  )

  const totalCount = useMemo(() => uniqBy(rows, 'permissionId').length, [rows])
  const dataLabels = useMemo(
    () => getDataLabels(totalCount, rows, roleId),
    [totalCount, rows, roleId],
  )

  const [showSelected, setShowSelected] = useState(false)

  const switchShowSelected = useCallback(() => {
    setShowSelected((v) => !v)
  }, [setShowSelected])

  const onDeleteClick = useCallback(() => {
    const entities = filterSelectedEntities(rows, selectedHash)
    deletePermissions(entities.map((entity) => entity.permissionId))
  }, [selectedHash, rows, deletePermissions])

  const selectedCount = filterActiveIds(selectedHash).length

  const { openPermissionSide } = usePermissionPreview()
  const onRowClick = useCallback(
    (row: RolePermission) => {
      openPermissionSide(row.permissionId)
    },
    [openPermissionSide],
  )
  const { hasSomePermissions } = usePermissions()

  return loadingState === 'no-results' ? (
    <EmptyStatusWidget
      title="Add permissions you want to associate with this Role"
      imageCode="3D055"
      actionLabel="Add permissions"
      onClick={openAdding}
      actionAllowed={hasSomePermissions(...EDIT)}
    />
  ) : (
    <EntitiesTable
      totalCount={totalCount}
      entitiesTypeLabel="Permissions"
      pluralForms={['permission', 'permissions']}
      data={searched}
      loadingState={loadingState}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
      dataLabels={dataLabels}
      getRowLink={getRowLink}
      onRowClick={onRowClick}
      selectedHash={selectedHash}
      switchShowSelected={switchShowSelected}
      showSelected={showSelected}
      setSelectedHash={setSelectedHash}
      getRowState={getRowState}
      renderActions={() => (
        <PermissionsCheck somePermissions={EDIT}>
          <RolePermissionListActions
            {...{
              disableDelete,
              selectedCount,
              loadingState,
              isActionsDisabled,
              openAdding,
              onDeleteClick,
              hasToxicPermissions,
            }}
          />
        </PermissionsCheck>
      )}
      showSelectedSwitcherForce={!hasSomePermissions(...EDIT)}
    />
  )
}

const RolePermissionListActions = (props: {
  disableDelete: boolean
  selectedCount: number
  loadingState: TableProps<TableData>['loadingState']
  isActionsDisabled: boolean
  hasToxicPermissions: boolean
  openAdding: () => void
  onDeleteClick: () => void
}) => {
  const {
    selectedCount,
    loadingState,
    isActionsDisabled,
    disableDelete,
    hasToxicPermissions,
    onDeleteClick,
    openAdding,
  } = props

  const disabled = loadingState !== 'ready' || isActionsDisabled

  return selectedCount ? (
    <MoreBar.Action
      useIcon="Delete"
      onClick={onDeleteClick}
      variant="negative"
      disabled={disabled || disableDelete}
    >
      Delete
    </MoreBar.Action>
  ) : (
    <MoreBar.Action
      useIcon="Plus"
      onClick={openAdding}
      disabled={disabled || hasToxicPermissions}
    >
      Add permissions
    </MoreBar.Action>
  )
}
