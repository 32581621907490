import { filterActiveIds } from 'components/EntitiesTable/utils'
import { omit } from 'lodash'

type GetSelectedValuesParams = {
  selectedHash: Record<string, boolean>
  newItemId: string
}
export const getSelectedValues = ({
  selectedHash,
  newItemId,
}: GetSelectedValuesParams) => {
  const newHash = selectedHash[newItemId]
    ? omit(selectedHash, newItemId)
    : { ...selectedHash, [newItemId]: true }

  return filterActiveIds(newHash)
}
