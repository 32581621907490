import { StatusCellLevels } from '@revolut/ui-kit'
import { PolicyDetails } from 'api/sam/policies'
import { pluralForm } from 'utils/string'

export const getPolicyAssignStatus = (policy: PolicyDetails) => {
  const completedCount =
    policy.mandatoryTrainings?.filter(({ completed }) => completed).length || 0
  const trainingsCount = policy.mandatoryTrainings?.length || 0

  if (completedCount === trainingsCount) {
    return {
      status: 'Assigned',
      statusLevel: 'success' as StatusCellLevels,
    }
  }

  return {
    status: pluralForm(trainingsCount, [
      `${completedCount} of ${trainingsCount} training completed`,
      `${completedCount} of ${trainingsCount} trainings completed`,
    ]),
    statusLevel: 'warning' as StatusCellLevels,
  }
}
