import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryUsers } from 'queries/idave/users'
import { useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { Author } from './Author'
import { LogAuthorSkeleton } from './LogAuthorSkeleton'

type AuthorUserProps = {
  id?: string
  userName?: string
  email?: string
}

export const AuthorUser = (props: AuthorUserProps) => {
  const { id, userName, email } = props
  const { status, data: users, fetchStatus } = useQueryUsers()
  const {
    data: avatars,
    status: avatarsStatus,
    fetchStatus: avatarsFS,
  } = useQueryMappedAvatars()

  const user = useMemo(() => {
    return users?.find((userItem) => {
      return (
        (id && userItem.id === id) ||
        (userName && userItem.username === userName) ||
        (email && userItem.email === email)
      )
    })
  }, [id, userName, email, users])

  if (!id && !userName && !email) {
    return null
  }

  const fallback = id || userName || email

  return (
    <QuerySwitch
      renderIdle={() => null}
      renderError={() => (fallback ? <Author name={fallback} /> : null)}
      renderLoading={() => <LogAuthorSkeleton />}
      renderSuccess={({ data }) => (
        <Author
          name={getName(data)}
          description={data.email}
          id={data.id}
          state={data.state}
          href={generatePath(Url.UserProfile, { userId: data.id })}
          avatarUrl={getAvatarUrl({ avatars, type: 'imageMedium', user: data })}
        />
      )}
      required={[{ qs: status, fs: fetchStatus }]}
      optional={[{ qs: avatarsStatus, fs: avatarsFS }]}
      data={user}
    />
  )
}
