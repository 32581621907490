import { Text } from '@revolut/ui-kit'
import { StatusText } from 'components/StatusText'

export const DiffSuffix = (props: { isNew?: boolean }) => (
  <StatusText level={props.isNew ? 'success' : 'alert'}>
    <Text fontSize="caption" fontWeight="400" whiteSpace="nowrap">
      {props.isNew ? '(Added)' : '(Removed)'}
    </Text>
  </StatusText>
)
