import { Page, TabBar } from '@revolut/ui-kit'
import { useQueryCrossChecksList } from 'queries/idave/crossChecks'
import { useQuerySamCrossChecksList } from 'queries/sam/crossChecks'
import { useQueryDartCrossChecksList } from 'queries/dart/crossChecks'
import { waitForAllOptional } from 'utils/query/waitForAllOptional'
import { Url } from 'routing'
import { TabBarLink } from 'components/TabBarLink'
import { QuerySwitch } from 'components/QuerySwitch'
import { IDAVE_PERMISSIONS } from 'security'
import { memo, useMemo, useState } from 'react'
import { useCurrentUser } from 'queries/idave/users'
import { UserListItem } from 'api/idave/user'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { getDefaultFilterParams } from './utils'
import { CrossChecksTab } from './types'
import { CrossChecksSkeleton } from './components/CrossChecksSkeleton'
import { List } from './components/List'

type Props = {
  tab: CrossChecksTab
}
export const CrossCheckList = memo(({ tab }: Props) => {
  const { data: currentUser, fetchStatus: fs, status: qs } = useCurrentUser()

  return (
    <>
      <Page.Header>Requests</Page.Header>
      <Page.Tabs>
        <PermissionsCheck permission={IDAVE_PERMISSIONS.USERS_CURRENT_VIEW_DETAILS}>
          <TabBar variant="navigation">
            <TabBarLink to={Url.CrossChecksToReview}>Approvals</TabBarLink>

            <TabBarLink to={Url.CrossChecksRequested}>Requested by me</TabBarLink>

            <TabBarLink to={Url.CrossChecksAll}>All</TabBarLink>
          </TabBar>
        </PermissionsCheck>
      </Page.Tabs>
      <Page.Main>
        <QuerySwitch
          required={[{ fs, qs }]}
          data
          renderError={() => <Inner tab={tab} />}
          renderIdle={() => <Inner tab={tab} />}
          renderLoading={() => <CrossChecksSkeleton tab={tab} />}
          renderSuccess={() => <Inner currentUser={currentUser} tab={tab} />}
        />
      </Page.Main>
    </>
  )
})

type InnerProps = {
  currentUser?: UserListItem
  tab: CrossChecksTab
}
export const Inner = ({ currentUser, tab }: InnerProps) => {
  const [userFilter, setUserFilter] = useState<UserListItem | undefined>()
  const requestFilters = useMemo(
    () => getDefaultFilterParams(tab, currentUser, userFilter),
    [tab, userFilter, currentUser],
  )

  const {
    data: idave = [],
    status: idaveQS,
    fetchStatus: idaveFS,
  } = useQueryCrossChecksList(requestFilters.idave)
  const {
    data: sam = [],
    status: samQS,
    fetchStatus: samFS,
  } = useQuerySamCrossChecksList(requestFilters.sam)
  const {
    data: dart = [],
    status: dartQS,
    fetchStatus: dartFS,
  } = useQueryDartCrossChecksList(requestFilters.dart)

  const crossChecks = {
    idave,
    sam,
    dart,
  }

  const statuses = [
    {
      qs: idaveQS,
      fs: idaveFS,
    },
    {
      qs: samQS,
      fs: samFS,
    },
    {
      qs: dartQS,
      fs: dartFS,
    },
  ]
  const queryResult = waitForAllOptional(...statuses)

  return (
    <QuerySwitch
      required={[]}
      optional={statuses}
      data={crossChecks}
      renderError={() => (
        <List
          crossChecks={crossChecks}
          queryResult={queryResult}
          tab={tab}
          setUserFilter={setUserFilter}
          userFilter={userFilter}
        />
      )}
      renderIdle={() => (
        <List
          crossChecks={crossChecks}
          queryResult={queryResult}
          tab={tab}
          setUserFilter={setUserFilter}
          userFilter={userFilter}
        />
      )}
      renderLoading={() => <CrossChecksSkeleton tab={tab} />}
      renderSuccess={() => (
        <List
          crossChecks={crossChecks}
          queryResult={queryResult}
          tab={tab}
          setUserFilter={setUserFilter}
          userFilter={userFilter}
        />
      )}
    />
  )
}
