import { FilterButton, RadioSelect, useToggle } from '@revolut/ui-kit'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryUsers } from 'queries/idave/users'
import { useCallback, useMemo, useRef } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { UserListItem } from 'api/idave/user'
import { getName } from 'utils/string/getName'
import { getLoadingState } from 'components/Selects/utils'
import { sortBy } from 'lodash'
import { makeRenderOption, renderIcon } from './utils'

export const ReviewerFilter = (props: {
  setUserFilter: (requestFilters?: UserListItem) => void
  userFilter?: UserListItem
}) => {
  const { setUserFilter, userFilter } = props

  const { data: users = [], status: qs, fetchStatus: fs } = useQueryUsers()
  const { data: avatars } = useQueryMappedAvatars()
  const anchorRef = useRef(null)
  const [open, toggleOpen] = useToggle()

  const userToShow = sortBy(
    users.filter((i) => i.externalRef?.reference),
    getName,
  )

  const { searched, setSearchValue } = useSearchFilter({
    entities: userToShow,
    timeout: 1000,
  })

  const options = searched.map((user) => ({
    key: user.id,
    label: getName(user),
    value: user,
  }))

  const onClick = useCallback(() => toggleOpen(), [toggleOpen])
  const onClear = useMemo(
    () => (userFilter ? () => setUserFilter(undefined) : undefined),
    [setUserFilter, userFilter],
  )
  const onChange = useCallback(
    (value?: null | UserListItem) => {
      if (value) {
        setUserFilter(value)
      } else {
        setUserFilter()
      }
    },
    [setUserFilter],
  )

  const renderOption = useMemo(() => makeRenderOption(avatars), [avatars])

  return (
    <>
      <FilterButton
        ref={anchorRef}
        useIcon={renderIcon(userFilter, avatars)}
        onClick={onClick}
        onClear={onClear}
        active={!!userFilter}
        aria-haspopup="listbox"
        aria-expanded={open}
      >
        {userFilter ? getName(userFilter) : 'Reviewer'}
      </FilterButton>

      <RadioSelect<UserListItem>
        open={open}
        onClose={toggleOpen.off}
        options={options}
        value={userFilter}
        onChange={onChange}
        onSearchText={setSearchValue}
        labelList="People"
        labelSearch="Search"
        labelNoResults="No results found"
        searchable
        minWidth="400px"
        placement="bottom-end"
        indicatorStyle="highlight"
        autoClose
        type="instant"
        loadingState={getLoadingState(mergeQueryStatuses({ qs, fs }))}
      >
        {renderOption}
      </RadioSelect>
    </>
  )
}
