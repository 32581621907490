import { Group, Item } from '@revolut/ui-kit'
import { Role } from 'api/idave/roles'
import { RoleInheritanceTreeLayer } from './RoleInheritanceTreeLayer'
import { InheritanceTree } from './types'

type RoleInheritanceTreeProps = {
  inheritanceTree: InheritanceTree
  sourceRole: Role
  roleMap: Map<string, Role>
}

export const RoleInheritanceTree = ({
  inheritanceTree,
  sourceRole,
  roleMap,
}: RoleInheritanceTreeProps) => (
  <Group>
    <Item>
      <RoleInheritanceTreeLayer
        inheritanceTree={inheritanceTree}
        isInitial
        sourceRole={sourceRole}
        roleMap={roleMap}
      />
    </Item>
  </Group>
)
