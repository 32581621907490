import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback } from 'react'
import { AddUserPopup } from './AddUserPopup'

export const useAddUserPopup = (onAdd: (userIds: string[]) => void) => {
  const { setPopup, closePopup } = useEditPopup()

  const addUsers = useCallback(
    (userIds: string[]) => {
      onAdd(userIds)
      closePopup()
    },
    [closePopup, onAdd],
  )

  const showAddUserPopup = useCallback(() => {
    setPopup({
      title: 'Add users',
      content: <AddUserPopup add={addUsers} />,
      focusTrap: false,
    })
  }, [addUsers, setPopup])

  return { showAddUserPopup }
}
