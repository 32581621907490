import { Table, TableColumn, Text, Token } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { Role } from 'api/idave/roles'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const getColumns = (
  clientsMap: Map<string, Client>,
  rolesMap: Map<string, Role>,
): TableColumn<Role>[] => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (params: { row: { original: Role } }) => {
        const role = params.row.original
        const client = role.client ? clientsMap.get(role.client) : undefined
        return (
          <Table.Cell>
            <Text>
              {role.name}
              {role.client ? (
                <Text color={Token.color.warning}>{` DEPRECATED (${
                  client?.name || role.client
                })`}</Text>
              ) : null}
            </Text>
          </Table.Cell>
        )
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Parent',
      accessor: 'parent',
      Cell: (params: { value?: string }) => {
        const parent = params.value
          ? rolesMap.get(params.value)?.name
          : EMPTY_VALUE_FALLBACK

        return (
          <Table.Cell>
            <Text>{parent}</Text>
          </Table.Cell>
        )
      },
    },
  ]
}
