import { TableColumn, Table } from '@revolut/ui-kit'
import { InternalLink } from 'components/Links/InternalLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'

export const COLUMNS: TableColumn<{ name: string; id: string }>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (params: { row: { original: { name: string; id: string } } }) => {
      return (
        <Table.Cell>
          <InternalLink href={generatePath(Url.Role, { roleId: params.row.original.id })}>
            {params.row.original.name}
          </InternalLink>
        </Table.Cell>
      )
    },
  },
]
