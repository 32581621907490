import { MoreBar } from '@revolut/ui-kit'

export const Actions = ({
  selectedCount,
  onAddClick,
  onRemoveClick,
}: {
  selectedCount: number
  onRemoveClick: () => void
  onAddClick: () => void
}) => (
  <>
    {!!selectedCount && (
      <MoreBar.Action variant="negative" onClick={onRemoveClick} useIcon="Delete">
        Delete
      </MoreBar.Action>
    )}

    {!selectedCount && (
      <MoreBar.Action useIcon="Plus" onClick={onAddClick}>
        Add users
      </MoreBar.Action>
    )}
  </>
)
