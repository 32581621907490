import { Role } from 'api/idave/roles'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getRolesByPermission } from 'utils/role/getRolesByPermission'
import { RoleRow } from './types'

type GetRoleEntitiesParams = {
  permissionId: string
  roleMap: Map<string, Role>
  roles: Role[]
}
export const getRoleEntities = ({
  permissionId,
  roleMap,
  roles,
}: GetRoleEntitiesParams): RoleRow[] => {
  const roleItems = getRolesByPermission({ roles, permissionId })

  return roleItems.map((role) => ({
    name: role.name,
    description: role.description || EMPTY_VALUE_FALLBACK,
    parent: role.parent
      ? roleMap.get(role.parent)?.name || role.parent
      : EMPTY_VALUE_FALLBACK,
    id: role.id,
  }))
}
