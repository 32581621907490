import { Button, Popup, TextArea, VStack } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckGroup, CrossCheckSystem } from 'api/types/crossCheck'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback, useState } from 'react'
import { UserList } from 'components/UserList'
import { useEditPopup } from 'hooks/useEditPopup'
import { GroupSelect } from '../GroupSelect'
import { AssignParams } from '../../types'
import { useCrossCheckUserSuggestions } from './useCrossCheckUserSuggestions'

type Props = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
  groups: CrossCheckGroup[]
  onSubmit: (params: AssignParams) => void
  defaultGroup?: string
}

export const AssignPopup = (props: Props) => {
  const { groups, onSubmit, source, defaultGroup, crossCheck } = props

  const { scrollRef } = useEditPopup()
  const [groupId, setGroupId] = useState<string | undefined>(defaultGroup)
  const [reviewersIds, setReviewersIds] = useState<string[]>([])
  const [reason, setReason] = useState<string>()
  const reasonHandler = useInputStringChange(setReason)

  const isFilled = groupId && reviewersIds

  const onReviewersChange = useCallback(
    (selected: string[]) => {
      setReviewersIds(selected)
    },
    [setReviewersIds],
  )

  const submit = useCallback(() => {
    if (groupId && reviewersIds.length) {
      onSubmit({ groupId, reviewers: reviewersIds, reason })
    }
  }, [onSubmit, groupId, reviewersIds, reason])

  const { excludeIds, bubbleUpIds } = useCrossCheckUserSuggestions({
    crossCheck,
    source,
  })

  return (
    <>
      <VStack space="s-16">
        <GroupSelect
          groups={groups}
          onGroupChange={setGroupId}
          defaultGroup={defaultGroup}
        />
        <TextArea
          label="Justification (optional)"
          rows={2}
          onChange={reasonHandler}
          value={reason}
        />

        <UserList
          onChange={onReviewersChange}
          variant="checkbox"
          scrollRef={scrollRef}
          excludeInactive
          exclude={excludeIds}
          bubbleUp={bubbleUpIds}
          usePeopleOpsIds={source === 'sam'}
        />
      </VStack>
      <Popup.Actions>
        <Button elevated onClick={submit} disabled={!isFilled}>
          Add
        </Button>
      </Popup.Actions>
    </>
  )
}
