import { usePermissions } from '@revolut-internal/idave-web-auth'
import { ActionButtonProps } from '@revolut/ui-kit'
import { User } from 'api/idave/user'
import { IDAVE_PERMISSIONS } from 'security'

type GetUserActionsParams = {
  permissionUtils: ReturnType<typeof usePermissions>
  isOwnProfile: boolean
  userState: User['state']
  onUserEditClick: () => void
  onResetPasswordClick: () => void
  onChangePasswordClick: () => void
  onActivateClick: () => void
  onTerminateClick: () => void
}

type GetUserActionsResult = Array<{
  variant: ActionButtonProps['variant']
  useIcon: ActionButtonProps['useIcon']
  onClick: () => void
  label: string
}>

export const getUserActions = ({
  permissionUtils: { hasPermission },
  isOwnProfile,
  userState,
  onUserEditClick,
  onResetPasswordClick,
  onChangePasswordClick,
  onActivateClick,
  onTerminateClick,
}: GetUserActionsParams) => {
  const actions: GetUserActionsResult = []

  if (hasPermission(IDAVE_PERMISSIONS.USERS_UPDATE)) {
    actions.push({
      label: 'Edit',
      onClick: onUserEditClick,
      useIcon: 'Pencil',
      variant: 'primary',
    })
  }

  if (hasPermission(IDAVE_PERMISSIONS.USERS_RESET_PASSWORD)) {
    actions.push({
      label: 'Reset password',
      onClick: onResetPasswordClick,
      useIcon: 'ArrowExchange',
      variant: 'primary',
    })
  }

  if (isOwnProfile && hasPermission(IDAVE_PERMISSIONS.USERS_CURRENT_CHANGE_PASSWORD)) {
    actions.push({
      label: 'Change password',
      onClick: onChangePasswordClick,
      useIcon: '16/LockOutline',
      variant: 'primary',
    })
  }

  if (
    !isOwnProfile &&
    userState === 'TERMINATED' &&
    hasPermission(IDAVE_PERMISSIONS.USERS_UNTERMINATE)
  ) {
    actions.push({
      label: 'Activate',
      onClick: onActivateClick,
      useIcon: '16/CheckCircle',
      variant: 'primary',
    })
  }

  if (
    !isOwnProfile &&
    userState === 'ACTIVE' &&
    hasPermission(IDAVE_PERMISSIONS.USERS_TERMINATE)
  ) {
    actions.push({
      label: 'Terminate',
      onClick: onTerminateClick,
      useIcon: 'MinusCircle',
      variant: 'negative',
    })
  }

  return actions
}
