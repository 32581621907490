import { Box, StatusWidget, Subheader, Table } from '@revolut/ui-kit'
import { ToxicAlert } from 'api/idave/toxic'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { AlertDescriptor } from 'components/previews/ToxicAlertPreview/types'
import { useQueryToxicAlerts } from 'queries/idave/permissions'
import { get3DImageSrcSetProps } from 'utils/url'
import { cartesianProduct } from 'utils/array/cartesianProduct'
import { useColumns } from './columns'

type Props = {
  toxicAlertDescriptor: AlertDescriptor
}

export const RolePairs = ({
  toxicAlertDescriptor: { toxicPermissionId, userId },
}: Props) => {
  const {
    data: toxicAlerts,
    fetchStatus: toxicFS,
    status: toxicQS,
  } = useQueryToxicAlerts()

  if (!toxicPermissionId) {
    return (
      <StatusWidget>
        <StatusWidget.Image {...get3DImageSrcSetProps('3D231', 'v2')} />
        <StatusWidget.Title>Lack of toxic pair id</StatusWidget.Title>
        <StatusWidget.Description>
          Toxic alert doesn&apos;t specify a toxic pair
        </StatusWidget.Description>
      </StatusWidget>
    )
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Role combinations</Subheader.Title>
      </Subheader>
      <QuerySwitch
        data={toxicAlerts?.find(
          (i) => i.toxicPermissionId === toxicPermissionId && i.userId === userId,
        )}
        required={[{ fs: toxicFS, qs: toxicQS }]}
        renderIdle={() => (
          <NoAccessWidget title="Looks like you don't have sufficient permissions to view toxic pairs or roles" />
        )}
        renderLoading={() => <DetailsGroupSkeleton cellCount={2} />}
        renderSuccess={({ data }) => <Inner toxicAlert={data} />}
      />
    </Box>
  )
}

const Inner = ({ toxicAlert }: { toxicAlert: ToxicAlert }) => {
  const rolePairs = cartesianProduct(
    toxicAlert.firstRoleIds,
    toxicAlert.secondRoleIds,
  ).map(([firstRole, secondRole]) => ({ firstRole, secondRole }))
  const columns = useColumns()

  return <Table data={rolePairs} columns={columns} />
}
