export type Fullfilled<Params, Result> = {
  value: Result
  params: Params
  status: 'fullfilled'
}

export type Rejected<Params> = {
  reason: any
  params: Params
  status: 'rejected'
}

export type FullfilledWithParams<Params, Result> =
  | Fullfilled<Params, Result>
  | Rejected<Params>

export const fullfillSettledWithParams = <Params, Result = unknown>(
  paramList: Params[],
  promiseFunction: (params: Params) => Promise<Result>,
) => {
  return Promise.allSettled(
    paramList.map((params) =>
      promiseFunction(params)
        .then((value) => ({ value, params, status: 'fullfilled' as const }))
        .catch((reason) => ({ reason, params, status: 'rejected' as const })),
    ),
  ).then((results) =>
    results.reduce<FullfilledWithParams<Params, Result>[]>((acc, item) => {
      // Extract values, all of promises is catched above
      if (item.status === 'fulfilled') {
        return [...acc, item.value]
      }
      return acc
    }, []),
  )
}

export const fullfilledRejectedSplit = <Params, Result = unknown>(
  promises: FullfilledWithParams<Params, Result>[],
) =>
  promises.reduce<{
    rejected: Params[]
    fullfilled: Params[]
  }>(
    (acc, item) => {
      return {
        ...acc,
        [item.status]: [...acc[item.status], item.params],
      }
    },
    { rejected: [], fullfilled: [] },
  )
