import { UserListItem } from 'api/idave/user'
import { SamPolicy } from 'api/sam/policies'
import { RevolutersDictionary, RevolutersEmployee } from 'api/sam/revoluters'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { uniq } from 'lodash'
import { useQueryEmployees } from 'queries/sam/users'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { useQueryRevolutersDictionaries } from 'queries/sam/revoluters'
import { useQueryUserPeopleOpsIdMap } from 'queries/idave/users'
import { mapify, notNullableMap } from 'utils/array'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { notNullable } from 'utils/common'
import { getEmployeeIds, getSpecIds, getTeamIds } from 'view/Sam/utils'

type Params = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
}
export const useCrossCheckUserSuggestions = ({ crossCheck, source }: Params) => {
  const { data: revoluters } = useQueryRevolutersDictionaries()
  const { data: employeeIdMap = new Map() } = useQueryUserPeopleOpsIdMap()
  const { data: employees = [] } = useQueryEmployees()

  const { policyId, userId } = extractEntityIds({
    crossCheck,
    source,
    employeeIdMap,
  })

  const { data: policy } = useQuerySamPolicy(policyId)
  const policyRelatedUsers = getPolicyRelatedUsers({
    policy,
    revoluters,
    employeeIdMap,
    employees,
  })

  return {
    bubbleUpIds: policyRelatedUsers,
    excludeIds: [userId].filter(notNullable),
  }
}

type ExtractEntityIdsParams = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
  employeeIdMap: Map<string, UserListItem>
}
const extractEntityIds = ({
  crossCheck,
  source,
  employeeIdMap,
}: ExtractEntityIdsParams) => {
  return {
    userId:
      source === 'sam'
        ? employeeIdMap.get(crossCheck.requester)?.id
        : crossCheck.requester,
    policyId: crossCheck.entityType === 'AccessPolicy' ? crossCheck.entityId : undefined,
  }
}

const getSpecOwners = (policy: SamPolicy, revoluters: RevolutersDictionary) => {
  const specIds = getSpecIds(policy.subject)
  if (!specIds.length) {
    return []
  }

  const specMap = mapify(revoluters.specialisations, (spec) => spec.id)
  return notNullableMap(specIds, (id) => {
    return specMap.get(id)?.ownerId
  })
}

const getTeamMembers = (policy: SamPolicy, employees: RevolutersEmployee[]) => {
  const teamIds = getTeamIds(policy.subject)
  if (!teamIds.length) {
    return []
  }

  const teamMap = toPresenceMap(teamIds)
  return notNullableMap(employees, ({ teamId, revolutersId }) =>
    teamMap[teamId] ? revolutersId : undefined,
  )
}

const getEmployeeLeads = (
  policy: SamPolicy,
  employeeMap: Map<string, RevolutersEmployee>,
  revoluters: RevolutersDictionary,
) => {
  const employeeIds = getEmployeeIds(policy.subject)
  if (!employeeIds.length) {
    return []
  }
  const employeeTeams = notNullableMap(employeeIds, (peopleOpsId) => {
    const employee = employeeMap.get(peopleOpsId)
    return employee?.teamId
  })

  const teamMap = mapify(revoluters.teams, (t) => t.id)

  return notNullableMap(employeeTeams, (teamId) => teamMap.get(teamId)?.ownerId)
}

type GetPolicyRelatedUsersParams = {
  policy?: SamPolicy
  revoluters?: RevolutersDictionary
  employeeIdMap: Map<string, UserListItem>
  employees?: RevolutersEmployee[]
}

export const getPolicyRelatedUsers = ({
  policy,
  revoluters,
  employeeIdMap,
  employees,
}: GetPolicyRelatedUsersParams) => {
  if (!policy || !revoluters || !employeeIdMap.size || !employees?.length) {
    return []
  }

  const employeeMap = mapify(employees, (e) => e.revolutersId)
  return notNullableMap(
    uniq([
      ...getTeamMembers(policy, employees),
      ...getSpecOwners(policy, revoluters),
      ...getEmployeeLeads(policy, employeeMap, revoluters),
    ]),
    (peopleOpsId) => employeeIdMap.get(peopleOpsId)?.id,
  )
}
