import { generatePath } from 'react-router'
import { Navigate, useParams } from 'react-router'
import { Url } from 'routing/url'

type RedirectElementProps = {
  params: string[]
  url: string
}
export const RedirectElement = ({ params, url }: RedirectElementProps) => {
  const pathParams = useParams()
  const paramsValue = params.reduce(
    (acc, name) => ({
      ...acc,
      [name]: pathParams[name],
    }),
    {},
  )

  if (Object.values(paramsValue).every((v) => !!v)) {
    return <Navigate to={generatePath(url, paramsValue)} replace />
  }

  return <Navigate to={Url.Home} replace />
}
