import { uniq } from 'lodash'
import { useCallback } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { PermissionsList } from 'view/Clients/Account/components/PermissionWidget'

export const Permissions = () => {
  const { control } = useFormContext()
  const { field } = useController({ name: 'permissionIds', control, defaultValue: [] })

  const onAdd = useCallback(
    (ids: string[]) => {
      field.onChange(uniq([...ids, ...field.value]))
    },
    [field],
  )

  const onDelete = useCallback(
    (ids: string[]) => {
      field.onChange(field.value.filter((id: string) => !ids.includes(id)))
    },
    [field],
  )

  return (
    <PermissionsList
      permissionIds={field.value}
      onDelete={onDelete}
      onAdd={onAdd}
      showActions
    />
  )
}
