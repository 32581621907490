import { ViewCase } from './types'

export const getAlertBannerText = (view: ViewCase) => {
  switch (view) {
    case 'creating':
      return {
        title: 'You’re creating the role with permissions that are in toxic pair',
        description:
          'Please, remove any of permissions that are in toxic pair to create a role',
      }
    case 'updating':
      return {
        title: 'You’re adding permissions that makes a toxic pair',
        description: 'Please, remove them from permissions list to update the role',
      }
    default:
    case 'reading':
      return {
        title: 'This role has permissions that are in toxic pair',
        description: 'Please, remove any of permissions that are in toxic pair',
      }
  }
}
