import { useCallback, useEffect, useState } from 'react'
import { SideAvatar } from 'view/SideBox'
import { useSideBox } from 'view/SideBox/SideBox'
import { USER_POLICY_TYPE } from 'view/SideBox/hooks/useSideboxNavigation'
import { Body } from './components/Body'
import { Title } from './components/Title'
import { Actions } from './components/Actions'
import { Subtitle } from './components/Subtitle'

export const useUserPolicyPreview = () => {
  const [userPolicy, setUserPolicy] = useState<{ userId?: string; policyId?: string }>()
  const { openSide } = useSideBox()
  const onClose = useCallback(() => setUserPolicy({}), [setUserPolicy])

  useEffect(() => {
    if (!userPolicy) {
      return undefined
    }
    const { userId, policyId } = userPolicy
    if (!userId || !policyId) {
      return undefined
    }
    const queryParams = {
      id: policyId,
      subjectId: userId,
      type: USER_POLICY_TYPE,
    }

    return openSide({
      title: <Title policyId={policyId} />,
      subtitle: <Subtitle policyId={policyId} />,
      body: <Body policyId={queryParams.id} userId={queryParams.subjectId} />,
      actions: <Actions policyId={queryParams.id} />,
      avatar: <SideAvatar iconName="Key" />,
      queryParams,
      onClose,
    })
  }, [userPolicy, openSide, onClose])

  return {
    openPolicySide: (policyId?: string, userId?: string) =>
      setUserPolicy({ policyId, userId }),
  }
}
