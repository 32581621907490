import { Table, TableColumn } from '@revolut/ui-kit'
import { RoleLink } from 'components/Links/RoleLink'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useMemo } from 'react'

export const useColumns = () => {
  const { data: roles } = useQueryRoleIdMap()

  return useMemo((): TableColumn<{ firstRole: string; secondRole: string }>[] => {
    return [
      {
        Header: 'First role',
        id: 'firstRole',
        accessor: ({ firstRole }) => roles?.get(firstRole)?.name || firstRole,
        Cell: (cell: { row: { original: { firstRole: string } } }) => (
          <Table.Cell>
            <RoleLink id={cell.row.original.firstRole} />
          </Table.Cell>
        ),
      },
      {
        Header: 'Second role',
        id: 'secondRole',
        accessor: ({ secondRole }) => roles?.get(secondRole)?.name || secondRole,
        Cell: (cell: { row: { original: { secondRole: string } } }) => (
          <Table.Cell>
            <RoleLink id={cell.row.original.secondRole} />
          </Table.Cell>
        ),
      },
    ]
  }, [roles])
}
