import { Account, AccountCustomization } from 'api/idave/accounts'
import { MoreBar } from '@revolut/ui-kit'
import { useAccountActions } from './hooks/useAccountActions'

export const Actions = (props: {
  account: Account
  customization?: AccountCustomization
}) => {
  const { account, customization } = props

  const actions = useAccountActions({ account, customization })
  if (!actions.length) {
    return null
  }

  return (
    <MoreBar>
      {actions.map((action) => (
        <MoreBar.Action
          useIcon={action.useIcon}
          onClick={action.onClick}
          variant={action.variant}
          key={action.label}
        >
          {action.label}
        </MoreBar.Action>
      ))}
    </MoreBar>
  )
}
