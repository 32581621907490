import { EntitiesTable } from 'components/EntitiesTable'
import { ActionButton, FilterButton } from '@revolut/ui-kit'
import { noop } from 'lodash'
import { getColumns } from './List/columns'
import { CrossChecksTab } from '../types'

export const CrossChecksSkeleton = (props: { tab: CrossChecksTab }) => (
  <EntitiesTable
    loadingState="pending"
    entitiesTypeLabel="Requests"
    pluralForms={['request', 'requests']}
    data={[]}
    columns={getColumns()}
    searchValue=""
    onSearchChange={noop}
    renderFiltersLeft={() =>
      props.tab === 'all' ? <ActionButton pending>Show overdue</ActionButton> : null
    }
    renderFiltersRight={() =>
      props.tab === 'all' ? <FilterButton pending>Reviewer</FilterButton> : null
    }
  />
)
