import { AddEmployeeModifier } from 'api/dart/types'

export type GetModifiersParams = {
  userIds: string[]
  companyId: string
  justification?: string
  expirationDate?: number
}
export const getAccessModifiers = (params: GetModifiersParams): AddEmployeeModifier[] => {
  const { userIds, companyId, justification, expirationDate } = params
  return userIds.map((employeeId) => ({
    employeeId,
    accessModifier: {
      type: 'ALLOWED',
      expirationDate,
      justification,
      entityId: {
        id: companyId,
        type: 'COMPANY',
      },
    },
  }))
}
