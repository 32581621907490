import { Box, Button, Popup, Subheader, VStack } from '@revolut/ui-kit'
import { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { UserSelect } from 'components/Selects/UserSelect'
import { useEditPopup } from 'hooks/useEditPopup'
import { UserListItem } from 'api/idave/user'
import { CustomerCompanySelect } from 'components/Selects/CustomerCompanySelect'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { AddEmployeeModifier } from 'api/dart/types'
import { DART_PERMISSIONS } from 'security'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { getAccessModifiers } from './utils'

export type FormState = {
  employeeId?: string
  subjectId?: string
}

export function DirectRelationsCreatePopup(props: {
  showSuccessToast: (message: string) => void
}) {
  const { showSuccessToast } = props
  const queryClient = useQueryClient()
  const { closePopup } = useEditPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEES_UPDATE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEES_UPDATE_ACCESS_MODIFIER_CROSS_CHECK,
  )

  const { mutate: addModifier } = useMutation({
    mutationFn: (modifiers: AddEmployeeModifier[]) =>
      dartApi.employee.addModifiers({
        payload: modifiers,
        crossCheck: isCrossCheck,
      }),
    onMutate: () =>
      showLoadingPopup({
        title: 'Creating...',
      }),
    onSuccess: (response) => {
      const crosscheckId = getCrossCheckIdFromResponse(response)
      hideLoadingPopup()
      closePopup()
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        showSuccessToast('Access modification created')
        queryClient.invalidateQueries([QueryKey.DirectRelations])
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Access modification failed',
        error: err,
      })
    },
  })

  const [formState, setFormState] = useState<FormState>({
    employeeId: undefined,
    subjectId: undefined,
  })

  const disabledSubmit = !formState.subjectId || !formState.employeeId

  const onSubmit = useCallback(() => {
    const accessModifiers = getAccessModifiers(formState)
    if (accessModifiers) {
      addModifier(accessModifiers)
    }
  }, [addModifier, formState])

  const onEmployeeChange = useCallback(
    (user?: UserListItem | null) => {
      setFormState((state) => ({
        ...state,
        employeeId: user?.id,
      }))
    },
    [setFormState],
  )

  const onCustomerCompanyChange = useCallback(
    (company?: CustomerCompanyItem | null) =>
      setFormState((state) => ({
        ...state,
        subjectId: company?.id || undefined,
      })),
    [setFormState],
  )

  return (
    <>
      <VStack>
        <Box>
          <Subheader variant="nested">
            <Subheader.Title>Employee</Subheader.Title>
          </Subheader>

          <UserSelect
            itemId={formState.employeeId}
            label="Employee"
            onChange={onEmployeeChange}
            onlyActive
            clearable
          />
        </Box>

        <Box>
          <Subheader variant="nested">
            <Subheader.Title>Customer company</Subheader.Title>
          </Subheader>
          <CustomerCompanySelect
            label="Customer company"
            itemId={formState.subjectId}
            onChange={onCustomerCompanyChange}
            clearable
          />
        </Box>
      </VStack>

      <Popup.Actions horizontal>
        <Button elevated variant="secondary" onClick={closePopup}>
          Cancel
        </Button>
        <Button elevated variant="primary" onClick={onSubmit} disabled={disabledSubmit}>
          Create
        </Button>
      </Popup.Actions>
    </>
  )
}
