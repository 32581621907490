import { useQueryRevolutersDictionaries } from 'queries/sam/revoluters'
import { useMemo } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { RevolutersSpecialisation } from 'api/sam/revoluters'
import { SAM_PERMISSIONS } from 'security'
import { BaseMultiSelect, BaseMultiSelectWrapperProps } from './BaseMultiSelect'

const specializationToOption = (spec: RevolutersSpecialisation) => ({
  id: spec.id,
  value: spec,
  key: spec.id,
  label: `${spec.name} (${spec.id})`,
})

export const SpecialisationIdsSelect = (
  props: BaseMultiSelectWrapperProps<RevolutersSpecialisation>,
) => {
  const {
    data: revoluters,
    status: qs,
    fetchStatus: fs,
  } = useQueryRevolutersDictionaries()
  const queryResult = mergeQueryStatuses({ qs, fs })
  const items = useMemo(() => revoluters?.specialisations || [], [revoluters])

  return (
    <BaseMultiSelect
      items={items}
      queryResult={queryResult}
      itemToOption={specializationToOption}
      requiredPermission={SAM_PERMISSIONS.REVOLUTERS_STRUCTURE_VIEW_LIST}
      {...props}
    />
  )
}
