import { useCallback, useEffect, useState } from 'react'
import { useSideBox } from 'view/SideBox/SideBox'
import { TOXIC_ALERT_SIDE } from 'view/SideBox/hooks/useSideboxNavigation'
import { Flex, Token } from '@revolut/ui-kit'
import { ToxicIcon } from 'components/ToxicIcon'
import { Actions } from './components/Actions'
import { Body } from './components/Body'
import { Title } from './components/Title'
import { AlertDescriptor } from './types'

export const useToxicAlertPreview = () => {
  const { openSide } = useSideBox()
  const [toxicAlert, setAlert] = useState<AlertDescriptor | undefined>()
  const onClose = useCallback(() => {
    setAlert(undefined)
  }, [setAlert])

  useEffect(() => {
    if (!toxicAlert || !toxicAlert?.userId || !toxicAlert?.userId) {
      return undefined
    }

    const { toxicPermissionId, userId } = toxicAlert

    const queryParams = {
      type: TOXIC_ALERT_SIDE,
      id: toxicPermissionId,
      subjectId: userId,
    }

    return openSide({
      body: <Body toxicAlertDescriptor={toxicAlert} />,
      title: <Title toxicAlertDescriptor={toxicAlert} />,
      avatar: (
        <Flex
          color={Token.color.blue}
          borderRadius="50%"
          width="56px"
          height="56px"
          alignItems="center"
          justifyContent="center"
          bg={Token.color.actionBackground}
        >
          <ToxicIcon width="40px" height="40px" />
        </Flex>
      ),

      actions: <Actions toxicAlertDescriptor={toxicAlert} />,
      onClose,
      queryParams,
    })
  }, [toxicAlert, toxicAlert?.toxicPermissionId, toxicAlert?.userId, onClose, openSide])

  return {
    openToxicAlertSide: (toxicPermissionId?: string, userId?: string) => {
      setAlert({ toxicPermissionId, userId })
    },
  }
}
