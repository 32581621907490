import { ButtonVariant } from '@revolut/ui-kit'
import { noop } from 'lodash'
import { createContext, useCallback, useContext, useState } from 'react'

export type Popup = {
  message: React.ReactNode
  title: React.ReactNode
  confirmButtonText?: string
  confirmButtonVariant?: ButtonVariant
  cancelButtonText?: string
  onConfirmClick(): void
  onCancelClick?(): void
  onClose?(): void
}

export const ConfirmationPopupContext = createContext<{
  setPopup(popup: Popup): void
  closePopup(): void
  popup: Popup | undefined
}>({
  setPopup: noop,
  closePopup: noop,
  popup: undefined,
})

export const ConfirmationPopupContextProvider = (props: {
  children: React.ReactElement
}) => {
  const [popup, setPopup] = useState<Popup>()
  const closePopup = useCallback(() => setPopup(undefined), [setPopup])

  return (
    <ConfirmationPopupContext.Provider value={{ closePopup, setPopup, popup }}>
      {props.children}
    </ConfirmationPopupContext.Provider>
  )
}

export const useConfirmationPopup = () => {
  return useContext(ConfirmationPopupContext)
}
