import { Box, Subheader } from '@revolut/ui-kit'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'

export const CrossCheckDetailsSkeleton = () => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Details</Subheader.Title>
      </Subheader>
      <DetailsGroupSkeleton />
    </Box>
  )
}
