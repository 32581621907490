import { Role } from 'api/idave/roles'
import { SelectEntitiesTableOverlay } from 'components/EntitiesTable'
import { useEditPopup } from 'hooks/useEditPopup'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap, useQueryRoles } from 'queries/idave/roles'
import { useCallback } from 'react'
import { getLoadingState, splitSelectedAvailable } from 'components/EntitiesTable/utils'
import { User } from 'api/idave/user'
import { mergeQueryStatuses } from 'utils/query'
import { getColumns } from './columns'
import { UserRolesAddPopup } from '../UserRolesAddPopup'
import { UserAddRoleSubtitle } from './UserAddRoleSubtitle'
import { useRolesAdd } from './useRolesAdd'

type UserRolesAddProps = {
  user: User
  close: () => void
  addedRoleIds: string[]
}

export const UserRolesAdd = (props: UserRolesAddProps) => {
  const { close, user, addedRoleIds } = props
  const { closePopup, setPopup: setEditPopup } = useEditPopup()
  const { data: roles = [], status, fetchStatus } = useQueryRoles()
  const { data: rolesMap = new Map() } = useQueryRoleIdMap()
  const { data: clientsMap = new Map() } = useQueryClientIdMap()

  const { available } = splitSelectedAvailable(roles, addedRoleIds)
  const { submitRoles } = useRolesAdd({
    onSuccess: () => {
      closePopup()
      close()
    },
  })

  const openTimerForm = useCallback(
    (roleItems: Role[]) => {
      const confirm = (expiryDate?: number) => {
        submitRoles({
          userId: user.id,
          roles: roleItems.map((r) => r.id),
          expiryDate,
        })
      }

      setEditPopup({
        title: 'Assignment duration',
        content: <UserRolesAddPopup confirm={confirm} />,
        focusTrap: false,
      })
    },
    [setEditPopup, submitRoles, user.id],
  )

  return (
    <SelectEntitiesTableOverlay
      entitiesTypeLabel="Roles"
      pluralForms={['role', 'roles']}
      columns={getColumns(clientsMap, rolesMap)}
      entities={available}
      loadingState={getLoadingState(
        mergeQueryStatuses({ qs: status, fs: fetchStatus }),
        available.length,
      )}
      onClose={close}
      onSelect={openTimerForm}
      title="Add roles"
      SubtitleComponent={() => <UserAddRoleSubtitle user={user} />}
    />
  )
}
