import { Text, TextSkeleton } from '@revolut/ui-kit'
import { useQueryPermission } from 'queries/idave/permissions'
import { mergeQueryStatuses } from 'utils/query'

type Props = {
  permissionId: string
}

export const Title = ({ permissionId }: Props) => {
  const {
    data: permission,
    fetchStatus: fs,
    status: qs,
  } = useQueryPermission(permissionId)
  const status = mergeQueryStatuses({ fs, qs })

  const name = permission?.name || permissionId

  return status === 'loading' ? <TextSkeleton size={12} /> : <Text>{name}</Text>
}
