import { Table, TableColumn } from '@revolut/ui-kit'
import { UserTableCell, useUserTableCellAccessor } from 'components/UserTableCell'
import { PermissionLink } from 'components/Links/PermissionLink'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { formatDateTime } from 'utils/date'
import { useMemo } from 'react'
import { ToxicPairRow } from './types'

export const useColumns = (): TableColumn<ToxicPairRow>[] => {
  const getUserName = useUserTableCellAccessor()
  return useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'First permission',
        id: 'firstPermission',
        accessor: (row) => getUserName(row.owner),
        Cell: (cell: { row: { original: ToxicPairRow } }) => (
          <Table.Cell>
            <PermissionLink id={cell.row.original.firstPermissionId} />
          </Table.Cell>
        ),
        filter: 'includesValue',
        Filter: SelectItemFilter,
      },
      {
        Header: 'Second permission',
        id: 'secondPermission',
        accessor: (row) => row.secondPermission?.name || row.secondPermissionId,
        Cell: (cell: { row: { original: ToxicPairRow } }) => (
          <Table.Cell>
            <PermissionLink id={cell.row.original.secondPermissionId} />
          </Table.Cell>
        ),
        filter: 'includesValue',
        Filter: SelectItemFilter,
      },
      {
        Header: 'Owner',
        accessor: ({ owner }) => getUserName(owner),
        Cell: (cell: { row: { original: ToxicPairRow } }) => (
          <UserTableCell userId={cell.row.original.owner} />
        ),
        filter: 'includesValue',
        Filter: SelectItemFilter,
      },
      {
        Header: 'Justification',
        accessor: 'justification',
      },

      {
        Header: 'Review Date',
        accessor: 'reviewTS',
        Cell: (cell: { row: { original: ToxicPairRow } }) => (
          <Table.Cell>{formatDateTime(cell.row.original.reviewTS)}</Table.Cell>
        ),
      },
    ],
    [getUserName],
  )
}
