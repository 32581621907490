import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { SamPolicyResources } from 'view/Sam/components/SamPolicyResources'
import { FormState } from '../types'

type SamCreatePolicyResourcesProps = {
  policyResources: string[]
  policyName: string
  setPolicyResources: (resources: FormState['resources']) => void
}

export const SamCreatePolicyResources = (props: SamCreatePolicyResourcesProps) => {
  const { setPolicyResources, policyResources, policyName } = props
  const { hasPermission } = usePermissions()
  return (
    <SamPolicyResources
      key={policyResources.length}
      policyResources={policyResources}
      isPending={false}
      isActive
      policyName={policyName}
      addResources={setPolicyResources}
      deleteResources={setPolicyResources}
      showAddPermissions={hasPermission(SAM_PERMISSIONS.RESOURCES_VIEW_LIST)}
      showDeletePermissions
    />
  )
}
