import { Role } from 'api/idave/roles'
import { PolicyDetails } from 'api/sam/policies'
import { getRoleRelatedPolicies } from 'utils/policy/getRoleRelatedPolicies'
import { getPolicyAssignStatus } from 'utils/policy/getPolicyAssignStatus'

export const getPoliciesRows = ({
  roleId,
  roleMap,
  policies = [],
}: {
  roleId: string
  roleMap: Map<string, Role>
  policies?: PolicyDetails[]
}) => {
  if (!policies) {
    return []
  }
  const relatedPolicies = getRoleRelatedPolicies({
    roleId,
    roleMap,
    policies,
  })

  return relatedPolicies.map((policy) => ({
    name: policy.policyName,
    id: policy.policyId,
    ...getPolicyAssignStatus(policy),
  }))
}
