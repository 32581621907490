import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { randomKey } from 'utils/common/randomKey'
import { MAX_TABLE_SEARCH_LENGTH } from 'components/EntitiesTable/components/EntitiesListSearch'
import { UseTableNavigationParams } from '../types'
import { TABLE_SEARCH_PARAM_NAME } from '../const'
import { extractSearchParamFilters, extractSortsParamFilters } from '../utils'

export const useInitTableNavigation = <Entity extends {}>(
  navigationParams: UseTableNavigationParams<Entity>,
  setInit: (init: boolean) => void,
  init: boolean,
) => {
  const [queryParams] = useSearchParams()
  const {
    enableNavigation,
    loadingState,
    table,
    onSearchChange,
    resetSearch,
    initialFilters = [],
    initialSorts = [],
  } = navigationParams

  useEffect(() => {
    if (!enableNavigation || loadingState !== 'ready' || init || !table) {
      return
    }
    const searchValue = decodeURIComponent(
      queryParams.get(TABLE_SEARCH_PARAM_NAME) || '',
    ).slice(0, MAX_TABLE_SEARCH_LENGTH)

    if (searchValue) {
      onSearchChange?.(searchValue)
      resetSearch(randomKey())
    }

    const filters = extractSearchParamFilters(queryParams, table)
    table?.setAllFilters(filters)

    const sorts = extractSortsParamFilters(queryParams, table)
    table?.setSortBy(sorts)

    setInit(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, enableNavigation, loadingState, initialFilters, initialSorts])
}
