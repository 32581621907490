import { Box, HStack, Text, Token } from '@revolut/ui-kit'
import { ArrowHookRight } from '@revolut/icons'
import { Role } from 'api/idave/roles'
import { InternalLink } from 'components/Links/InternalLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { InheritanceTree } from './types'

export const RoleInheritanceTreeLayer = (props: {
  inheritanceTree: InheritanceTree
  isInitial?: boolean
  sourceRole: Role
  roleMap: Map<string, Role>
}) => {
  const { inheritanceTree, isInitial, sourceRole, roleMap } = props
  const { root, relations } = inheritanceTree
  const childs = relations[root]
  const isCurrentLayer = sourceRole.id === inheritanceTree.root

  return (
    <Box>
      <HStack space="s-8" align="center" mb="s-12">
        {!isInitial ? <ArrowHookRight size={16} color={Token.color.black_20} /> : null}
        {isCurrentLayer ? (
          <Text>{sourceRole.name}</Text>
        ) : (
          <InternalLink href={generatePath(Url.Role, { roleId: inheritanceTree.root })}>
            {roleMap.get(inheritanceTree.root)?.name || inheritanceTree.root}
          </InternalLink>
        )}
      </HStack>
      {childs?.length ? (
        <Box ml="s-16">
          {childs.map((id) => (
            <RoleInheritanceTreeLayer
              inheritanceTree={{
                root: id,
                relations,
              }}
              key={id}
              sourceRole={sourceRole}
              roleMap={roleMap}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  )
}
