import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { HStack, MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'

export const ToxicPairActions = (props: { toxicPairId: string }) => {
  const { toxicPairId } = props
  const navigate = useNavigate()
  const { setPopup, closePopup } = useConfirmationPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const { mutate: removeToxicPair } = useMutation({
    mutationFn: () => idaveApi.toxic.deleteToxicPair(toxicPairId),
    onSuccess: (_response) => {
      hideLoadingPopup()
      closePopup()
      navigate(Url.ToxicPairs)
      showSuccessToast('Toxic pair deleted')
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ToxicPairs],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ToxicAlerts],
        refetchType: 'all',
      })
    },
    onError: (error) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Toxic pair deletion failed', error })
      closePopup()
    },
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
  })

  const onDeleteClick = useCallback(
    () =>
      setPopup({
        title: 'Delete',
        message: 'Do you really want to delete this toxic pair?',
        confirmButtonText: 'Delete',
        onConfirmClick: removeToxicPair,
      }),
    [removeToxicPair, setPopup],
  )

  return (
    <PermissionsCheck somePermissions={[IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_DELETE]}>
      <HStack space="s-16">
        <MoreBar>
          <PermissionsCheck permission={IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_DELETE}>
            <MoreBar.Action useIcon="Delete" variant="negative" onClick={onDeleteClick}>
              Delete
            </MoreBar.Action>
          </PermissionsCheck>
        </MoreBar>
      </HStack>
    </PermissionsCheck>
  )
}
