import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { RoleRow } from './types'

export const COLUMNS: TableColumn<RoleRow>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Parent',
    accessor: 'parent',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
