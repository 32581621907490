import { memo, useCallback, useMemo } from 'react'
import {
  Avatar,
  Caption,
  Ellipsis,
  HStack,
  SelectOptionItemType,
  Text,
  Token,
  VStack,
  abbreviate,
} from '@revolut/ui-kit'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryUsers } from 'queries/idave/users'
import { mergeQueryStatuses } from 'utils/query'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { useMapify } from 'hooks/useMapify'
import {
  BaseSelect,
  BaseSelectProps,
  BaseSelectWrapperProps,
} from 'components/Selects/BaseSelect'
import { IDAVE_PERMISSIONS } from 'security'
import { NamedUser } from './types'
import { getItems, peopleOpsItemToOption } from './utils'

export type UserSelectProps = {
  excludeIds?: string[]
  usePeopleOpsId?: boolean
  onlyActive?: boolean
} & BaseSelectWrapperProps<NamedUser>

const UserOption = (option: SelectOptionItemType<NamedUser>) => (
  <HStack space="s-12" align="center">
    <Avatar
      variant="brand"
      uuid={option.value.id}
      label={abbreviate(option.value.name)}
      image={option.value.avatarUrl}
    />
    <VStack>
      <Text use="p">{option.value.name}</Text>
      <Caption color={Token.color.greyTone50}>
        <Ellipsis>{option.value.email}</Ellipsis>
      </Caption>
    </VStack>
  </HStack>
)

const PeopleOpsUserOption = (option: SelectOptionItemType<NamedUser>) => (
  <HStack space="s-12" align="center">
    <Avatar
      variant="brand"
      uuid={option.value.id}
      label={abbreviate(option.value.name)}
      image={option.value.avatarUrl}
    />
    <VStack>
      <Text use="p">{`${option.value.name} (${option.value.externalRef?.reference})`}</Text>
      <Caption color={Token.color.greyTone50}>
        <Ellipsis>{option.value.email}</Ellipsis>
      </Caption>
    </VStack>
  </HStack>
)

export const UserSelect = memo(
  ({
    excludeIds = [],
    usePeopleOpsId = false,
    onlyActive = false,
    itemId,
    ...selectInputProps
  }: UserSelectProps) => {
    const excludeIdsMap = useMemo(() => toPresenceMap(excludeIds), [excludeIds])

    const { data: users = [], status: usersS, fetchStatus: usersFS } = useQueryUsers()
    const {
      data: avatars,
      status: avatarsS,
      fetchStatus: avatarsFS,
    } = useQueryMappedAvatars()

    const queryResult = mergeQueryStatuses(
      { qs: usersS, fs: usersFS },
      { qs: avatarsS, fs: avatarsFS },
    )

    const items = useMemo(
      () => getItems({ users, avatars, excludeIdsMap, onlyActive, usePeopleOpsId }),
      [users, avatars, excludeIdsMap, onlyActive, usePeopleOpsId],
    )
    const selectedItem = useMemo(
      () =>
        items.find((item) =>
          usePeopleOpsId ? item.externalRef?.reference === itemId : item.id === itemId,
        ),
      [itemId, items, usePeopleOpsId],
    )

    const renderPrefix = useCallback(() => {
      if (!selectedItem) return null

      return (
        <Avatar
          variant="brand"
          uuid={selectedItem.id}
          label={abbreviate(selectedItem.name)}
          image={selectedItem.avatarUrl}
        />
      )
    }, [selectedItem])

    const Component = usePeopleOpsId ? InnerPeopleOpsUserSelect : BaseSelect
    const OptionComponent = usePeopleOpsId ? PeopleOpsUserOption : UserOption

    return (
      <Component
        {...selectInputProps}
        requiredPermission={IDAVE_PERMISSIONS.USERS_VIEW_LIST}
        renderOption={OptionComponent}
        items={items}
        itemId={itemId}
        queryResult={queryResult}
        renderPrefix={renderPrefix}
      />
    )
  },
)

/**
 * Render select with peopleOpsIds separately to prevent unecessary user list mapping
 */
const InnerPeopleOpsUserSelect = (props: BaseSelectProps<NamedUser>) => {
  const peopleopsIdMap = useMapify(props.items, (i) => i.externalRef?.reference)
  const itemId = props.itemId ? peopleopsIdMap.get(props.itemId)?.id : undefined

  return <BaseSelect {...props} itemId={itemId} itemToOption={peopleOpsItemToOption} />
}
