import { CrossCheckDetail } from 'api/types/crossCheck'
import { ChangeType, DetailRow } from '../../types'
import { DetailParams } from './types'
import { getForecasted } from './utils'
import { CREATE_NAMES } from '../../../ChangeSwitcher/utils'

export const getOwnerGroup = ({
  entity,
  crossCheck,
}: DetailParams): DetailRow | undefined => {
  if (
    (crossCheck.entityType !== 'Role' && crossCheck.entityType !== 'Client') ||
    entity.type === 'AccessPolicy' ||
    entity.type === 'User' ||
    entity.type === 'EmployeeCompany' ||
    entity.type === 'CustomerCompany' ||
    entity.type === 'ServiceAccount'
  ) {
    return undefined
  }

  const requested = crossCheck.executableParams['Owner Group']?.value.id
  const current = entity.value?.ownerGroupId
  const changeType = getChangeType({
    current,
    requested,
    crossCheck,
  })

  return {
    title: 'Owner group',
    value: {
      current,
      requested,
      forecasted: getForecasted({ changeType, current, requested }),
      type: 'Group',
    },
    changeType,
  }
}

const getChangeType = (params: {
  requested?: string
  current?: string
  crossCheck: CrossCheckDetail
}): ChangeType => {
  const { requested, crossCheck, current } = params
  if (CREATE_NAMES.includes(crossCheck.executableName)) {
    return 'EntityCreation'
  }
  if (!requested || requested === current) {
    return 'NoChange'
  }

  return 'Edited'
}
