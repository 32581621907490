import LinkifyIt, { LinkifyIt as TLinkifyIt } from 'linkify-it'
import { createContext, useContext } from 'react'
import { MENTION_COMMAND, STATUS_COMMAND } from './constants'
import { validateStatusCommand, validateMention } from './utils'

const LinkifyParser = LinkifyIt()
  .add('ftp:', null)
  .add('mailto:', null)
  .add(STATUS_COMMAND, {
    validate: validateStatusCommand,
  })
  .add(MENTION_COMMAND, {
    validate: validateMention,
  })

const LinkifyContext = createContext<TLinkifyIt>(LinkifyParser)

export const __test_only = {
  LinkifyParser,
}

/**
 * Pls, use text tokenizer probably you need to tokenize text rather than
 * extract link-like tokens
 *
 * @returns linkify instance with project settings
 */
export const useLinkify = () => {
  return useContext(LinkifyContext)
}
