import { Table, TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { UserTableCell, useUserTableCellAccessor } from 'components/UserTableCell'
import { useMemo } from 'react'
import { ToxicPair } from 'api/idave/toxic'
import { ToxicPairLink } from 'components/Links/ToxicPairLink'
import { AlertRow } from './types'

export const useColumns = (params: {
  isLoading: boolean
  toxicPairMap?: Map<string, ToxicPair>
}): TableColumn<AlertRow>[] => {
  const { isLoading, toxicPairMap } = params
  const getUserName = useUserTableCellAccessor()

  return useMemo(() => {
    const loadingFilter = isLoading
      ? undefined
      : {
          filter: 'includesValue' as const,
          Filter: SelectItemFilter,
        }

    const loadingCell = isLoading
      ? {
          Cell: () => <TextSkeleton size={16} />,
        }
      : undefined

    return [
      {
        Header: 'Toxic pair',
        accessor: ({ toxicPermissionId }) =>
          toxicPairMap?.get(toxicPermissionId)?.name || toxicPermissionId,
        Cell: (cell: { row: { original: AlertRow } }) => (
          <Table.Cell>
            <ToxicPairLink id={cell.row.original.toxicPermissionId} />
          </Table.Cell>
        ),
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'User name',
        accessor: ({ userId }) => getUserName(userId),
        Cell: (cell: { row: { original: AlertRow } }) => (
          <UserTableCell userId={cell.row.original.userId} />
        ),
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'Team',
        accessor: 'team',
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'Spec',
        accessor: 'spec',
        ...loadingCell,
        ...loadingFilter,
      },
    ]
  }, [isLoading, getUserName, toxicPairMap])
}
