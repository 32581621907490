import { Avatar, Item, abbreviate } from '@revolut/ui-kit'
import { UnstyledLink } from 'components/Links/UnstyledLink'
import { formatLongDateTime } from 'utils/date'
import { capitalizeFirst } from 'utils/string'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { CrossCheckEvent } from '../../../types'

type Props = {
  event: CrossCheckEvent
}
export const EventUserItem = ({ event }: Props) => {
  const { name, ts, userId, photoUrl, avatarBadge, action } = event
  const userProfile = event.userId
    ? generatePath(Url.UserProfile, { userId: event.userId })
    : undefined

  return (
    <Item>
      <Item.Avatar>
        <UnstyledLink href={userProfile}>
          <Avatar size={40} uuid={userId} label={abbreviate(name)} image={photoUrl}>
            {avatarBadge ? (
              <Avatar.Badge
                bg={avatarBadge.color}
                position="bottom-right"
                useIcon={avatarBadge.icon}
              />
            ) : null}
          </Avatar>
        </UnstyledLink>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <UnstyledLink href={userProfile}>{name}</UnstyledLink>
        </Item.Title>
        <Item.Description>{formatLongDateTime(ts)}</Item.Description>
      </Item.Content>
      {action && (
        <Item.Side>
          <Item.Value color={avatarBadge?.color}>{capitalizeFirst(action)}</Item.Value>
        </Item.Side>
      )}
    </Item>
  )
}
