import {
  Table as BaseTable,
  Box,
  Subheader,
  TableColumn,
  TableData,
} from '@revolut/ui-kit'

export const Table = <T extends TableData>(props: {
  title: string
  data: T[]
  columns: TableColumn<T>[]
}) => {
  const { title, data, columns } = props

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
      <BaseTable columns={columns} data={data} disableSortBy />
    </Box>
  )
}
