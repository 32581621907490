import { useQueryOrgs } from 'queries/idave/orgs'
import { mergeQueryStatuses } from 'utils/query'
import { OrganisationData } from 'api/idave/organisations'
import { IDAVE_PERMISSIONS } from 'security'
import { BaseSelect, BaseSelectWrapperProps } from './BaseSelect'

export const OrgSelect = (props: BaseSelectWrapperProps<OrganisationData>) => {
  const { data: organisations = [], status: qs, fetchStatus: fs } = useQueryOrgs()
  const queryResult = mergeQueryStatuses({ qs, fs })

  return (
    <BaseSelect
      items={organisations}
      requiredPermission={IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST}
      queryResult={queryResult}
      {...props}
    />
  )
}
