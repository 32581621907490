export const FILTER_SUPPORT = [
  {
    id: 'state',
    value: ['Waiting for support'],
  },
]

export const SORT_DATE_ASC = [
  {
    id: 'updated',
    desc: false,
  },
]
