import { TableColumn } from '@revolut/ui-kit'
import { Group } from 'api/idave/groups'
import { GroupOwnersCell, useGroupOwnersCellAccessor } from 'components/GroupOwnersCell'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { useMemo } from 'react'

export const useColumns = () => {
  const getGroupOwnerAccessor = useGroupOwnersCellAccessor()

  const columns = useMemo(
    (): TableColumn<Group>[] => [
      {
        Header: 'Name',
        accessor: 'name',
        filter: 'includesValue',
        Filter: SelectItemFilter,
        width: 2,
      },
      {
        Header: 'Type',
        accessor: 'type',
        filter: 'includesValue',
        Filter: SelectItemFilter,
        width: 1,
      },
      {
        Header: 'Owner',
        accessor: ({ id }) => getGroupOwnerAccessor(id),
        Cell: (params: { row: { original: Group } }) => (
          <GroupOwnersCell groupId={params.row.original.id} />
        ),
        filter: 'includesValue',
        Filter: SelectItemFilter,
        width: 1,
      },
    ],
    [getGroupOwnerAccessor],
  )

  return columns
}
