import { Text, TextSkeleton } from '@revolut/ui-kit'
import { useQueryRole } from 'queries/idave/roles'
import { mergeQueryStatuses } from 'utils/query'

type Props = {
  roleId: string
}

export const Title = ({ roleId }: Props) => {
  const { data: role, fetchStatus: fs, status: qs } = useQueryRole({ roleId })
  const status = mergeQueryStatuses({ fs, qs })

  const name = role?.name || roleId

  return status === 'loading' ? <TextSkeleton size={12} /> : <Text>{name}</Text>
}
