import axios, { AxiosPromise } from 'axios'

export type RevolutersTeam = {
  id: string
  name: string
  ownerId: string
  departmentId: string
  active: boolean
}

export type RevolutersDepartment = {
  id: string
  name: string
  ownerId: string
  active: boolean
}

export type RevolutersSpecialisation = {
  id: string
  name: string
  jobRoleId: string
  ownerId: string
  active: boolean
}

export type RevolutersJobRole = {
  id: string
  name: string
  ownerId: string
  functionId: string
  active: boolean
}

export type RevolutersFunction = {
  id: string
  name: string
  ownerId: string
  active: boolean
}

export type RevolutersDictionary = {
  teams: RevolutersTeam[]
  departments: RevolutersDepartment[]
  specialisations: RevolutersSpecialisation[]
  jobRoles: RevolutersJobRole[]
  functions: RevolutersFunction[]
}

export type RevolutersEmployeeStatus =
  | 'active'
  | 'terminated'
  | 'onboarding'
  | 'not_hired'
  | 'hired'
  | 'inactive'
  | 'gardening_leave'
  | 'pending'

export type RevolutersEmployee = {
  departmentId: string
  employeeType: 'internal' | 'external'
  iDaveUserId: string
  positionId: string
  revolutersId: string
  seniorityId: string
  specialisationId: string
  teamId: string
  status: RevolutersEmployeeStatus
}

export type RevolutersEmployees = {
  employees: RevolutersEmployee[]
}

export type RevolutersAvatarSizes = {
  imageSmall: string
  imageMedium: string
}
export type RevolutersAvatar = {
  revolutersId: string
} & RevolutersAvatarSizes

const dropGravatarAvatars = (avatars: RevolutersAvatar[]) =>
  avatars.filter(
    (avatar) =>
      !avatar.imageMedium.includes('secure.gravatar.com') &&
      !avatar.imageSmall.includes('secure.gravatar.com'),
  )

export const revoluters = {
  getDictionaries: (): AxiosPromise<RevolutersDictionary> =>
    axios.get('/sam-api/api/revoluters-structure/'),

  getEmployees: (): AxiosPromise<RevolutersEmployees> =>
    axios.get('/sam-api/api/revoluters/employees'),

  getAvatars: () =>
    axios
      .get('/sam-api/api/revoluters/avatars')
      .then((response) => dropGravatarAvatars(response.data?.data || [])),
}
