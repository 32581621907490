import { Page } from '@revolut/ui-kit'
import { Url } from 'routing'
import { useQueryGroups } from 'queries/idave/groups'
import { QuerySwitch } from 'components/QuerySwitch'
import { useNavigate } from 'react-router'

import { GroupsTable, GroupsTableSkeleton } from './components/GroupsTable'

export const Groups = () => {
  const navigate = useNavigate()

  const { data: groups = [], status: groupsS, fetchStatus: groupsFS } = useQueryGroups()

  return (
    <>
      <Page.Header onBack={() => navigate(Url.System)}>Groups</Page.Header>
      <Page.Main>
        <QuerySwitch
          required={[{ qs: groupsS, fs: groupsFS }]}
          data={groups}
          idleText="Looks like you don't have sufficient permissions to view groups list"
          renderLoading={() => <GroupsTableSkeleton />}
          renderSuccess={(data) => <GroupsTable groups={data.data} />}
        />
      </Page.Main>
    </>
  )
}
