import axios from 'axios'
import { ActionResponse } from 'api/types/actionResponse'
import { AccessModifier } from './types'

export type CustomerCompanyItem = {
  id: string
  name: string
  region: string
  serviceType:
    | 'BANKING'
    | 'ADS'
    | 'TRADING'
    | 'INVESTMENT_MANAGEMENT'
    | 'INSURANCE'
    | 'CRYPTO_RAMP'
    | 'CRYPTO'
}

export type CustomerCompany = CustomerCompanyItem & {
  allowedCompanies: Array<{
    id: string
    allowedAccessModifier: AccessModifier
  }>
  allowedEmployees: Array<{
    id: string
    allowedAccessModifier: AccessModifier
  }>
  owners: string[]
  approvers: string[]
}

export const customerCompany = {
  getCustomerCompanies: () =>
    axios.get<CustomerCompanyItem[]>('/dart-api/customer-companies'),

  getCustomerCompany: ({ id }: { id: string }) =>
    axios.get<CustomerCompany>(`/dart-api/customer-companies/${id}`),

  addCustomerCompanyOwner: ({ id, ownerId }: { id: string; ownerId: string }) =>
    axios.post<ActionResponse>(`/dart-api/customer-companies/${id}/owner/${ownerId}`),

  deleteCustomerCompanyOwner: ({ id, ownerId }: { id: string; ownerId: string }) =>
    axios.delete<ActionResponse>(`/dart-api/customer-companies/${id}/owner/${ownerId}`),

  addCustomerCompanyApprover: ({ id, approverId }: { id: string; approverId: string }) =>
    axios.post(`/dart-api/customer-companies/${id}/approver/${approverId}`),

  deleteCustomerCompanyApprover: ({
    id,
    approverId,
  }: {
    id: string
    approverId: string
  }) => axios.delete(`/dart-api/customer-companies/${id}/approver/${approverId}`),
}
