import { Flex } from '@revolut/ui-kit'
import { useSideBox } from 'view/SideBox/SideBox'
import { useCallback } from 'react'
import { SideboxOpenParams } from 'view/SideBox/types'
import { HeaderAction } from './components/HeaderAction'
import { useHeaderActionsValues } from './hooks'
import { InfoAction } from './types'

export const useHeaderActions = () => {
  const actions = useHeaderActionsValues()
  const { openSide, ready } = useSideBox()

  const makeHeaderActions = useCallback(
    () => <HeaderActions actions={actions} openSide={openSide} />,
    [actions, openSide],
  )

  if (!actions.length || !ready) {
    return undefined
  }

  return makeHeaderActions
}

type Props = {
  actions: InfoAction[]
  openSide: (values: SideboxOpenParams) => void
}

export const HeaderActions = ({ actions, openSide }: Props) => {
  return (
    <Flex>
      {actions.map((action) => {
        return <HeaderAction key={action.type} action={action} openSide={openSide} />
      })}
    </Flex>
  )
}
