import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { isQueryParamsEqual } from 'utils/url/isQueryParamsEqual'
import { TableNavigationParams } from './types'
import { getNewTableParams } from './utils'
import { MAX_QUERY_LENGTH } from '../useTableNavigation/const'

/**
 * A hook for external usage, allows you navigate table to specific state
 * just use returned navigate callback and reset table like
 *
 * const {navigateTableState} = useNavigateTableState()
 * const [key, setKey] = useState<number>()
 *
 * const onClick = useCallback((sorts: TableSortingRule<Entity>[]) => {
 *  navigateTableState({sorts})
 *  setKey(randomKey)
 * }, [navigate, setKey])
 *
 *
 * return <EntitiesTable
 *   ...
 *   key={key}
 *   enableNavigation
 * />
 */
export const useNavigateTableState = <Entity extends { id: string }>() => {
  const [queryParams, setQueryParams] = useSearchParams()

  const navigateTableState = useCallback(
    (params: TableNavigationParams<Entity>) => {
      const newParams = getNewTableParams(queryParams, params)
      if (
        !isQueryParamsEqual(newParams, queryParams) &&
        newParams.toString().length <= MAX_QUERY_LENGTH
      ) {
        setQueryParams(newParams, { replace: true })
      }
    },
    [setQueryParams, queryParams],
  )

  const resetTableState = useCallback(() => navigateTableState({}), [navigateTableState])

  return {
    navigateTableState,
    resetTableState,
  }
}
