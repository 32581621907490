import { MoreBar } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { EntitiesTable } from 'components/EntitiesTable'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryGroupIdMap } from 'queries/idave/groups'
import { useQueryClients } from 'queries/idave/clients'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { waitForAll } from 'utils/query'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { CREATE_CLIENT } from '../permissions'
import { getColumns } from './columns'

export const ClientList = () => {
  const {
    data = [],
    status: clientStatus,
    fetchStatus: clientFetchStatus,
  } = useQueryClients()
  const {
    data: groupMap = new Map(),
    status: groupsStatus,
    fetchStatus: groupsFetchStatus,
  } = useQueryGroupIdMap()

  const navigate = useNavigate()
  const onRowClick = useCallback(
    (client: Client) => {
      navigate(
        generatePath(Url.Client, {
          clientId: client.id,
        }),
      )
    },
    [navigate],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: data,
  })
  const onClientCreateClick = useCallback(() => navigate(Url.ClientCreate), [navigate])
  const columns = useMemo(() => getColumns(groupMap), [groupMap])
  const status = waitForAll(
    [
      {
        qs: clientStatus,
        fs: clientFetchStatus,
      },
    ],
    [
      {
        qs: groupsStatus,
        fs: groupsFetchStatus,
      },
    ],
  )

  const getRowLink = useCallback(
    (client: Client) => generatePath(Url.Client, { clientId: client.id }),
    [],
  )

  return (
    <EntitiesTable
      enableNavigation
      totalCount={data.length}
      entitiesTypeLabel="Applications"
      pluralForms={['application', 'applications']}
      loadingState={getLoadingState(status, data.length)}
      data={searched}
      columns={columns}
      onRowClick={onRowClick}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
      getRowLink={getRowLink}
      renderActions={() => (
        <PermissionsCheck somePermissions={CREATE_CLIENT}>
          <MoreBar.Action useIcon="Plus" onClick={onClientCreateClick}>
            Create new
          </MoreBar.Action>
        </PermissionsCheck>
      )}
    />
  )
}
