import { mapify, notNullableMap } from 'utils/array'
import { pluralForm } from 'utils/string'
import { AddCompanyModifier } from 'api/dart/types'
import { CustomerCompany } from '../../types'

export const getActionDisability = (activeIds: string[], entities: CustomerCompany[]) => {
  if (!activeIds.length) {
    return { disabledAdd: true, disabledDelete: true }
  }

  const entityMap = mapify(entities, (e) => e.id)
  const isAllowed = !!entityMap.get(activeIds[0])?.accessModifierId
  const isAllSame = activeIds.every(
    (id) => !!entityMap.get(id)?.accessModifierId === isAllowed,
  )

  return {
    disabledAdd: !isAllSame || isAllowed,
    disabledDelete: !isAllSame || !isAllowed,
  }
}

export const getModifierIds = (activeIds: string[], entities: CustomerCompany[]) => {
  const entityMap = mapify(entities, (e) => e.id)
  return notNullableMap(activeIds, (id) => entityMap.get(id)?.accessModifierId)
}

export const getAddPopup = (companyCount: number) => ({
  title: pluralForm(companyCount, [
    'Are you sure you want to allow access to selected customer company?',
    'Are you sure you want to allow access to selected customer companies?',
  ]),
  message: pluralForm(companyCount, [
    'Users of this employee company will have access to selected customer company data.',
    'Users of this employee company will have access to selected customer companies data.',
  ]),
  confirmButtonText: 'Allow',
})

export const getRevokePopup = (companyCount: number) => ({
  title: pluralForm(companyCount, [
    'Are you sure you want to revoke access to selected customer company?',
    'Are you sure you want to revoke access to selected customer companies?',
  ]),
  message: pluralForm(companyCount, [
    'Users of this employee company will not have access to selected customer company data.',
    'Users of this employee company will not have access to selected customer companies data.',
  ]),
  confirmButtonText: 'Revoke',
})

export const getEmployeeCompanyModifiers = (params: {
  customerCompanyIds: string[]
  employeeCompanyId: string
}): AddCompanyModifier[] => {
  const { customerCompanyIds, employeeCompanyId } = params

  return customerCompanyIds.map((customerCompanyId) => ({
    employeeCompanyId,
    accessModifier: {
      type: 'ALLOWED',
      entityId: {
        type: 'COMPANY',
        id: customerCompanyId,
      },
    },
  }))
}
