import { Table, Text, textChain } from '@revolut/ui-kit'
import { Group } from 'api/idave/groups'
import { StatusText } from './StatusText'

export const GroupCell = (props: {
  ownerGroupId?: string
  groupMap: Map<string, Group>
}) => {
  const { ownerGroupId, groupMap } = props

  const ownerStatus = !ownerGroupId || !groupMap.get(ownerGroupId) ? 'warning' : undefined
  const text = getGroupCellText(props)

  return (
    <Table.Cell>
      {ownerStatus ? (
        <StatusText fontSize="caption" fontWeight="400" level={ownerStatus}>
          {text}
        </StatusText>
      ) : (
        <Text>{text}</Text>
      )}
    </Table.Cell>
  )
}

export const getGroupCellText = (params: {
  ownerGroupId?: string
  groupMap: Map<string, Group>
}) => {
  const { ownerGroupId, groupMap } = params
  return ownerGroupId
    ? groupMap.get(ownerGroupId)?.name || textChain('No owner', 'Group deleted')
    : 'No owner'
}
