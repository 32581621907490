import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import useLazyQuery from 'queries/useLazyQuery'
import { makeMappedKeyDataHook } from 'queries/utils'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'

export const useQueryGroup = (groupId?: string) => {
  const { hasPermission } = usePermissions()
  return useLazyQuery({
    params: groupId,
    queryKey: [QueryKey.Group, groupId],
    enabled: hasPermission(IDAVE_PERMISSIONS.GROUPS_VIEW_DETAILS),
    fetchFn: idaveApi.groups.group,
  })
}

export const useQueryGroups = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Groups],
    queryFn: () => idaveApi.groups.groups(),
    select: (response) => response.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.GROUPS_VIEW_LIST),
  })
}

export const useQueryGroupIdMap = makeMappedKeyDataHook(useQueryGroups, 'id')
