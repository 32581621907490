import { Button, Input, Popup, VStack } from '@revolut/ui-kit'
import { PasswordChange } from 'api/idave/currentUser'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback, useState } from 'react'

type UserChangePasswordPopupProps = {
  close: () => void
  submit: (passwordChange: PasswordChange) => void
}

export const UserChangePasswordPopup = (props: UserChangePasswordPopupProps) => {
  const { submit } = props
  const [currentPassword, setCurrent] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const isSubmitDisabled = !currentPassword || !newPassword || !repeatNewPassword
  const onPasswordChange = useInputStringChange(setCurrent)
  const onNewPasswordChange = useInputStringChange(setNewPassword)
  const onRepeatPasswordChange = useInputStringChange(setRepeatNewPassword)

  const onSubmit = useCallback(
    () =>
      submit({
        currentPassword,
        newPassword,
        repeatNewPassword,
      }),
    [currentPassword, newPassword, repeatNewPassword, submit],
  )

  return (
    <>
      <VStack space="s-16">
        <Input type="password" label="Current Password" onChange={onPasswordChange} />
        <Input type="password" label="New Password" onChange={onNewPasswordChange} />
        <Input
          type="password"
          label="Repeat New Password"
          onChange={onRepeatPasswordChange}
        />
      </VStack>

      <Popup.Actions>
        <Button elevated disabled={isSubmitDisabled} onClick={onSubmit}>
          Change Password
        </Button>
      </Popup.Actions>
    </>
  )
}
