import { getTeamIds } from 'view/Sam/utils'
import { DetailRow } from '../../../types'
import { DetailParams } from '../types'
import { getNormalizedValues } from '../utils'
import { getPolicySubjectChangeType } from './utils'

export const getTeams = ({ entity, crossCheck }: DetailParams): DetailRow | undefined => {
  const requested =
    crossCheck.executableName === 'CreatePolicy'
      ? crossCheck.executableParams.subject_params?.subject_team_ids
      : undefined

  const current =
    entity.type === 'AccessPolicy' && entity.value
      ? getTeamIds(entity.value.subject)
      : undefined
  const value = requested || current
  return {
    title: 'Teams',
    value: {
      ...getNormalizedValues(value, value),
      type: 'Teams',
    },
    changeType: getPolicySubjectChangeType(crossCheck),
    isContext: true,
  }
}
