import { SAMLog } from 'api/sam/auditLogs'
import { assertNonReachable } from 'utils/error'

export const getLogAuthor = (log: SAMLog) => {
  switch (log.action) {
    case 'ACCESS_POLICY_CREATED':
      return log.data.policy_author
    case 'ACCESS_POLICY_RESOURCES_SET':
    case 'ACCESS_POLICY_UPDATED':
      return log.data.updated_by
    case 'ACCESS_POLICY_DELETED':
      return log.data.deleted_by
    default:
      return assertNonReachable(log)
  }
}
