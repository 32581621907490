import { uniqBy } from 'lodash'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { RolePermission } from '../../types'

export const getDataLabels = (
  totalCount: number,
  permissionRows: RolePermission[],
  roleId: string,
) => {
  const inheritedDataLabel = {
    value: uniqBy(
      permissionRows.filter((p) => p.ownerRoleId !== roleId),
      'permissionId',
    ).length,
    label: 'Inherited',
    color: 'grey-tone-20',
  }

  return totalCount ? [inheritedDataLabel] : undefined
}

export const getRowLink = (permission: RolePermission) =>
  generatePath(Url.Permission, { permissionId: permission.permissionId })
