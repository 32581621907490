import { DataMaps } from 'view/AuditLogs/types'
import { Table, getEntityRows } from '../../../Table'
import { COLUMNS } from './columns'

export const Permissions = (props: {
  title: string
  permissionsIds?: string[]
  dataMaps: DataMaps
}) => {
  const { title, permissionsIds, dataMaps } = props
  if (!permissionsIds) {
    return null
  }

  const permissionsRows = getEntityRows(permissionsIds, 'Permission', dataMaps).map(
    ({ id, ...row }) => {
      const clientId = dataMaps.permissionsMap?.get(id)?.client
      const client = (clientId && dataMaps.clientsMap?.get(clientId)?.name) || '-'
      return { ...row, client }
    },
  )

  if (!permissionsRows || !permissionsRows.length) {
    return null
  }

  return <Table title={title} data={permissionsRows} columns={COLUMNS} />
}
