import { TabBar } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { getTabTitle } from './utils'
import { CrossCheckValueTab } from '../../types'

type CrosscheckTabSwitcherProps = {
  onChange: (status: CrossCheckValueTab) => void
  currentTab: CrossCheckValueTab
  tabs: CrossCheckValueTab[]
  counters?: Partial<Record<CrossCheckValueTab, number>>
  isFinished: boolean
}
export const CrosscheckTabSwitcher = ({
  onChange,
  currentTab,
  tabs,
  counters,
  isFinished,
}: CrosscheckTabSwitcherProps) => {
  const onChangeHandler = useCallback(
    (value?: CrossCheckValueTab | null) => {
      value && onChange(value)
    },
    [onChange],
  )

  if (tabs.length <= 1) {
    return null
  }

  return (
    <TabBar value={currentTab} onChange={onChangeHandler} variant="segmented fit">
      {tabs.map((tab) => (
        <TabBar.Item to={tab} key={tab}>
          {getTabTitle({
            count: counters?.[tab],
            tab,
            isFinished,
          })}
        </TabBar.Item>
      ))}
    </TabBar>
  )
}
