import axios, { AxiosPromise } from 'axios'
import { ActionResponse } from 'api/types/actionResponse'
import { UserStates } from './user'

export type PasswordChange = {
  currentPassword: string
  newPassword: string
  repeatNewPassword: string
}

export type WhoAmI = {
  id: string
  firstName: string
  lastName: string
  username: string
  email: string
  roles: string[]
  permissions: string[]
  organisation?: string
}

export type CurrentUserInfo = {
  firstName: string
  lastName: string
  username: string
  email: string
  id: string
  joined: string
  status: UserStates
}

const currentUser = {
  changePassword: (params: PasswordChange): AxiosPromise<ActionResponse> =>
    axios.post('/api/users/password', params),

  whoami: (): AxiosPromise<WhoAmI> => axios.get('/api/whoami'),
}

export default currentUser
