import { VStack } from '@revolut/ui-kit'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryResource } from 'queries/sam/resources'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { SamResource } from 'api/sam/resources'
import { DetailsGroup } from 'components/DetailsGroup'
import { CopyableText } from 'components/CopyableText'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'
import { getResourceLink } from 'utils/resource/getResourceLink'
import { capitalizeFirst } from 'utils/string'
import { ResourceLink } from 'components/Links/ResourceLink'
import { GroupName } from 'components/GroupName'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const Body = ({ resourceId }: { resourceId: string }) => {
  const { data, fetchStatus: fs, status: qs } = useQueryResource(resourceId)

  return (
    <QuerySwitch
      data={data}
      required={[{ qs, fs }]}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view resource" />
      )}
      renderLoading={() => <DetailsGroupSkeleton />}
      renderSuccess={({ data: resource }) => <Inner resource={resource} />}
    />
  )
}

type InnerProps = {
  resource: SamResource
}

const Inner = ({ resource }: InnerProps) => {
  const resourceLink = getResourceLink(resource)
  return (
    <VStack space="s-24">
      <DetailsGroup
        rows={[
          ['Name', resource.resourceName],
          ['Type', getResourceTypeLabel(resource.resourceType)],
          [
            'ID',
            <CopyableText value={resource.resourceId} key="id">
              {resource.resourceId}
            </CopyableText>,
          ],
          resourceLink && [
            capitalizeFirst(resourceLink.label),
            <ResourceLink resource={resource} key="link" />,
          ],
          [
            'Owner group',
            resource.ownerGroupId ? (
              <GroupName id={resource.ownerGroupId} />
            ) : (
              EMPTY_VALUE_FALLBACK
            ),
          ],
          [
            'External id',
            <CopyableText value={resource.externalId} key="externalId">
              {resource.externalId}
            </CopyableText>,
          ],
        ]}
      />
    </VStack>
  )
}
