import { Box, Group, Subheader } from '@revolut/ui-kit'
import { ReviewerItem, ReviewerStatus } from '../types'
import { getReviewerGroupHeader } from '../utils'
import { CrossCheckReviewGroupItem } from './CrossCheckReviewGroupItem'

type Props = {
  reviewers: ReviewerItem[]
  status: ReviewerStatus
}

export const CrossCheckReviewGroup = (props: Props) => {
  const { reviewers, status } = props

  if (!reviewers.length) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{getReviewerGroupHeader(reviewers, status)}</Subheader.Title>
      </Subheader>
      <Group>
        {reviewers.map((reviewer) => (
          <CrossCheckReviewGroupItem
            reviewer={reviewer}
            status={status}
            key={reviewer.reviewerId}
          />
        ))}
      </Group>
    </Box>
  )
}
