import { CheckCircle } from '@revolut/icons'
import { TabBar, Box, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

export type Tab<TabName extends string> = { label: string; value: TabName }

type FormMultiStepTabsProps<TabName extends string> = {
  activeTab?: TabName
  tabs: Tab<TabName>[]
  availableTabs: TabName[]
  checkedTabs: TabName[]
  onTabClick: (tab: TabName) => void
}

const EmptyCircle = styled.div`
  width: 12px;
  height: 12px;
  border: 2px solid currentcolor;
  border-radius: 50%;
  margin-right: 4px;
`

const TabIcon = (props: { checked?: boolean }) => (
  <Box pr="s-8">
    {props.checked ? (
      <CheckCircle color={Token.color.green} size="16px" />
    ) : (
      <EmptyCircle />
    )}
  </Box>
)

export const FormMultiStepTabs = <TabName extends string>(
  props: FormMultiStepTabsProps<TabName>,
) => {
  const { activeTab, tabs, availableTabs, checkedTabs, onTabClick } = props

  return (
    <TabBar variant="navigation">
      {tabs.map((tab) => {
        const checked = checkedTabs.includes(tab.value)
        const disabled = !checked && !availableTabs.includes(tab.value)
        return (
          <TabBar.Item
            use="button"
            onClick={() => onTabClick(tab.value)}
            aria-selected={tab.value === activeTab}
            disabled={disabled}
            key={tab.value}
          >
            <TabIcon checked={checked} />
            {tab.label}
          </TabBar.Item>
        )
      })}
    </TabBar>
  )
}
