import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'

import { AccessLogRow } from './types'

export const getColumns = (isAllLoaded: boolean): TableColumn<AccessLogRow>[] => {
  const filters = isAllLoaded
    ? {
        filter: 'includesValue' as const,
        Filter: SelectItemFilter,
      }
    : undefined

  return [
    {
      Header: 'Application',
      accessor: 'client',
      ...filters,
    },
    {
      Header: 'IP',
      accessor: 'ip',
      ...filters,
    },
    {
      Header: 'OS',
      accessor: 'os',
      ...filters,
    },
    {
      Header: 'Browser',
      accessor: 'browser',
      ...filters,
    },
    {
      Header: 'Date',
      id: 'date',
      accessor: 'date',
      disableSortBy: true,
    },
  ]
}
