import { MoreBar, TextSkeleton } from '@revolut/ui-kit'

const SKELETON_ACTIONS = [
  { size: 8, variant: 'accent' as const },
  { size: 6, variant: 'primary' as const },
  { size: 6, variant: 'primary' as const },
]

export const CrossCheckActionsSkeleton = () => (
  <MoreBar>
    {SKELETON_ACTIONS.map(({ size, variant }, idx) => (
      <MoreBar.Action useIcon="Loading" key={idx} variant={variant}>
        <TextSkeleton size={size} />
      </MoreBar.Action>
    ))}
  </MoreBar>
)
