import { Box, Subheader, VStack } from '@revolut/ui-kit'
import { DetailsGroup } from 'components/DetailsGroup'
import { getBackofficeUserUrl } from 'utils/url'
import { AuditLogData } from 'api/dart/auditLog'
import { formatDateTime } from 'utils/date'
import { CustomerCompanyLink } from 'components/Links/CustomerCompanyLink'
import { ExternalTextLink } from 'components/Links/ExternalTextLink'

export const AuditLogAccessModifier = ({ log }: { log: AuditLogData }) => {
  const accessModifier = log['Access Modifier']?.value

  if (!accessModifier) {
    return null
  }

  const userId = 'userId' in accessModifier ? accessModifier.userId : undefined
  const entityId = 'entityId' in accessModifier ? accessModifier.entityId : undefined
  const auxiliaryUserIds =
    'auxiliaryUserIds' in accessModifier ? accessModifier.auxiliaryUserIds : undefined

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Access modifier</Subheader.Title>
      </Subheader>

      <DetailsGroup
        hideEmpty
        rows={[
          ['Issuer', accessModifier.issuer],

          ['Justification', accessModifier.justification],

          [
            'Expiration date',
            accessModifier.expirationDate
              ? formatDateTime(accessModifier.expirationDate)
              : undefined,
          ],

          [
            'Backoffice user ID',
            userId ? (
              <ExternalTextLink href={getBackofficeUserUrl(userId)} text={userId} />
            ) : undefined,
          ],

          ['Type', 'type' in accessModifier ? accessModifier.type : undefined],

          [
            'Entity',
            entityId?.type === 'COMPANY' ? (
              <CustomerCompanyLink id={entityId.id} />
            ) : (
              entityId?.id
            ),
          ],
          [
            'Auxiliary backoffice user IDs',
            auxiliaryUserIds?.length ? (
              <VStack gap="s-8">
                {auxiliaryUserIds.map((id) => (
                  <ExternalTextLink href={getBackofficeUserUrl(id)} text={id} key={id} />
                ))}
              </VStack>
            ) : undefined,
          ],
        ]}
      />
    </Box>
  )
}
