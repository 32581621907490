import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { useCallback } from 'react'

type LoadingPopupProps = {
  title: string
  preventClose?: boolean
  Actions?: () => JSX.Element
  Description?: () => JSX.Element
}

export const useLoadingPopup = () => {
  const statusPopup = useStatusPopup()

  const showLoadingPopup = useCallback(
    ({ preventClose = true, title, Actions, Description }: LoadingPopupProps) =>
      statusPopup.show(
        <StatusPopup variant="loading" preventUserClose={preventClose}>
          <StatusPopup.Title>{title}</StatusPopup.Title>
          {Description && (
            <StatusPopup.Description>
              <Description />
            </StatusPopup.Description>
          )}
          {Actions && (
            <StatusPopup.Actions>
              <Actions />
            </StatusPopup.Actions>
          )}
        </StatusPopup>,
      ),
    [statusPopup],
  )

  return {
    hideLoadingPopup: statusPopup.hide,
    showLoadingPopup,
  }
}
