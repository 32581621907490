import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryDirectRelations } from 'queries/dart/employees'
import { useQueryUserIdMap } from 'queries/idave/users'
import { QueryKey } from 'helpers/configQuery'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { waitForAll } from 'utils/query'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useLoadingToast } from 'hooks/useLoadingToast'
import { UserRestrictionList } from './components/UserRestrictionList'
import { getRows } from './utils'
import { useDeleteRestriction } from './hooks'

const TIMEOUT = 1500

export const UserRestrictions = () => {
  const queryClient = useQueryClient()
  const {
    data: userMap = new Map(),
    status: userStatus,
    fetchStatus: userFetchStatus,
  } = useQueryUserIdMap()

  const {
    data: avatars,
    status: avatarsStatus,
    fetchStatus: avatarsFS,
  } = useQueryMappedAvatars()

  const {
    data: directRelations = [],
    status: directRelStatus,
    fetchStatus: directRelationsFetchStatus,
  } = useQueryDirectRelations()

  const items = useMemo(
    () =>
      getRows({
        directRelations,
        userMap,
        avatars,
      }),
    [directRelations, userMap, avatars],
  )

  const { onDeleteRestriction } = useDeleteRestriction(() => {
    queryClient.invalidateQueries([QueryKey.DirectRelations])
  })

  const queryStatus = waitForAll(
    [
      {
        qs: directRelStatus,
        fs: directRelationsFetchStatus,
      },
    ],
    [
      { qs: userStatus, fs: userFetchStatus },
      { qs: avatarsStatus, fs: avatarsFS },
    ],
  )

  useLoadingToast(
    [
      {
        qs: directRelStatus,
        fs: directRelationsFetchStatus,
      },
      { qs: userStatus, fs: userFetchStatus },
      { qs: avatarsStatus, fs: avatarsFS },
    ],
    "Just a little longer, we're loading",
    TIMEOUT,
  )

  const loadingState = getLoadingState(queryStatus, items.length)

  return (
    <UserRestrictionList
      items={items}
      loadingState={loadingState}
      onDeleteClick={onDeleteRestriction}
    />
  )
}
