import { Box, Group, Item, Subheader } from '@revolut/ui-kit'
import { useMemo } from 'react'
import { EmployeeDetails } from 'api/dart/employee'
import { getItems } from './utils'

export const UserDataAccessRestrictionsLimited = ({
  employeeDetails,
}: {
  employeeDetails: EmployeeDetails | undefined
}) => {
  const items = useMemo(() => getItems(employeeDetails), [employeeDetails])

  if (!employeeDetails) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Restrictions</Subheader.Title>
      </Subheader>

      <Group>
        {items.map((item) => (
          <Item role="row" key={item.title}>
            <Item.Content>
              <Item.Title role="cell">{item.title}</Item.Title>
              <Item.Description role="cell">{item.description}</Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Group>
    </Box>
  )
}
