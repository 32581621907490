import { Box, Subheader, textChain } from '@revolut/ui-kit'
import { pluralForm } from 'utils/string'
import { EntityList } from 'components/EntityList'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryUserPeopleOpsIdMap } from 'queries/idave/users'
import { isAllSettled } from 'utils/query'
import { useCallback, useMemo } from 'react'
import { useEditPopup } from 'hooks/useEditPopup'
import { uniq } from 'lodash'
import { getItems } from './utils'
import { UserSelectPopup } from './components/UserSelectPopup'

type Props = {
  disabled: boolean
  itemIds: string[]
  onChange: (values: string[]) => void
  label: string
  clearable: boolean
}

export const UsersSelect = ({ disabled, itemIds, onChange }: Props) => {
  const {
    data: userMap = new Map(),
    status: usersS,
    fetchStatus: usersFS,
  } = useQueryUserPeopleOpsIdMap()
  const {
    data: avatars,
    status: avatarsS,
    fetchStatus: avatarsFS,
  } = useQueryMappedAvatars()

  const isLoading = !isAllSettled(
    {
      fs: usersFS,
      qs: usersS,
    },
    {
      qs: avatarsS,
      fs: avatarsFS,
    },
  )
  const items = useMemo(
    () => getItems(itemIds, userMap, avatars),
    [itemIds, userMap, avatars],
  )
  const { closePopup: closeEditPopup, setPopup: setEditPopup } = useEditPopup()
  const onAdd = useCallback(
    (newIds: string[]) => {
      onChange(uniq([...itemIds, ...newIds]))
      closeEditPopup()
    },
    [onChange, itemIds, closeEditPopup],
  )
  const onDelete = useCallback(
    (deleteId: string) => onChange(itemIds.filter((id) => id !== deleteId)),
    [onChange, itemIds],
  )

  const onAddClick = useCallback(() => {
    setEditPopup({
      title: 'Add employee',
      content: <UserSelectPopup onAdd={onAdd} addedUsers={itemIds} />,
    })
  }, [setEditPopup, onAdd, itemIds])

  return (
    <Box>
      <Box pl="s-16" mt="-s-20">
        <Subheader variant="nested">
          <Subheader.Title>
            {textChain(pluralForm(itemIds, ['Employee', 'Employees']), itemIds.length)}
          </Subheader.Title>
        </Subheader>
      </Box>

      <EntityList
        disabled={disabled}
        items={items}
        showAvatars
        isLoading={isLoading}
        onAddClick={onAddClick}
        onDeleteClick={onDelete}
        addEnabled={!disabled}
        deleteEnabled={!disabled}
        addActionLabel="Add employee"
      />
    </Box>
  )
}
