import { MoreBar } from '@revolut/ui-kit'

export const UserListActions = ({
  selectedCount,
  showAddPermissions,
  showDeletePermissions,
  onAddClick,
  onRemoveClick,
}: {
  selectedCount: number
  showAddPermissions: boolean
  showDeletePermissions: boolean
  onRemoveClick: () => void
  onAddClick: () => void
}) => (
  <>
    {!!selectedCount && showDeletePermissions && (
      <MoreBar.Action variant="negative" onClick={onRemoveClick} useIcon="Delete">
        Delete
      </MoreBar.Action>
    )}

    {!selectedCount && showAddPermissions && (
      <MoreBar.Action useIcon="Plus" onClick={onAddClick}>
        Add users
      </MoreBar.Action>
    )}
  </>
)
