import { Button, Popup, TextArea, VStack } from '@revolut/ui-kit'
import { useEditPopup } from 'hooks/useEditPopup'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { AssignmentTimer } from 'components/AssignmentTimer'
import { useCallback, useState } from 'react'
import { AssignTimer } from 'components/AssignmentTimer/types'
import { CompanyList } from './components'

export type AddCompanyPopupData = {
  expirationDate?: number
  justification?: string
  companyIds: string[]
}

export const AddCompanyPopup = ({
  employeeId,
  add,
}: {
  employeeId: string
  add: (params: AddCompanyPopupData) => void
}) => {
  const [companyIds, setCompanyIds] = useState<string[]>([])
  const [justification, setJustification] = useState<string>()

  const handleJustificationChange = useInputStringChange(setJustification)
  const [timer, setTimer] = useState<AssignTimer>()

  const onAddClick = useCallback(
    () =>
      add({
        companyIds,
        justification,
        expirationDate: timer?.value,
      }),
    [add, companyIds, justification, timer?.value],
  )

  const { scrollRef } = useEditPopup()
  const count = companyIds.length
  const disabled = !count || (timer?.type === 'temporary' && !timer.value)

  return (
    <>
      <VStack space="s-16">
        <AssignmentTimer onChange={setTimer} />
        <TextArea
          label="Justification"
          onChange={handleJustificationChange}
          value={justification}
        />
        <CompanyList
          employeeId={employeeId}
          onChange={setCompanyIds}
          scrollRef={scrollRef}
        />
      </VStack>

      <Popup.Actions>
        <Button elevated onClick={onAddClick} disabled={disabled}>
          {count ? `Add ${count} selected` : 'Add'}
        </Button>
      </Popup.Actions>
    </>
  )
}
