import { BottomSheet, Button, TextArea } from '@revolut/ui-kit'
import { CrossCheckDecision } from 'api/types/crossCheck'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback, useState } from 'react'

type Props = {
  decision: CrossCheckDecision
  onSubmit: (decision: CrossCheckDecision, reason?: string) => void
  onCancel: () => void
}

export const ReviewPopup = (props: Props) => {
  const { decision, onCancel, onSubmit } = props

  const [value, onValueChange] = useState<string>()
  const changeHandler = useInputStringChange(onValueChange)
  const submit = useCallback(() => onSubmit(decision, value), [value, decision, onSubmit])

  return (
    <>
      <TextArea
        label="Justification (optional)"
        rows={3}
        onChange={changeHandler}
        value={value}
      />

      <BottomSheet.Actions horizontal>
        <Button elevated onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={submit} variant="primary">
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
