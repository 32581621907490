import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { sortBy } from 'lodash'

export const getItems = (
  userIds: string[],
  userMap: Map<string, UserListItem>,
  avatars?: Map<string, RevolutersAvatar>,
) => {
  return sortBy(
    userIds.map((userId) => {
      const user = userMap.get(userId)
      return {
        id: userId,
        title: user ? getName(user) : userId,
        description: user?.email,
        avatar: { image: getAvatarUrl({ user, avatars }) },
      }
    }),
    'title',
  )
}
