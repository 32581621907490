import { TabBar, TabProps } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'

type TabBarLinkProps = {
  ref?: React.Ref<HTMLElement | SVGSVGElement>
  to: string
} & TabProps

export const TabBarLink = (props: TabBarLinkProps) => {
  const navigate = useNavigate()
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      props.onClick?.(e)
      navigate(props.to)
    },
    [props, navigate],
  )
  return (
    <TabBar.Item
      {...props}
      aria-selected={Boolean(useMatch({ path: props.to }))}
      onClick={onClick}
    >
      {props.children}
    </TabBar.Item>
  )
}
