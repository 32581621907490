import { policies } from './policies'
import { permissions } from './permissions'
import { resources } from './resources'
import { trainings } from './trainings'
import { tasks } from './tasks'
import { crossChecks } from './crossChecks'
import { revoluters } from './revoluters'
import { auditLogs } from './auditLogs'
import { recertification } from './recertification'

const api = {
  crossChecks,
  policies,
  permissions,
  resources,
  revoluters,
  trainings,
  tasks,
  auditLogs,
  recertification,
}

export default api
