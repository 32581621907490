import { useQueryResource } from 'queries/sam/resources'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { SamResource as TSamResource } from 'api/sam/resources'
import { generatePath, useNavigate } from 'react-router'

import { Page, TabBar, TextSkeleton } from '@revolut/ui-kit'
import { Url } from 'routing'
import { TabBarLink } from 'components/TabBarLink'
import { SAM_PERMISSIONS } from 'security'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { SamResourceTab } from './types'
import { ResourceSkeleton } from './components/ResourceSkeleton'
import { ResourcePolicies } from './components/ResourcePolicies'
import { ResourceDetails } from './components/ResourceDetails'

type Props = {
  id: string
  tab: SamResourceTab
}
export const SamResource = ({ id, tab }: Props) => {
  const { data: resource, status, fetchStatus } = useQueryResource(id)

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      renderIdle={() => <NoAccessWidget />}
      data={resource}
      renderLoading={() => (
        <Outer id={id}>
          <ResourceSkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data }) => (
        <Outer resource={data} id={id}>
          <Inner tab={tab} resource={data} />
        </Outer>
      )}
    />
  )
}

const Outer = (props: {
  id: string
  children: React.ReactNode
  resource?: TSamResource
}) => {
  const { resource, children, id } = props
  const navigate = useNavigate()

  return (
    <>
      <Page.Header onBack={() => navigate(Url.SamResources)}>
        {resource?.resourceName || <TextSkeleton size={16} />}
      </Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={SAM_PERMISSIONS.RESOURCES_VIEW_DETAILS}>
            <TabBarLink to={generatePath(Url.SamResourceDetails, { resourceId: id })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={SAM_PERMISSIONS.POLICIES_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.SamResourcePolicies, { resourceId: id })}>
              Policies
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = ({ tab, resource }: { resource: TSamResource; tab: SamResourceTab }) => {
  switch (tab) {
    case 'policies':
      return <ResourcePolicies resourceId={resource.resourceId} />
    default:
    case 'details':
      return <ResourceDetails resource={resource} />
  }
}
