import { Box, Cell, Subheader, Text, TextSkeleton } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

export const EntityDescription = ({
  description,
  title = 'Description',
  isLoading,
}: {
  description?: string
  title?: string
  isLoading?: boolean
}) =>
  description?.trim() || isLoading ? (
    <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
      <Subheader variant="nested">
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>

      <Cell style={{ overflowWrap: 'anywhere' }}>
        {isLoading ? <TextSkeleton size={24} /> : <Text>{description}</Text>}
      </Cell>
    </Box>
  ) : null
