import { CrossCheckValueTab } from '../../../types'
import { DetailRow } from '../types'

export const getDefaultDetailsTab = (tabs: CrossCheckValueTab[], rows: DetailRow[]) => {
  const isNoChanges = rows.every(({ changeType }) => changeType === 'NoChange')

  if (isNoChanges && tabs.length > 1) {
    return tabs.filter((tab) => tab !== 'requested')[0]
  }

  return tabs[0] || 'requested'
}
