import { CrossCheckedPayload } from 'utils/types/crossChecked'
import { getHeaders } from './getHeaders'

export const splitPayloadHeader = <T,>(payload: CrossCheckedPayload<T>) => {
  const { crossCheck, payload: body } = payload

  const headers = getHeaders({ crossCheck })

  return { headers, body }
}
