import { Box, HStack, Subheader, Text, VStack } from '@revolut/ui-kit'
import { useQueryToxicPairsMap } from 'queries/idave/permissions'
import { useCallback } from 'react'
import { pluralForm } from 'utils/string'
import { useSideBox } from 'view/SideBox/SideBox'
import { QuerySwitch } from 'components/QuerySwitch'
import { ToxicPair } from 'api/idave/toxic'
import { DetailsGroup } from 'components/DetailsGroup'
import { CopyableText } from 'components/CopyableText'
import { PermissionLink } from 'components/Links/PermissionLink'
import { ToxicPairLink } from 'components/Links/ToxicPairLink'
import { ToxicPermissionSideAvatar } from './ToxicPermissionSideAvatar'

export const useToxicPermissionSide = () => {
  const { openSide, closeSide, sidebox } = useSideBox()
  const open = useCallback(
    (toxicPairsId: string[]) => {
      openSide({
        title: pluralForm(toxicPairsId.length, ['Toxic pair', 'Toxic pairs']),
        avatar: <ToxicPermissionSideAvatar />,
        body: <ToxicPermissionSide toxicPairsId={toxicPairsId} />,
        queryParams: {
          type: 'toxicPairs',
        },
      })
    },
    [openSide],
  )

  const close = useCallback(() => {
    if (sidebox?.queryParams?.type === 'toxicPairs') {
      closeSide()
    }
  }, [closeSide, sidebox])

  return {
    open,
    close,
    sidebox,
  }
}

type ToxicPermissionSideProps = {
  toxicPairsId: string[]
}
export const ToxicPermissionSide = (props: ToxicPermissionSideProps) => {
  const { data: toxicPairMap, fetchStatus: fs, status: qs } = useQueryToxicPairsMap()

  return (
    <QuerySwitch
      required={[{ fs, qs }]}
      data={toxicPairMap}
      renderLoading={() => null}
      renderSuccess={({ data }) => (
        <Inner toxicPairsId={props.toxicPairsId} toxicMap={data} />
      )}
    />
  )
}

type InnerProps = {
  toxicPairsId: string[]
  toxicMap: Map<string, ToxicPair>
}
const Inner = ({ toxicMap, toxicPairsId }: InnerProps) => {
  return (
    <VStack space="s-16">
      {toxicPairsId.map((toxicPairId, idx) => {
        const toxicPair = toxicMap.get(toxicPairId)

        if (!toxicPair) {
          return (
            <Box key={toxicPairId}>
              <Subheader variant="nested">
                <Subheader.Title>Unknown toxic pair ID {idx + 1}</Subheader.Title>
              </Subheader>
              <DetailsGroup
                rows={[
                  [
                    'ID',
                    <CopyableText value={toxicPairId} key={toxicPairId}>
                      {toxicPairId}
                    </CopyableText>,
                  ],
                ]}
              />
            </Box>
          )
        }

        const firstPermission = toxicPair.firstPermission
        const secondPermission = toxicPair.secondPermission

        return (
          <Box key={toxicPairId}>
            <Subheader variant="nested">
              <Subheader.Title>
                <HStack space="s-8" align="center">
                  <Text>Toxic pair {idx + 1}</Text>
                </HStack>
              </Subheader.Title>
            </Subheader>
            <DetailsGroup
              rows={[
                ['Toxic pair', <ToxicPairLink id={toxicPairId} key={toxicPairId} />],
                [
                  'First permission',
                  <PermissionLink
                    id={firstPermission}
                    withClient
                    key={firstPermission}
                  />,
                ],
                [
                  'Second permission',
                  <PermissionLink
                    id={secondPermission}
                    withClient
                    key={secondPermission}
                  />,
                ],
              ]}
            />
          </Box>
        )
      })}
    </VStack>
  )
}
