import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useQueryToxicAlerts } from 'queries/idave/permissions'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useCallback, useMemo } from 'react'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { mergeQueryStatuses } from 'utils/query'
import { EntitiesTable } from 'components/EntitiesTable'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useToxicAlertPreview } from 'components/previews/ToxicAlertPreview'
import { useColumns } from './columns'
import { getRows } from './utils'
import { UserAlertRow } from './types'

type Props = {
  toxicPairId: string
}
export const ToxicPairAlerts = ({ toxicPairId }: Props) => {
  const {
    data: alerts = [],
    fetchStatus: fs,
    status: qs,
  } = useQueryToxicAlerts({
    toxicPermissionId: toxicPairId,
  })

  const { data: users, fetchStatus: usersFS, status: usersQS } = useQueryUserIdMap()

  const { status: revoDescriptionStatus, getEmployeeDescription } =
    useGetEmployeeRevoDescription()

  const entities = useMemo(
    () =>
      getRows({
        getEmployeeDescription,
        alerts,
        users,
      }),
    [users, alerts, getEmployeeDescription],
  )

  const loadingState = getLoadingState(
    mergeQueryStatuses({ qs, fs }, { qs: usersQS, fs: usersFS }),
    alerts.length,
  )

  const isLoading = loadingState === 'pending' || revoDescriptionStatus === 'loading'
  const columns = useColumns({ isLoading })
  const { searchValue, searched, setSearchValue } = useSearchFilter({ entities })
  const { openToxicAlertSide } = useToxicAlertPreview()
  const onRowClick = useCallback(
    ({ toxicAlert }: UserAlertRow) =>
      openToxicAlertSide(toxicAlert.toxicPermissionId, toxicAlert.userId),
    [openToxicAlertSide],
  )

  return (
    <EntitiesTable
      entitiesTypeLabel="User alerts"
      pluralForms={['user alert', 'user alerts']}
      totalCount={entities.length}
      columns={columns}
      data={searched}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
      onRowClick={onRowClick}
    />
  )
}
