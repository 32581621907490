import { Group } from 'api/idave/groups'
import { EntitiesTable } from 'components/EntitiesTable'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useNavigate } from 'react-router'
import { useCallback } from 'react'
import { Url } from 'routing'
import { MoreBar } from '@revolut/ui-kit'
import { IDAVE_PERMISSIONS } from 'security'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useColumns } from './columns'
import { getGroupLink } from './utils'
import { GROUP_LABEL_PLURAL_FORMS } from './constants'

export const GroupsTable = ({ groups }: { groups?: Group[] }) => {
  const navigate = useNavigate()

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: groups || [],
  })

  const onRowClick = useCallback((row: Group) => navigate(getGroupLink(row)), [navigate])

  const onGroupCreateClick = useCallback(() => navigate(Url.SystemGroupAdd), [navigate])

  const renderActions = useCallback(
    () => (
      <PermissionsCheck permission={IDAVE_PERMISSIONS.GROUPS_CREATE}>
        <MoreBar.Action useIcon="Plus" onClick={onGroupCreateClick}>
          Add
        </MoreBar.Action>
      </PermissionsCheck>
    ),
    [onGroupCreateClick],
  )

  const columns = useColumns()

  return (
    <EntitiesTable
      enableNavigation
      entitiesTypeLabel="Group"
      pluralForms={GROUP_LABEL_PLURAL_FORMS}
      totalCount={groups?.length}
      loadingState={groups ? 'ready' : 'pending'}
      data={searched}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
      getRowLink={getGroupLink}
      onRowClick={onRowClick}
      renderActions={renderActions}
    />
  )
}
