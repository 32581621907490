import { Url } from 'routing'
import { CommonPageType, EntityPageType } from './types'

export type PathToMatch = EntityToMatch | CommonPageToMatch

type EntityToMatch = {
  pageType: EntityPageType
  path: string
  entityKey: string
}
type CommonPageToMatch = {
  pageType: CommonPageType
  path: string
}

export const PATHS_TO_MATCH: PathToMatch[] = [
  { pageType: 'role', path: Url.Role, entityKey: 'roleId' },
  { pageType: 'user', path: Url.User, entityKey: 'userId' },
  { pageType: 'client', path: Url.Client, entityKey: 'clientId' },
  { pageType: 'permission', path: Url.Permission, entityKey: 'permissionId' },
  { pageType: 'organisation', path: Url.Organisation, entityKey: 'organisationId' },
  { pageType: 'policy', path: Url.SamPolicy, entityKey: 'policyId' },
  {
    pageType: 'samCrossCheck',
    path: Url.CrossCheckSamDetails,
    entityKey: 'crossCheckId',
  },
  {
    pageType: 'idaveCrossCheck',
    path: Url.CrossCheckIdaveDetails,
    entityKey: 'crossCheckId',
  },
  {
    pageType: 'dartCrossCheck',
    path: Url.CrossCheckDartDetails,
    entityKey: 'crossCheckId',
  },
  { pageType: 'customerCompany', path: Url.DartCustomerCompany, entityKey: 'companyId' },
  { pageType: 'employeeCompany', path: Url.DartEmployeeCompany, entityKey: 'companyId' },
  { pageType: 'employeeAccesses', path: Url.DartUserAccesses },
  { pageType: 'employeeRestrictions', path: Url.DartUserRestrictions },
  { pageType: 'dartLogs', path: Url.DartAuditLogs },
  { pageType: 'idaveLogs', path: Url.AuditLogs },
]
