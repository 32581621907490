import {
  MultiSelectInput,
  MultiSelectInputProps,
  SelectOptionItemType,
} from '@revolut/ui-kit'
import { EmployeeType } from 'api/sam/policies'
import { startCase } from 'lodash'

const OPTIONS = [
  {
    key: EmployeeType.External,
    label: startCase(EmployeeType.External),
    value: EmployeeType.External,
  },
  {
    key: EmployeeType.Internal,
    label: startCase(EmployeeType.Internal),
    value: EmployeeType.Internal,
  },
]

type EmployeeTypesSelectProps = MultiSelectInputProps<
  string,
  SelectOptionItemType<EmployeeType>
>
type Props = Omit<EmployeeTypesSelectProps, 'options'>

export const EmployeeTypesSelect = (props: Props) => (
  <MultiSelectInput options={OPTIONS} {...props} />
)
