import { DataPoint, TableLoadingState, TableWidget } from '@revolut/ui-kit'
import { DataLabel } from '../../types'
import { getLabels } from './utils'
import { LabelSkeletons } from './LabelSkeletons'

type Props = {
  total?: number
  selected?: number
  dataLabels?: DataLabel[]
  loadingState?: TableLoadingState
}
export const EntitiesListInfo = (props: Props) => {
  const { total, selected, dataLabels, loadingState } = props

  const labels = getLabels({
    total,
    selected,
    dataLabels,
  })

  return (
    <TableWidget.Info>
      {loadingState === 'pending' ? (
        <LabelSkeletons />
      ) : (
        labels.map((item) => (
          <DataPoint key={item.label}>
            <DataPoint.Value use="h3" aria-label={item.ariaLabel} color={item?.color}>
              {item.value}
            </DataPoint.Value>
            <DataPoint.Label>{item.label}</DataPoint.Label>
          </DataPoint>
        ))
      )}
    </TableWidget.Info>
  )
}
