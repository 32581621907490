import { Table, TableColumn, Text } from '@revolut/ui-kit'
import { getCompletenessLevel } from './utils'
import { Training } from './types'

export const COLUMNS: TableColumn<Training>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Status',
    accessor: 'completeness',
    Cell: (params: { row: { original: Training } }) => (
      <Table.StatusCell level={getCompletenessLevel(params.row.original.completeness)}>
        <Text fontSize="14" fontWeight="500">
          {params.row.original.completeness}
        </Text>
      </Table.StatusCell>
    ),
  },
]
