import { Box, Button, Popup } from '@revolut/ui-kit'
import { UserList } from 'components/UserList'
import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback, useState } from 'react'

export const AddReviewerPopup = (props: {
  onAddUser: (userId: string) => void
  addLabel: string
}) => {
  const { onAddUser, addLabel } = props
  const { scrollRef } = useEditPopup()
  const [userId, setUserId] = useState<string | null>(null)

  const onChange = useCallback(
    (ids: string[]) => (ids.length ? setUserId(ids[0]) : setUserId(null)),
    [setUserId],
  )

  const onSubmitClick = useCallback(() => {
    if (userId) {
      onAddUser(userId)
    }
  }, [userId, onAddUser])

  return (
    <>
      <Box mt="-s-24">
        <UserList
          onChange={onChange}
          variant="radio"
          scrollRef={scrollRef}
          excludeInactive
        />
      </Box>
      <Popup.Actions>
        <Button variant="primary" elevated onClick={onSubmitClick} disabled={!userId}>
          {addLabel}
        </Button>
      </Popup.Actions>
    </>
  )
}
