import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { UserIds } from 'hooks/useCurrentUserIds'
import { PermissionUtils } from 'services/permissions'
import { getCrossCheckActionPermission } from 'view/CrossChecks/utils'
import { isRecertification as isRecertificationCheck } from 'view/CrossChecks/CrossCheckList/utils'

export const canJustify = (params: {
  source: CrossCheckSystem
  crossCheck: CrossCheckDetail
  ids: UserIds
  permissionUtils: PermissionUtils
}) => {
  const {
    source,
    crossCheck,
    ids,
    permissionUtils: { hasPermission },
  } = params
  const isRecertification = isRecertificationCheck(crossCheck.executableName)

  const requesterId = source === 'sam' ? ids.peopleOps : ids.idave

  const crossCheckPermission = getCrossCheckActionPermission(source, 'JUSTIFY')
  const isAllowed = hasPermission(crossCheckPermission)
  const isJustified = crossCheck.state !== 'REQUESTED'
  const isRequester = requesterId === crossCheck.requester

  return isRequester && isAllowed && !isJustified && !isRecertification
}
