import { Box, Button, Page } from '@revolut/ui-kit'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { useQueryClient } from 'queries/idave/clients'
import { QuerySwitch } from 'components/QuerySwitch'
import { Client } from 'api/idave/clients'
import { FormProvider, useForm } from 'react-hook-form'
import { useHeaderActions } from 'components/HeaderActions'
import { CommonClientEdit } from '../components/CommonClientEdit'
import { normalizeClient } from '../../utils'
import { useEditClient } from './useEditClient'
import { CommonClientEditFormValues } from '../components/CommonClientEdit/types'

export const ClientEdit = (props: { clientId: string }) => {
  const { data: client, status, fetchStatus } = useQueryClient(props.clientId)

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={client}
      renderLoading={() => <Inner isLoading clientId={props.clientId} />}
      renderSuccess={({ data }) => <Inner client={data} clientId={props.clientId} />}
    />
  )
}

type InnerProps = {
  clientId: string
  client?: Client
  isLoading?: boolean
}

const Inner = (props: InnerProps) => {
  const { clientId, isLoading, client } = props
  const navigate = useNavigate()
  const { mutate, isLoading: isSubmitting } = useEditClient()

  const onBackClick = useCallback(
    () => navigate(generatePath(Url.Client, { clientId })),
    [navigate, clientId],
  )

  const clientForm = useForm<CommonClientEditFormValues>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: client,
  })

  const onSubmit = useCallback(() => {
    if (client) {
      mutate(
        normalizeClient({
          ...client,
          ...clientForm.getValues(),
        }),
      )
    }
  }, [client, mutate, clientForm])

  const isInputLoading = !!isLoading || isSubmitting
  const isNothingToSave = !clientForm.formState.isDirty
  const isFormNotValid = !clientForm.formState.isValid
  const isSubmitDisabled = isInputLoading || isNothingToSave || isFormNotValid

  useEffect(() => {
    const isInactive = client && client.state !== 'ACTIVE'
    if (isInactive) {
      navigate(generatePath(Url.Client, { clientId }))
    }
  }, [client, clientId, navigate])

  const isOuterSource = Boolean(client?.source && client?.source !== 'idave')
  const HeaderActions = useHeaderActions()

  return (
    <>
      <Page.Header onBack={onBackClick} actions={HeaderActions && <HeaderActions />}>
        Edit application
      </Page.Header>
      <FormProvider {...clientForm}>
        <Page.Main>
          <Box pt="s-12">
            <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
              <CommonClientEdit
                isLoading={isInputLoading}
                isOuterSource={isOuterSource}
              />
            </Box>
          </Box>
          <Page.MainActions>
            <Button
              id="client-edit-save"
              variant="primary"
              elevated
              disabled={isSubmitDisabled}
              onClick={onSubmit}
              pending={isLoading}
            >
              Save
            </Button>
          </Page.MainActions>
        </Page.Main>
      </FormProvider>
    </>
  )
}
