import { EmployeeAccesses } from 'api/dart/employee'
import { AddEmployeeModifier } from 'api/dart/types'
import { isUuid } from 'utils/string/isUuid'
import { FormState } from './UserRestrictionCreate'

export type CreateRestrictionsPayloadResult = {
  payload?: AddEmployeeModifier[]
  error?: string
}

export const createAccessModifierPayload = (params: {
  formState: FormState
  accesses: EmployeeAccesses[]
}): CreateRestrictionsPayloadResult => {
  const { formState, accesses } = params
  const { type, employeeId } = formState
  const employeeAccesses = employeeId
    ? accesses.find((i) => i.id === employeeId)
    : undefined

  switch (type) {
    case 'bannedEmployee': {
      return getBannedEmployeeModifier({ formState, employeeAccesses })
    }

    case 'bannedRelations': {
      return getBannedRelationModifier({ formState, employeeAccesses })
    }

    default:
      return { error: 'Unknown type' }
  }
}

const getBannedRelationModifier = (params: {
  formState: FormState
  employeeAccesses?: EmployeeAccesses
}) => {
  const { formState, employeeAccesses } = params
  const { employeeId, subjectId } = formState

  if (!employeeId || !subjectId) {
    return { payload: undefined }
  }

  const isAlreadyBanned = !!employeeAccesses?.accessModifiers.find(
    ({ type, entityId }) => type === 'DENIED' && entityId?.id === subjectId,
  )
  if (isAlreadyBanned) {
    return { error: 'User already blocked to access the customer' }
  }

  if (!isUuid(subjectId)) {
    return { error: 'Customer ID must be an UUID' }
  }

  const modifier = {
    employeeId,
    accessModifier: {
      type: 'DENIED' as const,
      justification: formState.justification,
      entityId: {
        type: 'CUSTOMER' as const,
        id: subjectId,
      },
    },
  }

  return { payload: [modifier] }
}

const getBannedEmployeeModifier = (params: {
  formState: FormState
  employeeAccesses?: EmployeeAccesses
}) => {
  const { formState, employeeAccesses } = params
  const { employeeId } = formState

  if (!employeeId) {
    return { payload: undefined }
  }

  const isAllowedAll = !!employeeAccesses?.accessModifiers.find(
    (modifier) => modifier.type === 'ALLOWED' && !modifier.entityId,
  )
  if (isAllowedAll) {
    return { error: 'User has access to all companies modificator, remove it first' }
  }

  const isAlreadyBanned = !!employeeAccesses?.accessModifiers.find(
    (modifier) => modifier.type === 'DENIED' && !modifier.entityId,
  )
  if (isAlreadyBanned) {
    return { error: 'User already banned' }
  }

  const modifier = {
    employeeId,
    justification: formState.justification,
    accessModifier: {
      type: 'DENIED' as const,
    },
  }

  return { payload: [modifier] }
}
