import { EntitiesTable } from 'components/EntitiesTable'
import {
  useQueryPermissionIdMap,
  useQueryToxicAlerts,
  useQueryToxicPairsMap,
} from 'queries/idave/permissions'
import { useCallback, useMemo } from 'react'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useToxicAlertPreview } from 'components/previews/ToxicAlertPreview'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { waitForAll } from 'utils/query'
import { AlertRow } from './types'
import { COLUMNS } from './columns'
import { getRows } from './utils'

type Props = {
  userId: string
}
export const UserToxicAlerts = ({ userId }: Props) => {
  const {
    data: toxicAlerts,
    fetchStatus: toxicAlertsFS,
    status: toxicAlertsQS,
  } = useQueryToxicAlerts({ userId })
  const {
    data: toxicPairs,
    fetchStatus: toxicPairsFS,
    status: toxicPairsQS,
  } = useQueryToxicPairsMap()
  const {
    data: permissionMap,
    fetchStatus: permissionsFS,
    status: permissionQS,
  } = useQueryPermissionIdMap()
  const entities = useMemo(
    () => getRows({ toxicAlerts, toxicPairs, permissionMap }),
    [toxicAlerts, toxicPairs, permissionMap],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({ entities })

  const { openToxicAlertSide } = useToxicAlertPreview()
  const onRowClick = useCallback(
    ({ id }: AlertRow) => openToxicAlertSide(id, userId),
    [openToxicAlertSide, userId],
  )

  const loadingState = getLoadingState(
    waitForAll(
      [{ qs: toxicAlertsQS, fs: toxicAlertsFS }],
      [
        { qs: toxicPairsQS, fs: toxicPairsFS },
        { qs: permissionQS, fs: permissionsFS },
      ],
    ),
    entities.length,
  )

  return (
    <EntitiesTable
      entitiesTypeLabel="Alerts"
      pluralForms={['Alert', 'Alerts']}
      totalCount={entities.length}
      columns={COLUMNS}
      data={searched}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
      onRowClick={onRowClick}
    />
  )
}
