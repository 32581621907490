import { SAM_PERMISSIONS } from 'security'
import { UIRoute, Url } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { Sam } from './Sam'
import { SamCreatePolicy } from './SamCreatePolicy'
import { SamPoliciesAddResources, SamPoliciesAddTrainings } from './SamPolicies'
import { SamPolicy } from './SamPolicy'
import { SamPolicyEdit } from './SamPolicy/SamPolicyEdit'
import { CREATE, EDIT } from './permissions'
import { SamResource } from './SamResources/SamResource/SamResource'

export const routes = ({
  hasPermission,
  hasSomePermissions,
  hasEveryPermission,
}: PermissionUtils): Record<string, UIRoute> => ({
  [Url.Sam]: {
    type: 'redirect',
    params: [],
    newUrl: Url.SamPolicies,
  },
  [Url.SamPolicies]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Sam tab="policies" />,
  },
  [Url.SamResources]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(SAM_PERMISSIONS.RESOURCES_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Sam tab="resources" />,
  },
  [Url.SamResource]: {
    type: 'redirect',
    params: ['resourceId'],
    newUrl: Url.SamResourceDetails,
  },
  [Url.SamResourceDetails]: {
    type: 'component',
    withParams: true,
    params: ['resourceId'],
    allowed: hasPermission(SAM_PERMISSIONS.RESOURCES_VIEW_DETAILS),
    fallbackRoute: Url.SamResources,
    Component: ({ resourceId }) => <SamResource tab="details" id={resourceId} />,
  },
  [Url.SamResourcePolicies]: {
    type: 'component',
    withParams: true,
    params: ['resourceId'],
    allowed: hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_LIST),
    fallbackRoute: Url.SamResources,
    Component: ({ resourceId }) => <SamResource tab="policies" id={resourceId} />,
  },
  [Url.SamTasks]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(SAM_PERMISSIONS.TASKS_CREATE),
    fallbackRoute: Url.Home,
    Component: () => <Sam tab="tasks" />,
  },
  [Url.SamPoliciesCreate]: {
    withParams: true,
    type: 'component',
    allowed: hasSomePermissions(...CREATE),
    fallbackRoute: Url.SamPolicies,
    params: [],
    Component: SamCreatePolicy,
  },
  [Url.SamPoliciesCreateCopy]: {
    withParams: true,
    type: 'component',
    allowed: hasSomePermissions(...CREATE),
    fallbackRoute: Url.SamPolicies,
    params: ['policyId'],
    Component: SamCreatePolicy,
  },
  [Url.SamPoliciesAddResources]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(SAM_PERMISSIONS.POLICIES_UPDATE_RESOURCES),
    fallbackRoute: Url.SamPolicies,
    Component: SamPoliciesAddResources,
  },
  [Url.SamPoliciesAddTrainings]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(SAM_PERMISSIONS.POLICIES_UPDATE),
    fallbackRoute: Url.SamPolicies,
    Component: SamPoliciesAddTrainings,
  },
  [Url.SamPoliciesEdit]: {
    withParams: true,
    type: 'component',
    allowed: hasSomePermissions(...EDIT),
    fallbackRoute: Url.SamPolicies,
    params: ['policyId'],
    Component: SamPolicyEdit,
  },
  [Url.SamPolicy]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_DETAILS),
    fallbackRoute: Url.SamPolicies,
    params: ['policyId'],
    Component: (params) => <SamPolicy policyId={params.policyId} tab="details" />,
  },
  [Url.SamPolicyResources]: {
    withParams: true,
    type: 'component',
    allowed: hasEveryPermission(
      SAM_PERMISSIONS.POLICIES_VIEW_DETAILS,
      SAM_PERMISSIONS.RESOURCES_VIEW_LIST,
    ),
    fallbackRoute: Url.SamPolicies,
    params: ['policyId'],
    Component: (params) => <SamPolicy policyId={params.policyId} tab="resources" />,
  },
  [Url.SamPolicyTrainings]: {
    withParams: true,
    type: 'component',
    allowed: hasEveryPermission(
      SAM_PERMISSIONS.POLICIES_VIEW_DETAILS,
      SAM_PERMISSIONS.TRAININGS_VIEW_LIST,
    ),
    fallbackRoute: Url.SamPolicies,
    params: ['policyId'],
    Component: (params) => <SamPolicy policyId={params.policyId} tab="trainings" />,
  },
  [Url.SamPolicyEmployees]: {
    withParams: true,
    type: 'component',
    allowed: hasEveryPermission(
      SAM_PERMISSIONS.POLICIES_VIEW_DETAILS,
      SAM_PERMISSIONS.POLICIES_VIEW_EMPLOYEES_LIST,
    ),
    fallbackRoute: Url.SamPolicies,
    params: ['policyId'],
    Component: (params) => <SamPolicy policyId={params.policyId} tab="employees" />,
  },
  [Url.SamPolicyLogs]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(SAM_PERMISSIONS.AUDIT_VIEW_DETAILS),
    fallbackRoute: Url.SamPolicies,
    params: ['policyId'],
    Component: (params) => <SamPolicy policyId={params.policyId} tab="logs" />,
  },
})
