import { CrossCheckDetail } from 'api/types/crossCheck'
import { ChangeType, DetailRow } from '../../types'
import { DetailParams } from './types'
import { getForecasted } from './utils'
import { CREATE_NAMES } from '../../../ChangeSwitcher/utils'

export const getApprovers = ({
  entity,
  crossCheck,
}: DetailParams): DetailRow | undefined => {
  if (crossCheck.entityType !== 'Role' || entity.type !== 'Role') {
    return undefined
  }

  const newApprover = crossCheck.executableParams['New Approvers']?.value[0].value.id

  const requested = newApprover
  const current = entity.value?.approvers?.[0]
  const changeType = getChangeType(crossCheck)

  return {
    title: 'Approver',
    value: {
      current,
      requested,
      forecasted: getForecasted({ changeType, current, requested }),
      type: 'User',
    },
    changeType,
  }
}

const getChangeType = (crossCheck: CrossCheckDetail): ChangeType => {
  if (CREATE_NAMES.includes(crossCheck.executableName)) {
    return 'EntityCreation'
  }

  if (
    crossCheck.executableParams['New Approvers'] &&
    crossCheck.executableParams['Removed Approvers']
  ) {
    return 'Edited'
  }

  if (
    !crossCheck.executableParams['New Approvers'] &&
    crossCheck.executableParams['Removed Approvers']
  ) {
    return 'Deleted'
  }

  if (
    crossCheck.executableParams['New Approvers'] &&
    !crossCheck.executableParams['Removed Approvers']
  ) {
    return 'Added'
  }

  return 'NoChange'
}
