import {
  Box,
  ProgressStep,
  ProgressSteps,
  Subheader,
  VStack,
  chain,
} from '@revolut/ui-kit'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryUserIdMap, useQueryUserPeopleOpsIdMap } from 'queries/idave/users'
import { useMemo } from 'react'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { isAllSettled } from 'utils/query'
import { useTokenize } from 'hooks/useTokenize'
import { formatLongDateTime } from 'utils/date'
import { CrossCheckEvent as CrossCheckEventComponent } from './components/CrossCheckEvent'
import { CrossCheckEventSkeletons } from './components/CrossCheckEventSkeletons'
import { getEvents } from './utils'

type Props = {
  crossCheck?: CrossCheckDetail
  source: CrossCheckSystem
}

export const CrossCheckEvents = (props: Props) => {
  const { crossCheck, source } = props
  const useUserMap = source === 'sam' ? useQueryUserPeopleOpsIdMap : useQueryUserIdMap
  const { data: userMap = new Map(), status: qs, fetchStatus: fs } = useUserMap()
  const { data: avatars } = useQueryMappedAvatars()
  const { tokenize } = useTokenize()

  const isLoaded = isAllSettled({ qs, fs }) && crossCheck
  const events = useMemo(
    () =>
      getEvents({
        events: crossCheck?.events || [],
        userMap,
        avatars,
        tokenize,
      }),
    [crossCheck?.events, userMap, avatars, tokenize],
  )

  return (
    <Box>
      <Subheader variant="nested">
        {/* +1 is for the ProgressStep with the "Created on" text */}
        <Subheader.Title>{chain('Action log', events.length + 1)}</Subheader.Title>
      </Subheader>

      {isLoaded ? (
        <VStack space="s-8">
          {events.map((event, idx) => (
            <CrossCheckEventComponent event={event} key={`${event.userId}_${idx}`} />
          ))}
          <ProgressSteps variant="vertical-compact">
            <ProgressStep ml="s-16">
              <ProgressStep.Title>
                Created on {formatLongDateTime(crossCheck.createdDate)}
              </ProgressStep.Title>
            </ProgressStep>
          </ProgressSteps>
        </VStack>
      ) : (
        <CrossCheckEventSkeletons />
      )}
    </Box>
  )
}
