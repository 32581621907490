import { Avatar, Banner, Token } from '@revolut/ui-kit'
import { Account, AccountCustomization } from 'api/idave/accounts'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

export const CustomizationBanner = (props: {
  customization?: AccountCustomization
  account: Account
}) => {
  const {
    customization,
    account: { source },
  } = props
  if (!customization || !source || source === 'idave') {
    return null
  }

  return (
    <Banner maxWidth={MAXIMAL_DETAILS_WIDTH} mt="s-16">
      <Banner.Avatar>
        <Avatar useIcon="ExclamationTriangle" size={56} color={Token.color.warning} />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Title>Account is customized</Banner.Title>
        <Banner.Description>
          {`This account is currently managed by ${source}. All changes made to the account
          will be stored as account customization settings, which override data from the 
          ${source}`}
        </Banner.Description>
      </Banner.Content>
    </Banner>
  )
}
