import { ToastId } from '@revolut/ui-kit'
import { useEffect, useState } from 'react'
import { QueryFetchStatus, isAllSettled } from 'utils/query'
import { useToasts } from './useToasts'

const DEFAULT_TIMEOUT = 2000

// Shows loading toast if statuses isn't settled after specific timeout
export const useLoadingToast = (
  statuses: QueryFetchStatus[],
  message: string,
  timeOut = DEFAULT_TIMEOUT,
) => {
  const { showLoadingToast, hideToast } = useToasts()
  const isSettled = isAllSettled(...statuses)
  const [isTimeOut, setIsTimeOut] = useState(false)
  const closeToast = (toastId?: ToastId) => toastId && hideToast(toastId)

  useEffect(() => {
    let timer: undefined | NodeJS.Timeout

    if (!isSettled) {
      timer = setTimeout(() => {
        setIsTimeOut(true)
      }, timeOut)
    } else {
      timer && clearTimeout(timer)
      setIsTimeOut(false)
    }

    return () => timer && clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettled])

  useEffect(() => {
    let toastId: ToastId | undefined
    if (isTimeOut && !isSettled) {
      toastId = showLoadingToast(message)
    } else {
      closeToast(toastId)
    }

    return () => closeToast(toastId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettled, isTimeOut])
}
