import { ToxicAlert } from 'api/idave/toxic'
import { UserListItem } from 'api/idave/user'
import { EmployeeRevoDescriptionGetter } from 'hooks/useGetEmployeeRevoDescription'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { sortBy } from 'lodash'
import { UserAlertRow } from './types'

type GetRowsParams = {
  users?: Map<string, UserListItem>
  alerts?: ToxicAlert[]
  getEmployeeDescription: EmployeeRevoDescriptionGetter
}
export const getRows = ({
  alerts = [],
  getEmployeeDescription,
  users,
}: GetRowsParams): UserAlertRow[] => {
  return sortBy(
    alerts.map((toxicAlert) => {
      const { userId, firstRoleIds = [], secondRoleIds = [] } = toxicAlert
      const employeeDescription = getEmployeeDescription(userId)
      const user = users?.get(userId)
      return {
        id: userId,
        name: user ? getName(user) : userId,
        email: user?.email || EMPTY_VALUE_FALLBACK,
        spec: employeeDescription.spec?.name || EMPTY_VALUE_FALLBACK,
        team: employeeDescription.team?.name || EMPTY_VALUE_FALLBACK,
        casesCount: firstRoleIds.length * secondRoleIds.length,
        toxicAlert,
      }
    }),
    (i) => -i.casesCount,
  )
}
