import { getCookie } from 'utils/common/getCookie'
import { ProviderProps } from '@revolut/ui-kit'

export type ThemeMode = ProviderProps['mode']

export const STORAGE_MODE_KEY = 'BACKOFFICE_THEME'
export const MODES: NonNullable<ThemeMode>[] = ['auto', 'dark', 'light']

const isMode = (value?: string | null): value is ThemeMode => {
  return !!value && !!MODES.find((v) => v === value)
}

export const getCookieMode = () => {
  const modeValue = getCookie(STORAGE_MODE_KEY)
  return isMode(modeValue) ? modeValue : 'light'
}
