import {
  Flex,
  HStack,
  Table,
  TableColumn,
  Text,
  TextSkeleton,
  Token,
  Icon,
} from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { formatDateTimePrecise } from 'utils/date'
import { AuditLogRow } from './types'

export const getColumns = (isAllDataSettled: boolean): TableColumn<AuditLogRow>[] => {
  const filter = isAllDataSettled
    ? {
        filter: 'includesValue' as const,
        Filter: SelectItemFilter,
      }
    : undefined

  const loadingCell = !isAllDataSettled
    ? {
        Cell: () => <TextSkeleton size={16} />,
      }
    : undefined

  return [
    {
      Header: 'Event',
      accessor: 'event',
      id: 'event',
      width: 4,
      Cell: (params: { value: string; row: { original: AuditLogRow } }) => {
        const { iconName } = params.row.original
        return (
          <Table.Cell>
            <HStack space="s-24">
              <Flex
                width="24px"
                height="24px"
                borderRadius="50%"
                alignItems="center"
                justifyContent="center"
                bg={Token.color.actionBackground}
                flex="24px 0 0"
              >
                <Icon size="16px" color={Token.color.blue} name={iconName} />
              </Flex>
              <Text display="inline-block">{params.value}</Text>
            </HStack>
          </Table.Cell>
        )
      },
      ...filter,
    },
    {
      Header: 'Action',
      accessor: 'action',
      width: 4,
      ...filter,
    },
    {
      Header: 'Entity',
      accessor: 'entity',
      width: 4,
      ...filter,
      ...loadingCell,
    },
    {
      Header: 'Author',
      accessor: 'author',
      width: 4,
      ...filter,
      ...loadingCell,
    },
    {
      Header: 'Date',
      id: 'date',
      width: 3,
      accessor: (row) => formatDateTimePrecise(row.date),
      disableSortBy: true,
    },
  ]
}
