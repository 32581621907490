import { Box, Subheader, TableColumn, Table } from '@revolut/ui-kit'
import { SamTraining } from 'api/sam/trainings'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQueryTrainings } from 'queries/sam/resources'
import { useMemo } from 'react'
import { mapify } from 'utils/array'

export const SamPolicyLogTrainingsTable = ({
  trainingIds,
  title,
}: {
  title: string
  trainingIds?: string[]
}) => {
  const { data: trainings, status, fetchStatus } = useQueryTrainings()
  const trainingsMap = useMemo(() => mapify(trainings || [], (t) => t.code), [trainings])

  if (!trainingIds?.length) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
      <QuerySwitch
        required={[{ qs: status, fs: fetchStatus }]}
        data={trainingsMap}
        renderIdle={() => <RequestErrorWidget />}
        renderLoading={() => <TrainingTable codes={trainingIds} isLoading />}
        renderError={() => <TrainingTable codes={trainingIds} />}
        renderSuccess={() => (
          <TrainingTable trainingsMap={trainingsMap} codes={trainingIds} />
        )}
      />
    </Box>
  )
}

const COLUMNS: TableColumn<SamTraining>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Code',
    accessor: 'code',
  },
]

const TrainingTable = (props: {
  trainingsMap?: Map<string, SamTraining>
  codes: string[]
  isLoading?: boolean
}) => {
  const { trainingsMap, codes, isLoading } = props
  const entities = useMemo(
    () =>
      codes.map((code) => {
        const training = trainingsMap?.get(code)

        return training || { name: 'UNKNOWN_TRAINING', code }
      }),
    [codes, trainingsMap],
  )

  return (
    <Table
      columns={COLUMNS}
      data={entities}
      disableSortBy
      loadingState={isLoading ? 'pending' : 'ready'}
    />
  )
}
