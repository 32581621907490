import { MoreBar } from '@revolut/ui-kit'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { EntitiesTable } from 'components/EntitiesTable'
import {
  filterActiveIds,
  filterSelectedEntities,
  getLoadingState,
} from 'components/EntitiesTable/utils'
import { useMapify } from 'hooks/useMapify'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryPermissionIdMap } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useCallback, useMemo, useState } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { RolePermissionAlertBanner } from 'view/Roles/Role/components/RolePermissionAlertBanner'
import { useToxicPermissionSide } from 'view/Roles/Role/components/ToxicPermissionSide'
import { makeStaticGetToxicRowState } from 'view/Roles/Role/utils'
import { ToxicPair } from 'api/idave/toxic'
import { uniq } from 'lodash'
import { matchToxicPairsByPermissions } from 'utils/toxicPairs/matchToxicPairsByPermissions'
import { getRows } from './utils'
import { getReadingColumns } from './columns'

type RoleCreatePermissionsListProps = {
  permissionIds: string[]
  toxicPairs: ToxicPair[]
  parent?: string
  deletePermissions: (permissionsIds: string[]) => void
  openAdding: () => void
}

export const RoleCreatePermissionsList = ({
  permissionIds,
  toxicPairs,
  parent,
  deletePermissions,
  openAdding,
}: RoleCreatePermissionsListProps) => {
  const {
    data: permissionMap = new Map(),
    status: permissionsQS,
    fetchStatus: permissionsFS,
  } = useQueryPermissionIdMap()
  const { open: openToxicSide } = useToxicPermissionSide()

  const {
    data: clientMap = new Map(),
    status: clientQS,
    fetchStatus: clientFS,
  } = useQueryClientIdMap()

  const {
    data: roleMap = new Map(),
    status: roleQS,
    fetchStatus: roleFS,
  } = useQueryRoleIdMap()

  const rows = useMemo(
    () =>
      getRows({
        permissionIds,
        permissionMap,
        roleMap,
        parent,
      }),
    [parent, roleMap, permissionMap, permissionIds],
  )
  const rowsMap = useMapify(rows, (r) => r.id)
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: rows,
  })

  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})

  const [showSelected, setShowSelected] = useState(false)

  const switchShowSelected = useCallback(() => {
    setShowSelected((v) => !v)
  }, [setShowSelected])

  const onDeleteClick = useCallback(() => {
    const entities = filterSelectedEntities(rows, selectedHash)
    deletePermissions(entities.map((entity) => entity.id))
  }, [selectedHash, rows, deletePermissions])

  const selectedIds = useMemo(() => filterActiveIds(selectedHash), [selectedHash])
  const selectedCount = selectedIds.length
  const disabledDelete = useMemo(
    () => selectedIds.some((id) => rowsMap.get(id)?.ownerRoleId),
    [rowsMap, selectedIds],
  )

  const loadingState = getLoadingState(
    mergeQueryStatuses(
      { qs: permissionsQS, fs: permissionsFS },
      { qs: clientQS, fs: clientFS },
      { qs: roleQS, fs: roleFS },
    ),
    rows.length,
  )

  const matchedToxicPairs = useMemo(
    () => matchToxicPairsByPermissions(uniq(rows.map((i) => i.permissionId)), toxicPairs),
    [rows, toxicPairs],
  )
  const getRowState = useMemo(
    () => makeStaticGetToxicRowState(matchedToxicPairs),
    [matchedToxicPairs],
  )

  const columns = useMemo(
    () => getReadingColumns(clientMap, roleMap),
    [clientMap, roleMap],
  )

  return !rows.length ? (
    <EmptyStatusWidget
      title="Add permissions you want to associate with this Role"
      imageCode="3D055"
      actionLabel="Add permissions"
      onClick={openAdding}
      actionAllowed
    />
  ) : (
    <>
      <RolePermissionAlertBanner
        viewCase="creating"
        openToxicSide={openToxicSide}
        toxicPair={matchedToxicPairs}
      />
      <EntitiesTable
        entitiesTypeLabel="Permissions"
        pluralForms={['permission', 'permissions']}
        totalCount={rows.length}
        loadingState={loadingState}
        data={searched}
        columns={columns}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        searchAutoFocus
        selectedHash={selectedHash}
        switchShowSelected={switchShowSelected}
        showSelected={showSelected}
        setSelectedHash={setSelectedHash}
        getRowState={getRowState}
        renderActions={() => (
          <RolePermissionListActions
            disabledDelete={disabledDelete}
            selectedCount={selectedCount}
            openAdding={openAdding}
            onDeleteClick={onDeleteClick}
          />
        )}
        showSelectedSwitcherForce
      />
    </>
  )
}

const RolePermissionListActions = (props: {
  selectedCount: number
  disabledDelete: boolean
  openAdding: () => void
  onDeleteClick: () => void
}) => {
  const { selectedCount, disabledDelete, onDeleteClick, openAdding } = props

  return selectedCount ? (
    <MoreBar.Action
      useIcon="Delete"
      onClick={onDeleteClick}
      variant="negative"
      disabled={disabledDelete}
    >
      Delete
    </MoreBar.Action>
  ) : (
    <MoreBar.Action useIcon="Plus" onClick={openAdding}>
      Add permissions
    </MoreBar.Action>
  )
}
