import { FormMultiStep, Tab } from 'components/FormMultiStep'
import { Box } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import {
  RoleEditForm,
  RoleEditFormSkeleton,
  RoleEditFormState,
} from 'view/Roles/components/RoleEditForm'
import { ToxicPair } from 'api/idave/toxic'
import { FormTabs } from '../types'
import { RoleCreatePermissions } from './RoleCreatePermissions'

const TABS: Tab<FormTabs>[] = [
  {
    value: 'details',
    label: 'Details',
  },
  {
    value: 'permissions',
    label: 'Permissions',
  },
]

type Props = {
  isSubmitting: boolean
  isCopying: boolean
  roleDetails: RoleEditFormState
  permissionIds: string[]
  toxicPairs: ToxicPair[]
  isLoading?: boolean
  setPermissionIds: (ids: string[]) => void
  onCloseClick: () => void
  onSubmitClick: () => void
  checkTabValid: (tab: FormTabs) => boolean
  checkTabFilled: (tab: FormTabs) => boolean
}
export const RoleCreateForm = ({
  isSubmitting,
  isLoading,
  isCopying,
  roleDetails,
  permissionIds,
  toxicPairs,
  setPermissionIds,
  onCloseClick,
  onSubmitClick,
  checkTabFilled,
  checkTabValid,
}: Props) => {
  const title = isCopying ? 'Duplicate role' : 'Create role'
  return (
    <FormMultiStep
      title={title}
      onCloseClick={onCloseClick}
      tabs={TABS}
      onSubmit={onSubmitClick}
      submitting={isSubmitting}
      checkTabValid={checkTabValid}
      checkTabFilled={checkTabFilled}
      renderTab={(currentTab) => {
        if (isLoading) {
          return (
            <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
              <RoleEditFormSkeleton />
            </Box>
          )
        }

        return (
          <>
            {currentTab === 'details' && (
              <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
                <RoleEditForm />
              </Box>
            )}

            {currentTab === 'permissions' && (
              <RoleCreatePermissions
                permissionIds={permissionIds}
                setPermissionIds={setPermissionIds}
                roleName={roleDetails.name}
                toxicPairs={toxicPairs}
                parent={roleDetails.parent}
              />
            )}
          </>
        )
      }}
    />
  )
}
