import { TableColumn, Action } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { DART_PERMISSIONS } from 'security'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { PermissionUtils } from 'services/permissions'
import { UserTableCell } from 'components/UserTableCell'
import { DirectViewTable } from './types'
import { mapLimitationTypeToLabel, stringifyLimitationObject } from './utils'

export const getColumns = (params: {
  onDeleteClick: (item: DirectViewTable) => void
  customerCompanyMap: Map<string, CustomerCompanyItem>
  avatars?: Map<string, RevolutersAvatar>
  permissionUtils: PermissionUtils
}): TableColumn<DirectViewTable>[] => {
  const {
    onDeleteClick,
    customerCompanyMap,
    permissionUtils: { hasSomePermissions },
  } = params

  const BASE_COLUMNS: TableColumn<DirectViewTable>[] = [
    {
      Header: 'Name',
      accessor: 'name',
      filter: 'includesValue',
      Filter: SelectItemFilter,
      Cell: (cellParams: { row: { original: DirectViewTable } }) => {
        const { employeeId } = cellParams.row.original
        return <UserTableCell userId={employeeId} />
      },
      width: 2,
    },
    {
      Header: 'Email',
      accessor: 'email',
      filter: 'includesValue',
      Filter: SelectItemFilter,
      width: 2,
    },
    {
      Header: 'Limitation',
      accessor: (value) => mapLimitationTypeToLabel(value.type),
      id: 'type',
      filter: 'includesValue',
      Filter: SelectItemFilter,
      width: 2,
    },
    {
      Header: 'Subject',
      accessor: (value) => stringifyLimitationObject(value, customerCompanyMap),
      filter: 'includesValue',
      Filter: SelectItemFilter,
      width: 3,
    },
  ]

  if (
    !hasSomePermissions(
      DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
      DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
    )
  ) {
    return BASE_COLUMNS
  }

  return [
    ...BASE_COLUMNS,
    {
      Header: 'Action',
      id: 'action',
      Cell: ({ row }: { row: { original: DirectViewTable }; value: string }) => (
        <PermissionsCheck
          somePermissions={[
            DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
            DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
          ]}
        >
          <Action
            variant="text"
            onClick={(e) => {
              e.stopPropagation()
              onDeleteClick(row.original)
            }}
          >
            Delete
          </Action>
        </PermissionsCheck>
      ),
      align: 'center',
      width: 1,
    },
  ]
}
