import { CrossCheckDetail } from 'api/types/crossCheck'
import { useQueryEmployeeDetailsNotLimited } from 'queries/dart/employees'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { EmployeeDetailsNotLimited } from 'api/dart/employee'
import { useMemo, useState } from 'react'
import { Box, Subheader, Table } from '@revolut/ui-kit'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { makeGetChangeRowState } from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/utils'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../../../ChangeSwitcher'
import { getAccessModifierRows, getEmptyTableLabel } from './utils'
import { getColumns } from './columns'

export const CustomersOfEmployee = ({ crossCheck }: { crossCheck: CrossCheckDetail }) => {
  const {
    data: employeeDetails,
    status: employeeDetailsS,
    fetchStatus: employeeDetailsFS,
  } = useQueryEmployeeDetailsNotLimited(crossCheck.entityId)

  return (
    <QuerySwitch
      required={[{ qs: employeeDetailsS, fs: employeeDetailsFS }]}
      data
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view employee details" />
      )}
      renderLoading={() => <Inner crossCheck={crossCheck} />}
      renderSuccess={() => (
        <Inner crossCheck={crossCheck} employeeDetails={employeeDetails} />
      )}
    />
  )
}

const Inner = ({
  crossCheck,
  employeeDetails,
}: {
  crossCheck: CrossCheckDetail
  employeeDetails?: EmployeeDetailsNotLimited
}) => {
  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])

  const { rows, counters } = useMemo(
    () =>
      getAccessModifierRows({
        crossCheck,
        employeeDetails,
        tabs,
      }),
    [crossCheck, employeeDetails, tabs],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState(defaultTab)
  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])
  const data = rows[tab]
  const isLoading = !employeeDetails

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Customers</Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            tabs={tabs}
            currentTab={tab}
            counters={counters}
            onChange={setTab}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        loadingState={isLoading ? 'pending' : 'ready'}
        getRowState={getRowState}
        labelEmptyState={getEmptyTableLabel(tab)}
      />
    </Box>
  )
}
