import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { EmployeeRevoDescriptionGetter } from 'hooks/useGetEmployeeRevoDescription'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { sortBy } from 'lodash'
import { UserRow } from './types'

type GetEntitiesParams = {
  users: UserListItem[]
  avatars?: Map<string, RevolutersAvatar>
  getEmployeeDescription: EmployeeRevoDescriptionGetter
}

export const getEntities = ({
  users,
  getEmployeeDescription,
}: GetEntitiesParams): UserRow[] => {
  return sortBy(
    users.map((user) => {
      const employeeDescription = getEmployeeDescription(user.id)

      return {
        id: user.id,
        email: user.email,
        state: user.state,
        department: employeeDescription.department?.name || EMPTY_VALUE_FALLBACK,
        team: employeeDescription.team?.name || EMPTY_VALUE_FALLBACK,
      }
    }),
    'name',
  )
}
