import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { makeMappedKeyDataHook } from 'queries/utils'
import useLazyQuery from '../useLazyQuery'

export const useQueryClients = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Clients],
    queryFn: idaveApi.clients.getClients,
    enabled: hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST),
    select: (response) => response?.data,
  })
}

export const useQueryClientIdMap = makeMappedKeyDataHook(useQueryClients, 'id')

export const useQueryClient = (id?: string) => {
  const { hasPermission } = usePermissions()
  return useLazyQuery({
    params: id,
    queryKey: [QueryKey.Client, id],
    fetchFn: idaveApi.clients.getClient,
    enabled: hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_DETAILS),
  })
}

export const useQueryClientCustomization = (id?: string) => {
  const { hasPermission } = usePermissions()
  return useLazyQuery({
    params: id,
    queryKey: [QueryKey.ClientCustomization, id],
    fetchFn: idaveApi.clients.getClientCustomization,
    enabled: hasPermission(IDAVE_PERMISSIONS.CUSTOMIZATIONS_VIEW_CLIENTS_DETAILS),
  })
}

export const useQueryClientsCustomization = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.ClientsCustomization],
    queryFn: idaveApi.clients.getClientsCustomization,
    enabled: hasPermission(IDAVE_PERMISSIONS.CUSTOMIZATIONS_VIEW_CLIENTS_DETAILS),
    select: (response) => response?.data,
  })
}
