import {
  RevolutersDepartment,
  RevolutersEmployee,
  RevolutersTeam,
} from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'
import { getName } from 'utils/string/getName'
import { Employee } from './types'

type GetPolicyUsersParams = {
  employeesMap: Map<string, RevolutersEmployee>
  policyEmployees?: string[]
  departments: Map<string, RevolutersDepartment>
  teams: Map<string, RevolutersTeam>
  peopleOpsUsers: Map<string, UserListItem>
}

export const getPolicyUsers = (params: GetPolicyUsersParams) => {
  const { employeesMap, policyEmployees, departments, teams, peopleOpsUsers } = params

  return (policyEmployees || []).reduce<Employee[]>((employees, employeeId) => {
    const employee = employeesMap.get(employeeId)
    const user = peopleOpsUsers.get(employeeId)
    const employeeName = user ? getName(user) : employeeId

    const email = user?.email || '-'

    const departmentItem = employee ? departments.get(employee.departmentId) : undefined
    const department = departmentItem?.name || employee?.departmentId || '-'

    const teamItem = employee ? teams.get(employee.teamId) : undefined
    const team = teamItem?.name || employee?.teamId || '-'

    return [
      ...employees,
      {
        user: employeeName,
        email,
        department,
        team,
        id: employeeId,
        idaveId: employee?.iDaveUserId,
        status: employee?.status,
      },
    ]
  }, [])
}
