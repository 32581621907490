import { useCallback, useEffect, useState } from 'react'
import { useSideBox } from 'view/SideBox/SideBox'
import { SideAvatar } from 'view/SideBox'
import { PERMISSION_SIDE } from 'view/SideBox/hooks/useSideboxNavigation'
import { Body } from './components/Body'
import { Title } from './components/Title'
import { Actions } from './components/Actions'

export const usePermissionPreview = () => {
  const { openSide } = useSideBox()
  const [params, setParams] = useState<{ permissionId?: string; userId?: string }>()

  const onClose = useCallback(() => {
    setParams(undefined)
  }, [setParams])

  useEffect(() => {
    if (!params) {
      return undefined
    }

    const { permissionId, userId } = params

    if (!permissionId) {
      return undefined
    }

    const queryParams = {
      type: PERMISSION_SIDE,
      id: permissionId,
      subjectId: userId,
    }

    return openSide({
      body: <Body permissionId={permissionId} userId={userId} />,
      title: <Title permissionId={permissionId} />,
      avatar: <SideAvatar iconName="Controls" />,
      actions: <Actions permissionId={permissionId} />,
      queryParams,
      onClose,
    })
  }, [params, onClose, openSide])

  return {
    openPermissionSide: (permissionId?: string, userId?: string) =>
      setParams({ permissionId, userId }),
  }
}
