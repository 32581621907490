import { Page, VStack } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

import { Section } from './components/Section'
import { SECTIONS } from './constants'

export const System = () => {
  return (
    <>
      <Page.Header>System</Page.Header>
      <Page.Main>
        <VStack gap="s-24" maxWidth={MAXIMAL_DETAILS_WIDTH}>
          {SECTIONS.map((section) => (
            <Section key={section.title} {...section} />
          ))}
        </VStack>
      </Page.Main>
    </>
  )
}
