import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryCustomerCompanies } from 'queries/dart/companies'
import { mergeQueryStatuses } from 'utils/query'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { capitalizeFirst } from 'utils/string'
import { COLUMNS } from './columns'

export type CustomerCompanyMapItem = Omit<CustomerCompanyItem, 'serviceType'> & {
  serviceType: string
}

export const CustomerCompanies = () => {
  const navigate = useNavigate()
  const {
    data: customerCompanies = [],
    status: qs,
    fetchStatus: fs,
  } = useQueryCustomerCompanies()
  const loadingStatus = mergeQueryStatuses({ qs, fs })

  const formattedCompanies: CustomerCompanyMapItem[] = useMemo(
    () =>
      customerCompanies.map((company) => ({
        ...company,
        serviceType: capitalizeFirst(company.serviceType),
      })),
    [customerCompanies],
  )

  const loadingState = getLoadingState(loadingStatus, formattedCompanies.length)
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: formattedCompanies,
  })

  const onClick = useCallback(
    (item: CustomerCompanyMapItem) => {
      navigate(generatePath(Url.DartCustomerCompany, { companyId: item.id }))
    },
    [navigate],
  )

  const getRowLink = useCallback(
    (item: CustomerCompanyMapItem) =>
      generatePath(Url.DartCustomerCompany, { companyId: item.id }),
    [],
  )

  return (
    <EntitiesTable
      enableNavigation
      totalCount={formattedCompanies.length}
      entitiesTypeLabel="Companies"
      pluralForms={['company', 'companies']}
      data={searched}
      columns={COLUMNS}
      loadingState={loadingState}
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      searchAutoFocus
      getRowLink={getRowLink}
      onRowClick={onClick}
    />
  )
}
