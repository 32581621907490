import { IDAVE_PERMISSIONS } from 'security'
import { Url, UIRoute } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { System } from './System'
import { Groups } from './Groups'
import { Group } from './Group'
import { GroupAdd } from './GroupAdd'
import { GroupEdit } from './GroupEdit'

export const routes = ({
  hasPermission,
  hasEveryPermission,
}: PermissionUtils): Record<string, UIRoute> => ({
  [Url.System]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(IDAVE_PERMISSIONS.GROUPS_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: System,
  },

  [Url.SystemGroups]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(IDAVE_PERMISSIONS.GROUPS_VIEW_LIST),
    fallbackRoute: Url.System,
    Component: Groups,
  },

  [Url.SystemGroupAdd]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.GROUPS_CREATE),
    fallbackRoute: Url.SystemGroups,
    Component: GroupAdd,
  },

  [Url.SystemGroup]: {
    type: 'redirect',
    params: ['groupId'],
    newUrl: Url.SystemGroupDetails,
  },

  [Url.SystemGroupDetails]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.GROUPS_VIEW_DETAILS),
    fallbackRoute: Url.System,
    params: ['groupId'],
    Component: ({ groupId }: { groupId: string }) => (
      <Group tab="details" groupId={groupId} />
    ),
  },

  [Url.SystemGroupUsers]: {
    type: 'component',
    withParams: true,
    allowed: hasEveryPermission(
      IDAVE_PERMISSIONS.GROUPS_VIEW_DETAILS,
      IDAVE_PERMISSIONS.USERS_VIEW_LIST,
    ),
    fallbackRoute: Url.System,
    params: ['groupId'],
    Component: ({ groupId }: { groupId: string }) => (
      <Group tab="users" groupId={groupId} />
    ),
  },

  [Url.SystemGroupEdit]: {
    type: 'component',
    withParams: true,
    params: ['groupId'],
    allowed: hasPermission(IDAVE_PERMISSIONS.GROUPS_UPDATE),
    fallbackRoute: Url.SystemGroup,
    Component: GroupEdit,
  },
})
