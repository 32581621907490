import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { SamPolicyTrainings } from 'view/Sam/components/SamPolicyTrainings'
import { FormState } from '../types'

type SamCreatePolicyTrainingsProps = {
  mandatoryTrainings: FormState['mandatoryTrainings']
  setMandatoryTrainings: (trainings: FormState['mandatoryTrainings']) => void
  policyName: string
}

export const SamCreatePolicyTrainings = (props: SamCreatePolicyTrainingsProps) => {
  const { mandatoryTrainings, setMandatoryTrainings, policyName } = props
  const { hasPermission } = usePermissions()
  const showModify = hasPermission(SAM_PERMISSIONS.TRAININGS_VIEW_LIST)

  return (
    <SamPolicyTrainings
      key={mandatoryTrainings?.length}
      policyTrainigns={mandatoryTrainings}
      isPending={false}
      isActive
      updateTrainings={setMandatoryTrainings}
      showAddPermissions={showModify}
      showDeletePermissions
      policyName={policyName}
    />
  )
}
