import { DataMaps } from 'view/AuditLogs/types'
import { getEntityRows, Table } from '../../../../../../Table'
import { COLUMNS } from './columns'

export const Users = (props: {
  title: string
  userIds?: string[]
  dataMaps: DataMaps
}) => {
  const { title, userIds, dataMaps } = props
  if (!userIds) {
    return null
  }
  const usersRows = getEntityRows(userIds, 'User', dataMaps)

  if (!usersRows || !usersRows.length) {
    return null
  }

  return <Table title={title} data={usersRows} columns={COLUMNS} />
}
