import { Action, Table, TableColumn, Text, Token } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { differenceInDays } from 'date-fns'
import { pluralForm } from 'utils/string'
import { formatDate } from 'utils/date'
import { Role } from './types'

const BASE_COLUMNS: TableColumn<Role>[] = [
  {
    Header: 'Name',
    accessor: 'roleName',
    Cell: (params: { row: { original: Role } }) => {
      const role = params.row.original
      return (
        <Table.Cell>
          <Text>
            {role.roleName}
            {role.client ? (
              <Text color={Token.color.warning}>{` DEPRECATED (${role.client})`}</Text>
            ) : null}
          </Text>
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Assigned',
    accessor: 'assigned',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Granted until',
    accessor: 'expirationDate',
    Cell: (params: { row: { original: Role } }) => {
      const role = params.row.original
      if (!role.expirationDate) {
        return <Table.Cell>{EMPTY_VALUE_FALLBACK}</Table.Cell>
      }

      const date = formatDate(role.expirationDate)

      const daysCount = differenceInDays(role.expirationDate, Date.now())
      const daysLeft = pluralForm(daysCount, [
        `${daysCount} day left`,
        `${daysCount} days left`,
      ])

      return (
        <Table.Cell>
          <Text>{date}</Text>
          <Text color={Token.color.grey50}>{` · ${daysLeft}`}</Text>
        </Table.Cell>
      )
    },
  },
]

export const getColumns = (deleteAction?: (role: Role) => void) => {
  const actionColumn: TableColumn<Role> | undefined = deleteAction
    ? {
        Header: 'Action',
        id: 'action',
        Cell: (params: { row: { original: Role } }) => (
          <Action
            onClick={(e) => {
              e.stopPropagation()
              deleteAction(params.row.original)
            }}
          >
            Remove
          </Action>
        ),
        align: 'center',
      }
    : undefined

  return actionColumn ? [...BASE_COLUMNS, actionColumn] : BASE_COLUMNS
}
