import { get } from 'lodash'
import { notNullable } from 'utils/common'
import { ListKeyDescriptor } from './types'

export const KNONW_LIST_KEYS: ListKeyDescriptor[] = [
  {
    key: 'authorizedClients',
    title: 'Applications',
  },
  {
    key: 'redirectUris',
    title: 'Redirect URI',
  },
  {
    key: 'permissionIds',
    entity: 'Permission',
    title: 'Permissions',
  },
  {
    key: 'ownerIds',
    entity: 'User',
    title: 'Owners',
  },
  {
    key: 'userIds',
    entity: 'User',
    title: 'Users',
  },
  {
    key: 'roles',
    title: 'Roles',
    entity: 'Role',
    mapper: (roles: unknown[]) =>
      roles?.map((i) => get(i, 'roleId.id')).filter(notNullable),
  },
]
