import { Box, Subheader } from '@revolut/ui-kit'
import { AuditLogData } from 'api/types/auditLog'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'

export const LogInfo = (props: { log: Pick<AuditLogData, 'Event Id'> }) => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Event details</Subheader.Title>
      </Subheader>

      <DetailsGroup
        rows={[
          [
            'Log ID',
            <CopyableText
              value={props.log['Event Id'].value}
              key={props.log['Event Id'].value}
            >
              {props.log['Event Id'].value}
            </CopyableText>,
          ],
        ]}
      />
    </Box>
  )
}
