import { useCallback } from 'react'
import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form'

export const useSetValue = <T extends FieldValues, Event>(
  setValue: UseFormSetValue<T>,
  filedName: Path<T>,
  extractor: (event: Event) => PathValue<T, Path<T>>,
) =>
  useCallback(
    (event: Event) =>
      // setValue typing mismatch - value argument is required and can not be undefined by doc
      // https://react-hook-form.com/docs/useform/setvalue
      setValue(filedName, extractor(event) || (null as any), { shouldValidate: true }),
    [filedName, setValue, extractor],
  )
