import { SelectInput } from '@revolut/ui-kit'
import { CrossCheckGroup } from 'api/types/crossCheck'
import { useCallback, useMemo, useState } from 'react'
import { notNullableMap } from 'utils/array'

type Props = {
  groups: CrossCheckGroup[]
  disabled?: boolean
  onGroupChange: (groupId: string) => void
  defaultGroup?: string
}

const groupToOption = (group: CrossCheckGroup) => ({
  key: group.groupId,
  label: group.name,
  value: group.groupId,
})

export const GroupSelect = (props: Props) => {
  const { groups, onGroupChange, disabled, defaultGroup = null } = props

  const options = useMemo(() => notNullableMap(groups, groupToOption), [groups])
  const [value, setValue] = useState<string | null>(defaultGroup)
  const onChange = useCallback(
    (v: string | null) => {
      if (v) {
        setValue(v)
        onGroupChange(v)
      }
    },
    [setValue, onGroupChange],
  )

  return (
    <SelectInput
      label="Group"
      labelList="Groups"
      options={options}
      clearable={false}
      searchable={false}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  )
}
