import { useQueryClientIdMap } from 'queries/idave/clients'
import { TextSkeleton } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { QuerySwitch } from 'components/QuerySwitch'
import { useCallback } from 'react'
import { InternalLink } from '../InternalLink'

type Props = {
  id: string
}

export const ClientLink: React.FC<Props> = ({ id }) => {
  const { data: clientMap, status: qs, fetchStatus: fs } = useQueryClientIdMap()
  const href = generatePath(Url.Client, { clientId: id })
  const LinkComponent = useCallback(() => {
    const client = clientMap?.get(id)
    const textValue = client?.name || id

    return (
      <InternalLink href={href} title={textValue}>
        {textValue}
      </InternalLink>
    )
  }, [id, clientMap, href])

  return (
    <QuerySwitch
      data={clientMap}
      required={[{ qs, fs }]}
      renderError={LinkComponent}
      renderIdle={LinkComponent}
      renderLoading={() => (
        <InternalLink href={href}>
          <TextSkeleton size={8} />
        </InternalLink>
      )}
      renderSuccess={LinkComponent}
    />
  )
}
