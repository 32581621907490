import { accounts, CredentialResponse } from 'google-one-tap'

const params = {
  client_id:
    process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ||
    '641524086291-kl7036pv9d90qa40lgs61ng1uq75qcdt.apps.googleusercontent.com',
}

declare global {
  interface Window {
    google?: {
      accounts: accounts
    }
  }
}

const GoggleAuth = {
  init: (callback: (credential: CredentialResponse) => void) => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: params.client_id,
        ux_mode: 'popup',
        callback,
      })
    }
  },

  renderSignInButton: (parent: HTMLElement, isDarkMode: boolean) => {
    if (window.google) {
      window.google.accounts.id.renderButton(parent, {
        type: 'standard',
        logo_alignment: 'center',
        shape: 'pill',
        size: 'large',
        text: 'continue_with',
        theme: isDarkMode ? 'filled_black' : 'outline',
        width: 375,
      })
    }
  },

  signIn: () => {
    if (window.google) {
      window.google.accounts.id.prompt()
    }
  },
}

export default GoggleAuth
