import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { MentionToken } from 'hooks/useTokenize/types'
import { ActionButton } from '@revolut/ui-kit'
import { getName } from 'utils/string/getName'
import { getUserProps, mapTokensToUsers } from './utils'

export const Mentions = (props: {
  mentions: MentionToken[]
  emailUserMap?: Map<string, UserListItem>
  avatars?: Map<string, RevolutersAvatar>
}) => {
  const { mentions, emailUserMap, avatars } = props
  const mentionedUsers = mapTokensToUsers(mentions, emailUserMap, avatars)

  return (
    <>
      {mentionedUsers.map(({ user, avatar, email }, idx) => (
        <Mention user={user} avatar={avatar} email={email} key={idx} />
      ))}
    </>
  )
}

const Mention = (props: { email: string; user?: UserListItem; avatar?: string }) => {
  const { email, user, avatar } = props

  return (
    <ActionButton
      mr="s-4"
      mb="s-4"
      display="inline-flex"
      size="xs"
      {...getUserProps(user, avatar)}
      disabled={!user}
    >
      {user ? getName(user) : `Unknown (${email})`}
    </ActionButton>
  )
}
