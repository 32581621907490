import { MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { QueryKey } from 'helpers/configQuery'
import { useCallback } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getCrossCheckApi } from 'view/CrossChecks/utils'
import { canExecute, getButtonAppearance } from './utils'

type Props = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
}

export const RetryAction = (props: Props) => {
  const { crossCheck, source } = props

  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: (id: string) => {
      const api = getCrossCheckApi(source)
      return api.retry(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.CrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.SamCrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.DartCrossCheckDetails, crossCheck?.id])
    },
  })

  const onClick = useCallback(() => mutate(crossCheck.id), [mutate, crossCheck.id])
  const permissionUtils = usePermissions()
  const buttonAppearance = getButtonAppearance({ source })

  if (!canExecute({ source, crossCheck, permissionUtils })) {
    return null
  }

  return (
    <MoreBar.Action
      useIcon={buttonAppearance.iconName}
      onClick={onClick}
      pending={isLoading}
      disabled={isLoading}
    >
      {buttonAppearance.label}
    </MoreBar.Action>
  )
}
