import { Skeleton, VStack, HStack, Header } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

export const SamPolicyCreateSkeleton = (props: { onClose: () => void }) => {
  return (
    <>
      <Header variant="secondary">
        <Header.BackButton onClick={props.onClose} />
        <Header.Title>
          <Skeleton height="12px" width="200px" />
        </Header.Title>
      </Header>
      <VStack space="s-24" pt="s-24" maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <HStack>
          <Skeleton height="36px" width="100px" />
          <Skeleton height="36px" width="100px" />
          <Skeleton height="36px" width="100px" />
          <Skeleton height="36px" width="100px" />
        </HStack>
        <Skeleton height="56px" />
        <Skeleton height="56px" />
        <Skeleton height="56px" />
        <Skeleton height="56px" />
        <Skeleton height="56px" />
      </VStack>
    </>
  )
}
