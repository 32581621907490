import { notNullable } from 'utils/common'
import { DetailRow } from '../../../types'
import { DetailParams } from '../types'
import { getTeam } from './getTeam'
import { getSpec } from './getSpec'

export const getUserInfo = (params: DetailParams): DetailRow[] => {
  if (params.entity.type !== 'User') {
    return []
  }

  // user info is immutable, so every helper returns only actual value
  return [getTeam(params), getSpec(params)].filter(notNullable)
}
