import { UserListItem } from 'api/idave/user'
import { EmployeeRevoDescriptionGetter } from 'hooks/useGetEmployeeRevoDescription'
import { UserInfo } from './types'

type Params = {
  users: UserListItem[]
  getEmployeeDescription: EmployeeRevoDescriptionGetter
}
export const getRows = ({ users, getEmployeeDescription }: Params): UserInfo[] => {
  return users.map((user) => {
    const description = getEmployeeDescription(user.id)

    return {
      ...user,
      team: description.team?.name,
      department: description.department?.name,
    }
  })
}
