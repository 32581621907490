import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { Role } from 'api/idave/roles'
import { QueryKey } from 'helpers/configQuery'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'

export const useEditRole = () => {
  const { showSuccessToast } = useToasts()
  const navigate = useNavigate()
  const qClient = useQueryClient()
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    IDAVE_PERMISSIONS.ROLES_UPDATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_ROLE_UPDATE_ACTION,
  )
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  return useMutation({
    mutationFn: (newRole: Role) =>
      idaveApi.roles.editRole({
        payload: newRole,
        crossCheck: isCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Updating...' }),
    onSuccess: (response, { id: roleId }) => {
      hideLoadingPopup()
      const crosscheckId = getCrossCheckIdFromResponse(response)
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        qClient.invalidateQueries({
          queryKey: [QueryKey.Roles],
        })
        qClient.invalidateQueries({
          queryKey: [QueryKey.Role, roleId],
          refetchType: 'all',
        })
        qClient.resetQueries({ queryKey: [QueryKey.AuditLogs, roleId] })
        showSuccessToast('Role successfully updated')
        navigate(generatePath(Url.Role, { roleId }))
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Role update failed',
        error: err,
      })
    },
  })
}
