import { UserListItem } from 'api/idave/user'
import { TableFilters, TableSortingRule } from '@revolut/ui-kit'
import { isEqual } from 'lodash'
import { CrossCheckRow, CrossChecksTab } from '../../types'
import { ReviewerFilter } from './components/ReviewerFilter'
import { FILTER_SUPPORT, SORT_DATE_ASC } from './consts'

export const makeFilters =
  (
    tab: CrossChecksTab,
    setUserFilter: (requestFilters?: UserListItem) => void,
    userFilter?: UserListItem,
  ) =>
  () =>
    tab === 'all' ? (
      <ReviewerFilter setUserFilter={setUserFilter} userFilter={userFilter} />
    ) : null

export const getOverdueActive = (
  filters?: TableFilters<CrossCheckRow>,
  sorts?: TableSortingRule<CrossCheckRow>[],
) => {
  const isFiltered = isEqual(filters, FILTER_SUPPORT)
  const isSorted = isEqual(sorts, SORT_DATE_ASC)

  return isFiltered && isSorted
}
