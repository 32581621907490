import { CrossCheckDetail } from 'api/types/crossCheck'
import { EmployeeCompany } from 'api/dart/employeeCompanies'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { useQueryCustomerCompanyMap } from 'queries/dart/companies'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { useMemo, useState } from 'react'
import { Box, Subheader, Table } from '@revolut/ui-kit'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { makeGetChangeRowState } from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/utils'
import { getAccessModifierRows, getEmptyTableLabel } from './utils'
import { getColumns } from './columns'
import {
  CrosscheckTabSwitcher,
  getSwitcherTabs,
  getDefaultTab,
} from '../../../../../ChangeSwitcher'

export const CustomerCompaniesOfEmployeeCompany = ({
  crossCheck,
  entity,
}: {
  crossCheck: CrossCheckDetail
  entity?: EmployeeCompany
}) => {
  const {
    data: customerCompaniesMap,
    status: customerCompaniesS,
    fetchStatus: customerCompaniesFS,
  } = useQueryCustomerCompanyMap()

  return (
    <QuerySwitch
      required={[{ qs: customerCompaniesS, fs: customerCompaniesFS }]}
      data
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view customer companies" />
      )}
      renderLoading={() => <Inner crossCheck={crossCheck} />}
      renderSuccess={() => (
        <Inner
          crossCheck={crossCheck}
          entity={entity}
          customerCompaniesMap={customerCompaniesMap}
        />
      )}
    />
  )
}

const Inner = ({
  crossCheck,
  entity,
  customerCompaniesMap = new Map(),
}: {
  crossCheck: CrossCheckDetail
  entity?: EmployeeCompany
  customerCompaniesMap?: Map<string, CustomerCompanyItem>
}) => {
  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])

  const { rows, counters } = useMemo(
    () =>
      getAccessModifierRows({
        crossCheck,
        entity,
        customerCompaniesMap,
        tabs,
      }),
    [crossCheck, entity, customerCompaniesMap, tabs],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState(defaultTab)

  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])
  const data = rows[tab]
  const isLoading = !customerCompaniesMap.size

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>
          Customer companies
        </Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            tabs={tabs}
            currentTab={tab}
            counters={counters}
            onChange={setTab}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        loadingState={isLoading ? 'pending' : 'ready'}
        getRowState={getRowState}
        labelEmptyState={getEmptyTableLabel(tab)}
      />
    </Box>
  )
}
