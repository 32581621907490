import { EMAIL_REGEX } from 'constants/string'

export const RULE_REQUIRED = { required: true }

export const getEmailRule = (message: string) => ({
  pattern: {
    value: EMAIL_REGEX,
    message,
  },
})
