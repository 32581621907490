import { ToxicPair } from 'api/idave/toxic'
import { uniq } from 'lodash'
import { notNullableMap } from 'utils/array'
import { PermissionIdPair } from 'view/Permissions/types'

/**
 * returns toxic pair ids for permission pairs
 */
export const matchToxicPairIdsByPermissionPairs = (
  toxicPermissionPairs: PermissionIdPair[],
  toxicPairs: ToxicPair[],
) => {
  return uniq(
    toxicPermissionPairs.flatMap(([first, second]) => {
      return notNullableMap(toxicPairs, ({ firstPermission, secondPermission, id }) => {
        return (firstPermission === first && secondPermission === second) ||
          (firstPermission === second && secondPermission === first)
          ? id
          : undefined
      })
    }),
  )
}
