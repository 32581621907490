import { filterActiveIds } from 'components/EntitiesTable/utils'
import { MoreBar } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'hooks/useToasts'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { AddCompanyModifier } from 'api/dart/types'
import { DART_PERMISSIONS } from 'security'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { EmployeeCompany } from '../../types'
import {
  getActionDisability,
  getModifierIds,
  getAddPopup,
  getRevokePopup,
  getEmployeeCompanyModifiers,
} from './utils'

type Props = {
  reset: () => void
  selectedHash: Record<string, boolean>
  companyId?: string
  isEditable: boolean
  entities: EmployeeCompany[]
}

export const EmployeeCompanyEditActions = ({
  selectedHash,
  companyId,
  isEditable,
  entities,
  reset,
}: Props) => {
  const { setPopup, closePopup } = useConfirmationPopup()
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { hideLoadingPopup, showLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const activeIds = filterActiveIds(selectedHash)

  const { isCrossCheck: isCrossCheckAdd, toCrosscheck: toCrosscheckAdd } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_UPDATE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_UPDATE_ACCESS_MODIFIER_CROSS_CHECK,
  )

  const { isCrossCheck: isCrossCheckDelete, toCrosscheck: toCrossCheckDelete } =
    useCrossCheck(
      DART_PERMISSIONS.EMPLOYEE_COMPANIES_DELETE_ACCESS_MODIFIER,
      DART_PERMISSIONS.EMPLOYEE_COMPANIES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
    )

  const { mutate: allow, isLoading: isAdding } = useMutation({
    mutationFn: (modifiers: AddCompanyModifier[]) =>
      dartApi.employeeCompany.addModifiers({
        payload: modifiers,
        crossCheck: isCrossCheckAdd,
      }),
    onMutate: () => showLoadingPopup({ title: 'Requesting access allowance...' }),
    onSuccess: (response) => {
      const crosscheckId = getCrossCheckIdFromResponse(response)
      hideLoadingPopup()
      closePopup()
      if (isCrossCheckAdd && crosscheckId) {
        toCrosscheckAdd(crosscheckId)
      } else {
        showSuccessToast('Access allowed')
        reset()
        queryClient.invalidateQueries([QueryKey.CustomerCompany, companyId])
        queryClient.invalidateQueries([QueryKey.EmployeeCompanies])
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Access allowance failed', error: err })
      closePopup()
    },
  })

  const { mutate: revoke, isLoading: isRevoking } = useMutation({
    mutationFn: (modifiersIds: string[]) =>
      dartApi.employeeCompany.deleteModifiers({
        payload: modifiersIds,
        crossCheck: isCrossCheckDelete,
      }),
    onMutate: () => showLoadingPopup({ title: 'Revoking access...' }),
    onSuccess: (response) => {
      const crosscheckId = getCrossCheckIdFromResponse(response)
      hideLoadingPopup()
      closePopup()
      if (isCrossCheckDelete && crosscheckId) {
        toCrossCheckDelete(crosscheckId)
      } else {
        showSuccessToast('Access revoked')
        reset()
        queryClient.invalidateQueries([QueryKey.CustomerCompany, companyId])
        queryClient.invalidateQueries([QueryKey.EmployeeCompanies])
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Access revoking failed', error: err })
      closePopup()
    },
  })

  const { disableAdd, disableDelete } = getActionDisability(activeIds, entities)

  const allowAccess = useCallback(() => {
    setPopup({
      ...getAddPopup(activeIds.length),
      onConfirmClick: () => {
        if (companyId) {
          allow(
            getEmployeeCompanyModifiers({
              employeeCompanyIds: activeIds,
              customerCompanyId: companyId,
            }),
          )
        }
      },
    })
  }, [allow, setPopup, activeIds, companyId])

  const revokeAccess = useCallback(() => {
    const accessModifierIds = getModifierIds(activeIds, entities)
    setPopup({
      ...getRevokePopup(accessModifierIds.length),
      onConfirmClick: () => revoke(accessModifierIds),
    })
  }, [revoke, setPopup, activeIds, entities])

  if (!companyId) {
    return null
  }

  if (!isEditable) {
    return null
  }

  if (!activeIds.length) {
    return null
  }

  return (
    <>
      <MoreBar.Action
        onClick={allowAccess}
        disabled={disableAdd}
        pending={isAdding}
        useIcon="16/CheckCircle"
      >
        Allow access
      </MoreBar.Action>
      <MoreBar.Action
        onClick={revokeAccess}
        disabled={disableDelete}
        pending={isRevoking}
        useIcon="CrossVoid"
      >
        Revoke access
      </MoreBar.Action>
    </>
  )
}
