import { matchPath } from 'react-router'
import { Url } from 'routing'
import { useQueryCrossCheckDetails } from 'queries/idave/crossChecks'
import { Action } from './types'

const ACTION_CONFIG: Record<string, Action[]> = {
  [Url.SamPoliciesCreate]: [{ type: 'info', page: 'createPolicy', autoOpen: true }],
  [Url.SamPoliciesCreateCopy]: [{ type: 'info', page: 'createPolicy', autoOpen: true }],
  [Url.SamPoliciesEdit]: [{ type: 'info', page: 'updatePolicy', autoOpen: true }],
  [Url.AddRole]: [{ type: 'info', page: 'createRole', autoOpen: true }],
  [Url.EditRole]: [{ type: 'info', page: 'updateRole', autoOpen: true }],
  [Url.ClientCreate]: [{ type: 'info', page: 'createClient', autoOpen: true }],
  [Url.ClientEdit]: [{ type: 'info', page: 'updateClient', autoOpen: true }],
  [Url.UserRoles]: [{ type: 'info', page: 'userRoles' }],
  [Url.CrossCheckIdaveDetails]: [{ type: 'info', page: 'assignRoleRequest' }],
}

export const useHeaderActionsValues = (): Action[] => {
  const key = Object.keys(ACTION_CONFIG).find((path) => {
    return matchPath({ path }, window.location.pathname)
  })

  const showInfo = useShouldShowInfo(key)
  return key && showInfo ? ACTION_CONFIG[key] : []
}

export const useShouldShowInfo = (pageKey?: string) => {
  /**
   * Room for improvement here
   * Rework it if behavior became more complex than just:
   *  - show info for pages in config
   *  - show info for xCheck page only if it's role assignment xcheck
   */

  const isIdaveCrossCheck = pageKey === Url.CrossCheckIdaveDetails
  const match = pageKey
    ? matchPath({ path: pageKey }, window.location.pathname)
    : undefined
  const crossCheckId = isIdaveCrossCheck ? match?.params?.crossCheckId || '' : ''
  const { data: crossCheck } = useQueryCrossCheckDetails({
    params: crossCheckId,
    options: {
      enabled: !!crossCheckId,
    },
  })

  return isIdaveCrossCheck ? crossCheck?.executableName === 'RoleAssign' : !!pageKey
}
