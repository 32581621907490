import { ActionButton } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { AssignPopup } from './components/AssignPopup'
import { canAssignReviewer, getAssignableGroups } from './utils'
import { useAssign } from './useAssign'

type Props = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
  groupId?: string
}

export const AssignAction = (props: Props) => {
  const { crossCheck, source, groupId } = props

  const { setPopup } = useEditPopup()
  const { submit } = useAssign({ crossCheck, source })
  const openAssignPopup = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setPopup({
        title: 'Add approver',
        content: (
          <AssignPopup
            source={source}
            groups={getAssignableGroups(source, crossCheck)}
            onSubmit={submit}
            defaultGroup={groupId}
            crossCheck={crossCheck}
          />
        ),
      })
    },
    [source, crossCheck, groupId, submit, setPopup],
  )
  const permissionUtils = usePermissions()

  if (!canAssignReviewer(source, crossCheck, permissionUtils)) {
    return null
  }

  return <ActionButton useIcon="AddContact" onClick={openAssignPopup} />
}
