import { SelectOptionItemType } from '@revolut/ui-kit'
import { Action } from './types'

export const COMMENT: SelectOptionItemType<Action> = {
  key: 'comment',
  label: 'Comment',
  value: 'comment',
}

export const REQUEST_INFO: SelectOptionItemType<Action> = {
  key: 'request_info',
  label: 'Request info',
  value: 'request_info',
}

export const PROVIDE_INFO: SelectOptionItemType<Action> = {
  key: 'provide_info',
  label: 'Provide info',
  value: 'provide_info',
}
