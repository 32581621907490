import { Action, Page, Button } from '@revolut/ui-kit'
import api from 'api/sam'
import { atom, useAtom } from 'jotai'
import { useQueryResources } from 'queries/sam/resources'
import { useQueryPolicyMap } from 'queries/sam/policies'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { notNullableMap } from 'utils/array'
import { SamPolicyResources } from 'view/Sam/components/SamPolicyResources'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { pluralForm } from 'utils/string'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { fullfilledRejectedSplit, fullfillSettledWithParams } from 'utils/promise'
import { makeInvalidationPredicate } from 'utils/query'
import { useSideBox } from 'view/SideBox/SideBox'
import { SamSidePolicyList } from 'view/Sam/components/SamSidePolicyList'
import { generatePath } from 'react-router'
import { randomKey } from 'utils/common/randomKey'
import { getResourceUpdateParams } from './utils'

export const policyIdsAtom = atom<string[]>([])

export const SamPoliciesAddResources = () => {
  const { hasEveryPermission } = usePermissions()
  const { data: policyMap = new Map(), status } = useQueryPolicyMap()
  const [policyIds, setPolicyIds] = useAtom(policyIdsAtom)
  const [resourceIds, setResourceIds] = useState<string[]>([])
  const navigate = useNavigate()
  const { showSuccessToast } = useToasts()
  const { showErrorPopup, hideErrorPopup } = useErrorPopup()
  const queryClient = useQueryClient()
  const { openSide, closeSide } = useSideBox()
  const { data: resources = [] } = useQueryResources()
  const [resetKey, setResetKey] = useState(randomKey())
  const updateResourceIds = useCallback(
    (ids: string[]) => {
      setResourceIds(ids)
      setResetKey(randomKey())
    },
    [setResourceIds, setResetKey],
  )

  const { mutate, status: mutateStatus } = useMutation({
    mutationFn: () => {
      return fullfillSettledWithParams(
        getResourceUpdateParams(policyIds, policyMap, resourceIds, resources),
        api.policies.updatePolicyResources,
      )
    },
    onSuccess: (promises) => {
      const { rejected, fullfilled } = fullfilledRejectedSplit(promises)
      queryClient.resetQueries({
        predicate: makeInvalidationPredicate(
          QueryKey.SamPolicy,
          fullfilled.map((f) => [f.policyId]),
        ),
      })

      setPolicyIds(rejected.map((r) => r.policyId))
      if (rejected.length) {
        showErrorPopup({
          title: pluralForm(policyIds, [
            `Policy wasn't updated`,
            `${rejected.length} of ${policyIds.length} weren't updated`,
          ]),
          message: pluralForm(policyIds, [
            'Click “Try again” to apply changes to un-updated policy or close and change your request.',
            'Click “Try again” to apply changes to un-updated policies or close and change your request.',
          ]),
          continueLabel: 'Close',
          retryLabel: 'Try again',
          onRetryClick: () => {
            hideErrorPopup()
            mutate()
          },
        })
      } else {
        showSuccessToast('Policies updated')
      }
    },
  })

  const onBackClick = useCallback(() => {
    setPolicyIds([])
    closeSide()
    navigate(Url.SamPolicies)
  }, [navigate, setPolicyIds, closeSide])

  const showPolicies = useCallback(
    () =>
      openSide({
        title: 'Policies preview',
        body: (
          <SamSidePolicyList
            policies={notNullableMap(policyIds, (id) => policyMap.get(id))}
          />
        ),
      }),
    [policyIds, policyMap, openSide],
  )

  useEffect(() => {
    if (!policyIds.length) {
      closeSide()
      // redirect to Policies if policyIds is empty
      navigate(generatePath(Url.SamPolicies), {
        replace: true,
      })
    }
  }, [closeSide, policyIds.length, navigate])

  const policyNames = policyIds
    .map((id) => policyMap.get(id)?.policyName || id)
    .join(', ')

  return (
    <>
      <Page.Header
        onBack={onBackClick}
        description={
          <Action onClick={showPolicies}>
            {pluralForm(policyIds, ['1 policy', `${policyIds.length} policies`])}
          </Action>
        }
      >
        Bulk resources adding
      </Page.Header>
      <Page.Main>
        <SamPolicyResources
          showAddPermissions={hasEveryPermission(
            SAM_PERMISSIONS.POLICIES_UPDATE_RESOURCES,
          )}
          showDeletePermissions
          addResources={updateResourceIds}
          deleteResources={updateResourceIds}
          policyResources={resourceIds}
          policyStatus={status}
          policyName={policyNames}
          emptyStatusTitle={pluralForm(policyIds, [
            'No resources added to this policy',
            'No resources added to this policies',
          ])}
          allowRemoveAllResources
          isActive
          key={resetKey}
        />
        <Page.MainActions>
          <Button
            onClick={() => {
              mutate()
              closeSide()
            }}
            variant="primary"
            elevated
            pending={mutateStatus === 'loading'}
            disabled={!resourceIds.length}
          >
            {pluralForm(resourceIds, [
              'Add 1 resource',
              `Add ${resourceIds.length} resources`,
            ])}
          </Button>
        </Page.MainActions>
      </Page.Main>
    </>
  )
}
