import { Button } from '@revolut/ui-kit'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { useEditPopup } from 'hooks/useEditPopup'
import { useTokenize } from 'hooks/useTokenize'
import { useQueryUserEmailMap, useQueryUserIdMap } from 'queries/idave/users'
import { QuerySwitch } from 'components/QuerySwitch'
import { useCallback, useMemo } from 'react'
import { getBubbleUpUsers, getButtonProps } from './utils'
import { NotifeeSelectPopup } from './NotifeeSelectPopup'

export const NotifeeSelect = (props: {
  emails: string[]
  setEmails: (notifees: string[]) => void
  crossCheck: CrossCheckDetail
}) => {
  const { emails, setEmails, crossCheck } = props

  const { closePopup, setPopup } = useEditPopup()

  const { data: userIdMap = new Map(), status: qs, fetchStatus: fs } = useQueryUserIdMap()
  const { data: userEmailMap = new Map() } = useQueryUserEmailMap()

  const { text } = useMemo(
    () =>
      getButtonProps({
        emails,
        userEmailMap,
      }),
    [emails, userEmailMap],
  )

  const notifees = useMemo(
    () =>
      emails.reduce<string[]>((acc, email) => {
        const user = userEmailMap.get(email)
        return user ? acc.concat(user.id) : acc
      }, []),
    [emails, userEmailMap],
  )

  const onChange = useCallback(
    (ids: string[]) => {
      const newEmails = ids.reduce<string[]>((acc, id) => {
        const user = userIdMap.get(id)
        return user ? acc.concat(user.email) : acc
      }, [])

      setEmails(newEmails)
    },
    [userIdMap, setEmails],
  )
  const { tokenize } = useTokenize()
  const bubbleUpUsers = useMemo(
    () => getBubbleUpUsers({ crossCheck, tokenize, userEmailMap }),
    [crossCheck, tokenize, userEmailMap],
  )
  const openNotifeePopup = useCallback(() => {
    setPopup({
      title: 'Add users to notify',
      content: (
        <NotifeeSelectPopup
          onChange={onChange}
          onSubmitClick={closePopup}
          defaultNotifees={notifees}
          bubbleUpUsers={bubbleUpUsers}
        />
      ),
    })
  }, [bubbleUpUsers, notifees, onChange, closePopup, setPopup])

  return (
    <QuerySwitch
      data
      required={[{ qs, fs }]}
      renderError={() => null}
      renderIdle={() => null}
      renderLoading={() => <Button variant="secondary" pending />}
      renderSuccess={() => (
        <Button variant="secondary" onClick={openNotifeePopup} useIcon="AddContact">
          {text}
        </Button>
      )}
    />
  )
}
