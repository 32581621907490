import { EntitiesTable } from 'components/EntitiesTable'
import { useQueryUser } from 'queries/idave/users'
import { useQueryPermissionIdMap } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useCallback, useMemo } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { usePermissionPreview } from 'components/previews/PermissionPreview'
import { getPermissionRows } from './utils'
import { COLUMNS } from './columns'
import { PermissionRow } from './types'

type UserPermissionsProps = {
  userId: string
}

export const UserPermissions = (props: UserPermissionsProps) => {
  const {
    data: roleMap = new Map(),
    status: rolesStatus,
    fetchStatus: rolesFS,
  } = useQueryRoleIdMap()

  const {
    data: permissionsMap = new Map(),
    status: permissionsStatus,
    fetchStatus: permissionFS,
  } = useQueryPermissionIdMap()

  const {
    data: user,
    status: userStatus,
    fetchStatus: userFS,
  } = useQueryUser({ id: props.userId })

  const {
    data: clientMap = new Map(),
    status: clientStatus,
    fetchStatus: clientFS,
  } = useQueryClientIdMap()

  const rows = useMemo(
    () =>
      getPermissionRows({
        clientMap,
        permissionsMap,
        roleMap,
        user,
      }),
    [clientMap, permissionsMap, roleMap, user],
  )

  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities: rows,
  })
  const { openPermissionSide } = usePermissionPreview()
  const onRowClick = useCallback(
    (permission: PermissionRow) => {
      openPermissionSide(permission.id, props.userId)
    },
    [openPermissionSide, props.userId],
  )

  const loadingStatus = mergeQueryStatuses(
    { qs: rolesStatus, fs: rolesFS },
    { qs: permissionsStatus, fs: permissionFS },
    { qs: userStatus, fs: userFS },
    { qs: clientStatus, fs: clientFS },
  )

  return (
    <EntitiesTable
      totalCount={rows.length}
      entitiesTypeLabel="Permissions"
      pluralForms={['permission', 'permissions']}
      loadingState={getLoadingState(loadingStatus, rows.length)}
      columns={COLUMNS}
      data={searched}
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      searchAutoFocus
      onRowClick={onRowClick}
    />
  )
}
