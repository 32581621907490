import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useToasts } from 'hooks/useToasts'
import { IDAVE_PERMISSIONS } from 'security'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'

export const useTerminateAccount = () => {
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { closePopup } = useConfirmationPopup()
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_TERMINATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_SERVICE_ACCOUNT_TERMINATE_ACTION,
  )
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  return useMutation({
    mutationFn: (id: string) =>
      idaveApi.accounts.terminateAccount({
        payload: { id },
        crossCheck: isCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Terminating...' }),
    onSuccess: (response, id) => {
      hideLoadingPopup()
      closePopup()
      const crosscheckId = getCrossCheckIdFromResponse(response)
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        showSuccessToast('Service account terminated')
        queryClient.resetQueries({
          queryKey: [QueryKey.Account, id],
          type: 'all',
        })
      }
    },
    onError: (error) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({
        title: 'Service account termination failed',
        error,
      })
    },
  })
}
