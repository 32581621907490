import dartApi from 'api/dart'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useToasts } from 'hooks/useToasts'
import { useEditPopup } from 'hooks/useEditPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { DART_PERMISSIONS } from 'security'
import { DELETE_POPUP } from './texts'
import { UserRestrictionCreate } from './components/UserRestrictionCreate'

export const useCreateRestriction = ({
  employeeId,
  onSuccess,
}: {
  employeeId?: string
  onSuccess: () => void
}) => {
  const { setPopup } = useEditPopup()
  const { showSuccessToast } = useToasts()

  const onCreateRestriction = useCallback(
    () =>
      setPopup({
        content: (
          <UserRestrictionCreate
            employeeId={employeeId}
            showSuccessToast={showSuccessToast}
            onSuccess={onSuccess}
          />
        ),
        title: 'Add user restriction',
      }),
    [employeeId, onSuccess, setPopup, showSuccessToast],
  )

  return { onCreateRestriction }
}

export const useDeleteRestriction = (onSuccess: () => void) => {
  const { showSuccessToast } = useToasts()
  const { setPopup: setConfirmation, closePopup: closeConfirmation } =
    useConfirmationPopup()

  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
  )

  const { mutate: deleteModifier } = useMutation({
    mutationFn: (modifiersIds: string[]) =>
      dartApi.employee.deleteModifiers({
        payload: modifiersIds,
        crossCheck: isCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
    onSuccess: (response) => {
      const crosscheckId = getCrossCheckIdFromResponse(response)
      hideLoadingPopup()
      closeConfirmation()
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        showSuccessToast('Restriction deleted')
        onSuccess()
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      closeConfirmation()
      showErrorPopup({
        title: 'Restriction deletion failed',
        error: err,
      })
    },
  })

  const onDeleteRestriction = useCallback(
    (id: string) => {
      setConfirmation({
        ...DELETE_POPUP,
        onConfirmClick: () => deleteModifier([id]),
      })
    },
    [setConfirmation, deleteModifier],
  )

  return { onDeleteRestriction }
}
