import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useCallback } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { Client, ClientCustomization } from 'api/idave/clients'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { useDeleteClient } from './useDeleteClient'
import { useResetCustomization } from './useResetCustomization'
import { getClientActions } from '../utils'

export const useClientActions = ({
  client,
  customization,
}: {
  client: Client
  customization?: ClientCustomization
}) => {
  const { setPopup } = useConfirmationPopup()

  const { mutate: removeClient } = useDeleteClient()
  const { mutate: resetCustomization } = useResetCustomization()

  const navigate = useNavigate()
  const onEditClick = useCallback(() => {
    navigate(generatePath(Url.ClientEdit, { clientId: client.id }))
  }, [client.id, navigate])

  const onDeleteClick = useCallback(
    () =>
      setPopup({
        title: 'Application deletion confirmation',
        confirmButtonText: 'Delete',
        message: 'Are you sure you want to delete the application?',
        onConfirmClick: () => {
          removeClient(client.id)
        },
      }),
    [setPopup, removeClient, client.id],
  )

  const onResetClick = useCallback(
    () =>
      setPopup({
        title: 'Customization reset confirmation',
        confirmButtonText: 'Reset',
        message: 'Are you sure you want tot reset the app customization?',
        onConfirmClick: () => {
          resetCustomization(client.id)
        },
      }),
    [resetCustomization, setPopup, client.id],
  )
  const permissionUtils = usePermissions()

  return getClientActions({
    permissionUtils,
    client,
    customization,
    onEditClick,
    onDeleteClick,
    onResetClick,
  })
}
