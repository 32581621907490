import { mapify, notNullableMap } from 'utils/array'
import { pluralForm } from 'utils/string'
import { AddCompanyModifier } from 'api/dart/types'
import { EmployeeCompany } from '../../types'

export const getActionDisability = (activeIds: string[], entities: EmployeeCompany[]) => {
  if (!activeIds.length) {
    return { disableAdd: true, disableDelete: true }
  }

  const entityMap = mapify(entities, (e) => e.id)
  const isAllowed = !!entityMap.get(activeIds[0])?.accessModifierId
  const isAllSame = activeIds.every(
    (id) => !!entityMap.get(id)?.accessModifierId === isAllowed,
  )

  return {
    disableAdd: !isAllSame || isAllowed,
    disableDelete: !isAllSame || !isAllowed,
  }
}

export const getModifierIds = (activeIds: string[], entities: EmployeeCompany[]) => {
  const entityMap = mapify(entities, (e) => e.id)
  return notNullableMap(activeIds, (id) => entityMap.get(id)?.accessModifierId)
}

export const getAddPopup = (companyCount: number) => ({
  title: 'Are you sure you want to allow access to this customer company?',
  message: pluralForm(companyCount, [
    'Users of added employee company will have access to this customer company data.',
    'Users of added employee companies will have access to this customer company data.',
  ]),
  confirmButtonText: 'Allow',
})

export const getRevokePopup = (companyCount: number) => ({
  title: 'Are you sure you want to revoke access to this customer company? ',
  message: pluralForm(companyCount, [
    'Users of revoked employee company will not have access to selected customer company data.',
    'Users of revoked employee companies will not have access to selected customer company data.',
  ]),
  confirmButtonText: 'Revoke',
})

export const getEmployeeCompanyModifiers = (params: {
  employeeCompanyIds: string[]
  customerCompanyId: string
}): AddCompanyModifier[] => {
  const { employeeCompanyIds, customerCompanyId } = params

  return employeeCompanyIds.map((employeeCompanyId) => ({
    employeeCompanyId,
    accessModifier: {
      type: 'ALLOWED',
      entityId: {
        type: 'COMPANY',
        id: customerCompanyId,
      },
    },
  }))
}
