import { Button, Popup, VStack } from '@revolut/ui-kit'
import { UserList } from 'components/UserList'
import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback, useState } from 'react'

export const AddUserPopup = (props: { add: (userIds: string[]) => void }) => {
  const { add } = props

  const [userIds, setUserIds] = useState<string[]>([])

  const onAddClick = useCallback(() => add(userIds), [add, userIds])

  const { scrollRef } = useEditPopup()

  const count = userIds.length
  const disabled = !count

  return (
    <>
      <VStack space="s-16">
        <UserList onChange={setUserIds} variant="checkbox" scrollRef={scrollRef} />
      </VStack>

      <Popup.Actions>
        <Button elevated onClick={onAddClick} disabled={disabled}>
          {count ? `Add ${count} selected` : 'Add'}
        </Button>
      </Popup.Actions>
    </>
  )
}
