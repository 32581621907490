import {
  TableColumn,
  Button,
  TableLoadingState,
  GetRowState,
  Page,
} from '@revolut/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { SideBox } from 'view/SideBox'
import { useSideBox } from 'view/SideBox/SideBox'
import { ModalOverlay } from './ModalOverlay'
import { filterActiveIds, filterSelectedEntities } from './utils'
import { EntitiesTable } from './EntitiesTable'

/**
 * Shows table of entities overlay to select
 */
export const SelectEntitiesTableOverlay = <Entity extends { id: string }>(props: {
  onClose: () => void
  onSelect: (entities: Entity[]) => void
  getRowState?: (entity: Entity) => ReturnType<GetRowState<Entity>>
  getDisableSubmit?: (selectedIds: string[]) => boolean
  onSelectUpdate?: (selectedIds: string[]) => void
  preselectedItems?: string[]
  entities: Entity[]
  loadingState: TableLoadingState
  title: string
  columns: TableColumn<Entity>[]
  pending?: boolean
  isSubmitDisabled?: boolean
  SubtitleComponent?: () => JSX.Element
  BannerComponent?: () => JSX.Element
  entitiesTypeLabel: string
  pluralForms: [string, string]
}) => {
  const {
    onClose,
    onSelect,
    getRowState: rowStateGetter,
    onSelectUpdate,
    entities,
    title,
    columns,
    pending,
    preselectedItems,
    loadingState,
    isSubmitDisabled,
    SubtitleComponent,
    BannerComponent,
    entitiesTypeLabel,
    pluralForms,
  } = props

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities,
  })
  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((v) => !v),
    [setShowSelected],
  )

  // overlay always removes sideboxes
  const { closeSide } = useSideBox()
  useEffect(() => {
    closeSide()
  }, [closeSide])

  useEffect(() => {
    onSelectUpdate?.(filterActiveIds(selectedHash))
  }, [selectedHash, onSelectUpdate])

  useEffect(() => {
    if (preselectedItems?.length) {
      const newHash = toPresenceMap(preselectedItems)
      setSelectedHash(newHash)
    }
  }, [preselectedItems])

  const submitNewEntities = useCallback(() => {
    onSelect(filterSelectedEntities(entities, selectedHash))
  }, [selectedHash, entities, onSelect])

  const selectedCount = filterActiveIds(selectedHash).length
  const getRowState = useCallback(
    (params: { value: Entity }) => {
      return rowStateGetter?.(params.value) || {}
    },
    [rowStateGetter],
  )

  return (
    <ModalOverlay>
      <Page>
        <Page.Header
          onBack={onClose}
          subtitle={SubtitleComponent ? <SubtitleComponent /> : undefined}
        >
          {title}
        </Page.Header>
        <Page.Main>
          {BannerComponent ? <BannerComponent /> : null}
          <EntitiesTable
            entitiesTypeLabel={entitiesTypeLabel}
            pluralForms={pluralForms}
            totalCount={entities.length}
            columns={columns}
            data={searched}
            selectedHash={selectedHash}
            setSelectedHash={setSelectedHash}
            switchShowSelected={switchShowSelected}
            showSelected={showSelected}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            showSelectedSwitcherForce
            loadingState={loadingState}
            getRowState={getRowState}
            searchAutoFocus
            isStickyHeader
          />
          <Page.MainActions>
            <Button
              elevated
              disabled={!selectedCount || pending || isSubmitDisabled}
              onClick={submitNewEntities}
              pending={pending}
            >
              {!selectedCount ? 'Add' : `Add ${selectedCount} selected`}
            </Button>
          </Page.MainActions>
        </Page.Main>
        <Page.Side>
          <SideBox />
        </Page.Side>
      </Page>
    </ModalOverlay>
  )
}
