import { useQueryClient } from 'queries/idave/clients'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { DataWrapperProps, InnerProps } from './types'

export const ClientDataWrapper = ({ Component, crossCheck, id }: DataWrapperProps) => {
  if (!id) {
    const entity = {
      type: 'Client' as const,
      value: undefined,
    }
    return <Component crossCheck={crossCheck} entity={entity} />
  }

  return <Inner id={id} crossCheck={crossCheck} Component={Component} />
}

const Inner = ({ Component, crossCheck, id }: InnerProps) => {
  const { data: client, status: qs, fetchStatus: fs, refetch } = useQueryClient(id)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={client}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view clients" />
      )}
      renderLoading={() => <DetailsGroupSkeleton />}
      renderSuccess={({ data }) => (
        <Component
          crossCheck={crossCheck}
          entity={{
            type: 'Client',
            value: data,
          }}
        />
      )}
    />
  )
}
