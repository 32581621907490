import { UnknownAuthor } from './UnknownAuthor'
import { AuthorUser } from './AuthorUser'

export type LogAuthorProps =
  | { id?: string; userName?: string; email?: string; entity: 'user' }
  | { name: string; description?: string; entity: 'unknown' }

export const LogAuthor = (props: LogAuthorProps) => {
  const { entity } = props

  if (entity === 'unknown') {
    return <UnknownAuthor name={props.name} />
  }

  return <AuthorUser id={props.id} userName={props.userName} email={props.email} />
}
