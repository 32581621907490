import { Button, SelectInput, TextArea, Widget, Flex, HStack } from '@revolut/ui-kit'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { useCallback, useMemo, useState } from 'react'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { getCommentRawValue, getCurrentActions } from './utils'
import { Action } from './types'
import { NotifeeSelect } from './NotifeeSelect'

export const CommentInput = (props: {
  crossCheck: CrossCheckDetail
  submitComment: (comment: string) => void
  isLoading: boolean
  commentRef: React.Ref<HTMLTextAreaElement>
}) => {
  const { submitComment, commentRef, isLoading, crossCheck } = props

  const [value, setValue] = useState('')
  const onChange = useInputStringChange(setValue)

  const actions = useMemo(() => getCurrentActions(crossCheck), [crossCheck])
  const [action, setAction] = useState<Action>(actions[0].value)
  const onActionChange = useCallback(
    (actionValue?: Action | null) => {
      if (actionValue) {
        setAction(actionValue)
      }
    },
    [setAction],
  )

  const [emails, setEmails] = useState<string[]>([])

  const onEnterPress = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && event.ctrlKey) {
        submitComment(getCommentRawValue(value, action, emails))
      }
    },
    [submitComment, action, value, emails],
  )

  const onSubmit = useCallback(() => {
    submitComment(getCommentRawValue(value, action, emails))
  }, [submitComment, action, value, emails])

  return (
    <Widget p="s-16">
      <TextArea
        ref={commentRef}
        label="Write something..."
        onChange={onChange}
        disabled={isLoading}
        onKeyUp={onEnterPress}
        value={value}
        rows={3}
      />

      <Flex justifyContent="space-between" mt="s-12">
        <HStack space="s-12" align="center">
          <SelectInput
            options={actions}
            value={action}
            onChange={onActionChange}
            label="Select action"
            searchable={false}
          />
          <NotifeeSelect emails={emails} setEmails={setEmails} crossCheck={crossCheck} />
        </HStack>

        <Button
          useIcon="SendMessage"
          disabled={!value || isLoading}
          pending={isLoading}
          onClick={onSubmit}
          maxWidth="110px"
        >
          Send
        </Button>
      </Flex>
    </Widget>
  )
}
