import { ProgressStep, ProgressSteps, TextSkeleton, Widget } from '@revolut/ui-kit'

const STEP_SIZES: [number, number][] = [
  [7, 10],
  [5, 12],
  [6, 7],
]

export const CrossCheckApproversSkeleton = () => {
  return (
    <Widget>
      <ProgressSteps variant="vertical-compact">
        {STEP_SIZES.map(([titleSize, descriptionSize], idx) => (
          <ProgressStep key={idx}>
            <ProgressStep.Title>
              <TextSkeleton size={titleSize} />
            </ProgressStep.Title>
            <ProgressStep.Description>
              <TextSkeleton size={descriptionSize} />
            </ProgressStep.Description>
          </ProgressStep>
        ))}
      </ProgressSteps>
    </Widget>
  )
}
