import { Box, Subheader, textChain } from '@revolut/ui-kit'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { pluralForm } from 'utils/string'
import { EntityList } from 'components/EntityList'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryUserIdMap } from 'queries/idave/users'
import { isAllSettled } from 'utils/query'
import { useCallback, useMemo } from 'react'
import { useEditPopup } from 'hooks/useEditPopup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'api/dart'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { getItems } from './utils'
import { AddReviewerPopup } from './AddReviewerPopup'

export const CustomerOwners = (props: { ids: string[]; customerCompanyId: string }) => {
  const { hasPermission } = usePermissions()
  const { ids, customerCompanyId } = props
  const {
    data: userMap = new Map(),
    status: usersS,
    fetchStatus: usersFS,
  } = useQueryUserIdMap()
  const {
    data: avatars,
    status: avatarsS,
    fetchStatus: avatarsFS,
  } = useQueryMappedAvatars()

  const isLoading = !isAllSettled(
    {
      fs: usersFS,
      qs: usersS,
    },
    {
      qs: avatarsS,
      fs: avatarsFS,
    },
  )

  const items = useMemo(() => getItems(ids, userMap, avatars), [ids, userMap, avatars])
  const { closePopup: closeEditPopup, setPopup: setEditPopup } = useEditPopup()

  const { showSuccessToast } = useToasts()
  const { closePopup, setPopup } = useConfirmationPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const queryClient = useQueryClient()

  const { mutate: addOwner } = useMutation({
    mutationFn: (ownerId: string) =>
      api.customerCompany.addCustomerCompanyOwner({
        id: customerCompanyId,
        ownerId,
      }),
    onMutate: () => {
      showLoadingPopup({ title: 'Adding owner...' })
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Addition failed', error: err })
    },
    onSuccess: () => {
      hideLoadingPopup()
      showSuccessToast('Owner added')
      closeEditPopup()
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CustomerCompany, customerCompanyId],
      })
    },
  })

  const { mutate: deleteOwner } = useMutation({
    mutationFn: (ownerId: string) =>
      api.customerCompany.deleteCustomerCompanyOwner({
        id: customerCompanyId,
        ownerId,
      }),
    onMutate: () => {
      showLoadingPopup({ title: 'Deleting owner...' })
    },
    onSuccess() {
      hideLoadingPopup()
      showSuccessToast('Owner deleted')
      closePopup()
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CustomerCompany, customerCompanyId],
      })
    },
    onError(err) {
      hideLoadingPopup()
      showErrorPopup({ title: 'Deletion failed', error: err })
    },
  })

  const onDeleteClick = useCallback(
    (ownerId: string) => {
      setPopup({
        title: 'Are you sure you want to delete company owner?',
        message: 'This user won’t be able to add or delete company approvers.',
        confirmButtonText: 'Delete',
        onConfirmClick: () => deleteOwner(ownerId),
      })
    },
    [setPopup, deleteOwner],
  )

  const onAddClick = useCallback(() => {
    setEditPopup({
      title: 'Add owner',
      content: <AddReviewerPopup onAddUser={addOwner} addLabel="Add owner" />,
    })
  }, [setEditPopup, addOwner])

  return (
    <Box>
      <Box pl="s-16">
        <Subheader variant="nested">
          <Subheader.Title>
            {textChain(pluralForm(ids, ['Company owner', 'Company owners']), ids.length)}
          </Subheader.Title>
        </Subheader>
      </Box>

      <EntityList
        items={items}
        showAvatars
        isLoading={isLoading}
        onAddClick={onAddClick}
        onDeleteClick={onDeleteClick}
        addEnabled={hasPermission(DART_PERMISSIONS.CUSTOMER_COMPANIES_UPDATE_OWNER)}
        deleteEnabled={hasPermission(DART_PERMISSIONS.CUSTOMER_COMPANIES_DELETE_OWNER)}
        addActionLabel="Add owner"
      />
    </Box>
  )
}
