import { SideboxQueryParams } from 'view/SideBox/types'
import {
  AUDIT_LOG_TYPE,
  GROUP_SIDE,
  PERMISSION_SIDE,
  RESOURCE_SIDE,
  ROLE_SIDE,
  SIDEBOX_OBJECT_ID_PARAM_NAME,
  SIDEBOX_SUBJECT_ID_PARAM_NAME,
  SIDEBOX_TYPE_PARAM_NAME,
  TOXIC_ALERT_SIDE,
  USER_POLICY_TYPE,
} from './constants'

export const extractSideboxQueryParams = (
  queryParams: URLSearchParams,
): SideboxQueryParams | undefined => {
  const type = queryParams.get(SIDEBOX_TYPE_PARAM_NAME)
  const id = queryParams.get(SIDEBOX_OBJECT_ID_PARAM_NAME)
  const subjectId = queryParams.get(SIDEBOX_SUBJECT_ID_PARAM_NAME) || undefined

  if (!id) {
    return undefined
  }

  switch (type) {
    case GROUP_SIDE:
    case ROLE_SIDE:
    case PERMISSION_SIDE:
    case USER_POLICY_TYPE:
    case RESOURCE_SIDE:
    case TOXIC_ALERT_SIDE:
    case AUDIT_LOG_TYPE: {
      return {
        type,
        id,
        subjectId,
      }
    }

    default:
      return undefined
  }
}
