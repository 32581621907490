import { filterActiveIds } from 'components/EntitiesTable/utils'
import { UserListVariant } from 'components/UserList/types'
import { omit } from 'lodash'

type GetSelectedValuesParams = {
  selectedHash: Record<string, boolean>
  variant: UserListVariant
  newItemId: string
}
export const getSelectedValues = ({
  selectedHash,
  variant,
  newItemId,
}: GetSelectedValuesParams) => {
  if (variant === 'radio') {
    return [newItemId]
  }

  const newHash = selectedHash[newItemId]
    ? omit(selectedHash, newItemId)
    : { ...selectedHash, [newItemId]: true }

  return filterActiveIds(newHash)
}
