import { Box, Subheader, Table } from '@revolut/ui-kit'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useQuerySamUser } from 'queries/sam/users'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { mergeQueryStatuses } from 'utils/query'
import { COLUMNS } from './columns'
import { getPoliciesRows } from './utils'

export const UserRolePolicies = ({
  roleId,
  userId,
}: {
  roleId: string
  userId: string
}) => {
  const { data, status, fetchStatus } = useQuerySamUser({ params: userId })
  const {
    data: roleMap = new Map(),
    status: roleMapQS,
    fetchStatus: roleMapFS,
  } = useQueryRoleIdMap()

  const policies = getPoliciesRows({ roleId, roleMap, policies: data?.policies })
  const loadingState = getLoadingState(
    mergeQueryStatuses({ qs: status, fs: fetchStatus }, { qs: roleMapQS, fs: roleMapFS }),
    policies.length,
  )

  if (!policies.length) {
    return null
  }
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Related with user policies</Subheader.Title>
      </Subheader>
      <Table columns={COLUMNS} data={policies} loadingState={loadingState} />
    </Box>
  )
}
