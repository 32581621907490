import { Box, Subheader, TableColumn, Table } from '@revolut/ui-kit'
import { SamResource } from 'api/sam/resources'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQueryResourceMap } from 'queries/sam/resources'
import { useCallback, useMemo } from 'react'
import { getResourceLink } from 'utils/resource/getResourceLink'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'

export const SamPolicyLogResourcesTable = ({
  resourceIds,
  title,
}: {
  title: string
  resourceIds?: string[]
}) => {
  const { data: resourcesMap, status, fetchStatus } = useQueryResourceMap()

  if (!resourceIds?.length) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
      <QuerySwitch
        required={[{ qs: status, fs: fetchStatus }]}
        data={resourcesMap}
        renderLoading={() => <ResourceTable ids={resourceIds} isLoading />}
        renderIdle={() => <RequestErrorWidget />}
        renderError={() => <ResourceTable ids={resourceIds} />}
        renderSuccess={() => (
          <ResourceTable resourcesMap={resourcesMap} ids={resourceIds} />
        )}
      />
    </Box>
  )
}

const COLUMNS: TableColumn<{ name: string; type: string; id: string }>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
]

const ResourceTable = (props: {
  resourcesMap?: Map<string, SamResource>
  ids: string[]
  isLoading?: boolean
}) => {
  const { resourcesMap, ids, isLoading } = props
  const entities = useMemo(
    () =>
      ids.map((id) => {
        const resource = resourcesMap?.get(id)
        const type = getResourceTypeLabel(resource?.resourceType)

        return { name: resource?.resourceName || id, type, id }
      }),
    [ids, resourcesMap],
  )

  const onRowClick = useCallback(
    (row: { id: string }) => {
      const resource = resourcesMap?.get(row.id)
      if (resource) {
        const link = getResourceLink(resource)
        link && window.open(link.href, '_blank')
      }
    },
    [resourcesMap],
  )

  return (
    <Table
      columns={COLUMNS}
      data={entities}
      disableSortBy
      onRowClick={onRowClick}
      loadingState={isLoading ? 'pending' : 'ready'}
    />
  )
}
