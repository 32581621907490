import { PolicyDetails } from 'api/sam/policies'
import { Training } from './types'

type GetTrainingParams = {
  policy?: PolicyDetails
}

const COMPLETE_LABEL: Training['completeness'] = 'Completed'
const INCOMPLETE_LABEL: Training['completeness'] = 'Not completed'

export const getTrainings = ({ policy }: GetTrainingParams) => {
  if (!policy) {
    return undefined
  }

  return (policy.mandatoryTrainings || []).map(({ name, completed }) => ({
    name,
    completeness: completed ? COMPLETE_LABEL : INCOMPLETE_LABEL,
  }))
}

export const getCompletenessLevel = (completeness: Training['completeness']) => {
  switch (completeness) {
    case COMPLETE_LABEL:
      return 'success' as const
    case INCOMPLETE_LABEL:
    default:
      return 'warning' as const
  }
}
