import { Page, TabBar } from '@revolut/ui-kit'
import { useQueryClient, useQueryClientCustomization } from 'queries/idave/clients'
import { ClientCustomization, Client as ClientType } from 'api/idave/clients'
import { useNavigate } from 'react-router-dom'
import { Url } from 'routing'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useCallback } from 'react'
import { TabBarLink } from 'components/TabBarLink'
import { generatePath } from 'react-router'
import { IDAVE_PERMISSIONS } from 'security'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'

import { ClientAuditLogs } from './components/ClientAuditLogs'
import { ClientPermissions } from './components/ClientPermissions'
import { ClientDetails } from './components/ClientDetails'
import { ClientSkeleton } from './components/ClientSkeleton'
import { ClientProps, ClientTab } from './types'
import { ClientHeaderName } from './components/ClientHeaderName'

export const Client = ({ clientId, tab }: ClientProps) => {
  const {
    data: client,
    status: clientQS,
    fetchStatus: clientFS,
  } = useQueryClient(clientId)
  const {
    data: customization,
    status: customizationQS,
    fetchStatus: customizationFS,
  } = useQueryClientCustomization(clientId)
  const navigate = useNavigate()
  const toClients = useCallback(
    () => navigate(Url.Clients, { replace: true }),
    [navigate],
  )

  return (
    <QuerySwitch
      required={[{ qs: clientQS, fs: clientFS }]}
      optional={[{ qs: customizationQS, fs: customizationFS }]}
      data={client}
      renderError={() => (
        <RequestErrorWidget actionText="To clients" action={toClients} />
      )}
      renderIdle={() => <RequestErrorWidget actionText="To clients" action={toClients} />}
      renderLoading={() => (
        <Outer clientId={clientId}>
          <ClientSkeleton clientId={clientId} tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data }) => (
        <Outer clientId={clientId} client={data}>
          <Inner client={data} tab={tab} customization={customization} />
        </Outer>
      )}
    />
  )
}

const Outer = (props: {
  client?: ClientType
  clientId: string
  children: React.ReactNode
}) => {
  const { clientId, client, children } = props
  const navigate = useNavigate()

  return (
    <>
      <Page.Header onBack={() => navigate(Url.Clients)}>
        <ClientHeaderName client={client} />
      </Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <TabBarLink to={generatePath(Url.ClientDetails, { clientId })}>
            Details
          </TabBarLink>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.ClientPermissions, { clientId })}>
              Permissions
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.ClientAuditLogs, { clientId })}>
              Audit log
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = (props: {
  client: ClientType
  tab: ClientTab
  customization?: ClientCustomization
}) => {
  const { client, tab, customization } = props

  switch (tab) {
    case 'permissions':
      return <ClientPermissions client={client} />
    case 'log':
      return <ClientAuditLogs clientId={client.id} />
    default:
    case 'details':
      return <ClientDetails client={client} customization={customization} />
  }
}
