import { ToxicPair } from 'api/idave/toxic'
import { ToxicPairInfo } from './components/ToxicPairInfo'
import { ToxicPairActions } from './components/ToxicPairActions'

type Props = {
  toxicPair: ToxicPair
}
export const ToxicPairDetails = ({ toxicPair }: Props) => {
  return (
    <>
      <ToxicPairActions toxicPairId={toxicPair.id} />
      <ToxicPairInfo toxicPair={toxicPair} />
    </>
  )
}
