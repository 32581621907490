import { Action, AvatarBar, abbreviate } from '@revolut/ui-kit'
import { UserListItem } from 'api/idave/user'
import { CrossCheckGroup } from 'api/types/crossCheck'
import { uniq } from 'lodash'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'

type Props = {
  userMap: Map<string, UserListItem>
  group: CrossCheckGroup
  onClick: () => void
}

export const CrossCheckGroupReviewers = (props: Props) => {
  const { userMap, group, onClick } = props
  const { data: avatars } = useQueryMappedAvatars()

  return (
    <Action>
      <AvatarBar maxCount={3} size={40} variant="compact" onClick={onClick}>
        {uniq(group.reviewers).map((reviewer) => {
          const user = userMap.get(reviewer)
          const avatarUrl = getAvatarUrl({ user, avatars, type: 'imageMedium' })
          const showState = user?.state === 'TERMINATED' || user?.state === 'DELETED'

          return user ? (
            <AvatarBar.Item
              key={reviewer}
              uuid={reviewer}
              label={abbreviate(getName(user))}
              image={avatarUrl}
              disabled={showState}
            />
          ) : null
        })}
      </AvatarBar>
    </Action>
  )
}
