import { AuditLogEntityParam } from 'api/idave/auditsLog'
import { DataMaps } from 'view/AuditLogs/types'
import { stringifyEntity } from 'view/AuditLogs/utils'
import { InternalLink } from 'components/Links/InternalLink'
import { UserLink } from 'components/Links/UserLink'
import { GroupName } from 'components/GroupName'
import { getEntityHref } from '../../../../../utils'

export const Entity = (props: {
  entity: AuditLogEntityParam['value']
  dataMaps: DataMaps
}) => {
  const { entity, dataMaps } = props

  const href = getEntityHref(entity)
  const text = stringifyEntity({
    value: entity,
    dataMaps,
    shortVersion: false,
  })

  if (entity.entity === 'User') {
    return <UserLink id={entity.id} />
  }

  if (entity.entity === 'Group') {
    return <GroupName id={entity.id} />
  }

  return href && text ? (
    <InternalLink href={href}>{text}</InternalLink>
  ) : (
    <>{text || entity.id}</>
  )
}
