import { VFC } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input, VStack } from '@revolut/ui-kit'

type OtpFormProps = {
  isPending: boolean
  onSubmit: (code: string) => void
}

export const OtpForm: VFC<OtpFormProps> = ({ isPending, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ code: string }>()

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data.code))}>
      <VStack space="s-8">
        <Input
          {...register('code', {
            required: { value: true, message: 'Code cannot be empty' },
          })}
          disabled={isPending}
          placeholder="Code from email"
          label="OTP code"
          aria-invalid={!!errors.code?.message}
          errorMessage={errors.code?.message}
        />
        <Button type="submit" pending={isPending}>
          Sign In
        </Button>
      </VStack>
    </form>
  )
}
