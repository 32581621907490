import { Box, Item, ItemSkeleton, Subheader, TextSkeleton, Widget } from '@revolut/ui-kit'

export const JustificationSkeleton = () => (
  <Box>
    <Subheader variant="nested">
      <Subheader.Title>Justification</Subheader.Title>
    </Subheader>

    <Widget>
      <ItemSkeleton>
        <ItemSkeleton.Avatar />
        <Item.Content>
          <ItemSkeleton.Title />
          <ItemSkeleton.Description />
        </Item.Content>
      </ItemSkeleton>
      <Box pl="s-16" pr="s-16" pb="s-16">
        <TextSkeleton size={25} />
      </Box>
    </Widget>
  </Box>
)
