import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { isQueryParamsEqual } from 'utils/url/isQueryParamsEqual'
import { isEqual } from 'lodash'
import { appendToQueryParams } from 'utils/url/appendToQueryParams'
import { clearQueryParams } from 'utils/url/clearQueryParams'
import { useSideboxByQueryParams } from './useSideboxByQueryParams'
import { SideBoxType } from '../../types'
import { extractSideboxQueryParams } from './utils'
import {
  SIDEBOX_OBJECT_ID_PARAM_NAME,
  SIDEBOX_SUBJECT_ID_PARAM_NAME,
  SIDEBOX_TYPE_PARAM_NAME,
} from './constants'

type UseSideboxNavigationParams = {
  openSide: (sidebox: SideBoxType) => void
  ready: boolean
  sidebox?: SideBoxType
}

export const useSideboxNavigation = ({
  sidebox,
  openSide,
  ready,
}: UseSideboxNavigationParams) => {
  const [init, setInit] = useState(false)

  useSideboxNavigationInit({ init, setInit, openSide, sidebox, ready })
  useSideboxNavigationUpdate({ sidebox, init })
}

const useSideboxNavigationInit = (params: {
  init: boolean
  setInit: (initValue: boolean) => void
  openSide: (sidebox: SideBoxType) => void
  sidebox?: SideBoxType
  ready: boolean
}) => {
  const { init, setInit, sidebox, ready } = params
  const [queryParams] = useSearchParams()
  const openSideboxByParams = useSideboxByQueryParams()

  useEffect(() => {
    if (init || !ready) {
      return
    }

    const sideboxParams = extractSideboxQueryParams(queryParams)
    if (!isEqual(sidebox?.queryParams, sideboxParams)) {
      openSideboxByParams(sideboxParams)
    }
    setInit(true)
  }, [init, queryParams, sidebox, ready, openSideboxByParams, setInit])
}

const useSideboxNavigationUpdate = (params: { sidebox?: SideBoxType; init: boolean }) => {
  const { init, sidebox } = params
  const [queryParams, setQueryParams] = useSearchParams()
  const locationPath = useLocation().pathname

  useEffect(() => {
    if (!init) {
      return
    }
    const newParams = sidebox?.queryParams
      ? appendToQueryParams(queryParams, {
          [SIDEBOX_OBJECT_ID_PARAM_NAME]: sidebox.queryParams.id,
          [SIDEBOX_SUBJECT_ID_PARAM_NAME]: sidebox.queryParams.subjectId,
          [SIDEBOX_TYPE_PARAM_NAME]: sidebox.queryParams.type,
        })
      : clearQueryParams(
          queryParams,
          SIDEBOX_OBJECT_ID_PARAM_NAME,
          SIDEBOX_SUBJECT_ID_PARAM_NAME,
          SIDEBOX_TYPE_PARAM_NAME,
        )

    if (!isQueryParamsEqual(newParams, queryParams)) {
      setQueryParams(newParams, { replace: true })
    }
  }, [sidebox, queryParams, setQueryParams, init, locationPath])
}
