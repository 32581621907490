import { LinkExternal } from '@revolut/icons'
import { HStack, Link } from '@revolut/ui-kit'
import React from 'react'

export type ExternalLinkProps = {
  href: string
  children: React.ReactNode
  target?: string
}

export const ExternalLink = (props: ExternalLinkProps) => {
  const { href, children, target } = props

  return (
    <Link href={href} target={target} display="inline-block">
      <HStack align="center" space="s-8" width="fit-content" wrap="no-wrap">
        {children}
        <LinkExternal size={16} />
      </HStack>
    </Link>
  )
}
