import { Role } from 'api/idave/roles'
import { notNullableMap, mapify } from 'utils/array'
import { traverseRolesChildren } from './traverseRolesChildren'

export const getRolesByPermission = ({
  roles,
  permissionId,
}: {
  roles: Role[]
  permissionId: string
}): Role[] => {
  const roleToTraverseIds = notNullableMap(roles, ({ id, permissions }) =>
    permissions.includes(permissionId) ? id : undefined,
  )

  const childIds = traverseRolesChildren({ roleIds: roleToTraverseIds, roles })
  const roleListIds = new Set(roleToTraverseIds.concat(childIds))
  const roleMap = mapify(roles, (r) => r.id)

  return notNullableMap([...roleListIds], (id) => roleMap.get(id))
}
