import { Box, ItemSkeleton } from '@revolut/ui-kit'
import { times } from 'utils/array/times'

export const UserListSkeleton = () => {
  return <>{times(UserItemSkeleton, 3)}</>
}

const UserItemSkeleton = () => {
  return (
    <Box pl="s-48">
      <ItemSkeleton />
    </Box>
  )
}
