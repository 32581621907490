import { Text, TextSkeleton } from '@revolut/ui-kit'
import { QuerySwitch } from 'components/QuerySwitch'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { useQueryOrgs } from 'queries/idave/orgs'
import { useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { InternalLink } from '../InternalLink'

export const OrgLink = (props: { orgId?: string }) => {
  const { orgId } = props

  if (!orgId) {
    return <Text>{EMPTY_VALUE_FALLBACK}</Text>
  }

  return <Inner orgId={orgId} />
}

const Inner = (props: { orgId: string }) => {
  const { orgId } = props
  const { data: organisations = [], status, fetchStatus } = useQueryOrgs()
  const href = generatePath(Url.Organisation, { organisationId: orgId })
  const text = useMemo(
    () => organisations.find((org) => org.id === orgId)?.name || orgId,
    [orgId, organisations],
  )

  return (
    <InternalLink href={href}>
      <QuerySwitch
        required={[{ fs: fetchStatus, qs: status }]}
        data={organisations}
        renderError={() => text}
        renderIdle={() => text}
        renderLoading={() => <TextSkeleton size={12} />}
        renderSuccess={() => text}
      />
    </InternalLink>
  )
}
