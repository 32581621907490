import { formatDateTime } from 'utils/date'
import { KeyDescriptor } from './types'

export const KNOWN_KEYS: KeyDescriptor[] = [
  {
    key: 'organisationId.id',
    title: 'Organisation',
    entity: 'Organisation',
  },
  {
    key: 'oldOrganisation.id',
    title: 'Old organisation',
    entity: 'Organisation',
  },
  {
    key: 'newOrganisation.id',
    title: 'New organisation',
    entity: 'Organisation',
  },
  {
    key: 'deviceId',
    title: 'Device',
    entity: 'Device',
  },
  {
    key: 'deviceId.id',
    title: 'Device',
    entity: 'Device',
  },
  {
    key: 'parentId',
    title: 'Parent role',
    entity: 'Role',
  },
  {
    key: 'ownerId.id',
    title: 'Owner',
    entity: 'User',
  },
  {
    key: 'userId.id',
    title: 'User',
    entity: 'User',
  },
  {
    key: 'userId',
    title: 'User',
    entity: 'User',
  },
  {
    key: 'clientId',
    title: 'Client',
    entity: 'Client',
  },
  {
    key: 'email',
    title: 'Email',
  },
  {
    key: 'username',
    title: 'User name',
  },
  {
    key: 'state',
    title: 'State',
  },
  {
    key: 'firstName',
    title: 'First name',
  },
  {
    key: 'lastName',
    title: 'Last name',
  },
  {
    key: 'oldEmail',
    title: 'Old email',
  },
  {
    key: 'oldUsername',
    title: 'Old username',
  },
  {
    key: 'oldLastName',
    title: 'Old last name',
  },
  {
    key: 'oldFirstName',
    title: 'Old first name',
  },
  {
    key: 'scopeName',
    title: 'Scope name',
  },
  {
    key: 'oldScopeName',
    title: 'Old scope name',
  },
  {
    key: 'displayName',
    title: 'Display name',
  },
  {
    key: 'description',
    title: 'Description',
  },
  {
    key: 'oldRoleName',
    title: 'Old role name',
  },
  {
    key: 'newState',
    title: 'New state',
  },
  {
    key: 'oldState',
    title: 'Old state',
  },
  {
    key: 'userAgent',
    title: 'User agent',
  },
  {
    key: 'code',
    title: 'Code',
  },
  {
    key: 'domain',
    title: 'Domain',
  },
  {
    key: 'roleName',
    title: 'Role name',
  },
  {
    key: 'expiryDate',
    title: 'Expire Date',
    stringify: (value) => (typeof value === 'number' ? formatDateTime(value) : undefined),
  },
  {
    key: 'attempts',
    title: 'Attempts',
    stringify: (value) => (typeof value === 'number' ? String(value) : undefined),
  },
]
