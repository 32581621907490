import { UserListItem } from 'api/idave/user'
import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useFilteredUsers } from 'queries/idave/users'
import { waitForAll } from 'utils/query'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useCallback, useMemo } from 'react'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { getColumns } from './columns'
import { getRows } from './utils'

export const RoleUsers = (props: { roleId: string }) => {
  const {
    data: users = [],
    status,
    fetchStatus,
  } = useFilteredUsers({ roleId: props.roleId })
  const { getEmployeeDescription, status: descriptionStatus } =
    useGetEmployeeRevoDescription()
  const isDataLoading = descriptionStatus === 'loading'

  const loadingState = getLoadingState(
    waitForAll([{ qs: status, fs: fetchStatus }]),
    users.length,
  )

  const rows = useMemo(
    () => getRows({ users, getEmployeeDescription }),
    [users, getEmployeeDescription],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: rows,
  })
  const getUserLink = useCallback(
    (user: UserListItem) => generatePath(Url.UserProfile, { userId: user.id }),
    [],
  )

  const columns = useMemo(() => getColumns(isDataLoading), [isDataLoading])

  return loadingState === 'no-results' ? (
    <EmptyStatusWidget
      title="No users with this role"
      imageCode="3D082"
      imageVersion="v2"
    />
  ) : (
    <EntitiesTable
      totalCount={users.length}
      entitiesTypeLabel="Users"
      pluralForms={['user', 'users']}
      data={searched}
      loadingState={loadingState}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      getRowLink={getUserLink}
      searchAutoFocus
    />
  )
}
