import { Page, TabBar } from '@revolut/ui-kit'
import { DART_PERMISSIONS } from 'security'
import { Url } from 'routing'
import { TabBarLink } from 'components/TabBarLink'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'

import { EmployeeCompanies } from './EmployeeCompanies'
import { UserAccesses } from './UserAccesses'
import { UserRestrictions } from './UserRestrictions'
import { CustomerCompanies } from './CustomerCompanies'
import { AuditLogs } from './AuditLogs'

type DartProps = {
  tab:
    | 'employeeCompanies'
    | 'customerCompanies'
    | 'userAccesses'
    | 'userRestrictions'
    | 'auditLogs'
}

export const Dart = (props: DartProps) => {
  return (
    <>
      <Page.Header>Data access</Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_LIST}>
            <TabBarLink to={Url.DartCustomerCompanies}>Customer companies</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST}>
            <TabBarLink to={Url.DartEmployeeCompanies}>Employee companies</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={DART_PERMISSIONS.EMPLOYEES_VIEW_LIST}>
            <TabBarLink to={Url.DartUserAccesses}>User accesses</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={DART_PERMISSIONS.EMPLOYEES_VIEW_LIST}>
            <TabBarLink to={Url.DartUserRestrictions}>User restrictions</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={DART_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={Url.DartAuditLogs}>Audit logs</TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>
        {props.tab === 'customerCompanies' && <CustomerCompanies />}

        {props.tab === 'employeeCompanies' && <EmployeeCompanies />}

        {props.tab === 'userAccesses' && <UserAccesses />}

        {props.tab === 'userRestrictions' && <UserRestrictions />}

        {props.tab === 'auditLogs' && <AuditLogs />}
      </Page.Main>
    </>
  )
}
