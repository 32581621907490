import { OrganisationData } from 'api/idave/organisations'
import { Box } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { EntityDescription } from 'components/EntityDescription'
import { OrganisationActions } from './components/OrganisationActions'
import { OrganisationInfo } from './components/OrganisationInfo'
import { OrganisationExternalInfo } from './components/OrganisationExternalInfo'

export const OrganisationDetails = (props: { organisation: OrganisationData }) => {
  const { organisation } = props

  return (
    <>
      <OrganisationActions organisation={organisation} />
      <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <OrganisationInfo organisation={organisation} />
        <OrganisationExternalInfo organisation={organisation} />
        <EntityDescription description={organisation.description} />
      </Box>
    </>
  )
}
