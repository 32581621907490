/**
 * Finds not nullable (null/undefined) item by map cb, and returns mapped value (instead of item)
 * Good to use when need to find an item and get cb result instead of item itself
 *
 * A code:
 *
 * const item = items.filter(cb)
 * const value = cb(item)
 *
 * turns into:
 *
 * const value = findMapped(items, cb)
 */
export const findMapped = <T, M>(
  array: T[],
  map: (i: T, idx: number, arr: T[]) => M | undefined,
) => {
  for (let i = 0; i < array.length; i++) {
    const mapped = map(array[i], i, array)
    if (mapped) {
      return mapped
    }
  }

  return undefined
}
