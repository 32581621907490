import { TableColumn } from '@revolut/ui-kit'
import { AccountState } from 'api/idave/accounts'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { StateCell } from 'components/StateCell'

type AccountRow = {
  id: string
  accountApp: string
  source: string
  state: AccountState
}

export const columns: TableColumn<AccountRow>[] = [
  {
    Header: 'Account app',
    accessor: 'accountApp',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Origin',
    accessor: 'source',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'State',
    accessor: 'state',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: StateCell,
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
]
