import { StatusCellLevels } from '@revolut/ui-kit'
import {
  AddReviewerEvent,
  ApproveEvent,
  CommentEvent,
  CrossCheck,
  CrossCheckEntityType,
  CrossCheckEvent,
  CrossCheckExecutableName,
  CrossCheckState,
  CrossCheckStatus,
  CrossCheckSystem,
  JustifyEvent,
  RejectEvent,
} from 'api/types/crossCheck'
import { capitalize } from 'lodash'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { getName } from 'utils/string/getName'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { DART_PERMISSIONS, IDAVE_PERMISSIONS, SAM_PERMISSIONS } from 'security'
import idaveApi from 'api/idave'
import samApi from 'api/sam'
import dartApi from 'api/dart'
import { Page } from 'components/HeaderActions/types'
import {
  AUTOMATION_APP_ID,
  DART_APP_ID,
  IDAVE_APP_ID,
  SAM_APP_ID,
  TOWER_APP_ID,
} from 'utils/constants/clients'
import { DataMaps, SystemRequester } from './types'

export const getRequest = (executableName: CrossCheckExecutableName) => {
  switch (executableName) {
    case 'ClientCreate':
      return 'Create application'
    case 'ClientDelete':
      return 'Delete application'
    case 'ClientUpdate':
      return 'Update application'
    case 'RoleAssign':
      return 'Assign role'
    case 'RoleCreate':
      return 'Create role'
    case 'RoleUpdate':
      return 'Update role'
    case 'CreatePolicy':
      return 'Create policy'
    case 'DeletePolicy':
      return 'Delete policy'
    case 'UpdatePolicy':
      return 'Update policy'
    case 'ApproveRecertification':
    case 'RecertificationApprove':
      return 'Recertification'
    case 'DartEmployeeAddAccessModifiers':
    case 'DartEmployeeCompanyAddAccessModifiers':
      return 'Add access modifiers'
    case 'DartEmployeeDeleteAccessModifiers':
    case 'DartEmployeeCompanyDeleteAccessModifiers':
      return 'Delete access modifiers'
    case 'ServiceAccountCreate':
      return 'Service account create'
    case 'ServiceAccountDelete':
      return 'Service account delete'
    case 'ServiceAccountUpdate':
      return 'Service account update'
    case 'ServiceAccountActivate':
      return 'Service account activate'
    case 'ServiceAccountTerminate':
      return 'Service account terminate'
    case 'PermissionMatrixSynchronizer':
      return 'Apps permission sync'
    case 'PermissionDelete':
      return 'Delete permissions'
    default:
      return executableName
  }
}

export const getRequestStateText = (crossCheck: CrossCheck) => {
  switch (crossCheck.state) {
    case CrossCheckState.Approved:
      return 'Approved'
    case CrossCheckState.ExecutionSucceed:
    case CrossCheckState.Executed:
      return crossCheck.entityType === 'Recertification' ? 'Completed' : 'Executed'
    case CrossCheckState.AwaitingReview:
      return getReviewingState(crossCheck)
    case CrossCheckState.Requested:
      return 'Justification required'
    case CrossCheckState.Rejected:
      return 'Rejected'
    default:
      return humanizeCode(crossCheck.state)
  }
}

const getReviewingState = (crossCheck: CrossCheck) => {
  if (crossCheck.status && crossCheck.status !== 'WAITING_FOR_APPROVAL') {
    return humanizeStatus(crossCheck.status)
  }

  return 'Waiting for review'
}

const humanizeStatus = (status: CrossCheckStatus) => {
  switch (status) {
    case 'WAITING_APPROVAL':
      return 'Waiting for approval'
    case 'WAITING_SUPPORT':
      return 'Waiting for support'
    default:
      return humanizeCode(status)
  }
}

export const getRequestStateLevel = (state: CrossCheckState) => {
  switch (state) {
    case CrossCheckState.Approved:
      return StatusCellLevels.MODERATE
    case CrossCheckState.ExecutionSucceed:
    case CrossCheckState.Executed:
      return StatusCellLevels.SUCCESS
    case CrossCheckState.AwaitingReview:
    case CrossCheckState.Requested:
      return StatusCellLevels.WARNING
    case CrossCheckState.Rejected:
    case CrossCheckState.Declined:
    default:
      return StatusCellLevels.ALERT
  }
}

type GetEntityInfoParams = {
  entityType: CrossCheckEntityType
  id?: string
  dataMaps: DataMaps
  source: CrossCheckSystem
}
export const getEntityInfo = ({
  entityType,
  id,
  dataMaps,
  source,
}: GetEntityInfoParams) => {
  if (!id) {
    return {}
  }

  const fallbackName = `${entityType} ${id}`

  switch (entityType) {
    case 'Role': {
      const role = dataMaps.roleMap.get(id)
      return {
        name: role?.name || fallbackName,
        link: generatePath(Url.Role, { roleId: id }),
      }
    }
    case 'User': {
      const user = dataMaps.userMap.get(id)

      return {
        name: user ? getName(user) : fallbackName,
        link: user ? generatePath(Url.User, { userId: id }) : undefined,
      }
    }

    case 'AccessPolicy': {
      const policy = dataMaps.policyMap.get(id)
      const removedName = id === 'None' ? EMPTY_VALUE_FALLBACK : id
      return {
        name: policy?.policyName || removedName,
        link: policy ? generatePath(Url.SamPolicy, { policyId: id }) : undefined,
      }
    }

    case 'Client': {
      const client = dataMaps.clientMap.get(id)

      return {
        name: client?.name || fallbackName,
        link: generatePath(Url.Client, { clientId: id }),
      }
    }

    case 'Recertification': {
      if (source === 'dart') {
        const customerCompany = dataMaps.customerCompanyMap.get(id)
        return {
          name: customerCompany?.name || `Unknown customer company (${id})`,
          link: generatePath(Url.DartCustomerCompany, { companyId: id }),
        }
      }

      const policyId = dataMaps.recertToPolicy[id]
      const policy = dataMaps.policyMap.get(policyId)

      return {
        name: policy?.policyName || 'Unknown policy',
        link: policy ? generatePath(Url.SamPolicy, { policyId }) : undefined,
      }
    }

    case 'CustomerCompany': {
      const customerCompany = dataMaps.customerCompanyMap.get(id)

      return {
        name: customerCompany ? customerCompany.name : fallbackName,
        link: generatePath(Url.DartCustomerCompany, { companyId: id }),
      }
    }

    case 'EmployeeCompany': {
      const employeeCompany = dataMaps.employeeCompanyMap.get(id)

      return {
        name: employeeCompany ? employeeCompany.name : fallbackName,
        link: generatePath(Url.DartEmployeeCompany, { companyId: id }),
      }
    }

    case 'ServiceAccount': {
      const account = dataMaps.accountMap.get(id)
      const accountClient = account ? dataMaps.clientMap.get(account.clientId) : undefined
      return {
        name: accountClient ? `${accountClient.name} (account)` : fallbackName,
        link: id ? generatePath(Url.Account, { accountId: id }) : undefined,
      }
    }

    default:
      return {
        name: fallbackName,
      }
  }
}

type FilteredEvents<T extends CrossCheckEvent['type']> = T extends 'APPROVE'
  ? ApproveEvent
  : T extends 'REJECT'
  ? RejectEvent
  : T extends 'JUSTIFY'
  ? JustifyEvent
  : T extends 'COMMENT'
  ? CommentEvent
  : T extends 'ADD_REVIEWERS'
  ? AddReviewerEvent
  : never

export const getEventsByType = <T extends CrossCheckEvent['type']>(
  events: CrossCheckEvent[],
  eventType: T,
) =>
  events.filter<FilteredEvents<T>>(
    (event): event is FilteredEvents<T> => event.type === eventType,
  )

const humanizeCode = (value: string = '') => capitalize(value.replace(/_/g, ' '))

/**
 * Adding default comment to actions (review/assign) because of a bug on IDave BE side
 */
export const ADD_REVIEWERS_DEFAULT = 'Extended reviewer group'

export const SYSTEM_REQUESTERS: Record<string, SystemRequester> = {
  '00000000-0000-0000-0000-000000000000': {
    type: 'Client',
    id: SAM_APP_ID,
    name: 'SAM (app)',
  },
  tower: {
    type: 'Client',
    id: TOWER_APP_ID,
    name: 'Tower (app)',
  },
  idave: {
    type: 'Client',
    id: IDAVE_APP_ID,
    name: 'iDave (app)',
  },
  dart: {
    type: 'Client',
    id: DART_APP_ID,
    name: 'Dart (app)',
  },
  automation: {
    type: 'Client',
    id: AUTOMATION_APP_ID,
    name: 'Automation (app)',
  },
}

export const getCrossCheckActionPermission = (
  source: CrossCheckSystem,
  action: 'COMMENT' | 'DECLINE' | 'RETRY' | 'JUSTIFY' | 'REVIEW',
) => {
  switch (source) {
    case 'sam':
      return SAM_PERMISSIONS[`CROSS_CHECKS_${action}`]
    case 'dart':
      return DART_PERMISSIONS[`CROSS_CHECKS_${action}`]
    case 'idave':
    default:
      return IDAVE_PERMISSIONS[`CROSS_CHECKS_${action}`]
  }
}

export const getCrossCheckApi = (source: CrossCheckSystem) => {
  switch (source) {
    case 'sam':
      return samApi.crossChecks
    case 'dart':
      return dartApi.crossChecks
    case 'idave':
    default:
      return idaveApi.crossChecks
  }
}

export const getCrossCheckPage = (
  executableName?: CrossCheck['executableName'],
): Page | undefined => {
  switch (executableName) {
    case 'RoleAssign':
      return 'assignRoleRequest'
    default:
      return undefined
  }
}
