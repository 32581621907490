import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { EntitySkeleton } from 'components/EntitySkeleton'
import { ToxicPairTab } from '../types'

export const ToxicPairSkeleton = (props: { tab: ToxicPairTab }) => {
  const variant = props.tab === 'details' ? 'details' : 'table'
  const { hasSomePermissions } = usePermissions()
  const hasActions = hasSomePermissions(IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_DELETE)
  return <EntitySkeleton variant={variant} hasActions={hasActions} />
}
