import { TableFilters } from '@revolut/ui-kit'
import { AuditLogData } from 'api/dart/auditLog'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { isDateBetweenDates } from 'utils/date'
import { AuditLogEntityParam } from 'api/types/auditLog'
import { DataMaps, AuditLogRow } from './types'

export const formatLogs = (
  logItems: AuditLogData[],
  filterMap: Map<string, TableFilters<AuditLogRow>[number]>,
  dataMaps: DataMaps,
  limits?: { from: number; to: number },
) => {
  return logItems
    .map(mapLogValues(dataMaps))
    .filter(filterLogValue(filterMap, limits))
    .sort((a, b) => b.date - a.date)
}

export const stringifyDartEntity = <T extends unknown>({
  dataMaps,
  value,
  shortVersion = true,
}: {
  value: AuditLogEntityParam<T>['value']
  dataMaps: DataMaps
  shortVersion?: boolean
}) => {
  switch (value.entity) {
    case 'DartEmployee':
      const employeeUser = dataMaps.userMap?.get(value.id)
      return employeeUser
        ? `${employeeUser.firstName} ${employeeUser.lastName}`
        : value.id
    case 'DartEmployeeCompany':
      const employeeCompany = dataMaps.employeeCompaniesMap?.get(value.id)
      return employeeCompany?.name || value.id
    case 'User':
      const user = dataMaps.userMap?.get(value.id)
      return user ? `${user.firstName} ${user.lastName}` : value.id
    default:
      return shortVersion ? EMPTY_VALUE_FALLBACK : value.id
  }
}

export const filterLogValue =
  (
    filterMap: Map<string, TableFilters<AuditLogRow>[number]>,
    limits?: { from: number; to: number },
  ) =>
  (logRow: AuditLogRow) => {
    const events = filterMap.get('event')?.value || []
    const actions = filterMap.get('action')?.value || []
    const entities = filterMap.get('entity')?.value || []
    const author = filterMap.get('author')?.value || []

    return (
      (!events.length || events.includes(logRow.event)) &&
      (!actions.length || actions.includes(logRow.action)) &&
      (!entities.length || entities.includes(logRow.entity)) &&
      (!author.length || author.includes(logRow.author)) &&
      isDateBetweenDates(logRow.date, limits?.from, limits?.to)
    )
  }

const mapLogValues =
  (dataMaps: DataMaps) =>
  (item: AuditLogData): AuditLogRow => {
    return {
      logItem: item,
      id: item['Event Id'].value,
      date: item['Event Date'].value,
      event: stringifyLogCell(item.Event, dataMaps),
      action: stringifyLogCell(item.Action, dataMaps),
      entity: stringifyLogCell(item.Model, dataMaps),
      author: stringifyLogCell(item.Author, dataMaps),
    }
  }

const stringifyLogCell = (item: AuditLogData[keyof AuditLogData], dataMaps: DataMaps) => {
  if (!item) {
    return EMPTY_VALUE_FALLBACK
  }
  switch (item?.type) {
    case 'entity':
      return stringifyDartEntity({ value: item.value, dataMaps })
    default:
      return String(item.value)
  }
}

export const getRowSubtitle = (row: AuditLogRow) => {
  if (row.entity === EMPTY_VALUE_FALLBACK || row.entity === row.logItem.Model.value.id) {
    return undefined
  }
  return row.entity
}
