import { Button, Input, Popup } from '@revolut/ui-kit'
import { useCallback, useState, KeyboardEvent } from 'react'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { getUrlValidity } from './utils'

export const ClientAddUrlPopup = (props: { onSubmit: (newUrl: string) => void }) => {
  const [newUrl, setNewUrl] = useState('http://')
  const onChange = useInputStringChange(setNewUrl)
  const disabled = !newUrl || !getUrlValidity(newUrl)
  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter' && !disabled) {
        props.onSubmit(newUrl)
      }
    },
    [newUrl, disabled, props],
  )

  return (
    <>
      <Input
        label="URL"
        onChange={onChange}
        value={newUrl}
        autoFocus
        onKeyPress={onKeyPress}
      />
      <Popup.Actions>
        <Button elevated onClick={() => props.onSubmit(newUrl)} disabled={disabled}>
          Add URL
        </Button>
      </Popup.Actions>
    </>
  )
}
