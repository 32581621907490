import { Table, TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { ToxicPairLink } from 'components/Links/ToxicPairLink'
import { PermissionLink } from 'components/Links/PermissionLink'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { AlertRow } from './types'

export const COLUMNS: TableColumn<AlertRow>[] = [
  {
    Header: 'Name',
    accessor: 'toxicPairName',
    Cell: (params: { row: { original: AlertRow } }) => {
      const { id } = params.row.original
      return (
        <Table.Cell>
          <ToxicPairLink id={id} />
        </Table.Cell>
      )
    },
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'First permission',
    accessor: 'firstPermissionName',
    Cell: (params: { row: { original: AlertRow } }) => {
      const { firstPermissionId } = params.row.original
      return (
        <Table.Cell>
          {firstPermissionId ? (
            <PermissionLink id={firstPermissionId} withClient />
          ) : (
            EMPTY_VALUE_FALLBACK
          )}
        </Table.Cell>
      )
    },
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Second permission',
    accessor: 'secondPermissionName',
    Cell: (params: { row: { original: AlertRow } }) => {
      const { secondPermissionId } = params.row.original
      return (
        <Table.Cell>
          {secondPermissionId ? (
            <PermissionLink id={secondPermissionId} withClient />
          ) : (
            EMPTY_VALUE_FALLBACK
          )}
        </Table.Cell>
      )
    },
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
