import { IDAVE_PERMISSIONS } from 'security'

export const CREATE_CLIENT = [
  IDAVE_PERMISSIONS.CLIENTS_CREATE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_CLIENT_CREATE_ACTION,
]

export const EDIT_CLIENT = [
  IDAVE_PERMISSIONS.CLIENTS_UPDATE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_CLIENT_UPDATE_ACTION,
]

export const DELETE_CLIENT = [
  IDAVE_PERMISSIONS.CLIENTS_DELETE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_CLIENT_DELETE_ACTION,
]

export const CREATE_ACCOUNT = [
  IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_CREATE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_SERVICE_ACCOUNT_CREATE_ACTION,
]

export const DELETE_ACCOUNT = [
  IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_DELETE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_SERVICE_ACCOUNT_DELETE_ACTION,
]

export const EDIT_ACCOUNT = [
  IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_UPDATE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_SERVICE_ACCOUNT_UPDATE_ACTION,
]

export const ACTIVATE_ACCOUNT = [
  IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_ACTIVATE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_SERVICE_ACCOUNT_ACTIVATE_ACTION,
]

export const TERMINATE_ACCOUNT = [
  IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_TERMINATE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_SERVICE_ACCOUNT_TERMINATE_ACTION,
]
