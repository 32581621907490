import api from 'api/sam'
import { SamPolicy } from 'api/sam/policies'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'hooks/useToasts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { PolicyDetails } from 'view/Sam/components/SamEditPolicyDetails'
import { SAM_PERMISSIONS } from 'security'
import { useCrossCheck } from 'hooks/useCrossCheck'

export const usePolicyUpdate = (policy: SamPolicy, policyDetails: PolicyDetails) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { showSuccessToast } = useToasts()
  const { toCrosscheck, isDirect } = useCrossCheck(
    SAM_PERMISSIONS.POLICIES_UPDATE,
    SAM_PERMISSIONS.POLICIES_UPDATE_WITH_CROSS_CHECK,
  )
  const crossCheckMutation = useMutation({
    mutationFn: () =>
      api.policies.crossCheckUpdatePolicy({
        policyId: policy.policyId,
        data: {
          mandatoryTrainings: policy.mandatoryTrainings || [],
          resourceIds: policy.resourceIds,
          businessReason: policyDetails.businessReason,
          policyName: policyDetails.policyName,
        },
      }),
    onSuccess: (response) => {
      toCrosscheck(response.data.crossCheckId)
    },
  })

  const updateMutation = useMutation({
    mutationFn: () =>
      api.policies.updatePolicy({
        policyId: policy.policyId,
        data: {
          mandatoryTrainings: policy.mandatoryTrainings,
          businessReason: policyDetails.businessReason,
          policyName: policyDetails.policyName,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SamPolicies],
        refetchType: 'all',
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SamPolicy, policy.policyId],
      })
      queryClient.resetQueries({
        queryKey: [QueryKey.SamAuditLogs, 'access-policy', policy.policyId],
      })

      navigate(generatePath(Url.SamPolicy, { policyId: policy.policyId }))
      showSuccessToast('Policy edited')
    },
  })

  return isDirect
    ? {
        update: () => updateMutation.mutate(),
        isUpdating: updateMutation.isLoading,
      }
    : {
        update: () => crossCheckMutation.mutate(),
        isUpdating: crossCheckMutation.isLoading,
      }
}
