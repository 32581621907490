import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryRoleIdMap, useQueryRoles } from 'queries/idave/roles'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useRolePreview } from 'components/previews/RolePreview'
import { mergeQueryStatuses } from 'utils/query'
import { getRoleEntities } from './utils'
import { RoleRow } from './types'
import { COLUMNS } from './columns'

export const PermissionRoles = (props: { permissionId: string }) => {
  const { permissionId } = props
  const { data: roles = [], status: qs, fetchStatus: fs } = useQueryRoles()
  const { data: roleMap = new Map() } = useQueryRoleIdMap()
  const entities = useMemo(
    () => getRoleEntities({ roles, roleMap, permissionId }),
    [roleMap, roles, permissionId],
  )
  const { searched, setSearchValue, searchValue } = useSearchFilter({ entities })
  const getRowLink = useCallback(
    (role: RoleRow) => generatePath(Url.Role, { roleId: role.id }),
    [],
  )
  const loadingState = getLoadingState(mergeQueryStatuses({ qs, fs }), roles.length)
  const { openRoleSide } = useRolePreview()
  const onRowClick = useCallback((row: RoleRow) => openRoleSide(row.id), [openRoleSide])

  return (
    <EntitiesTable
      entitiesTypeLabel="Roles"
      pluralForms={['role', 'roles']}
      totalCount={entities.length}
      data={searched}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      columns={COLUMNS}
      getRowLink={getRowLink}
      loadingState={loadingState}
      onRowClick={onRowClick}
    />
  )
}
