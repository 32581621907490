import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { PermissionRow } from './types'

export const COLUMNS: TableColumn<PermissionRow>[] = [
  {
    Header: 'Name',
    accessor: 'permission',
  },
  {
    Header: 'Application',
    accessor: 'client',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
