import { CustomerCompany } from 'api/dart/customerCompanies'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'
import { EmployeeRevoDescriptionGetter } from 'hooks/useGetEmployeeRevoDescription'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { EmployeeAccesses } from 'api/dart/employee'
import { sortBy } from 'lodash'
import { Row } from './types'

type GetRowsParams = {
  userMap: Map<string, UserListItem>
  customerCompany?: CustomerCompany
  avatars?: Map<string, RevolutersAvatar>
  employeesAccesses?: EmployeeAccesses[]
  getEmployeeDescription: EmployeeRevoDescriptionGetter
}

export const getRows = ({
  userMap,
  customerCompany,
  getEmployeeDescription,
  employeesAccesses = [],
}: GetRowsParams): Row[] => {
  const directAccesses = getDirectAccesses(employeesAccesses, customerCompany?.id)

  return sortBy(
    (customerCompany?.allowedEmployees || []).map(({ id, allowedAccessModifier }) => {
      const user = userMap.get(id)

      return {
        id,
        email: user?.email || EMPTY_VALUE_FALLBACK,
        team: user
          ? getEmployeeDescription(user.id)?.team?.name || EMPTY_VALUE_FALLBACK
          : EMPTY_VALUE_FALLBACK,
        expirationDate: allowedAccessModifier.expirationDate,
        accessModifierId: directAccesses[id],
        state: user?.state,
      }
    }),
    'name',
  )
}

const getDirectAccesses = (employeesAccesses: EmployeeAccesses[], companyId?: string) => {
  if (!companyId) {
    return {}
  }

  return employeesAccesses.reduce<Record<string, string>>((acc, employeeAccesses) => {
    const modifier = employeeAccesses.accessModifiers.find(
      (item) => item.entityId?.id === companyId,
    )
    if (modifier) {
      return { ...acc, [employeeAccesses.id]: modifier.id }
    }

    return acc
  }, {})
}
