import { Text, TextSkeleton } from '@revolut/ui-kit'
import { useQueryAuditLog } from 'queries/idave/logs'
import { mergeQueryStatuses } from 'utils/query'

type Props = {
  eventId: string
}

export const Title = ({ eventId }: Props) => {
  const { data: logs, fetchStatus: fs, status: qs } = useQueryAuditLog({ eventId })
  const status = mergeQueryStatuses({ fs, qs })

  const log = logs?.[0]

  const name = log?.Action.value || eventId

  return status === 'loading' ? <TextSkeleton size={12} /> : <Text>{name}</Text>
}
