import { TableColumn } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { Group } from 'api/idave/groups'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { GroupCell, getGroupCellText } from 'components/GroupCell'
import { capitalizeFirst } from 'utils/string'
import { StateCell } from 'components/StateCell'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const getColumns = (groupMap: Map<string, Group>): TableColumn<Client>[] => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Scope',
    accessor: 'scope',
  },
  {
    Header: 'Owner group',
    accessor: ({ ownerGroupId }: Client) => getGroupCellText({ ownerGroupId, groupMap }),
    Cell: (params: { row: { original: Client } }) => (
      <GroupCell groupMap={groupMap} ownerGroupId={params.row.original.ownerGroupId} />
    ),
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Status',
    accessor: ({ state }: Client) =>
      state ? capitalizeFirst(state) : EMPTY_VALUE_FALLBACK,
    Cell: StateCell,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    minWidth: 80,
    maxWidth: 80,
  },
  {
    Header: 'Source',
    accessor: 'source',
    minWidth: 80,
    maxWidth: 80,
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
