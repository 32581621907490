import { IDAVE_PERMISSIONS, SAM_PERMISSIONS } from 'security'
import { UIRoute, Url } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { Roles, Role } from '.'
import { RoleEdit } from './Role/RoleEdit'
import { RoleCreate } from './Role/RoleCreate'
import { CREATE, EDIT } from './permissions'

export const routes = ({
  hasPermission,
  hasSomePermissions,
  hasEveryPermission,
}: PermissionUtils): Record<string, UIRoute> => ({
  [Url.Roles]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.ROLES_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: Roles,
  },

  [Url.Role]: {
    type: 'redirect',
    params: ['roleId'],
    newUrl: Url.RoleDetails,
  },

  [Url.RoleDetails]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.ROLES_VIEW_DETAILS),
    fallbackRoute: Url.Roles,
    params: ['roleId'],
    Component: (props: { roleId: string }) => (
      <Role tab="details" roleId={props.roleId} />
    ),
  },

  [Url.RolePermissions]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.ROLES_VIEW_DETAILS),
    fallbackRoute: Url.Roles,
    params: ['roleId'],
    Component: (props: { roleId: string }) => (
      <Role tab="permissions" roleId={props.roleId} />
    ),
  },

  [Url.RolePolicies]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_LIST),
    fallbackRoute: Url.Roles,
    params: ['roleId'],
    Component: (props: { roleId: string }) => (
      <Role tab="policies" roleId={props.roleId} />
    ),
  },

  [Url.RoleUsers]: {
    withParams: true,
    type: 'component',
    allowed: hasEveryPermission(IDAVE_PERMISSIONS.ROLES_VIEW_DETAILS),
    fallbackRoute: Url.Roles,
    params: ['roleId'],
    Component: (props: { roleId: string }) => <Role tab="users" roleId={props.roleId} />,
  },

  [Url.RoleAuditLogs]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    fallbackRoute: Url.Roles,
    params: ['roleId'],
    Component: (props: { roleId: string }) => (
      <Role tab="auditLog" roleId={props.roleId} />
    ),
  },

  [Url.AddRole]: {
    withParams: false,
    type: 'component',
    allowed: hasSomePermissions(...CREATE),
    fallbackRoute: Url.Roles,
    Component: () => <RoleCreate />,
  },

  [Url.AddRoleDuplicate]: {
    withParams: true,
    params: ['roleId'],
    type: 'component',
    allowed: hasSomePermissions(...CREATE),
    fallbackRoute: Url.Roles,
    Component: (props: { roleId: string }) => <RoleCreate roleId={props.roleId} />,
  },

  [Url.EditRole]: {
    withParams: true,
    type: 'component',
    allowed: hasSomePermissions(...EDIT),
    fallbackRoute: Url.Roles,
    params: ['roleId'],
    Component: RoleEdit,
  },
})
