import { Box, Search, Widget } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryEmployeeDetails } from 'queries/dart/employees'
import { useQueryCustomerCompanies } from 'queries/dart/companies'
import { memo, useCallback, useMemo, useState } from 'react'
import { DART_PERMISSIONS } from 'security'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { useMapify } from 'hooks/useMapify'
import { CompanyListHeader } from './components/CompanyListHeader'
import { CompanyListSkeleton } from './components/CompanyListSkeleton'
import { CompanyList as CompanyListComponent } from './components/CompanyList'
import { getFilteredCompanies } from './utils'

type Props = {
  employeeId: string
  scrollRef?: React.RefObject<HTMLDivElement>
  onChange: (selected: string[]) => void
}

export const CompanyList = memo(({ employeeId, onChange, scrollRef }: Props) => {
  const {
    data: customerCompanies = [],
    status: customerCompaniesS,
    fetchStatus: customerCompaniesFS,
  } = useQueryCustomerCompanies()
  const {
    data: employeeDetails,
    status: employeeDetailsS,
    fetchStatus: employeeDetailsFS,
  } = useQueryEmployeeDetails(employeeId)

  const [value, setValue] = useState<string[]>([])
  const selectedHash = useMemo(() => toPresenceMap(value), [value])

  const [showSelected, setShowSelected] = useState(false)

  const update = useCallback(
    (newIds: string[]) => {
      onChange(newIds)
      setValue(newIds)
      if (!newIds.length && showSelected) {
        setShowSelected(false)
      }
    },
    [onChange, showSelected],
  )

  const employeeAccesses = useMapify(
    employeeDetails?.accessModifiers || [],
    ({ entityId }) => entityId?.id,
  )

  const employeeCompanyAccesses = useMapify(
    employeeDetails?.company?.accessModifiers || [],
    ({ entityId }) => entityId?.id,
  )

  const availableCustomerCompanies = useMemo(
    () =>
      customerCompanies.filter(
        (v) => !employeeAccesses.has(v.id) && !employeeCompanyAccesses.has(v.id),
      ),
    [customerCompanies, employeeAccesses, employeeCompanyAccesses],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: availableCustomerCompanies,
  })
  const items = useMemo(
    () =>
      getFilteredCompanies({
        companies: searched,
        selectedHash,
        showSelected,
      }),
    [searched, selectedHash, showSelected],
  )

  const onShowSelectedClick = useCallback(() => {
    setShowSelected((v) => !v)
    setSearchValue('')
  }, [setShowSelected, setSearchValue])

  const onSearchUpdate = useCallback(
    (search: string) => {
      setShowSelected(false)
      setSearchValue(search)
    },
    [setSearchValue, setShowSelected],
  )

  return (
    <>
      <CompanyListHeader
        count={value.length}
        size={items.length}
        onShowSelectedClick={onShowSelectedClick}
        showSelected={showSelected}
      />
      <Widget maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <Box p="s-16" pb="0">
          <Search value={searchValue} placeholder="Search" onChange={onSearchUpdate} />
        </Box>
        <QuerySwitch
          required={[
            { qs: customerCompaniesS, fs: customerCompaniesFS },
            { qs: employeeDetailsS, fs: employeeDetailsFS },
          ]}
          data={searched}
          renderIdle={() => (
            <NoAccessWidget
              title={`Looks like you don’t have sufficient permissions to view this (${DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_LIST.value})`}
            />
          )}
          renderLoading={CompanyListSkeleton}
          renderSuccess={() => (
            <CompanyListComponent
              update={update}
              items={items}
              selectedHash={selectedHash}
              scrollRef={scrollRef}
            />
          )}
        />
      </Widget>
    </>
  )
})
