import { Button, Flex, Page } from '@revolut/ui-kit'

type FormMultiStepNavigationProps = {
  labelBackward?: string
  labelForward?: string
  disabledForward?: boolean
  disabledBackward?: boolean
  pending?: boolean
  onForwardClick?: () => void
  onBackwardClick?: () => void
}

export const FormMultiStepNavigation = (props: FormMultiStepNavigationProps) => {
  const {
    labelBackward = 'Back',
    labelForward = 'Continue',
    disabledBackward,
    disabledForward,
    pending,
    onBackwardClick,
    onForwardClick,
  } = props

  return (
    <Page.MainActions>
      <Flex gap="s-16">
        <Button
          id="form-backward"
          disabled={disabledBackward}
          onClick={onBackwardClick}
          pending={pending}
          variant="secondary"
          elevated
        >
          {labelBackward}
        </Button>
        <Button
          id="form-forward"
          variant="primary"
          elevated
          disabled={disabledForward}
          onClick={onForwardClick}
          pending={pending}
        >
          {labelForward}
        </Button>
      </Flex>
    </Page.MainActions>
  )
}
