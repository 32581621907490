import { Box } from '@revolut/ui-kit'
import { CustomerCompany } from 'api/dart/customerCompanies'
import { EmployeeCompany } from 'api/dart/employeeCompanies'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryEmployeeCompanies } from 'queries/dart/companies'
import { useCallback, useState } from 'react'
import { randomKey } from 'utils/common/randomKey'
import { EmployeeCompanyList } from './components/EmployeeCompanyList'

type Props = {
  customerCompany?: CustomerCompany
}

export const EmployeeCompanies = ({ customerCompany }: Props) => {
  const { data, status: qs, fetchStatus: fs } = useQueryEmployeeCompanies()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderLoading={() => <Inner customerCompany={customerCompany} />}
      renderSuccess={({ data: employeeCompanies }) => (
        <Inner customerCompany={customerCompany} employeeCompanies={employeeCompanies} />
      )}
    />
  )
}

type InnerProps = {
  customerCompany?: CustomerCompany
  employeeCompanies?: EmployeeCompany[]
}
const Inner = ({ customerCompany, employeeCompanies }: InnerProps) => {
  const [resetKey, setResetKey] = useState(randomKey())

  const reset = useCallback(() => {
    setResetKey(randomKey())
  }, [setResetKey])

  return (
    <Box key={resetKey}>
      <EmployeeCompanyList
        reset={reset}
        customerCompany={customerCompany}
        employeeCompanies={employeeCompanies}
      />
    </Box>
  )
}
