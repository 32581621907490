import axios, { AxiosPromise } from 'axios'

export type Credentials = {
  username: string
  password: string
}

export type RefreshPayload = {
  // refresh token from cookies
  refresh_token: string
  // client id it is id of client exp. idave - 269570c5-3d9e-406f-a989-5e76300bbb49
  client_id?: string
  // or use client name
  client_name?: string
  // refresh_token for refresh token
  grant_type: string
}

export type CredentialsResponse = {
  message: string
  code: number
}

export type SsoUser = {
  user: {
    firstName: string
    lastName: string
    email: string
  }
}

export const AuthErrorCode = {
  OTP_REQUIRED: 2020,
  INVALID_CREDENTIALS: 2030,
}

const auth = {
  validateAuthToken: (reauthenticate: boolean = false) =>
    axios.get(
      `/api/auth/credentials?auth_type=${reauthenticate ? 'reauthenticate' : 'default'}`,
    ),

  refreshAuthToken: (payload: RefreshPayload) => axios.post('/api/token', payload),

  submitCredentials: (
    credentials: Credentials,
    params: string,
  ): AxiosPromise<CredentialsResponse> =>
    axios.post(`/api/auth/credentials?${params}`, credentials),

  submitOtp: (code: string): AxiosPromise<{ redirectUri: string }> =>
    axios.post(`/api/auth/2fa/verify?code=${code}`),

  submitGoogleToken: (idToken: string) =>
    axios.post('/api/auth/google-token', { id_token: idToken }),

  getSsoUser: () => axios.get<SsoUser>('/api/auth/session'),

  postSsoAuth: () => axios.post<void>('/api/auth/session'),

  logout: () => axios.post<void>('/api/oauth2/logout'),

  logoutSso: () => axios.post<void>('/api/auth/logout'),
}

export default auth
