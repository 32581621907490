import { Text } from '@revolut/ui-kit'
import { Group } from 'api/idave/groups'
import { StatusText } from 'components/StatusText'

export const GroupNameText = ({
  name,
  state,
}: {
  name: string
  state?: Group['state']
}) => {
  if (state === 'DELETED') {
    return (
      <StatusText level="warning">
        <Text fontSize="caption" fontWeight="400">
          (Deleted) {name}
        </Text>
      </StatusText>
    )
  }

  return <Text>{name}</Text>
}
