import { Client } from 'api/idave/clients'
import { OrphanPermission, Permission } from 'api/idave/permissions'
import { useEffect, useMemo } from 'react'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { getPermissionItems } from './utils'

type Params = {
  clientMap: Map<string, Client>
  orphans: OrphanPermission[]
  permissions: Permission[]
  showOrphaned: boolean
}
export const usePermissionList = ({
  clientMap,
  orphans,
  permissions,
  showOrphaned,
}: Params) => {
  const orphanedIdsMap = useMemo(
    () => toPresenceMap(orphans.map((i) => i.permission)),
    [orphans],
  )

  // Init both items (& searches) to avoid lags during orphans/common switching
  const { commonItems, orphanedItems } = useMemo(
    () =>
      getPermissionItems({
        permissions,
        clientMap,
        orphanedIdsMap,
      }),
    [permissions, clientMap, orphanedIdsMap],
  )

  const commonSearch = useSearchFilter({ entities: commonItems })
  const orphanSearch = useSearchFilter({ entities: orphanedItems })

  const { searched, setSearchValue, searchValue } = useMemo(
    () => (showOrphaned ? orphanSearch : commonSearch),
    [showOrphaned, commonSearch, orphanSearch],
  )

  useEffect(() => {
    if (showOrphaned) {
      commonSearch.setSearchValue('')
    } else {
      orphanSearch.setSearchValue('')
    }
    // Igonre commonSearch & orphanSearch updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOrphaned])

  return {
    entitiesCount: showOrphaned ? orphanedItems.length : commonItems.length,
    data: searched,
    onSearchChange: setSearchValue,
    searchValue,
  }
}
