import { IDAVE_PERMISSIONS, SAM_PERMISSIONS, DART_PERMISSIONS } from 'security'
import { Url, UIRoute } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { CrossCheckList } from './CrossCheckList'
import { CrossCheck } from './CrossCheck'

export const routes = ({
  hasSomePermissions,
  hasPermission,
}: PermissionUtils): Record<string, UIRoute> => {
  const xChecksAllowed = hasSomePermissions(
    IDAVE_PERMISSIONS.CROSS_CHECKS_VIEW_LIST,
    SAM_PERMISSIONS.CROSS_CHECKS_VIEW_LIST,
    DART_PERMISSIONS.CROSS_CHECKS_VIEW_LIST,
  )
  const userDetailsAllowed = hasPermission(IDAVE_PERMISSIONS.USERS_CURRENT_VIEW_DETAILS)

  return {
    [Url.CrossChecks]: {
      type: 'redirect',
      params: [],
      newUrl:
        xChecksAllowed && userDetailsAllowed
          ? Url.CrossChecksToReview
          : Url.CrossChecksAll,
    },

    [Url.CrossChecksToReview]: {
      withParams: false,
      type: 'component',
      allowed: xChecksAllowed && userDetailsAllowed,
      fallbackRoute: Url.CrossChecks,
      Component: () => <CrossCheckList tab="reviews" />,
    },

    [Url.CrossChecksRequested]: {
      withParams: false,
      type: 'component',
      allowed: xChecksAllowed && userDetailsAllowed,
      fallbackRoute: Url.CrossChecks,
      Component: () => <CrossCheckList tab="requests" />,
    },

    [Url.CrossChecksAll]: {
      withParams: false,
      type: 'component',
      allowed: xChecksAllowed,
      fallbackRoute: Url.Home,
      Component: () => <CrossCheckList tab="all" />,
    },

    [Url.CrossCheckIdaveDetails]: {
      withParams: true,
      type: 'component',
      allowed: hasPermission(IDAVE_PERMISSIONS.CROSS_CHECKS_VIEW_DETAILS),
      fallbackRoute: Url.CrossChecks,
      params: ['crossCheckId'],
      Component: (props: { crossCheckId: string }) => {
        return <CrossCheck source="idave" crossCheckId={props.crossCheckId} />
      },
    },

    [Url.CrossCheckSamDetails]: {
      withParams: true,
      type: 'component',
      allowed: hasPermission(SAM_PERMISSIONS.CROSS_CHECKS_VIEW_DETAILS),
      fallbackRoute: Url.CrossChecks,
      params: ['crossCheckId'],
      Component: (props: { crossCheckId: string }) => (
        <CrossCheck source="sam" crossCheckId={props.crossCheckId} />
      ),
    },

    [Url.CrossCheckDartDetails]: {
      withParams: true,
      type: 'component',
      allowed: hasPermission(DART_PERMISSIONS.CROSS_CHECKS_VIEW_DETAILS),
      fallbackRoute: Url.CrossChecks,
      params: ['crossCheckId'],
      Component: (props: { crossCheckId: string }) => (
        <CrossCheck source="dart" crossCheckId={props.crossCheckId} />
      ),
    },
  }
}
