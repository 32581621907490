import { Box, Subheader } from '@revolut/ui-kit'
import { Group } from 'api/idave/groups'
import { DetailsGroup } from 'components/DetailsGroup'
import { GroupOwners } from 'components/GroupOwners'
import { RevolutersEmployeeLink } from 'components/Links/RevolutersEmployeeLink'
import { RevolutersTeamLink } from 'components/Links/RevolutersTeamLink'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { Actions } from './components/Actions'

export const Details = ({ group }: { group: Group }) => {
  return (
    <>
      {group.type === 'idave:team' && <Actions group={group} />}

      <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>
        <DetailsGroup
          hideEmpty
          rows={[
            ['Name', group.name],
            ['Type', group.type],
            ['Code', group.code],
            ['Description', group.description],
            ['Owners', <GroupOwners groupId={group.id} key={group.id} />],
            [
              'People Ops',
              group.type === 'peopleops:team' && group.externalRef && (
                <RevolutersTeamLink id={group.externalRef.reference} />
              ),
            ],
            [
              'People Ops',
              group.type === 'peopleops:lm' && group.externalRef && (
                <RevolutersEmployeeLink id={group.externalRef.reference} />
              ),
            ],
          ]}
        />
      </Box>
    </>
  )
}
