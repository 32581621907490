import { Role } from 'api/idave/roles'
import { useQueryPermissionIdMap, useQueryToxicPairs } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { waitForAll } from 'utils/query'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useMemo } from 'react'
import { uniq } from 'lodash'
import { makeStaticGetToxicRowState } from 'view/Roles/Role/utils'
import { matchToxicPairsByPermissions } from 'utils/toxicPairs/matchToxicPairsByPermissions'
import { RolePermissionsList } from './RolePermissionsList'
import { RolePermissionAlertBanner } from '../../../RolePermissionAlertBanner'
import { getRolePermissions } from '../../utils'

type RolePermissionListProps = {
  openAdding: () => void
  deletePermissions: (permissions: string[]) => void
  openToxicSide: (toxicPairIds: string[]) => void
  isLoading: boolean
  role: Role
}
export const RolePermissionsWidget = ({
  openAdding,
  deletePermissions,
  openToxicSide,
  isLoading,
  role,
}: RolePermissionListProps) => {
  const {
    data: roleMap = new Map(),
    status: roleStatus,
    fetchStatus: roleFetchStatus,
    isFetching,
  } = useQueryRoleIdMap()

  const {
    data: permissionMap = new Map(),
    status: permissionStatus,
    fetchStatus: permissionsFS,
  } = useQueryPermissionIdMap()

  const {
    data: clientMap = new Map(),
    status: clientStatus,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  const { data: toxicPairs = [] } = useQueryToxicPairs()

  const rows = useMemo(
    () => getRolePermissions({ role, permissionMap, roleMap }),
    [role, permissionMap, roleMap],
  )

  const rolePermissionIds = useMemo(() => uniq(rows.map((r) => r.permissionId)), [rows])
  const activeToxicPairIds = matchToxicPairsByPermissions(rolePermissionIds, toxicPairs)

  const status = waitForAll(
    [{ qs: permissionStatus, fs: permissionsFS }],
    [
      { qs: clientStatus, fs: clientsFS },
      {
        qs: roleStatus,
        fs: roleFetchStatus,
      },
    ],
  )
  const loadingState = getLoadingState(status, rows.length)

  const getRowState = useMemo(
    () => makeStaticGetToxicRowState(activeToxicPairIds),
    [activeToxicPairIds],
  )

  return (
    <>
      <RolePermissionAlertBanner
        viewCase="reading"
        openToxicSide={openToxicSide}
        toxicPair={activeToxicPairIds}
      />
      <RolePermissionsList
        key={role.permissions.length}
        roleId={role.id}
        openAdding={openAdding}
        deletePermissions={deletePermissions}
        isActionsDisabled={isLoading || isFetching}
        hasToxicPermissions={!!activeToxicPairIds.length}
        roleMap={roleMap}
        clientMap={clientMap}
        rows={rows}
        loadingState={loadingState}
        getRowState={getRowState}
      />
    </>
  )
}
