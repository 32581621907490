import { useQuerySamAuditLogs } from 'queries/sam/logs'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { SamPolicyLogsTable } from './SamPolicyLogsTable'

export const SamPolicyLogs = (props: { id: string }) => {
  const {
    data: response,
    status,
    refetch,
    fetchStatus,
  } = useQuerySamAuditLogs({
    entityType: 'access-policy',
    entityId: props.id,
  })

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={response?.data}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => <RequestErrorWidget action={refetch} />}
      renderLoading={() => (
        <SamPolicyLogsTable items={[]} loadingStatus={status} policyId={props.id} />
      )}
      renderSuccess={({ data }) => (
        <SamPolicyLogsTable items={data} loadingStatus={status} policyId={props.id} />
      )}
    />
  )
}
