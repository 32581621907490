import { isProduction } from 'utils/common'

const IDAVE_DEV_APP_ID = '269570c5-3d9e-406f-a989-5e76300bbb49'
const IDAVE_PROD_APP_ID = '269570c5-3d9e-406f-a989-5e76300bbb49'
export const IDAVE_APP_ID = isProduction() ? IDAVE_PROD_APP_ID : IDAVE_DEV_APP_ID

const SAM_DEV_APP_ID = '3b019f99-d115-4b27-99ee-e17b81ebbafe'
const SAM_PROD_APP_ID = '1a9b0336-11b8-49f2-8092-fe0ad0ac7b7d'
export const SAM_APP_ID = isProduction() ? SAM_PROD_APP_ID : SAM_DEV_APP_ID

const TOWER_DEV_APP_ID = 'e8656d78-882e-4d6d-ad31-60898f9cf52c'
const TOWER_PROD_APP_ID = 'e805717a-13e2-44a8-a18f-e97618a080a6'
export const TOWER_APP_ID = isProduction() ? TOWER_PROD_APP_ID : TOWER_DEV_APP_ID

const DART_DEV_APP_ID = '43c186cd-4859-4193-9ab9-414ce41c3973'
const DART_PROD_APP_ID = '10c61dc5-44e8-4bd9-aed5-68c5b7501f47'
export const DART_APP_ID = isProduction() ? DART_PROD_APP_ID : DART_DEV_APP_ID

const AUTOMATION_DEV_APP_ID = '41ce41cc-bf8e-4334-96d8-5e9f4e9a113e'
const AUTOMATION_PROD_APP_ID = '629a2364-ea9d-47fb-8c5d-6c038475c8bb'
export const AUTOMATION_APP_ID = isProduction()
  ? AUTOMATION_PROD_APP_ID
  : AUTOMATION_DEV_APP_ID
