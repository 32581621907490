export const alphabeticComparator = (a: string, b: string) => {
  const loweredA = a.toLowerCase()
  const loweredB = b.toLowerCase()

  if (loweredA > loweredB) {
    return 1
  }

  if (loweredA < loweredB) {
    return -1
  }

  return 0
}
