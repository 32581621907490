import { Group, useVirtualViewport } from '@revolut/ui-kit'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { useCallback, useRef } from 'react'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { CompanyListItem } from './components/CompanyListItem'
import { getSelectedValues } from './utils'
import { EmptyList } from './components/EmptyList'

type CompanyListProps = {
  update: (ids: string[]) => void
  items: CustomerCompanyItem[]
  selectedHash: Record<string, boolean>
  scrollRef?: React.RefObject<HTMLElement>
  avatars?: Map<string, RevolutersAvatar>
  disabled?: boolean
}

export const CompanyList = ({
  items,
  scrollRef,
  selectedHash,
  update,
}: CompanyListProps) => {
  const parentRef = useRef<HTMLElement>(null)
  const estimateSize = useCallback(() => 76, [])
  const keyExtractor = useCallback((index: number) => items[index].id, [items])

  const list = useVirtualViewport({
    parentRef,
    size: items.length,
    estimateSize,
    keyExtractor,
    overscan: 16,
    scrollRef,
  })

  const onItemChange = useCallback(
    (newItemId: string) =>
      update(
        getSelectedValues({
          selectedHash,
          newItemId,
        }),
      ),
    [update, selectedHash],
  )

  if (!items.length) {
    return <EmptyList />
  }

  return (
    <Group
      ref={parentRef}
      style={{
        position: 'relative' as const,
        height: `${list.totalSize}px`,
      }}
    >
      {list.virtualItems.map((virtual) => {
        const companyItem = items[virtual.index]

        return (
          <CompanyListItem
            key={companyItem.id}
            onChange={onItemChange}
            selectedHash={selectedHash}
            company={companyItem}
            virtualItem={virtual}
          />
        )
      })}
    </Group>
  )
}
