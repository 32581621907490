import { Box, StatusWidget, Subheader, TextSkeleton } from '@revolut/ui-kit'
import { User } from 'api/idave/user'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { UserLink } from 'components/Links/UserLink'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { AlertDescriptor } from 'components/previews/ToxicAlertPreview/types'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useQueryUser } from 'queries/idave/users'
import { get3DImageSrcSetProps } from 'utils/url'

type Props = {
  toxicAlertDescriptor: AlertDescriptor
}

export const UserInfo = ({ toxicAlertDescriptor: { userId } }: Props) => {
  const { data: user, fetchStatus: userFS, status: userQS } = useQueryUser({ id: userId })

  if (!userId) {
    return (
      <StatusWidget>
        <StatusWidget.Image {...get3DImageSrcSetProps('3D231', 'v2')} />
        <StatusWidget.Title>Lack of user id</StatusWidget.Title>
        <StatusWidget.Description>
          Toxic alert doesn&apos;t specify a user
        </StatusWidget.Description>
      </StatusWidget>
    )
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>User</Subheader.Title>
      </Subheader>
      <QuerySwitch
        data={user}
        required={[{ fs: userFS, qs: userQS }]}
        renderIdle={() => (
          <NoAccessWidget title="Looks like you don't have sufficient permissions to view user" />
        )}
        renderLoading={() => <DetailsGroupSkeleton />}
        renderSuccess={({ data }) => <Inner user={data} />}
      />
    </Box>
  )
}
const Inner = ({ user }: { user: User }) => {
  const { status: revoDescriptionStatus, getEmployeeDescription } =
    useGetEmployeeRevoDescription()
  const employeeDescription = getEmployeeDescription(user.id)

  return (
    <DetailsGroup
      rows={[
        ['Name', <UserLink id={user.id} key={user.id} />],
        [
          'ID',
          <CopyableText value={user.id} key="id">
            {user.id}
          </CopyableText>,
        ],
        [
          'Team',
          revoDescriptionStatus === 'loading' ? (
            <TextSkeleton size={9} />
          ) : (
            employeeDescription.team?.name || EMPTY_VALUE_FALLBACK
          ),
        ],

        [
          'Spec',
          revoDescriptionStatus === 'loading' ? (
            <TextSkeleton size={12} />
          ) : (
            employeeDescription.spec?.name || EMPTY_VALUE_FALLBACK
          ),
        ],
      ]}
    />
  )
}
