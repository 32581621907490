import { useQueryToxicAlerts, useQueryToxicPairsMap } from 'queries/idave/permissions'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useCallback, useMemo } from 'react'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { waitForAll } from 'utils/query'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useToxicAlertPreview } from 'components/previews/ToxicAlertPreview'
import { EntitiesTable } from 'components/EntitiesTable'
import { useColumns } from './columns'
import { getRows } from './utils'
import { AlertRow } from './types'

export const ToxicAlerts = () => {
  const {
    data: alerts = [],
    fetchStatus: alertsFS,
    status: alertsQS,
  } = useQueryToxicAlerts()
  const { data: users, fetchStatus: usersFS, status: usersQS } = useQueryUserIdMap()
  const { status: revoDescriptionStatus, getEmployeeDescription } =
    useGetEmployeeRevoDescription()
  const {
    data: toxicPairMap,
    fetchStatus: toxicFS,
    status: toxicQS,
  } = useQueryToxicPairsMap()

  const loadingState = getLoadingState(
    waitForAll(
      [{ qs: alertsQS, fs: alertsFS }],
      [
        { qs: usersQS, fs: usersFS },
        { qs: toxicQS, fs: toxicFS },
      ],
    ),
    alerts.length,
  )

  const isLoading = loadingState === 'pending' || revoDescriptionStatus === 'loading'
  const columns = useColumns({ isLoading, toxicPairMap })

  const entities = useMemo(
    () =>
      getRows({
        getEmployeeDescription,
        alerts,
        users,
      }),
    [alerts, users, getEmployeeDescription],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({ entities })
  const { openToxicAlertSide } = useToxicAlertPreview()
  const onRowClick = useCallback(
    ({ userId, toxicPermissionId }: AlertRow) =>
      openToxicAlertSide(toxicPermissionId, userId),
    [openToxicAlertSide],
  )

  return (
    <EntitiesTable
      entitiesTypeLabel="Alerts"
      pluralForms={['Alert', 'Alerts']}
      totalCount={entities.length}
      columns={columns}
      data={searched}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
      onRowClick={onRowClick}
    />
  )
}
