import { useQuery } from '@tanstack/react-query'
import samApi from 'api/sam'
import { QueryKey } from 'helpers/configQuery'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { Optioned } from '../types'
import { isEnabled } from '../utils'

export const useQuerySamCrossChecksList = (
  params: {
    reviewer?: string
    requester?: string
  } = {},
) => {
  const { reviewer, requester } = params
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamCrossChecksList, requester, reviewer],
    queryFn: () => samApi.crossChecks.getCrossChecksList({ requester, reviewer }),
    enabled: hasPermission(SAM_PERMISSIONS.CROSS_CHECKS_VIEW_LIST),
    select: (response) => response.data?.data,
  })
}

export const useQuerySamCrossCheckDetails = ({
  params: id,
  options,
}: Optioned<string>) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamCrossCheckDetails, id],
    queryFn: () => samApi.crossChecks.getCrossCheckDetails(id),
    select: (response) => response.data,
    enabled: isEnabled(hasPermission(SAM_PERMISSIONS.CROSS_CHECKS_VIEW_DETAILS), options),
  })
}
