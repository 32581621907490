import { Box } from '@revolut/ui-kit'
import { CustomerCompany } from 'api/dart/customerCompanies'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryDirectRelations } from 'queries/dart/employees'
import { useQueryUserIdMap } from 'queries/idave/users'
import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { EmployeeAccesses } from 'api/dart/employee'
import { useCallback, useState } from 'react'
import { randomKey } from 'utils/common/randomKey'
import { AllowedUserList } from './AllowedUserList'

type Props = {
  customerCompany: CustomerCompany
}

export const AllowedUsers = ({ customerCompany }: Props) => {
  const { data, status: qs, fetchStatus: fs } = useQueryUserIdMap()
  const {
    data: avatars,
    status: avatarsS,
    fetchStatus: avatarsFS,
  } = useQueryMappedAvatars()
  const {
    data: employeesAccesses,
    status: accessesS,
    fetchStatus: accessesFS,
  } = useQueryDirectRelations()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      optional={[
        { qs: avatarsS, fs: avatarsFS },
        { qs: accessesS, fs: accessesFS },
      ]}
      data={data}
      renderLoading={() => <AllowedUsersBase customerCompany={customerCompany} />}
      renderSuccess={({ data: userMap }) => (
        <AllowedUsersBase
          customerCompany={customerCompany}
          userMap={userMap}
          avatars={avatars}
          employeesAccesses={employeesAccesses}
        />
      )}
    />
  )
}

type AllowedUsersBaseProps = {
  customerCompany: CustomerCompany
  userMap?: Map<string, UserListItem>
  avatars?: Map<string, RevolutersAvatar>
  employeesAccesses?: EmployeeAccesses[]
}

const AllowedUsersBase = (props: AllowedUsersBaseProps) => {
  const [resetKey, setResetKey] = useState(randomKey())

  const reset = useCallback(() => {
    setResetKey(randomKey())
  }, [setResetKey])

  return (
    <Box key={resetKey}>
      <AllowedUserList reset={reset} {...props} />
    </Box>
  )
}
