import { useController, useFormContext } from 'react-hook-form'
import { RoleSelect, RoleSelectProps } from 'components/Selects/RoleSelect'
import { useOnChange } from 'utils/form/useOnChange'
import { Fielded } from 'utils/types/fielded'

export const FieldRoleSelect = (props: Fielded<RoleSelectProps>) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  const onChange = useOnChange(field.onChange)

  return (
    <RoleSelect
      {...rest}
      {...field}
      itemId={field.value}
      onChange={onChange}
      ref={undefined}
    />
  )
}
