import { CrossCheckDetail } from 'api/types/crossCheck'
import { PermissionList } from './components/PermissionList'
import { UrlsList } from './components/UrlList'
import { CrossCheckEntity } from '../../types'
import { ResourceList } from './components/ResourceList'
import { TrainingList } from './components/TrainingList'
import { RoleList } from './components/RoleList'
import { AccessModifierList } from './components/AccessModifierList'
import { isDartCrossCheck } from '../../utils'
import { ScopeList } from './components/ScopeList'
import { isCrosscheckWithoutAttributes } from './utils'
import { ClientDeletePermissions } from './components/ClientDeletePermissions'

export const AttributeLists = ({
  crossCheck,
  entity,
}: {
  crossCheck: CrossCheckDetail
  entity: CrossCheckEntity
}) => {
  if (isCrosscheckWithoutAttributes({ crossCheck })) {
    return null
  }

  switch (entity.type) {
    case 'Role':
      return (
        <PermissionList
          crossCheck={crossCheck}
          permissionIds={entity.value?.permissions}
          entityType={entity.type}
        />
      )

    case 'Client':
      return (
        <>
          <UrlsList crossCheck={crossCheck} client={entity.value} />
          <ClientDeletePermissions crossCheck={crossCheck} entity={entity} />
        </>
      )

    case 'AccessPolicy':
      return (
        <>
          <ResourceList crossCheck={crossCheck} policy={entity.value} />
          <TrainingList crossCheck={crossCheck} policy={entity.value} />
        </>
      )

    case 'User':
      return isDartCrossCheck(crossCheck) ? (
        <AccessModifierList crossCheck={crossCheck} entity={entity} />
      ) : (
        <RoleList crossCheck={crossCheck} user={entity.value} />
      )

    case 'CustomerCompany':
    case 'EmployeeCompany':
      return <AccessModifierList crossCheck={crossCheck} entity={entity} />

    case 'ServiceAccount':
      return (
        <>
          <PermissionList
            crossCheck={crossCheck}
            permissionIds={entity.value?.permissionIds}
            entityType={entity.type}
          />
          <ScopeList crossCheck={crossCheck} account={entity.value} />
        </>
      )

    default:
      return null
  }
}
