import { Avatar, TabBar, TextSkeleton, Page, HStack } from '@revolut/ui-kit'
import { CustomerCompany as TCustomerCompany } from 'api/dart/customerCompanies'
import { QuerySwitch } from 'components/QuerySwitch'
import { TabBarLink } from 'components/TabBarLink'

import { useQueryCustomerCompany } from 'queries/dart/companies'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { DART_PERMISSIONS, IDAVE_PERMISSIONS } from 'security'
import { getRegionFlagImageUrl } from 'utils/url/getRegionFlagImageUrl'
import { ExternalTextLink } from 'components/Links/ExternalTextLink'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { EmployeeCompanies } from './components/EmployeeCompanies'
import { AllowedUsers } from './components/AllowedUsers'
import { getCustomerCompanyLink } from './utils'
import { CustomerCompanyManagers } from './components/CustomerCompanyManagers'
import { CustomerCompanySkeleton } from './components/CustomerCompanySkeleton'

export type CustomerCompanyTab = 'employeeCompanies' | 'employee' | 'approvers'

type Props = {
  companyId: string
  tab: CustomerCompanyTab
}
export const CustomerCompany = ({ companyId, tab }: Props) => {
  const { data, status: qs, fetchStatus: fs } = useQueryCustomerCompany(companyId)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderLoading={() => (
        <Outer tab={tab} companyId={companyId}>
          <CustomerCompanySkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data: customerCompany }) => (
        <Outer tab={tab} companyId={companyId} customerCompany={customerCompany}>
          <Inner tab={tab} customerCompany={customerCompany} />
        </Outer>
      )}
    />
  )
}

type OuterProps = {
  tab: CustomerCompanyTab
  companyId: string
  children: React.ReactNode
  customerCompany?: TCustomerCompany
}
const Outer = ({ companyId, customerCompany, children }: OuterProps) => {
  const navigate = useNavigate()

  return (
    <>
      <Page.Header
        onBack={() => navigate(Url.DartCustomerCompanies)}
        subtitle="Customer company"
        description={
          <ExternalTextLink href={getCustomerCompanyLink(companyId)} text="Details" />
        }
      >
        <HStack align="center" space="s-4">
          {customerCompany?.region && (
            <Avatar
              size={20}
              uuid={companyId}
              image={getRegionFlagImageUrl(customerCompany.region)}
            />
          )}
          {customerCompany?.name || <TextSkeleton variant="h1" size={16} />}
        </HStack>
      </Page.Header>

      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck
            everyPermission={[
              DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_DETAILS,
              DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST,
            ]}
          >
            <TabBarLink
              to={generatePath(Url.DartCustomerCompanyAllowedCompanies, { companyId })}
            >
              Allowed Employee Companies
            </TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck
            everyPermission={[
              DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_DETAILS,
              IDAVE_PERMISSIONS.USERS_VIEW_LIST,
            ]}
          >
            <TabBarLink
              to={generatePath(Url.DartCustomerCompanyAllowedEmployees, { companyId })}
            >
              Allowed Users
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            everyPermission={[
              DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_DETAILS,
              IDAVE_PERMISSIONS.USERS_VIEW_LIST,
            ]}
          >
            <TabBarLink
              to={generatePath(Url.DartCustomerCompanyApprovers, { companyId })}
            >
              Approvers
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = ({
  tab,
  customerCompany,
}: {
  customerCompany: TCustomerCompany
  tab: CustomerCompanyTab
}) => {
  switch (tab) {
    case 'employee':
      return <AllowedUsers customerCompany={customerCompany} />
    case 'approvers':
      return <CustomerCompanyManagers customerCompany={customerCompany} />
    case 'employeeCompanies':
    default:
      return <EmployeeCompanies customerCompany={customerCompany} />
  }
}
