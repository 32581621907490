import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryResourcePolicies } from 'queries/sam/policies'
import { useMemo } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { useColumns } from './useColumns'
import { getRowLink } from './utils'

export const RolePolicies = ({ roleId }: { roleId: string }) => {
  const { data: policies = [], status, fetchStatus } = useQueryResourcePolicies(roleId)

  const entities = useMemo(
    () => policies.map((policy) => ({ ...policy, id: policy.policyId })),
    [policies],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities,
  })

  const state = getLoadingState(
    mergeQueryStatuses({ qs: status, fs: fetchStatus }),
    entities.length,
  )

  const columns = useColumns(roleId)

  return state === 'no-results' ? (
    <EmptyStatusWidget title="No policy associated with this role" imageCode="3D055" />
  ) : (
    <EntitiesTable
      data={searched}
      totalCount={entities.length}
      entitiesTypeLabel="Policies"
      pluralForms={['policy', 'policies']}
      loadingState={state}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      getRowLink={getRowLink}
      searchAutoFocus
    />
  )
}
