import { useCallback, useMemo } from 'react'
import { useQueryResources } from 'queries/sam/resources'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { mergeQueryStatuses } from 'utils/query'
import { EntitiesTable } from 'components/EntitiesTable'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { columns } from './columns'
import { Resource } from '../components/SamResourcesSide/types'

export const SamResources = () => {
  const { data, status, fetchStatus } = useQueryResources()

  const resources = useMemo(
    () => data?.map((r) => ({ ...r, id: r.resourceId })) || [],
    [data],
  )

  const tableLoadState = useMemo(
    () =>
      getLoadingState(
        mergeQueryStatuses({ qs: status, fs: fetchStatus }),
        resources.length,
      ),
    [resources.length, status, fetchStatus],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: resources,
  })

  const navigate = useNavigate()
  const getRowLink = useCallback(
    (row: Resource) => generatePath(Url.SamResource, { resourceId: row.resourceId }),
    [],
  )
  const onRowClick = useCallback(
    (row: Resource) => {
      navigate(getRowLink(row))
    },
    [navigate, getRowLink],
  )

  return (
    <EntitiesTable
      columns={columns}
      data={searched}
      loadingState={tableLoadState}
      entitiesTypeLabel="Resources"
      pluralForms={['resource', 'resources']}
      enableNavigation
      totalCount={resources.length}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      onRowClick={onRowClick}
      getRowLink={getRowLink}
      searchAutoFocus
    />
  )
}
