import { UAParser } from 'ua-parser-js'
import { Box, Subheader } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { UserAccessLogs } from 'api/idave/user'
import { DetailRow, DetailsGroup } from 'components/DetailsGroup'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { formatDateTimePrecise } from 'utils/date'

const INFO_FALLBACK = 'Unknown'
const userAgentInfo = (userAgent: string): DetailRow[] => {
  const ua = new UAParser(userAgent)
  const { version: browserVersion, name: browserName } = ua.getBrowser()
  const { version: osVersion, name: osName } = ua.getOS()

  const browser = browserName
    ? [browserName, browserVersion].join(' ').trim()
    : INFO_FALLBACK
  const os = osName ? [osName, osVersion].join(' ').trim() : INFO_FALLBACK

  return [
    ['Browser', browser],
    ['OS', os],
  ]
}

type UserAccessLogSideDetailsProps = {
  log: UserAccessLogs
  clientMap: Map<string, Client>
}

export const UserAccessLogSideDetails = (props: UserAccessLogSideDetailsProps) => {
  const { log, clientMap } = props

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Access log details</Subheader.Title>
      </Subheader>

      <DetailsGroup
        rows={[
          ['Client', clientMap.get(log.clientId)?.name || log.clientId],
          ['Date', formatDateTimePrecise(log.date)],
          ['IP', log.ip || EMPTY_VALUE_FALLBACK],
          ...userAgentInfo(log.userAgent),
          ['Access type', log.accessType],
        ]}
      />
    </Box>
  )
}
