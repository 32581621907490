import { MoreBar, TableColumn, Text, Token } from '@revolut/ui-kit'
import { SamResource, SamResourceType } from 'api/sam/resources'
import { useQueryResourceMap, useQueryResources } from 'queries/sam/resources'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { QueryResult, mergeQueryStatuses } from 'utils/query'
import { useCallback, useMemo, useState } from 'react'
import { notNullableMap } from 'utils/array'
import { useSearchFilter } from 'hooks/useSearchFilter'
import {
  filterActiveIds,
  filterInactiveIds,
  getLoadingState,
} from 'components/EntitiesTable/utils'
import { EntitiesTable, SelectEntitiesTableOverlay } from 'components/EntitiesTable'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { sortBy, uniq } from 'lodash'
import { SamPolicy } from 'api/sam/policies'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { Client } from 'api/idave/clients'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'

type SamPolicyResourcesProps = {
  policyResources: SamPolicy['resourceIds']
  policyStatus?: QueryResult
  isPending?: boolean
  isActive: boolean
  showAddPermissions: boolean
  showDeletePermissions: boolean
  emptyStatusTitle?: string
  allowRemoveAllResources?: boolean
  policyName: string
  addResources: (resources: SamPolicy['resourceIds']) => void
  deleteResources: (resources: SamPolicy['resourceIds']) => void
  onResourceClick?: (resource: SamResource) => void
}

const COLUMNS: TableColumn<SamResource & { id: string; clientName?: string }>[] = [
  {
    Header: 'Name',
    filter: 'includesValue',
    id: 'resourceName',
    accessor: (row) => (
      <Text>
        {row.resourceName}
        {row.clientName ? (
          <Text
            color={Token.color.warning}
          >{` Deprecated, please don't use (${row.clientName})`}</Text>
        ) : null}
      </Text>
    ),
  },
  {
    Header: 'Type',
    id: 'resourceType',
    accessor: (row) => getResourceTypeLabel(row.resourceType),
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]

export const SamPolicyResources = ({
  policyResources,
  policyStatus = 'success',
  isPending,
  isActive,
  showAddPermissions,
  showDeletePermissions,
  emptyStatusTitle,
  allowRemoveAllResources: allowEmptyResourceList = false,
  policyName,
  addResources,
  deleteResources,
  onResourceClick,
}: SamPolicyResourcesProps) => {
  const {
    data: allResources,
    status: resourcesS,
    fetchStatus: resourcesFS,
  } = useQueryResources()
  const { data: resourcesMap = new Map() } = useQueryResourceMap()
  const {
    data: rolesMap = new Map(),
    status: rolesMVPs,
    fetchStatus: rolesFS,
  } = useQueryRoleIdMap()
  const {
    data: clientsMap = new Map<string, Client>(),
    status: clientsS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  const status = mergeQueryStatuses(
    { qs: resourcesS, fs: resourcesFS },
    { qs: rolesMVPs, fs: rolesFS },
    { qs: clientsS, fs: clientsFS },
  )

  const formattedResources = useMemo(
    () =>
      policyResources.map((resourceId) => {
        const item = resourcesMap.get(resourceId)
        const oldRole = rolesMap.get(resourceId)
        const client = oldRole?.client ? clientsMap.get(oldRole.client) : undefined

        return item
          ? { ...item, id: resourceId, clientName: client?.name }
          : {
              id: resourceId,
              resourceId,
              externalId: '-',
              resourceName: `Unknown resource(${resourceId})`,
              resourceType: 'UNKNOWN' as SamResourceType,
              clientName: undefined,
            }
      }),
    [policyResources, resourcesMap, rolesMap, clientsMap],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: formattedResources,
  })
  const sorted = useMemo(
    () => sortBy(searched, ['resourceName', 'resourceType']),
    [searched],
  )
  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((v) => !v),
    [setShowSelected],
  )
  const onRemoveClick = useCallback(() => {
    const resourceIds = filterInactiveIds(formattedResources, selectedHash)
    deleteResources(resourceIds)
  }, [selectedHash, formattedResources, deleteResources])

  const onAddResourcesClick = useCallback(
    (newResources: SamResource[]) => {
      addResources(
        uniq([...formattedResources, ...newResources].map((i) => i.resourceId)),
      )
    },
    [formattedResources, addResources],
  )

  const [view, setView] = useState<'added' | 'available'>('added')

  const onShowAvailableClick = useCallback(() => {
    setView('available')
  }, [])
  const onShowAddedClick = useCallback(() => setView('added'), [])

  const availableResources = useMemo(
    () =>
      notNullableMap(allResources || [], (resource) => {
        const oldRole = rolesMap.get(resource.resourceId)
        const client = oldRole?.client ? clientsMap.get(oldRole.client) : undefined

        return formattedResources.find(
          (addedResource) => addedResource.id === resource.resourceId,
        )
          ? undefined
          : { ...resource, id: resource.resourceId, clientName: client?.name }
      }),
    [formattedResources, allResources, rolesMap, clientsMap],
  )

  const selectedCount = filterActiveIds(selectedHash).length
  const disableAddAction = formattedResources.length >= (allResources?.length || 0)
  const disableDeleteAction =
    !allowEmptyResourceList &&
    (formattedResources.length === 1 || selectedCount >= formattedResources.length)

  const showEmptyStatus = policyStatus === 'success' && !policyResources.length

  return (
    <>
      {view === 'available' && (
        <SelectEntitiesTableOverlay
          columns={COLUMNS}
          entitiesTypeLabel="Resources"
          pluralForms={['resource', 'resources']}
          loadingState={getLoadingState(status, availableResources.length)}
          entities={availableResources}
          onClose={onShowAddedClick}
          title="Add resources"
          onSelect={onAddResourcesClick}
          pending={isPending}
          SubtitleComponent={() => <Text>{policyName}</Text>}
        />
      )}

      {showEmptyStatus ? (
        <EmptyStatusWidget
          actionLabel="Add resources"
          title={emptyStatusTitle || 'No resources added to this policy'}
          imageCode="3D055"
          onClick={onShowAvailableClick}
          actionAllowed={showAddPermissions}
          disabled={false}
        />
      ) : (
        <EntitiesTable
          totalCount={formattedResources.length}
          entitiesTypeLabel="Resources"
          pluralForms={['resource', 'resources']}
          columns={COLUMNS}
          data={sorted}
          loadingState={getLoadingState(status, formattedResources.length)}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          searchAutoFocus
          selectedHash={selectedHash}
          switchShowSelected={switchShowSelected}
          showSelected={showSelected}
          setSelectedHash={isActive ? setSelectedHash : undefined}
          labelNoResults="No resources added"
          onRowClick={onResourceClick}
          renderActions={
            isActive
              ? getActionsRender({
                  selectedCount,
                  showAddPermissions,
                  showDeletePermissions,
                  disableAddAction,
                  disableDeleteAction,
                  onRemoveClick,
                  onAddClick: onShowAvailableClick,
                })
              : undefined
          }
        />
      )}
    </>
  )
}

const getActionsRender =
  (params: {
    selectedCount: number
    showAddPermissions: boolean
    showDeletePermissions: boolean
    disableDeleteAction: boolean
    disableAddAction: boolean
    onRemoveClick: () => void
    onAddClick: () => void
  }) =>
  () => {
    const {
      selectedCount,
      showAddPermissions,
      showDeletePermissions,
      disableAddAction,
      disableDeleteAction,
      onAddClick,
      onRemoveClick,
    } = params
    return (
      <>
        {!!selectedCount && showDeletePermissions && (
          <MoreBar.Action
            variant="negative"
            onClick={onRemoveClick}
            useIcon="Delete"
            disabled={disableDeleteAction}
          >
            Remove
          </MoreBar.Action>
        )}
        {!selectedCount && showAddPermissions && (
          <MoreBar.Action useIcon="Plus" onClick={onAddClick} disabled={disableAddAction}>
            Add resources
          </MoreBar.Action>
        )}
      </>
    )
  }
