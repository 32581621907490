import { useQueryCrossCheckDetails } from 'queries/idave/crossChecks'
import { useQuerySamCrossCheckDetails } from 'queries/sam/crossChecks'
import { useQueryDartCrossCheckDetails } from 'queries/dart/crossChecks'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { useMemo } from 'react'
import { isTruthy } from 'utils/types/isTruthy'

export type CrossCheckHookData = {
  source: CrossCheckSystem
  crossCheck?: CrossCheckDetail
}

export const useCrosscheckQuery = (params: {
  source: CrossCheckSystem
  crossCheckId: string
}) => {
  const { source, crossCheckId } = params

  const idaveQuery = useQueryCrossCheckDetails({
    params: crossCheckId,
    options: { enabled: source === 'idave' },
  })
  const dartQuery = useQueryDartCrossCheckDetails({
    params: crossCheckId,
    options: { enabled: source === 'dart' },
  })
  const samQuery = useQuerySamCrossCheckDetails({
    params: crossCheckId,
    options: { enabled: source === 'sam' },
  })

  const [query] = [
    source === 'sam' && samQuery,
    source === 'dart' && dartQuery,
    source === 'idave' && idaveQuery,
  ].filter(isTruthy)

  return useMemo(() => ({ source, query }), [source, query])
}
