import {
  CrossCheck,
  CrossCheckExecutableName,
  CrossCheckSystem,
} from 'api/types/crossCheck'
import { sortBy } from 'lodash'
import { UserListItem } from 'api/idave/user'
import { DataMaps } from '../types'
import { CrossCheckRow, CrossChecksTab, FilterParams } from './types'
import { getEntityInfo, getRequest } from '../utils'

type GetCrossCheckRowsParams = {
  idave: CrossCheck[]
  sam: CrossCheck[]
  dart: CrossCheck[]
  dataMaps: DataMaps
  isEmployeeSettled: boolean
  isRecertToPolycySettled: boolean
}

type GetRowParams = {
  crossCheck: CrossCheck
  source: CrossCheckSystem
  dataMaps: DataMaps
  isEmployeeSettled?: boolean
}

const getCrossCheckRow = ({
  crossCheck,
  source,
  dataMaps,
  isEmployeeSettled = true,
}: GetRowParams): CrossCheckRow => {
  const { requesterUser, requesterId, requesterReady } = getRequester({
    crossCheck,
    source,
    dataMaps,
    isEmployeeSettled,
  })
  const { name: entityName, link: entityLink } = getEntityInfo({
    dataMaps,
    entityType: crossCheck.entityType,
    id: crossCheck.entityId,
    source,
  })

  return {
    id: crossCheck.id,
    source,
    requesterUser,
    requesterId,
    requesterReady,
    entityName,
    entityLink,
    request: getRequest(crossCheck.executableName),
    crossCheck,
  }
}

export const getCrossCheckRows = (params: GetCrossCheckRowsParams) => {
  const { idave, sam, dart, dataMaps, isEmployeeSettled } = params
  const samCrossChecks: CrossCheckRow[] = sam.map((crossCheck) =>
    getCrossCheckRow({
      crossCheck,
      source: 'sam',
      dataMaps,
      isEmployeeSettled,
    }),
  )
  const idaveCrossChecks = idave.map((crossCheck) =>
    getCrossCheckRow({ crossCheck, source: 'idave', dataMaps }),
  )
  const dartCrossChecks = dart.map((crossCheck) =>
    getCrossCheckRow({ crossCheck, source: 'dart', dataMaps }),
  )

  return sortBy(
    samCrossChecks.concat(idaveCrossChecks).concat(dartCrossChecks),
    (item) => -item.crossCheck.updatedDate,
  )
}

const getIdaveRequester = ({ dataMaps, crossCheck }: GetRequesterParams) => {
  const user = dataMaps.userMap.get(crossCheck.requester)

  return {
    requesterUser: user,
    requesterReady: true,
    requesterId: crossCheck.requester,
  }
}

const getSamRequester = ({
  isEmployeeSettled,
  dataMaps,
  crossCheck,
}: GetRequesterParams) => {
  if (!isEmployeeSettled) {
    return {
      requesterUser: undefined,
      requesterReady: false,
      requesterId: crossCheck.requester,
    }
  }

  const employee = dataMaps.employeeMap.get(crossCheck.requester)
  if (!employee) {
    return {
      requesterUser: undefined,
      requesterReady: true,
      requesterId: crossCheck.requester,
    }
  }

  const idaveId = employee.iDaveUserId
  const user = idaveId ? dataMaps.userMap.get(idaveId) : undefined

  return {
    requesterUser: user,
    requesterReady: true,
    requesterId: crossCheck.requester,
  }
}

type GetRequesterParams = {
  crossCheck: CrossCheck
  source: CrossCheckSystem
  dataMaps: DataMaps
  isEmployeeSettled: boolean
}

const getRequester = (params: GetRequesterParams) => {
  return params.source === 'sam' ? getSamRequester(params) : getIdaveRequester(params)
}

const getFilterValues = (tab: CrossChecksTab, user?: UserListItem) => {
  if (!user) {
    return {}
  }

  const idave = user.id
  const peopleOps = user.externalRef?.reference

  if (!idave || !peopleOps) {
    return {}
  }

  const filterKey = tab === 'requests' ? 'requester' : 'reviewer'

  return {
    sam: { [filterKey]: peopleOps },
    idave: { [filterKey]: idave },
    dart: { [filterKey]: idave },
  }
}

export const getDefaultFilterParams = (
  tab: CrossChecksTab,
  currentUser?: UserListItem,
  filterUser?: UserListItem,
): FilterParams => {
  switch (tab) {
    case 'requests':
    case 'reviews':
      return getFilterValues(tab, currentUser)
    case 'all':
      return getFilterValues(tab, filterUser)

    default:
      return {}
  }
}

export const getEmptyTabParams = (tab: CrossChecksTab) => {
  switch (tab) {
    case 'requests':
      return {
        title: 'No request created',
        description: 'All created requests will appear here',
        imageCode: '3D299',
        imageVersion: 'v2' as const,
      }
    case 'reviews':
      return {
        title: 'No request assigned',
        description: 'All assigned requests will appear here',
        imageCode: '3D299',
        imageVersion: 'v2' as const,
      }
    case 'all':
    default:
      return {
        title: 'No request created',
        description: 'All created requests will appear here',
        imageCode: '3D299',
        imageVersion: 'v2' as const,
      }
  }
}

export const isRecertification = (executableName: CrossCheckExecutableName) =>
  executableName === 'ApproveRecertification' ||
  executableName === 'RecertificationApprove'
