import { UserListItem } from 'api/idave/user'
import { useQueryUserPeopleOpsIdMap } from 'queries/idave/users'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { mergeQueryStatuses } from 'utils/query'
import { CrossChecksTab } from 'view/CrossChecks/CrossCheckList/types'

const REVIEWER_FIELD_NAME = 'reviewer'

type ReviewerFilterNavigation = {
  tab: CrossChecksTab
  userFilter?: UserListItem
  setUserFilter: (user: UserListItem) => void
}

export const useReviewerFilterNavigation = (params: ReviewerFilterNavigation) => {
  const [init, setInit] = useState(false)

  useReviewerFilterNavigationInit(params, init, setInit)
  useReviewerFilterNavigationUpdate(params, init)
}

const useReviewerFilterNavigationInit = (
  params: ReviewerFilterNavigation,
  init: boolean,
  setInit: (init: boolean) => void,
) => {
  const { tab, setUserFilter } = params

  const { data: userMap, status: qs, fetchStatus: fs } = useQueryUserPeopleOpsIdMap()
  const [queryParams] = useSearchParams()

  useEffect(() => {
    if (tab !== 'all' || init || mergeQueryStatuses({ qs, fs }) !== 'success') {
      return
    }

    const reviewer = queryParams.get(REVIEWER_FIELD_NAME)
    const user = reviewer ? userMap?.get(reviewer) : undefined
    if (user) {
      setUserFilter(user)
    }
    setInit(true)
  }, [init, setInit, tab, setUserFilter, qs, fs, queryParams, userMap])
}

const useReviewerFilterNavigationUpdate = (
  params: ReviewerFilterNavigation,
  init: boolean,
) => {
  const { tab, userFilter } = params
  const [currentParams, setQueryParams] = useSearchParams()

  useEffect(() => {
    if (!init || tab !== 'all') {
      return
    }
    const userId = userFilter?.externalRef?.reference
    const currentUser = currentParams.get(REVIEWER_FIELD_NAME)

    if (userId === currentUser) {
      return
    }

    const newParams = new URLSearchParams(currentParams.toString())

    if (userId) {
      newParams.set(REVIEWER_FIELD_NAME, userId)
    } else {
      newParams.delete(REVIEWER_FIELD_NAME)
    }
    setQueryParams(newParams, { replace: true })
  }, [init, tab, currentParams, userFilter, setQueryParams])
}
