import { Link, LinkProps } from 'react-router-dom'

export const UnstyledLink = ({
  children,
  href,
  target,
}: {
  target?: LinkProps['target']
  href?: string
  children?: React.ReactNode
}) => {
  if (href && children) {
    return (
      <Link
        to={href}
        style={{ textDecoration: 'none', color: 'inherit' }}
        target={target}
      >
        {children}
      </Link>
    )
  }

  return children ? <>{children}</> : null
}
