import { Client } from 'api/idave/clients'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { Box, Subheader, Table } from '@revolut/ui-kit'
import { useMemo, useState } from 'react'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { getEmptyUrisLabel, getUrlRowsInfo } from './utils'
import { getColumns } from './columns'
import { makeGetChangeRowState } from '../../../../utils'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../ChangeSwitcher'
import { CrossCheckValueTab } from '../../../../types'

export const UrlsList = ({
  crossCheck,
  client,
}: {
  crossCheck: CrossCheckDetail
  client?: Client
}) => {
  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])
  const { counters, rows } = useMemo(
    () =>
      getUrlRowsInfo({
        crossCheck,
        client,
        tabs,
      }),
    [crossCheck, client, tabs],
  )
  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState<CrossCheckValueTab>(defaultTab)

  const data = rows[tab]
  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])

  if (crossCheck.executableName === 'PermissionDelete') {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Redirect URIs</Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            currentTab={tab}
            onChange={setTab}
            counters={counters}
            tabs={tabs}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        getRowState={getRowState}
        labelEmptyState={getEmptyUrisLabel(tab)}
      />
    </Box>
  )
}
