import { Box, Popup, Button } from '@revolut/ui-kit'
import { UserList } from 'components/UserList'
import { useCurrentUserIds } from 'hooks/useCurrentUserIds'
import { useEditPopup } from 'hooks/useEditPopup'

type AddNotificationPopupProps = {
  onChange: (ids: string[]) => void
  onSubmitClick: () => void
  defaultNotifees: string[]
  bubbleUpUsers: string[]
}
export const NotifeeSelectPopup = ({
  onChange,
  onSubmitClick,
  defaultNotifees,
  bubbleUpUsers,
}: AddNotificationPopupProps) => {
  const { scrollRef } = useEditPopup()
  const {
    data: { idave },
  } = useCurrentUserIds()

  return (
    <>
      <Box mt="-s-24">
        <UserList
          onChange={onChange}
          variant="checkbox"
          scrollRef={scrollRef}
          defaultValue={defaultNotifees}
          exclude={idave ? [idave] : undefined}
          bubbleUp={bubbleUpUsers}
          excludeInactive
          hoistYourself={false}
        />
      </Box>
      <Popup.Actions>
        <Button variant="primary" elevated onClick={onSubmitClick}>
          Select
        </Button>
      </Popup.Actions>
    </>
  )
}
