import { useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import { allKeys } from 'utils/array'
import { useDebouncedValue } from './useDebouncedValue'

export const SEARCH_TIMEOUT = 300

type UseSearchFilterParams<T> = {
  entities: T[]
  threshold?: number
  timeout?: number
}

export const useSearchFilter = <T>({
  entities,
  threshold = 0.15,
  timeout = SEARCH_TIMEOUT,
}: UseSearchFilterParams<T>) => {
  const [value, setValue] = useState('')
  const searchValue = useDebouncedValue(value, timeout)

  const options = useMemo(
    () =>
      // https://fusejs.io/api/options.html
      ({
        threshold,
        keys: allKeys(entities),
        ignoreLocation: true,
        includeScore: true,
      }),
    [entities, threshold],
  )

  const collection = useMemo(() => new Fuse(entities, options), [entities, options])
  const searched = useMemo(() => {
    return searchValue
      ? collection
          .search(searchValue)
          .sort((a, b) => (a.score || 0) - (b.score || 0))
          .map(({ item }) => item)
      : entities
  }, [searchValue, entities, collection])

  const searchState = useMemo(() => {
    if (value !== searchValue) {
      return 'pending' as const
    }

    if (!!entities.length && !searched.length) {
      return 'failed' as const
    }

    return 'ready' as const
  }, [value, searchValue, searched.length, entities.length])

  return {
    setSearchValue: setValue,
    searchValue: value,
    searched,
    searchState,
  }
}
