import { useLocation } from 'react-router'
import { useEffect } from 'react'
import { SideBoxType } from '../types'

type UseSideboxAutocloseParams = {
  sidebox?: SideBoxType
  closeSide: () => void
  ready: boolean
}

export const useSideBoxAutoclose = ({
  sidebox,
  closeSide,
  ready,
}: UseSideboxAutocloseParams) => {
  const currentPath = useLocation().pathname

  useEffect(() => {
    if (!ready) {
      return
    }
    /**
     * if location was changed after sidebox opening, it means that sidebox
     * isn't related to the current page, so we should close it
     */
    if (sidebox && sidebox.origin !== currentPath) {
      closeSide()
    }
  }, [ready, closeSide, sidebox, currentPath])
}
