import { TableFilters } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { UserAccessLogs } from 'api/idave/user'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { UAParser } from 'ua-parser-js'
import { PresenceMap, toPresenceMap } from 'utils/array/toPresenceMap'
import { formatDateTimePrecise, isDateBetweenDates } from 'utils/date'
import { AccessLogRow } from './types'

export const getLogItems = (
  logItems: UserAccessLogs[],
  clientMap: Map<string, Client>,
  filterMap: Map<string, TableFilters<AccessLogRow>[number]>,
  limits?: { from: number; to: number },
) => {
  const filtersPresence = getFiltersPresenceMap(filterMap)

  return logItems.reduce<AccessLogRow[]>((acc, log) => {
    const { clientId, id, userAgent, date, ip } = log

    const client = clientMap.get(clientId)?.name || clientId
    const { os, browser } = getUAInfo(userAgent)

    const logItem = {
      client,
      ip: ip || EMPTY_VALUE_FALLBACK,
      os,
      browser,
      date: formatDateTimePrecise(date),
      id,
      logItem: log,
    }

    if (
      isValidByFilters(logItem, filtersPresence) &&
      isDateBetweenDates(date, limits?.from, limits?.to)
    ) {
      return [...acc, logItem]
    }

    return acc
  }, [])
}

const INFO_FALLBACK = 'Unknown'
const getUAInfo = (userAgent: string) => {
  const ua = new UAParser(userAgent)
  const { version: browserVersion, name: browserName } = ua.getBrowser()
  const { version: osVersion, name: osName } = ua.getOS()

  const shortBrowserVersion = (browserVersion || '').split('.').slice(0, 1).join('')
  const shortOsVersion = (osVersion || '').split('.').slice(0, 1).join('')

  const browser = browserName
    ? [browserName, shortBrowserVersion].join(' ').trim()
    : INFO_FALLBACK
  const os = osName ? [osName, shortOsVersion].join(' ').trim() : INFO_FALLBACK

  return {
    browser,
    os,
  }
}

const getFiltersPresenceMap = (
  filterMap: Map<string, TableFilters<AccessLogRow>[number]>,
) => {
  const keys = Array.from(filterMap.keys())
  return keys.reduce<Record<string, PresenceMap>>((acc, key) => {
    const value = filterMap.get(key)?.value

    return value ? { ...acc, [key]: toPresenceMap(value) } : acc
  }, {})
}

type FilterColumns = keyof AccessLogRow
const isValidByFilters = (
  logItem: AccessLogRow,
  filterMap: Record<FilterColumns, PresenceMap>,
) => {
  const activeFilters = Object.keys(filterMap) as FilterColumns[]

  return activeFilters.every((key) => {
    const values = filterMap[key]
    const value = logItem[key]
    const isFilterable = typeof value === 'string'

    if (!isFilterable) {
      return true
    }

    return !values || (value && values[value])
  })
}
