import { EntitiesTable } from 'components/EntitiesTable'
import { TableLoadingState } from '@revolut/ui-kit'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getColumns } from './columns'
import { UserRestrictionAction } from './components/UserRestrictionAction'
import { UserRestriction } from '../../types'

type Props = {
  items: UserRestriction[]
  onDeleteClick: (id: string) => void
  loadingState: TableLoadingState
}
export const UserRestrictionList = ({ items, loadingState, onDeleteClick }: Props) => {
  const permissionUtils = usePermissions()
  const { searched, searchValue, setSearchValue } = useSearchFilter({ entities: items })
  const columns = useMemo(
    () => getColumns({ onDeleteClick, permissionUtils }),
    [onDeleteClick, permissionUtils],
  )
  const getEmployeeLink = useCallback(({ employeeId: userId }: UserRestriction) => {
    return generatePath(Url.UserProfile, { userId })
  }, [])

  return (
    <EntitiesTable
      enableNavigation
      totalCount={items.length}
      entitiesTypeLabel="Restrictions"
      pluralForms={['restriction', 'restrictions']}
      data={searched}
      columns={columns}
      searchValue={searchValue}
      searchAutoFocus
      renderActions={UserRestrictionAction}
      loadingState={loadingState}
      onSearchChange={setSearchValue}
      getRowLink={getEmployeeLink}
    />
  )
}
