import { IDAVE_PERMISSIONS } from 'security'
import { Url, UIRoute } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { Organisation } from './Organisation'
import { Organisations } from './Organisations'
import { OrganisationEdit } from './Organisation/components/OrganisationEdit'
import { OrganisationCreate } from './Organisation/components/OrganisationCreate'

export const routes = ({
  hasPermission,
  hasEveryPermission,
}: PermissionUtils): Record<string, UIRoute> => ({
  [Url.Organisations]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST),
    Component: Organisations,
    fallbackRoute: Url.Home,
  },

  [Url.Organisation]: {
    type: 'redirect',
    params: ['organisationId'],
    newUrl: Url.OrganisationDetails,
  },

  [Url.OrganisationDetails]: {
    type: 'component',
    withParams: true,
    params: ['organisationId'],
    allowed: hasPermission(IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST),
    fallbackRoute: Url.Organisations,
    Component: (props: { organisationId: string }) => (
      <Organisation tab="details" organisationId={props.organisationId} />
    ),
  },

  [Url.OrganisationAuditLog]: {
    type: 'component',
    withParams: true,
    params: ['organisationId'],
    allowed: hasEveryPermission(
      IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST,
      IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST,
    ),
    fallbackRoute: Url.Organisations,
    Component: (props: { organisationId: string }) => (
      <Organisation tab="auditLog" organisationId={props.organisationId} />
    ),
  },

  [Url.OrganisationEdit]: {
    type: 'component',
    withParams: true,
    params: ['organisationId'],
    allowed: hasPermission(IDAVE_PERMISSIONS.ORGANISATIONS_UPDATE),
    fallbackRoute: Url.Organisations,
    Component: (props: { organisationId: string }) => (
      <OrganisationEdit organisationId={props.organisationId} />
    ),
  },

  [Url.OrganisationCreate]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(IDAVE_PERMISSIONS.ORGANISATIONS_CREATE),
    fallbackRoute: Url.Organisations,
    Component: OrganisationCreate,
  },
})
