import { Permission } from 'api/idave/permissions'
import { PermissionInfo } from './components/PermissionInfo'
import { PermissionActions } from './components/PermissionActions'

type Props = {
  permission: Permission
}

export const PermissionDetails = ({ permission }: Props) => {
  return (
    <>
      <PermissionActions permission={permission} />
      <PermissionInfo permission={permission} />
    </>
  )
}
