import { useQueryRecertification } from 'queries/sam/recerts'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { DataWrapperProps, InnerProps } from './types'
import { PolicyDataWrapper } from './PolicyDataWrapper'

export const RecertificationDataWrapper = ({
  Component,
  crossCheck,
  id,
}: DataWrapperProps) => {
  if (!id) {
    const entity = {
      type: 'AccessPolicy' as const,
      value: undefined,
    }
    return <Component crossCheck={crossCheck} entity={entity} />
  }

  return <Inner id={id} crossCheck={crossCheck} Component={Component} />
}

const Inner = ({ Component, crossCheck, id }: InnerProps) => {
  const {
    data: recertification,
    status: recertQS,
    fetchStatus: recertFS,
  } = useQueryRecertification(id)

  return (
    <QuerySwitch
      required={[{ qs: recertQS, fs: recertFS }]}
      data={recertification}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view recertifications" />
      )}
      renderLoading={() => <DetailsGroupSkeleton />}
      renderSuccess={({ data }) => (
        <PolicyDataWrapper
          crossCheck={crossCheck}
          id={data.policyId}
          Component={Component}
        />
      )}
    />
  )
}
