import { useTokenize } from 'hooks/useTokenize'
import { TokensText } from 'components/TokensText'
import { Box, Subheader, TextWidget } from '@revolut/ui-kit'
import { Page } from 'components/HeaderActions/types'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { CONTENT_PAGES } from './utils'
import { INFO_BLOCK_CONTENT } from './infoContent'

export const Content = ({ page }: { page?: Page }) => {
  const { tokenize } = useTokenize()

  if (!page) {
    return <RequestErrorWidget title="Info page not found" />
  }

  const blocks = CONTENT_PAGES[page]

  return (
    <>
      {blocks.map((blockName) => {
        const { text, title } = INFO_BLOCK_CONTENT[blockName]
        return (
          <Box key={title}>
            <Subheader variant="nested">
              <Subheader.Title>{title}</Subheader.Title>
            </Subheader>
            <TextWidget>
              <TextWidget.Content>
                <TokensText tokens={tokenize(text)} />
              </TextWidget.Content>
            </TextWidget>
          </Box>
        )
      })}
    </>
  )
}
