import { User } from '@revolut-internal/idave-web-auth'
import { pickBy } from 'lodash'
import querystring from 'querystring'

export const USER_STORAGE_KEY = 'idave-user'

export const getUrlParams = () => querystring.parse(window.location.search.slice(1))

export const confirmSignIn = () => {
  const originUrl = window.location.origin
  const p = getUrlParams()

  const params = querystring.stringify(
    pickBy(
      {
        response_type: p.response_type,
        client_id: p.client_id,
        client_name: p.client_name,
        redirect_uri: p.redirect_uri,
      },
      (e) => e !== undefined,
    ),
  )

  window.location.href = `${originUrl}/api/auth?${params}`
}

export const isOnSignInPage = () => window.location.pathname === '/signin'

export const storeUser = (user: User) =>
  localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user))

export const getStoredUser = () => {
  const data = localStorage.getItem(USER_STORAGE_KEY)

  try {
    return data === null ? null : (JSON.parse(data) as User)
  } catch (_) {
    return null
  }
}

export const clearStoredUser = () => localStorage.removeItem(USER_STORAGE_KEY)
