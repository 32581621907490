import { VStack } from '@revolut/ui-kit'
import { SAMLog } from 'api/sam/auditLogs'
import { LogAuthor } from 'components/LogAuthor'
import { SamPolicyCreateSideLog } from './SamPolicyCreateSideLog'
import { SamPolicyResourceSetSideLog } from './SamPolicyResourceSetSideLog'
import { SamPolicyUpdateSideLog } from './SamPolicyUpdateSideLog'
import { SamLogDetails } from './SamLogDetails'
import { getLogAuthor } from './utils'

type Props = {
  log: SAMLog
}

export const SamPolicyLogSide = (props: Props) => {
  const { log } = props
  const author = getLogAuthor(log)

  return (
    <VStack space="s-16">
      {log.action === 'ACCESS_POLICY_CREATED' && <SamPolicyCreateSideLog log={log} />}
      {log.action === 'ACCESS_POLICY_UPDATED' && <SamPolicyUpdateSideLog log={log} />}
      {log.action === 'ACCESS_POLICY_RESOURCES_SET' && (
        <SamPolicyResourceSetSideLog log={log} />
      )}
      {!!author && (
        // sometimes author is username, sometimes email :(
        <LogAuthor userName={author} email={author} entity="user" />
      )}
      <SamLogDetails log={log} />
    </VStack>
  )
}
