import { UIRoute, Url } from 'routing'
import { Logout } from 'view/Logout'

export const routes = (): Record<string, UIRoute> => ({
  [Url.Logout]: {
    type: 'component',
    withParams: false,
    allowed: true,
    Component: Logout,
  },
})
