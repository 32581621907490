/**
 * Searches throught a entity array for the specific entity with the entityId
 * Accepts mathcehr function as the way to get an entitie's id
 */
export const findEntityById = <Entity extends Record<string, unknown>>(
  entities: Entity[],
  entityId: string,
  matcher?: (entity: Entity) => string,
) => {
  return entities.find((entity) => {
    return matcher ? entityId === matcher(entity) : entity.id === entityId
  })
}
