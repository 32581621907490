import { Avatar, Box, Group, IconName, Item, Subheader } from '@revolut/ui-kit'
import { Link } from 'react-router-dom'

type SectionItem = { title: string; icon: IconName; link: string }

export type SectionProps = { title: string; items: SectionItem[] }

export const Section = ({ title, items }: SectionProps) => {
  return (
    <Box>
      <Box pl="s-16">
        <Subheader variant="nested">
          <Subheader.Title>{title}</Subheader.Title>
        </Subheader>
      </Box>

      <Group>
        {items.map((item) => (
          <Item key={item.link} use={Link} to={item.link} variant="disclosure">
            <Item.Avatar>
              <Avatar size={40} useIcon={item.icon} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title role="cell">{item.title}</Item.Title>
            </Item.Content>
          </Item>
        ))}
      </Group>
    </Box>
  )
}
