import {
  ASCENDING_SORT,
  DESCENDING_SORT,
  FILTER_PREFIX,
  SORT_PREFIX,
  TABLE_SEARCH_PARAM_NAME,
} from '../useTableNavigation/const'
import { TableNavigationParams } from './types'

export const getNewTableParams = <Entity extends { id: string }>(
  searchParams: URLSearchParams,
  params: TableNavigationParams<Entity>,
) => {
  const { search = '', filters = [], sorts = [] } = params
  const resultParams = new URLSearchParams(searchParams.toString())
  if (search) {
    resultParams.set(TABLE_SEARCH_PARAM_NAME, search)
  } else {
    resultParams.delete(TABLE_SEARCH_PARAM_NAME)
  }

  const queryParams = Array.from(resultParams.entries())
  // remove filters+sorts related entires
  queryParams.forEach(([key]) => {
    if (key.startsWith(FILTER_PREFIX)) {
      resultParams.delete(key)
    }

    if (key.startsWith(SORT_PREFIX)) {
      resultParams.delete(key)
    }
  })

  // add new filter+sorts entries
  filters.forEach(({ id, value }) =>
    resultParams.set(`${FILTER_PREFIX}${id}`, value.join(',')),
  )

  sorts.forEach(({ id, desc }) =>
    resultParams.set(`${SORT_PREFIX}${id}`, desc ? DESCENDING_SORT : ASCENDING_SORT),
  )

  return resultParams
}
