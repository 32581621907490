import { Role } from 'api/idave/roles'
import { mapify } from 'utils/array'
import { getRoleByParentTable } from 'utils/role/getRoleByParentTable'
import { traverseRoleParents } from 'utils/role/traverseRoleParents'

export const getRoleRoot = (roleId: string, roleMap: Map<string, Role>) => {
  return traverseRoleParents({ roleId, roleMap })[0]
}

type GetInheritanceTreeParams = { roleId: string; roles: Role[] }
export const getInheritanceTree = ({ roles, roleId }: GetInheritanceTreeParams) => {
  const roleMap = mapify(roles, (r) => r.id)

  return {
    root: getRoleRoot(roleId, roleMap) || roleId,
    relations: getRoleByParentTable(roles),
  }
}
