import { usePermissions } from '@revolut-internal/idave-web-auth'
import { useQuery } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { makeMappedKeyDataHook } from 'queries/utils'
import { DART_PERMISSIONS } from 'security'

export const useQueryCustomerCompanies = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.CustomerCompanies],
    queryFn: dartApi.customerCompany.getCustomerCompanies,
    select: (response) => response.data,
    enabled: hasPermission(DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_LIST),
  })
}

export const useQueryCustomerCompanyMap = makeMappedKeyDataHook(
  useQueryCustomerCompanies,
  'id',
)

export const useQueryCustomerCompany = (id?: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.CustomerCompany, id],
    queryFn: () => {
      if (!id) {
        throw new Error('id is not set')
      }
      return dartApi.customerCompany.getCustomerCompany({ id })
    },
    select: (response) => response.data,
    enabled: hasPermission(DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_DETAILS),
  })
}

export const useQueryEmployeeCompanies = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.EmployeeCompanies],
    queryFn: dartApi.employeeCompany.getEmployeeCompanies,
    enabled: hasPermission(DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST),
    select: (response) => response.data,
  })
}
export const useQueryEmployeeCompanyMap = makeMappedKeyDataHook(
  useQueryEmployeeCompanies,
  'id',
)
