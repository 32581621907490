import { Box, DetailsCell, Group, Subheader } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckExecutableName } from 'api/types/crossCheck'
import { useMemo, useState } from 'react'
import { CrossCheckEntity, CrossCheckValueTab } from '../../types'
import { CrosscheckTabSwitcher, getSwitcherTabs } from '../ChangeSwitcher'
import { getDetailRows } from './utils/getDetailRows'
import { DetailRow } from './types'
import { DetailContent } from './components/DetailContent'
import { isFinished as getIsFinished } from '../../../CrossCheckRules/utils'
import { isRowHidden } from './utils/isRowHidden'
import { EmptyRequestedDetails } from './components/EmptyRequestedDetails'
import { getDefaultDetailsTab } from './utils/getDefaultDetailsTab'
import { showRequestPlaceholder } from './utils/showRequestPlaceholder'

export const Details = ({
  crossCheck,
  entity,
}: {
  crossCheck: CrossCheckDetail
  entity: CrossCheckEntity
}) => {
  const tabs = getSwitcherTabs(crossCheck, 'details')

  const rows = getDetailRows({
    crossCheck,
    entity,
  })

  const defaultTab = getDefaultDetailsTab(tabs, rows)
  const [tab, setTab] = useState<CrossCheckValueTab>(defaultTab)

  if (!rows.length) {
    return null
  }

  const isFinished = getIsFinished(crossCheck)
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Details</Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            tabs={tabs}
            onChange={setTab}
            currentTab={tab}
            isFinished={isFinished}
          />
        </Subheader.Side>
      </Subheader>
      <Inner
        rows={rows}
        tab={tab}
        isFinished={isFinished}
        executableName={crossCheck.executableName}
      />
    </Box>
  )
}

const Inner = (props: {
  rows: DetailRow[]
  tab: CrossCheckValueTab
  isFinished: boolean
  executableName: CrossCheckExecutableName
}) => {
  const { rows, tab, isFinished, executableName } = props

  const displayRows = useMemo(
    () =>
      rows.filter(
        (row) =>
          !isRowHidden({
            row,
            tab,
            isFinished,
            executableName,
          }),
      ),
    [rows, tab, isFinished, executableName],
  )

  if (showRequestPlaceholder({ rows, tab, isFinished, executableName })) {
    return <EmptyRequestedDetails />
  }

  return (
    <Group>
      {displayRows.map((row, index) => (
        <DetailsCell key={index}>
          <DetailsCell.Title>{row.title}</DetailsCell.Title>
          <DetailsCell.Content>
            <DetailContent row={row} tab={tab} isFinished={isFinished} />
          </DetailsCell.Content>
        </DetailsCell>
      ))}
    </Group>
  )
}
