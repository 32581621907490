type HeadersOptions = {
  crossCheck?: boolean
}

export const getHeaders = (params: HeadersOptions) => {
  return Object.keys(params).reduce((headers, key) => {
    switch (key) {
      case 'crossCheck':
        return params[key] ? { ...headers, 'X-Crosscheck-Enabled': true } : headers
      default:
        return headers
    }
  }, {})
}
