import { DART_PERMISSIONS, IDAVE_PERMISSIONS, SAM_PERMISSIONS } from 'security'
import { Url, UIRoute } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { Users } from './Users'
import { User } from './User'

export const routes = ({
  hasPermission,
  hasSomePermissions,
}: PermissionUtils): Record<string, UIRoute> => ({
  [Url.Users]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_LIST),
    Component: Users,
  },

  [Url.User]: {
    type: 'redirect',
    params: ['userId'],
    newUrl: Url.UserProfile,
  },

  [Url.UserProfile]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_DETAILS),
    fallbackRoute: Url.Users,
    params: ['userId'],
    Component: (props: { userId: string }) => (
      <User tab="profile" userId={props.userId} />
    ),
  },

  [Url.UserRoles]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_DETAILS),
    fallbackRoute: Url.Users,
    params: ['userId'],
    Component: (props: { userId: string }) => <User tab="roles" userId={props.userId} />,
  },

  [Url.UserPolicies]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(SAM_PERMISSIONS.USER_POLICIES_VIEW_DETAILS),
    fallbackRoute: Url.Users,
    params: ['userId'],
    Component: (props: { userId: string }) => (
      <User tab="policies" userId={props.userId} />
    ),
  },

  [Url.UserAccessLogs]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_LOGS_ACCESS_LIST),
    fallbackRoute: Url.Users,
    params: ['userId'],
    Component: (props: { userId: string }) => (
      <User tab="accessLog" userId={props.userId} />
    ),
  },

  [Url.UserAuditLogs]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    fallbackRoute: Url.Users,
    params: ['userId'],
    Component: (props: { userId: string }) => (
      <User tab="auditLog" userId={props.userId} />
    ),
  },

  [Url.UserPermissions]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_DETAILS),
    fallbackRoute: Url.Users,
    params: ['userId'],
    Component: (props: { userId: string }) => (
      <User tab="permissions" userId={props.userId} />
    ),
  },

  [Url.UserDataAccess]: {
    type: 'component',
    withParams: true,
    allowed: hasSomePermissions(
      DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS,
      DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS_LIMITED,
    ),
    fallbackRoute: Url.Users,
    params: ['userId'],
    Component: (props: { userId: string }) => (
      <User tab="dataAccess" userId={props.userId} />
    ),
  },

  [Url.UserDataLogs]: {
    type: 'component',
    withParams: true,
    params: ['userId'],
    allowed: hasPermission(DART_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    fallbackRoute: Url.Users,
    Component: (props: { userId: string }) => (
      <User tab="dataLogs" userId={props.userId} />
    ),
  },

  [Url.UserToxicAlerts]: {
    type: 'component',
    withParams: true,
    params: ['userId'],
    allowed: hasPermission(IDAVE_PERMISSIONS.TOXIC_PERMISSION_ALERTS_VIEW_LIST),
    fallbackRoute: Url.Users,
    Component: (props: { userId: string }) => (
      <User tab="toxicAlerts" userId={props.userId} />
    ),
  },
})
