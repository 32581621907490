import axios, { AxiosPromise } from 'axios'
import { AuditParams, AuditLogData as TAuditLogData } from 'api/types/auditLog'
import { ArrayParams, ObjectParam } from 'api/types/crossCheck'
import { DisposableAccessModifier, AccessModifier } from './types'

type AuditLogAccessModifierParam = ObjectParam<AccessModifier | DisposableAccessModifier>
export type AuditLogAccessModifiersParam = ArrayParams<ObjectParam<AccessModifier>>

type ModelEntity = 'DartEmployee' | 'DartEmployeeCompany'

type AuthorEntity = 'User'

export type AuditLogData = TAuditLogData<ModelEntity, AuthorEntity> & {
  'Access Modifier'?: AuditLogAccessModifierParam
  'Access Modifiers'?: AuditLogAccessModifiersParam
}

export const auditLog = {
  getLogs: (params?: AuditParams): AxiosPromise<AuditLogData[]> =>
    axios.get('/dart-api/audit-log', { params }),
}
