import { SamPolicy } from 'api/sam/policies'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { SamTraining } from 'api/sam/trainings'
import { TrainingRow } from './types'
import { isFinished as getIsFinished } from '../../../../../CrossCheckRules/utils'
import { CrossCheckValueTab } from '../../../../types'
import {
  getChangeRowsInfo,
  getStateTabRowsInfo,
  getStateValues,
  isStatePolicyChange,
} from '../../utils'
import { CREATE_NAMES } from '../../../ChangeSwitcher/utils'

type GetResourceRowsInfoParams = {
  crossCheck: CrossCheckDetail
  policy?: SamPolicy
  trainingMap: Map<string, SamTraining>
  tabs: CrossCheckValueTab[]
}
export const getTrainingRowsInfo = (params: GetResourceRowsInfoParams) => {
  const { tabs, crossCheck, trainingMap, policy } = params
  if (isStatePolicyChange(params)) {
    return getStateTrainingsRowsInfo(params)
  }

  const { executableParams } = crossCheck

  const current = policy?.mandatoryTrainings || []
  const values = CREATE_NAMES.includes(crossCheck.executableName)
    ? executableParams?.mandatory_trainings || []
    : []
  const added = executableParams?.mandatory_trainings_diff?.added || []
  const deleted = executableParams?.mandatory_trainings_diff?.removed || []

  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
    trainingMap,
  })
}

type MakeGetTrainingRowParams = {
  trainingMap: Map<string, SamTraining>
  deletedHash?: Record<string, boolean>
  addedHash?: Record<string, boolean>
}

const makeMapValue =
  ({ deletedHash = {}, addedHash = {}, trainingMap }: MakeGetTrainingRowParams) =>
  (id: string): TrainingRow => {
    const removedStatus = deletedHash[id] ? 'removed' : undefined
    const addedStatus = addedHash[id] ? 'added' : undefined

    return {
      id,
      name: trainingMap.get(id)?.name || id,
      code: trainingMap.get(id)?.code,
      status: addedStatus || removedStatus,
    }
  }

const getStateTrainingsRowsInfo = ({
  tabs,
  crossCheck,
  trainingMap,
  policy,
}: GetResourceRowsInfoParams) => {
  const { executableParams } = crossCheck
  const values = policy?.mandatoryTrainings || []
  const newValues = executableParams?.mandatory_trainings || []

  const { addedValues, deletedValues } = getStateValues({ newValues, values, crossCheck })

  const addedHash = toPresenceMap(addedValues)
  const deletedHash = toPresenceMap(deletedValues)

  const params = {
    values,
    newValues,
    trainingMap,
    addedHash,
    deletedHash,
    isFinished: getIsFinished(crossCheck),
  }

  return getStateTabRowsInfo({
    tabs,
    makeMapValue: makeStateMapValue,
    ...params,
  })
}

type MakeGetStateTrainingRowParams = {
  trainingMap: Map<string, SamTraining>
  deletedHash?: Record<string, boolean>
  addedHash?: Record<string, boolean>
  isFinished: boolean
}

const makeStateMapValue =
  ({
    deletedHash = {},
    addedHash = {},
    trainingMap,
    isFinished,
  }: MakeGetStateTrainingRowParams) =>
  (id: string): TrainingRow => {
    const removedStatus = deletedHash[id] ? 'removed' : undefined
    const addedStatus = addedHash[id] ? 'added' : undefined

    return {
      id,
      name: trainingMap.get(id)?.name || id,
      code: trainingMap.get(id)?.code,
      status: isFinished ? undefined : addedStatus || removedStatus,
    }
  }

export const NO_CHANGE_LABEL = "There aren't added or deleted trainings"
export const NO_ITEMS_LABEL = 'No trainings for the policy'
export const getEmptyTrainingsLabel = (status: CrossCheckValueTab) =>
  status === 'requested' ? NO_CHANGE_LABEL : NO_ITEMS_LABEL
