import axios, { AxiosPromise } from 'axios'

export enum SamResourceType {
  IdaveRole = 'idave_role',
  GoogleGroup = 'google_group',
  TeamCityGroup = 'teamcity_group',
  MetabaseGroup = 'metabase_group',
  LookerGroup = 'looker_group',
  TestFlight = 'testflight_beta_group',
  AppStoreUserRole = 'app_store_user_role',
  AppStoreApp = 'app_store_app',
  AppStoreUserProperty = 'app_store_user_property',
  OktaGroup = 'okta_group',
  DartAllowCustomerCompany = 'dart_allow_customer_company',
}

export type SamResource = {
  resourceId: string
  externalId: string
  resourceType: SamResourceType
  resourceName: string
  ownerGroupId?: string
}

export type SamResourcesResponse = {
  data: SamResource[]
}

export const resources = {
  getResources: (): AxiosPromise<SamResourcesResponse> =>
    axios.get('/sam-api/api/resources/'),

  getResource: (id: string): AxiosPromise<SamResource> =>
    axios.get(`/sam-api/api/resources/${id}`),

  setResourceOwner: (resourceId: string, ownerGroupId?: string): AxiosPromise<void> =>
    axios.post('/sam-api/api/resources/set-owners', {
      data: [
        {
          resourceId,
          ownerGroupId: ownerGroupId || null,
        },
      ],
    }),
}
