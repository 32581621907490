import { CrossCheckDetail } from 'api/types/crossCheck'
import { useQueryPermissions } from 'queries/idave/permissions'
import { QuerySwitch } from 'components/QuerySwitch'
import { notNullableMap } from 'utils/array'
import { useMemo } from 'react'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { Client } from 'api/idave/clients'
import { CrossCheckEntity } from '../../../types'
import { PermissionList } from './PermissionList'

type Props = {
  crossCheck: CrossCheckDetail
  entity: CrossCheckEntity
}
export const ClientDeletePermissions = ({ crossCheck, entity }: Props) => {
  if (
    crossCheck.executableName !== 'PermissionDelete' ||
    entity.type !== 'Client' ||
    !entity.value?.id
  ) {
    return null
  }

  return <Inner crossCheck={crossCheck} client={entity.value} />
}

const Inner = ({
  crossCheck,
  client,
}: {
  crossCheck: CrossCheckDetail
  client: Client
}) => {
  const {
    data: permissions,
    status: qs,
    fetchStatus: fs,
    refetch,
  } = useQueryPermissions()

  const permissionIds = useMemo(
    () =>
      notNullableMap(permissions || [], (permission) =>
        permission.client === client.id ? permission.id : undefined,
      ),
    [permissions, client.id],
  )

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view permissions" />
      )}
      renderLoading={() => <PermissionList crossCheck={crossCheck} entityType="Client" />}
      renderSuccess={() => (
        <PermissionList
          crossCheck={crossCheck}
          entityType="Client"
          permissionIds={permissionIds}
        />
      )}
    />
  )
}
