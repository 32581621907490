import { EmployeeType, SamPolicySubjectType } from 'api/sam/policies'
import { UseFormSetValue } from 'react-hook-form'
import { SpecialisationIdsSelect } from 'components/Selects/SpecialisationIdsSelect'
import { TeamsSelect } from 'components/Selects/TeamsSelect'
import { DepartmentsSelect } from 'components/Selects/DepartmentsSelect'
import { useSetValue } from 'hooks/useSetValue'
import { memo } from 'react'
import { EmployeeTypesSelect } from 'components/Selects/EmployeeTypesSelect'
import { SeniorityIdsSelect } from 'components/Selects/SeniorityIdsSelect'
import { SamPolicySubjectField, PolicyDetails } from '../types'
import { getLabel, isFieldOptional } from './utils'
import { UsersSelect } from './UsersSelect'

export const SamEditPolicySubjectField = memo(
  (props: {
    subjectType?: SamPolicySubjectType
    field: SamPolicySubjectField
    disabled: boolean
    setValue: UseFormSetValue<PolicyDetails>
    values: string[] | EmployeeType[] | undefined
    error: string | undefined
  }) => {
    const { subjectType, field, disabled, values, setValue } = props

    const handleEntityChange = useSetValue(
      setValue,
      `subject.${field}`,
      (newValue?: Array<{ id: string }> | null) => newValue?.map((i) => i.id),
    )

    const handleValueChange = useSetValue(
      setValue,
      `subject.${field}`,
      (newValue?: string[] | null) => newValue || undefined,
    )

    const isOptional = isFieldOptional(field, subjectType)
    const label = getLabel(field, isOptional)

    switch (field) {
      case 'employeeTypes':
        return (
          <EmployeeTypesSelect
            onChange={handleValueChange}
            label={label}
            clearable={isOptional}
            disabled={disabled}
            value={values}
            valueAsTags
          />
        )

      case 'seniorityIds':
        return (
          <SeniorityIdsSelect
            onChange={handleValueChange}
            label={label}
            clearable={isOptional}
            disabled={disabled}
            value={values}
          />
        )

      case 'specialisationIds':
        return (
          <SpecialisationIdsSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
          />
        )
      case 'teamIds':
        return (
          <TeamsSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
          />
        )
      case 'departmentIds':
        return (
          <DepartmentsSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
          />
        )
      case 'employeeIds':
        return (
          <UsersSelect
            disabled={disabled}
            itemIds={values || []}
            onChange={handleValueChange}
            label={label}
            clearable={isOptional}
          />
        )
      default:
        return null
    }
  },
)
