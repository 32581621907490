import axios, { AxiosPromise } from 'axios'
import { CrossCheckedPayload } from 'utils/types/crossChecked'
import { splitPayloadHeader } from 'utils/api/splitBodyHeaders'
import { ActionResponse } from 'api/types/actionResponse'

export type Client = {
  redirectUris: string[]
  name: string
  id: string
  ownerGroupId: string
  description?: string
  scope: string
  externalRef?: ClientReference
  source?: 'idave' | 'automation'
  state: 'ACTIVE' | 'DELETED'
  enableSAAuthentication?: boolean
}

export type ClientReference = {
  origin: ClientOrigin
  reference: string
}

export type ClientPayload = {
  id: string
  redirectUris: string[]
  name: string
  ownerGroupId: string
  description?: string
  enableSAAuthentication: boolean
}

type ClientOrigin = 'tower'

type CustomConfig = {
  redirectUris?: {
    original: string[]
    custom: string[]
  }
}

export type ClientCustomization = {
  id: string
  config: CustomConfig
}

export type NewClientPayload = Omit<ClientPayload, 'id'> & { scope: string }

const clients = {
  getClients: (): AxiosPromise<Client[]> => axios.get('/api/clients'),

  getClient: (id: string): AxiosPromise<Client> => axios.get(`/api/clients/${id}`),

  addClient: (
    params: CrossCheckedPayload<NewClientPayload>,
  ): AxiosPromise<ActionResponse | { id: string }> => {
    const { body, headers } = splitPayloadHeader(params)

    return axios.post('/api/client', body, { headers })
  },

  editClient: (
    params: CrossCheckedPayload<ClientPayload>,
  ): AxiosPromise<ActionResponse> => {
    const {
      body: { id, ...clientDetails },
      headers,
    } = splitPayloadHeader(params)

    return axios.put(`/api/client/${id}`, clientDetails, { headers })
  },

  removeClient: (
    params: CrossCheckedPayload<{ clientId: string }>,
  ): AxiosPromise<ActionResponse> => {
    const {
      body: { clientId },
      headers,
    } = splitPayloadHeader(params)

    return axios.delete(`/api/client/${clientId}`, { headers })
  },

  getClientsCustomization: (): AxiosPromise<ClientCustomization[]> =>
    axios.get('/api/customizations/clients'),

  getClientCustomization: (id: string): AxiosPromise<ClientCustomization> =>
    axios.get(`/api/customizations/clients/${id}`),

  resetClientCustomization: (id: string): AxiosPromise<ActionResponse> =>
    axios.delete(`/api/customizations/clients/${id}`),
}

export default clients
