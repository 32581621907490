import { Flex, Token, Icon, IconName } from '@revolut/ui-kit'
import { memo } from 'react'

export const SideAvatar = memo(
  (props: { iconName: IconName; onDoubleClick?: () => void }) => {
    const { iconName, onDoubleClick } = props

    return (
      <Flex
        color={Token.color.blue}
        borderRadius="50%"
        width="56px"
        height="56px"
        alignItems="center"
        justifyContent="center"
        bg={Token.color.actionBackground}
        onDoubleClick={onDoubleClick}
      >
        <Icon name={iconName} size={32} />
      </Flex>
    )
  },
)
