import { Role } from 'api/idave/roles'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryToxicPairs } from 'queries/idave/permissions'
import { useQueryRoleIdMap, useQueryRoles } from 'queries/idave/roles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { RoleEditFormState, roleToRoleState } from 'view/Roles/components/RoleEditForm'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { getMatchedToxicPairs } from 'view/Permissions/utils'
import { useStorageItem } from 'hooks/useStorageItem'
import { PermissionIdPair } from 'view/Permissions/types'
import { FormProvider, useForm } from 'react-hook-form'
import { debounce } from 'lodash'
import { useGroupOwnerId } from 'hooks/useGroupOwnerId'
import { matchToxicPairIdsByPermissionPairs } from 'utils/toxicPairs/matchToxicPairIdsByPermissionPairs'
import {
  getInheritedPermissions,
  getRolePayload,
  makeCheckTabFilled,
  makeCheckTabValid,
} from './utils'
import { useToxicPermissionSide } from '../components/ToxicPermissionSide'
import { RoleCreateForm } from './components/RoleCreateForm'
import { useCreateRole } from './useCreateRole'

export const RoleCreate = ({ roleId }: { roleId?: string }) => {
  const { data: roles = [], status, fetchStatus } = useQueryRoles()
  const originalRole = roleId ? roles.find((role) => role.id === roleId) : undefined

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={roles}
      renderLoading={() => <Inner loading />}
      renderSuccess={() => <Inner role={originalRole} />}
    />
  )
}

const Inner = (props: { loading?: boolean; role?: Role }) => {
  const { loading, role } = props
  const { open: openToxicSide, close: closeToxicSide } = useToxicPermissionSide()
  const { data: toxicPairs = [] } = useQueryToxicPairs()
  const navigate = useNavigate()

  const getGroupOwnerId = useGroupOwnerId()

  const onCloseClick = useCallback(() => {
    closeToxicSide()
    navigate(Url.Roles)
  }, [navigate, closeToxicSide])

  const { showErrorPopup } = useErrorPopup()

  const rolePermissionsStorageName = role ? undefined : 'roleCreatePermissions'
  const {
    clearItem: clearStoragePermissions,
    setItem: setStoragePermissions,
    item: storagePermissions,
  } = useStorageItem<{ value: string[] }>(rolePermissionsStorageName)

  const roleDetailsStorageName = role ? undefined : 'roleCreateDetails'
  const {
    clearItem: clearStorageDetails,
    setItem: setStorageDetails,
    item: storageDetails,
  } = useStorageItem<RoleEditFormState>(roleDetailsStorageName)

  const onSuccess = useCallback(() => {
    clearStorageDetails()
    clearStoragePermissions()
    closeToxicSide()
  }, [clearStorageDetails, clearStoragePermissions, closeToxicSide])

  const onErrorToxicPair = useCallback(
    (toxicPermissionPairs: PermissionIdPair[]) => {
      openToxicSide(matchToxicPairIdsByPermissionPairs(toxicPermissionPairs, toxicPairs))
      showErrorPopup({
        title: 'Toxic pair was detected.',
        message:
          "Please, review the permissions you're trying to add and remove ones that include toxic pair",
      })
    },
    [showErrorPopup, openToxicSide, toxicPairs],
  )

  const { mutate, isLoading: submitting } = useCreateRole({ onSuccess, onErrorToxicPair })

  const { data: toxicPermissions = [] } = useQueryToxicPairs()

  const [roleDetails, setRoleDetails] = useState<RoleEditFormState>({
    ...roleToRoleState(role),
    name: '',
    ...storageDetails,
  })

  const roleForm = useForm<RoleEditFormState>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: roleDetails,
  })

  const setDetails = useCallback(
    (details: Partial<RoleEditFormState>) => {
      setStorageDetails(details as RoleEditFormState)
      setRoleDetails(details as RoleEditFormState)
    },
    [setStorageDetails, setRoleDetails],
  )

  useEffect(() => {
    const subscription = roleForm.watch(debounce(setDetails, 300))
    return () => subscription.unsubscribe()
  }, [roleForm, setDetails])

  const [permissionIds, setPermissionIds] = useState<string[]>(
    role?.permissions || storagePermissions?.value || [],
  )
  const setPermissions = useCallback(
    (value: string[]) => {
      setPermissionIds(value)
      setStoragePermissions({ value })
    },
    [setPermissionIds, setStoragePermissions],
  )

  const onSubmitClick = useCallback(() => {
    const values = { ...roleDetails }
    const groupOwnerId = getGroupOwnerId(values.ownerGroupId)
    if (groupOwnerId === values.approverId) {
      values.approverId = undefined
    }
    mutate(getRolePayload(values, permissionIds))
  }, [roleDetails, getGroupOwnerId, mutate, permissionIds])

  const { data: roleMap = new Map() } = useQueryRoleIdMap()
  const allPermissions = useMemo(
    () =>
      getInheritedPermissions({
        parent: roleDetails.parent,
        roleMap,
      }).concat(permissionIds),
    [roleDetails.parent, roleMap, permissionIds],
  )

  const toxicItems = useMemo(
    () =>
      getMatchedToxicPairs({
        permissions: allPermissions,
        toxicPairs: toxicPermissions,
      }),
    [allPermissions, toxicPermissions],
  )

  const checkTabValid = useMemo(
    () =>
      makeCheckTabValid({
        loading,
        role,
        roleDetails,
        toxicPairsCount: toxicItems.length,
      }),
    [loading, role, roleDetails, toxicItems.length],
  )

  const checkTabFilled = useMemo(
    () =>
      makeCheckTabFilled({
        roleDetails,
        loading,
      }),
    [roleDetails, loading],
  )

  return (
    <FormProvider {...roleForm}>
      <RoleCreateForm
        checkTabFilled={checkTabFilled}
        checkTabValid={checkTabValid}
        onCloseClick={onCloseClick}
        onSubmitClick={onSubmitClick}
        setPermissionIds={setPermissions}
        toxicPairs={toxicPairs}
        roleDetails={roleDetails}
        permissionIds={permissionIds}
        isCopying={!!role}
        isLoading={loading}
        isSubmitting={submitting}
      />
    </FormProvider>
  )
}
