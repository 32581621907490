import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { Client } from 'api/idave/clients'
import { Role } from 'api/idave/roles'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { PermissionRow } from './types'

export const getAddingColumns = (
  clientMap: Map<string, Client>,
): TableColumn<PermissionRow>[] => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Application',
    accessor: (row) => clientMap.get(row.clientId)?.name || row.clientId,
    id: 'client',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]

export const getReadingColumns = (
  clientMap: Map<string, Client>,
  roleMap: Map<string, Role>,
): TableColumn<PermissionRow>[] => [
  ...getAddingColumns(clientMap),
  {
    Header: 'Inherited from',
    accessor: (value) =>
      value.ownerRoleId
        ? roleMap.get(value.ownerRoleId)?.name || value.ownerRoleId
        : EMPTY_VALUE_FALLBACK,
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
