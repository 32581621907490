import { capitalize, get } from 'lodash'
import { uniqBy } from 'lodash/fp'
import { DataMaps } from 'view/AuditLogs/types'
import { Box, BoxProps, Token } from '@revolut/ui-kit'
import { DetailRow } from 'components/DetailsGroup'
import { KeyDescriptor, DataItem } from './types'
import { KNOWN_KEYS } from './constants'
import { Entity } from './components/Entity'
import { trimUnknown } from '../../../../utils'

const uniqueByTitle = uniqBy<DataItem>(({ title }) => title)
export const keysToCells = (keys: KeyDescriptor[], values: Record<string, unknown>) =>
  keys.reduce<DataItem[]>((acc, { key, stringify, title, entity }) => {
    const result = get(values, key)
    const stringified: unknown = stringify ? stringify(result) : result
    const value = trimUnknown(stringified)

    if (!value) {
      return acc
    }

    return [
      ...acc,
      {
        key,
        title,
        value,
        entity,
      },
    ]
  }, [])

export const getDataItems = (dataString?: string) => {
  if (!dataString) {
    return []
  }

  try {
    const values = JSON.parse(dataString)
    if (typeof values !== 'object') {
      return []
    }

    const cells = keysToCells(KNOWN_KEYS, values)
    return uniqueByTitle(cells)
  } catch (_e) {
    return []
  }
}

export const dataItemsToCells = (dataMaps: DataMaps, logData?: string): DetailRow[] => {
  if (!logData) {
    return []
  }
  const items = getDataItems(logData)
  return items.map(({ entity, value, title, key }) => {
    return [
      title,
      entity ? (
        <Entity dataMaps={dataMaps} entity={{ id: value, entity }} />
      ) : (
        <Box {...getItemStyle({ key, value })}>{processText({ key, value })}</Box>
      ),
    ]
  })
}
const getStateColor = (state: string) => {
  switch (state) {
    case 'ACTIVE':
      return Token.color.green
    case 'INACTIVE':
      return Token.color.yellow
    case 'DELETED':
    case 'TERMINATED':
      return Token.color.red
    default:
      return undefined
  }
}

const getItemStyle = ({
  key,
  value,
}: {
  key: string
  value: string
}): Partial<BoxProps> => {
  if (key === 'state' || key === 'oldState' || key === 'newState') {
    return { color: getStateColor(value) }
  }

  return {}
}

const processText = ({ key, value }: { key: string; value: string }) => {
  switch (key) {
    case 'state':
    case 'oldState':
    case 'newState':
      return capitalize(value)
    default:
      return value
  }
}
