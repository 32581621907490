import { VStack } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { useQueryEmployeeDetails } from 'queries/dart/employees'
import { useQueryCustomerCompanyMap } from 'queries/dart/companies'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import {
  UserDataAccessRestrictions,
  UserDataAccessIgnored,
  UserDataAccessCompanies,
  UserDataAccessSkeleton,
  UserDataAccessRestrictionsLimited,
} from './components'

export const UserDataAccess = ({ userId }: { userId: string }) => {
  const { hasPermission } = usePermissions()

  const {
    data: employeeDetails,
    status: employeeDetailsS,
    fetchStatus: employeeDetailsFS,
  } = useQueryEmployeeDetails(userId)

  const {
    data: customerCompaniesMap = new Map(),
    status: customerCompaniesS,
    fetchStatus: customerCompaniesFS,
  } = useQueryCustomerCompanyMap()

  const isLimitedDetails =
    !hasPermission(DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS) &&
    hasPermission(DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS_LIMITED)

  return (
    <QuerySwitch
      data={employeeDetails}
      required={[{ qs: employeeDetailsS, fs: employeeDetailsFS }]}
      optional={[{ qs: customerCompaniesS, fs: customerCompaniesFS }]}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view this data" />
      )}
      renderLoading={() => <UserDataAccessSkeleton />}
      renderSuccess={() => (
        <VStack maxWidth={MAXIMAL_DETAILS_WIDTH} gap="s-24">
          <UserDataAccessIgnored employeeDetails={employeeDetails} />
          {isLimitedDetails ? (
            <UserDataAccessRestrictionsLimited employeeDetails={employeeDetails} />
          ) : (
            <UserDataAccessRestrictions employeeDetails={employeeDetails} />
          )}
          <UserDataAccessCompanies
            employeeDetails={employeeDetails}
            customerCompaniesMap={customerCompaniesMap}
          />
        </VStack>
      )}
    />
  )
}
