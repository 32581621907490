import { TableFilters } from '@revolut/ui-kit'
import { AuditLogData } from 'api/idave/auditsLog'
import { DataMaps } from 'view/AuditLogs/types'
import { getActionIcon, stringifyEntity } from 'view/AuditLogs/utils'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { isDateBetweenDates } from 'utils/date'
import { AuditLogRow } from './types'

export const formatLogs = (
  logItems: AuditLogData[],
  filterMap: Map<string, TableFilters<AuditLogRow>[number]>,
  dataMaps: DataMaps,
  limits?: { from: number; to: number },
) => {
  return logItems
    .map(mapLogValues(dataMaps))
    .filter(filterLogValue(filterMap, limits))
    .sort((a, b) => b.date - a.date)
}

export const filterLogValue =
  (
    filterMap: Map<string, TableFilters<AuditLogRow>[number]>,
    limits?: { from: number; to: number },
  ) =>
  (logRow: AuditLogRow) => {
    const events = filterMap.get('event')?.value || []
    const actions = filterMap.get('action')?.value || []
    const entities = filterMap.get('entity')?.value || []
    const author = filterMap.get('author')?.value || []

    return (
      (!events.length || events.includes(logRow.event)) &&
      (!actions.length || actions.includes(logRow.action)) &&
      (!entities.length || entities.includes(logRow.entity)) &&
      (!author.length || author.includes(logRow.author)) &&
      isDateBetweenDates(logRow.date, limits?.from, limits?.to)
    )
  }

const mapLogValues =
  (dataMaps: DataMaps) =>
  (item: AuditLogData): AuditLogRow => {
    return {
      logItem: item,
      id: item['Event Id'].value,
      date: item['Event Date'].value,
      event: stringifyLogCell(item.Event, dataMaps),
      action: stringifyLogCell(item.Action, dataMaps),
      entity: stringifyLogCell(item.Model, dataMaps),
      author: stringifyLogCell(item.Author, dataMaps),
      iconName: getActionIcon(item.Action.value),
    }
  }

export const stringifyLogCell = (
  item: AuditLogData[keyof AuditLogData],
  dataMaps: DataMaps,
) => {
  if (!item) {
    return EMPTY_VALUE_FALLBACK
  }
  switch (item?.type) {
    case 'string':
      return replaceClientApplication(item.value)
    case 'entity':
      return stringifyEntity({ value: item.value, dataMaps })
    case 'array':
      return item.value
        .map((entity) => stringifyEntity({ value: entity.value, dataMaps }))
        .join(', ')
    case 'timestamp':
    default:
      return String(item.value)
  }
}
export const getRowSubtitle = (row: AuditLogRow) => {
  if (row.entity === EMPTY_VALUE_FALLBACK || row.entity === row.logItem.Model.value.id) {
    return undefined
  }
  return row.entity
}

export const replaceClientApplication = (value: string) => {
  switch (value) {
    case 'Client':
    case 'Client Created':
    case 'Client Delete':
    case 'Client Create':
    case 'Client Update':
    case 'Client Display Name Changed':
    case 'Client Scope Name Changed':
      return value.replace('Client', 'Application')
    default:
      return value.replace(/^client:/, 'application:')
  }
}
