import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'

export const useResetAccountAction = () => {
  const { closePopup } = useConfirmationPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  return useMutation({
    mutationFn: (accountId: string) =>
      idaveApi.accounts.resetAccountCustomization(accountId),
    onMutate: () => showLoadingPopup({ title: 'Reseting...' }),
    onSuccess: (_data, accountId) => {
      hideLoadingPopup()
      closePopup()
      queryClient.resetQueries({
        queryKey: [QueryKey.Accounts],
        type: 'all',
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Account, accountId],
        refetchType: 'all',
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.AccountCustomization, accountId],
        refetchType: 'all',
      })
      showSuccessToast('Account reset')
    },
    onError: (err) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({
        title: 'Account reset failed',
        error: err,
      })
    },
  })
}
