import { get3DImageSrcSetProps } from 'utils/url'
import { ActionButton, StatusWidget, TextSkeleton } from '@revolut/ui-kit'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { useCallback } from 'react'
import {
  ErrorSideboxOpenParams,
  LoadingSideboxOpenParams,
  SideBoxType,
  SideboxOpenParams,
} from '../types'
import { SideAvatar } from '../SideAvatar'

export const useOpenSide = ({
  setSidebox,
  locationPath,
}: {
  setSidebox: (sidebox: SideBoxType | undefined) => void
  locationPath: string
}) =>
  useCallback(
    (values: SideboxOpenParams) => {
      setSidebox({
        ...values,
        origin: locationPath,
      })
    },
    [setSidebox, locationPath],
  )

export const useOpenLoadingSide = ({
  setSidebox,
  locationPath,
}: {
  setSidebox: (sidebox: SideBoxType | undefined) => void
  locationPath: string
}) =>
  useCallback(
    ({
      avatar,
      title,
      onClose,
      queryParams,

      withActions,
      withAvatar,
      withDescription,
      withSubtitle,
    }: LoadingSideboxOpenParams) => {
      setSidebox({
        title: title || <TextSkeleton size={12} />,
        subtitle: withSubtitle ? <TextSkeleton size={8} /> : undefined,
        description: withDescription ? <TextSkeleton size={6} /> : undefined,
        avatar: avatar || (withAvatar ? <SideAvatar iconName="Loading" /> : undefined),
        actions: withActions ? <ActionButton width="s-12" pending /> : undefined,
        queryParams,
        onClose,
        body: <DetailsGroupSkeleton />,
        origin: locationPath,
      })
    },
    [setSidebox, locationPath],
  )

export const useOpenErrorSide = ({
  setSidebox,
  locationPath,
}: {
  setSidebox: (sidebox: SideBoxType | undefined) => void
  locationPath: string
}) =>
  useCallback(
    ({
      title = 'Something went wrong',
      subtitle,
      description,
      avatarIcon,
      queryParams,
      onClose,
      statusActionText,
      onStatusActionClick,
      statusTitle = 'An error happened',
      statusDescription,
      statusImageProps = get3DImageSrcSetProps('3D358', 'v2'),
    }: ErrorSideboxOpenParams) => {
      setSidebox({
        title,
        subtitle,
        description,
        avatar: avatarIcon ? <SideAvatar iconName={avatarIcon} /> : undefined,
        queryParams,
        onClose,
        body: (
          <StatusWidget>
            <StatusWidget.Image {...statusImageProps} />
            <StatusWidget.Title>{statusTitle}</StatusWidget.Title>
            {statusDescription && (
              <StatusWidget.Description>{statusDescription}</StatusWidget.Description>
            )}
            {onStatusActionClick && statusActionText ? (
              <StatusWidget.Action onClick={onStatusActionClick}>
                {statusActionText}
              </StatusWidget.Action>
            ) : undefined}
          </StatusWidget>
        ),
        origin: locationPath,
      })
    },
    [setSidebox, locationPath],
  )
