import { Text, TextSkeleton, Token, chain } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { capitalizeFirst } from 'utils/string'

export const ClientHeaderName = (props: { client?: Client }) => {
  const { client } = props
  if (!client) {
    return <TextSkeleton size={12} />
  }
  return (
    <>
      {chain(
        client.name,
        client.state !== 'ACTIVE' ? (
          <Text color={Token.color.red}>{capitalizeFirst(client.state)}</Text>
        ) : undefined,
      )}
    </>
  )
}
