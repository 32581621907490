import { SamPolicy, SamPolicySubjectType } from 'api/sam/policies'
import { assertNonReachable } from 'utils/error'
import {
  EMPLOYEE_INPUTS,
  EMPLOYEE_TYPE_INPUTS,
  SPEC_INPUTS,
  TEAM_INPUTS,
  DEPARTMENT_INPUTS,
  DEFAULT_SUBJECT_EMPLOYEE,
  DEFAULT_SUBJECT_EMPLOYEE_TYPE,
  DEFAULT_SUBJECT_SPEC,
  DEFAULT_SUBJECT_TEAM,
  DEFAULT_SUBJECT_DEPARTMENT,
} from 'view/Sam/components/SamEditPolicyDetails/consts'
import { SamPolicySubjectField } from 'view/Sam/components/SamEditPolicyDetails/types'

export const getPolicyNameRules = (
  mappedNames: Map<string, SamPolicy>,
  currentEditingId?: string,
) => ({
  validate(value: string | null | undefined) {
    if (!value) {
      return "Policy name can't be empty"
    }

    return getNameMatchingRule(mappedNames, value, currentEditingId)
  },
})

export const getNameMatchingRule = (
  mappedNames: Map<string, SamPolicy>,
  value: string,
  currentEditingId?: string,
) => {
  const sameNamePolicy = mappedNames.get(value)

  if (sameNamePolicy && sameNamePolicy.policyId !== currentEditingId) {
    return `Policy with this name already exists (${sameNamePolicy.policyId})`
  }

  return undefined
}

export const getFieldRules = (
  field: SamPolicySubjectField,
  subjectType?: SamPolicySubjectType,
) => {
  switch (subjectType) {
    case SamPolicySubjectType.Employee:
      return getEmployeeRules(field)
    default:
      return undefined
  }
}
const getEmployeeRules = (filed: SamPolicySubjectField) => {
  switch (filed) {
    case 'employeeIds':
      return {
        validate: (value: string[] | null | undefined) => {
          if (!value?.length) {
            return "Employee id can't be empty"
          }

          return undefined
        },
      }
    default:
      return undefined
  }
}

export const getPolicySubjectFields = (subjectType?: SamPolicySubjectType) => {
  if (!subjectType) {
    return []
  }

  switch (subjectType) {
    case SamPolicySubjectType.Specialisation:
      return SPEC_INPUTS

    case SamPolicySubjectType.Team:
      return TEAM_INPUTS

    case SamPolicySubjectType.Department:
      return DEPARTMENT_INPUTS

    case SamPolicySubjectType.EmployeeType:
      return EMPLOYEE_TYPE_INPUTS

    case SamPolicySubjectType.Employee:
      return EMPLOYEE_INPUTS

    default:
      return assertNonReachable(subjectType)
  }
}

export const getDefaultSubject = (subjectType?: SamPolicySubjectType) => {
  switch (subjectType) {
    case SamPolicySubjectType.Employee:
      return DEFAULT_SUBJECT_EMPLOYEE
    case SamPolicySubjectType.Specialisation:
      return DEFAULT_SUBJECT_SPEC
    case SamPolicySubjectType.Team:
      return DEFAULT_SUBJECT_TEAM
    case SamPolicySubjectType.Department:
      return DEFAULT_SUBJECT_DEPARTMENT
    default:
    case SamPolicySubjectType.EmployeeType:
      return DEFAULT_SUBJECT_EMPLOYEE_TYPE
  }
}
