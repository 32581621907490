import { pluralForm } from 'utils/string'

export const getToastText = (changedCount: number, type: 'add' | 'delete') => {
  if (type === 'add') {
    return pluralForm(changedCount, [
      'Training successfully added',
      'Trainings successfully added',
    ])
  }

  return pluralForm(changedCount, [
    'Training successfully deleted',
    'Trainings successfully deleted',
  ])
}

export const getPopupText = (changedCount: number) => ({
  message: pluralForm(changedCount, [
    'Are you sure you want to delete selected training?',
    'Are you sure you want to delete selected trainings?',
  ]),
  title: pluralForm(changedCount, [
    'Training deletion confirmation',
    'Trainings deletion confirmation',
  ]),
  confirmButtonText: 'Delete',
})
