import {
  CrossCheckDetail,
  Entity,
  EntityParam,
  REMOVED_MARK,
  RemovedParent,
} from 'api/types/crossCheck'
import { ChangeType, DetailRow } from '../../types'
import { DetailParams } from './types'
import { getForecasted } from './utils'
import { CREATE_NAMES } from '../../../ChangeSwitcher/utils'

export const getParentRole = ({
  entity,
  crossCheck,
}: DetailParams): DetailRow | undefined => {
  if (crossCheck.entityType !== 'Role') {
    return undefined
  }
  const current = entity.type === 'Role' ? entity.value?.parent : undefined

  const crossCheckParent = crossCheck.executableParams.Parent

  const requested = isParentRemoved(crossCheckParent)
    ? undefined
    : crossCheckParent?.value.id

  const changeType = getChangeType({
    current,
    requested,
    crossCheck,
  })

  return {
    title: 'Parent',
    value: {
      current,
      requested,
      forecasted: getForecasted({ changeType, current, requested }),
      type: 'Role',
    },
    changeType: getChangeType({ requested, current, crossCheck }),
  }
}

const getChangeType = (params: {
  requested?: string
  current?: string
  crossCheck: CrossCheckDetail
}): ChangeType => {
  const { requested, crossCheck, current } = params
  if (CREATE_NAMES.includes(crossCheck.executableName)) {
    return 'EntityCreation'
  }

  if (requested === current) {
    return 'NoChange'
  }

  if (!requested) {
    const crossCheckParent = crossCheck.executableParams.Parent
    return isParentRemoved(crossCheckParent) ? 'Deleted' : 'NoChange'
  }

  return !current ? 'Added' : 'Edited'
}

const isParentRemoved = <T extends Entity>(
  value?: EntityParam<T> | RemovedParent,
): value is RemovedParent => {
  return value?.type === 'string' && value.value === REMOVED_MARK
}
