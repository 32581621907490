import { Route, Routes } from 'react-router-dom'
import { Page } from '@revolut/ui-kit'
import { ConfirmationPopup } from 'components/ConfirmationPopup'
import { EditPopup } from 'components/EditPopup'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  RouteElement,
  ErrorElement,
  getLegacyRedirections,
  UIRoute,
  failoverRoutes,
} from 'routing'
import { useMemo } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import { EditPopupProvider } from 'hooks/useEditPopup'
import { ConfirmationPopupContextProvider } from 'hooks/useConfirmationPopup'
import { SideBox } from './SideBox'
import { SideBoxProvider } from './SideBox/SideBox'
import { routes as dartRoutes } from './Dart/routes'
import { routes as usersRoutes } from './Users/routes'
import { routes as auditLogsRoutes } from './AuditLogs/routes'
import { routes as clientRoutes } from './Clients/routes'
import { routes as orgRoutes } from './Organisations/routes'
import { routes as rolesRoutes } from './Roles/routes'
import { routes as crossCheckRoutes } from './CrossChecks/routes'
import { routes as permissionsRoutes } from './Permissions/routes'
import { routes as samRoutes } from './Sam/routes'
import { routes as systemRoutes } from './System/routes'
import { routes as logoutRoutes } from './Logout/routes'
import { routes as homeRoutes } from './Home/routes'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

const UILayoutProviders = (props: { children: React.ReactNode }) => {
  return (
    <EditPopupProvider>
      <ConfirmationPopupContextProvider>
        <SideBoxProvider>{props.children}</SideBoxProvider>
      </ConfirmationPopupContextProvider>
    </EditPopupProvider>
  )
}

export const UILayout = () => {
  const permissionHandlers = usePermissions()

  const layoutRoutes = useMemo<Record<string, UIRoute>>(
    () => ({
      ...getLegacyRedirections(),
      ...dartRoutes(permissionHandlers),
      ...usersRoutes(permissionHandlers),
      ...auditLogsRoutes(permissionHandlers),
      ...clientRoutes(permissionHandlers),
      ...orgRoutes(permissionHandlers),
      ...rolesRoutes(permissionHandlers),
      ...crossCheckRoutes(permissionHandlers),
      ...permissionsRoutes(permissionHandlers),
      ...samRoutes(permissionHandlers),
      ...systemRoutes(permissionHandlers),
      ...logoutRoutes(),
      ...homeRoutes(),
      ...failoverRoutes(),
    }),
    // Regenerate pathes only if permissions changed
    [permissionHandlers],
  )

  return (
    <UILayoutProviders>
      <Page>
        <SentryRoutes>
          {Object.keys(layoutRoutes).map((routePath) => (
            <Route
              path={routePath}
              key={routePath}
              element={
                <RouteElement route={layoutRoutes[routePath]} routePath={routePath} />
              }
              errorElement={<ErrorElement />}
            />
          ))}
        </SentryRoutes>
        <Page.Side behaviour="push">
          <SideBox />
        </Page.Side>
        <EditPopup />
        <ConfirmationPopup />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </Page>
    </UILayoutProviders>
  )
}
