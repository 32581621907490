import { useInfiniteQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import useLazyQuery from 'queries/useLazyQuery'
import { getLogsNextPageParam } from '../utils'

export type UseAuditLogsParams = {
  limits?: {
    from: number
    to: number
  }
  to?: number
  modelId?: string
}
export const useQueryAuditLogs = (params?: UseAuditLogsParams) => {
  const { hasPermission } = usePermissions()
  return useInfiniteQuery(
    [QueryKey.AuditLogs, params?.modelId, params?.limits?.from, params?.limits?.to],
    ({ pageParam }) => {
      const to = pageParam?.to || params?.limits?.to || undefined
      const modelId = params?.modelId

      return idaveApi.auditLog.getLogs({ modelId, to })
    },
    {
      enabled: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
      getNextPageParam: getLogsNextPageParam(params?.limits?.from),
    },
  )
}

export type UseAuditLogParams = {
  eventId?: string
}

export const useQueryAuditLog = (params: UseAuditLogParams) => {
  const { hasPermission } = usePermissions()
  return useLazyQuery({
    queryKey: [QueryKey.AuditLog, params.eventId],
    params,
    isParamsValid: (v) => !!v?.eventId,
    fetchFn: () => {
      return idaveApi.auditLog.getLogs(params)
    },
    enabled: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
  })
}

export const useQueryUserAccessLogs = (
  id: string,
  limits?: {
    from: number
    to: number
  },
) =>
  useInfiniteQuery(
    [QueryKey.UserAccessLogs, id, limits?.from, limits?.to],
    ({ pageParam }) => {
      const to = pageParam?.to || limits?.to || undefined
      return idaveApi.user.getUserAccessLogs({
        userId: id,
        to,
      })
    },
    {
      getNextPageParam: getLogsNextPageParam(limits?.from),
    },
  )
