import { DetailFieldsSkeleton } from 'view/Clients/Account/components/DetailFields'
import { Page } from '@revolut/ui-kit'
import { Header } from './Header'

export const Skeleton = ({ accountId }: { accountId: string }) => {
  return (
    <>
      <Header accountId={accountId} />
      <Page.Main>
        <DetailFieldsSkeleton />
      </Page.Main>
    </>
  )
}
