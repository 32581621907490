import { Avatar, Box, Flex, HStack, TextSkeleton, Text } from '@revolut/ui-kit'
import { InternalLink } from 'components/Links/InternalLink'
import { QuerySwitch } from 'components/QuerySwitch'
import { InternalLink as InternalLinkToken } from 'hooks/useTokenize/types'
import { useCallback } from 'react'
import { LinkExternal } from '@revolut/icons'
import { useEntityName, useImage } from './hooks'
import { getIcon } from './utils'

type Props = {
  token: InternalLinkToken
}
export const EntityTokenLink = ({ token }: Props) => {
  const { data: name, status: qs, fetchStatus: fs } = useEntityName(token.page)

  const image = useImage(token.page)
  const icon = getIcon(token.page.type)
  const RenderName = useCallback(() => <>{token.text || name}</>, [name, token.text])
  const RenderErroredName = useCallback(
    () => (
      <HStack align="center" space="s-8" width="fit-content">
        <Text>{token.value}</Text>
        <LinkExternal size={16} />
      </HStack>
    ),
    [token.value],
  )

  return (
    <InternalLink href={token.value} target="_blank">
      <Flex gap="s-4" flexDirection="row" display="inline-flex" alignItems="baseline">
        <Box alignSelf="center">
          <Avatar size={20} useIcon={image ? undefined : icon} image={image} />
        </Box>
        <QuerySwitch
          data={name}
          required={[{ qs, fs }]}
          renderError={RenderErroredName}
          renderIdle={RenderErroredName}
          renderLoading={() => <TextSkeleton size={12} />}
          renderSuccess={RenderName}
        />
      </Flex>
    </InternalLink>
  )
}
