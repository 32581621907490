import { notNullable } from 'utils/common'
import { DetailRow } from '../../types'
import { DetailParams } from './types'
import { getEntity } from './getEntity'
import { getName } from './getName'
import { getOwnerGroup } from './getOwnerGroup'
import { getParentRole } from './getParentRole'
import { getPolicyInfo } from './getPolicyInfo'
import { getDescription } from './getDescription'
import { getUserInfo } from './getUserInfo'
import { getApprovers } from './getApprovers'
import { getCustomerCompanyInfo } from './getCustomerCompanyInfo'
import { getAccountInfo } from './getAccountInfo'
import { getClientInfo } from './getClientInfo'

export const getDetailRows = (params: DetailParams): DetailRow[] => {
  return [
    getEntity(params),
    getName(params),
    getOwnerGroup(params),
    getApprovers(params),
    getParentRole(params),
    getDescription(params),
    getCustomerCompanyInfo(params),
    ...getPolicyInfo(params),
    ...getClientInfo(params),
    ...getUserInfo(params),
    ...getAccountInfo(params),
  ].filter(notNullable)
}
