import { Table, TableColumn, Text, TextSkeleton } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { Policy } from './types'

export const getColumns = (isSettled: boolean): TableColumn<Policy>[] => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Subject',
      accessor: 'subject',
      filter: 'includesValue' as const,
      Filter: SelectItemFilter,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (params: { row: { original: Policy } }) =>
        isSettled ? (
          <Table.StatusCell level={params.row.original.statusLevel}>
            <Text fontSize="14" fontWeight="500">
              {params.row.original.status}
            </Text>
          </Table.StatusCell>
        ) : (
          <TextSkeleton size={16} />
        ),
    },
  ]
}
