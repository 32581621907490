import { VStack } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { UserAccessLogs } from 'api/idave/user'
import { UserAccessLogSideDetails } from './components/UserAccessLogSideDetails'
import { UserAccessLogSideUA } from './components/UserAccessLogSideUA'
import { UserAccessLogSideLogInfo } from './components/UserAccessLogSideLogInfo'

type UserAccessLogSideProps = {
  log: UserAccessLogs
  clientMap: Map<string, Client>
}

export const UserAccessLogSide = (props: UserAccessLogSideProps) => {
  const { log, clientMap } = props

  return (
    <VStack space="s-16">
      <UserAccessLogSideDetails log={log} clientMap={clientMap} />
      <UserAccessLogSideUA userAgent={log.userAgent} />
      <UserAccessLogSideLogInfo log={log} />
    </VStack>
  )
}
