import { useCallback, useEffect, useState } from 'react'
import { SideAvatar } from 'view/SideBox'
import { useSideBox } from 'view/SideBox/SideBox'
import { GROUP_SIDE } from 'view/SideBox/hooks/useSideboxNavigation'
import { GroupSubtitle } from './components/GroupSubtitle'
import { Actions } from './components/Actions'
import { Body } from './components/Body'
import { GroupTitle } from './components/GroupTitle'

export const useGroupPreview = () => {
  const [groupId, setGroupId] = useState<string | undefined>()
  const { openSide } = useSideBox()
  const onClose = useCallback(() => setGroupId(undefined), [setGroupId])

  useEffect(() => {
    if (!groupId) {
      return undefined
    }
    const queryParams = {
      type: GROUP_SIDE,
      id: groupId,
    }

    return openSide({
      title: <GroupTitle groupId={groupId} />,
      subtitle: <GroupSubtitle groupId={groupId} />,
      avatar: <SideAvatar iconName="People" />,
      actions: <Actions groupId={groupId} />,
      body: <Body groupId={groupId} />,
      queryParams,
      onClose,
    })
  }, [openSide, onClose, groupId])

  return {
    openGroupSide: setGroupId,
  }
}
