import { VFC } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input, VStack } from '@revolut/ui-kit'
import { Credentials } from 'api/idave/auth'

type CredentialsFormProps = {
  isPending: boolean
  onSubmit: (credentials: Credentials) => void
}

export const CredentialsForm: VFC<CredentialsFormProps> = ({ isPending, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Credentials>()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack space="s-8">
        <Input
          {...register('username', {
            required: { value: true, message: 'Username cannot be empty' },
          })}
          disabled={isPending}
          label="Username"
          aria-invalid={!!errors.username?.message}
          errorMessage={errors.username?.message}
        />
        <Input
          {...register('password', {
            required: { value: true, message: 'Password cannot be empty' },
          })}
          disabled={isPending}
          type="password"
          label="Password"
          aria-invalid={!!errors.password?.message}
          errorMessage={errors.password?.message}
        />
        <Button type="submit" pending={isPending} mt="s-24">
          Sign In
        </Button>
      </VStack>
    </form>
  )
}
