import { BottomSheet, Button, TextArea } from '@revolut/ui-kit'
import { useEditPopup } from 'hooks/useEditPopup'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { CrossCheckSystem } from 'api/types/crossCheck'
import { useCallback, useState } from 'react'
import { DeclineParams } from './types'

type Props = {
  onSubmit: (params: DeclineParams) => void
  source: CrossCheckSystem
  id: string
}

export const DeclinePopup = (props: Props) => {
  const { onSubmit, source, id } = props
  const { closePopup } = useEditPopup()
  const [reason, setReason] = useState<string>('')
  const changeHandler = useInputStringChange(setReason)
  const submit = useCallback(
    () => onSubmit({ id, source, reason }),
    [onSubmit, source, id, reason],
  )

  return (
    <>
      <TextArea label="Justification" rows={3} onChange={changeHandler} value={reason} />

      <BottomSheet.Actions horizontal>
        <Button elevated onClick={closePopup} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={submit} variant="primary" disabled={!reason}>
          Decline
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
