import { Avatar, Text, HStack } from '@revolut/ui-kit'
import { getRegionFlagImageUrl } from 'utils/url/getRegionFlagImageUrl'

export const RegionText = ({
  id,
  title,
  region,
}: {
  id?: string
  title: string
  region?: string
}) => (
  <HStack space="s-8" align="center">
    {region && (
      <Avatar
        size={24}
        uuid={id}
        label={region}
        title={title || region}
        image={region ? getRegionFlagImageUrl(region) : undefined}
      />
    )}
    <Text>{title}</Text>
  </HStack>
)
