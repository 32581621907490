import { ExclamationMark } from '@revolut/icons'
import { Flex, Token } from '@revolut/ui-kit'

export const ToxicPermissionSideAvatar = () => (
  <Flex
    color={Token.color.red}
    borderRadius="50%"
    width="56px"
    height="56px"
    alignItems="center"
    justifyContent="center"
    bg={Token.color.red_10}
  >
    <ExclamationMark size={32} />
  </Flex>
)
