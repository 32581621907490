import { SamPolicySubject, SamPolicySubjectType } from 'api/sam/policies'
import { RevolutersTeamLink } from 'components/Links/RevolutersTeamLink'
import { RevolutersSeniority } from 'components/Links/RevolutersSeniority'
import { RevolutersSpecialisationLink } from 'components/Links/RevolutersSpecialisationLink'
import { capitalizeFirst } from 'utils/string/capitalizeFirst'
import { DetailRow, vstackItems } from 'components/DetailsGroup'
import { RevolutersEmployeeLink } from 'components/Links/RevolutersEmployeeLink'
import { stringifySubjectType } from 'view/Sam/utils'
import { RevolutersDepartmentLink } from 'components/Links/RevolutersDepartmentLink'
import { Box } from '@revolut/ui-kit'

export const makeSubjectTypeRows = (subject?: SamPolicySubject): DetailRow[] => {
  if (!subject) {
    return []
  }

  const subjectTypeRow: DetailRow = [
    'Subject type',
    stringifySubjectType(subject.subjectType),
  ]

  switch (subject.subjectType) {
    case SamPolicySubjectType.Team:
      return [
        subjectTypeRow,
        vstackItems('Teams', subject.teamIds, (teamId) => (
          <RevolutersTeamLink id={teamId} key={teamId} withId />
        )),
        vstackItems('Employee types', subject.employeeTypes, (v) => (
          <Box key={v}>{capitalizeFirst(v)}</Box>
        )),
        vstackItems('Specialisations', subject.specialisationIds, (v) => (
          <RevolutersSpecialisationLink id={v} key={v} withId />
        )),
        vstackItems('Seniorities', subject.seniorityIds, (v) => (
          <RevolutersSeniority id={v} key={v} />
        )),
      ]
    case SamPolicySubjectType.Department:
      return [
        subjectTypeRow,
        vstackItems('Departments', subject.departmentIds, (departmentId) => (
          <RevolutersDepartmentLink id={departmentId} key={departmentId} />
        )),
        vstackItems('Employee types', subject.employeeTypes, (v) => (
          <Box key={v}>{capitalizeFirst(v)}</Box>
        )),
        vstackItems('Specialisations', subject.specialisationIds, (v) => (
          <RevolutersSpecialisationLink id={v} key={v} withId />
        )),
        vstackItems('Seniorities', subject.seniorityIds, (v) => (
          <RevolutersSeniority id={v} key={v} />
        )),
      ]
    case SamPolicySubjectType.Employee:
      return [
        subjectTypeRow,
        vstackItems('Employees', subject.employeeIds, (v) => (
          <RevolutersEmployeeLink id={v} key={v} />
        )),
      ]
    case SamPolicySubjectType.Specialisation:
      return [
        subjectTypeRow,
        vstackItems('Employee types', subject.employeeTypes, (v) => (
          <Box key={v}>{capitalizeFirst(v)}</Box>
        )),
        vstackItems('Specialisations', subject.specialisationIds, (v) => (
          <RevolutersSpecialisationLink id={v} key={v} withId />
        )),
        vstackItems('Seniorities', subject.seniorityIds, (v) => (
          <RevolutersSeniority id={v} key={v} />
        )),
      ]
    case SamPolicySubjectType.EmployeeType:
      return [
        subjectTypeRow,
        vstackItems('Employee types', subject.employeeTypes, (v) => (
          <Box key={v}>{capitalizeFirst(v)}</Box>
        )),
      ]
    default:
      return []
  }
}
