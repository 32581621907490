import { CrossCheckDetail } from 'api/types/crossCheck'
import { ChangeType, DetailRow } from '../../types'
import { DetailParams } from './types'
import { getForecasted } from './utils'
import { CREATE_NAMES } from '../../../ChangeSwitcher/utils'

export const getDescription = ({
  entity,
  crossCheck,
}: DetailParams): DetailRow | undefined => {
  switch (entity.type) {
    case 'Role': {
      const requested = crossCheck.executableParams.Description?.value
      const current = entity.value?.description
      const changeType = getChangeType({
        current,
        requested,
        crossCheck,
      })

      return {
        title: 'Description',
        value: {
          current,
          requested,
          forecasted: getForecasted({ current, changeType, requested }),
          type: 'text',
        },
        changeType,
      }
    }
    case 'AccessPolicy': {
      const current = entity.value?.businessReason
      const requested = crossCheck.executableParams.business_reason
      const changeType = getChangeType({
        current,
        requested,
        crossCheck,
      })

      return {
        title: 'Business reason',
        value: {
          current,
          requested,
          forecasted: getForecasted({ current, changeType, requested }),
          type: 'text',
        },
        changeType,
      }
    }
    default:
      return undefined
  }
}

const getChangeType = (params: {
  requested?: string
  current?: string
  crossCheck: CrossCheckDetail
}): ChangeType => {
  const { requested, crossCheck, current } = params
  if (CREATE_NAMES.includes(crossCheck.executableName)) {
    return 'EntityCreation'
  }

  if (!requested && typeof requested !== 'undefined' && current) {
    return 'Deleted'
  }

  if (requested === current || !requested) {
    return 'NoChange'
  }

  if (requested && !current) {
    return 'Added'
  }

  return 'Edited'
}
