import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'api/sam'
import { SamPolicy } from 'api/sam/policies'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { SAM_PERMISSIONS } from 'security'
import { useCallback } from 'react'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { Flex, Text } from '@revolut/ui-kit'

export const POLICY_UPDATE_PERMISSIONS = {
  actionPermission: SAM_PERMISSIONS.POLICIES_UPDATE_RESOURCES,
  xCheckPermission: SAM_PERMISSIONS.POLICIES_UPDATE_WITH_CROSS_CHECK,
}

export const useRemoveRoleFromPolicy = (roleId: string) => {
  const { setPopup, closePopup } = useConfirmationPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const { isDirect, toCrosscheck } = useCrossCheck(
    POLICY_UPDATE_PERMISSIONS.actionPermission,
    POLICY_UPDATE_PERMISSIONS.xCheckPermission,
  )

  const onError = useCallback(
    (error: unknown) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Policy update failed',
        error,
      })
      closePopup()
    },
    [hideLoadingPopup, showErrorPopup, closePopup],
  )

  const setMutation = useMutation({
    mutationFn: (policy: SamPolicy) =>
      api.policies.updatePolicyResources({
        policyId: policy.policyId,
        resourceIds: policy.resourceIds.filter((resourceId) => resourceId !== roleId),
      }),
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      showSuccessToast('The role removed from the policy')
      queryClient.invalidateQueries([QueryKey.SamPolicies, roleId])
    },
    onError,
    onMutate: () => showLoadingPopup({ title: 'Updating policy...' }),
  })

  const setCrossCheckMutation = useMutation({
    mutationFn: (policy: SamPolicy) =>
      api.policies.crossCheckUpdatePolicy({
        policyId: policy.policyId,
        data: {
          ...policy,
          mandatoryTrainings: policy.mandatoryTrainings || [],
          resourceIds: policy.resourceIds.filter((resourceId) => resourceId !== roleId),
        },
      }),
    onSuccess: (response) => {
      hideLoadingPopup()
      closePopup()
      toCrosscheck(response.data.crossCheckId)
    },
    onError,
    onMutate: () => showLoadingPopup({ title: 'Creating policy update cross-check' }),
  })

  const removeRoleFromPolicy = useCallback(
    (policy: SamPolicy) => {
      setPopup({
        title: 'Policy update confirmation',
        message: (
          <Flex flexDirection="column" gap="s-8">
            <Text>Are you sure that you want to remove the role from the policy?</Text>
            <Text>This role will no longer be provided by the policy.</Text>
          </Flex>
        ),
        confirmButtonText: 'Remove',
        confirmButtonVariant: 'negative',
        onConfirmClick: () =>
          isDirect ? setMutation.mutate(policy) : setCrossCheckMutation.mutate(policy),
      })
    },
    [isDirect, setCrossCheckMutation, setMutation, setPopup],
  )

  return removeRoleFromPolicy
}
