import { Box, StatusWidget } from '@revolut/ui-kit'
import { SAMPolicyResourceUpdateLog } from 'api/sam/auditLogs'
import { get3DImageSrcSetProps } from 'utils/url'
import { SamPolicyLogResourcesTable } from '../components/SamPolicyLogResourcesTable'
import { noResourceChanges } from './utils'

export const SamPolicyResourceSetSideLog = ({
  log,
}: {
  log: SAMPolicyResourceUpdateLog
}) => {
  if (noResourceChanges(log)) {
    return (
      <Box pt="s-16">
        <StatusWidget>
          <StatusWidget.Image {...get3DImageSrcSetProps('3D087')} />
          <StatusWidget.Title>No data available for this log</StatusWidget.Title>
          <StatusWidget.Description>
            We didn&apos;t save any details about this event
          </StatusWidget.Description>
        </StatusWidget>
      </Box>
    )
  }

  return (
    <>
      <SamPolicyLogResourcesTable
        resourceIds={log.data.resource_ids}
        title="Resources added"
      />
      <SamPolicyLogResourcesTable
        resourceIds={log.data.resource_deleted_ids}
        title="Resources deleted"
      />
      <SamPolicyLogResourcesTable
        resourceIds={log.data.resource_summary_ids}
        title="Summary"
      />
    </>
  )
}
