import { SamPolicySubjectType } from 'api/sam/policies'
import { toStringOption } from 'components/Selects/utils'

import { SelectInput } from '@revolut/ui-kit'

const OPTIONS = Object.values(SamPolicySubjectType).map(toStringOption)

export const PolicySubjectSelect = (props: {
  value?: SamPolicySubjectType
  onChange: (value?: SamPolicySubjectType | null) => void
  disabled?: boolean
}) => {
  const { value, onChange, disabled } = props

  return (
    <SelectInput
      label="Subject type"
      searchable={false}
      options={OPTIONS}
      clearable={false}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  )
}
