import { DetailRow } from '../../types'
import { DetailParams } from './types'
import { getDetailRow } from './utils'

export const getName = ({ entity, crossCheck }: DetailParams): DetailRow | undefined => {
  if (
    entity.type === 'User' ||
    entity.type === 'CustomerCompany' ||
    entity.type === 'EmployeeCompany' ||
    entity.type === 'ServiceAccount'
  ) {
    return undefined
  }

  const requested =
    crossCheck.entityType === 'AccessPolicy'
      ? crossCheck.executableParams.name
      : crossCheck.executableParams.Name?.value

  const current =
    entity.type === 'AccessPolicy' ? entity.value?.policyName : entity.value?.name

  const row = getDetailRow({
    title: 'Name',
    crossCheck,
    requested,
    current,
    type: 'text',
  })

  /**
   * If no change in name field no need to add row
   * there will be entity row with exact same text content
   */
  return row?.changeType === 'NoChange' ? undefined : row
}
