import { TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { UserTableCell, useUserTableCellAccessor } from 'components/UserTableCell'
import { useMemo } from 'react'
import { UserAlertRow } from './types'

export const useColumns = (params: {
  isLoading: boolean
}): TableColumn<UserAlertRow>[] => {
  const { isLoading } = params
  const getUserName = useUserTableCellAccessor()

  return useMemo(() => {
    const loadingFilter = isLoading
      ? undefined
      : {
          filter: 'includesValue' as const,
          Filter: SelectItemFilter,
        }

    const loadingCell = isLoading
      ? {
          Cell: () => <TextSkeleton size={16} />,
        }
      : undefined

    return [
      {
        Header: 'Name',
        id: 'name',
        accessor: ({ id }) => getUserName(id),
        Cell: (cell: { row: { original: UserAlertRow } }) => (
          <UserTableCell userId={cell.row.original.id} />
        ),
      },
      {
        Header: 'Team',
        accessor: 'team',
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'Spec',
        accessor: 'spec',
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'Cases',
        accessor: 'casesCount',
        align: 'center',
        ...loadingCell,
        ...loadingFilter,
      },
    ]
  }, [isLoading, getUserName])
}
