import { ActionButton } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'

type Props = {
  resourceId: string
}

export const Actions = ({ resourceId }: Props) => {
  return (
    <ActionButton
      use="a"
      href={generatePath(Url.SamResource, { resourceId })}
      useEndIcon="LinkExternal"
      target="_blank"
    >
      To resource
    </ActionButton>
  )
}
