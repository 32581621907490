import { Page, TabBar, TextSkeleton } from '@revolut/ui-kit'
import { OrganisationData } from 'api/idave/organisations'
import { QuerySwitch } from 'components/QuerySwitch'
import { TabBarLink } from 'components/TabBarLink'

import { useQueryOrgs } from 'queries/idave/orgs'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'
import { OrganisationTab } from 'view/Organisations/type'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'

import { OrganisationAuditLog } from './components/OrganisationAuditLog'
import { OrganisationDetails } from './components/OrganisationDetails'
import { OrganisationSkeleton } from './components/OrganisationSkeleton'

export const Organisation = (props: { organisationId: string; tab: OrganisationTab }) => {
  const { organisationId, tab } = props
  const { data: organisations = [], status, fetchStatus } = useQueryOrgs()
  const organisation = useMemo(
    () => organisations.find((i) => i.id === organisationId),
    [organisationId, organisations],
  )
  const organisationsRequestStatus = useMemo(
    () => [{ qs: status, fs: fetchStatus }],
    [status, fetchStatus],
  )
  return (
    <QuerySwitch
      required={organisationsRequestStatus}
      data={organisation}
      renderLoading={() => (
        <Outer organisationId={organisationId}>
          <OrganisationSkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data: org }) => (
        <Outer organisationId={organisationId} organisation={org}>
          <Inner tab={tab} organisation={org} />
        </Outer>
      )}
    />
  )
}

const Outer = (props: {
  organisationId: string
  children: React.ReactNode
  organisation?: OrganisationData
}) => {
  const { organisationId, children, organisation } = props
  const navigate = useNavigate()

  const onBackClick = useCallback(() => navigate(Url.Organisations), [navigate])

  return (
    <>
      <Page.Header onBack={onBackClick}>
        {organisation?.name || <TextSkeleton size={16} />}
      </Page.Header>

      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.OrganisationDetails, { organisationId })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            everyPermission={[
              IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST,
              IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST,
            ]}
          >
            <TabBarLink to={generatePath(Url.OrganisationAuditLog, { organisationId })}>
              Audit log
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = ({
  tab,
  organisation,
}: {
  organisation: OrganisationData
  tab: OrganisationTab
}) => {
  switch (tab) {
    case 'auditLog':
      return <OrganisationAuditLog organisationId={organisation.id} />
    default:
    case 'details':
      return <OrganisationDetails organisation={organisation} />
  }
}
