import { MoreBar } from '@revolut/ui-kit'
import { usePolicyActions } from './hooks/usePolicyActions'

export const SamPolicyDetailsActions = ({ id }: { id: string }) => {
  const actions = usePolicyActions({ policyId: id })

  if (!actions.length) {
    return null
  }

  return (
    <MoreBar>
      {actions.map((action) => (
        <MoreBar.Action
          key={action.label}
          variant={action.variant}
          useIcon={action.useIcon}
          onClick={action.onClick}
          disabled={action.disabled}
        >
          {action.label}
        </MoreBar.Action>
      ))}
    </MoreBar>
  )
}
