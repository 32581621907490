import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useFilteredUsers } from 'queries/idave/users'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { mergeQueryStatuses } from 'utils/query'

import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useColumns } from './columns'
import { getEntities } from './utils'

export const PermissionUsers = (props: { permissionId: string }) => {
  const { permissionId } = props
  const {
    data: users = [],
    status: usersQS,
    fetchStatus: usersFS,
  } = useFilteredUsers({ permissionId })
  const { data: avatars } = useQueryMappedAvatars()
  const { getEmployeeDescription, status } = useGetEmployeeRevoDescription()

  const columns = useColumns({ isLoading: status === 'loading' })
  const entities = useMemo(
    () => getEntities({ users, avatars, getEmployeeDescription }),
    [users, avatars, getEmployeeDescription],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({ entities })
  const getRowLink = useCallback(
    ({ id }: { id: string }) => generatePath(Url.User, { userId: id }),
    [],
  )
  const loadingState = getLoadingState(
    mergeQueryStatuses({ qs: usersQS, fs: usersFS }),
    users.length,
  )

  return (
    <EntitiesTable
      entitiesTypeLabel="User"
      pluralForms={['user', 'users']}
      totalCount={entities.length}
      columns={columns}
      data={searched}
      getRowLink={getRowLink}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
    />
  )
}
