import { DART_PERMISSIONS, IDAVE_PERMISSIONS } from 'security'
import { PermissionUtils } from 'services/permissions'
import { Url, UIRoute } from 'routing'
import { Dart } from './Dart'
import { DartEmployeeCompany } from './EmployeeCompany'
import { CustomerCompany } from './CustomerCompany'

export const routes = ({
  hasPermission,
  hasEveryPermission,
}: PermissionUtils): Record<string, UIRoute> => ({
  [Url.Dart]: {
    type: 'redirect',
    params: [],
    newUrl: Url.DartCustomerCompanies,
  },

  [Url.DartCustomerCompanies]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Dart tab="customerCompanies" />,
  },

  [Url.DartCustomerCompany]: {
    type: 'redirect',
    params: ['companyId'],
    newUrl: Url.DartCustomerCompanyAllowedCompanies,
  },

  [Url.DartCustomerCompanyAllowedCompanies]: {
    type: 'component',
    withParams: true,
    allowed: hasEveryPermission(
      DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_DETAILS,
      DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST,
    ),
    params: ['companyId'],
    fallbackRoute: Url.DartEmployeeCompanies,
    Component: (params: { companyId: string }) => (
      <CustomerCompany tab="employeeCompanies" companyId={params.companyId} />
    ),
  },

  [Url.DartCustomerCompanyAllowedEmployees]: {
    type: 'component',
    withParams: true,
    allowed: hasEveryPermission(
      DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_DETAILS,
      IDAVE_PERMISSIONS.USERS_VIEW_LIST,
    ),
    params: ['companyId'],
    fallbackRoute: Url.DartEmployeeCompanies,
    Component: (params: { companyId: string }) => (
      <CustomerCompany tab="employee" companyId={params.companyId} />
    ),
  },

  [Url.DartCustomerCompanyApprovers]: {
    type: 'component',
    withParams: true,
    allowed: hasEveryPermission(
      DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_DETAILS,
      DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST,
    ),
    params: ['companyId'],
    fallbackRoute: Url.DartEmployeeCompanies,
    Component: (params: { companyId: string }) => (
      <CustomerCompany tab="approvers" companyId={params.companyId} />
    ),
  },

  [Url.DartEmployeeCompanies]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Dart tab="employeeCompanies" />,
  },

  [Url.DartEmployeeCompany]: {
    type: 'redirect',
    params: ['companyId'],
    newUrl: Url.DartEmployeeCompanyAllowedCompanies,
  },

  [Url.DartEmployeeCompanyAllowedCompanies]: {
    type: 'component',
    withParams: true,
    allowed: hasEveryPermission(
      DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_DETAILS,
      DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST,
    ),
    fallbackRoute: Url.DartEmployeeCompanies,
    params: ['companyId'],
    Component: (params: { companyId: string }) => (
      <DartEmployeeCompany tab="allowedCustomerCompanies" companyId={params.companyId} />
    ),
  },

  [Url.DartEmployeeCompanyAuditLog]: {
    type: 'component',
    withParams: true,
    params: ['companyId'],
    allowed: hasPermission(DART_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    fallbackRoute: Url.DartEmployeeCompanies,
    Component: (params: { companyId: string }) => (
      <DartEmployeeCompany tab="auditLog" companyId={params.companyId} />
    ),
  },

  [Url.DartUserAccesses]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(DART_PERMISSIONS.EMPLOYEES_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Dart tab="userAccesses" />,
  },

  [Url.DartUserRestrictions]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(DART_PERMISSIONS.EMPLOYEES_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Dart tab="userRestrictions" />,
  },

  [Url.DartAuditLogs]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(DART_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Dart tab="auditLogs" />,
  },
})
