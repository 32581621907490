import { UserListItem } from 'api/idave/user'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { EmployeeAccesses } from 'api/dart/employee'
import { AccessModifier } from 'api/dart/types'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { sortBy } from 'lodash'
import { Restriction, UserRestriction, RestrictionType } from './types'

export const mapLimitationTypeToLabel = (type: RestrictionType) => {
  switch (type) {
    case 'bannedEmployee':
      return 'Banned Employee'
    case 'bannedRelations':
      return 'Banned Relation'
    default:
      return 'Unknown'
  }
}

export const getUserRestrictionType = ({
  entityId,
  type,
}: AccessModifier): RestrictionType => {
  if (type === 'ALLOWED') {
    return 'unknown'
  }

  if (!entityId) {
    return 'bannedEmployee'
  }

  if (entityId.type === 'CUSTOMER') {
    return 'bannedRelations'
  }

  return 'unknown'
}

export const getUserRestrictuions = (accessModifiers: AccessModifier[]) => {
  return accessModifiers.reduce<Restriction[]>((acc, modifier) => {
    if (modifier.type === 'ALLOWED') {
      return acc
    }

    return acc.concat({
      id: modifier.id,
      entity: modifier.entityId,
      justification: modifier.justification,
      type: getUserRestrictionType(modifier),
    })
  }, [])
}

export const getRows = (params: {
  directRelations: EmployeeAccesses[]
  userMap: Map<string, UserListItem>
  avatars?: Map<string, RevolutersAvatar>
}) => {
  const { directRelations, userMap, avatars } = params

  return sortBy(
    directRelations.reduce<UserRestriction[]>(
      (acc, employee) => {
        const restrictions = getUserRestrictuions(employee.accessModifiers)

        if (!restrictions.length) {
          return acc
        }
        const user = userMap.get(employee.id)
        const name = user ? getName(user) : employee.id
        const avatarUrl = getAvatarUrl({ avatars, user })

        const userRestrictions = restrictions.map<UserRestriction>((restriction) => ({
          ...restriction,
          employeeId: employee.id,
          name,
          avatarUrl,
          state: user?.state,
        }))

        return acc.concat(userRestrictions)
      },

      [],
    ),
    'name',
  )
}

export const stringifyLimitationObject = (value: UserRestriction) => {
  switch (value.type) {
    case 'bannedRelations':
      return value.entity?.id || EMPTY_VALUE_FALLBACK
    case 'bannedEmployee':
    default:
      return EMPTY_VALUE_FALLBACK
  }
}
