import { StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'

export const NoAccessWidget = ({
  title = 'Looks like you don’t have sufficient permissions to view this page',
}: {
  title?: string
}) => (
  <StatusWidget>
    <StatusWidget.Image {...get3DImageSrcSetProps('3D231', 'v2')} />
    <StatusWidget.Title>Not allowed</StatusWidget.Title>
    <StatusWidget.Description>{title}</StatusWidget.Description>
  </StatusWidget>
)
