import React from 'react'
import {
  ToastProvider as Provider,
  DefaultToastContainer,
  DefaultToast,
} from 'react-toast-notifications'
import styled from 'styled-components'

export const CustomToast = styled(DefaultToast)`
  pointer-events: auto;
`

export const CustomContainer = styled(DefaultToastContainer)`
  pointer-events: none;
`

export const ToastProvider = (props: { children: React.ReactNode }) => {
  return (
    <Provider components={{ Toast: CustomToast, ToastContainer: CustomContainer }}>
      {props.children}
    </Provider>
  )
}
