/* eslint-disable prettier/prettier */
export const IDAVE_PERMISSIONS = {
  AUDIT_LOG_VIEW_LIST: {
    endpoints: [
      'GET /audit-log'
    ],
    service: 'idave',
    value: 'audit_log.view.list'
  },
  CLIENTS_CREATE: {
    endpoints: [
      'PUT /clients',
      'POST /client'
    ],
    service: 'idave',
    value: 'clients.create'
  },
  CLIENTS_DELETE: {
    endpoints: [
      'DELETE /client/:clientId'
    ],
    service: 'idave',
    value: 'clients.delete'
  },
  CLIENTS_UPDATE: {
    endpoints: [
      'PUT /client/:clientId'
    ],
    service: 'idave',
    value: 'clients.update'
  },
  CLIENTS_VIEW_DETAILS: {
    endpoints: [
      'GET /clients/:clientId'
    ],
    service: 'idave',
    value: 'clients.view.details'
  },
  CLIENTS_VIEW_LIST: {
    endpoints: [
      'GET /clients'
    ],
    service: 'idave',
    value: 'clients.view.list'
  },
  CROSS_CHECKS_COMMENT: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/comment'
    ],
    service: 'idave',
    value: 'cross_checks.comment'
  },
  CROSS_CHECKS_CREATE_GROUPS: {
    endpoints: [
      'POST /cross-checks/groups'
    ],
    service: 'idave',
    value: 'cross_checks.create.groups'
  },
  CROSS_CHECKS_DECLINE: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/decline'
    ],
    service: 'idave',
    value: 'cross_checks.decline'
  },
  CROSS_CHECKS_JUSTIFY: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/justify'
    ],
    service: 'idave',
    value: 'cross_checks.justify'
  },
  CROSS_CHECKS_REFRESH: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/refresh'
    ],
    service: 'idave',
    value: 'cross_checks.refresh'
  },
  CROSS_CHECKS_RETRY: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/retry'
    ],
    service: 'idave',
    value: 'cross_checks.retry'
  },
  CROSS_CHECKS_REVIEW: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/review'
    ],
    service: 'idave',
    value: 'cross_checks.review'
  },
  CROSS_CHECKS_RUN_CLIENT_CREATE_ACTION: {
    endpoints: [
      'PUT /clients',
      'POST /client'
    ],
    service: 'idave',
    value: 'cross_checks.run.client_create_action'
  },
  CROSS_CHECKS_RUN_CLIENT_DELETE_ACTION: {
    endpoints: [
      'DELETE /client/:clientId'
    ],
    service: 'idave',
    value: 'cross_checks.run.client_delete_action'
  },
  CROSS_CHECKS_RUN_CLIENT_UPDATE_ACTION: {
    endpoints: [
      'PUT /client/:clientId'
    ],
    service: 'idave',
    value: 'cross_checks.run.client_update_action'
  },
  CROSS_CHECKS_RUN_ROLE_ASSIGN_ACTION: {
    endpoints: [
      'POST /users/:userId/roles'
    ],
    service: 'idave',
    value: 'cross_checks.run.role_assign_action'
  },
  CROSS_CHECKS_RUN_ROLE_CREATE_ACTION: {
    endpoints: [
      'POST /roles'
    ],
    service: 'idave',
    value: 'cross_checks.run.role_create_action'
  },
  CROSS_CHECKS_RUN_ROLE_UPDATE_ACTION: {
    endpoints: [
      'PUT /roles/:roleId'
    ],
    service: 'idave',
    value: 'cross_checks.run.role_update_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_ACTIVATE_ACTION: {
    endpoints: [
      'POST /service-accounts/:saId/activate'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_activate_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_CREATE_ACTION: {
    endpoints: [
      'POST /service-accounts'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_create_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_DELETE_ACTION: {
    endpoints: [
      'DELETE /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_delete_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_TERMINATE_ACTION: {
    endpoints: [
      'POST /service-accounts/:saId/terminate'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_terminate_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_UPDATE_ACTION: {
    endpoints: [
      'PUT /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_update_action'
  },
  CROSS_CHECKS_UPDATE_GROUPS_REVIEWERS: {
    endpoints: [
      'POST /cross-checks/groups/:crossCheckGroupId/reviewers'
    ],
    service: 'idave',
    value: 'cross_checks.update.groups.reviewers'
  },
  CROSS_CHECKS_VIEW_DETAILS: {
    endpoints: [
      'GET /cross-checks/:crossCheckId'
    ],
    service: 'idave',
    value: 'cross_checks.view.details'
  },
  CROSS_CHECKS_VIEW_GROUPS_DETAILS: {
    endpoints: [
      'GET /cross-checks/groups/:crossCheckGroupId'
    ],
    service: 'idave',
    value: 'cross_checks.view.groups.details'
  },
  CROSS_CHECKS_VIEW_LIST: {
    endpoints: [
      'GET /cross-checks'
    ],
    service: 'idave',
    value: 'cross_checks.view.list'
  },
  CUSTOMIZATIONS_DELETE_CLIENTS: {
    endpoints: [
      'DELETE /customizations/clients/:clientId'
    ],
    service: 'idave',
    value: 'customizations.delete.clients'
  },
  CUSTOMIZATIONS_DELETE_SERVICE_ACCOUNTS: {
    endpoints: [
      'DELETE /customizations/service-accounts/:saId'
    ],
    service: 'idave',
    value: 'customizations.delete.service_accounts'
  },
  CUSTOMIZATIONS_UPDATE_CLIENTS: {
    endpoints: [],
    service: 'idave',
    value: 'customizations.update.clients'
  },
  CUSTOMIZATIONS_UPDATE_SERVICE_ACCOUNTS: {
    endpoints: [],
    service: 'idave',
    value: 'customizations.update.service_accounts'
  },
  CUSTOMIZATIONS_VIEW_CLIENTS_DETAILS: {
    endpoints: [
      'GET /customizations/clients/:clientId'
    ],
    service: 'idave',
    value: 'customizations.view.clients.details'
  },
  CUSTOMIZATIONS_VIEW_CLIENTS_LIST: {
    endpoints: [
      'GET /customizations/clients'
    ],
    service: 'idave',
    value: 'customizations.view.clients.list'
  },
  CUSTOMIZATIONS_VIEW_SERVICE_ACCOUNTS_DETAILS: {
    endpoints: [
      'GET /customizations/service-accounts/:saId'
    ],
    service: 'idave',
    value: 'customizations.view.service_accounts.details'
  },
  CUSTOMIZATIONS_VIEW_SERVICE_ACCOUNTS_LIST: {
    endpoints: [
      'GET /customizations/service-accounts'
    ],
    service: 'idave',
    value: 'customizations.view.service_accounts.list'
  },
  GROUPS_CREATE: {
    endpoints: [
      'POST /groups'
    ],
    service: 'idave',
    value: 'groups.create'
  },
  GROUPS_DELETE: {
    endpoints: [
      'DELETE /groups/:groupId'
    ],
    service: 'idave',
    value: 'groups.delete'
  },
  GROUPS_UPDATE: {
    endpoints: [
      'PUT /groups/:groupId'
    ],
    service: 'idave',
    value: 'groups.update'
  },
  GROUPS_VIEW_DETAILS: {
    endpoints: [
      'GET /groups/:groupId'
    ],
    service: 'idave',
    value: 'groups.view.details'
  },
  GROUPS_VIEW_LIST: {
    endpoints: [
      'GET /groups'
    ],
    service: 'idave',
    value: 'groups.view.list'
  },
  JOBS_RUN: {
    endpoints: [
      'GET /jobs/:name'
    ],
    service: 'idave',
    value: 'jobs.run'
  },
  OAUTH2_VIEW_BACKUP_DETAILS: {
    endpoints: [
      'GET /oauth2/backup'
    ],
    service: 'idave',
    value: 'oauth2.view.backup.details'
  },
  ORGANISATIONS_CREATE: {
    endpoints: [
      'POST /organisations'
    ],
    service: 'idave',
    value: 'organisations.create'
  },
  ORGANISATIONS_DELETE: {
    endpoints: [
      'DELETE /organisations/:organisationId'
    ],
    service: 'idave',
    value: 'organisations.delete'
  },
  ORGANISATIONS_UPDATE: {
    endpoints: [
      'PUT /organisations/:organisationId'
    ],
    service: 'idave',
    value: 'organisations.update'
  },
  ORGANISATIONS_VIEW_LIST: {
    endpoints: [
      'GET /organisations'
    ],
    service: 'idave',
    value: 'organisations.view.list'
  },
  ORPHANED_PERMISSIONS_VIEW_LIST: {
    endpoints: [
      'GET /orphaned-permissions'
    ],
    service: 'idave',
    value: 'orphaned_permissions.view.list'
  },
  PERMISSIONS_UPDATE_DESCRIPTION: {
    endpoints: [
      'PATCH /permissions/:permissionId/description'
    ],
    service: 'idave',
    value: 'permissions.update.description'
  },
  PERMISSIONS_VIEW_ACL_LIST: {
    endpoints: [
      'GET /permissions/acl'
    ],
    service: 'idave',
    value: 'permissions.view.acl.list'
  },
  PERMISSIONS_VIEW_DETAILS: {
    endpoints: [
      'GET /permissions/:permissionId'
    ],
    service: 'idave',
    value: 'permissions.view.details'
  },
  PERMISSIONS_VIEW_LIST: {
    endpoints: [
      'GET /permissions'
    ],
    service: 'idave',
    value: 'permissions.view.list'
  },
  PERMISSIONS_VIEW_ROLES_LIST: {
    endpoints: [
      'GET /permissions/:permissionId/roles'
    ],
    service: 'idave',
    value: 'permissions.view.roles.list'
  },
  ROLES_CREATE: {
    endpoints: [
      'POST /roles'
    ],
    service: 'idave',
    value: 'roles.create'
  },
  ROLES_DELETE: {
    endpoints: [
      'DELETE /roles/:roleId'
    ],
    service: 'idave',
    value: 'roles.delete'
  },
  ROLES_UPDATE: {
    endpoints: [
      'PUT /roles/:roleId'
    ],
    service: 'idave',
    value: 'roles.update'
  },
  ROLES_VIEW_DETAILS: {
    endpoints: [
      'GET /roles/:roleId'
    ],
    service: 'idave',
    value: 'roles.view.details'
  },
  ROLES_VIEW_LIST: {
    endpoints: [
      'GET /roles'
    ],
    service: 'idave',
    value: 'roles.view.list'
  },
  SERVICE_ACCOUNTS_ACTIVATE: {
    endpoints: [
      'POST /service-accounts/:saId/activate'
    ],
    service: 'idave',
    value: 'service_accounts.activate'
  },
  SERVICE_ACCOUNTS_CREATE: {
    endpoints: [
      'POST /service-accounts'
    ],
    service: 'idave',
    value: 'service_accounts.create'
  },
  SERVICE_ACCOUNTS_DELETE: {
    endpoints: [
      'DELETE /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'service_accounts.delete'
  },
  SERVICE_ACCOUNTS_TERMINATE: {
    endpoints: [
      'POST /service-accounts/:saId/terminate'
    ],
    service: 'idave',
    value: 'service_accounts.terminate'
  },
  SERVICE_ACCOUNTS_UPDATE: {
    endpoints: [
      'PUT /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'service_accounts.update'
  },
  SERVICE_ACCOUNTS_VIEW_DETAILS: {
    endpoints: [
      'GET /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'service_accounts.view.details'
  },
  SERVICE_ACCOUNTS_VIEW_LIST: {
    endpoints: [
      'GET /service-accounts'
    ],
    service: 'idave',
    value: 'service_accounts.view.list'
  },
  TOXIC_PERMISSIONS_CREATE: {
    endpoints: [
      'POST /toxic-permissions'
    ],
    service: 'idave',
    value: 'toxic_permissions.create'
  },
  TOXIC_PERMISSIONS_DELETE: {
    endpoints: [
      'DELETE /toxic-permissions/:toxicPermissionId'
    ],
    service: 'idave',
    value: 'toxic_permissions.delete'
  },
  TOXIC_PERMISSIONS_VIEW_LIST: {
    endpoints: [
      'GET /toxic-permissions'
    ],
    service: 'idave',
    value: 'toxic_permissions.view.list'
  },
  TOXIC_PERMISSION_ALERTS_VIEW_LIST: {
    endpoints: [
      'GET /toxic-permission-alerts'
    ],
    service: 'idave',
    value: 'toxic_permission_alerts.view.list'
  },
  USERS_ASSIGN_ROLES: {
    endpoints: [
      'POST /users/:userId/roles'
    ],
    service: 'idave',
    value: 'users.assign.roles'
  },
  USERS_CLEAR_ROLES: {
    endpoints: [
      'POST /users/:userId/roles/clear'
    ],
    service: 'idave',
    value: 'users.clear.roles'
  },
  USERS_CREATE: {
    endpoints: [
      'POST /users'
    ],
    service: 'idave',
    value: 'users.create'
  },
  USERS_CURRENT_CHANGE_PASSWORD: {
    endpoints: [
      'POST /users/password'
    ],
    service: 'idave',
    value: 'users.current.change.password'
  },
  USERS_CURRENT_VIEW_DETAILS: {
    endpoints: [
      'GET /users/current'
    ],
    service: 'idave',
    value: 'users.current.view.details'
  },
  USERS_CURRENT_VIEW_ROLES_LIST: {
    endpoints: [
      'GET /users/current/roles'
    ],
    service: 'idave',
    value: 'users.current.view.roles.list'
  },
  USERS_NOTIFY: {
    endpoints: [
      'POST /users/notify'
    ],
    service: 'idave',
    value: 'users.notify'
  },
  USERS_RESET_PASSWORD: {
    endpoints: [
      'PUT /users/reset'
    ],
    service: 'idave',
    value: 'users.reset.password'
  },
  USERS_TERMINATE: {
    endpoints: [
      'PUT /users/terminate'
    ],
    service: 'idave',
    value: 'users.terminate'
  },
  USERS_UNASSIGN_ROLES: {
    endpoints: [
      'DELETE /users/:userId/roles/:roleId'
    ],
    service: 'idave',
    value: 'users.unassign.roles'
  },
  USERS_UNTERMINATE: {
    endpoints: [
      'PUT /users/activate',
      'POST /users/unterminate'
    ],
    service: 'idave',
    value: 'users.unterminate'
  },
  USERS_UPDATE: {
    endpoints: [
      'PUT /users/update'
    ],
    service: 'idave',
    value: 'users.update'
  },
  USERS_VIEW_DETAILS: {
    endpoints: [
      'GET /users/:userId',
      'GET /users/info'
    ],
    service: 'idave',
    value: 'users.view.details'
  },
  USERS_VIEW_LIST: {
    endpoints: [
      'GET /users',
      'GET /users/all'
    ],
    service: 'idave',
    value: 'users.view.list'
  },
  USERS_VIEW_LOGS_ACCESS_LIST: {
    endpoints: [
      'GET /users/:userId/logs/access'
    ],
    service: 'idave',
    value: 'users.view.logs.access.list'
  },
  USERS_VIEW_ROLES_LIST: {
    endpoints: [
      'GET /users/:userId/roles'
    ],
    service: 'idave',
    value: 'users.view.roles.list'
  },
  WHOAMI_VIEW_DETAILS: {
    endpoints: [
      'GET /whoami'
    ],
    service: 'idave',
    value: 'whoami.view.details'
  }
} as const