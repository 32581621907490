import { Box, TableFilters, useIntersectViewport } from '@revolut/ui-kit'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EntitiesTable } from 'components/EntitiesTable'
import { useMapify } from 'hooks/useMapify'
import { isAllSettled } from 'utils/query'
import { useQueryUserAccessLogs } from 'queries/idave/logs'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useSideBox } from 'view/SideBox/SideBox'
import { CalendarFilter, DateFilterValue } from 'components/CalendarFilter'
import { SideAvatar } from 'view/SideBox/SideAvatar'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { AccessLogRow } from './types'
import { getLogItems } from './utils'
import { getColumns } from './columns'
import { UserAccessLogSide } from './UserAccessLogSide'

type UserAccessLogsProps = {
  userId: string
}

export const UserAccessLogs = ({ userId }: UserAccessLogsProps) => {
  const {
    data: clientsMap = new Map(),
    status: clientStatus,
    fetchStatus: clientFetchStatus,
  } = useQueryClientIdMap()
  const { openSide } = useSideBox()

  const openLogView = useCallback(
    (log: AccessLogRow) =>
      openSide({
        body: <UserAccessLogSide log={log.logItem} clientMap={clientsMap} />,
        title: 'Access log',
        avatar: <SideAvatar iconName="Apps" />,
      }),
    [openSide, clientsMap],
  )
  const [dateFilter, setDateFilter] = useState<DateFilterValue>(null)

  const limits = useMemo(
    () =>
      dateFilter
        ? {
            from: dateFilter.from.getTime(),
            to: dateFilter.to.getTime(),
          }
        : undefined,
    [dateFilter],
  )

  const {
    data: userAccessLogs,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status: logsStatus,
  } = useQueryUserAccessLogs(userId, limits)
  const logItems = useMemo(
    () => (userAccessLogs?.pages || []).flatMap((page) => page.data),
    [userAccessLogs],
  )

  const [filters, setFilters] = useState<TableFilters<AccessLogRow>>([])

  const filterMap = useMapify(filters, (f) => f.id)

  const logs = useMemo(
    () => getLogItems(logItems, clientsMap, filterMap, limits),
    [logItems, clientsMap, filterMap, limits],
  )

  // load data ourself because of bug in Table
  // DEUI-2677
  const [isIntersected, setIntersected] = useState(false)
  const afterRef = useRef(null)
  useIntersectViewport(afterRef, setIntersected)
  useEffect(() => {
    if (isIntersected && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [isIntersected, hasNextPage, isFetchingNextPage, fetchNextPage])

  const isAllDataSettled = isAllSettled({ qs: clientStatus, fs: clientFetchStatus })
  const columns = useMemo(() => getColumns(isAllDataSettled), [isAllDataSettled])

  const loadingStatus = logItems.length ? 'success' : logsStatus

  const dataLabels = useMemo(
    () => [
      {
        value: logItems.length,
        label: 'Loaded',
      },
    ],
    [logItems.length],
  )
  return (
    <>
      <EntitiesTable
        key={String(isAllDataSettled)}
        entitiesTypeLabel="Logs"
        pluralForms={['log', 'logs']}
        columns={columns}
        data={logs}
        loadingState={getLoadingState(loadingStatus, logItems.length)}
        onFilter={setFilters}
        hasMore={hasNextPage}
        dataLabels={dataLabels}
        onRowClick={openLogView}
        manualFilters
        renderFiltersLeft={() => (
          <CalendarFilter value={dateFilter} onChange={setDateFilter} exceptFuture />
        )}
      />
      <Box ref={afterRef} />
    </>
  )
}
