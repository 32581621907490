import { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { useQueryEmployeeCompanies } from 'queries/dart/companies'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { Url } from 'routing'
import { mergeQueryStatuses } from 'utils/query'
import { EntitiesTable } from 'components/EntitiesTable'
import { EmployeeCompany } from 'api/dart/employeeCompanies'
import { COLUMNS } from './columns'

export const EmployeeCompanies = () => {
  const navigate = useNavigate()
  const {
    data: employeeCompanies = [],
    status: qs,
    fetchStatus: fs,
  } = useQueryEmployeeCompanies()

  const loadingStatus = mergeQueryStatuses({ qs, fs })
  const loadingState = getLoadingState(loadingStatus, employeeCompanies.length)

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: employeeCompanies,
  })

  const onRowClick = useCallback(
    ({ id: companyId }: EmployeeCompany) =>
      navigate(
        generatePath(Url.DartEmployeeCompany, {
          companyId,
        }),
      ),
    [navigate],
  )
  const getRowLink = useCallback(
    ({ id: companyId }: EmployeeCompany) =>
      generatePath(Url.DartEmployeeCompany, {
        companyId,
      }),
    [],
  )

  return (
    <EntitiesTable
      enableNavigation
      totalCount={employeeCompanies.length}
      entitiesTypeLabel="Companies"
      pluralForms={['company', 'companies']}
      data={searched}
      columns={COLUMNS}
      loadingState={loadingState}
      onRowClick={onRowClick}
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      getRowLink={getRowLink}
      searchAutoFocus
    />
  )
}
