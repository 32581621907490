import { CrossCheckDetail } from 'api/types/crossCheck'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { isRecertification } from 'view/CrossChecks/CrossCheckList/utils'
import { sortBy } from 'lodash'
import { CrossCheckValueTab } from '../../types'

type GetStateCountersParams = {
  values: string[]
  newValues: string[]
  deletedHash: Record<string, boolean>
  addedHash: Record<string, boolean>
  isFinished: boolean
}
export const getStateCounters = ({
  values,
  newValues,
  addedHash,
  deletedHash,
  isFinished,
}: GetStateCountersParams) => ({
  current: values.length,
  forecasted: newValues.length,
  requested: isFinished
    ? newValues.length
    : [...new Set([...newValues, ...values])].filter(
        (v) => addedHash[v] || deletedHash[v],
      ).length,
})

type GetStateCurrentRowsParams<T, P> = {
  values: string[]
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getCurrentRows = <T, P>(params: GetStateCurrentRowsParams<T, P>) =>
  params.values.map(params.makeMapValue(params))

type GetStateRequestedRowsParams<T, P> = {
  values: string[]
  newValues: string[]
  deletedHash: Record<string, boolean>
  addedHash: Record<string, boolean>
  isFinished: boolean
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getStateRequestedRows = <T, P>(
  params: GetStateRequestedRowsParams<T, P>,
) => {
  const { newValues, isFinished, makeMapValue, addedHash, deletedHash, values } = params
  if (isFinished) {
    return newValues.map(makeMapValue(params))
  }

  const diffValues = [...new Set([...newValues, ...values])].filter(
    (v) => addedHash[v] || deletedHash[v],
  )

  return diffValues
    .filter((v) => addedHash[v] || deletedHash[v])
    .map(makeMapValue(params))
}

type GetStateForecastedRowsParams<T, P> = {
  newValues: string[]
  deletedHash: Record<string, boolean>
  addedHash: Record<string, boolean>
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getStateForecastedRows = <T, P>(
  params: GetStateForecastedRowsParams<T, P>,
) => params.newValues.map(params.makeMapValue(params))

type GetStateTabRowsInfo<T, P> = {
  tabs: CrossCheckValueTab[]
  values: string[]
  newValues: string[]
  deletedHash: Record<string, boolean>
  addedHash: Record<string, boolean>
  isFinished: boolean
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getStateTabRowsInfo = <T, P>(params: GetStateTabRowsInfo<T, P>) => {
  return {
    rows: params.tabs.reduce<Partial<Record<CrossCheckValueTab, T[]>>>(
      (acc, tab) => ({
        ...acc,
        [tab]: sortBy(getStateTab(tab, params), 'status', 'name'),
      }),
      {},
    ),
    counters: getStateCounters(params),
  }
}

type GetStateTabParams<T, P> = {
  values: string[]
  newValues: string[]
  deletedHash: Record<string, boolean>
  addedHash: Record<string, boolean>
  isFinished: boolean
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getStateTab = <T, P>(
  tab: CrossCheckValueTab,
  params: GetStateTabParams<T, P>,
) => {
  switch (tab) {
    case 'current':
      return getCurrentRows(params)
    case 'requested':
      return getStateRequestedRows(params)
    case 'forecasted':
      return getStateForecastedRows(params)
    default:
      return []
  }
}

type GetRequestedChangeRowsParams<T, P> = {
  current: string[]
  values: string[]
  added: string[]
  deleted: string[]
  deletedHash: Record<string, boolean>
  addedHash: Record<string, boolean>
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getRequestedChangeRows = <T, P>(
  params: GetRequestedChangeRowsParams<T, P>,
) => {
  const { added, deleted, values } = params

  const uniq = new Set([...added, ...deleted, ...values])
  return [...uniq].map(params.makeMapValue(params))
}

type GetForecastedChangeRowsParams<T, P> = {
  current: string[]
  values: string[]
  added: string[]
  deleted: string[]
  deletedHash: Record<string, boolean>
  addedHash: Record<string, boolean>
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getForecastedChangeRows = <T, P>(
  params: GetForecastedChangeRowsParams<T, P>,
) => {
  const { added, deletedHash, values, current } = params

  const uniq = new Set([...added, ...values, ...current.filter((v) => !deletedHash[v])])
  return [...uniq].map(params.makeMapValue(params))
}

type GetChangeRowsParams<T, P> = {
  current: string[]
  values: string[]
  added: string[]
  deleted: string[]
  addedHash: Record<string, boolean>
  deletedHash: Record<string, boolean>
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getChangeRows = <T, P>(
  tab: CrossCheckValueTab,
  params: GetChangeRowsParams<T, P>,
) => {
  switch (tab) {
    case 'current':
      return getCurrentRows({
        ...params,
        values: params.current,
      })
    case 'requested':
      return getRequestedChangeRows(params)
    case 'forecasted':
      return getForecastedChangeRows(params)
    default:
      return []
  }
}

const getChangeRowCounters = <T>(
  tabs: CrossCheckValueTab[],
  rows: Partial<Record<CrossCheckValueTab, T[]>>,
): Partial<Record<CrossCheckValueTab, number>> =>
  tabs.reduce((acc, tab) => {
    return { ...acc, [tab]: rows[tab]?.length }
  }, {})

type GetChangeRowsInfo<T, P> = {
  tabs: CrossCheckValueTab[]
  current: string[]
  values: string[]
  added: string[]
  deleted: string[]
  deletedHash: Record<string, boolean>
  addedHash: Record<string, boolean>
  makeMapValue: (params: P) => (value: string) => T
} & P
export const getChangeRowsInfo = <T, P>(params: GetChangeRowsInfo<T, P>) => {
  const rows = params.tabs.reduce<Partial<Record<CrossCheckValueTab, T[]>>>(
    (acc, tab) => ({
      ...acc,
      [tab]: sortBy(getChangeRows(tab, params), 'status', 'name', 'value'),
    }),
    {},
  )

  return {
    rows,
    counters: getChangeRowCounters(params.tabs, rows),
  }
}

type GetValuesParams = {
  newValues: string[]
  values: string[]
  crossCheck: CrossCheckDetail
}

export const getStateValues = ({ newValues, values, crossCheck }: GetValuesParams) => {
  const valuesHash = toPresenceMap(values)
  const newValuesHash = toPresenceMap(newValues)
  if (isRecertification(crossCheck.executableName)) {
    return { addedValues: [], deletedValues: [] }
  }

  return {
    addedValues: newValues.filter((id) => !valuesHash[id]),
    deletedValues: values.filter((id) => !newValuesHash[id]),
  }
}

export const isStatePolicyChange = (params: { crossCheck: CrossCheckDetail }) => {
  const { crossCheck } = params
  const { executableName, executableParams } = crossCheck

  // only old policy updates used state changes
  if (executableName !== 'UpdatePolicy') {
    return false
  }

  const hasDiffs =
    typeof executableParams.resources_diff === 'object' ||
    typeof executableParams.mandatory_trainings_diff === 'object'

  return !hasDiffs
}

export const isCrosscheckWithoutAttributes = ({
  crossCheck,
}: {
  crossCheck: CrossCheckDetail
}) => {
  const isPermissionSync = crossCheck.executableName === 'PermissionMatrixSynchronizer'

  return isPermissionSync
}
