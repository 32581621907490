import { Navigate } from 'react-router'
import { UIRoute } from 'routing/types'
import { Url } from 'routing/url'
import { ParametrizedElement } from './ParametrizedElement'
import { RedirectElement } from './RedirectElement'

export const RouteElement = (props: { route: UIRoute; routePath: string }) => {
  const { route, routePath } = props
  const { type } = route

  if (type === 'redirect') {
    return <RedirectElement params={route.params} url={route.newUrl} />
  }

  const { allowed, withParams, Component } = route
  if (!allowed) {
    return <Navigate to={route.fallbackRoute || Url.Home} replace key={routePath} />
  }

  return withParams ? (
    <ParametrizedElement
      Component={route.Component}
      fallbackRoute={route.fallbackRoute || Url.Home}
      params={route.params}
    />
  ) : (
    <Component />
  )
}
