import { Text, TextSkeleton } from '@revolut/ui-kit'
import { useQueryResource } from 'queries/sam/resources'
import { mergeQueryStatuses } from 'utils/query'

type Props = {
  resourceId: string
}

export const Title = ({ resourceId }: Props) => {
  const { data, fetchStatus, status } = useQueryResource(resourceId)
  const queryStatus = mergeQueryStatuses({ fs: fetchStatus, qs: status })

  const name = data?.resourceName || resourceId

  return queryStatus === 'loading' ? <TextSkeleton size={12} /> : <Text>{name}</Text>
}
