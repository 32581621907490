import { Client } from 'api/idave/clients'
import { Permission } from 'api/idave/permissions'
import { Role } from 'api/idave/roles'
import { User } from 'api/idave/user'
import { uniq } from 'lodash'
import { traverseRoleParents } from 'utils/role/traverseRoleParents'

type GetPermissionRowsParams = {
  roleMap: Map<string, Role>
  permissionsMap: Map<string, Permission>
  clientMap: Map<string, Client>
  user?: User
}

export const getPermissionRows = ({
  roleMap,
  permissionsMap,
  user,
  clientMap,
}: GetPermissionRowsParams) => {
  const userRoleIds = (user?.roles || []).map(({ roleId }) => roleId)
  const userFullRoles = userRoleIds.reduce<string[]>((parents, roleId) => {
    return [...parents, roleId, ...traverseRoleParents({ roleMap, roleId })]
  }, [])
  const permissionIds = uniq(
    userFullRoles.flatMap((roleId) => roleMap.get(roleId)?.permissions || []),
  )

  return permissionIds.map((permissionId) => {
    const permission = permissionsMap.get(permissionId)
    if (!permission) {
      return {
        id: permissionId,
        permission: permissionId,
        client: 'UNKNOWN',
        clientId: 'UNKNOWN',
      }
    }

    const client = clientMap.get(permission.client)

    return {
      id: permission.id,
      permission: permission.name,
      client: client?.name || permission.client,
      clientId: client?.id || permission.client,
    }
  })
}
