import { useQueryRoleIdMap } from 'queries/idave/roles'
import { TextSkeleton } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { QuerySwitch } from 'components/QuerySwitch'
import { InternalLink } from './InternalLink'

type Props = {
  id: string
}

export const RoleLink = ({ id }: Props) => {
  const { data: roles, status: qs, fetchStatus: fs } = useQueryRoleIdMap()

  const href = generatePath(Url.Role, { roleId: id })
  const role = roles?.get(id)

  return (
    <InternalLink href={href} title={role?.name}>
      <QuerySwitch
        required={[{ qs, fs }]}
        data={role}
        renderError={() => id}
        renderIdle={() => id}
        renderLoading={() => <TextSkeleton size={12} />}
        renderSuccess={({ data }) => data.name}
      />
    </InternalLink>
  )
}
