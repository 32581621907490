import { Role } from 'api/idave/roles'
import { RoleEditFormState } from './RoleEditForm'

export const roleToRoleState = (role?: Role): RoleEditFormState => ({
  name: role?.name.trim() || '',
  description: role?.description.trim() || '',
  ownerGroupId: role?.ownerGroupId || '',
  parent: role?.parent,
  approverId: role?.approvers?.[0],
})
