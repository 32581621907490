import { useQuery } from '@tanstack/react-query'
import samApi from 'api/sam'
import { QueryKey } from 'helpers/configQuery'
import { makeMappedKeyDataHook } from 'queries/utils'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'

export const useQueryResources = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamResources],
    queryFn: samApi.resources.getResources,
    enabled: hasPermission(SAM_PERMISSIONS.RESOURCES_VIEW_LIST),
    select: (response) => response?.data.data,
  })
}
export const useQueryResourceMap = makeMappedKeyDataHook(useQueryResources, 'resourceId')

export const useQueryResource = (id: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamResource, id],
    queryFn: () => samApi.resources.getResource(id),
    enabled: hasPermission(SAM_PERMISSIONS.RESOURCES_VIEW_DETAILS),
    select: (response) => response?.data,
  })
}
export const useQueryTrainings = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamTrainings],
    queryFn: samApi.trainings.getTrainingsList,
    enabled: hasPermission(SAM_PERMISSIONS.TRAININGS_VIEW_LIST),
    select: (response) => response?.data.data,
  })
}
export const useQueryTrainingMap = makeMappedKeyDataHook(useQueryTrainings, 'code')
