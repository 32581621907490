import { PEOPLE_URL } from 'constants/urls'
import { useQueryRevolutersDictionaries } from 'queries/sam/revoluters'
import { QuerySwitch } from 'components/QuerySwitch'
import { TextSkeleton } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { ExternalTextLink } from './ExternalTextLink'

export const RevolutersDepartmentLink: React.FC<{ id?: string; withId?: boolean }> = ({
  id,
  withId,
}) => {
  const {
    data: revoluters,
    status: qs,
    fetchStatus: fs,
  } = useQueryRevolutersDictionaries()

  if (!id) {
    return <>{EMPTY_VALUE_FALLBACK}</>
  }

  const href = `${PEOPLE_URL}/department/summary/${id}`

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={revoluters}
      renderError={() => <ExternalTextLink href={href} text={id} />}
      renderIdle={() => <ExternalTextLink href={href} text={id} />}
      renderLoading={() => <TextSkeleton size={15} />}
      renderSuccess={({ data }) => {
        const department = data.departments?.find((t) => t.id === id)
        const text =
          withId && department ? `${department.name} (${id})` : department?.name

        return <ExternalTextLink href={href} text={text || id} />
      }}
    />
  )
}
