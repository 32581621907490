import { Avatar, HStack, Text, abbreviate } from '@revolut/ui-kit'
import { UserStates } from 'api/idave/user'
import { useUserStateTooltip } from 'hooks/useUserStateTooltip'
import { HTMLAttributeAnchorTarget } from 'react'
import { InternalLink } from '../InternalLink'
import { ExternalLink } from '../ExternalLink'

export type UserLinkViewProps = {
  id: string
  name: string
  avatarUrl?: string
  state?: UserStates
  target?: HTMLAttributeAnchorTarget
  displayId?: string
  isExternal?: boolean
  href: string
}

export const UserLinkView = ({
  id,
  name,
  state,
  avatarUrl,
  target,
  displayId,
  isExternal,
  href,
}: UserLinkViewProps) => {
  const { anchorProps, avatarProps, titleProps, tooltip } = useUserStateTooltip(state)
  const LinkComponent = isExternal ? ExternalLink : InternalLink

  return (
    <LinkComponent href={href} target={target}>
      <HStack space="s-8" {...anchorProps} align="center">
        <Avatar size={24} image={avatarUrl} uuid={id} {...avatarProps}>
          {abbreviate(name)}
        </Avatar>
        <Text whiteSpace="nowrap" {...titleProps}>
          {name}
          {displayId ? `(${displayId})` : null}
        </Text>
      </HStack>
      {tooltip}
    </LinkComponent>
  )
}
