import { Box, TableFilters, copyToClipboard, useIntersectViewport } from '@revolut/ui-kit'
import { useQueryDartAuditLogs } from 'queries/dart/logs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EntitiesTable } from 'components/EntitiesTable'
import { useMapify } from 'hooks/useMapify'
import { useSideBox } from 'view/SideBox/SideBox'
import { CalendarFilter, DateFilterValue } from 'components/CalendarFilter'
import { SideAvatar } from 'view/SideBox/SideAvatar'
import { getActionIcon } from 'view/AuditLogs/utils'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { formatDateTime } from 'utils/date'
import { formatLogs, getRowSubtitle } from './utils'
import { getColumns } from './columns'
import { AuditLogSide } from '../AuditLogSide'
import { DataMaps, AuditLogRow } from './types'

type AuditLogViewProps = {
  isAllDataSettled: boolean
  dataMaps: DataMaps
  modelId?: string
}

export const AuditLogsTable = ({
  isAllDataSettled,
  dataMaps,
  modelId,
}: AuditLogViewProps) => {
  const { openSide } = useSideBox()

  const [dateFilter, setDateFilter] = useState<DateFilterValue>(null)

  const limits = useMemo(
    () =>
      dateFilter
        ? {
            from: dateFilter.from.getTime(),
            to: dateFilter.to.getTime(),
          }
        : undefined,
    [dateFilter],
  )

  const {
    data: auditLogs,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status: logsStatus,
  } = useQueryDartAuditLogs({ modelId, limits })
  const [filters, setFilters] = useState<TableFilters<AuditLogRow>>([])

  const filterMap = useMapify(filters, (f) => f.id)
  const logItems = useMemo(
    () => (auditLogs?.pages || []).flatMap((page) => page.data),
    [auditLogs],
  )
  const logs = useMemo(
    () => formatLogs(logItems, filterMap, dataMaps, limits),
    [logItems, filterMap, dataMaps, limits],
  )

  // load data ourself because of bug in Table
  // DEUI-2677
  const [isIntersected, setIntersected] = useState(false)
  const afterRef = useRef(null)
  useIntersectViewport(afterRef, setIntersected)
  useEffect(() => {
    if (isIntersected && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [isIntersected, hasNextPage, isFetchingNextPage, fetchNextPage])

  const columns = useMemo(() => getColumns(isAllDataSettled), [isAllDataSettled])

  const onLogItemClick = useCallback(
    (row: AuditLogRow) => {
      openSide({
        title: row.action,
        subtitle: getRowSubtitle(row),
        description: formatDateTime(row.date),
        body: <AuditLogSide logItem={row.logItem} />,
        avatar: (
          <SideAvatar
            iconName={getActionIcon(row.logItem.Action.value)}
            onDoubleClick={() => copyToClipboard(JSON.stringify(row.logItem))}
          />
        ),
      })
    },
    [openSide],
  )

  const loadingStatus = logs.length ? 'success' : logsStatus

  const dataLabels = useMemo(
    () => [
      {
        value: logItems.length,
        label: 'Loaded',
      },
    ],
    [logItems.length],
  )

  return (
    <>
      <EntitiesTable
        key={String(isAllDataSettled)}
        entitiesTypeLabel="Logs"
        pluralForms={['log', 'logs']}
        columns={columns}
        data={logs}
        loadingState={getLoadingState(loadingStatus, logs.length)}
        searchAutoFocus
        onFilter={setFilters}
        manualFilters
        hasMore={hasNextPage}
        onRequestMore={fetchNextPage}
        dataLabels={dataLabels}
        onRowClick={onLogItemClick}
        renderFiltersLeft={() => (
          <CalendarFilter value={dateFilter} onChange={setDateFilter} exceptFuture />
        )}
      />
      <Box ref={afterRef} />
    </>
  )
}
