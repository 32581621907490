import { DataLabel } from 'components/EntitiesTable/types'

type GetLabelsParams = {
  total?: number
  showed?: number
  selected?: number
  dataLabels?: DataLabel[]
}

const TOTAL_ARIA_LABEL = 'total count'
const SELECTED_ARIA_LABEL = 'selected count'

export const getLabels = ({ total, selected, dataLabels }: GetLabelsParams) => {
  const result: DataLabel[] = []

  if (typeof total === 'number') {
    result.push({
      label: 'Total',
      value: total,
      ariaLabel: TOTAL_ARIA_LABEL,
    })
  }

  if (selected) {
    result.push({
      label: 'Selected',
      value: selected,
      ariaLabel: SELECTED_ARIA_LABEL,
    })
  }

  if (dataLabels?.length) {
    result.push(...dataLabels)
  }

  return result
}

export const __test_only = {
  TOTAL_ARIA_LABEL,
  SELECTED_ARIA_LABEL,
}
