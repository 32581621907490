import React, { PropsWithChildren, useCallback, useContext, useState } from 'react'
import { isEmpty } from 'lodash'
import { Header, Side, Token } from '@revolut/ui-kit'
import { useLocation } from 'react-router'
import { useSideboxNavigation } from './hooks/useSideboxNavigation'
import { useSideBoxAutoclose } from './hooks/useSideBoxAutoclose'
import {
  ErrorSideboxOpenParams,
  LoadingSideboxOpenParams,
  SideBoxType,
  SideboxOpenParams,
} from './types'
import { useOpenErrorSide, useOpenLoadingSide, useOpenSide } from './hooks/useOpenSidebox'

type SideState = {
  openSide: (values: SideboxOpenParams) => void
  openLoadingSide: (params: LoadingSideboxOpenParams) => void
  openErrorSide: (params: ErrorSideboxOpenParams) => void
  closeSide: () => void
  ready: boolean
  sidebox?: SideBoxType
}

const SideBoxContext = React.createContext<SideState>({
  sidebox: undefined,
  ready: false,
  openSide: () => {},
  closeSide: () => {},
  openLoadingSide: () => {},
  openErrorSide: () => {},
})

export function useSideBox() {
  return useContext(SideBoxContext)
}

export const SideBoxProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sidebox, setSidebox] = useState<SideBoxType | undefined>()
  const locationPath = useLocation().pathname

  const openSide = useOpenSide({ setSidebox, locationPath })
  const openLoadingSide = useOpenLoadingSide({ setSidebox, locationPath })
  const openErrorSide = useOpenErrorSide({ setSidebox, locationPath })

  const closeSide = useCallback(() => {
    setSidebox(undefined)
  }, [setSidebox])

  return (
    <SideBoxContext.Provider
      value={{
        sidebox,
        openSide,
        closeSide,
        ready: true,
        openLoadingSide,
        openErrorSide,
      }}
    >
      {children}
    </SideBoxContext.Provider>
  )
}

export const SideBox = () => {
  const { sidebox, closeSide, openSide, ready } = useSideBox()
  const processClose = useCallback(() => {
    sidebox?.onClose?.()
    closeSide()
  }, [sidebox, closeSide])

  useSideboxNavigation({
    openSide,
    sidebox,
    ready,
  })

  useSideBoxAutoclose({
    sidebox,
    ready,
    closeSide: processClose,
  })

  return (
    <>
      <Side
        open={!!sidebox}
        onClose={processClose}
        variant="wide"
        resizable={sidebox?.resizable}
      >
        <Header variant="item">
          <Header.CloseButton aria-label="Close" color={Token.color.foreground} />
          <Header.Title>{sidebox?.title}</Header.Title>
          {!isEmpty(sidebox?.avatar) && <Header.Avatar>{sidebox?.avatar}</Header.Avatar>}
          {!isEmpty(sidebox?.subtitle) && (
            <Header.Subtitle>{sidebox?.subtitle}</Header.Subtitle>
          )}
          {!isEmpty(sidebox?.description) && (
            <Header.Description>{sidebox?.description}</Header.Description>
          )}
          {sidebox?.actions && <Header.Bar>{sidebox?.actions}</Header.Bar>}
        </Header>
        {sidebox?.body}
      </Side>
    </>
  )
}
