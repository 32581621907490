import { Client } from 'api/idave/clients'
import { Permission } from 'api/idave/permissions'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { Row } from './types'

type GetRowsParams = {
  permissionIds: string[]
  permissionMap: Map<string, Permission>
  clientMap: Map<string, Client>
}
export const getRows = ({
  permissionIds,
  permissionMap,
  clientMap,
}: GetRowsParams): Row[] =>
  permissionIds.map((id) => {
    const permission = permissionMap.get(id)
    if (!permission) {
      return { id, name: id, client: EMPTY_VALUE_FALLBACK }
    }

    return {
      id,
      name: permission.name,
      client: permission.client,
      clientName: clientMap.get(permission.client)?.name,
      description: permission.description,
    }
  })
