import { isEqual } from 'lodash'
import { Query } from '@tanstack/react-query'

export const makeInvalidationPredicate = <Params>(key: string, params: Params[][]) => {
  const queryParams = params.map((param) => [key, ...param])

  return (query: Query) => {
    return !!queryParams.find((item) => isEqual(item, query.queryKey))
  }
}
