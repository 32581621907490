import { useState } from 'react'
import { useInitTableNavigation } from './hooks/useInitTableNavigation'
import { UseTableNavigationParams } from './types'
import { useUpdateTableNavigation } from './hooks/useUpdateTableNavigation'

export const useTableNavigation = <Entity extends {}>(
  navigationParams: UseTableNavigationParams<Entity>,
) => {
  const [init, setInit] = useState(false)

  useInitTableNavigation(navigationParams, setInit, init)
  useUpdateTableNavigation(navigationParams, init)
}
