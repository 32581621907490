import axios from 'axios'

export type SamTraining = {
  code: string
  name: string
}

export type SamTrainingsList = {
  data: SamTraining[]
}

export const trainings = {
  getTrainingsList: () => axios.get<SamTrainingsList>('/sam-api/api/trainings/'),
}
