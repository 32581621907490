import { useCallback, useEffect, useState } from 'react'
import { useSideBox } from 'view/SideBox/SideBox'
import { SideAvatar } from 'view/SideBox'
import { ROLE_SIDE } from 'view/SideBox/hooks/useSideboxNavigation'
import { Actions } from './components/Actions'
import { Body } from './components/Body'
import { Title } from './components/Title'

export const useRolePreview = () => {
  const { openSide } = useSideBox()
  const [params, setParams] = useState<{ roleId?: string; userId?: string }>()
  const onClose = useCallback(() => {
    setParams(undefined)
  }, [setParams])

  useEffect(() => {
    if (!params) {
      return undefined
    }

    const { roleId, userId } = params

    if (!roleId) {
      return undefined
    }

    const queryParams = {
      type: ROLE_SIDE,
      id: roleId,
      subjectId: userId,
    }

    return openSide({
      body: <Body roleId={roleId} userId={userId} />,
      title: <Title roleId={roleId} />,
      avatar: <SideAvatar iconName="GroupVault" />,
      actions: <Actions roleId={roleId} />,
      onClose,
      queryParams,
    })
  }, [params, onClose, openSide])

  return {
    openRoleSide: (roleId?: string, userId?: string) => setParams({ roleId, userId }),
  }
}
