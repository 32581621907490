import { orderBy } from 'lodash'
import { EmployeeDetails } from 'api/dart/employee'
import { HIDDEN_DATA_FALLBACK } from 'constants/string'
import { AccessModifierLimited } from 'api/dart/types'

const getDescription = (item: AccessModifierLimited): string => {
  let description = item.entityId
    ? `Customer ID: ${item.entityId.id}` || HIDDEN_DATA_FALLBACK
    : 'No access to customer companies'

  if (item.justification) {
    description += `\n${item.justification}`
  }

  return description
}

export const getItems = (details: EmployeeDetails | undefined) => {
  if (!details) {
    return []
  }

  const filteredAndSorted = orderBy(
    details.accessModifiers.filter((item) => item.type === 'DENIED'),
    ['entityId', 'expirationDate'],
    ['desc', 'asc'],
  )

  return filteredAndSorted.map((item) => ({
    id: item.id,
    title: item.entityId ? 'Limited customer access' : 'Blocked access',
    entityId: item.entityId?.id,
    description: getDescription(item),
  }))
}
