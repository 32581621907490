import { StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'

export const EmptyRequestedDetails = () => {
  return (
    <StatusWidget>
      <StatusWidget.Image {...get3DImageSrcSetProps('3D298', 'v2')} />
      <StatusWidget.Title>Details will remain the same</StatusWidget.Title>
      <StatusWidget.Description>
        There aren&apos;t changes in details
      </StatusWidget.Description>
    </StatusWidget>
  )
}
