import {
  CrossCheck,
  CrossCheckAddReviewerPayload,
  CrossCheckCommentPayload,
  CrossCheckCreatedEvent,
  CrossCheckDeclinePayload,
  CrossCheckDetail,
  CrossCheckGroup,
  CrossCheckReviewPayload,
  CrossJustifyPayload,
} from 'api/types/crossCheck'
import axios from 'axios'

export const crossChecks = {
  getCrossChecksList: (params?: { reviewer?: string; requester?: string }) => {
    const { requester, reviewer } = params || {}
    // preventing to load too old x-checks for list
    const to = requester || reviewer ? undefined : Date.now()

    return axios.get<CrossCheck[]>('/dart-api/cross-checks', {
      params: {
        requester,
        reviewer,
        to,
      },
    })
  },

  getCrossCheckDetails: (id: string) =>
    axios.get<CrossCheckDetail>(`/dart-api/cross-checks/${id}`),

  getReviewGroup: (id: string) =>
    axios.get<CrossCheckGroup>(`/dart-api/cross-cheks/groups/${id}`),

  commentCrossCheck: (id: string, payload: CrossCheckCommentPayload) =>
    axios.post<CrossCheckCreatedEvent>(`/dart-api/cross-checks/${id}/comment`, payload),

  reviewCrossCheck: (id: string, payload: CrossCheckReviewPayload) =>
    axios.post<CrossCheckCreatedEvent>(`/dart-api/cross-checks/${id}/review`, payload),

  justifyCrossCheck: (id: string, payload: CrossJustifyPayload) =>
    axios.post<CrossCheckCreatedEvent>(`/dart-api/cross-checks/${id}/justify`, payload),

  addReviewer: (id: string, payload: CrossCheckAddReviewerPayload) =>
    axios.post<CrossCheckCreatedEvent>(
      `/dart-api/cross-checks/groups/${id}/reviewers`,
      payload,
    ),

  decline: (id: string, payload: CrossCheckDeclinePayload) =>
    axios.post<CrossCheckCreatedEvent>(`/dart-api/cross-checks/${id}/decline`, payload),

  retry: (id: string) => axios.post<{}>(`/dart-api/cross-checks/${id}/retry`),
}
