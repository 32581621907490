import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from 'helpers/configQuery'
import { CrossCheckSystem } from 'api/types/crossCheck'
import { useErrorToast } from 'hooks/useErrorToast'
import { getCrossCheckApi } from 'view/CrossChecks/utils'

export const useCrossCheckComment = (params: {
  source: CrossCheckSystem
  onSuccess?: () => void
  onError?: () => void
}) => {
  const { source, onError, onSuccess } = params

  const api = getCrossCheckApi(source)

  const queryClient = useQueryClient()

  const handler = useErrorToast()

  return useMutation({
    mutationFn: ({ id, comment }: { id?: string; comment: string }) => {
      if (id) {
        return api.commentCrossCheck(id, { comment })
      }

      return Promise.reject("Crosscheck id wasn't sent")
    },
    onSuccess: (_response, { id }) => {
      queryClient.invalidateQueries([QueryKey.CrossCheckDetails, id])
      queryClient.invalidateQueries([QueryKey.SamCrossCheckDetails, id])
      queryClient.invalidateQueries([QueryKey.DartCrossCheckDetails, id])
      onSuccess?.()
    },

    onError: (err) => {
      handler(err)
      onError?.()
    },
  })
}
