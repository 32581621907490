import { ReactNode, memo } from 'react'
import { Box, IconName, Subheader, Widget } from '@revolut/ui-kit'
import { useTokenize } from 'hooks/useTokenize'
import { TokensText, isTokensDisplayable } from 'components/TokensText'
import { UserStates } from 'api/idave/user'
import { UserItem } from 'components/UserItem'
import { SYSTEM_REQUESTERS } from 'view/CrossChecks/utils'
import { ClientItem } from 'components/ClientItem'

export const Justification = memo(
  (props: {
    children?: ReactNode
    text?: string
    userId?: string
    name: string
    photoUrl?: string
    icon?: IconName
    description?: string
    descriptionHref?: string
    state?: UserStates
    createdDate: number
  }) => {
    const {
      userId,
      description,
      name,
      photoUrl,
      text,
      icon,
      children,
      state,
      descriptionHref,
      createdDate,
    } = props

    const system = SYSTEM_REQUESTERS[name]

    return (
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Justification</Subheader.Title>
        </Subheader>

        <Widget>
          {system ? (
            <ClientItem id={system.id} name={system.name} createdDate={createdDate} />
          ) : (
            <UserItem
              name={name}
              description={description}
              descriptionHref={descriptionHref}
              state={state}
              id={userId}
              avatarUrl={photoUrl}
              icon={icon}
              createdDate={createdDate}
            />
          )}

          <JustificationContent text={text}>{children}</JustificationContent>
        </Widget>
      </Box>
    )
  },
)

const JustificationContent = (props: { children?: ReactNode; text?: string }) => {
  const { children, text } = props
  const { tokenize } = useTokenize()

  if (children) {
    return (
      <Box ml="s-16" mr="s-16" overflow="hidden" pb="s-16">
        {children || <TokensText tokens={tokenize(text)} />}
      </Box>
    )
  }

  const tokens = tokenize(text)
  if (isTokensDisplayable(tokens)) {
    return (
      <Box ml="s-16" mr="s-16" overflow="hidden" pb="s-16">
        <TokensText tokens={tokenize(text)} />
      </Box>
    )
  }

  return null
}
