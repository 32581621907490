import { Subheader, Box } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { Account } from 'api/idave/accounts'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { InternalLink } from 'components/Links/InternalLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { capitalizeFirst } from 'utils/string'

type Props = {
  account: Account
  client: Client
}
export const Info = ({ account, client }: Props) => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Details</Subheader.Title>
      </Subheader>
      <DetailsGroup
        hideEmpty
        rows={[
          [
            'App',
            <InternalLink
              href={generatePath(Url.Client, { clientId: client.id })}
              key={client.id}
            >
              {client.name}
            </InternalLink>,
          ],
          ['Account ID', <CopyableText value={account.id} key={account.id} />],
          ['Origin', capitalizeFirst(account.source)],
          ['Auth ID', account.authentication?.gcpSA],
        ]}
      />
    </Box>
  )
}
