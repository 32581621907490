import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { ActionResponse } from 'api/types/actionResponse'
import { AxiosError } from 'axios'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'react-toast-notifications'

export const useQuerySsoUser = () => {
  const { addToast } = useToasts()
  return useQuery({
    queryKey: [QueryKey.SsoUser],
    queryFn: idaveApi.auth.getSsoUser,
    select: (response) => response.data,
    onError: (error: AxiosError<ActionResponse>) => {
      if (error.response?.status !== 401) {
        const message =
          error.response?.data.message ?? 'Error while validating SSO session'
        addToast(message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    },
  })
}
