import { CrossCheckDetail, CrossCheckSystem, JustifyEvent } from 'api/types/crossCheck'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryEmployees } from 'queries/sam/users'
import { useQueryRevolutersDictionaries } from 'queries/sam/revoluters'
import { useQueryUsers } from 'queries/idave/users'
import { useMemo } from 'react'
import { getEventsByType } from 'view/CrossChecks/utils'
import { JustificationSkeleton } from './components/JustificationSkeleton'
import { Justification } from './components/Justification'
import { getUserData } from './utils'

export const CrossCheckJustification = (props: {
  crossCheck?: CrossCheckDetail
  source: CrossCheckSystem
}) => {
  const { source, crossCheck } = props
  const justification = useMemo(
    () => getEventsByType(props.crossCheck?.events || [], 'JUSTIFY')?.[0],
    [props.crossCheck],
  )
  const requesterId = crossCheck?.requester

  if (!requesterId || !justification) {
    return null
  }

  return <Inner source={source} justification={justification} requesterId={requesterId} />
}

const Inner = (props: {
  requesterId: string
  source: CrossCheckSystem
  justification: JustifyEvent
}) => {
  const { source, requesterId, justification } = props

  const { data: avatars } = useQueryMappedAvatars()
  const { data: employees } = useQueryEmployees()
  const { data: revolutStructure } = useQueryRevolutersDictionaries()
  const { data: users = [], status: qs, fetchStatus: fs } = useQueryUsers()

  const user = useMemo(
    () =>
      users.find(({ id, externalRef }) =>
        source === 'sam' ? externalRef?.reference === requesterId : id === requesterId,
      ),
    [source, users, requesterId],
  )

  const { text, createdDate } = justification
  const teams = revolutStructure?.teams

  const { name, photoUrl, team, teamHref, state } = getUserData({
    user,
    avatars,
    teams,
    requesterId,
    employees,
  })

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={user}
      renderLoading={JustificationSkeleton}
      renderError={() => (
        <Justification text={text} name={requesterId} createdDate={createdDate} />
      )}
      renderIdle={() => (
        <Justification text={text} name={requesterId} createdDate={createdDate} />
      )}
      renderSuccess={({ data }) => (
        <Justification
          text={text}
          userId={data.id}
          name={name}
          photoUrl={photoUrl}
          description={team}
          state={state}
          descriptionHref={teamHref}
          createdDate={createdDate}
        />
      )}
    />
  )
}
