import { ActionButton } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'

export const Actions = (props: { policyId: string }) => (
  <ActionButton
    useIcon="LinkExternal"
    use="a"
    target="_blank"
    href={generatePath(Url.SamPolicy, { policyId: props.policyId })}
  >
    Open policy
  </ActionButton>
)
