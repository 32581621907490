import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { makeMappedKeyDataHook } from 'queries/utils'
import useLazyQuery from '../useLazyQuery'

export const useQueryDeprecatedRoles = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.RolesDeprecated],
    queryFn: idaveApi.roles.getRoles,
    enabled: hasPermission(IDAVE_PERMISSIONS.ROLES_VIEW_LIST),
    select: (response) => response.data,
  })
}

export const useQueryRoles = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Roles],
    queryFn: idaveApi.roles.getRolesMVP,
    enabled: hasPermission(IDAVE_PERMISSIONS.ROLES_VIEW_LIST),
    select: (response) => response?.data,
  })
}

export const useQueryRoleIdMap = makeMappedKeyDataHook(useQueryRoles, 'id')

export const useQueryRole = (params: { roleId?: string }) => {
  const { hasPermission } = usePermissions()
  return useLazyQuery({
    params: params.roleId,
    queryKey: [QueryKey.Role, params.roleId],
    enabled: hasPermission(IDAVE_PERMISSIONS.ROLES_VIEW_LIST),
    fetchFn: (id) => idaveApi.roles.getRoleMVP(id),
  })
}

export const useQueryRolesByPermission = (permissionId: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.RolesByPermission, permissionId],
    queryFn: () => idaveApi.permissions.getRolesByPermission(permissionId),
    select: (response) => response.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.PERMISSIONS_VIEW_ROLES_LIST),
  })
}
