import { TextSkeleton } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryToxicPairsMap } from 'queries/idave/permissions'
import { InternalLink } from './InternalLink'

type Props = {
  id: string
}

export const ToxicPairLink = ({ id }: Props) => {
  const { data: toxicPairs, status: qs, fetchStatus: fs } = useQueryToxicPairsMap()

  const href = generatePath(Url.ToxicPair, { toxicPairId: id })
  const pair = toxicPairs?.get(id)

  return (
    <InternalLink href={href} title={pair?.name}>
      <QuerySwitch
        required={[{ qs, fs }]}
        data={pair}
        renderError={() => id}
        renderIdle={() => id}
        renderLoading={() => <TextSkeleton size={12} />}
        renderSuccess={({ data }) => data.name}
      />
    </InternalLink>
  )
}
