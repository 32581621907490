import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useErrorToast } from 'hooks/useErrorToast'
import React, { useMemo } from 'react'

export const QueryProvider = (props: { children: React.ReactNode }) => {
  const errorHandler = useErrorToast()

  /**
   * never recreating query client between renders
   * using initialDefaultHandler wrapped around addToast (which is stable)
   * may occure bugs if client will be updated during renders
   */
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            onError: errorHandler,
          },
          mutations: {
            onError: errorHandler,
          },
        },
      }),
    [errorHandler],
  )

  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
}
