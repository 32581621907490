import { DetailsCellSkeleton, Group } from '@revolut/ui-kit'
import { times } from 'lodash'

type Props = {
  cellCount?: number
}
export const DetailsGroupSkeleton = ({ cellCount = 3 }: Props) => (
  <Group>
    {times(cellCount, (idx) => (
      <DetailsCellSkeleton key={idx}>
        <DetailsCellSkeleton.Title />
        <DetailsCellSkeleton.Content />
      </DetailsCellSkeleton>
    ))}
  </Group>
)
