import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { UserListItem, UserListParams } from 'api/idave/user'
import { QueryKey } from 'helpers/configQuery'
import useLazyQuery from 'queries/useLazyQuery'
import { makeMappedKeyDataHook, makeMappedDataHook } from 'queries/utils'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getStoredUser } from 'services/auth'

export const useQueryUsers = () => {
  const { hasPermission } = usePermissions()

  return useQuery({
    queryKey: [QueryKey.Users],
    queryFn: () => idaveApi.user.getUserList(),
    select: (response) => response.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_LIST),
  })
}

export const useQueryUserIdMap = makeMappedKeyDataHook(useQueryUsers, 'id')
export const useQueryUserEmailMap = makeMappedKeyDataHook(useQueryUsers, 'email')
export const useQueryUserPeopleOpsIdMap = makeMappedDataHook(
  useQueryUsers,
  // as peopleOps <-> idave users is one <-> many relationship
  // and in case of collision of peopleOps ids for a few idave users last item wins
  // placing active users in back to choose active idave users if possible
  (items) => [...items].sort((a) => (a.state !== 'ACTIVE' ? -1 : 1)),
  (item: UserListItem) => item.externalRef?.reference,
)

export const useFilteredUsers = (params?: UserListParams) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Users, params, params?.roleId],
    queryFn: () => idaveApi.user.getUserList(params),
    select: (response) => response.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_LIST),
  })
}

export const useQueryUser = (params: { id?: string }) => {
  const { hasPermission } = usePermissions()
  return useLazyQuery({
    params: params.id,
    queryKey: [QueryKey.User, params.id],
    enabled: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_DETAILS),
    fetchFn: (id) => idaveApi.user.getUser(id),
  })
}

export const useCurrentUser = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.CurrentUser],
    queryFn: idaveApi.user.getCurrentUser,
    select: (response) => response?.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.USERS_CURRENT_VIEW_DETAILS),
  })
}

export const useCurrentUserId = () =>
  useQuery({
    queryKey: [QueryKey.CurrentUserId],
    queryFn: () => {
      try {
        const storedUser = getStoredUser()
        if (storedUser?.id) {
          return Promise.resolve({ data: { id: storedUser.id } })
        }
      } catch (_e) {
        return Promise.reject(new Error("User didn't log in"))
      }

      return Promise.reject(new Error("User didn't log in"))
    },
    select: (response) => response?.data.id,
  })
