import { Item, ActionButton, Box, Token, Avatar } from '@revolut/ui-kit'
import { ToxicPair } from 'api/idave/toxic'
import { useCallback } from 'react'
import { pluralForm } from 'utils/string'
import { ViewCase } from './types'
import { getAlertBannerText } from './utils'

export const RolePermissionAlertBanner = ({
  toxicPair,
  viewCase,
  openToxicSide,
}: {
  toxicPair: ToxicPair[]
  viewCase: ViewCase
  openToxicSide: (toxicPairIds: string[]) => void
}) => {
  const onButtonClick = useCallback(
    () => openToxicSide(toxicPair.map((i) => i.id)),
    [toxicPair, openToxicSide],
  )
  if (!toxicPair.length) {
    return null
  }
  const { title, description } = getAlertBannerText(viewCase)
  return (
    <Box mb="s-16">
      <Item>
        <Item.Avatar>
          <Avatar useIcon="ExclamationTriangle" size={40} color={Token.color.red} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description>{description}</Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton onClick={onButtonClick}>
            {pluralForm(toxicPair.length, [
              `Show 1 toxic pair`,
              `Show ${toxicPair.length} toxic pair`,
            ])}
          </ActionButton>
        </Item.Side>
      </Item>
    </Box>
  )
}
