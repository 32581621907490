import { Group, Item, Subheader } from '@revolut/ui-kit'
import { SamPolicy } from 'api/sam/policies'
import { countedItems } from 'utils/string'
import { stringifySubjectType } from 'view/Sam/utils'

export const SamSidePolicyList = (props: { policies: SamPolicy[] }) => {
  return (
    <>
      <Subheader variant="nested">
        <Subheader.Title>
          {countedItems('Policies', props.policies.length)}
        </Subheader.Title>
      </Subheader>
      <Group>
        {props.policies.map((policy) => (
          <Item key={policy.policyId}>
            <Item.Content>
              <Item.Title>{policy.policyName}</Item.Title>
              <Item.Description>{`${stringifySubjectType(
                policy.subject.subjectType,
              )} policy`}</Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Group>
    </>
  )
}
