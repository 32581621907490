import { ActionButton, Group, Item } from '@revolut/ui-kit'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'

type ClientUrlListProps = {
  urls: string[]
  allowUpdate: boolean
  onAddClick: () => void
  deleteUrl: (url: string) => void
}

export const ClientUrlList = (props: ClientUrlListProps) => {
  const { urls, onAddClick, deleteUrl, allowUpdate } = props

  return urls.length ? (
    <ClientUrlsItems
      onAddClick={onAddClick}
      deleteUrl={deleteUrl}
      urls={urls}
      allowUpdate={allowUpdate}
    />
  ) : (
    <ClientUrlsEmpty onAddClick={onAddClick} allowUpdate={allowUpdate} />
  )
}

const ClientUrlsEmpty = (props: { onAddClick: () => void; allowUpdate: boolean }) => (
  <EmptyStatusWidget
    onClick={props.allowUpdate ? props.onAddClick : undefined}
    actionLabel={props.allowUpdate ? 'Add URL' : undefined}
    actionAllowed={props.allowUpdate}
    title="No URLs added"
    imageCode="3D094"
    imageVersion="v2"
  />
)

const ClientUrlsItems = (props: {
  onAddClick: () => void
  deleteUrl: (url: string) => void
  urls: string[]
  allowUpdate: boolean
}) => {
  const { onAddClick, deleteUrl, urls, allowUpdate } = props

  return (
    <Group>
      {urls.map((url) => (
        <Item key={url}>
          <Item.Content>
            <Item.Title>{url}</Item.Title>
          </Item.Content>

          {allowUpdate && (
            <Item.Side>
              <ActionButton
                useIcon="Delete"
                onClick={() => deleteUrl(url)}
                label="delete"
              />
            </Item.Side>
          )}
        </Item>
      ))}

      {allowUpdate && (
        <Item>
          <Item.Content>
            <ActionButton onClick={onAddClick} useIcon="Plus">
              Add URL
            </ActionButton>
          </Item.Content>
        </Item>
      )}
    </Group>
  )
}
