import { Role } from 'api/idave/roles'
import { AssignedRole, CrossCheckDetail } from 'api/types/crossCheck'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { User, UserRole } from 'api/idave/user'
import { mapify } from 'utils/array'
import { RoleRow } from './types'
import { CrossCheckValueTab } from '../../../../types'
import { extractEntityArrayIds } from '../../../../utils'
import { getChangeRowsInfo } from '../../utils'

type GetRolesListParams = {
  crossCheck: CrossCheckDetail
  roles?: Role[]
  user?: User
  tabs: CrossCheckValueTab[]
}

export const getRoleList = (params: GetRolesListParams) => {
  const { crossCheck, user, roles = [], tabs } = params
  const { executableParams } = crossCheck
  const userRoles = user?.roles || []

  const current = userRoles.map(({ roleId }) => roleId) || []

  const added = extractEntityArrayIds(executableParams?.Roles)
  const addedHash = toPresenceMap(added)

  // Role can't be removed through x-check, only assign is available
  const deleted: string[] = []
  const deletedHash: Record<string, boolean> = {}

  // User can't be created with roles through x-check, only assign is available
  const values: string[] = []

  const roleMap = mapify(roles, ({ id }) => id)
  const assignTimings = getAssignTimings(userRoles, executableParams?.Roles?.value)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    roleMap,
    makeMapValue,
    assignTimings,
  })
}

const getAssignTimings = (userRoles: UserRole[], roles?: AssignedRole[]) => {
  const current = userRoles.reduce((timings, { roleId, expiryDate }) => {
    return {
      ...timings,
      [roleId]: expiryDate,
    }
  }, {})
  const added = (roles || []).reduce((timings, { value }) => {
    return {
      ...timings,
      [value.id]: value.expiryDate,
    }
  }, {})

  return {
    ...current,
    ...added,
  }
}

type MakeGetPermissionRowParams = {
  roleMap: Map<string, Role>
  assignTimings: Record<string, number | undefined>
  addedHash: Record<string, boolean>
}

const makeMapValue =
  ({ roleMap, addedHash, assignTimings }: MakeGetPermissionRowParams) =>
  (roleId: string): RoleRow => {
    const role = roleMap.get(roleId)

    const status = addedHash[roleId] ? 'added' : undefined

    return {
      id: roleId,
      name: role?.name || roleId,
      expiryDate: assignTimings[roleId],
      status,
    }
  }

export const getEmptyRoleLabel = (status: CrossCheckValueTab) =>
  status === 'requested'
    ? "There aren't added or deleted roles"
    : 'No roles for the employee'
