import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { EmployeeCompany } from 'api/dart/employeeCompanies'
import { mapify } from 'utils/array'
import { capitalizeFirst } from 'utils/string'
import { CustomerCompany } from '../../types'

export const getAllowedCompanyModifiers = (employeeCompany?: EmployeeCompany) =>
  (employeeCompany?.accessModifiers || []).reduce<
    { companyId: string; accessModifierId: string }[]
  >((result, access) => {
    if (access.type === 'ALLOWED' && access.entityId?.type === 'COMPANY') {
      return [...result, { companyId: access.entityId.id, accessModifierId: access.id }]
    }

    return result
  }, [])

export const getRows = (
  employeeCompany?: EmployeeCompany,
  customerCompanies: CustomerCompanyItem[] = [],
) => {
  const allowedCompanyModifiers = getAllowedCompanyModifiers(employeeCompany)
  const allowedCompanyMap = mapify(allowedCompanyModifiers, (a) => a.companyId)
  const allowedCompanyIds = allowedCompanyModifiers.map((i) => i.companyId)

  const customerCompanyMap = mapify(customerCompanies, (e) => e.id)
  const customerCompanyIds = customerCompanies.map((i) => i.id)

  const companiesIds = new Set(allowedCompanyIds.concat(customerCompanyIds))

  return {
    allowedEntitiesCount: allowedCompanyModifiers.length,
    entities: [...companiesIds].map((companyId): CustomerCompany => {
      const customerCompany = customerCompanyMap.get(companyId)
      return {
        id: companyId,
        name: customerCompany?.name || companyId,
        region: customerCompany?.region,
        serviceType: customerCompany
          ? capitalizeFirst(customerCompany.serviceType)
          : undefined,
        accessModifierId: allowedCompanyMap.get(companyId)?.accessModifierId,
      }
    }),
  }
}
