import { useToasts } from 'react-toast-notifications'
import { Button, TextButton, VStack } from '@revolut/ui-kit'
import idaveApi from 'api/idave'
import { SsoUser } from 'api/idave/auth'
import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'
import { confirmSignIn } from 'services/auth'

type IdaveSsoProps = {
  user?: SsoUser['user']
  onCancel: () => void
}

export const IdaveSso = ({ user, onCancel }: IdaveSsoProps) => {
  const { addToast } = useToasts()
  const { mutate: updateSession, isIdle } = useMutation({
    mutationFn: idaveApi.auth.postSsoAuth,
    onSuccess: () => {
      confirmSignIn()
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      if (error.response?.status !== 401) {
        const message =
          error.response?.data.message ?? 'Error while validating SSO session'
        addToast(message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }

      onCancel()
    },
  })

  return (
    // VStack has display: flex, but "alignItems" is not exposed
    <VStack space="s-16" style={{ alignItems: 'center' }}>
      <Button onClick={() => updateSession()} pending={!user || !isIdle} autoFocus>
        Continue as {user?.firstName} {user?.lastName}
      </Button>
      {user && (
        <TextButton onClick={onCancel}>Not you? Log in as a different user</TextButton>
      )}
    </VStack>
  )
}
