import { EmployeeDetails } from 'api/dart/employee'
import { notNullable } from 'utils/common'

const BLOCKED_ACCESS_ITEM = {
  title: 'Blocked access',
  description: 'No access to customer companies',
}

export const getItems = (details: EmployeeDetails | undefined) => {
  if (!details) {
    return []
  }

  const hasBlockedAccessModifier = details.accessModifiers.some(
    ({ type, entityId }) => type === 'DENIED' && !entityId,
  )
  const limitedAccessModifiers = details.accessModifiers.filter(
    ({ type, entityId }) => type === 'DENIED' && entityId,
  )

  return [
    hasBlockedAccessModifier ? BLOCKED_ACCESS_ITEM : undefined,
    {
      title: 'Limited customer access',
      description: `The user has ${limitedAccessModifiers.length} customer access restrictions`,
    },
  ].filter(notNullable)
}
