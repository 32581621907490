import { Cell, HStack, Text, Token } from '@revolut/ui-kit'
import {
  CrossCheckEvent,
  CrossCheckGroup,
  CrossCheckRule as CrossCheckRuleType,
} from 'api/types/crossCheck'
import { CheckSuccess, CrossCircle, RadiobuttonOff } from '@revolut/icons'
import { getRuleAggregation, getRulePoints } from '../utils'
import { RuleState } from '../types'

const CrossCheckRuleIcon = (props: { state: RuleState }) => {
  switch (props.state) {
    case 'FAIL':
      return <CrossCircle color={Token.color.red} size={16} />
    case 'OK':
      return <CheckSuccess color={Token.color.green} size={16} />
    case 'PENDING':
    default:
      return <RadiobuttonOff size={16} />
  }
}

export const CrossCheckRule = (props: {
  rule: CrossCheckRuleType
  groups: CrossCheckGroup[]
  events: CrossCheckEvent[]
}) => {
  const { rule, groups, events } = props
  const rulePoints = getRulePoints({
    events,
    groups,
    rule,
  })

  const ruleAggregation = getRuleAggregation(rulePoints)

  return (
    <>
      {ruleAggregation ? (
        <Cell pt="s-8" pb="s-8">
          <HStack space="s-8">
            <CrossCheckRuleIcon state={ruleAggregation.state} />
            <Text>{ruleAggregation.text}</Text>
          </HStack>
        </Cell>
      ) : null}
      {rulePoints.map(({ state, text }, idx) => (
        <Cell key={idx} pt="s-8" pb="s-8">
          <HStack space="s-8" pl={ruleAggregation ? 's-16' : undefined}>
            <CrossCheckRuleIcon state={state} />
            <Text>{text}</Text>
          </HStack>
        </Cell>
      ))}
    </>
  )
}
