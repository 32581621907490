import { Box, Subheader, Table } from '@revolut/ui-kit'
import { AuditLogData } from 'api/dart/auditLog'
import { getItems } from './utils'
import { COLUMNS } from './constants'

export const AuditLogAccessModifiers = ({ log }: { log: AuditLogData }) => {
  const accessModifiers = log['Access Modifiers']

  if (!accessModifiers || !accessModifiers.value.length) {
    return null
  }

  const items = getItems(accessModifiers)

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Access modifiers</Subheader.Title>
      </Subheader>

      <Table columns={COLUMNS} data={items} />
    </Box>
  )
}
