import { CrossCheckDetail } from 'api/types/crossCheck'

export const getAccessModifiersInfo = (crossCheck: CrossCheckDetail) => {
  const accessModifiers =
    crossCheck.executableParams['Access Modifiers']?.value.map((v) => v.value) || []

  // assuming that all the modifiers are of the same type (ALLOWED | DENIED)
  // and with the same entity type (CUSTOMER | COMPANY)
  const firstAccessModifier = accessModifiers?.[0]

  const actionType = [
    'DartEmployeeAddAccessModifiers',
    'DartEmployeeCompanyAddAccessModifiers',
  ].includes(crossCheck.executableName)
    ? 'added'
    : 'removed'

  return { accessModifiers, firstAccessModifier, actionType }
}
