import { useMutation, useQueryClient } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { useEditPopup } from 'hooks/useEditPopup'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'
import { pluralForm } from 'utils/string'
import { AccessModifierPayload } from 'api/dart/types'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { DART_PERMISSIONS } from 'security'
import { getAccessModifiers } from './utils'
import { AddCompanyPopup, AddCompanyPopupData } from './components'

export const useAddCompanies = (employeeId: string) => {
  const { setPopup, closePopup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { toCrosscheck, isCrossCheck } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEES_UPDATE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEES_UPDATE_ACCESS_MODIFIER_CROSS_CHECK,
  )

  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { mutate: submitModifiers } = useMutation({
    mutationFn: (modifiers: AccessModifierPayload[]) =>
      dartApi.employee.addModifiers({
        payload: modifiers.map((accessModifier) => ({ employeeId, accessModifier })),
        crossCheck: isCrossCheck,
      }),
    onMutate(modifiers) {
      showLoadingPopup({
        title: pluralForm(modifiers, ['Access adding', 'Accesses adding']),
      })
    },
    onSuccess(response, modifiers) {
      const crosscheckId = getCrossCheckIdFromResponse(response)
      hideLoadingPopup()
      closePopup()
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        showSuccessToast(pluralForm(modifiers, ['Access added', 'Accesses added']))
        queryClient.invalidateQueries([QueryKey.EmployeeDetails, employeeId])
      }
    },
    onError(error) {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Access addition failed',
        error,
      })
    },
  })

  const addModifiers = useCallback(
    (params: AddCompanyPopupData) => {
      submitModifiers(getAccessModifiers(params))
    },
    [submitModifiers],
  )

  const onAddCompanies = useCallback(() => {
    setPopup({
      title: 'Add companies',
      content: <AddCompanyPopup employeeId={employeeId} add={addModifiers} />,
      focusTrap: false,
    })
  }, [addModifiers, employeeId, setPopup])

  return { onAddCompanies }
}

export const useDeleteCompany = (employeeId: string) => {
  const { setPopup, closePopup } = useConfirmationPopup()
  const { showSuccessToast } = useToasts()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const queryClient = useQueryClient()
  const { toCrosscheck, isCrossCheck } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
  )
  const { mutate: deleteModifier } = useMutation({
    mutationFn: (modifiersIds: string[]) =>
      dartApi.employee.deleteModifiers({
        payload: modifiersIds,
        crossCheck: isCrossCheck,
      }),
    onMutate(modifiers) {
      showLoadingPopup({
        title: pluralForm(modifiers, ['Access deleting', 'Accesses deleting']),
      })
    },
    onSuccess(response) {
      const crosscheckId = getCrossCheckIdFromResponse(response)
      hideLoadingPopup()
      closePopup()
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        showSuccessToast('Access deleted')
        queryClient.invalidateQueries([QueryKey.EmployeeDetails, employeeId])
        queryClient.invalidateQueries([QueryKey.DirectRelations])
      }
    },
    onError(error) {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Access deletion failed',
        error,
      })
      closePopup()
    },
  })

  const onDeleteCompany = useCallback(
    (modifierId: string) => {
      setPopup({
        title: 'Are you sure you want to delete access to customer company?',
        message: 'User will no longer have access to deleted customer company data.',
        confirmButtonText: 'Delete',
        onConfirmClick: () => deleteModifier([modifierId]),
      })
    },
    [setPopup, deleteModifier],
  )

  return { onDeleteCompany }
}
