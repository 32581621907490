import { Table, TableColumn, Text, Token, chain } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { capitalizeFirst, pluralForm } from 'utils/string'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { differenceInDays, format } from 'date-fns'
import { RoleRow } from './types'
import { CrossCheckValueTab } from '../../../../types'
import { isComparable } from '../../../ChangeSwitcher/utils'

export const getColumns = (
  status: CrossCheckValueTab,
  crossCheck: CrossCheckDetail,
): TableColumn<RoleRow>[] =>
  status === 'requested' && isComparable(crossCheck) && !isFinished(crossCheck)
    ? [...BASE_COLUMNS, ...STATE_COLUMNS]
    : BASE_COLUMNS

const BASE_COLUMNS: TableColumn<RoleRow>[] = [
  {
    Header: 'Role Name',
    accessor: 'name',
  },
  {
    Header: 'Granted until',
    accessor: ({ expiryDate }) =>
      expiryDate ? format(expiryDate, 'dd-MM-yyyy') : EMPTY_VALUE_FALLBACK,
    Cell: (params: { row: { original: RoleRow } }) => {
      const dateValue = params.row.original.expiryDate
      if (!dateValue) {
        return <Table.Cell>{EMPTY_VALUE_FALLBACK}</Table.Cell>
      }

      const date = format(dateValue, 'dd-MM-yyyy')

      const daysCount = differenceInDays(dateValue, Date.now())
      const daysLeft = pluralForm(daysCount, [
        `${daysCount} day left`,
        `${daysCount} days left`,
      ])

      return (
        <Table.Cell>
          {chain(
            <Text>{date}</Text>,
            daysCount > 0 ? <Text color={Token.color.grey50}>{daysLeft}</Text> : null,
          )}
        </Table.Cell>
      )
    },
  },
]

const STATE_COLUMNS: TableColumn<RoleRow>[] = [
  {
    Header: 'Change',
    accessor: ({ status }: RoleRow) =>
      status ? capitalizeFirst(status) : EMPTY_VALUE_FALLBACK,
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
