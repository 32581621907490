import { SelectEntitiesTableOverlay } from 'components/EntitiesTable'
import {
  useQueryPermissionIdMap,
  useQueryPermissions,
  useQueryToxicPairs,
} from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useCallback, useMemo, useState } from 'react'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { waitForAll } from 'utils/query'
import { Text } from '@revolut/ui-kit'
import { makeGetToxicRowState } from 'view/Roles/Role/utils'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { notNullableMap } from 'utils/array'
import { Permission } from 'api/idave/permissions'
import { getAddingColumns } from './columns'
import { PermissionRow } from './types'
import { getRows } from './utils'
import { getInheritedPermissions } from '../../../utils'

export const RoleCreateAddPermissions = (props: {
  permissionIds: string[]
  roleName: string
  parent?: string
  addPermissions: (permissionIds: string[]) => void
  closeAdding: () => void
}) => {
  const { closeAdding, addPermissions, roleName, permissionIds, parent } = props
  const {
    data: permissions = [],
    status: permissionsQS,
    fetchStatus: permissionsFS,
  } = useQueryPermissions()
  const { data: permissionMap = new Map<string, Permission>() } =
    useQueryPermissionIdMap()

  const {
    data: clientMap = new Map(),
    status: clientQS,
    fetchStatus: clientFS,
  } = useQueryClientIdMap()

  const permissionsToAdd = useMemo(() => {
    const permissionPresence = toPresenceMap(permissionIds)
    return notNullableMap(permissions, ({ id }) =>
      !permissionPresence[id] ? id : undefined,
    )
  }, [permissionIds, permissions])

  const rows = useMemo(
    () =>
      getRows({
        permissionIds: permissionsToAdd,
        permissionMap,
      }),
    [permissionMap, permissionsToAdd],
  )
  const onSelect = useCallback(
    (items: PermissionRow[]) => addPermissions(items.map((r) => r.id)),
    [addPermissions],
  )

  const loadingState = getLoadingState(
    waitForAll(
      [{ qs: permissionsQS, fs: permissionsFS }],
      [
        {
          qs: clientQS,
          fs: clientFS,
        },
      ],
    ),
    rows.length,
  )

  const [selected, setSelected] = useState<string[]>([])

  const { data: toxicPermissions = [] } = useQueryToxicPairs()
  const { data: roleMap = new Map() } = useQueryRoleIdMap()
  const allPermissions = useMemo(
    () =>
      getInheritedPermissions({
        parent,
        roleMap,
      })
        .concat(permissionIds)
        .concat(selected),
    [parent, roleMap, permissionIds, selected],
  )

  const getRowState = useMemo(
    () => makeGetToxicRowState(allPermissions, toxicPermissions),
    [allPermissions, toxicPermissions],
  )
  const columns = useMemo(() => getAddingColumns(clientMap), [clientMap])

  return (
    <SelectEntitiesTableOverlay
      entitiesTypeLabel="Permissions"
      pluralForms={['permission', 'permissions']}
      entities={rows}
      columns={columns}
      title="Add permissions"
      SubtitleComponent={() => <Text>{roleName}</Text>}
      onSelect={onSelect}
      onSelectUpdate={setSelected}
      onClose={closeAdding}
      loadingState={loadingState}
      getRowState={getRowState}
    />
  )
}
