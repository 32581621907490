import { Box, Subheader } from '@revolut/ui-kit'
import { useQueryUser } from 'queries/idave/users'
import { useQueryEmployeeDetails } from 'queries/dart/employees'
import { DetailsGroup } from 'components/DetailsGroup'
import { QuerySwitch } from 'components/QuerySwitch'
import { User } from 'api/idave/user'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { EmployeeDetails } from 'api/dart/employee'
import { OrgLink } from 'components/Links/OrgLink/OrgLink'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { CopyableText } from 'components/CopyableText'
import { NOT_ALLOWED_TITLE } from '../../texts'
import { getDartCompanyType } from '../../utils'
import { DartCompany } from '../DartCompany'

export const UserIdaveProfile = ({
  userId,
  flexBasis,
}: {
  userId: string
  flexBasis: number
}) => {
  const { data: user, status: userS, fetchStatus: userFS } = useQueryUser({ id: userId })
  const {
    data: employeeDetails,
    status: employeeDetailsS,
    fetchStatus: employeeDetailsFS,
  } = useQueryEmployeeDetails(userId)

  return (
    <Box style={{ flexBasis: `${flexBasis}px`, flexGrow: 1 }}>
      <Subheader variant="nested">
        <Subheader.Title>Profile</Subheader.Title>
      </Subheader>
      <QuerySwitch
        required={[{ qs: userS, fs: userFS }]}
        optional={[{ qs: employeeDetailsS, fs: employeeDetailsFS }]}
        data={user}
        renderIdle={() => <NoAccessWidget title={NOT_ALLOWED_TITLE} />}
        renderLoading={() => <DetailsGroupSkeleton />}
        renderSuccess={({ data }) => (
          <Inner user={data} employeeDetails={employeeDetails} />
        )}
      />
    </Box>
  )
}

const Inner = ({
  user,
  employeeDetails,
}: {
  user: User
  employeeDetails?: EmployeeDetails
}) => {
  const dartCompanyType = getDartCompanyType(employeeDetails?.company)

  return (
    <DetailsGroup
      rows={[
        ['First Name', user.firstName],
        ['Last Name', user.lastName],
        [
          'User Name',
          <CopyableText value={user.username} key="username">
            {user.username}
          </CopyableText>,
        ],
        [
          'Email',
          <CopyableText value={user.email} key="email">
            {user.email}
          </CopyableText>,
        ],
        [
          'Organisation',
          <OrgLink orgId={user.organisationId} key={user.organisationId} />,
        ],
        dartCompanyType
          ? [
              dartCompanyType,
              <DartCompany
                company={employeeDetails?.company}
                key={employeeDetails?.company?.id}
              />,
            ]
          : null,
      ]}
    />
  )
}
