import { Box, StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'

type RequestErrorWidgetProps = {
  action?: () => void
  actionText?: string
  title?: string
}

export const RequestErrorWidget = ({
  action,
  actionText = 'Retry',
  title = 'Something went wrong',
}: RequestErrorWidgetProps) => (
  <Box maxWidth="1272px" data-testid="error-banner">
    <StatusWidget>
      <StatusWidget.Image {...get3DImageSrcSetProps('3D018', 'v2')} />
      <StatusWidget.Title>{title}</StatusWidget.Title>

      {action && <StatusWidget.Action onClick={action}>{actionText}</StatusWidget.Action>}
    </StatusWidget>
  </Box>
)
