import { Avatar, HStack, abbreviate, Text } from '@revolut/ui-kit'
import { User } from 'api/idave/user'
import { getName } from 'utils/string/getName'

export const UserAddRoleSubtitle = (props: { user: User }) => {
  const name = getName(props.user)
  return (
    <HStack space="s-8">
      <Avatar size={20}>{abbreviate(name)}</Avatar>
      <Text>{name}</Text>
    </HStack>
  )
}
