import { UseEmployeeRevoDescriptionResult } from 'hooks/useGetEmployeeRevoDescription'

const FALLBACK_SPECIALISATION = 'Unknown specialisation'
export const getUserFunction = (params: {
  employeeDescriptions: UseEmployeeRevoDescriptionResult
  userId: string
}) => {
  const {
    userId,
    employeeDescriptions: { status, getEmployeeDescription },
  } = params

  if (status === 'success') {
    return getEmployeeDescription(userId).spec?.name || FALLBACK_SPECIALISATION
  }
  return FALLBACK_SPECIALISATION
}

const FALLBACK_TEAM = 'Unknown team'
export const getUserTeam = (params: {
  userId: string
  employeeDescriptions: UseEmployeeRevoDescriptionResult
}) => {
  const {
    userId,
    employeeDescriptions: { status, getEmployeeDescription },
  } = params

  if (status === 'success') {
    return getEmployeeDescription(userId).team?.name || FALLBACK_TEAM
  }
  return FALLBACK_TEAM
}
