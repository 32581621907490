import { Search, TableWidget } from '@revolut/ui-kit'
import { useCallback, useRef } from 'react'

export const MAX_TABLE_SEARCH_LENGTH = 512

export const EntitiesListSearch = (props: {
  handler?: (v: string) => void
  value?: string
  autoFocus?: boolean
  resetKey?: number
  triggerSelected?: () => void
  showSelected?: boolean
}) => {
  const { handler, triggerSelected, value, autoFocus, resetKey, showSelected } = props
  const ref = useRef<HTMLInputElement>(null)

  const onChange = useCallback(
    (v: string) => {
      handler && handler(v)
      showSelected && triggerSelected?.()
    },
    [showSelected, handler, triggerSelected],
  )

  if (!handler) {
    return null
  }

  return (
    <TableWidget.Search>
      <Search
        key={resetKey}
        ref={ref}
        placeholder="Search"
        onChange={onChange}
        defaultValue={value || ''}
        role="search"
        autoFocus={autoFocus}
        maxLength={MAX_TABLE_SEARCH_LENGTH}
      />
    </TableWidget.Search>
  )
}
