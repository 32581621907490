import { Client } from 'api/idave/clients'
import { Permission } from 'api/idave/permissions'
import { Role } from 'api/idave/roles'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const getPermissionRows = (params: {
  role: Role
  clientMap: Map<string, Client>
  permissionMap: Map<string, Permission>
}) => {
  const { role, clientMap, permissionMap } = params

  return role.permissions.map((id) => {
    const permission = permissionMap.get(id)
    const client = permission ? clientMap.get(permission.client) : undefined

    return {
      id,
      name: permission?.name || id,
      client: client?.name || permission?.client || EMPTY_VALUE_FALLBACK,
      clientId: permission?.client,
    }
  })
}
