import Fuse from 'fuse.js'

const extractKeys = <T>(obj: T, prefix = ''): string[] => {
  return Object.entries(obj as Object).reduce((collector: string[], [key, val]) => {
    const newKeys = [...collector, prefix ? `${prefix}.${key}` : key]
    if (Object.prototype.toString.call(val) === '[object Object]') {
      const newPrefix = prefix ? `${prefix}.${key}` : key
      const otherKeys = extractKeys(val, newPrefix)
      return [...newKeys, ...otherKeys]
    }
    return newKeys
  }, [])
}

// extract all uniq keys from all objects in array
export const allKeys = <T>(objs: T[]): string[] => {
  const keys: string[] = []
  for (const obj of objs) {
    keys.push(...extractKeys(obj))
  }
  return [...new Set(keys)]
}

export const fuzzySearch = <T>(
  arrOfObjs: T[],
  substr: string,
  threshold?: number,
): T[] => {
  if (substr === undefined || substr.length === 0) {
    return arrOfObjs
  }

  // https://fusejs.io/api/options.html
  const options = {
    threshold: threshold || 0.3,
    keys: allKeys(arrOfObjs),
    ignoreLocation: true,
  }

  const fuse = new Fuse(arrOfObjs, options)

  const found = fuse.search(substr).map((item) => item.item)

  return found
}
