import { VStack } from '@revolut/ui-kit'
import { AlertDescriptor } from '../../types'
import { UserInfo } from './components/UserInfo'
import { RolePairs } from './components/RolePairs'
import { ToxicPairInfo } from './components/ToxicPairInfo'

type Props = {
  toxicAlertDescriptor: AlertDescriptor
}

export const Body = ({ toxicAlertDescriptor }: Props) => {
  return (
    <VStack space="s-16">
      <UserInfo toxicAlertDescriptor={toxicAlertDescriptor} />
      <ToxicPairInfo toxicAlertDescriptor={toxicAlertDescriptor} />
      <RolePairs toxicAlertDescriptor={toxicAlertDescriptor} />
    </VStack>
  )
}
