import { Client } from 'api/idave/clients'
import { Permission as PermissionType } from 'api/idave/permissions'
import { sortBy } from 'lodash'

type GetPermissionsParams = {
  permissions: PermissionType[]
  clientMap: Map<string, Client>
  orphanedIdsMap: Record<string, boolean>
}
export const getPermissionItems = ({
  permissions,
  clientMap,
  orphanedIdsMap,
}: GetPermissionsParams) => {
  const items = sortBy(
    permissions.map((permission) => getPermissionItem({ permission, clientMap })),
    'clientName',
    'name',
  )

  return {
    orphanedItems: items.filter(({ id }) => orphanedIdsMap[id]),
    commonItems: items,
  }
}

type GetPermissionParams = {
  permission: PermissionType
  clientMap: Map<string, Client>
}

const getPermissionItem = ({ permission, clientMap }: GetPermissionParams) => ({
  ...permission,
  clientName: clientMap.get(permission.client)?.name,
})
