import { useCallback } from 'react'
import { TextSkeleton, chain } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryPermissionIdMap } from 'queries/idave/permissions'
import { InternalLink } from './InternalLink'
import { ClientLink } from './ClientLink'

type Props = {
  id: string
  withClient?: boolean
}

export const PermissionLink = ({ id, withClient }: Props) => {
  const { data: permissions, status: qs, fetchStatus: fs } = useQueryPermissionIdMap()

  const href = generatePath(Url.Permission, { permissionId: id })
  const LinkComponent = useCallback(() => {
    const permission = permissions?.get(id)
    return chain(
      <InternalLink href={href}>{permission?.name || id}</InternalLink>,
      withClient && permission ? <ClientLink id={permission.client} /> : null,
    )
  }, [id, permissions, href, withClient])

  return (
    <QuerySwitch
      data={permissions}
      required={[{ qs, fs }]}
      renderError={LinkComponent}
      renderIdle={LinkComponent}
      renderLoading={() => (
        <InternalLink href={href}>
          <TextSkeleton size={17} />
        </InternalLink>
      )}
      renderSuccess={LinkComponent}
    />
  )
}
