import { Role } from 'api/idave/roles'
import { PolicyDetails } from 'api/sam/policies'

type GetRolePolicyListParams = {
  roleId: string
  roleMap: Map<string, Role>
  policies: PolicyDetails[]
}
export const getRoleRelatedPolicies = (params: GetRolePolicyListParams) => {
  const { roleId, roleMap, policies } = params

  return policies.filter(
    (policy) => policy.resourceIds.includes(roleId) && roleMap.has(roleId),
  )
}
