import { DataMaps } from 'view/AuditLogs/types'
import { Table, getEntityRows } from '../../../../../../Table'
import { COLUMNS } from './columns'

export const Roles = (props: {
  title: string
  rolesIds?: string[]
  dataMaps: DataMaps
}) => {
  const { title, rolesIds, dataMaps } = props
  if (!rolesIds) {
    return null
  }
  const permissionsRows = getEntityRows(rolesIds, 'Role', dataMaps).map(
    ({ id, ...row }) => {
      const permissionCount = dataMaps.rolesMap?.get(id)?.permissions.length || '-'
      return { ...row, permissionCount }
    },
  )

  if (!permissionsRows || !permissionsRows.length) {
    return null
  }

  return <Table title={title} data={permissionsRows} columns={COLUMNS} />
}
