import {
  CrossCheckDecision,
  CrossCheckDetail,
  CrossCheckState,
  CrossCheckSystem,
} from 'api/types/crossCheck'
import { UserIds } from 'hooks/useCurrentUserIds'
import { PermissionUtils } from 'services/permissions'
import { getCrossCheckActionPermission, getEventsByType } from 'view/CrossChecks/utils'
import { isRecertification as isRecertificationCheck } from 'view/CrossChecks/CrossCheckList/utils'

export const canReview = (params: {
  source: CrossCheckSystem
  crossCheck: CrossCheckDetail
  ids: UserIds
  permissionUtils: PermissionUtils
}) => {
  const {
    source,
    crossCheck,
    ids,
    permissionUtils: { hasPermission },
  } = params

  const userId = source === 'sam' ? ids.peopleOps : ids.idave
  const isAuthor = crossCheck.requester === userId

  const crossCheckPermission = getCrossCheckActionPermission(source, 'REVIEW')
  const isAllowed = hasPermission(crossCheckPermission)

  const isReviewer = isUserReviewer({ crossCheck, userId })

  const reviewEvents = [
    ...getEventsByType(crossCheck.events, 'APPROVE'),
    ...getEventsByType(crossCheck.events, 'REJECT'),
  ]
  const notReviewed = !reviewEvents.some((e) => e.author === userId)
  const isAwaitingReview = crossCheck.state === CrossCheckState.AwaitingReview

  const isRecertification = isRecertificationCheck(crossCheck.executableName)
  const canApprove =
    isAwaitingReview && isReviewer && isAllowed && notReviewed && !isAuthor

  const canReject =
    isAwaitingReview &&
    isReviewer &&
    isAllowed &&
    notReviewed &&
    !isAuthor &&
    !isRecertification

  return { canApprove, canReject }
}

const isUserReviewer = (params: { userId?: string; crossCheck: CrossCheckDetail }) => {
  const { crossCheck, userId } = params

  return crossCheck.reviewerGroups.some((group) =>
    group.reviewers.find((r) => r === userId),
  )
}

export const LOADING_POPUP_TEXT: Record<CrossCheckDecision, string> = {
  APPROVE: 'Submitting approve...',
  REJECT: 'Submitting rejection...',
}

export const SUCCESS_TOAST_TEXT: Record<CrossCheckDecision, string> = {
  APPROVE: 'Approved',
  REJECT: 'Rejected',
}
