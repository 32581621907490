import { useQueryAccount } from 'queries/idave/accounts'
import { QuerySwitch } from 'components/QuerySwitch'
import { AccountEditForm } from './components/AccountEditForm'
import { Skeleton } from './components/Skeleton'

export const AccountEdit = ({ accountId }: { accountId: string }) => {
  const { data, status, fetchStatus } = useQueryAccount({ accountId })
  return (
    <QuerySwitch
      data={data}
      required={[{ qs: status, fs: fetchStatus }]}
      renderSuccess={({ data: account }) => <AccountEditForm account={account} />}
      renderLoading={() => <Skeleton accountId={accountId} />}
    />
  )
}
