import { UserListItem } from 'api/idave/user'
import { sortBy } from 'lodash'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { getName } from 'utils/string/getName'

type GetUserEntitiesParams = {
  users: UserListItem[]
  bubbleUp?: string[]
  excludeHash: Record<string, boolean>
  excludeInactive?: boolean
  usePeopleOpsIds?: boolean
  currentUserId?: string
  hoistYourself: boolean
}
export const getUserEntities = ({
  users,
  bubbleUp = [],
  excludeHash,
  excludeInactive,
  usePeopleOpsIds,
  currentUserId,
  hoistYourself,
}: GetUserEntitiesParams) => {
  const hoistIds = toPresenceMap(bubbleUp)

  const userItems = users.filter((user) => {
    const shouldDropByActivity = excludeInactive && user.state !== 'ACTIVE'
    const shouldDropByExcluding = excludeHash[user.id]
    const shouldDropByPeopleOpsIds = usePeopleOpsIds && !user.externalRef?.reference

    return !shouldDropByActivity && !shouldDropByPeopleOpsIds && !shouldDropByExcluding
  })

  return sortBy(userItems, [
    ({ id }) => (hoistYourself ? currentUserId !== id : false),
    ({ id }) => !hoistIds[id],
    'state',
    getName,
  ])
}

type GetFilteredUsers = {
  users: UserListItem[]
  showSelected: boolean
  selectedHash: Record<string, boolean>
  usePeopleOpsIds?: boolean
}
export const getFilteredUsers = ({
  users,
  showSelected,
  selectedHash,
  usePeopleOpsIds,
}: GetFilteredUsers) => {
  return users.filter((user) => {
    const id = usePeopleOpsIds ? user.externalRef?.reference : user.id
    if (!id) {
      return false
    }

    const visibleBySelection = !showSelected || selectedHash[id]
    return visibleBySelection
  })
}
