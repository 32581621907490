import { getSeniorityIds } from 'view/Sam/utils'
import { DetailRow } from '../../../types'
import { DetailParams } from '../types'
import { getNormalizedValues } from '../utils'
import { getPolicySubjectChangeType } from './utils'

export const getSeniorities = ({
  entity,
  crossCheck,
}: DetailParams): DetailRow | undefined => {
  const requested =
    crossCheck.executableName === 'CreatePolicy'
      ? crossCheck.executableParams.subject_params?.subject_seniority_ids
      : undefined

  const current =
    entity.type === 'AccessPolicy' && entity.value
      ? getSeniorityIds(entity.value.subject)
      : undefined

  const value = requested || current

  return {
    title: 'Seniorities',
    value: {
      ...getNormalizedValues(value, value),
      type: 'Seniorities',
    },
    changeType: getPolicySubjectChangeType(crossCheck),
    isContext: true,
  }
}
