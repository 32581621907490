import { CrossCheckDetail } from 'api/types/crossCheck'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { CrossCheckValueTab } from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/types'
import { EmployeeDetailsNotLimited } from 'api/dart/employee'
import { getItems } from 'view/Users/User/UserDataAccess/components/UserDataAccessRestrictions/utils'
import { notNullable } from 'utils/common'
import { getChangeRowsInfo } from '../../../../utils'
import { CustomerRow } from './columns'
import { getAccessModifiersInfo } from '../../utils'

type GetAccessModifierRowsParams = {
  crossCheck: CrossCheckDetail
  employeeDetails?: EmployeeDetailsNotLimited
  tabs: CrossCheckValueTab[]
}

export const getAccessModifierRows = ({
  crossCheck,
  employeeDetails,
  tabs,
}: GetAccessModifierRowsParams) => {
  const { accessModifiers, actionType } = getAccessModifiersInfo(crossCheck)

  const ids = accessModifiers
    .map((v) => v.accessModifier.entityId?.id)
    .filter(notNullable)

  const items = getItems(employeeDetails)

  const added = actionType === 'added' ? ids : []
  const deleted = actionType === 'removed' ? ids : []

  const values: string[] = []
  const current = items.map((v) => v.entityId).filter(notNullable)

  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
  })
}

const makeMapValue =
  ({
    deletedHash = {},
    addedHash = {},
  }: {
    deletedHash?: Record<string, boolean>
    addedHash?: Record<string, boolean>
  }) =>
  (userId: string): CustomerRow => {
    const removedStatus = deletedHash[userId] ? 'removed' : undefined
    const addedStatus = addedHash[userId] ? 'added' : undefined

    return {
      id: userId,
      status: addedStatus || removedStatus,
    }
  }

export const getEmptyTableLabel = (status: CrossCheckValueTab) =>
  status === 'requested'
    ? 'No added or deleted customers'
    : 'No customer restrictions for the user'
