import { Avatar, Item, Token } from '@revolut/ui-kit'
import { useMemo } from 'react'
import { EmployeeDetails } from 'api/dart/employee'

export const UserDataAccessIgnored = ({
  employeeDetails,
}: {
  employeeDetails: EmployeeDetails | undefined
}) => {
  const accessModifier = useMemo(
    () =>
      employeeDetails?.accessModifiers.find(
        (item) => item.type === 'ALLOWED' && !item.entityId,
      ),
    [employeeDetails],
  )

  if (!employeeDetails || !accessModifier) {
    return null
  }

  return (
    <Item role="row">
      <Item.Content>
        <Item.Title role="cell">Ignored employee</Item.Title>
        <Item.Description role="cell">
          The user will bypass all the restriction checks, because the user is ignored by
          all the restrictions
        </Item.Description>
      </Item.Content>

      <Item.Avatar role="img">
        <Avatar
          size={40}
          variant="square"
          bg={Token.color.danger}
          color={Token.color.white}
          useIcon="Info"
        />
      </Item.Avatar>
    </Item>
  )
}
