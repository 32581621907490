import { Token } from 'hooks/useTokenize/types'

export const isTokensDisplayable = (tokens: Token[]) => tokens.some(isTokenDisplayable)

export const isTokenDisplayable = (token: Token) => {
  switch (token.type) {
    case 'external_link':
    case 'text':
      return Boolean(token.value)
    case 'internal_link':
      return true
    default:
      return false
  }
}
