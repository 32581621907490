import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { AttributeLists } from './components/AttributeLists'
import { Details } from './components/Details'
import { CrossCheckEntity } from './types'
import { DataWrapper } from './components/DataWrapper'
import { CrossCheckDetailsSkeleton } from './components/CrossCheckDetailsSkeleton'

type Props = {
  crossCheck?: CrossCheckDetail
  source: CrossCheckSystem
}

export const CrossCheckDetails = (props: Props) => {
  const { crossCheck, source } = props

  return crossCheck ? (
    <DataWrapper crossCheck={crossCheck} Component={Inner} source={source} />
  ) : (
    <CrossCheckDetailsSkeleton />
  )
}

export const Inner = (props: {
  crossCheck: CrossCheckDetail
  entity: CrossCheckEntity
}) => {
  const { crossCheck, entity } = props

  return (
    <>
      <Details crossCheck={crossCheck} entity={entity} />
      <AttributeLists crossCheck={crossCheck} entity={entity} />
    </>
  )
}
