import {
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMonth,
  isSameYear,
  getYear,
  getMonth,
  getDate as getMonthDay,
} from 'date-fns'
import { DateFilterValue } from './CalendarFilter'

export const filterItemsByDate = <T>(
  logs: T[],
  getDate: (log: T) => Date,
  filterValue: DateFilterValue,
) =>
  !filterValue
    ? logs
    : logs.filter((log) => {
        const date = getDate(log)

        return (
          (isAfter(date, filterValue.from) || isEqual(date, filterValue.from)) &&
          (isBefore(date, filterValue.to) || isEqual(date, filterValue.to))
        )
      })

const DATE_FORMAT = 'd'
const DATE_MONTH_FORMAT = 'd LLL'
const DATE_MONTH_YEAR_FORMAT = 'd LLL u'

export const getRangeLabel = (filterValue: DateFilterValue, now: Date) => {
  if (!filterValue) {
    return 'Filter by date'
  }

  const { from, to } = filterValue
  if (isSameDay(from, to)) {
    const dayFormat = isSameYear(from, now) ? DATE_MONTH_FORMAT : DATE_MONTH_YEAR_FORMAT
    return format(from, dayFormat)
  }

  if (isSameMonth(from, to)) {
    const toFormat = isSameYear(from, now) ? DATE_MONTH_FORMAT : DATE_MONTH_YEAR_FORMAT
    return `${format(from, DATE_FORMAT)} - ${format(to, toFormat)}`
  }

  if (isSameYear(from, to)) {
    const toFormat = isSameYear(from, now) ? DATE_MONTH_FORMAT : DATE_MONTH_YEAR_FORMAT
    return `${format(from, DATE_MONTH_FORMAT)} - ${format(to, toFormat)}`
  }

  return `${format(from, DATE_MONTH_YEAR_FORMAT)} - ${format(to, DATE_MONTH_YEAR_FORMAT)}`
}

export const getRange = (filterValue: DateFilterValue) => {
  if (!filterValue) {
    return null
  }
  const fromYear = getYear(filterValue.from)
  const fromMonth = getMonth(filterValue.from)
  const fromDate = getMonthDay(filterValue.from)
  const from = new Date(fromYear, fromMonth, fromDate, 0, 0, 0, 0)

  const toYear = getYear(filterValue.to)
  const toMonth = getMonth(filterValue.to)
  const toDate = getMonthDay(filterValue.to)
  const to = new Date(toYear, toMonth, toDate, 23, 59, 59, 999)

  return { from, to }
}
