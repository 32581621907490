import { EntitiesTable } from 'components/EntitiesTable'
import { useColumns } from './columns'
import { GROUP_LABEL_PLURAL_FORMS } from './constants'

export const GroupsTableSkeleton = () => {
  const columns = useColumns()

  return (
    <EntitiesTable
      columns={columns}
      data={[]}
      loadingState="pending"
      entitiesTypeLabel="Group"
      pluralForms={GROUP_LABEL_PLURAL_FORMS}
    />
  )
}
