import { HStack, Text, VStack } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { isEqual } from 'lodash'
import { capitalizeFirst } from 'utils/string'
import { RevolutersSeniority } from 'components/Links/RevolutersSeniority'
import { RevolutersSpecialisationLink } from 'components/Links/RevolutersSpecialisationLink'
import { RevolutersTeamLink } from 'components/Links/RevolutersTeamLink'
import { RevolutersEmployeeLink } from 'components/Links/RevolutersEmployeeLink'
import { CrossCheckValueTab } from '../../../types'
import { DiffSuffix } from './DiffSuffix'
import { EntitiesValue } from '../types'

const IDSComponentContent = (props: { type: EntitiesValue['type']; ids: string[] }) => {
  const { type, ids } = props
  switch (type) {
    case 'Employees':
      return (
        <>
          {ids.map((id) => (
            <RevolutersEmployeeLink key={id} id={id} />
          ))}
        </>
      )
    case 'Seniorities':
      return (
        <>
          {ids.map((id) => (
            <RevolutersSeniority key={id} id={id} />
          ))}
        </>
      )
    case 'Specialisations':
      return (
        <>
          {ids.map((id) => (
            <RevolutersSpecialisationLink key={id} id={id} />
          ))}
        </>
      )
    case 'Teams':
      return (
        <>
          {ids.map((id) => (
            <RevolutersTeamLink key={id} id={id} />
          ))}
        </>
      )
    case 'EmployeeTypes':
    default:
      return (
        <>
          {ids.map((value) => (
            <Text key={value} id={value}>
              {capitalizeFirst(value)}
            </Text>
          ))}
        </>
      )
  }
}

const IDSComponent = (props: { type: EntitiesValue['type']; ids: string[] }) => {
  const { type, ids } = props
  return (
    <VStack align="end" gap="s-4">
      <IDSComponentContent type={type} ids={ids} />
    </VStack>
  )
}

export const DetailEntities = (props: {
  tab: CrossCheckValueTab
  value: EntitiesValue
  isFinished: boolean
}) => {
  const { value, tab, isFinished } = props
  const { type } = value

  switch (tab) {
    case 'current':
    case 'forecasted': {
      const ids = value[tab]

      return ids?.length ? (
        <IDSComponent ids={ids} type={type} />
      ) : (
        <Text>{EMPTY_VALUE_FALLBACK}</Text>
      )
    }

    default:
    case 'requested': {
      const { requested = [], current = [] } = value
      const requestedItems = requested.sort()
      const currentItems = current.sort()

      if (isFinished || isEqual(requestedItems, currentItems)) {
        return requestedItems?.length ? (
          <IDSComponent ids={requestedItems} type={type} />
        ) : (
          <Text>{EMPTY_VALUE_FALLBACK}</Text>
        )
      }

      return (
        <VStack align="end">
          {requestedItems?.length ? (
            <HStack space="s-6" align="center">
              <IDSComponent ids={requestedItems} type={type} />
              <DiffSuffix isNew />
            </HStack>
          ) : (
            EMPTY_VALUE_FALLBACK
          )}

          {currentItems?.length ? (
            <HStack space="s-6" align="center">
              <IDSComponent ids={currentItems} type={type} />
              <DiffSuffix />
            </HStack>
          ) : (
            EMPTY_VALUE_FALLBACK
          )}
        </VStack>
      )
    }
  }
}
