import { Group } from 'api/idave/groups'
import { useCallback } from 'react'
import { useEditGroup } from 'view/System/GroupEdit/useEditGroup'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { UsersTable } from './components/UsersTable'

export const Users = ({ group }: { group: Group }) => {
  const { hasPermission } = usePermissions()

  const editGroup = useEditGroup(group.id)

  const addUsers = useCallback(
    (userIds: string[]) => {
      return editGroup({
        groupId: group.id,
        data: {
          ...group,
          userIds: Array.from(new Set([...userIds, ...group.userIds])),
        },
      })
    },
    [editGroup, group],
  )

  const onDelete = useCallback(
    (userIds: string[]) => {
      return editGroup({
        groupId: group.id,
        data: {
          ...group,
          userIds: group.userIds.filter((userId: string) => !userIds.includes(userId)),
        },
      })
    },
    [editGroup, group],
  )

  return (
    <UsersTable
      userIds={group?.userIds}
      onAdd={addUsers}
      onDelete={onDelete}
      showActions={
        group.type === 'idave:team' && hasPermission(IDAVE_PERMISSIONS.GROUPS_UPDATE)
      }
    />
  )
}
