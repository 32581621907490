export const SENIORITY: Record<string, string> = {
  '19': 'Graduate',
  '4': 'Junior',
  '1': 'Mid',
  '3': 'Senior',
  '2': 'Lead',
  '5': 'Director',
  '17': 'Senior Director',
  '7': 'Vice President',
  '18': 'Senior Vice President',
  '6': 'Chief',
}
