import { ActionButton } from '@revolut/ui-kit'
import { CrossChecksTab } from 'view/CrossChecks/CrossCheckList/types'

export const makeOverdueAction =
  (tab: CrossChecksTab, onClick: () => void, isActive: boolean) => () => {
    if (tab !== 'all') {
      return null
    }

    return <OverdueAction isActive={isActive} onClick={onClick} />
  }

const OverdueAction = (props: { onClick: () => void; isActive: boolean }) => {
  const { isActive, onClick } = props
  return (
    <ActionButton variant={isActive ? 'accent' : 'primary'} onClick={onClick}>
      Show overdue
    </ActionButton>
  )
}
