import { MoreBar } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { CrossCheckActionsSkeleton } from './CrossCheckActionsSkeleton'
import { JustifyAction } from './components/JustifyAction'
import { ReviewActions } from './components/ReviewActions'
import { CommentAction } from './components/CommentAction'
import { DeclineAction } from './components/DeclineAction'
import { RetryAction } from './components/RetryAction'

export const CrossCheckActions = (props: {
  crossCheck?: CrossCheckDetail
  source: CrossCheckSystem
  commentRef: React.RefObject<HTMLTextAreaElement>
}) => {
  const { crossCheck, source, commentRef } = props

  if (!crossCheck) {
    return <CrossCheckActionsSkeleton />
  }

  return (
    <MoreBar>
      <JustifyAction crossCheck={crossCheck} source={source} />
      <ReviewActions crossCheck={crossCheck} source={source} />
      <CommentAction crossCheck={crossCheck} commentRef={commentRef} source={source} />
      <DeclineAction crossCheck={crossCheck} source={source} />
      <RetryAction crossCheck={crossCheck} source={source} />
    </MoreBar>
  )
}
