import { QueryFunction, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

export default function useLazyQuery<TResponse, TError, TParams>(args: {
  queryKey: QueryKey
  fetchFn: (p: TParams) => Promise<AxiosResponse<TResponse>>
  enabled?: boolean
  params: TParams | undefined
  isParamsValid?: (params?: TParams) => boolean
}): UseQueryResult<TResponse, TError> {
  const { queryKey, fetchFn, params, isParamsValid, enabled } = args
  const isValid = isParamsValid ? isParamsValid(params) : !!params
  const enabledOption = typeof enabled !== 'undefined' ? enabled : true
  const queryFn: QueryFunction<AxiosResponse<TResponse>, QueryKey> = () => {
    if (isValid) {
      return fetchFn(params as TParams)
    }
    return Promise.reject(new Error("Required params weren't provided"))
  }

  return useQuery<AxiosResponse<TResponse>, TError, TResponse, QueryKey>({
    queryKey,
    queryFn,
    select: (response: AxiosResponse<TResponse>) => response?.data,
    enabled: isValid && enabledOption,
  })
}
