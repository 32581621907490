import { Box, Subheader, Table } from '@revolut/ui-kit'
import { SamPolicy } from 'api/sam/policies'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { useQueryResourceMap } from 'queries/sam/resources'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { SamResource } from 'api/sam/resources'
import { useCallback, useMemo, useState } from 'react'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { useResourcePreview } from 'components/previews/ResourcePreview'
import { getColumns } from './columns'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../ChangeSwitcher'
import { CrossCheckValueTab } from '../../../../types'
import { makeGetChangeRowState } from '../../../../utils'
import { getEmptyResourceLabel, getResourceRowsInfo } from './utils'
import { ResourceRow } from './types'

export const ResourceList = ({
  crossCheck,
  policy,
}: {
  crossCheck: CrossCheckDetail
  policy?: SamPolicy
}) => {
  const {
    data: resourceMap = new Map(),
    status: qs,
    fetchStatus: fs,
    refetch,
  } = useQueryResourceMap()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={resourceMap}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view resources" />
      )}
      renderLoading={() => (
        <Inner
          crossCheck={crossCheck}
          policy={policy}
          resourceMap={resourceMap}
          isLoading
        />
      )}
      renderSuccess={() => (
        <Inner crossCheck={crossCheck} policy={policy} resourceMap={resourceMap} />
      )}
    />
  )
}

const Inner = ({
  crossCheck,
  policy,
  isLoading,
  resourceMap,
}: {
  crossCheck: CrossCheckDetail
  policy?: SamPolicy
  isLoading?: boolean
  resourceMap: Map<string, SamResource>
}) => {
  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])
  const { rows, counters } = useMemo(
    () =>
      getResourceRowsInfo({
        crossCheck,
        resourceMap,
        policy,
        tabs,
      }),
    [crossCheck, resourceMap, policy, tabs],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState<CrossCheckValueTab>(defaultTab)
  const data = rows[tab]

  const { openResourceSide } = useResourcePreview()
  const onRowClick = useCallback(
    (row: ResourceRow) => openResourceSide(row.id),
    [openResourceSide],
  )

  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Resources</Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            currentTab={tab}
            onChange={setTab}
            counters={counters}
            tabs={tabs}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        onRowClick={onRowClick}
        loadingState={isLoading ? 'pending' : 'ready'}
        getRowState={getRowState}
        labelEmptyState={getEmptyResourceLabel(tab)}
      />
    </Box>
  )
}
