import { TableColumn } from '@revolut/ui-kit'
import { UserTableCell, useUserTableCellAccessor } from 'components/UserTableCell'
import { useMemo } from 'react'
import { Row } from './types'

export const useColumns = (): TableColumn<Row>[] => {
  const getUserName = useUserTableCellAccessor()

  return useMemo(
    () => [
      {
        Header: 'Name',
        accessor: ({ id }) => getUserName(id),
        Cell: (params: { row: { original: Row } }) => (
          <UserTableCell userId={params.row.original.id} />
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
    [getUserName],
  )
}
