import { Avatar, Checkbox, Ellipsis, Item, Radio, abbreviate } from '@revolut/ui-kit'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem as TUserListItem } from 'api/idave/user'
import { UserListVariant } from 'components/UserList/types'
import { useCallback } from 'react'
import { VirtualItem } from 'react-virtual'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'

type UserListItemProps = {
  user: TUserListItem
  virtualItem: VirtualItem
  selectedHash: Record<string, boolean | undefined>
  variant: UserListVariant
  avatars?: Map<string, RevolutersAvatar>
  disabled?: boolean
  usePeopleOpsIds?: boolean
  onChange: (id: string) => void
}

export const UserListItem = ({
  user,
  avatars,
  virtualItem,
  variant,
  selectedHash,
  disabled,
  usePeopleOpsIds,
  onChange,
}: UserListItemProps) => {
  const VariantComponent = variant === 'checkbox' ? Checkbox : Radio
  const id = usePeopleOpsIds ? user.externalRef?.reference : user.id
  const checked = !!id && !!selectedHash[id]

  const onItemChange = useCallback(() => {
    if (id) {
      onChange(id)
    }
  }, [onChange, id])

  if (!id) {
    return null
  }

  return (
    <Item use="label" ref={virtualItem.measureRef} style={getItemStyle(virtualItem)}>
      <Item.Prefix>
        <VariantComponent
          aria-labelledby="item-title"
          aria-describedby="item-description"
          onChange={onItemChange}
          disabled={disabled}
          checked={checked}
          align="center"
        />
      </Item.Prefix>
      <Item.Avatar>
        <Avatar
          size={40}
          uuid={user.id}
          label={abbreviate(getName(user))}
          image={getAvatarUrl({ user, avatars, type: 'imageMedium' })}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{getName(user)}</Item.Title>
        <Item.Description>
          <Ellipsis>{user.email}</Ellipsis>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

const getItemStyle = (virtualItem: VirtualItem) => ({
  position: 'absolute' as const,
  top: 0,
  left: 0,
  width: '100%',
  minHeight: `${virtualItem.size}px`,
  transform: `translateY(${virtualItem.start}px)`,
})
