import { SamResource, SamResourceType } from 'api/sam/resources'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { useCallback, useState } from 'react'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { SamPolicyResources as SamPolicyResourcesCommon } from 'view/Sam/components/SamPolicyResources'
import { randomKey } from 'utils/common/randomKey'
import { EDIT_RESOURCES } from 'view/Sam/permissions'
import { useRolePreview } from 'components/previews/RolePreview'
import { useResourcePreview } from 'components/previews/ResourcePreview'
import { getPopupText } from './utils'
import { useSetPolicyResources } from './useSetPolicyResources'

type SamPolicyResourcesProps = {
  id: string
  isActive: boolean
}

export const SamPolicyResources = ({ id, isActive }: SamPolicyResourcesProps) => {
  const { hasSomePermissions, hasPermission } = usePermissions()
  const { data: policy, status: policyStatus } = useQuerySamPolicy(id)
  const { setPopup, closePopup } = useConfirmationPopup()

  const [resetKey, setResetKey] = useState(randomKey())
  const updateResetKey = useCallback(() => setResetKey(randomKey()), [setResetKey])
  const onSuccess = useCallback(() => {
    updateResetKey()
    closePopup()
  }, [updateResetKey, closePopup])
  const { setResources, isSetting } = useSetPolicyResources({
    policy,
    onSuccess,
  })

  const onRemoveClick = useCallback(
    (resourceIds: string[]) => {
      setPopup({
        ...getPopupText(resourceIds),
        confirmButtonText: 'Remove',
        onConfirmClick: () => {
          setResources({
            resourceIds,
            type: 'delete',
          })
        },
      })
    },
    [setPopup, setResources],
  )

  const onAddResourcesClick = useCallback(
    (resourceIds: string[]) => {
      setResources({
        resourceIds,
        type: 'add',
      })
    },
    [setResources],
  )

  const { openRoleSide } = useRolePreview()
  const { openResourceSide } = useResourcePreview()

  const openResource = useCallback(
    (entity: SamResource) => {
      if (entity.resourceType === SamResourceType.IdaveRole) {
        return openRoleSide(entity.resourceId)
      }
      return openResourceSide(entity.resourceId)
    },
    [openRoleSide, openResourceSide],
  )

  return (
    <SamPolicyResourcesCommon
      key={resetKey}
      isActive={isActive}
      policyResources={policy?.resourceIds || []}
      policyStatus={policyStatus}
      isPending={isSetting}
      onResourceClick={openResource}
      addResources={onAddResourcesClick}
      deleteResources={onRemoveClick}
      policyName={policy?.policyName || id}
      showAddPermissions={
        hasSomePermissions(...EDIT_RESOURCES) &&
        hasPermission(SAM_PERMISSIONS.RESOURCES_VIEW_LIST)
      }
      showDeletePermissions={hasSomePermissions(...EDIT_RESOURCES)}
    />
  )
}
