import { ToxicAlert, ToxicPair } from 'api/idave/toxic'
import { UserListItem } from 'api/idave/user'
import { EmployeeRevoDescriptionGetter } from 'hooks/useGetEmployeeRevoDescription'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { AlertRow } from './types'

type GetRowsParams = {
  users?: Map<string, UserListItem>
  toxicPairs?: Map<string, ToxicPair>
  alerts?: ToxicAlert[]
  getEmployeeDescription: EmployeeRevoDescriptionGetter
}
export const getRows = ({
  alerts = [],
  getEmployeeDescription,
  users,
  toxicPairs,
}: GetRowsParams): AlertRow[] => {
  return alerts.map((toxicAlert) => {
    const { userId, toxicPermissionId } = toxicAlert
    const employeeDescription = getEmployeeDescription(userId)
    const user = users?.get(userId)
    return {
      id: `${userId}_${toxicPermissionId}`,
      userId,
      toxicPermissionId,
      userName: user ? getName(user) : userId,
      name:
        toxicPairs?.get(toxicAlert.toxicPermissionId)?.name ||
        toxicAlert.toxicPermissionId,
      email: user?.email || EMPTY_VALUE_FALLBACK,
      spec: employeeDescription.spec?.name || EMPTY_VALUE_FALLBACK,
      team: employeeDescription.team?.name || EMPTY_VALUE_FALLBACK,
    }
  })
}
