import { ActionButton } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { AlertDescriptor } from '../types'

type Props = {
  toxicAlertDescriptor: AlertDescriptor
}

export const Actions = ({ toxicAlertDescriptor }: Props) =>
  toxicAlertDescriptor?.toxicPermissionId ? (
    <ActionButton
      useIcon="LinkExternal"
      use="a"
      target="_blank"
      href={generatePath(Url.ToxicPair, {
        toxicPairId: toxicAlertDescriptor.toxicPermissionId,
      })}
    >
      Open toxic pair
    </ActionButton>
  ) : null
