import { Box, Subheader } from '@revolut/ui-kit'
import { UserItem, UserItemProps } from 'components/UserItem'

export const Author = (props: UserItemProps) => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Author</Subheader.Title>
      </Subheader>
      <UserItem {...props} />
    </Box>
  )
}
