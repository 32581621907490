import {
  AnyUserRule,
  CrossCheckDetail,
  CrossCheckEvent,
  CrossCheckGroup,
  CrossCheckRule,
  CrossCheckState,
  RequiredGroupReview,
} from 'api/types/crossCheck'
import { pluralForm } from 'utils/string'
import { getEventsByType } from 'view/CrossChecks/utils'
import { RulePoint, RuleState } from './types'

export const isFinished = (crossCheck: CrossCheckDetail) =>
  crossCheck.state === CrossCheckState.Rejected ||
  crossCheck.state === CrossCheckState.ExecutionSucceed ||
  crossCheck.state === CrossCheckState.Declined ||
  crossCheck.state === CrossCheckState.Executed

export const getRulePoints = (params: {
  rule: CrossCheckRule
  groups: CrossCheckGroup[]
  events: CrossCheckEvent[]
}) => {
  const { rule, groups, events } = params

  switch (rule.type) {
    case 'REQUIRED_GROUP_REVIEW':
      return rule.groupIds.reduce<RulePoint[]>((acc, groupId) => {
        const group = groups.find((item) => item.groupId === groupId)
        if (!group) {
          return acc
        }

        return [
          ...acc,
          {
            text: pluralForm(rule.approvals, [
              `${rule.approvals} member of ${group.name}`,
              `${rule.approvals} members of ${group.name}`,
            ]),
            state: getGroupReviewState({ events, group, rule }),
          },
        ]
      }, [])

    default:
    case 'ANY_USER_REVIEW':
      return [
        {
          text: pluralForm(rule.approvals, [
            `${rule.approvals} approval at least`,
            `${rule.approvals} approvals at least`,
          ]),
          state: getAnyUserReviewState({ rule, events }),
        },
      ]
  }
}

export const getGroupReviewState = (params: {
  rule: RequiredGroupReview
  group: CrossCheckGroup
  events: CrossCheckEvent[]
}): RuleState => {
  const { rule, group, events } = params
  const rejectEvents = getEventsByType(events, 'REJECT')
  const hasRejected = rejectEvents.some((reject) =>
    group.reviewers.includes(reject.author),
  )
  if (hasRejected) {
    return 'FAIL'
  }

  const approveEvents = getEventsByType(events, 'APPROVE')
  const groupApproveEvents = approveEvents.filter((event) =>
    group.reviewers.includes(event.author),
  )

  if (groupApproveEvents.length >= rule.approvals) {
    return 'OK'
  }

  return 'PENDING'
}

export const getAnyUserReviewState = (params: {
  rule: AnyUserRule
  events: CrossCheckEvent[]
}): RuleState => {
  const { rule, events } = params

  const rejectEvents = getEventsByType(events, 'REJECT')
  if (rejectEvents.length) {
    return 'FAIL'
  }
  const approveEvents = getEventsByType(events, 'APPROVE')
  if (approveEvents.length >= rule.approvals) {
    return 'OK'
  }

  return 'PENDING'
}

const aggregateRuleStates = (rulePoints: RulePoint[]): RuleState => {
  if (rulePoints.some((point) => point.state === 'FAIL')) {
    return 'FAIL'
  }
  if (rulePoints.some((point) => point.state === 'OK')) {
    return 'OK'
  }

  return 'PENDING'
}

export const getRuleAggregation = (rulePoints: RulePoint[]) => {
  if (rulePoints.length <= 1) {
    return undefined
  }

  return {
    state: aggregateRuleStates(rulePoints),
    text: 'At least one of:',
  }
}
