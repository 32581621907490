import { Token, ProgressStepState } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckGroup } from 'api/types/crossCheck'
import { pluralForm } from 'utils/string'
import { getEventsByType } from 'view/CrossChecks/utils'
import { ACTION_TAG } from 'components/ActionTemplatedText'
import { uniq } from 'lodash'
import { isFinished } from '../../../CrossCheckRules/utils'

type GroupState = {
  text?: string
  state?: ProgressStepState
  stateColor?: string
}

export const getGroupState = (
  group: CrossCheckGroup,
  crossCheck: CrossCheckDetail,
): GroupState => {
  const reviewers = uniq(group.reviewers)
  const rejects = getEventsByType(crossCheck.events, 'REJECT')
  const groupRejects = rejects.filter(({ author }) => reviewers.includes(author))

  const approves = getEventsByType(crossCheck.events, 'APPROVE')
  const groupApproves = approves.filter(({ author }) => reviewers.includes(author))

  const text = getText(groupApproves.length, groupRejects.length, reviewers.length)

  if (groupRejects.length) {
    return {
      state: 'failed',
      text,
    }
  }

  const approveRule = crossCheck.rules.find(
    (rule) =>
      rule.type === 'REQUIRED_GROUP_REVIEW' && rule.groupIds.includes(group.groupId),
  )

  const isApproved =
    approveRule &&
    'approvals' in approveRule &&
    groupApproves.length >= approveRule.approvals

  if (isApproved) {
    return {
      state: 'done',
      stateColor: Token.color.success,
      text,
    }
  }

  if (isFinished(crossCheck)) {
    return {
      state: 'done',
      stateColor: Token.color.grey10,
      text,
    }
  }

  return {
    state: 'pending',
    stateColor: undefined,
    text,
  }
}

const getText = (approves: number, rejects: number, groupSize: number) => {
  if (!groupSize) {
    return undefined
  }

  const approvePart = pluralForm(approves, [
    `${approves} approve`,
    `${approves} approves`,
  ])
  const rejectPart = rejects
    ? pluralForm(rejects, [`${rejects} reject`, `${rejects} rejects`])
    : undefined
  const groupPart = pluralForm(groupSize, [
    `${ACTION_TAG}${groupSize} person${ACTION_TAG}`,
    `${ACTION_TAG}${groupSize} persons${ACTION_TAG}`,
  ])

  if (rejectPart) {
    return approves
      ? `${rejectPart}, ${approvePart} from ${groupPart}`
      : `${rejectPart} from ${groupPart}`
  }

  return `${approvePart} from ${groupPart}`
}
