import { Box, Subheader } from '@revolut/ui-kit'
import { UserAccessLogs } from 'api/idave/user'
import { DetailsGroup } from 'components/DetailsGroup'

type UserAccessLogSideLogInfoProps = {
  log: UserAccessLogs
}

export const UserAccessLogSideLogInfo = (props: UserAccessLogSideLogInfoProps) => {
  const { log } = props

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Access log details</Subheader.Title>
      </Subheader>

      <DetailsGroup rows={[['Log id', log.id]]} />
    </Box>
  )
}
