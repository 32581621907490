import { Role } from 'api/idave/roles'
import { UserRole } from 'api/idave/user'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { getRolesByPermission } from 'utils/role/getRolesByPermission'

type GetRoleItemsParams = {
  roles: Role[]
  permissionId: string
  userRoles: UserRole[]
}
export const getRoleItems = ({ permissionId, roles, userRoles }: GetRoleItemsParams) => {
  const permissionRoles = getRolesByPermission({
    roles,
    permissionId,
  })
  const userRoleIds = toPresenceMap(userRoles.map(({ roleId }) => roleId))
  return permissionRoles.filter(({ id }) => userRoleIds[id])
}
