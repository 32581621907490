import { UserListItem, UserStates } from 'api/idave/user'
import {
  RevolutersDepartment,
  RevolutersEmployee,
  RevolutersSpecialisation,
  RevolutersTeam,
} from 'api/sam/revoluters'
import { sortBy } from 'lodash'
import { getName } from 'utils/string/getName'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { User } from './types'

export const usernameRegExpCheck = /^[A-Z0-9._%+\-[\]\s]+$/i

export const mapUsers = (params: {
  users: UserListItem[]
  employeesMap?: Map<string, RevolutersEmployee>
  departmentsMap?: Map<string, RevolutersDepartment>
  teamsMap?: Map<string, RevolutersTeam>
  specsMap?: Map<string, RevolutersSpecialisation>
}) => {
  const { users, employeesMap, departmentsMap, teamsMap, specsMap } = params

  return sortBy(
    users.map<User>((user) => {
      const employee = employeesMap?.get(user.id)

      return {
        id: user.id,
        name: getName(user),
        state: user.state,
        email: user.email,
        statusLevel: stateToStatus(user.state),
        department: employee
          ? departmentsMap?.get(employee.departmentId)?.name
          : undefined,
        team: employee ? teamsMap?.get(employee.teamId)?.name : undefined,
        spec: employee ? specsMap?.get(employee.specialisationId)?.name : undefined,
      }
    }),
    'state',
    'name',
  )
}

const stateToStatus = (state: UserStates) => {
  switch (state) {
    case 'ACTIVE':
      return 'success' as const
    case 'DELETED':
      return 'warning' as const
    case 'TERMINATED':
    default:
      return 'alert' as const
  }
}

export const getUserRoute = (userId: string) => generatePath(Url.User, { userId })
export const getUserProfile = (userId: string) =>
  generatePath(Url.UserProfile, { userId })
export const getUserRoles = (userId: string) => generatePath(Url.UserRoles, { userId })
export const getUserAccessLogs = (userId: string) =>
  generatePath(Url.UserAccessLogs, { userId })
export const getUserAuditLogs = (userId: string) =>
  generatePath(Url.UserAuditLogs, { userId })
export const getUserPolicies = (userId: string) =>
  generatePath(Url.UserPolicies, { userId })
