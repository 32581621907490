import { StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'

export const EmptyList = () => {
  return (
    <StatusWidget>
      <StatusWidget.Image {...get3DImageSrcSetProps('3D086', 'v2')} />
      <StatusWidget.Title>Nothing here</StatusWidget.Title>
    </StatusWidget>
  )
}
