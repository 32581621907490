import { Client, ClientPayload } from 'api/idave/clients'

/*
 * Not using '...client' here to exclude the BE fields that are not listed on FE, e.g. ownerRef
 */
export const normalizeClient = (client: Client): ClientPayload => {
  return {
    id: client.id,
    redirectUris: client.redirectUris,
    name: client.name.trim(),
    ownerGroupId: client.ownerGroupId,
    description: client.description?.trim() || undefined,
    enableSAAuthentication: client.enableSAAuthentication || false,
  }
}
