import { useQuery } from '@tanstack/react-query'
import samApi from 'api/sam'
import { QueryKey } from 'helpers/configQuery'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'

export const useQueryRecertificationPolicyMap = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamRecertificationPolicyMap],
    queryFn: () => samApi.recertification.getRecertificationPolicyMap(),
    select: (response) => response?.data.data,
    enabled: hasPermission(SAM_PERMISSIONS.RECERTIFICATIONS_VIEW_POLICY_MAPPING_LIST),
  })
}

export const useQueryRecertification = (id: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamRecertification, id],
    queryFn: () => samApi.recertification.getRecertification(id),
    select: (response) => response?.data,
    enabled: hasPermission(SAM_PERMISSIONS.RECERTIFICATIONS_VIEW_DETAILS),
  })
}
