import { useCallback } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { Page } from '@revolut/ui-kit'

export const Header = ({ accountId }: { accountId: string }) => {
  const navigate = useNavigate()
  const toAccount = useCallback(
    () => navigate(generatePath(Url.Account, { accountId })),
    [accountId, navigate],
  )
  return <Page.Header onBack={toAccount}>Edit Account</Page.Header>
}
