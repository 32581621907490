import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import md5 from 'md5'
import { auth, getStoredUser, isOnSignInPage } from 'services/auth'
import { notNullable } from 'utils/common'

const DEFAULT_TIMEOUT = 10 * 1000

export const configAxios = async () => {
  axios.defaults.timeout = DEFAULT_TIMEOUT
  /**
   * Axios interceptor.
   * On 401 response try to refresh the token otherwise redirect to login.
   * On 403 response discards the request and redirect to login
   * On other errors display an error in console
   */
  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const isSignInFlow = isOnSignInPage()
      // Sign in errors will be gracefully handled at the auth page
      if (error.response?.status === 401 && !isSignInFlow) {
        auth.redirectToSignIn()

        return undefined
      }

      return Promise.reject(error)
    },
  )

  axios.interceptors.request.use((config) => {
    config.headers.set('Request-Id', getRequestId(config))
    return config
  })
}

const getRequestId = <T>(config: InternalAxiosRequestConfig<T>) => {
  const method = config.method
  const url = config.url
  const userEmail = getStoredUser()?.email || 'UNKNOWN'
  const now = Date.now().toString()

  const requestHashAttributes = [method, url, userEmail, now]
    .filter(notNullable)
    .join('-')

  return md5(requestHashAttributes)
}
