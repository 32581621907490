import { Action, Button, Page } from '@revolut/ui-kit'
import api from 'api/sam'
import { atom, useAtom } from 'jotai'
import { useQueryPolicies } from 'queries/sam/policies'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { mapify, notNullableMap } from 'utils/array'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { pluralForm } from 'utils/string'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { fullfilledRejectedSplit, fullfillSettledWithParams } from 'utils/promise'
import { makeInvalidationPredicate } from 'utils/query'
import { SamPolicyTrainings } from 'view/Sam/components/SamPolicyTrainings'
import { useSideBox } from 'view/SideBox/SideBox'
import { SamSidePolicyList } from 'view/Sam/components/SamSidePolicyList'
import { generatePath } from 'react-router'
import { getTrainingUpdateParams } from './utils'

export const policyIdsAtom = atom<string[]>([])

export const SamPoliciesAddTrainings = () => {
  const { data: policies = [], status } = useQueryPolicies()
  const { hasPermission } = usePermissions()
  const navigate = useNavigate()
  const policyMap = useMemo(() => mapify(policies, (p) => p.policyId), [policies])
  const [policyIds, setPolicyIds] = useAtom(policyIdsAtom)
  const [trainings, setTrainings] = useState<string[]>([])
  const { showSuccessToast } = useToasts()
  const { hideErrorPopup, showErrorPopup } = useErrorPopup()
  const queryClient = useQueryClient()
  const { openSide, closeSide } = useSideBox()

  const { mutate, status: mutateStatus } = useMutation({
    mutationFn: () =>
      fullfillSettledWithParams(
        getTrainingUpdateParams(policyIds, policyMap, trainings),
        api.policies.updatePolicy,
      ),
    onSuccess: (promises) => {
      const { rejected, fullfilled } = fullfilledRejectedSplit(promises)
      queryClient.resetQueries({
        predicate: makeInvalidationPredicate(
          QueryKey.SamPolicy,
          fullfilled.map((f) => [f.policyId]),
        ),
      })

      setPolicyIds(rejected.map((r) => r.policyId))

      if (rejected.length) {
        showErrorPopup({
          title: pluralForm(policyIds, [
            `Policy wasn't updated`,
            `${rejected.length} of ${policyIds.length} weren't updated`,
          ]),
          message: pluralForm(policyIds, [
            'Click “Try again” to apply changes to un-updated policy or close and change your request.',
            'Click “Try again” to apply changes to un-updated policies or close and change your request.',
          ]),
          continueLabel: 'Close',
          retryLabel: 'Try again',
          onRetryClick: () => {
            hideErrorPopup()
            mutate()
          },
        })
      } else {
        showSuccessToast('Policies updated')
      }
    },
  })

  const onBackClick = useCallback(() => {
    setPolicyIds([])
    closeSide()
    navigate(Url.SamPolicies)
  }, [navigate, setPolicyIds, closeSide])

  const showPolicies = useCallback(
    () =>
      openSide({
        title: 'Policies preview',
        body: (
          <SamSidePolicyList
            policies={notNullableMap(policyIds, (id) => policyMap.get(id))}
          />
        ),
      }),
    [policyIds, policyMap, openSide],
  )

  useEffect(() => {
    if (!policyIds.length) {
      closeSide()
      // redirect to Policies if policyIds is empty
      navigate(generatePath(Url.SamPolicies), {
        replace: true,
      })
    }
  }, [closeSide, policyIds.length, navigate])
  const policyNames = policyIds
    .map((id) => policyMap.get(id)?.policyName || id)
    .join(', ')

  return (
    <>
      <Page.Header
        onBack={onBackClick}
        description={
          <Action onClick={showPolicies}>
            {pluralForm(policyIds, ['1 policy', `${policyIds.length} policies`])}
          </Action>
        }
      >
        Bulk trainings adding
      </Page.Header>
      <Page.Main>
        <SamPolicyTrainings
          showAddPermissions={hasPermission(SAM_PERMISSIONS.POLICIES_UPDATE)}
          showDeletePermissions
          key={trainings.length}
          policyTrainigns={trainings}
          updateTrainings={(values) => {
            setTrainings(values || [])
          }}
          policyName={policyNames}
          isPending={mutateStatus === 'loading'}
          isActive
          policyStatus={status}
          emptyStatusTitle={pluralForm(policyIds, [
            'No trainings added to this policy',
            'No trainings added to this policies',
          ])}
        />
        <Page.MainActions>
          <Button
            onClick={() => {
              mutate()
              closeSide()
            }}
            variant="primary"
            elevated
            pending={mutateStatus === 'loading'}
            disabled={!trainings.length}
          >
            {pluralForm(trainings, [
              'Add 1 training',
              `Add ${trainings.length} trainings`,
            ])}
          </Button>
        </Page.MainActions>
      </Page.Main>
    </>
  )
}
