import { Box, Button, Input, Page, TextArea, TextSkeleton, VStack } from '@revolut/ui-kit'
import { useToasts } from 'hooks/useToasts'
import idaveApi from 'api/idave'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from 'helpers/configQuery'
import { Permission } from 'api/idave/permissions'
import { useQueryPermission } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { IDAVE_PERMISSIONS } from 'security'
import { Client } from 'api/idave/clients'
import { useCallback, useState } from 'react'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { useNavigate } from 'react-router'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useStorageItem } from 'hooks/useStorageItem'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'

type Props = {
  permissionId: string
}

export const PermissionEdit = ({ permissionId }: Props) => {
  const {
    data: permission,
    status: qs,
    fetchStatus: fs,
  } = useQueryPermission(permissionId)
  const {
    data: clientMap = new Map(),
    status: clientsQS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      optional={[{ qs: clientsQS, fs: clientsFS }]}
      data={permission}
      renderIdle={() => (
        <NoAccessWidget
          title={`Looks like you don’t have sufficient permissions to view permission details (${IDAVE_PERMISSIONS.PERMISSIONS_VIEW_DETAILS.value})`}
        />
      )}
      renderLoading={() => <Inner clientMap={clientMap} permissionId={permissionId} />}
      renderSuccess={({ data }) => (
        <Inner permission={data} clientMap={clientMap} permissionId={permissionId} />
      )}
    />
  )
}

type InnerProps = {
  permissionId: string
  permission?: Permission
  clientMap: Map<string, Client>
}
const Inner = ({ permission, clientMap, permissionId }: InnerProps) => {
  const isPending = !permission
  const client = permission?.client
    ? clientMap.get(permission.client)?.name || permission.client
    : undefined

  const { clearItem, setItem, item } = useStorageItem<{ description: string }>(
    `permissionEdit_${permissionId}`,
  )
  const [description, setDescription] = useState(
    item?.description || permission?.description || '',
  )
  const onDescriptionChange = useInputStringChange((value: string) => {
    setItem({ description: value })
    setDescription(value)
  })

  const navigate = useNavigate()
  const toPermissionPage = useCallback(
    () => navigate(generatePath(Url.Permission, { permissionId })),
    [navigate, permissionId],
  )

  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const { mutate, isLoading } = useMutation({
    mutationFn: (value: string) =>
      idaveApi.permissions.editDescription(permissionId, { description: value }),
    onMutate: () => showLoadingPopup({ title: 'Updating...' }),
    onSuccess: () => {
      hideLoadingPopup()
      clearItem()
      toPermissionPage()
      showSuccessToast('Description updated')
      queryClient.invalidateQueries([QueryKey.Permission, permissionId])
      queryClient.invalidateQueries([QueryKey.Permissions], { refetchType: 'all' })
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Permission update failed',
        error: err,
      })
    },
  })

  const onSubmit = useCallback(() => mutate(description), [description, mutate])

  const disabledSubmit = (permission?.description?.trim() || '') === description.trim()

  return (
    <>
      <Page.Header onBack={toPermissionPage}>
        {permission?.name || <TextSkeleton size={25} />}
      </Page.Header>

      <Page.Main>
        <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
          <VStack space="s-24">
            <Input label="Name" disabled value={permission?.name} pending={isPending} />
            <Input label="Client" disabled value={client} pending={isPending} />
            <TextArea
              label="Description (Optional)"
              disabled={isPending}
              pending={isPending}
              value={description}
              onChange={onDescriptionChange}
            />
          </VStack>
        </Box>
        <Page.MainActions>
          <Button
            id="permission-edit-save"
            variant="primary"
            elevated
            disabled={disabledSubmit}
            onClick={onSubmit}
            pending={isPending || isLoading}
          >
            Save changes
          </Button>
        </Page.MainActions>
      </Page.Main>
    </>
  )
}
