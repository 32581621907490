import { stringifySubjectType } from 'view/Sam/utils'
import { PolicyDetails, SamUserDetails } from 'api/sam/policies'
import { sortBy } from 'lodash'
import { getPolicyAssignStatus } from 'utils/policy/getPolicyAssignStatus'

export const getUserPolicies = (user?: SamUserDetails) => {
  if (!user?.policies?.length) {
    return []
  }
  const policies = user.policies
  return sortBy(
    policies.map((policy) => getPolicyRow(policy)),
    'name',
  )
}

export const getPolicyRow = (policy: PolicyDetails) => {
  const { status, statusLevel } = getPolicyAssignStatus(policy)

  return {
    name: policy.policyName,
    subject: stringifySubjectType(policy.subject.subjectType),
    status,
    statusLevel,
    id: policy.policyId,
  }
}
