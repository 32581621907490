import { Avatar, Item, Text } from '@revolut/ui-kit'
import { UnstyledLink } from 'components/Links/UnstyledLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { formatLongDateTime } from 'utils/date'

export const ClientItem = (props: { id: string; name: string; createdDate?: number }) => {
  const { name, id, createdDate } = props
  const href = generatePath(Url.Client, { clientId: id })

  return (
    <Item>
      <Item.Avatar>
        <UnstyledLink href={href}>
          <Avatar size={40} useIcon="Apps" />
        </UnstyledLink>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <UnstyledLink href={href}>
            <Text>{name}</Text>
          </UnstyledLink>
        </Item.Title>
      </Item.Content>
      {createdDate && (
        <Item.Side>
          <Item.Value variant="secondary">{formatLongDateTime(createdDate)}</Item.Value>
        </Item.Side>
      )}
    </Item>
  )
}
