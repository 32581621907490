import { useCallback } from 'react'
import { MoreBar, Page } from '@revolut/ui-kit'
import { useNavigate } from 'react-router-dom'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { EntitiesTable } from 'components/EntitiesTable'
import { Url } from 'routing'
import { generatePath } from 'react-router'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useQueryGroupIdMap } from 'queries/idave/groups'
import { useQueryRoleIdMap, useQueryRoles } from 'queries/idave/roles'
import { waitForAll } from 'utils/query'
import { Role } from 'api/idave/roles'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useColumns } from './columns'
import { CREATE } from './permissions'

export const Roles = () => {
  const { data: roles = [], status, fetchStatus } = useQueryRoles()
  const { data: roleMap = new Map() } = useQueryRoleIdMap()
  const {
    data: groupMap = new Map(),
    status: groupsQS,
    fetchStatus: groupsFS,
  } = useQueryGroupIdMap()

  const navigate = useNavigate()
  const onRowClick = useCallback(
    (role: Role) => {
      navigate(
        generatePath(Url.Role, {
          roleId: role.id,
        }),
      )
    },
    [navigate],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: roles,
  })
  const onRoleCreateClick = useCallback(() => navigate(Url.AddRole), [navigate])
  const columns = useColumns(roleMap, groupMap)

  const state = getLoadingState(
    waitForAll([{ qs: status, fs: fetchStatus }], [{ qs: groupsQS, fs: groupsFS }]),
    roles.length,
  )

  const getRowLink = useCallback(
    (role: Role) => generatePath(Url.Role, { roleId: role.id }),
    [],
  )

  return (
    <>
      <Page.Header>Roles</Page.Header>
      <Page.Main>
        <EntitiesTable
          enableNavigation
          totalCount={roles.length}
          entitiesTypeLabel="Roles"
          pluralForms={['role', 'roles']}
          loadingState={state}
          data={searched}
          columns={columns}
          onRowClick={onRowClick}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          searchAutoFocus
          getRowLink={getRowLink}
          renderActions={() => (
            <PermissionsCheck somePermissions={CREATE}>
              <MoreBar.Action useIcon="Plus" onClick={onRoleCreateClick}>
                Create new
              </MoreBar.Action>
            </PermissionsCheck>
          )}
        />
      </Page.Main>
    </>
  )
}
