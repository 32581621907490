import { Text, TextSkeleton, HStack, Icon } from '@revolut/ui-kit'
import { UseEmployeeRevoDescriptionResult } from 'hooks/useGetEmployeeRevoDescription'
import { User } from 'api/idave/user'
import { getUserFunction } from '../utils'
import { ActivityBadge } from './ActivityBadge'

export const UserHeaderSubtitle = (props: {
  user?: User
  employeeDescriptions: UseEmployeeRevoDescriptionResult
}) => {
  const { user, employeeDescriptions } = props
  if (!user || employeeDescriptions.status === 'loading') {
    return <TextSkeleton size={25} />
  }

  return (
    <HStack space="s-12" align="center">
      <ActivityBadge state={user.state} />
      <HStack space="s-4" align="center">
        <Icon name="RepairTool" size="s-16" />
        <Text>{getUserFunction({ employeeDescriptions, userId: user.id })}</Text>
      </HStack>
    </HStack>
  )
}
