import { orderBy } from 'lodash'
import { EmployeeDetails } from 'api/dart/employee'
import { textChain } from '@revolut/ui-kit'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import {
  AccessModifier,
  AccessModifierLimited,
  AccessModifierPayload,
} from 'api/dart/types'
import { getRegionFlagImageUrl } from 'utils/url/getRegionFlagImageUrl'
import { notNullableMap } from 'utils/array'
import { formatDate } from 'utils/date'
import { EMPTY_VALUE_FALLBACK, HIDDEN_DATA_FALLBACK } from 'constants/string'
import { AddCompanyPopupData } from './components'

const makeModifierMapper =
  (assignType: 'MANUAL' | 'AUTO') => (item: AccessModifier | AccessModifierLimited) =>
    item.type === 'ALLOWED' && item.entityId?.type === 'COMPANY'
      ? { ...item, assignType, entityId: item.entityId }
      : undefined

const getFallbackTitle = (
  entityId: AccessModifier['entityId'] | AccessModifierLimited['entityId'],
) => {
  if (entityId) {
    return entityId?.id || HIDDEN_DATA_FALLBACK
  }
  return EMPTY_VALUE_FALLBACK
}

export const getItems = (
  employeeDetails: EmployeeDetails | undefined,
  customerCompaniesMap: Map<string, CustomerCompanyItem>,
) => {
  if (!employeeDetails || !customerCompaniesMap.size) {
    return []
  }

  const autoAssigned = notNullableMap(
    employeeDetails.company?.accessModifiers || [],
    makeModifierMapper('AUTO'),
  )
  const manualAssigned = notNullableMap(
    employeeDetails.accessModifiers,
    makeModifierMapper('MANUAL'),
  )

  const mergedAndSorted = orderBy(
    autoAssigned.concat(manualAssigned),
    ['assignType', 'expirationDate'],
    ['desc', 'asc'],
  )

  return mergedAndSorted.map((item) => {
    const entityId = item.entityId.id
    const customerCompany = entityId ? customerCompaniesMap.get(entityId) : undefined
    return {
      id: item.id,
      title: customerCompany?.name || getFallbackTitle(item.entityId),
      customerCompanyId: entityId,
      description: textChain(
        customerCompany?.region ? `${customerCompany?.region} region` : '',
        item.assignType === 'AUTO' ? 'Assigned automatically' : 'Assigned manually',
        item.expirationDate ? `Until: ${formatDate(item.expirationDate)}` : 'Permanent',
      ),
      deleteDisabled: item.assignType === 'AUTO',
      avatar: customerCompany?.region
        ? {
            title: customerCompany?.region,
            image: getRegionFlagImageUrl(customerCompany?.region),
          }
        : undefined,
    }
  })
}

export const getAccessModifiers = ({
  companyIds,
  justification,
  expirationDate,
}: AddCompanyPopupData): AccessModifierPayload[] => {
  return companyIds.map((companyId) => ({
    type: 'ALLOWED',
    expirationDate,
    justification,
    entityId: {
      id: companyId,
      type: 'COMPANY',
    },
  }))
}
