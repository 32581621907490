import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { extractErrorMsg } from 'utils/error/extractErrorMsg'

type ErrorPopupProps = {
  title: string
  message?: string
  error?: unknown
  continueLabel?: string
  isLoading?: boolean
  retryLabel?: string
  onContinueClick?: () => void
  onRetryClick?: () => void
}

export const useErrorPopup = () => {
  const { show, hide } = useStatusPopup()

  const showErrorPopup = useCallback(
    ({
      title,
      message,
      error,
      isLoading,
      retryLabel,
      onRetryClick,
      continueLabel = 'Continue',
      onContinueClick = hide,
    }: ErrorPopupProps) => {
      const showRetry = retryLabel && onRetryClick

      const resultMessage = message || extractErrorMsg(error)

      show(
        <StatusPopup variant="error">
          <StatusPopup.Title>{title}</StatusPopup.Title>
          <StatusPopup.Description>{resultMessage}</StatusPopup.Description>
          <StatusPopup.Actions>
            {showRetry && (
              <Button
                elevated
                onClick={onRetryClick}
                variant="primary"
                pending={isLoading}
              >
                {retryLabel}
              </Button>
            )}
            <Button
              elevated
              onClick={onContinueClick}
              variant={showRetry ? 'secondary' : 'primary'}
            >
              {continueLabel}
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    },
    [show, hide],
  )

  return {
    showErrorPopup,
    hideErrorPopup: hide,
  }
}
