import { Text, TextSkeleton } from '@revolut/ui-kit'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { stringifySubjectType } from 'view/Sam/utils'

type Props = {
  policyId: string
}

export const Subtitle = ({ policyId }: Props) => {
  const { data, fetchStatus: fs, status: qs } = useQuerySamPolicy(policyId)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderLoading={() => <TextSkeleton size={6} />}
      renderError={() => null}
      renderIdle={() => null}
      renderSuccess={({ data: policy }) => (
        <Text>{`${stringifySubjectType(policy.subject.subjectType)} policy`}</Text>
      )}
    />
  )
}
