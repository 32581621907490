import { useCurrentUser } from 'queries/idave/users'
import { useMemo } from 'react'

export type UserIds = {
  idave?: string
  peopleOps?: string
}

export const useCurrentUserIds = () => {
  const query = useCurrentUser()

  const data = useMemo(
    () => ({
      idave: query.data?.id,
      peopleOps: query.data?.externalRef?.reference,
    }),
    [query.data],
  )

  return {
    ...query,
    data,
  }
}
