import samApi from 'api/sam'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'helpers/configQuery'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { makeMappedDataHook } from '../utils'

export const useQueryRevolutersDictionaries = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.RevolutersDictionaries],
    queryFn: samApi.revoluters.getDictionaries,
    enabled: hasPermission(SAM_PERMISSIONS.REVOLUTERS_STRUCTURE_VIEW_LIST),
    select: (response) => response.data,
  })
}

export const useQueryTeamMap = makeMappedDataHook(
  useQueryRevolutersDictionaries,
  ({ teams }) => teams,
  (team) => team.id,
)

export const useQuerySpecMap = makeMappedDataHook(
  useQueryRevolutersDictionaries,
  ({ specialisations }) => specialisations,
  (spec) => spec.id,
)

export const useQueryDepartmentMap = makeMappedDataHook(
  useQueryRevolutersDictionaries,
  ({ departments }) => departments,
  (department) => department.id,
)
