import { Subheader, Box } from '@revolut/ui-kit'
import { Account } from 'api/idave/accounts'
import { EmptyScope } from './components/EmptyScope'
import { ClientScopes } from './components/ClientScopes'

type Props = {
  account: Account
}

export const Scopes = ({ account }: Props) => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Scopes</Subheader.Title>
      </Subheader>
      {!account.scope.length ? <EmptyScope /> : <ClientScopes clients={account.scope} />}
    </Box>
  )
}
