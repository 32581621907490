import { DataPoint, TextSkeleton } from '@revolut/ui-kit'
import { times } from 'lodash'

const SKELETONS_COUNT = 3

export const LabelSkeletons = () => (
  <>
    {times(SKELETONS_COUNT, (idx) => (
      <DataPoint key={idx}>
        <DataPoint.Value use="h3">
          <TextSkeleton size={2} />
        </DataPoint.Value>
        <DataPoint.Label>
          <TextSkeleton size={6} />
        </DataPoint.Label>
      </DataPoint>
    ))}
  </>
)
