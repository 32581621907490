import { VFC } from 'react'
import { Text } from '@revolut/ui-kit'
import { SENIORITY } from 'constants/seniority'

type UserLinkProps = {
  id: string | null | undefined
}

export const RevolutersSeniority: VFC<UserLinkProps> = ({ id }) => {
  if (!id) {
    return <>-</>
  }

  return <Text>{SENIORITY[id] || id}</Text>
}
