import { customerCompany } from './customerCompanies'
import { employeeCompany } from './employeeCompanies'
import { user } from './user'
import { employee } from './employee'
import { auditLog } from './auditLog'
import { crossChecks } from './crossChecks'

const api = {
  customerCompany,
  employeeCompany,
  user,
  employee,
  auditLog,
  crossChecks,
}

export default api
