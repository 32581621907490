import { Box, Subheader } from '@revolut/ui-kit'
import { ToxicPair } from 'api/idave/toxic'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { EntityDescription } from 'components/EntityDescription'
import { PermissionLink } from 'components/Links/PermissionLink'
import { UserLink } from 'components/Links/UserLink'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { formatDateTime } from 'utils/date'

type Props = {
  toxicPair: ToxicPair
}

export const ToxicPairInfo = ({ toxicPair }: Props) => {
  return (
    <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
      <Subheader variant="nested">
        <Subheader.Title>Details</Subheader.Title>
      </Subheader>
      <DetailsGroup
        rows={[
          ['Name', toxicPair.name],
          ['ID', <CopyableText value={toxicPair.id} key={toxicPair.id} />],
          ['Owner', <UserLink id={toxicPair.owner} key={toxicPair.owner} />],
          [
            'First permission',
            <PermissionLink
              id={toxicPair.firstPermission}
              withClient
              key={toxicPair.firstPermission}
            />,
          ],
          [
            'Second permission',
            <PermissionLink
              id={toxicPair.secondPermission}
              withClient
              key={toxicPair.secondPermission}
            />,
          ],
          ['Review date', formatDateTime(toxicPair.reviewDate)],
        ]}
      />
      <EntityDescription description={toxicPair.justification} title="Justification" />
    </Box>
  )
}
