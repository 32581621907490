import { SelectEntitiesTableOverlay } from 'components/EntitiesTable'
import { useQueryPermissionIdMap, useQueryPermissions } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { mergeQueryStatuses } from 'utils/query'
import { useCallback, useMemo } from 'react'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { PresenceMap } from 'utils/array/toPresenceMap'
import { notNullableMap } from 'utils/array'
import { COLUMNS } from '../columns'
import { getRows } from './PermissionList/utils'
import { Row } from './PermissionList/types'

type Props = {
  addedPermissions: PresenceMap
  onClose: () => void
  onAdd: (ids: string[]) => void
}

export const AddPermissions = ({ onClose, onAdd, addedPermissions }: Props) => {
  const {
    data: permissions = [],
    status: permissionS,
    fetchStatus: permissionFS,
  } = useQueryPermissions()
  const { data: permissionMap = new Map() } = useQueryPermissionIdMap()
  const {
    data: clientMap = new Map(),
    status: clientsQS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  const queryResult = mergeQueryStatuses(
    { qs: clientsQS, fs: clientsFS },
    { qs: permissionS, fs: permissionFS },
  )

  const permissionIds = useMemo(
    () =>
      notNullableMap(permissions, ({ id }) => (!addedPermissions[id] ? id : undefined)),
    [permissions, addedPermissions],
  )

  const rows = useMemo(
    () =>
      getRows({
        permissionIds,
        permissionMap,
        clientMap,
      }),
    [permissionIds, permissionMap, clientMap],
  )

  const onSelect = useCallback(
    (items: Row[]) => {
      onAdd(items.map(({ id }) => id))
      onClose()
    },
    [onAdd, onClose],
  )

  return (
    <SelectEntitiesTableOverlay
      columns={COLUMNS}
      entities={rows}
      entitiesTypeLabel="Permissions"
      onClose={onClose}
      onSelect={onSelect}
      loadingState={getLoadingState(queryResult, rows.length)}
      title="Permissions"
      pluralForms={['permission', 'permissions']}
    />
  )
}
