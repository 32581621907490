import { Page, TabBar } from '@revolut/ui-kit'
import { TabBarLink } from 'components/TabBarLink'
import { IDAVE_PERMISSIONS } from 'security'
import { Url } from 'routing'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { ToxicPairs } from './ToxicPairs'
import { PermissionList } from './PermissionList'
import { ToxicAlerts } from './ToxicAlerts'

type PermissionsProps = {
  tab: 'permissions' | 'toxicPairs' | 'toxicAlerts'
}

export const Permissions = ({ tab }: PermissionsProps) => {
  return (
    <>
      <Page.Header>Permissions</Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST}>
            <TabBarLink to={Url.Permissions}>Permissions</TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck permission={IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_VIEW_LIST}>
            <TabBarLink to={Url.ToxicPairs}>Toxic pairs</TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck
            permission={IDAVE_PERMISSIONS.TOXIC_PERMISSION_ALERTS_VIEW_LIST}
          >
            <TabBarLink to={Url.ToxicAlerts}>Alerts</TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>
        {tab === 'permissions' && <PermissionList />}

        {tab === 'toxicPairs' && <ToxicPairs />}

        {tab === 'toxicAlerts' && <ToxicAlerts />}
      </Page.Main>
    </>
  )
}
