import { AxiosError, AxiosResponse } from 'axios'
import Bowser from 'bowser'
import { startCase } from 'lodash'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { ActionResponse } from 'api/types/actionResponse'

export const isBrowserSupported = () => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  return browser.satisfies({
    chrome: '>=51',
    edge: '>=16',
    opera: '>=39',
    firefox: '>=60',
    safari: '>=12',
  })
}

const CROSS_CHECK_ID_HEADER = 'x-crosscheck-id'
export const getCrossCheckIdFromResponse = (response: AxiosResponse) =>
  response.headers[CROSS_CHECK_ID_HEADER]

export function toStringOption<T extends string>(value: T): SelectOptionItemType<T> {
  return {
    key: value,
    label: startCase(value),
    value,
  }
}

export function getErrorMesasge(error: AxiosError<ActionResponse>): string {
  if (error.response?.data.message) {
    return error.response.data.message
  }

  if (error.response?.data.detail) {
    if (typeof error.response.data.detail === 'object') {
      return JSON.stringify(error.response.data.detail)
    }

    return error.response.data.detail
  }

  if (error.response?.data) {
    return JSON.stringify(error.response.data)
  }

  return error.message
}
