import { MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { QueryKey } from 'helpers/configQuery'
import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback, useEffect } from 'react'
import { useCurrentUserIds } from 'hooks/useCurrentUserIds'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useToasts } from 'hooks/useToasts'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getCrossCheckApi } from 'view/CrossChecks/utils'
import { DeclinePopup } from './DeclinePopup'
import { canDecline } from './utils'
import { DeclineParams } from './types'

type Props = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
}
const POPUP_TITLE = 'Decline request'
export const DeclineAction = (props: Props) => {
  const { crossCheck, source } = props

  const queryClient = useQueryClient()

  const { setPopup, closePopup, popup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()
  const { mutate } = useMutation({
    mutationFn: ({ id, reason }: DeclineParams) => {
      const api = getCrossCheckApi(source)
      return api.decline(id, {
        reason,
      })
    },
    onMutate: () => {
      showLoadingPopup({ title: 'Declining...' })
    },
    onSuccess: () => {
      hideLoadingPopup()
      showSuccessToast('Crosscheck declined')
      queryClient.invalidateQueries([QueryKey.CrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.SamCrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.DartCrossCheckDetails, crossCheck?.id])
      closePopup()
    },
    onError: (err: Error) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Decline failed', error: err })
    },
  })

  const submit = useCallback(
    (params: DeclineParams) => {
      mutate(params)
    },
    [mutate],
  )

  const openDeclinePopup = useCallback(
    () =>
      setPopup({
        title: POPUP_TITLE,
        isBottomSheet: true,
        headerVariant: 'bottom-sheet',
        content: <DeclinePopup source={source} onSubmit={submit} id={crossCheck.id} />,
      }),
    [source, crossCheck, submit, setPopup],
  )

  const { data: userIds } = useCurrentUserIds()
  const permissionUtils = usePermissions()

  // closing opened Decline popup after unmounting
  useEffect(
    () => () => {
      if (popup?.title === POPUP_TITLE) {
        closePopup()
      }
    },
    [popup, closePopup],
  )

  if (!canDecline({ source, crossCheck, userIds, permissionUtils })) {
    return null
  }

  return (
    <MoreBar.Action useIcon="Reverted" onClick={openDeclinePopup}>
      Decline
    </MoreBar.Action>
  )
}
