import { TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { formatDateTime } from 'utils/date'
import { AuditLogRow } from './types'

export const getColumns = (isAllDataSettled: boolean): TableColumn<AuditLogRow>[] => {
  const filter = isAllDataSettled
    ? {
        filter: 'includesValue' as const,
        Filter: SelectItemFilter,
      }
    : undefined

  const loadingCell = !isAllDataSettled
    ? {
        Cell: () => <TextSkeleton size={16} />,
      }
    : undefined

  return [
    {
      Header: 'Event',
      accessor: 'event',
      id: 'event',
      ...filter,
    },
    {
      Header: 'Action',
      accessor: 'action',
      ...filter,
    },
    {
      Header: 'Entity',
      accessor: 'entity',
      ...filter,
      ...loadingCell,
    },
    {
      Header: 'Author',
      accessor: 'author',
      ...filter,
      ...loadingCell,
    },
    {
      Header: 'Date',
      id: 'date',
      accessor: (row) => formatDateTime(row.date),
      disableSortBy: true,
    },
  ]
}
