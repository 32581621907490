import { TableColumn } from '@revolut/ui-kit'
import { SamResource } from 'api/sam/resources'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'

interface Resource extends SamResource {
  id: string
}

export const columns: TableColumn<Resource>[] = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'resourceName',
  },
  {
    Header: 'Type',
    accessor: (resource) => getResourceTypeLabel(resource.resourceType),
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'ID',
    accessor: 'resourceId',
  },

  {
    Header: 'External ID',
    accessor: 'externalId',
  },
]
