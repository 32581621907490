import { Box, Cell, Group, Subheader } from '@revolut/ui-kit'
import { DataMaps } from 'view/AuditLogs/types'
import { Permissions } from '../../Permissions'
import { Roles } from './components/Roles'
import { Users } from './components/Users/Users'
import { TDataList } from '../types'

type DataListProps = {
  dataList: TDataList
  dataMaps: DataMaps
}

export const DataList = ({ dataList, dataMaps }: DataListProps) => {
  if (dataList.entity === 'Role') {
    return <Roles dataMaps={dataMaps} title={dataList.title} rolesIds={dataList.items} />
  }
  if (dataList.entity === 'User') {
    return <Users dataMaps={dataMaps} title={dataList.title} userIds={dataList.items} />
  }
  if (dataList.entity === 'Permission') {
    return (
      <Permissions
        dataMaps={dataMaps}
        title={dataList.title}
        permissionsIds={dataList.items}
      />
    )
  }
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{dataList.title}</Subheader.Title>
      </Subheader>
      <Group>
        {dataList.items.map((item) => (
          <Cell key={item}>{item}</Cell>
        ))}
      </Group>
    </Box>
  )
}
