import { InfoAction as TInfoAction } from 'components/HeaderActions/types'
import { useCallback } from 'react'
import { SideboxOpenParams } from 'view/SideBox/types'
import { HeaderActionButton } from '../HeaderActionButton'
import { getInfoSidebox } from './utils'
import { useInfoAutoOpen } from './hooks'

type Props = {
  action: TInfoAction
  openSide: (values: SideboxOpenParams) => void
}

export const InfoAction = ({ action, openSide }: Props) => {
  const { page, autoOpen } = action
  const openSideInfo = useCallback(() => {
    openSide(getInfoSidebox(page))
  }, [page, openSide])
  useInfoAutoOpen({ openSideInfo, autoOpen })

  return (
    <HeaderActionButton
      onClick={openSideInfo}
      useIcon="QuestionOutline"
      aria-label="Info"
    />
  )
}
