import { useCallback, useMemo, useState } from 'react'
import { filterActiveIds, getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { EntitiesTable } from 'components/EntitiesTable'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryUserIdMap } from 'queries/idave/users'
import { mergeQueryStatuses } from 'utils/query'
import { Row } from './types'
import { getRows } from './utils'
import { useColumns } from './columns'
import { USER_LABEL_PLURAL_FORMS } from './constants'
import { Actions } from './components/Actions'
import { useAddUserPopup } from './components/AddUserPopup'

export type UsersTableProps = {
  userIds?: string[]
  onAdd: (userIds: string[]) => void
  onDelete: (userIds: string[]) => void
  reset: () => void
  showActions?: boolean
}

export const UsersTable = ({
  userIds,
  onAdd,
  onDelete,
  reset,
  showActions,
}: UsersTableProps) => {
  const {
    data: userMap = new Map(),
    status: usersS,
    fetchStatus: usersFS,
  } = useQueryUserIdMap()
  const {
    data: avatars,
    status: avatarsS,
    fetchStatus: avatarsFS,
  } = useQueryMappedAvatars()

  const queryResult = mergeQueryStatuses(
    { qs: usersS, fs: usersFS },
    { qs: avatarsS, fs: avatarsFS },
  )

  const rows = useMemo(
    () =>
      getRows({
        userIds,
        userMap,
        avatars,
      }),
    [userIds, avatars, userMap],
  )

  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities: rows,
  })

  const loadingState = getLoadingState(queryResult, rows.length)

  const getRowLink = useCallback(
    (row: Row) => generatePath(Url.User, { userId: row.id }),
    [],
  )

  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const columns = useColumns()
  const switchShowSelected = useCallback(
    () => setShowSelected((showSelectedValue) => !showSelectedValue),
    [setShowSelected],
  )
  const selectedCount = filterActiveIds(selectedHash).length

  const { showAddUserPopup } = useAddUserPopup(onAdd)

  const onDeleteClick = useCallback(() => {
    const selectedUserIds = Object.keys(selectedHash)
    onDelete(selectedUserIds)
    reset()
  }, [onDelete, reset, selectedHash])

  const renderActions = useCallback(
    () =>
      showActions && (
        <Actions
          selectedCount={selectedCount}
          onRemoveClick={onDeleteClick}
          onAddClick={showAddUserPopup}
        />
      ),
    [showActions, onDeleteClick, selectedCount, showAddUserPopup],
  )

  return (
    <EntitiesTable
      totalCount={rows.length}
      entitiesTypeLabel="Users"
      pluralForms={USER_LABEL_PLURAL_FORMS}
      data={searched}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
      searchValue={searchValue}
      searchAutoFocus
      columns={columns}
      getRowLink={getRowLink}
      selectedHash={selectedHash}
      switchShowSelected={switchShowSelected}
      showSelected={showSelected}
      setSelectedHash={showActions ? setSelectedHash : undefined}
      renderActions={renderActions}
    />
  )
}
