import { Route, Routes } from 'react-router-dom'
import { baseRoute, ErrorElement, Url } from 'routing'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import { SignIn } from './SignIn'
import { UILayout } from './Layout'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

export const RootRoutes = () => {
  return (
    <SentryRoutes>
      <Route path={Url.ToSignIn} Component={SignIn} errorElement={<ErrorElement />} />
      <Route
        path={baseRoute()}
        index
        Component={UILayout}
        errorElement={<ErrorElement />}
      />
    </SentryRoutes>
  )
}
