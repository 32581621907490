import { Box, Cell, Copyable, Group, Subheader, Text, Token } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useToasts } from 'hooks/useToasts'

export const UserAccessLogSideUA = (props: { userAgent: string }) => {
  const { showSuccessToast } = useToasts()
  const successCopy = useCallback(() => showSuccessToast('Copied'), [showSuccessToast])

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>User agent</Subheader.Title>
      </Subheader>

      <Group>
        <Cell>
          <Copyable value={props.userAgent} labelButtonCopy="Copy" onCopy={successCopy}>
            <Text variant="caption" use="p" color={Token.color.foreground}>
              {props.userAgent}
            </Text>
          </Copyable>
        </Cell>
      </Group>
    </Box>
  )
}
