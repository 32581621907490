import { Table, TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { RoleLink } from 'components/Links/RoleLink'
import { RoleRow } from './types'

export const COLUMNS: TableColumn<RoleRow>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (params: { row: { original: RoleRow } }) => {
      const { id } = params.row.original
      return (
        <Table.Cell>
          <RoleLink id={id} />
        </Table.Cell>
      )
    },
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Parent',
    accessor: 'parentName',
    Cell: (params: { row: { original: RoleRow } }) => {
      const { parentName, parentId } = params.row.original
      return <Table.Cell>{parentId ? <RoleLink id={parentId} /> : parentName}</Table.Cell>
    },
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
