import { Group, useVirtualViewport } from '@revolut/ui-kit'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem as TUserListItem } from 'api/idave/user'
import { UserListVariant } from 'components/UserList/types'
import { useCallback, useRef } from 'react'
import { UserListItem } from './components/UserListItem'
import { getSelectedValues } from './utils'
import { EmptyList } from './components/EmptyList'

type UserListProps = {
  update: (ids: string[]) => void
  items: TUserListItem[]
  selectedHash: Record<string, boolean>
  variant: UserListVariant
  scrollRef?: React.RefObject<HTMLElement>
  avatars?: Map<string, RevolutersAvatar>
  disabled?: boolean
  usePeopleOpsIds?: boolean
}

export const UserList = ({
  items,
  scrollRef,
  avatars,
  variant,
  selectedHash,
  usePeopleOpsIds,
  update,
}: UserListProps) => {
  const parentRef = useRef<HTMLElement>(null)
  const estimateSize = useCallback(() => 76, [])
  const keyExtractor = useCallback((index: number) => items[index].id, [items])

  const list = useVirtualViewport({
    parentRef,
    size: items.length,
    estimateSize,
    keyExtractor,
    overscan: 16,
    scrollRef,
  })

  const onItemChange = useCallback(
    (newItemId: string) =>
      update(
        getSelectedValues({
          selectedHash,
          variant,
          newItemId,
        }),
      ),
    [update, selectedHash, variant],
  )

  if (!items.length) {
    return <EmptyList />
  }

  return (
    <Group
      ref={parentRef}
      style={{
        position: 'relative' as const,
        height: `${list.totalSize}px`,
      }}
    >
      {list.virtualItems.map((virtual) => {
        const userItem = items[virtual.index]

        return (
          <UserListItem
            key={userItem.id}
            onChange={onItemChange}
            selectedHash={selectedHash}
            user={userItem}
            variant={variant}
            virtualItem={virtual}
            avatars={avatars}
            usePeopleOpsIds={usePeopleOpsIds}
          />
        )
      })}
    </Group>
  )
}
