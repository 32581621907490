import { VStack } from '@revolut/ui-kit'
import { PermissionInfo } from './components/PermissionInfo'
import { PermissionRoles } from './components/PermissionRoles'
import { UserPermissionRoles } from './components/UserPermissionRoles'

type Props = {
  permissionId: string
  userId?: string
}

export const Body = ({ permissionId, userId }: Props) => {
  return (
    <VStack space="s-16">
      <PermissionInfo permissionId={permissionId} />
      <UserPermissionRoles permissionId={permissionId} userId={userId} />
      <PermissionRoles permissionId={permissionId} />
    </VStack>
  )
}
