import { useQueryClients } from 'queries/idave/clients'
import { mergeQueryStatuses } from 'utils/query'
import { Client } from 'api/idave/clients'
import { IDAVE_PERMISSIONS } from 'security'
import { useMemo } from 'react'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { BaseSelect, BaseSelectWrapperProps } from './BaseSelect'

export type ClientSelectProps = {
  excludeIds?: string[]
} & BaseSelectWrapperProps<Client>

export const ClientSelect = ({ excludeIds = [], ...props }: ClientSelectProps) => {
  const { data: clients = [], status: qs, fetchStatus: fs } = useQueryClients()
  const excludeIdsMap = useMemo(() => toPresenceMap(excludeIds), [excludeIds])
  const items = useMemo(
    () => clients.filter(({ id }) => !excludeIdsMap[id]),
    [excludeIdsMap, clients],
  )

  const queryResult = mergeQueryStatuses({ qs, fs })

  return (
    <BaseSelect
      items={items}
      requiredPermission={IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST}
      queryResult={queryResult}
      {...props}
    />
  )
}
