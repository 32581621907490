import { TableColumn } from '@revolut/ui-kit'
import { formatDateTimePrecise } from 'utils/date'

export type PermissionRow = {
  id: string
  name: string
  createdDate: number
}

export const COLUMNS: TableColumn<PermissionRow>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Created',
    accessor: (row) => formatDateTimePrecise(row.createdDate),
  },
]
