import { useQueryAccount } from 'queries/idave/accounts'
import { useQueryClient } from 'queries/idave/clients'
import { TextSkeleton } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { QuerySwitch } from 'components/QuerySwitch'
import { InternalLink } from './InternalLink'

type Props = {
  id: string
}

export const AccountLink = ({ id }: Props) => {
  const {
    data: account,
    status: qs,
    fetchStatus: fs,
  } = useQueryAccount({ accountId: id })
  const {
    data: client,
    status: clientQs,
    fetchStatus: clientFs,
  } = useQueryClient(account?.clientId)
  const href = generatePath(Url.Account, { accountId: id })

  return (
    <InternalLink href={href} title={client?.name}>
      <QuerySwitch
        required={[{ qs, fs }]}
        optional={[{ qs: clientQs, fs: clientFs }]}
        data={client}
        renderError={() => id}
        renderIdle={() => id}
        renderLoading={() => <TextSkeleton size={12} />}
        renderSuccess={({ data }) => {
          return `${data.name} (Account)`
        }}
      />
    </InternalLink>
  )
}
