import { RolePolicies } from './components/RolePolicies'
import { UserRolePolicies } from './components/UserPolicies/UserPolicies'

export const Policies = ({ roleId, userId }: { roleId: string; userId?: string }) => {
  return userId ? (
    <UserRolePolicies roleId={roleId} userId={userId} />
  ) : (
    <RolePolicies roleId={roleId} />
  )
}
