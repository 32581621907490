import { Box, TableFilters, useIntersectViewport } from '@revolut/ui-kit'
import { useQueryAuditLogs } from 'queries/idave/logs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EntitiesTable } from 'components/EntitiesTable'
import { useMapify } from 'hooks/useMapify'
import { DataMaps } from 'view/AuditLogs/types'
import { CalendarFilter, DateFilterValue } from 'components/CalendarFilter'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { AuditLogRow } from './types'
import { formatLogs } from './utils'
import { getColumns } from './columns'
import { useAuditSidebox } from '../../../../components/previews/AuditLogPreview/hook'

type AuditLogViewProps = {
  isAllDataSettled: boolean
  dataMaps: DataMaps
  modelId?: string
}

export const AuditLogsTable = ({
  isAllDataSettled,
  dataMaps,
  modelId,
}: AuditLogViewProps) => {
  const [dateFilter, setDateFilter] = useState<DateFilterValue>(null)

  const limits = useMemo(
    () =>
      dateFilter
        ? {
            from: dateFilter.from.getTime(),
            to: dateFilter.to.getTime(),
          }
        : undefined,
    [dateFilter],
  )

  const {
    data: auditLogs,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status: logsStatus,
  } = useQueryAuditLogs({ modelId, limits })
  const [filters, setFilters] = useState<TableFilters<AuditLogRow>>([])
  const filterMap = useMapify(filters, (f) => f.id)
  const logItems = useMemo(
    () => (auditLogs?.pages || []).flatMap((page) => page.data),
    [auditLogs],
  )
  const logs = useMemo(
    () => formatLogs(logItems, filterMap, dataMaps, limits),
    [logItems, filterMap, dataMaps, limits],
  )

  // load data ourself because of bug in Table
  // DEUI-2677
  const [isIntersected, setIntersected] = useState(false)
  const afterRef = useRef(null)
  useIntersectViewport(afterRef, setIntersected)
  useEffect(() => {
    if (isIntersected && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [isIntersected, hasNextPage, isFetchingNextPage, fetchNextPage])

  const columns = useMemo(() => getColumns(isAllDataSettled), [isAllDataSettled])
  const { openAuditLog: openLog } = useAuditSidebox()
  const onLogItemClick = useCallback(
    (row: AuditLogRow) => {
      openLog(row.id)
    },
    [openLog],
  )
  const loadingStatus = logs.length ? 'success' : logsStatus
  const dataLabels = useMemo(
    () => [
      {
        value: logItems.length,
        label: 'Loaded',
      },
    ],
    [logItems.length],
  )

  return (
    <>
      <EntitiesTable
        isStickyHeader
        entitiesTypeLabel="Logs"
        pluralForms={['log', 'logs']}
        key={String(isAllDataSettled)}
        columns={columns}
        data={logs}
        loadingState={getLoadingState(loadingStatus, logs.length)}
        onFilter={setFilters}
        hasMore={hasNextPage}
        manualFilters
        searchAutoFocus
        dataLabels={dataLabels}
        onRowClick={onLogItemClick}
        renderFiltersLeft={() => (
          <CalendarFilter value={dateFilter} onChange={setDateFilter} exceptFuture />
        )}
      />
      <Box ref={afterRef} />
    </>
  )
}
