import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { NewClientPayload } from 'api/idave/clients'
import { QueryKey } from 'helpers/configQuery'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'

export const useNewClient = ({ onSuccess }: { onSuccess: () => void }) => {
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { toCrosscheck, isCrossCheck } = useCrossCheck(
    IDAVE_PERMISSIONS.CLIENTS_CREATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_CLIENT_CREATE_ACTION,
  )

  return useMutation({
    mutationFn: (newClient: NewClientPayload) =>
      idaveApi.clients.addClient({
        payload: newClient,
        crossCheck: isCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Creating...' }),
    onSuccess: (response) => {
      hideLoadingPopup()
      onSuccess()
      const crosscheckId = getCrossCheckIdFromResponse(response)
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        queryClient.resetQueries({
          queryKey: [QueryKey.Clients],
          type: 'all',
        })
        showSuccessToast('Application added')
        const clientId = 'id' in response.data ? response.data.id : undefined
        const url = clientId ? generatePath(Url.Client, { clientId }) : Url.Clients
        navigate(url)
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Application creation failed',
        error: err,
      })
    },
  })
}
