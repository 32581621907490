import { useCallback, useMemo, VFC } from 'react'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { TextSkeleton } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { QuerySwitch } from 'components/QuerySwitch'
import { InternalLink } from './InternalLink'

type PolicyLinkProps = {
  id: string
}

export const PolicyLink: VFC<PolicyLinkProps> = ({ id }) => {
  const { data: policy, status: qs, fetchStatus: fs } = useQuerySamPolicy(id)

  const policyName = useMemo(() => policy?.policyName || id, [id, policy])
  const href = generatePath(Url.SamPolicy, { policyId: id })
  const LinkConponent = useCallback(
    () => <InternalLink href={href}>{policyName || id}</InternalLink>,
    [id, policyName, href],
  )

  return (
    <QuerySwitch
      data={policy}
      required={[{ qs, fs }]}
      renderError={LinkConponent}
      renderIdle={LinkConponent}
      renderLoading={() => (
        <InternalLink href={href}>
          <TextSkeleton size={17} />
        </InternalLink>
      )}
      renderSuccess={LinkConponent}
    />
  )
}
