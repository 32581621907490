import { Role, RolePayloadMVP } from 'api/idave/roles'
import { ToxicPair } from 'api/idave/toxic'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { RoleEditFormState } from 'view/Roles/components/RoleEditForm'
import { isEqual, uniq } from 'lodash'
import { traverseRoleParents } from 'utils/role/traverseRoleParents'
import { FormTabs } from './types'
import { isRoleDetailsFilled, mergeStateAndRole } from '../utils'

export const getRolePayload = (
  roleDetails: RoleEditFormState,
  permissions: string[],
): RolePayloadMVP => {
  return {
    name: roleDetails.name.trim(),
    parent: roleDetails.parent,
    description: roleDetails.description,
    ownerGroupId: roleDetails.ownerGroupId,
    approvers: roleDetails.approverId ? [roleDetails.approverId] : undefined,
    permissions,
  }
}

export const getToxicPermissions = (
  permissionsIds: string[],
  toxicPermissions: ToxicPair[],
) => {
  const presenceMap = toPresenceMap(permissionsIds)
  return toxicPermissions.filter(
    (i) => presenceMap[i.firstPermission] && presenceMap[i.secondPermission],
  )
}

type MakeCheckTabValidParams = {
  role?: Role
  loading?: boolean
  roleDetails: RoleEditFormState
  toxicPairsCount: number
}

export const makeCheckTabValid =
  ({ role, loading, roleDetails, toxicPairsCount }: MakeCheckTabValidParams) =>
  (tab: FormTabs) => {
    if (loading) {
      return false
    }

    switch (tab) {
      case 'details':
        return !(role && isEqual(mergeStateAndRole(roleDetails, role), role))
      case 'permissions':
        return !toxicPairsCount
      default:
        return true
    }
  }

type MakeCheckTabFilledParams = {
  loading?: boolean
  roleDetails: RoleEditFormState
}
export const makeCheckTabFilled =
  ({ roleDetails, loading }: MakeCheckTabFilledParams) =>
  (tab: FormTabs) => {
    if (loading) {
      return false
    }

    switch (tab) {
      case 'details':
        return isRoleDetailsFilled(roleDetails)
      case 'permissions':
        return true
      default:
        return false
    }
  }

type GetAllPermissionsParams = {
  parent?: string
  roleMap: Map<string, Role>
}
/**
 * Returns permission ids which role gets if it has specific parent
 */
export const getInheritedPermissions = ({ parent, roleMap }: GetAllPermissionsParams) => {
  if (!parent) {
    return []
  }

  const traversedRoles = traverseRoleParents({ roleId: parent, roleMap })
  const roles = [parent, ...traversedRoles]
  return uniq(roles.flatMap((id) => roleMap.get(id)?.permissions || []))
}
