import { useController, useFormContext } from 'react-hook-form'
import {
  PermissionSelect,
  PermissionSelectProps,
} from 'components/Selects/PermissionSelect'
import { useOnChange } from 'utils/form/useOnChange'
import { Fielded } from 'utils/types/fielded'

export const FieldPermissionSelect = (props: Fielded<PermissionSelectProps>) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  const onChange = useOnChange(field.onChange)

  return (
    <PermissionSelect
      {...rest}
      {...field}
      itemId={field.value}
      onChange={onChange}
      ref={undefined}
    />
  )
}
