import React from 'react'
import {
  PermissionsProvider,
  PermissionsProviderWithDevTools,
} from '@revolut-internal/idave-web-auth'
import { permissions } from 'services/permissions'
import { IS_DEVELOPMENT_ENV } from 'utils/constants/misc'
import { isOnSignInPage } from 'services/auth'
import { QueryProvider } from './components/QueryProvider'
import { ToastProvider } from './components/ToastProvider'

export const AppProvider = (props: { children: React.ReactNode }) => {
  const SelectedPermissionsProvider =
    IS_DEVELOPMENT_ENV && !isOnSignInPage()
      ? PermissionsProviderWithDevTools
      : PermissionsProvider

  return (
    <React.StrictMode>
      <SelectedPermissionsProvider permissionsService={permissions}>
        <ToastProvider>
          <QueryProvider>{props.children}</QueryProvider>
        </ToastProvider>
      </SelectedPermissionsProvider>
    </React.StrictMode>
  )
}
