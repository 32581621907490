import { StatusText } from 'components/StatusText'
import { useQueryGroup } from 'queries/idave/groups'

export const GroupSubtitle = ({ groupId }: { groupId: string }) => {
  const { data: group } = useQueryGroup(groupId)

  if (group?.state === 'DELETED') {
    return <StatusText level="warning">Deleted</StatusText>
  }

  return null
}
