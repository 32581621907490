import { Box, Subheader } from '@revolut/ui-kit'
import { DetailsGroup } from 'components/DetailsGroup'
import { QuerySwitch } from 'components/QuerySwitch'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { getRevoulutersEmployeeLink } from 'components/Links/RevolutersEmployeeLink'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { useQueryEmployeeMap } from 'queries/sam/users'
import { RevolutersEmployee } from 'api/sam/revoluters'
import { capitalizeFirst } from 'utils/string'
import { RevolutersDepartmentLink } from 'components/Links/RevolutersDepartmentLink'
import { SENIORITY } from 'constants/seniority'
import { RevolutersSpecialisationLink } from 'components/Links/RevolutersSpecialisationLink'
import { RevolutersTeamLink } from 'components/Links/RevolutersTeamLink'
import { ExternalTextLink } from 'components/Links/ExternalTextLink'
import { NOT_ALLOWED_TITLE } from '../../texts'

export const UserPeopleopsProfile = ({
  userId,
  flexBasis,
}: {
  userId: string
  flexBasis: number
}) => {
  const { data: employeeMap, fetchStatus: fs, status: qs } = useQueryEmployeeMap()

  const employee = employeeMap?.get(userId)
  return (
    <Box style={{ flexBasis: `${flexBasis}px`, flexGrow: 1 }}>
      <Subheader variant="nested">
        <Subheader.Title>PeopleOps</Subheader.Title>
      </Subheader>
      <QuerySwitch
        required={[{ qs, fs }]}
        data={employee}
        renderIdle={() => <NoAccessWidget title={NOT_ALLOWED_TITLE} />}
        renderLoading={() => <DetailsGroupSkeleton />}
        renderSuccess={({ data }) => <Inner employee={data} />}
      />
    </Box>
  )
}

const Inner = ({ employee }: { employee: RevolutersEmployee }) => {
  const employeeType = capitalizeFirst(employee.employeeType)
  const seniority = SENIORITY[employee.seniorityId]

  return (
    <DetailsGroup
      rows={[
        [
          'Employee Id',
          <ExternalTextLink
            key="employeeLink"
            href={getRevoulutersEmployeeLink(employee.revolutersId)}
            text={employee.revolutersId}
          />,
        ],
        [
          'Department',
          <RevolutersDepartmentLink key="department" id={employee.departmentId} withId />,
        ],
        ['Employee type', employeeType],
        ['Seniority', seniority],
        [
          'Specialisation',
          <RevolutersSpecialisationLink
            key="spec"
            id={employee.specialisationId}
            withId
          />,
        ],
        ['Team', <RevolutersTeamLink key="team" id={employee.teamId} withId />],
      ]}
    />
  )
}
