import { IDAVE_PERMISSIONS } from 'security'
import { Url, UIRoute } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { AuditLogs } from './AuditLogs'

export const routes = ({ hasPermission }: PermissionUtils): Record<string, UIRoute> => ({
  [Url.AuditLogs]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    Component: AuditLogs,
  },
})
