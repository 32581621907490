import { ToxicPair } from 'api/idave/toxic'
import { uniq } from 'lodash'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { Pair } from 'utils/types/pairs'

/**
 * Returns permission key table with permissions ids which presence in ToxicPermissions
 * usefull for cheking pair of permission for toxic combo
 *
 * Like {
 *  [permissionId]: [permissionId2, permissionId3]
 *  [permissionId2]: [permissionId]
 *  [permissionId3]: [permissionId]
 * }
 */
export const getPermissionToxicPairsTable = (toxicPairs: ToxicPair[]) =>
  toxicPairs.reduce<Record<string, string[]>>(
    (acc, { firstPermission, secondPermission }) => {
      return {
        ...acc,
        [firstPermission]: uniq([...(acc[firstPermission] || []), secondPermission]),
        [secondPermission]: uniq([...(acc[secondPermission] || []), firstPermission]),
      }
    },
    {},
  )

type GetPermissionsToxicPairs = {
  permissions: string[]
  toxicPairs: ToxicPair[]
}

export type ToxicItems = {
  id: string
  permissions: Pair<string>
}
/**
 * Retunrs pair ids matched with permissions
 * Usefull for getting toxicPairs for specific permission set
 *
 * Like
 * [{
 *  id: ToxicPermisisonId,
 *  permissions: [firstPermission, secondPermission]
 * }, ...]
 */
export const getMatchedToxicPairs = ({
  permissions,
  toxicPairs,
}: GetPermissionsToxicPairs): ToxicItems[] => {
  const permissionPresenceMap = toPresenceMap(permissions)
  return toxicPairs.reduce<ToxicItems[]>((acc, toxicPair) => {
    if (
      permissionPresenceMap[toxicPair.firstPermission] &&
      permissionPresenceMap[toxicPair.secondPermission]
    ) {
      return [
        ...acc,
        {
          id: toxicPair.id,
          permissions: [toxicPair.firstPermission, toxicPair.secondPermission],
        },
      ]
    }
    return acc
  }, [])
}
