import { Box, StatusWidget, Subheader } from '@revolut/ui-kit'
import { ToxicPair } from 'api/idave/toxic'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { PermissionLink } from 'components/Links/PermissionLink'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { AlertDescriptor } from 'components/previews/ToxicAlertPreview/types'
import { useQueryToxicPairsMap } from 'queries/idave/permissions'

import { get3DImageSrcSetProps } from 'utils/url'

type Props = {
  toxicAlertDescriptor: AlertDescriptor
}

export const ToxicPairInfo = ({ toxicAlertDescriptor: { toxicPermissionId } }: Props) => {
  const { data: toxicPairs, fetchStatus: fs, status: qs } = useQueryToxicPairsMap()

  if (!toxicPermissionId) {
    return (
      <StatusWidget>
        <StatusWidget.Image {...get3DImageSrcSetProps('3D231', 'v2')} />
        <StatusWidget.Title>Lack of toxic pair id</StatusWidget.Title>
        <StatusWidget.Description>
          Toxic alert doesn&apos;t specify a toxic pair
        </StatusWidget.Description>
      </StatusWidget>
    )
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Toxic pair</Subheader.Title>
      </Subheader>
      <QuerySwitch
        data={toxicPairs?.get(toxicPermissionId)}
        required={[{ fs, qs }]}
        renderIdle={() => (
          <NoAccessWidget title="Looks like you don't have sufficient permissions to view toxic pairs" />
        )}
        renderLoading={() => <DetailsGroupSkeleton />}
        renderSuccess={({ data }) => <Inner toxicPair={data} />}
      />
    </Box>
  )
}
const Inner = ({ toxicPair }: { toxicPair: ToxicPair }) => {
  return (
    <DetailsGroup
      rows={[
        ['Name', toxicPair.name],
        [
          'ID',
          <CopyableText value={toxicPair.id} key="id">
            {toxicPair.id}
          </CopyableText>,
        ],
        [
          'First permission',
          <PermissionLink
            id={toxicPair.firstPermission}
            withClient
            key={toxicPair.firstPermission}
          />,
        ],
        [
          'Second permission',
          <PermissionLink
            id={toxicPair.secondPermission}
            withClient
            key={toxicPair.secondPermission}
          />,
        ],
      ]}
    />
  )
}
