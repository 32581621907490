import { Text } from '@revolut/ui-kit'
import { useQueryAuditLog } from 'queries/idave/logs'
import { formatDateTimePrecise } from 'utils/date'
import { mergeQueryStatuses } from 'utils/query'
import { SideAvatar } from 'view/SideBox'

type Props = {
  eventId: string
}

export const Avatar = ({ eventId }: Props) => {
  const { data: logs, fetchStatus: fs, status: qs } = useQueryAuditLog({ eventId })
  const status = mergeQueryStatuses({ fs, qs })

  if (status === 'loading') {
    return <SideAvatar iconName="Loading" />
  }
  const log = logs?.[0]
  if (!log) {
    return <SideAvatar iconName="Inbox" />
  }

  const date = formatDateTimePrecise(log['Event Date'].value)
  return <Text>{date}</Text>
}
