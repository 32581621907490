import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { Page, TabBar, TextSkeleton } from '@revolut/ui-kit'
import { ToxicPair as TToxicPair } from 'api/idave/toxic'
import { QuerySwitch } from 'components/QuerySwitch'
import { TabBarLink } from 'components/TabBarLink'

import { useQueryToxicPairsMap } from 'queries/idave/permissions'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'
import { ToxicPairTab } from './types'
import { ToxicPairSkeleton } from './components/ToxicPairSkeleton'
import { ToxicPairAlerts } from './components/ToxicPairAlerts'
import { ToxicPairDetails } from './components/ToxicPairDetails/ToxicPairDetails'

type Props = {
  id: string
  tab: ToxicPairTab
}
export const ToxicPair = ({ id, tab }: Props) => {
  const { data, fetchStatus, status } = useQueryToxicPairsMap()
  return (
    <QuerySwitch
      data={data?.get(id)}
      required={[{ qs: status, fs: fetchStatus }]}
      renderLoading={() => (
        <Outer toxicPairId={id}>
          <ToxicPairSkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data: toxicPair }) => (
        <Outer toxicPairId={id} toxicPair={toxicPair}>
          <Inner tab={tab} toxicPair={toxicPair} />
        </Outer>
      )}
    />
  )
}

const Outer = (props: {
  toxicPairId: string
  children: React.ReactNode
  toxicPair?: TToxicPair
}) => {
  const { children, toxicPairId, toxicPair } = props
  const navigate = useNavigate()

  return (
    <>
      <Page.Header onBack={() => navigate(Url.ToxicPairs)}>
        {toxicPair?.name || <TextSkeleton size={15} />}
      </Page.Header>

      <Page.Tabs>
        <TabBar variant="navigation" pb="s-24">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.ToxicPairDetails, { toxicPairId })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            permission={IDAVE_PERMISSIONS.TOXIC_PERMISSION_ALERTS_VIEW_LIST}
          >
            <TabBarLink to={generatePath(Url.ToxicPairAlerts, { toxicPairId })}>
              Alerts
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>

      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = (props: { toxicPair: TToxicPair; tab: ToxicPairTab }) => {
  const { toxicPair, tab } = props
  switch (tab) {
    case 'alerts':
      return <ToxicPairAlerts toxicPairId={toxicPair.id} />
    default:
    case 'details':
      return <ToxicPairDetails toxicPair={toxicPair} />
  }
}
