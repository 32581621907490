import { TableColumn } from '@revolut/ui-kit'
import { EmployeeCompany } from 'api/dart/employeeCompanies'
import { RegionTableCell } from 'components/RegionTableCell'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { capitalizeFirst } from 'utils/string'

export const COLUMNS: TableColumn<EmployeeCompany>[] = [
  {
    Header: 'Company name',
    accessor: 'name',
  },
  {
    Header: 'Region',
    accessor: 'region',
    Cell: RegionTableCell,
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Type',
    accessor: (value) => capitalizeFirst(value.type),
    id: 'type',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
