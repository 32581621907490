import { Box, Separator, Widget } from '@revolut/ui-kit'
import { getMentionsTokens } from 'hooks/useTokenize/utils'
import { TokensText, isTokensDisplayable } from 'components/TokensText'
import { SYSTEM_REQUESTERS } from 'view/CrossChecks/utils'
import { ClientItem } from 'components/ClientItem'
import { EventUserItem } from './components/EventUserItem'
import { CrossCheckEvent as TCrossCheckEvent } from '../../types'
import { CrossCheckEventMentions } from '../CrossCheckEventMentions'

export const CrossCheckEvent = (props: { event: TCrossCheckEvent }) => {
  const { event } = props

  const system = SYSTEM_REQUESTERS[event.name]

  return (
    <Widget>
      {system ? (
        <ClientItem id={system.id} name={system.name} />
      ) : (
        <EventUserItem event={event} />
      )}
      {isTokensDisplayable(event.tokens) ? (
        <Box ml="s-16" mr="s-16" pb="s-16" overflow="hidden">
          <TokensText tokens={event.tokens} />
        </Box>
      ) : null}

      {getMentionsTokens(event.tokens).length ? (
        <Box pb="s-12" pr="s-16" pl="s-16">
          <Separator pb="s-8" />
          <CrossCheckEventMentions tokens={event.tokens} />
        </Box>
      ) : null}
    </Widget>
  )
}
