import { TableColumn, Table } from '@revolut/ui-kit'
import { InternalLink } from 'components/Links/InternalLink'

export type RolesRow = {
  name: string
  permissionCount: string | number
  href?: string
}

export const COLUMNS: TableColumn<RolesRow>[] = [
  {
    Header: 'Name',
    id: 'name',
    Cell: (params: { row: { original: RolesRow } }) => {
      const { name, href } = params.row.original
      return (
        <Table.Cell>
          {href ? <InternalLink href={href}>{name}</InternalLink> : name}
        </Table.Cell>
      )
    },
    width: 3,
  },
  {
    Header: 'Permission count',
    accessor: 'permissionCount',
    width: 1,
  },
]
