import { EmptyStatusWidget } from 'components/EmptyStatusWidget'

type Props = {
  onClick: () => void
  actionAllowed?: boolean
}

export const EmptyList = ({ onClick, actionAllowed }: Props) => {
  return (
    <EmptyStatusWidget
      title="No permissions associated with the Account"
      imageCode="3D055"
      actionLabel="Add permissions"
      onClick={onClick}
      actionAllowed={actionAllowed}
    />
  )
}
