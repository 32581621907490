import { MoreBar } from '@revolut/ui-kit'
import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryAccounts } from 'queries/idave/accounts'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { waitForAll } from 'utils/query'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { getAccountRows } from './utils'
import { columns } from './columns'
import { CREATE_ACCOUNT } from '../permissions'

export const AccountList = () => {
  const { data: accounts = [], status: qs, fetchStatus: fs } = useQueryAccounts()
  const {
    data: clientMap = new Map(),
    status: clientQS,
    fetchStatus: clientFS,
  } = useQueryClientIdMap()
  const status = waitForAll([{ qs, fs }], [{ qs: clientQS, fs: clientFS }])

  const rows = useMemo(
    () => getAccountRows({ clientMap, accounts }),
    [accounts, clientMap],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: rows,
  })
  const navigate = useNavigate()
  const onClick = useCallback(
    (params: { id: string }) => {
      navigate(generatePath(Url.Account, { accountId: params.id }))
    },
    [navigate],
  )

  const onAccountCreateClick = useCallback(() => navigate(Url.AccountAdd), [navigate])
  const getRowLink = useCallback(
    (row: { id: string }) => generatePath(Url.Account, { accountId: row.id }),
    [],
  )

  return (
    <EntitiesTable
      enableNavigation
      totalCount={accounts.length}
      entitiesTypeLabel="Service account"
      pluralForms={['account', 'accounts']}
      loadingState={getLoadingState(status, accounts.length)}
      data={searched}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      onRowClick={onClick}
      getRowLink={getRowLink}
      searchAutoFocus
      renderActions={() => (
        <PermissionsCheck somePermissions={CREATE_ACCOUNT}>
          <MoreBar.Action useIcon="Plus" onClick={onAccountCreateClick}>
            Create new
          </MoreBar.Action>
        </PermissionsCheck>
      )}
    />
  )
}
