import { Role } from 'api/idave/roles'
import { getRoleByParentTable } from 'utils/role/getRoleByParentTable'

type TraverseRolesChildren = {
  roleIds: string[]
  roles: Role[]
}
/**
 * @returns list of roles parents layered by origin and layer
 * traverseRolesChildren(params) => [childRole, grandChild, grandGrandChild, otherChainChildren, otherChainGrandChildren]
 */
export const traverseRolesChildren = (params: TraverseRolesChildren) => {
  const { roleIds, roles } = params
  const rolesParentTable = getRoleByParentTable(roles)

  const childrenToTraverse = [...roleIds]
  const result: string[] = []

  let current = childrenToTraverse.pop()
  while (current) {
    const items = rolesParentTable[current]
    if (items?.length) {
      const resultItems = items.filter((i) => !result.includes(i))
      result.push(...resultItems)
      childrenToTraverse.push(...resultItems)
    }
    current = childrenToTraverse.pop()
  }

  return result
}

type TraverseRoleChildParams = { roleId: string; roles: Role[] }
/**
 * @returns list of role children with the layer order
 * traverseRoleChildren(params) => [childRole, grandChild, grandGrandChild]
 */
export const traverseRoleChildren = (params: TraverseRoleChildParams) => {
  return traverseRolesChildren({ roleIds: [params.roleId], roles: params.roles })
}
