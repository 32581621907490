import { useCallback } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { UsersTable } from 'view/System/Group/components/Users/components/UsersTable'

export const Users = () => {
  const { control } = useFormContext()
  const { field } = useController({ name: 'userIds', control, defaultValue: [] })

  const addUsers = useCallback(
    (userIds: string[]) => {
      field.onChange(Array.from(new Set([...userIds, ...field.value])))
    },
    [field],
  )

  const onDeleteClick = useCallback(
    (userIds: string[]) => {
      field.onChange(field.value.filter((userId: string) => !userIds.includes(userId)))
    },
    [field],
  )

  return (
    <UsersTable
      userIds={field.value}
      onDelete={onDeleteClick}
      onAdd={addUsers}
      showActions
    />
  )
}
