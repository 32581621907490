import { Client } from 'api/idave/clients'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { Permission } from 'api/idave/permissions'
import { PermissionRow } from './types'
import { CrossCheckEntity, CrossCheckValueTab } from '../../../../types'
import { extractEntityArrayIds } from '../../../../utils'
import { getChangeRowsInfo } from '../../utils'

type GetPermissionsParams = {
  crossCheck: CrossCheckDetail
  permissionMap: Map<string, Permission>
  clientMap: Map<string, Client>
  permissionIds?: string[]
  tabs: CrossCheckValueTab[]
}

export const getPermissionRowsData = (params: GetPermissionsParams) => {
  const { crossCheck, permissionIds, permissionMap, clientMap, tabs } = params
  const { executableParams } = crossCheck

  const added = extractEntityArrayIds(executableParams?.['New Permissions'])
  const deleted = extractEntityArrayIds(executableParams?.['Removed Permissions'])
  const values = extractEntityArrayIds(executableParams?.Permissions)
  const current = permissionIds || []

  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
    permissionMap,
    clientMap,
  })
}

type MakeGetPermissionRowParams = {
  permissionMap: Map<string, Permission>
  clientMap: Map<string, Client>
  deletedHash?: Record<string, boolean>
  addedHash?: Record<string, boolean>
}

const makeMapValue =
  ({
    permissionMap,
    clientMap,
    deletedHash = {},
    addedHash = {},
  }: MakeGetPermissionRowParams) =>
  (permissionId: string): PermissionRow => {
    const permission = permissionMap.get(permissionId)
    const clientId = permission?.client
    const client = clientId
      ? clientMap.get(clientId)?.name || clientId
      : EMPTY_VALUE_FALLBACK

    const removedStatus = deletedHash[permissionId] ? 'removed' : undefined
    const addedStatus = addedHash[permissionId] ? 'added' : undefined

    return {
      id: permissionId,
      name: permission?.name || permissionId,
      client,
      status: addedStatus || removedStatus,
    }
  }

export const getEmptyPermissionsLabel = (
  status: CrossCheckValueTab,
  entityType: CrossCheckEntity['type'],
) => {
  if (status === 'requested') {
    return "There aren't added or deleted permissions"
  }

  return entityType === 'Role'
    ? 'No permissions for the role'
    : 'No permissions for the account'
}
