import { get } from 'lodash'
import { trimUnknown } from '../../../../utils'
import { KNONW_LIST_KEYS } from './constants'
import { TDataList, ListKeyDescriptor } from './types'

export const getDataLists = (dataString?: string) => {
  if (!dataString) {
    return []
  }

  try {
    const values = JSON.parse(dataString)
    if (typeof values !== 'object') {
      return []
    }

    return keysToLists(KNONW_LIST_KEYS, values)
  } catch (_e) {
    return []
  }
}

export const keysToLists = (keys: ListKeyDescriptor[], values: Record<string, unknown>) =>
  keys.reduce<TDataList[]>((acc, { title, entity, key, mapper }) => {
    const value = get(values, key)
    if (!Array.isArray(value)) {
      return acc
    }
    const items = mapper ? mapper(value) : value
    const filteredItems = items.reduce<string[]>((result, item) => {
      const trimmed = trimUnknown(item)
      if (!trimmed) {
        return result
      }
      return [...result, trimmed]
    }, [])

    if (!Array.isArray(filteredItems) || !filteredItems.length) {
      return acc
    }

    return [...acc, { title, entity, items: filteredItems, key }]
  }, [])
