import { useLinkify } from 'hooks/useLinkify'
import { useMemo } from 'react'
import { makeTokenize } from './utils/makeTokenize'

export const useTokenize = () => {
  const linkify = useLinkify()
  const tokenize = useMemo(() => makeTokenize(linkify), [linkify])

  return {
    tokenize,
  }
}
