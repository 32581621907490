import { UserListItem } from 'api/idave/user'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { Tokenize } from 'hooks/useTokenize/types'
import { getMentionsTokens } from 'hooks/useTokenize/utils'
import { uniq } from 'lodash'
import { notNullableMap } from 'utils/array'
import { pluralForm } from 'utils/string'
import { getName } from 'utils/string/getName'
import { getEventsByType } from 'view/CrossChecks/utils'

type GetButtonPropsParams = {
  emails: string[]
  userEmailMap: Map<string, UserListItem>
}
export const getButtonProps = ({ emails, userEmailMap }: GetButtonPropsParams) => {
  if (!emails.length) {
    return {
      text: 'Notify',
    }
  }

  const firstUser = userEmailMap.get(emails[0])

  return {
    text: pluralForm(emails, [
      firstUser ? getName(firstUser) : emails[0],
      `${emails.length} notifees`,
    ]),
  }
}
type GetBubbleUpUsersParams = {
  crossCheck: CrossCheckDetail
  tokenize: Tokenize
  userEmailMap: Map<string, UserListItem>
}
export const getBubbleUpUsers = ({
  crossCheck,
  tokenize,
  userEmailMap,
}: GetBubbleUpUsersParams) => {
  const comments = getEventsByType(crossCheck.events, 'COMMENT')
  const mentionTokens = getMentionsTokens(
    comments.map(({ text }) => tokenize(text)).flat(),
  )
  const mentionedUsersIds = notNullableMap(
    mentionTokens,
    ({ value }) => userEmailMap.get(value)?.id,
  )
  const reviewers = crossCheck.reviewerGroups.map((group) => group.reviewers).flat()

  return uniq([crossCheck.requester, ...mentionedUsersIds, ...reviewers])
}
