import { MoreBar } from '@revolut/ui-kit'

import { useUserActions } from './hooks/useUserActions'

type Props = {
  userId: string
}

export const UserActions: React.FunctionComponent<Props> = ({ userId }) => {
  const actions = useUserActions({
    userId,
  })

  if (!actions.length) {
    return null
  }

  return (
    <MoreBar data-testid="actions">
      {actions.map((action) => (
        <MoreBar.Action
          key={action.label}
          variant={action.variant}
          useIcon={action.useIcon}
          onClick={action.onClick}
        >
          {action.label}
        </MoreBar.Action>
      ))}
    </MoreBar>
  )
}
