import { Client } from 'api/idave/clients'
import { normalizeClient } from 'view/Clients/utils'
import { UrlUpdateParams } from 'view/Clients/Client/components/CommonClientUrls'

export const getNewClient = (client: Client, update: UrlUpdateParams) => {
  switch (update.type) {
    case 'deleteAll':
      return { ...normalizeClient(client), redirectUris: [] }
    case 'delete':
      return {
        ...normalizeClient(client),
        redirectUris: client.redirectUris.filter((uri) => uri !== update.deleteUrl),
      }
    case 'add':
    default:
      return {
        ...normalizeClient(client),
        redirectUris: client.redirectUris.concat(update.newUrl),
      }
  }
}
export const getUpdateSuccessToast = (updateType: UrlUpdateParams['type']) => {
  switch (updateType) {
    case 'add':
      return 'URL added'
    case 'delete':
      return 'URL deleted'
    case 'deleteAll':
      return 'All URLs deleted'
    default:
      return 'URLs changed'
  }
}
