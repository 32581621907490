import { Table } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { UserStates } from 'api/idave/user'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { capitalizeFirst } from 'utils/string'

type State = UserStates | Client['state']

type Props = {
  row: { original: { state?: State } }
}

const stateToStatus = (state: State) => {
  switch (state) {
    case 'ACTIVE':
      return 'success'
    case 'DELETED':
    case 'TERMINATED':
    default:
      return 'alert'
  }
}

export const StateCell = (props: Props) => {
  const { state } = props.row.original

  if (!state) {
    return <Table.Cell align="center">{EMPTY_VALUE_FALLBACK}</Table.Cell>
  }

  return (
    <Table.StatusCell align="center" level={stateToStatus(state)}>
      {capitalizeFirst(state)}
    </Table.StatusCell>
  )
}
