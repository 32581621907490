import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'
import { Box, Group, Subheader, textChain } from '@revolut/ui-kit'
import { getUserInfo } from './utils'
import { ParticipantListItem } from './components/ParticipantListItem'

const GROUP_BIG_SIZE = 50

export const ParticipantList = ({
  items,
  title,
  avatars,
  userMap,
}: {
  items: string[]
  title: string
  avatars?: Map<string, RevolutersAvatar>
  userMap: Map<string, UserListItem>
}) => {
  if (!items.length) {
    return null
  }

  const users = getUserInfo(items.slice(0, GROUP_BIG_SIZE), userMap, avatars)
  const largeMark = items.length >= GROUP_BIG_SIZE ? 'First 50 items' : undefined

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{textChain(title, largeMark)}</Subheader.Title>
      </Subheader>
      <Group>
        {users.map((user) => (
          <ParticipantListItem user={user} key={user.id} />
        ))}
      </Group>
    </Box>
  )
}
