import { waitForAll } from 'utils/query'
import { useCallback, useMemo } from 'react'
import { IDAVE_PERMISSIONS } from 'security'
import { Permission } from 'api/idave/permissions'
import { useQueryPermissions } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import {
  Caption,
  Ellipsis,
  SelectOptionItemType,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { BaseSelect, BaseSelectWrapperProps } from './BaseSelect'

export type PermissionSelectProps = BaseSelectWrapperProps<Permission> & {
  excludeIds?: string[]
}

export const PermissionSelect = ({
  excludeIds = [],
  ...selectProps
}: PermissionSelectProps) => {
  const { data: permissions = [], status: qs, fetchStatus: fs } = useQueryPermissions()
  const {
    data: clientsMap,
    status: clientsQS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  const queryResult = waitForAll([{ qs, fs }], [{ qs: clientsQS, fs: clientsFS }])
  const OptionComponent = useCallback(
    (option: SelectOptionItemType<Permission>) => (
      <VStack>
        <Text use="p">{option.value.name}</Text>
        {clientsMap?.get(option.value.client) ? (
          <Caption color={Token.color.greyTone50}>
            <Ellipsis>{clientsMap?.get(option.value.client)?.name}</Ellipsis>
          </Caption>
        ) : null}
      </VStack>
    ),
    [clientsMap],
  )

  const itemToOption = useCallback(
    (permission: Permission) => ({
      id: permission.id,
      value: permission,
      key: permission.id,
      label: clientsMap?.get(permission.client)
        ? `${permission.name} (${clientsMap?.get(permission.client)?.name})`
        : permission.name,
    }),
    [clientsMap],
  )

  const excludedMap = useMemo(() => toPresenceMap(excludeIds), [excludeIds])
  const items = useMemo(
    () => permissions.filter(({ id }) => !excludedMap[id]),
    [excludedMap, permissions],
  )

  return (
    <BaseSelect
      items={items}
      renderOption={OptionComponent}
      requiredPermission={IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST}
      queryResult={queryResult}
      itemToOption={itemToOption}
      {...selectProps}
    />
  )
}
