import { Box, Subheader, Table } from '@revolut/ui-kit'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryRoles } from 'queries/idave/roles'
import { useQueryUser } from 'queries/idave/users'
import { User } from 'api/idave/user'
import { Role } from 'api/idave/roles'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useMemo } from 'react'
import { COLUMNS } from './columns'
import { getRoleItems } from './utils'

type Props = {
  userId?: string
  permissionId: string
}
export const UserPermissionRoles = ({ userId, permissionId }: Props) => {
  const { data: user, fetchStatus: userFS, status: userQS } = useQueryUser({ id: userId })
  const { data: roles, fetchStatus: roleFS, status: roleQS } = useQueryRoles()

  if (!userId) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Provided by roles</Subheader.Title>
      </Subheader>
      <QuerySwitch
        data
        required={[
          { qs: userQS, fs: userFS },
          { qs: roleQS, fs: roleFS },
        ]}
        renderLoading={() => <Table data={[]} columns={COLUMNS} loadingState="pending" />}
        idleText="Lack of user or roles view permission"
        renderSuccess={() => {
          if (!user || !roles) {
            return <RequestErrorWidget />
          }

          return <Inner user={user} roles={roles} permissionId={permissionId} />
        }}
      />
    </Box>
  )
}

type InnerProps = {
  user: User
  roles: Role[]
  permissionId: string
}
const Inner = ({ user, permissionId, roles }: InnerProps) => {
  const resultRoles = useMemo(
    () => getRoleItems({ permissionId, userRoles: user.roles, roles }),
    [permissionId, user.roles, roles],
  )

  return <Table columns={COLUMNS} data={resultRoles} />
}
