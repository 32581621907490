import { Box, Cell, Group, Subheader } from '@revolut/ui-kit'

export const UnknownAuthor = (props: { name: string }) => {
  const { name } = props

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Author</Subheader.Title>
      </Subheader>
      <Group>
        <Cell>{name}</Cell>
      </Group>
    </Box>
  )
}
