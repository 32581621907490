import { useQueryCustomerCompanies } from 'queries/dart/companies'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { mergeQueryStatuses } from 'utils/query'
import { DART_PERMISSIONS } from 'security'
import { BaseSelect, BaseSelectWrapperProps } from './BaseSelect'

export const CustomerCompanySelect = (
  props: BaseSelectWrapperProps<CustomerCompanyItem>,
) => {
  const {
    data: customerCompanies = [],
    status: qs,
    fetchStatus: fs,
  } = useQueryCustomerCompanies()
  const queryResult = mergeQueryStatuses({ qs, fs })

  return (
    <BaseSelect
      items={customerCompanies}
      queryResult={queryResult}
      requiredPermission={DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_LIST}
      {...props}
    />
  )
}
