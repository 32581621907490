import {
  ActionButton,
  AvatarBar,
  Button,
  Group,
  Item,
  ItemSkeleton,
  TransitionCollapse,
} from '@revolut/ui-kit'
import { EntityListProps } from './types'
import { EmptyEntityList } from './components/EmptyEntityList'
import { EntityListItem } from './components/EntityListItem'

export const EntityList = (props: EntityListProps) => {
  const {
    onAddClick,
    items,
    addActionLabel,
    addEnabled,
    isLoading,
    defaultItemsCount,
    defaultItemsThreshold = 0,
    expanded,
    onExpandClick,
    expandButtonType = 'avatarBar',
    disabled,
  } = props

  if (isLoading) {
    return (
      <Group>
        <ItemSkeleton data-testid="loading" />
      </Group>
    )
  }

  if (!items.length) {
    return <EmptyEntityList {...props} />
  }

  return (
    <>
      <Group>
        {defaultItemsCount && defaultItemsThreshold < items.length ? (
          <>
            {items.slice(0, defaultItemsCount).map((item) => (
              <EntityListItem {...props} item={item} key={item.id} />
            ))}

            {items.length > defaultItemsCount &&
              (expanded ? (
                <TransitionCollapse in={expanded}>
                  {items.slice(defaultItemsCount).map((item) => (
                    <EntityListItem {...props} item={item} key={item.id} />
                  ))}
                </TransitionCollapse>
              ) : (
                <>
                  {expandButtonType === 'avatarBar' ? (
                    <Item
                      use="button"
                      variant="disclosure"
                      p="s-16"
                      onClick={onExpandClick}
                      disabled={disabled}
                    >
                      <Item.Content>
                        <AvatarBar size={40} variant="compact">
                          {items.slice(defaultItemsCount).map((item) => (
                            <AvatarBar.Item
                              key={item.id}
                              {...item.avatar}
                              title={item.title}
                            />
                          ))}
                        </AvatarBar>
                      </Item.Content>
                    </Item>
                  ) : (
                    <Item>
                      <Button variant="secondary" onClick={onExpandClick}>
                        Show all
                      </Button>
                    </Item>
                  )}
                </>
              ))}
          </>
        ) : (
          items.map((item) => <EntityListItem {...props} item={item} key={item.id} />)
        )}
      </Group>

      {addEnabled && (
        <ActionButton
          onClick={onAddClick}
          useIcon="Plus"
          disabled={!addEnabled || disabled}
          mt="s-24"
        >
          {addActionLabel}
        </ActionButton>
      )}
    </>
  )
}
