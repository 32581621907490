import { Box, Token } from '@revolut/ui-kit'
import { captureException } from '@sentry/browser'
import { ErrorStatus } from 'components/ErrorStatus'
import { Component } from 'react'

type ErrorBoundaryProps = {
  children?: React.ReactNode
}
export class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }

  componentDidCatch(err: Error) {
    captureException(err)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box backgroundColor={Token.color.white}>
          <ErrorStatus />
        </Box>
      )
    }
    return this.props.children
  }
}
