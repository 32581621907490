import { Box, Subheader, Table } from '@revolut/ui-kit'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQueryResourceMap, useQueryResources } from 'queries/sam/resources'
import { useMemo } from 'react'
import { QueryStatus } from '@tanstack/react-query'
import { COLUMNS } from './columns'
import { Resource } from './types'

type SamResourceDetailsProps = {
  ids: string[]
}

export const SamResourcesSide = ({ ids }: SamResourceDetailsProps) => {
  const { data: resources, status, refetch, fetchStatus } = useQueryResources()
  const { data: resourceMap } = useQueryResourceMap()

  const sortedIds = [...ids].sort((idA, idB) => {
    const aName = resourceMap?.get(idA)?.resourceName
    const bName = resourceMap?.get(idB)?.resourceName
    const aType = resourceMap?.get(idA)?.resourceType
    const bType = resourceMap?.get(idB)?.resourceType
    if (aName && bName && aType && bType) {
      return aType.localeCompare(bType) || aName.localeCompare(bName)
    }
    return 0
  })

  const mappedResources = useMemo(
    () =>
      sortedIds.map(
        (id) =>
          resourceMap?.get(id) || {
            resourceId: id,
            resourceType: 'UNKNOWN' as const,
            resourceName: 'UNKNOWN' as const,
          },
      ),
    [sortedIds, resourceMap],
  )

  if (!ids?.length) {
    return null
  }

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={resources}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => <RequestErrorWidget action={refetch} />}
      renderLoading={() => <Inner status={status} resources={mappedResources} />}
      renderSuccess={() => <Inner status={status} resources={mappedResources} />}
    />
  )
}

const Inner = (props: { status: QueryStatus; resources: Resource[] }) => {
  const { status, resources } = props
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Resources</Subheader.Title>
      </Subheader>
      <Table
        columns={COLUMNS}
        data={resources}
        loadingState={getLoadingState(status, resources.length)}
      />
    </Box>
  )
}
