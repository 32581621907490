import { VStack } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { Account, AccountCustomization } from 'api/idave/accounts'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { Info } from './components/Info'
import { Scopes } from './components/Scopes'
import { Actions } from './components/Actions'
import { CustomizationBanner } from './components/CustomizationBanner/CustomizationBanner'

type Props = {
  account: Account
  client?: Client
  customization?: AccountCustomization
}
export const Details = ({ account, client, customization }: Props) => {
  if (!client) {
    return <RequestErrorWidget />
  }

  return (
    <>
      <Actions account={account} customization={customization} />
      <VStack space="s-16" maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <CustomizationBanner customization={customization} account={account} />
        <Info account={account} client={client} />
        <Scopes account={account} />
      </VStack>
    </>
  )
}
