import { Token } from 'hooks/useTokenize/types'
import { Text } from '@revolut/ui-kit'
import { isTokenDisplayable } from './utils'
import { TokenText } from './components/TokenText'

export const TokensText = (props: { tokens: Token[] }) => {
  const { tokens } = props
  const displayableTokens = tokens.filter(isTokenDisplayable)

  return (
    <Text whiteSpace="pre-line">
      {displayableTokens.map((token, idx) => (
        <TokenText token={token} key={idx} />
      ))}
    </Text>
  )
}
