import { abbreviate, Avatar, Page, TextSkeleton } from '@revolut/ui-kit'
import { User } from 'api/idave/user'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useNavigate } from 'react-router'
import { getName } from 'utils/string/getName'
import { Url } from 'routing'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useHeaderActions } from 'components/HeaderActions'
import { UserTab } from '../User'
import { UserActions } from './components/UserActions'
import { UserHeaderSubtitle } from './components/UserHeaderSubtitle'

export const UserHeader = (props: { user?: User; userId: string; tab: UserTab }) => {
  const { user, userId } = props
  const { data: avatars } = useQueryMappedAvatars()
  const avatarUrl = getAvatarUrl({ user, type: 'imageMedium', avatars })
  const navigate = useNavigate()
  const employeeDescriptions = useGetEmployeeRevoDescription()
  const HeaderActions = useHeaderActions()
  return (
    <>
      <Page.Header
        onBack={() => navigate(Url.Users)}
        description={
          <UserHeaderSubtitle user={user} employeeDescriptions={employeeDescriptions} />
        }
        actions={HeaderActions && <HeaderActions />}
        avatar={
          <Avatar
            label={user ? abbreviate(getName(user)) : undefined}
            uuid={userId}
            image={avatarUrl}
            size={56}
          />
        }
        meta={<UserActions userId={userId} />}
      >
        {user ? getName(user) : <TextSkeleton size={36} />}
      </Page.Header>
    </>
  )
}
