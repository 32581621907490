import { Box, ItemSkeleton } from '@revolut/ui-kit'
import { times } from 'utils/array/times'

export const CompanyListSkeleton = () => {
  return <>{times(CompanyItemSkeleton, 3)}</>
}

const CompanyItemSkeleton = () => {
  return (
    <Box pl="s-48">
      <ItemSkeleton />
    </Box>
  )
}
