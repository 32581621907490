import { SEARCH_TIMEOUT } from 'hooks/useSearchFilter'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useEffect } from 'react'
import { UseTableNavigationParams } from '../types'
import { useNavigateTableState } from '../../useNavigateTableState/useNavigateTableState'

export const useUpdateTableNavigation = <Entity extends {}>(
  navigationParams: UseTableNavigationParams<Entity>,
  init: boolean,
) => {
  const { navigateTableState } = useNavigateTableState()
  const search = useDebouncedValue(navigationParams.searchValue, SEARCH_TIMEOUT)
  const filters = useDebouncedValue(navigationParams.filters, SEARCH_TIMEOUT)
  const sorts = useDebouncedValue(navigationParams.sorts, SEARCH_TIMEOUT)

  useEffect(() => {
    if (!init || !navigationParams.enableNavigation) {
      return
    }

    navigateTableState({
      search,
      filters,
      sorts,
    })
  }, [
    navigateTableState,
    search,
    filters,
    sorts,
    init,
    navigationParams.enableNavigation,
  ])
}
