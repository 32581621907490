import { Client } from 'api/idave/clients'
import { EntitiesTable } from 'components/EntitiesTable'
import { useQueryPermissions } from 'queries/idave/permissions'
import { ActionButton } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useSideBox } from 'view/SideBox/SideBox'
import { SideAvatar } from 'view/SideBox'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { mergeQueryStatuses } from 'utils/query'
import { getPermissionRows } from './utils'
import { COLUMNS, PermissionRow } from './columns'
import { ClientPermissionSide } from './ClientPermissionSide'

export const ClientPermissions = (props: { client: Client }) => {
  const { data: permissions = [], status, fetchStatus } = useQueryPermissions()

  const { openSide } = useSideBox()
  const clientPermissions = getPermissionRows(permissions, props.client.id)
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: clientPermissions,
  })

  const onRowClickHandler = useCallback(
    (permission: PermissionRow) =>
      openSide({
        title: permission.name,
        description: 'Permission',
        avatar: <SideAvatar iconName="Controls" />,
        body: <ClientPermissionSide permissionId={permission.id} />,
        actions: (
          <ActionButton
            useIcon="LinkExternal"
            use="a"
            target="_blank"
            href={generatePath(Url.Permission, { permissionId: permission.id })}
          >
            Open permission
          </ActionButton>
        ),
      }),
    [openSide],
  )
  const state = getLoadingState(
    mergeQueryStatuses({ qs: status, fs: fetchStatus }),
    clientPermissions.length,
  )

  return state === 'no-results' ? (
    <EmptyStatusWidget
      title="There isn't any permission provided by this Application"
      imageCode="3D055"
    />
  ) : (
    <EntitiesTable
      totalCount={clientPermissions.length}
      entitiesTypeLabel="Permissions"
      pluralForms={['permission', 'permissions']}
      data={searched}
      loadingState={state}
      columns={COLUMNS}
      onRowClick={onRowClickHandler}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
    />
  )
}
