import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { CrossCheckEvent as TCrossCheckEvent } from 'api/types/crossCheck'
import { sortBy } from 'lodash'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { IconName, Token as UIToken } from '@revolut/ui-kit'
import { notNullableMap } from 'utils/array'
import { Token, Tokenize } from 'hooks/useTokenize/types'
import { getStatusCommandTokens } from 'hooks/useTokenize/utils'
import { CrossCheckEvent } from './types'

type GetEventsParams = {
  userMap: Map<string, UserListItem>
  events: TCrossCheckEvent[]
  avatars?: Map<string, RevolutersAvatar>
  tokenize: Tokenize
}

export const getEvents = (params: GetEventsParams): CrossCheckEvent[] => {
  const { events, userMap, avatars, tokenize } = params

  return sortBy(
    notNullableMap(events, (event) => {
      if (!showEvent(event.type)) {
        return null
      }

      const user = userMap.get(event.author)
      const name = user ? getName(user) : event.author
      const tokens = tokenize(event.text)
      const action = getActionText(event, tokens)
      const avatarBadge = getAvatarBadge(event, tokens)

      return {
        name,
        tokens,
        action,
        avatarBadge,
        requesterId: event.author,
        userId: user?.id,
        ts: event.createdDate,
        photoUrl: getAvatarUrl({ user, type: 'imageMedium', avatars }),
      }
    }),
    (item) => -item.ts,
  )
}

const getActionText = (event: TCrossCheckEvent, tokens: Token[]) => {
  switch (event.type) {
    case 'APPROVE':
      return 'Approved'
    case 'REJECT':
      return 'Rejected'
    case 'DECLINE':
      return 'Declined'
    case 'COMMENT': {
      const commands = getStatusCommandTokens(tokens)
      const command = commands[0]?.value

      if (command === 'provide_info') {
        return 'Provided info'
      }
      if (command === 'request_info') {
        return 'Requested info'
      }
      return undefined
    }

    default:
      return undefined
  }
}

const getAvatarBadge = (
  event: TCrossCheckEvent,
  tokens: Token[],
): { color: string; icon: IconName } | undefined => {
  switch (event.type) {
    case 'APPROVE':
      return { color: UIToken.color.green, icon: 'Check' }
    case 'REJECT':
      return { color: UIToken.color.red, icon: 'Cross' }
    case 'DECLINE':
      return { color: UIToken.color.red, icon: 'Delete' }
    case 'COMMENT': {
      const commands = getStatusCommandTokens(tokens)
      const command = commands[0]?.value
      if (command === 'provide_info') {
        return { color: UIToken.color.green, icon: 'Statement' }
      }
      if (command === 'request_info') {
        return { color: UIToken.color.yellow, icon: 'QuestionSign' }
      }
      return undefined
    }
    default:
      return undefined
  }
}

const SUPPORTED_EVENTS: TCrossCheckEvent['type'][] = [
  'APPROVE',
  'COMMENT',
  'DECLINE',
  'REJECT',
]
const showEvent = (eventType: TCrossCheckEvent['type']) => {
  return SUPPORTED_EVENTS.includes(eventType)
}
