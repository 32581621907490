import { TableColumn, Table } from '@revolut/ui-kit'
import { InternalLink } from 'components/Links/InternalLink'

export type UsersRow = {
  name: string
  href?: string
}

export const COLUMNS: TableColumn<UsersRow>[] = [
  {
    Header: 'Name',
    id: 'name',
    Cell: (params: { row: { original: UsersRow } }) => {
      const { name, href } = params.row.original
      return (
        <Table.Cell>
          {href ? <InternalLink href={href}>{name}</InternalLink> : name}
        </Table.Cell>
      )
    },
  },
]
