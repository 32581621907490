import { useQuery } from '@tanstack/react-query'
import samApi from 'api/sam'
import { SAMGetAuditLogsParams } from 'api/sam/auditLogs'
import { QueryKey } from 'helpers/configQuery'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'

export const useQuerySamAuditLogs = ({ entityType, entityId }: SAMGetAuditLogsParams) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamAuditLogs, entityType, entityId],
    queryFn: () => samApi.auditLogs.getLogs({ entityType, entityId }),
    select: (response) => response?.data,
    enabled: hasPermission(SAM_PERMISSIONS.AUDIT_VIEW_DETAILS),
  })
}
