import { Table } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { RegionText } from './RegionText'

type RegionTableCellProps = {
  row: { original: { region?: string; id: string } }
}

export const RegionTableCell = (props: RegionTableCellProps) => {
  const { region, id } = props.row.original

  return (
    <Table.Cell>
      {region ? (
        <RegionText id={id} region={region} title={region} />
      ) : (
        EMPTY_VALUE_FALLBACK
      )}
    </Table.Cell>
  )
}
