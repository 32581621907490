import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { OrganisationRow } from './type'

export const COLUMNS: TableColumn<OrganisationRow>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Code',
    accessor: 'code',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
