import { useCallback, useMemo } from 'react'
import { useMapify } from 'hooks/useMapify'
import { SelectInput } from '@revolut/ui-kit'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { getErrorMessage } from './utils'
import { BaseSelectProps } from './types'
import { getLoadingState, makeRenderValue, toOption } from '../utils'

export const BaseSelect = <Data extends { id: string; name: string }>({
  items,
  itemId,
  queryResult,
  itemToOption,
  requiredPermission,
  ...selectProps
}: BaseSelectProps<Data>) => {
  const itemMap = useMapify(items, (i) => i.id)
  const { searchValue, searched, searchState, setSearchValue } = useSearchFilter({
    entities: items,
  })

  const getOption = useMemo(() => itemToOption || toOption, [itemToOption])
  const options = useMemo(() => searched.map(getOption) ?? [], [searched, getOption])

  const errorMsg =
    selectProps.errorMessage ||
    getErrorMessage({
      itemMap,
      itemId,
      queryResult,
      requiredPermission,
    })

  const loadingState = getLoadingState(queryResult)
  const isPending = loadingState === 'pending'
  const renderValue = useMemo(
    () => makeRenderValue({ getOption, itemId, itemMap }),
    [getOption, itemId, itemMap],
  )

  const value = useMemo(() => (itemId ? itemMap.get(itemId) : null), [itemId, itemMap])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClear = useCallback(() => selectProps?.onChange?.(null), [selectProps.onChange])

  return (
    <SelectInput
      {...selectProps}
      invalid={!!errorMsg || !!selectProps.invalid}
      errorMessage={errorMsg}
      pending={isPending}
      loadingState={loadingState}
      options={options}
      value={value}
      onSearchText={setSearchValue}
      searchText={searchValue}
      searchState={searchState}
      renderValue={renderValue}
      onClear={onClear}
      labelFailedState="Cannot load data or permission is missing"
    />
  )
}
