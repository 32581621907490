import { Table, TableColumn, Text } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { InternalLink } from 'components/Links/InternalLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'

type PermissionRow = {
  id: string
  name: string
  client: string
}

export const COLUMNS: TableColumn<PermissionRow>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (params: { row: { original: { name: string; id: string } } }) => {
      return (
        <Table.Cell>
          <InternalLink
            href={generatePath(Url.Permission, { permissionId: params.row.original.id })}
          >
            {params.row.original.name}
          </InternalLink>
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Application',
    accessor: 'client',
    Cell: (params: { row: { original: { client: string; clientId?: string } } }) => {
      const clientId = params.row.original.clientId
      const client = params.row.original.client
      return (
        <Table.Cell>
          {clientId ? (
            <InternalLink href={generatePath(Url.Client, { clientId })}>
              {client}
            </InternalLink>
          ) : (
            <Text>{client}</Text>
          )}
        </Table.Cell>
      )
    },
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
