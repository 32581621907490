import { Navigate, useParams } from 'react-router'

type ParametrizedElementProps<T extends Object> = {
  params: string[]
  fallbackRoute: string
  Component: React.FC<T>
}

export const ParametrizedElement = <T extends Object>(
  props: ParametrizedElementProps<T>,
) => {
  const { Component, fallbackRoute, params } = props

  const pathParams = useParams()
  const paramsValue = params.reduce<T>(
    (acc, name) => ({
      ...acc,
      [name]: pathParams[name],
    }),
    {} as T,
  )
  if (Object.values(paramsValue).some((v) => !v)) {
    return <Navigate to={fallbackRoute} replace />
  }

  return <Component {...paramsValue} />
}
