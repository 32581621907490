import { UserListItem } from 'api/idave/user'
import { RevolutersEmployee, RevolutersTeam } from 'api/sam/revoluters'
import { CrossCheckDetail, CrossCheckGroup } from 'api/types/crossCheck'
import { getName } from 'utils/string/getName'
import { IconName, Token, chain } from '@revolut/ui-kit'
import { capitalizeFirst } from 'utils/string'
import { getEventsByType } from 'view/CrossChecks/utils'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { uniq } from 'lodash'
import { ReviewerItem, ReviewerStatus } from './types'

type ReviewersByDecision = {
  rejectedIds: string[]
  approvedIds: string[]
  pendingIds: string[]
}

export const getReviewersByDecision = (
  group: CrossCheckGroup,
  crossCheck: CrossCheckDetail,
) => {
  const userReviewMap = crossCheck.events.reduce<Record<string, 'REJECT' | 'APPROVE'>>(
    (acc, event) => {
      if (event.type === 'APPROVE' || event.type === 'REJECT') {
        return { ...acc, [event.author]: event.type }
      }
      return acc
    },
    {},
  )

  return uniq(group.reviewers).reduce<ReviewersByDecision>(
    (acc, reviewer) => {
      const reaction = userReviewMap[reviewer]
      switch (reaction) {
        case 'APPROVE':
          return { ...acc, approvedIds: [...acc.approvedIds, reviewer] }
        case 'REJECT':
          return { ...acc, rejectedIds: [...acc.rejectedIds, reviewer] }
        default:
          return { ...acc, pendingIds: [...acc.pendingIds, reviewer] }
      }
    },
    {
      rejectedIds: [],
      approvedIds: [],
      pendingIds: [],
    },
  )
}

const makeGetReviewerInfo = (params: {
  groupId: string
  userMap: Map<string, UserListItem>
  crossCheck: CrossCheckDetail
  employeeMap: Map<string, RevolutersEmployee>
  teamMap: Map<string, RevolutersTeam>
  avatars?: Map<string, RevolutersAvatar>
}) => {
  const assignments = getEventsByType(params.crossCheck.events, 'ADD_REVIEWERS')
  const reviews = [
    ...getEventsByType(params.crossCheck.events, 'APPROVE'),
    ...getEventsByType(params.crossCheck.events, 'REJECT'),
  ]

  return (id: string): ReviewerItem => {
    const { userMap, employeeMap, teamMap, groupId, avatars } = params
    const assignment = assignments.find(
      (event) => event.reviewers.includes(id) && event.groupId === groupId,
    )
    const review = reviews.find((r) => r.author === id)

    const user = userMap.get(id)

    const peopleOpsId = user?.externalRef?.reference
    const employee = peopleOpsId ? employeeMap.get(peopleOpsId) : undefined
    const assignerUser = assignment ? userMap.get(assignment.author) : undefined
    const assignerUserName = (assignerUser && getName(assignerUser)) || assignment?.author
    const photoUrl = getAvatarUrl({ user, avatars, type: 'imageMedium' })
    const team = employee ? teamMap.get(employee.teamId) : undefined

    return {
      reviewerId: id,
      userId: user?.id,
      name: user ? getName(user) : id,
      teamName: team?.name,
      teamId: team?.id,
      assignmentComment: assignment?.text,
      reviewComment: review?.text,
      reviewTS: review?.createdDate,
      assignedBy: assignerUserName ? `Assigned by ${assignerUserName}` : undefined,
      photoUrl,
      state: user?.state,
    }
  }
}

export const getReviewers = (params: {
  userMap: Map<string, UserListItem>
  group: CrossCheckGroup
  crossCheck: CrossCheckDetail
  employeeMap: Map<string, RevolutersEmployee>
  teamMap: Map<string, RevolutersTeam>
  avatars?: Map<string, RevolutersAvatar>
}) => {
  const { userMap, group, crossCheck, employeeMap, avatars, teamMap } = params
  const { rejectedIds, approvedIds, pendingIds } = getReviewersByDecision(
    group,
    crossCheck,
  )
  const getReviewerInfo = makeGetReviewerInfo({
    userMap,
    crossCheck,
    employeeMap,
    teamMap,
    groupId: group.groupId,
    avatars,
  })

  return {
    rejected: rejectedIds.map((id) => getReviewerInfo(id)),
    approved: approvedIds.map((id) => getReviewerInfo(id)),
    pending: pendingIds.map((id) => getReviewerInfo(id)),
  }
}

export const getReviewerGroupHeader = (
  reviewers: ReviewerItem[],
  status: ReviewerStatus,
) => {
  const statusText = capitalizeFirst(status)
  return reviewers.length ? chain(statusText, reviewers.length) : statusText
}

export const getStatusBadge = (
  status: ReviewerStatus,
): { color: string; icon: IconName } | undefined => {
  switch (status) {
    case 'approved':
      return { color: Token.color.green, icon: 'Check' }
    case 'rejected':
      return { color: Token.color.red, icon: 'Cross' }
    case 'pending':
    default:
      return undefined
  }
}
