import { SamUserDetails } from 'api/sam/policies'
import { useQuerySamUser } from 'queries/sam/users'
import { QuerySwitch } from 'components/QuerySwitch'
import { Box, Subheader, Table, VStack } from '@revolut/ui-kit'
import { SamResourcesSide } from 'view/Sam/components/SamResourcesSide/SamResourcesSide'
import { useMemo } from 'react'
import { Skeleton } from './Skeleton'
import { COLUMNS } from './columns'
import { getTrainings } from './utils'

type UserPoliciesSideProps = { policyId: string; userId: string }

export const Body = ({ policyId, userId }: UserPoliciesSideProps) => {
  const {
    data,
    status: userQs,
    fetchStatus: userFs,
  } = useQuerySamUser({ params: userId })

  return (
    <QuerySwitch
      required={[{ fs: userFs, qs: userQs }]}
      idleText="Lack of policy or user view permissions"
      data={data}
      renderLoading={Skeleton}
      renderSuccess={({ data: user }) => <Inner user={user} policyId={policyId} />}
    />
  )
}

const Inner = (props: { policyId: string; user: SamUserDetails }) => {
  const { policyId, user } = props
  const policy = user.policies.find(({ policyId: id }) => policyId === id)
  const trainingList = useMemo(() => getTrainings({ policy }), [policy])

  return (
    <VStack space="s-16">
      <SamResourcesSide ids={policy?.resourceIds || []} />
      {trainingList?.length ? (
        <Box>
          <Subheader variant="nested">
            <Subheader.Title>Trainings</Subheader.Title>
          </Subheader>
          <Table columns={COLUMNS} data={trainingList} />
        </Box>
      ) : null}
    </VStack>
  )
}
