import { Role } from 'api/idave/roles'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getRolesByPermission } from 'utils/role/getRolesByPermission'
import { RoleRow } from './types'

type GetRoleEntitiesParams = {
  roleMap: Map<string, Role>
  roles: Role[]
  permissionId: string
}
export const getRoleEntities = ({
  roles,
  permissionId,
  roleMap,
}: GetRoleEntitiesParams): RoleRow[] => {
  const roleItems = getRolesByPermission({ roles, permissionId })
  return roleItems.map((role) => {
    return {
      name: role.name,
      parentName: role.parent
        ? roleMap.get(role.parent)?.name || role.parent
        : EMPTY_VALUE_FALLBACK,
      parentId: role.parent,
      id: role.id,
    }
  })
}
