import { Text, TextButton } from '@revolut/ui-kit'
import { ACTION_TAG, getTextParts } from './utils'
import { Handler } from './types'

type Props = {
  text: string
  clickHandlers: Handler[]
  separator?: string
}

export const ActionTemplatedText = ({
  text,
  clickHandlers,
  separator = ACTION_TAG,
}: Props) => {
  const parts = getTextParts({
    text,
    clickHandlers,
    separator,
  })

  return (
    <Text>
      {parts.map((part, idx) =>
        part.type === 'text' ? (
          <Text key={idx}>{part.value}</Text>
        ) : (
          <TextButton key={idx} onClick={part.onClick}>
            {part.value}
          </TextButton>
        ),
      )}
    </Text>
  )
}
