import { Box, Subheader, Table } from '@revolut/ui-kit'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useQueryRoleIdMap, useQueryRoles } from 'queries/idave/roles'
import { mergeQueryStatuses } from 'utils/query'
import { useMemo } from 'react'
import { getRoleEntities } from './utils'
import { COLUMNS } from './columns'

type Props = {
  permissionId: string
}

export const PermissionRoles = ({ permissionId }: Props) => {
  const { data: roles = [] } = useQueryRoles()

  const {
    data: roleMap = new Map(),
    status: roleMapQS,
    fetchStatus: roleMapFS,
  } = useQueryRoleIdMap()

  const items = useMemo(
    () =>
      getRoleEntities({
        roles,
        permissionId,
        roleMap,
      }),
    [roles, roleMap, permissionId],
  )

  const roleLoadingStatus = mergeQueryStatuses({ fs: roleMapFS, qs: roleMapQS })
  const roleTableState = getLoadingState(roleLoadingStatus, items.length)

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Participating in roles</Subheader.Title>
      </Subheader>
      <Table
        columns={COLUMNS}
        data={items}
        loadingState={roleTableState}
        labelEmptyState="No roles with the permission"
      />
    </Box>
  )
}
