import { UIRoute, Url } from 'routing'
import { Home } from 'view/Home'

export const routes = (): Record<string, UIRoute> => ({
  [Url.Home]: {
    type: 'component',
    withParams: false,
    allowed: true,
    Component: Home,
  },
})
