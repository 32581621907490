import { useController, useFormContext } from 'react-hook-form'
import { GroupSelect, GroupSelectProps } from 'components/Selects/GroupSelect'
import { Fielded } from 'utils/types/fielded'
import { useOnChange } from 'utils/form/useOnChange'

type Props = Fielded<GroupSelectProps>

export const FieldGroupSelect = (props: Props) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  const onChange = useOnChange(field.onChange)

  return (
    <GroupSelect
      {...rest}
      {...field}
      onChange={onChange}
      itemId={field.value}
      ref={undefined}
    />
  )
}
