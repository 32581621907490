import { SamResourceType } from 'api/sam/resources'

export const getResourceTypeLabel = (resourceType?: SamResourceType) => {
  switch (resourceType) {
    case SamResourceType.GoogleGroup:
      return 'Google group'
    case SamResourceType.MetabaseGroup:
      return 'Metabase group'
    case SamResourceType.TeamCityGroup:
      return 'Teamcity group'
    case SamResourceType.LookerGroup:
      return 'Looker group'
    case SamResourceType.IdaveRole:
      return 'iDave role'
    case SamResourceType.TestFlight:
      return 'TestFlight group'
    case SamResourceType.AppStoreApp:
      return 'AppStore app'
    case SamResourceType.AppStoreUserRole:
      return 'AppStore user role'
    case SamResourceType.OktaGroup:
      return 'Okta group'
    case SamResourceType.AppStoreUserProperty:
      return 'AppStore user property'
    case SamResourceType.DartAllowCustomerCompany:
      return 'Customer company access'
    default:
      return `Unknown resource (${resourceType})`
  }
}
