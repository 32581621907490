import { capitalizeFirst } from 'utils/string'
import { DetailRow } from '../../types'
import { DetailParams } from './types'

export const getCustomerCompanyInfo = (params: DetailParams): DetailRow | undefined => {
  if (params.entity.type === 'CustomerCompany' && params.entity.value) {
    return {
      title: 'Service type',
      value: {
        current: capitalizeFirst(params.entity.value.serviceType),
        type: 'text',
      },
      changeType: 'NoChange',
      isContext: true,
    }
  }

  return undefined
}
