import { Table, TableColumn, Box, Subheader } from '@revolut/ui-kit'
import { RoleByPermission } from 'api/idave/permissions'
import { Role } from 'api/idave/roles'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { InternalLink } from 'components/Links/InternalLink'
import { uniq } from 'lodash'
import { useQueryRolesByPermission } from 'queries/idave/roles'
import { useQueryRoles } from 'queries/idave/roles'
import { useCallback } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { mapify } from 'utils/array'
import { isAllSettled, mergeQueryStatuses } from 'utils/query'
import { traverseRoleChildren } from 'utils/role/traverseRolesChildren'
import { countedItems } from 'utils/string'

type ClientPermissionSideBoxProps = {
  permissionId: string
}

type RoleRow = {
  roleId: string
  name?: string
}

const getRolesRow = (rolesByPermission: RoleByPermission[], roles: Role[]) => {
  const roleMap = mapify(roles, (r) => r.id)

  const childRolesIds = rolesByPermission.reduce<string[]>(
    (acc, role) => [...acc, ...traverseRoleChildren({ roleId: role.id, roles })],
    [],
  )

  const roleIds = [...rolesByPermission.map((r) => r.id), ...childRolesIds]

  return uniq(roleIds).map((id) => ({ roleId: id, name: roleMap.get(id)?.name }))
}

const COLUMNS: TableColumn<RoleRow>[] = [
  {
    Header: 'Name',
    id: 'name',
    Cell: (params: { row: { original: RoleRow } }) => {
      const { name, roleId } = params.row.original
      return (
        <Table.Cell>
          <InternalLink href={generatePath(Url.Role, { roleId })}>{name}</InternalLink>
        </Table.Cell>
      )
    },
  },
]

export const ClientPermissionSide = (props: ClientPermissionSideBoxProps) => {
  const {
    data: rolesByPermission = [],
    status: rolesByPermissionStatus,
    fetchStatus: rolesPermissionFetchStatus,
  } = useQueryRolesByPermission(props.permissionId)
  const {
    data: roles = [],
    status: rolesStatus,
    fetchStatus: rolesFetchStatus,
  } = useQueryRoles()

  const navigate = useNavigate()
  const onRoleClick = useCallback(
    ({ roleId }: RoleRow) =>
      navigate(
        generatePath(Url.Role, {
          roleId,
        }),
      ),
    [navigate],
  )

  const statuses = [
    {
      qs: rolesByPermissionStatus,
      fs: rolesPermissionFetchStatus,
    },
    {
      qs: rolesStatus,
      fs: rolesFetchStatus,
    },
  ]

  const rolesRows = getRolesRow(rolesByPermission, roles)
  const isNoRolesFound = isAllSettled(...statuses) && !rolesByPermission.length

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>
          {countedItems('Uses in roles', rolesRows.length)}
        </Subheader.Title>
      </Subheader>
      {isNoRolesFound ? (
        <EmptyStatusWidget
          imageCode="3D086"
          imageVersion="v2"
          title="Not used in any role"
        />
      ) : (
        <Table
          columns={COLUMNS}
          data={rolesRows}
          loadingState={getLoadingState(
            mergeQueryStatuses(...statuses),
            rolesRows.length,
          )}
          onRowClick={onRoleClick}
        />
      )}
    </Box>
  )
}
