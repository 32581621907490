import { ActionResponse } from 'api/types/actionResponse'
import axios, { AxiosPromise } from 'axios'

export type GroupType = 'peopleops:lm' | 'peopleops:team' | 'perfectionist' | 'idave:team'

export type Group = GroupPayload & {
  id: string
  clientId?: string
  createdDate: number
  updatedDate: number
  externalRef?: {
    origin: string
    reference: string
  }
  state: 'ACTIVE' | 'DELETED'
}

export type GroupPayload = {
  name: string
  code: string
  description?: string
  type: GroupType
  ownerIds: [string]
  userIds: string[]
}

const groups = {
  groups: () => axios.get<Group[]>('/api/groups'),

  group: (groupId: string) => axios.get<Group>(`/api/groups/${groupId}`),

  addGroup: (data: GroupPayload): AxiosPromise<{ id: string }> =>
    axios.post('/api/groups', data),

  editGroup: ({
    groupId,
    data,
  }: {
    groupId: string
    data: GroupPayload
  }): AxiosPromise<void> => axios.put(`/api/groups/${groupId}`, data),

  deleteGroup: (groupId: string): AxiosPromise<ActionResponse> =>
    axios.delete(`/api/groups/${groupId}`),
}

export default groups
