import axios from 'axios'
import { ActionResponse } from 'api/types/actionResponse'
import { CrossCheckedPayload } from 'utils/types/crossChecked'
import { splitPayloadHeader } from 'utils/api/splitBodyHeaders'
import { AccessModifier, AddCompanyModifier } from './types'

export type EmployeeCompany = {
  id: string
  externalId: string
  name: string
  type: 'INTERNAL' | 'EXTERNAL'
  region?: string
  accessModifiers: AccessModifier[]
}

export const employeeCompany = {
  getEmployeeCompanies: () =>
    axios.get<EmployeeCompany[]>('/dart-api/dart/employee-companies'),

  addModifiers: (params: CrossCheckedPayload<AddCompanyModifier[]>) => {
    const { body, headers } = splitPayloadHeader(params)
    return axios.post<ActionResponse>(
      '/dart-api/dart/employee-companies/access-modifiers/add',
      body,
      { headers },
    )
  },

  deleteModifiers: (params: CrossCheckedPayload<string[]>) => {
    const { body, headers } = splitPayloadHeader(params)
    return axios.post<ActionResponse>(
      '/dart-api/dart/employee-companies/access-modifiers/delete',
      body,
      { headers },
    )
  },
}
