import { TableRef } from '@revolut/ui-kit'
import { TableInstance } from 'react-table'
import { useCallback, useState } from 'react'
import { useDebouncedValue } from 'hooks/useDebouncedValue'

const DEBOUNCE_TIMEOUT = 20
/**
 * Keep table value actual when Table onUpdate wasn't triggered
 */
export const useTableInstance = <Entity extends { id: string }>() => {
  const [_table, setTable] = useState<TableInstance<Entity>>()
  const tableRef = useCallback(
    (ref: TableRef<Entity>) => {
      setTable(ref?.getInstance())
    },
    [setTable],
  )
  const table = useDebouncedValue(_table, DEBOUNCE_TIMEOUT)

  return {
    table,
    tableRef,
  }
}
