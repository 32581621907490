import { Box, Subheader, Table, VStack } from '@revolut/ui-kit'

export const Skeleton = () => {
  return (
    <VStack space="s-16">
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Resources</Subheader.Title>
        </Subheader>
        <Table columns={[]} data={[]} loadingState="pending" />
      </Box>
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Trainings</Subheader.Title>
        </Subheader>
        <Table columns={[]} data={[]} loadingState="pending" />
      </Box>
    </VStack>
  )
}
