import { Client } from 'api/idave/clients'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { useCallback } from 'react'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { EDIT_CLIENT } from 'view/Clients/permissions'
import { useEditPopup } from 'hooks/useEditPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { UrlUpdateParams, CommonClientUrls } from '../../../CommonClientUrls'
import { useEditClientUrls } from './useEditClientUrls'
import { getNewClient } from './utils'

const DELETE_ALL_POPUP_TEXT = {
  title: 'URLs deletion confirmation',
  message: 'Are you sure you want to delete all URLs?',
}

const DELETE_POPUP_TEXT = {
  title: 'URL deletion confirmation',
  message: 'Are you sure you want to delete this URL?',
}

export const ClientUrls = (props: { client: Client }) => {
  const { client } = props
  const { hasSomePermissions } = usePermissions()
  const { setPopup: setConfirmPopup, closePopup: closeConfirmPopup } =
    useConfirmationPopup()
  const { closePopup: closeEditPopup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const onMutate = useCallback(
    (update: UrlUpdateParams) => {
      const title = update.type === 'add' ? 'Adding...' : 'Deleting...'
      showLoadingPopup({ title })
    },
    [showLoadingPopup],
  )

  const onSuccess = useCallback(() => {
    hideLoadingPopup()
    closeConfirmPopup()
    closeEditPopup()
  }, [closeConfirmPopup, closeEditPopup, hideLoadingPopup])

  const onError = useCallback(
    (error: unknown, update: UrlUpdateParams) => {
      hideLoadingPopup()
      closeConfirmPopup()

      const title = update.type === 'add' ? 'Addition' : 'Deletion'
      showErrorPopup({
        title: `${title} failed`,
        error,
      })
    },
    [hideLoadingPopup, closeConfirmPopup, showErrorPopup],
  )

  const { mutate } = useEditClientUrls({
    onMutate,
    onSuccess,
    onError,
  })

  const updateHandler = useCallback(
    (update: UrlUpdateParams) => {
      if (update.type === 'add') {
        return mutate({
          client: getNewClient(client, update),
          update,
        })
      }

      return setConfirmPopup({
        ...(update.type === 'delete' ? DELETE_POPUP_TEXT : DELETE_ALL_POPUP_TEXT),
        confirmButtonText: 'Delete',
        onConfirmClick: () =>
          mutate({
            client: getNewClient(client, update),
            update,
          }),
      })
    },
    [mutate, setConfirmPopup, client],
  )

  const isEditable = hasSomePermissions(...EDIT_CLIENT)
  const isActive = client.state === 'ACTIVE'

  return (
    <CommonClientUrls
      urls={client.redirectUris}
      allowUpdate={isEditable && isActive}
      updateUrls={updateHandler}
    />
  )
}
