import { Page, TextSkeleton, VStack } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { QuerySwitch } from 'components/QuerySwitch'
import { useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { StatusText } from 'components/StatusText'
import { useHeaderActions } from 'components/HeaderActions'
import { useCrosscheckQuery } from './useCrosscheckQuery'
import { CrossCheckActions } from './components/CrossCheckActions'
import { getRequest, getRequestStateLevel, getRequestStateText } from '../utils'
import { CrossCheckDetails } from './components/CrosscheckDetails'
import { CrossCheckApprovers } from './components/CrossCheckApprovers'
import { CrossCheckEvents } from './components/CrossCheckEvents'
import { CrossCheckRules } from './components/CrossCheckRules'
import { CrossCheckJustification } from './components/CrossCheckJustification'
import { CrossCheckComment } from './components/CrossCheckComment'

type Props = {
  crossCheckId: string
  source: CrossCheckSystem
}

export const CrossCheck = (props: Props) => {
  const { source, crossCheckId } = props
  const { query } = useCrosscheckQuery({ source, crossCheckId })
  const { data, status, fetchStatus } = query
  const requiredStatuses = useMemo(
    () => [{ qs: status, fs: fetchStatus }],
    [status, fetchStatus],
  )

  return (
    <QuerySwitch
      required={requiredStatuses}
      data={data}
      renderLoading={() => <Inner source={source} />}
      renderSuccess={() => <Inner source={source} crossCheck={data} />}
    />
  )
}

const Inner = (props: { crossCheck?: CrossCheckDetail; source: CrossCheckSystem }) => {
  const { crossCheck, source } = props
  const navigate = useNavigate()
  const onBackClick = useCallback(() => navigate(Url.CrossChecks), [navigate])
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const HeaderActions = useHeaderActions()

  return (
    <>
      <Page.Header
        onBack={onBackClick}
        subtitle={
          crossCheck ? (
            <StatusText level={getRequestStateLevel(crossCheck.state)}>
              {getRequestStateText(crossCheck)}
            </StatusText>
          ) : (
            <TextSkeleton size={6} />
          )
        }
        actions={HeaderActions && <HeaderActions />}
      >
        {crossCheck ? getRequest(crossCheck.executableName) : <TextSkeleton size={12} />}
      </Page.Header>
      <Page.Main>
        <VStack space="s-24" maxWidth={MAXIMAL_DETAILS_WIDTH}>
          <CrossCheckActions
            crossCheck={crossCheck}
            source={source}
            commentRef={commentRef}
          />
          <CrossCheckJustification crossCheck={crossCheck} source={source} />
          <CrossCheckDetails crossCheck={crossCheck} source={source} />
          <CrossCheckRules crossCheck={crossCheck} />
          <CrossCheckApprovers crossCheck={crossCheck} source={source} />
          <CrossCheckComment
            commentRef={commentRef}
            source={source}
            crossCheck={crossCheck}
          />
          <CrossCheckEvents crossCheck={crossCheck} source={source} />
        </VStack>
      </Page.Main>
    </>
  )
}
