import { Box, Subheader, Table } from '@revolut/ui-kit'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useQueryPolicies } from 'queries/sam/policies'
import { useMemo } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { COLUMNS } from './columns'
import { getRoleRows } from './utils'

export const RolePolicies = ({ roleId }: { roleId: string }) => {
  const { data: policies = [], status: qs, fetchStatus: fs } = useQueryPolicies()
  const entities = useMemo(() => getRoleRows(policies, roleId), [roleId, policies])

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Participated in policies</Subheader.Title>
      </Subheader>
      <Table
        columns={COLUMNS}
        data={entities}
        loadingState={getLoadingState(mergeQueryStatuses({ qs, fs }), entities.length)}
        labelEmptyState="There isn't a policy with the role"
      />
    </Box>
  )
}
