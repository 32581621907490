import { Table, TableColumn } from '@revolut/ui-kit'
import { SamPolicy } from 'api/sam/policies'
import { InternalLink } from 'components/Links/InternalLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { stringifySubjectType } from 'view/Sam/utils'

export const COLUMNS: TableColumn<SamPolicy>[] = [
  {
    Header: 'Name',
    accessor: 'policyName',
    Cell: (params: { row: { original: SamPolicy } }) => {
      const { policyId, policyName } = params.row.original
      return (
        <Table.Cell>
          <InternalLink href={generatePath(Url.SamPolicy, { policyId })}>
            {policyName}
          </InternalLink>
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Subject type',
    accessor: (value) => stringifySubjectType(value.subject.subjectType),
    filter: 'includesValue',
  },
]
