import { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { extractErrorMsg } from 'utils/error/extractErrorMsg'

/**
 * Shows error in toast, use only for not important errors
 *
 * @todo migrate from react-toast-notifications
 */
export const useErrorToast = () => {
  const { addToast } = useToasts()
  const [initialDefaultHandler] = useState(() => (error: unknown) => {
    addToast(extractErrorMsg(error), {
      appearance: 'error',
      autoDismiss: true,
    })
  })

  return initialDefaultHandler
}
