import { Client } from 'api/idave/clients'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { Account } from 'api/idave/accounts'
import { ScopesRow } from './types'
import { CrossCheckValueTab } from '../../../../types'
import { extractEntityArrayIds } from '../../../../utils'
import { getChangeRowsInfo } from '../../utils'

type GetScopesParams = {
  crossCheck: CrossCheckDetail
  clientMap: Map<string, Client>
  tabs: CrossCheckValueTab[]
  account?: Account
}

export const getRows = (params: GetScopesParams) => {
  const { crossCheck, account, clientMap, tabs } = params
  const { executableParams } = crossCheck

  const added = extractEntityArrayIds(executableParams?.['New Scope'])
  const deleted = extractEntityArrayIds(executableParams?.['Removed Scope'])

  const scopes = executableParams?.Scope
  const isAccountScopes = scopes?.type === 'array'

  const values = extractEntityArrayIds(isAccountScopes ? scopes : undefined)
  const current = account?.scope || []

  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
    clientMap,
  })
}

type MakeGetPermissionRowParams = {
  clientMap: Map<string, Client>
  deletedHash?: Record<string, boolean>
  addedHash?: Record<string, boolean>
}

const makeMapValue =
  ({ clientMap, deletedHash = {}, addedHash = {} }: MakeGetPermissionRowParams) =>
  (clientId: string): ScopesRow => {
    const client = clientMap.get(clientId)

    const removedStatus = deletedHash[clientId] ? 'removed' : undefined
    const addedStatus = addedHash[clientId] ? 'added' : undefined

    return {
      id: clientId,
      clientName: client?.name || clientId,
      status: addedStatus || removedStatus,
    }
  }

export const getEmptyScopesLabel = (status: CrossCheckValueTab) =>
  status === 'requested'
    ? "There aren't added or deleted scopes"
    : 'No scopes for the account'
