import { useCallback } from 'react'
import { SideboxQueryParams } from 'view/SideBox/types'
import { useAuditSidebox } from 'components/previews/AuditLogPreview/hook'
import { useUserPolicyPreview } from 'components/previews/UserPolicyPreview/hook'
import { useGroupPreview } from 'components/previews/GroupPreview/hook'
import { useRolePreview } from 'components/previews/RolePreview'
import { usePermissionPreview } from 'components/previews/PermissionPreview'
import { useResourcePreview } from 'components/previews/ResourcePreview'
import { useToxicAlertPreview } from 'components/previews/ToxicAlertPreview'
import {
  AUDIT_LOG_TYPE,
  GROUP_SIDE,
  PERMISSION_SIDE,
  RESOURCE_SIDE,
  ROLE_SIDE,
  TOXIC_ALERT_SIDE,
  USER_POLICY_TYPE,
} from './constants'

export const useSideboxByQueryParams = () => {
  const { openAuditLog } = useAuditSidebox()
  const { openPolicySide } = useUserPolicyPreview()
  const { openGroupSide } = useGroupPreview()
  const { openRoleSide } = useRolePreview()
  const { openPermissionSide } = usePermissionPreview()
  const { openResourceSide } = useResourcePreview()
  const { openToxicAlertSide } = useToxicAlertPreview()

  return useCallback(
    (params?: SideboxQueryParams) => {
      switch (params?.type) {
        case AUDIT_LOG_TYPE:
          return openAuditLog(params.id)
        case USER_POLICY_TYPE:
          return openPolicySide(params.id, params.subjectId)
        case GROUP_SIDE:
          return openGroupSide(params.id)
        case ROLE_SIDE:
          return openRoleSide(params.id, params.subjectId)
        case PERMISSION_SIDE:
          return openPermissionSide(params.id, params.subjectId)
        case RESOURCE_SIDE:
          return openResourceSide(params.id)
        case TOXIC_ALERT_SIDE:
          return openToxicAlertSide(params.id, params.subjectId)
        default:
          return undefined
      }
    },
    [
      openAuditLog,
      openPolicySide,
      openPermissionSide,
      openResourceSide,
      openRoleSide,
      openGroupSide,
      openToxicAlertSide,
    ],
  )
}
