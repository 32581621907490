import { Text, TextSkeleton } from '@revolut/ui-kit'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { mergeQueryStatuses } from 'utils/query'

type Props = {
  policyId: string
}

export const Title = ({ policyId }: Props) => {
  const { data: policy, fetchStatus: fs, status: qs } = useQuerySamPolicy(policyId)
  const status = mergeQueryStatuses({ fs, qs })

  const name = policy?.policyName || policyId

  return status === 'loading' ? <TextSkeleton size={12} /> : <Text>{name}</Text>
}
