import { CrossCheckValueTab } from '../../../types'
import { DiffedText } from './DiffedText'
import { DetailRow } from '../types'
import { DetailEntity } from './DetailEntity'
import { DetailEntities } from './DetailEntities'
import { getIdComponent } from '../utils/getIDComponent'

export const DetailContent = (props: {
  tab: CrossCheckValueTab
  row: DetailRow
  isFinished: boolean
}) => {
  const { row, tab, isFinished } = props
  const { value, changeType } = row

  const { type } = value
  const IDComponent = getIdComponent(row)

  switch (type) {
    case 'text':
      return (
        <DiffedText
          value={value}
          tab={tab}
          isFinished={isFinished}
          changeType={changeType}
        />
      )
    case 'Group':
    case 'Role':
    case 'Client':
    case 'AccessPolicy':
    case 'User':
    case 'EmployeeTeam':
    case 'EmployeeSpecialisation':
    case 'CustomerCompany':
    case 'EmployeeCompany':
    case 'TowerComponent':
    case 'ServiceAccount':
      return IDComponent ? (
        <DetailEntity
          IDComponent={IDComponent}
          value={value}
          tab={tab}
          isFinished={isFinished}
        />
      ) : null

    case 'EmployeeTypes':
    case 'Employees':
    case 'Seniorities':
    case 'Specialisations':
    case 'Teams':
      return <DetailEntities value={value} tab={tab} isFinished={isFinished} />
    default:
      return null
  }
}
