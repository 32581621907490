import { Avatar, Item, Text, VStack, Widget, abbreviate } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { UserItem } from '../types'

export const ParticipantListItem = (props: { user: UserItem }) => {
  const { user } = props

  return (
    <Widget>
      <Item use="a" target="_blank" href={generatePath(Url.User, { userId: user.id })}>
        <Item.Avatar>
          <Avatar image={user.photoUrl} label={abbreviate(user.name)} uuid={user.id} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{user.name}</Item.Title>
          <Item.Description>
            <VStack>
              <Text>{user.email}</Text>
            </VStack>
          </Item.Description>
        </Item.Content>
      </Item>
    </Widget>
  )
}
