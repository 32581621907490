import { useQueryRoles } from 'queries/idave/roles'
import { Role } from 'api/idave/roles'
import { mergeQueryStatuses } from 'utils/query'
import { useMemo } from 'react'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { IDAVE_PERMISSIONS } from 'security'
import { BaseSelect, BaseSelectWrapperProps } from './BaseSelect'

export type RoleSelectProps = BaseSelectWrapperProps<Role> & {
  excludeRoles?: string[]
}

export const RoleSelect = ({ excludeRoles = [], ...selectProps }: RoleSelectProps) => {
  const { data = [], status: qs, fetchStatus: fs } = useQueryRoles()
  const queryResult = mergeQueryStatuses({ qs, fs })
  const excluded = useMemo(() => toPresenceMap(excludeRoles), [excludeRoles])
  const items = useMemo(() => data.filter((item) => !excluded[item.id]), [data, excluded])

  return (
    <BaseSelect
      items={items}
      requiredPermission={IDAVE_PERMISSIONS.ROLES_VIEW_LIST}
      queryResult={queryResult}
      {...selectProps}
    />
  )
}
