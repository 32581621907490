import { useCallback } from 'react'
import { ControllerRenderProps } from 'react-hook-form'

export const useOnChange = <T extends { id: string }>(
  onChange: ControllerRenderProps['onChange'],
) => {
  return useCallback(
    (value: T | null) =>
      // empty string because when undefined the value returns to defaultValue
      onChange(value?.id || ''),
    [onChange],
  )
}
