import { useMutation } from '@tanstack/react-query'
import { generatePath, useNavigate } from 'react-router'
import api from 'api/sam'
import { Url } from 'routing'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'
import { useErrorToast } from 'hooks/useErrorToast'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { usePolicyDelete } from '../../usePolicyDelete'
import { getPolicyActions } from '../utils'

export const usePolicyActions = ({ policyId }: { policyId: string }) => {
  const navigate = useNavigate()
  const { showSuccessToast } = useToasts()
  const { setPopup } = useConfirmationPopup()

  const showErrorToast = useErrorToast()

  const { deletePolicy, isLoading: isDeleteLoading } = usePolicyDelete(policyId)

  const { mutate: applyPolicy, isLoading: isApplyLoading } = useMutation({
    mutationFn: () => api.policies.applyPolicy(policyId),
    onSuccess: () => {
      showSuccessToast('The policy will be applied shortly')
    },
    onError: (err) => showErrorToast(err),
  })

  const onApplyPolicyClick = useCallback(() => {
    applyPolicy()
  }, [applyPolicy])

  const onDeleteClick = useCallback(() => {
    setPopup({
      title: 'Policy deletion confirmation',
      message: 'Are you sure you want to delete this policy?',
      confirmButtonText: 'Delete',
      onConfirmClick: () => {
        deletePolicy()
      },
    })
  }, [setPopup, deletePolicy])

  const onCopyClick = useCallback(() => {
    navigate(generatePath(Url.SamPoliciesCreateCopy, { policyId }))
  }, [navigate, policyId])
  const onEditClick = useCallback(
    () => navigate(generatePath(Url.SamPoliciesEdit, { policyId })),
    [policyId, navigate],
  )

  const isLoading = isDeleteLoading || isApplyLoading
  const permissionUtils = usePermissions()

  return getPolicyActions({
    permissionUtils,
    isLoading,
    onEditClick,
    onApplyPolicyClick,
    onCopyClick,
    onDeleteClick,
  })
}
