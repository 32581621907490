import auditLog from './auditsLog'
import auth from './auth'
import clients from './clients'
import crossChecks from './crossChecks'
import currentUser from './currentUser'
import organisations from './organisations'
import permissions from './permissions'
import roles from './roles'
import toxic from './toxic'
import user from './user'
import groups from './groups'
import accounts from './accounts'

const idaveApi = {
  auditLog,
  auth,
  clients,
  crossChecks,
  currentUser,
  organisations,
  permissions,
  roles,
  toxic,
  user,
  groups,
  accounts,
}

export default idaveApi
