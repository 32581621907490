import { useQuerySamUser } from 'queries/sam/users'
import { useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { EntitiesTable } from 'components/EntitiesTable'
import { isAllSettled, mergeQueryStatuses } from 'utils/query'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { UserPoliciesReprovisionAction } from './UserPoliciesReprovisionAction'
import { getColumns } from './columns'
import { getUserPolicies } from './utils'
import { Policy } from './types'
import { useUserPolicyPreview } from '../../../../components/previews/UserPolicyPreview/hook'

export const UserPolicies = ({ userId }: { userId: string }) => {
  const { hasPermission } = usePermissions()

  const {
    data: user,
    status: userStatus,
    fetchStatus: userFetchStatus,
  } = useQuerySamUser({ params: userId })

  const entities = useMemo(() => getUserPolicies(user), [user])

  const isSettled = isAllSettled({ qs: userStatus, fs: userFetchStatus })
  const columns = useMemo(() => getColumns(isSettled), [isSettled])

  const getRowLink = useMemo(
    () =>
      hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_DETAILS)
        ? (policy: Policy) => generatePath(Url.SamPolicy, { policyId: policy.id })
        : undefined,
    [hasPermission],
  )

  const { openPolicySide } = useUserPolicyPreview()

  const onRowClick = useMemo(
    () =>
      hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_DETAILS)
        ? (policy: Policy) => {
            openPolicySide(policy.id, userId)
          }
        : undefined,
    [userId, hasPermission, openPolicySide],
  )

  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities,
  })
  return (
    <EntitiesTable
      columns={columns}
      totalCount={entities.length}
      entitiesTypeLabel="Policies"
      pluralForms={['policy', 'policies']}
      data={searched}
      loadingState={getLoadingState(
        mergeQueryStatuses({ qs: userStatus, fs: userFetchStatus }),
        entities.length,
      )}
      getRowLink={getRowLink}
      onRowClick={onRowClick}
      renderActions={() => <UserPoliciesReprovisionAction userId={userId} />}
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      searchAutoFocus
    />
  )
}
