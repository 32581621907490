import { CustomerCompany } from 'api/dart/customerCompanies'
import { EmployeeAccesses } from 'api/dart/employee'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useCallback, useMemo, useState } from 'react'
import {
  filterActiveIds,
  filterSelectedEntities,
  getLoadingState,
} from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { EntitiesTable } from 'components/EntitiesTable'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { notNullable } from 'utils/common'
import { pluralForm } from 'utils/string'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { Row } from './types'
import { getRows } from './utils'
import { useColumns } from './columns'
import { useAddUserPopup } from './components/AddUserPopup'
import { UserListActions } from './components/UserListActions'

type AllowedUserListProps = {
  customerCompany: CustomerCompany
  userMap?: Map<string, UserListItem>
  avatars?: Map<string, RevolutersAvatar>
  employeesAccesses?: EmployeeAccesses[]
  reset: () => void
}

export const AllowedUserList = ({
  customerCompany,
  avatars,
  userMap = new Map(),
  employeesAccesses,
  reset,
}: AllowedUserListProps) => {
  const loadingStatus = userMap?.size ? 'success' : 'loading'
  const { status: revoDescriptionStatus, getEmployeeDescription } =
    useGetEmployeeRevoDescription()

  const rows = useMemo(
    () =>
      getRows({
        userMap,
        customerCompany,
        avatars,
        employeesAccesses,
        getEmployeeDescription,
      }),
    [userMap, customerCompany, avatars, getEmployeeDescription, employeesAccesses],
  )

  const loadingState = getLoadingState(loadingStatus, rows.length)
  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities: rows,
  })

  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((showSelectedValue) => !showSelectedValue),
    [setShowSelected],
  )
  const selectedCount = filterActiveIds(selectedHash).length

  const getRowLink = useCallback(
    (row: Row) => generatePath(Url.User, { userId: row.id }),
    [],
  )

  const { setPopup, closePopup } = useConfirmationPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { hasSomePermissions } = usePermissions()
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
  )

  const { mutate: deleteModifier } = useMutation({
    mutationFn: (modifiersIds: string[]) =>
      dartApi.employee.deleteModifiers({
        payload: modifiersIds,
        crossCheck: isCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
    onSuccess(response) {
      const crosscheckId = getCrossCheckIdFromResponse(response)
      hideLoadingPopup()
      closePopup()
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        showSuccessToast('Access deleted')
        reset()
        queryClient.invalidateQueries([QueryKey.CustomerCompany, customerCompany.id])
        queryClient.invalidateQueries([QueryKey.DirectRelations])
      }
    },
    onError(error) {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Deletion failed',
        error,
      })
      closePopup()
    },
  })

  const { showAddUserPopup } = useAddUserPopup(customerCompany.id)

  const onDeleteClick = () =>
    setPopup({
      title: 'Are you sure you want to delete access to customer company?',
      message: pluralForm(selectedCount, [
        'User will no longer have access to deleted customer company data.',
        'Users will no longer have access to deleted customer company data.',
      ]),
      confirmButtonText: 'Delete',
      confirmButtonVariant: 'primary',
      onConfirmClick: () => {
        const modifierIds = filterSelectedEntities(rows, selectedHash)
          .map((selectedRow) => selectedRow.accessModifierId)
          .filter(notNullable)
        deleteModifier(modifierIds)
      },
    })
  const columns = useColumns({ isTeamsLoaded: revoDescriptionStatus !== 'loading' })

  const showAddPermissions = hasSomePermissions(
    DART_PERMISSIONS.EMPLOYEES_UPDATE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEES_UPDATE_ACCESS_MODIFIER_CROSS_CHECK,
  )
  const showDeletePermissions = hasSomePermissions(
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
  )

  return (
    <EntitiesTable
      totalCount={rows.length}
      entitiesTypeLabel="Employees"
      pluralForms={['employee', 'employees']}
      data={searched}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
      searchValue={searchValue}
      searchAutoFocus
      columns={columns}
      getRowLink={getRowLink}
      selectedHash={selectedHash}
      switchShowSelected={switchShowSelected}
      showSelected={showSelected}
      setSelectedHash={setSelectedHash}
      renderActions={() => (
        <UserListActions
          selectedCount={selectedCount}
          showAddPermissions={showAddPermissions}
          showDeletePermissions={showDeletePermissions}
          onRemoveClick={onDeleteClick}
          onAddClick={showAddUserPopup}
        />
      )}
    />
  )
}
