import { DataMaps } from 'view/AuditLogs/types'
import { Box, Subheader } from '@revolut/ui-kit'
import { DetailsGroup } from 'components/DetailsGroup'
import { RoleLink } from 'components/Links/RoleLink'
import {
  replaceClientApplication,
  stringifyLogCell,
} from 'view/AuditLogs/components/AuditLogsTable/utils'
import { formatDateTime } from 'utils/date'
import { AuditLogData } from 'api/idave/auditsLog'
import { Entity } from './components/Entity'
import { dataItemsToCells } from './utils'

export const Details = (props: { log: AuditLogData; dataMaps: DataMaps }) => {
  const { log, dataMaps } = props

  const roleId = log.Role?.value.id
  const until = log['Assigned Until']?.value
    ? formatDateTime(log['Assigned Until']?.value)
    : undefined

  const dataItems = dataItemsToCells(dataMaps, log.Data?.value)
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Action details</Subheader.Title>
      </Subheader>

      <DetailsGroup
        rows={[
          ['Event', stringifyLogCell(log.Event, dataMaps)],
          until ? ['Expire Date', until] : undefined,
          roleId ? ['Role', <RoleLink id={roleId} key={roleId} />] : undefined,
          [
            replaceClientApplication(log.Model.value.entity),
            <Entity
              dataMaps={dataMaps}
              entity={log.Model.value}
              key={log.Model.value.id}
            />,
          ],
          ...dataItems,
        ]}
      />
    </Box>
  )
}
