import { Page } from 'components/HeaderActions/types'
import { Text, Token } from '@revolut/ui-kit'
import { Content } from './components/InfoSideboxContent/Content'

export const getInfoSidebox = (page?: Page) => ({
  title: (
    <Text variant="heading1" color={Token.color.foreground}>
      Help and instructions
    </Text>
  ),
  body: <Content page={page} />,
})
