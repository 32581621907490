import { Permissions } from '@revolut-internal/idave-web-auth'
import { Box, Spinner, Text, VStack } from '@revolut/ui-kit'
import idaveApi from 'api/idave'
import { useEffect } from 'react'
import { auth, clearStoredUser } from 'services/auth'

export const Logout = () => {
  useEffect(() => {
    Promise.all([
      // Suppress possible network errors
      idaveApi.auth.logout().catch(() => {}),
      idaveApi.auth.logoutSso().catch(() => {}),
    ]).then(() => {
      clearStoredUser()
      Permissions.clear()
      auth.redirectToSignIn({ keepUrl: false })
    })
  }, [])

  return (
    <VStack pt="s-48" mx="auto" space="s-16">
      <Text>Logging you out...</Text>
      <Box mx="auto">
        <Spinner />
      </Box>
    </VStack>
  )
}
