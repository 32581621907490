import { Text, TextSkeleton } from '@revolut/ui-kit'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQuerySamUser } from 'queries/sam/users'
import { useCallback } from 'react'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { RevolutersSpecialisationLink } from './RevolutersSpecialisationLink'

export const RevolutersEmployeeSpec = (props: { id: string }) => {
  const {
    data: employeeInfo,
    status: qs,
    fetchStatus: fs,
  } = useQuerySamUser({
    params: props.id,
  })

  const EmptyFallback = useCallback(() => <Text>{EMPTY_VALUE_FALLBACK}</Text>, [])

  return (
    <QuerySwitch
      data={employeeInfo}
      required={[{ qs, fs }]}
      renderLoading={() => <TextSkeleton size={15} />}
      renderError={EmptyFallback}
      renderIdle={EmptyFallback}
      renderSuccess={({ data }) => {
        const { employee } = data
        return <RevolutersSpecialisationLink id={employee.specialisationId} />
      }}
    />
  )
}
