import { MoreBar } from '@revolut/ui-kit'
import { useQueryDirectRelations } from 'queries/dart/employees'
import { useQueryCustomerCompanyMap } from 'queries/dart/companies'
import { useQueryUserIdMap } from 'queries/idave/users'
import { DART_PERMISSIONS } from 'security'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { EntitiesTable } from 'components/EntitiesTable'
import { useToasts } from 'hooks/useToasts'
import { waitForAll } from 'utils/query'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useEditPopup } from 'hooks/useEditPopup'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { PermissionsCheck, usePermissions } from '@revolut-internal/idave-web-auth'
import { mapDirectRelations, getPopup } from './utils'
import { DirectViewTable } from './types'
import { DirectRelationsCreatePopup } from './DirectRelationsCreatePopup'
import { getColumns } from './columns'

export const UserAccesses = () => {
  const queryClient = useQueryClient()
  const {
    data: userMap = new Map(),
    status: userStatus,
    fetchStatus: userFetchStatus,
  } = useQueryUserIdMap()

  const {
    data: customerCompanyMap = new Map(),
    status: customerCompaniesStatus,
    fetchStatus: customerCompaniesFetchStatus,
  } = useQueryCustomerCompanyMap()

  const {
    data: directRelations = [],
    status: directRelStatus,
    fetchStatus: directRelationsFetchStatus,
  } = useQueryDirectRelations()

  const { showSuccessToast } = useToasts()
  const { setPopup: setConfirmation, closePopup: closeConfirmation } =
    useConfirmationPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const { setPopup: setEdit } = useEditPopup()

  const permissionUtils = usePermissions()
  const { toCrosscheck, isCrossCheck } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
  )

  const { mutate: deleteModifier } = useMutation({
    mutationFn: (modifiersIds: string[]) =>
      dartApi.employee.deleteModifiers({
        payload: modifiersIds,
        crossCheck: isCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
    onSuccess: (response) => {
      const crosscheckId = getCrossCheckIdFromResponse(response)
      hideLoadingPopup()
      closeConfirmation()
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        showSuccessToast('Direct access deleted')
        queryClient.invalidateQueries([QueryKey.DirectRelations])
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Direct access deletion failed',
        error: err,
      })
      closeConfirmation()
    },
  })

  const onDeleteClick = useCallback(
    (item: DirectViewTable) => {
      setConfirmation({
        ...getPopup(item),
        onConfirmClick: () => deleteModifier([item.id]),
      })
    },
    [setConfirmation, deleteModifier],
  )

  const columns = useMemo(
    () => getColumns({ onDeleteClick, customerCompanyMap, permissionUtils }),
    [onDeleteClick, customerCompanyMap, permissionUtils],
  )

  const entities = useMemo(
    () =>
      mapDirectRelations({
        userMap,
        directRelations,
      }),
    [directRelations, userMap],
  )

  const onCreateLimitationClick = useCallback(
    () =>
      setEdit({
        content: <DirectRelationsCreatePopup showSuccessToast={showSuccessToast} />,
        title: 'Add access',
      }),
    [setEdit, showSuccessToast],
  )

  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities,
  })

  const queryStatus = waitForAll(
    [
      {
        qs: directRelStatus,
        fs: directRelationsFetchStatus,
      },
    ],
    [
      { qs: userStatus, fs: userFetchStatus },
      { qs: customerCompaniesStatus, fs: customerCompaniesFetchStatus },
    ],
  )

  const getEmployeeLink = useCallback(({ employeeId: userId }: DirectViewTable) => {
    return generatePath(Url.UserProfile, { userId })
  }, [])

  return (
    <EntitiesTable
      enableNavigation
      totalCount={entities.length}
      entitiesTypeLabel="Accesses"
      pluralForms={['access', 'accesses']}
      data={searched}
      columns={columns}
      loadingState={getLoadingState(queryStatus, entities.length)}
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      searchAutoFocus
      getRowLink={getEmployeeLink}
      renderActions={() => (
        <PermissionsCheck
          somePermissions={[
            DART_PERMISSIONS.EMPLOYEES_UPDATE_ACCESS_MODIFIER,
            DART_PERMISSIONS.EMPLOYEES_UPDATE_ACCESS_MODIFIER_CROSS_CHECK,
          ]}
        >
          <MoreBar.Action useIcon="Plus" onClick={onCreateLimitationClick}>
            Add
          </MoreBar.Action>
        </PermissionsCheck>
      )}
    />
  )
}
