import { Match, Validate } from 'linkify-it'
import { ChangeStatusCommand } from 'api/types/crossCheck'
import { MENTION_COMMAND, STATUS_COMMAND } from './constants'

export const validateStatusCommand = /(provide_info|request_info)/
export const extractStatusCommand = (match: Match): ChangeStatusCommand | undefined => {
  if (!match.schema.startsWith(STATUS_COMMAND)) {
    return undefined
  }
  const command = match.url.replace(STATUS_COMMAND, '')
  return isCommand(command) ? command : undefined
}
export const isCommand = (command: string): command is ChangeStatusCommand =>
  command === 'request_info' || command === 'provide_info'

export const validateMention: Validate = (text, pos, self) => {
  const tail = text.slice(pos)

  if (!self.re.mentionEmail) {
    if (!self.re.src_email_name || !self.re.src_host_strict) {
      return false
    }

    self.re.mentionEmail = new RegExp(
      `^\\?email=${self.re.src_email_name}@${self.re.src_host_strict}`,
      'i',
    )
  }

  const match = tail.match(self.re.mentionEmail)
  return match ? match[0].length : 0
}

export const extractMentionEmail = (match: Match) => {
  if (!match.schema.startsWith(MENTION_COMMAND)) {
    return undefined
  }

  const [_path, paramsValue] = match.url.split('?')
  const params = new URLSearchParams(paramsValue)

  return params.get('email') || undefined
}
