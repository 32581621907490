import { SamPolicySubjectType } from 'api/sam/policies'

export const EMPLOYEE_INPUTS = ['employeeIds'] as const
export const EMPLOYEE_TYPE_INPUTS = ['employeeTypes'] as const
export const TEAM_INPUTS = [
  'teamIds',
  'employeeTypes',
  'specialisationIds',
  'seniorityIds',
] as const
export const DEPARTMENT_INPUTS = [
  'departmentIds',
  'employeeTypes',
  'specialisationIds',
  'seniorityIds',
] as const
export const SPEC_INPUTS = ['specialisationIds', 'employeeTypes', 'seniorityIds'] as const

export const DEFAULT_SUBJECT_TEAM = {
  subjectType: SamPolicySubjectType.Team as const,
  teamIds: [],
  employeeTypes: [],
  specialisationIds: [],
  seniorityIds: [],
}

export const DEFAULT_SUBJECT_DEPARTMENT = {
  subjectType: SamPolicySubjectType.Department as const,
  departmentIds: [],
  employeeTypes: [],
  specialisationIds: [],
  seniorityIds: [],
}

export const DEFAULT_SUBJECT_EMPLOYEE = {
  subjectType: SamPolicySubjectType.Employee as const,
  employeeIds: [],
}

export const DEFAULT_SUBJECT_SPEC = {
  subjectType: SamPolicySubjectType.Specialisation as const,
  specialisationIds: [],
  employeeTypes: [],
  seniorityIds: [],
}

export const DEFAULT_SUBJECT_EMPLOYEE_TYPE = {
  subjectType: SamPolicySubjectType.EmployeeType as const,
  employeeTypes: [],
}

export const DEFAULT_POLICY_DETAILS = {
  subject: {
    subjectType: undefined,
  },
  policyName: '',
  businessReason: '',
}
