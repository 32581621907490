import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { EntitySkeleton } from 'components/EntitySkeleton'
import { useCurrentUserId } from 'queries/idave/users'
import { UserTab } from './User'
import { UserDataAccessSkeleton } from './UserDataAccess'

export const UserSkeleton = (props: { userId: string; tab: UserTab }) => {
  const { data: currentUserId } = useCurrentUserId()
  const isOwnProfile = currentUserId === props.userId
  const { hasPermission, hasSomePermissions } = usePermissions()

  if (props.tab === 'profile') {
    const hasActions =
      hasSomePermissions(
        IDAVE_PERMISSIONS.USERS_TERMINATE,
        IDAVE_PERMISSIONS.USERS_RESET_PASSWORD,
      ) ||
      (isOwnProfile && hasPermission(IDAVE_PERMISSIONS.USERS_CURRENT_CHANGE_PASSWORD))

    return <EntitySkeleton variant="details" hasActions={hasActions} />
  }

  if (props.tab === 'dataAccess') {
    return <UserDataAccessSkeleton />
  }

  return <EntitySkeleton variant="table" hasActions={false} />
}
