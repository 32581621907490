import { DetailRow } from '../../../types'
import { DetailParams } from '../types'

export const getSpec = ({ entity }: DetailParams): DetailRow | undefined => {
  if (entity.type !== 'User' || !entity.value) {
    return undefined
  }
  return {
    title: 'Specialisation',
    value: {
      /** returns id to find an employee entity to decode team */
      current: entity.value.id,
      type: 'EmployeeSpecialisation',
    },
    changeType: 'NoChange',
    isContext: true,
  }
}
