import { Header, Input, TextArea, VStack } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

export const ToxicPairCreateSkeleton = () => {
  return (
    <>
      <Header variant="secondary">
        <Header.BackButton />
        <Header.Title>Create toxic pair</Header.Title>
      </Header>
      <VStack space="s-16" maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <Input disabled label="Toxic pair name" />
        <Input disabled label="First permission" />
        <Input disabled label="Second permission" />
        <Input disabled label="Owner" />
        <TextArea
          disabled
          rows={3}
          label="Justification (with related ticket mention)..."
        />
      </VStack>
    </>
  )
}
