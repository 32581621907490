import { useCallback, useEffect, useState } from 'react'
import { SideAvatar } from 'view/SideBox'
import { useSideBox } from 'view/SideBox/SideBox'
import { RESOURCE_SIDE } from 'view/SideBox/hooks/useSideboxNavigation'
import { Title } from './components/Title'
import { Body } from './components/Body'
import { Actions } from './components/Actions'

export const useResourcePreview = () => {
  const [resourceId, setResourceId] = useState<string | undefined>()
  const { openSide } = useSideBox()
  const onClose = useCallback(() => setResourceId(undefined), [setResourceId])

  useEffect(() => {
    if (!resourceId) {
      return undefined
    }
    const queryParams = {
      type: RESOURCE_SIDE,
      id: resourceId,
    }

    return openSide({
      title: <Title resourceId={resourceId} />,
      avatar: <SideAvatar iconName="People" />,
      body: <Body resourceId={resourceId} />,
      actions: <Actions resourceId={resourceId} />,
      queryParams,
      onClose,
    })
  }, [openSide, onClose, resourceId])

  return {
    openResourceSide: setResourceId,
  }
}
