import { Input, VStack } from '@revolut/ui-kit'
import { SamPolicySubjectType } from 'api/sam/policies'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { get, isEmpty } from 'lodash'
import { useQueryPolicies } from 'queries/sam/policies'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { useMapify } from 'hooks/useMapify'
import { PolicySubjectSelect } from './PolicySubjectSelect'
import { SamEditPolicySubjectField } from './SamEditPolicySubjectField'
import {
  getFieldRules,
  getPolicySubjectFields,
  getDefaultSubject,
  getPolicyNameRules,
} from './utils'
import { PolicyDetails } from './types'

export const SamEditPolicyDetails = (props: {
  policyDetails: PolicyDetails
  disableSubjectEdit: boolean
  setPolicyDetails: (details: PolicyDetails) => void
  setHasErrors: (hasErrors: boolean) => void
  currentEditingPolicyId?: string
}) => {
  const {
    policyDetails,
    currentEditingPolicyId,
    disableSubjectEdit,
    setHasErrors,
    setPolicyDetails,
  } = props
  const { data: policies = [] } = useQueryPolicies()

  const mappedPolicyNames = useMapify(policies, (policy) => policy.policyName)

  const { formState, control, setValue, getValues, watch } = useForm<PolicyDetails>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: policyDetails,
  })
  const { subject } = getValues()
  const fields = getPolicySubjectFields(subject.subjectType)

  useEffect(() => {
    if (disableSubjectEdit) {
      return setHasErrors(!!formState.errors?.policyName)
    }

    return setHasErrors(!isEmpty(formState.errors))
  }, [formState, setHasErrors, disableSubjectEdit])

  useEffect(() => {
    const subscription = watch((state) => {
      setPolicyDetails(state as PolicyDetails)
    })
    return subscription.unsubscribe
  }, [setPolicyDetails, watch])

  const nameChangeHandler = useCallback(
    (value: string) => {
      setValue('policyName', value, { shouldValidate: true })
    },
    [setValue],
  )
  const onNameChange = useInputStringChange(nameChangeHandler)

  const businessReasonHandler = useCallback(
    (value: string) => {
      setValue('businessReason', value, { shouldValidate: true })
    },
    [setValue],
  )
  const onBusinessReasonChange = useInputStringChange(businessReasonHandler)

  const onSubjectTypeChange = useCallback(
    (subjectType?: SamPolicySubjectType | null) => {
      if (subjectType) {
        const newSubject = getDefaultSubject(subjectType)
        setValue('subject', newSubject, { shouldValidate: true })
      }
    },
    [setValue],
  )

  return (
    <VStack space="s-24" maxWidth={MAXIMAL_DETAILS_WIDTH}>
      <Controller
        name="subject.subjectType"
        control={control}
        render={({ field: { onChange: _onChange, ref: _ref, ...rest } }) => (
          <PolicySubjectSelect
            {...rest}
            onChange={onSubjectTypeChange}
            disabled={disableSubjectEdit}
          />
        )}
      />

      {fields.map((field) => (
        <Controller
          key={`subject.${field}`}
          name={`subject.${field}`}
          control={control}
          rules={getFieldRules(field, subject.subjectType)}
          render={({ field: { onChange: _onChange, ref: _ref, ...rest } }) => (
            <SamEditPolicySubjectField
              {...rest}
              field={field}
              subjectType={subject.subjectType}
              setValue={setValue}
              disabled={disableSubjectEdit}
              values={getValues(`subject.${field}`)}
              error={get(formState.errors, `subject.${field}.message`)}
            />
          )}
        />
      ))}

      <Controller
        control={control}
        name="policyName"
        rules={getPolicyNameRules(mappedPolicyNames, currentEditingPolicyId)}
        render={({ field }) => (
          <Input
            {...field}
            ref={undefined}
            onChange={onNameChange}
            onClear={() => nameChangeHandler('')}
            label="Policy name"
            invalid={!!formState.errors.policyName?.message}
            errorMessage={formState.errors.policyName?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="businessReason"
        render={({ field }) => (
          <Input
            {...field}
            ref={undefined}
            onChange={onBusinessReasonChange}
            onClear={() => businessReasonHandler('')}
            label="Business reason"
          />
        )}
      />
    </VStack>
  )
}
