import { Client } from 'api/idave/clients'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { Box, Subheader, Table } from '@revolut/ui-kit'
import { useMemo, useState } from 'react'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { Account } from 'api/idave/accounts'
import { getColumns } from './columns'
import { makeGetChangeRowState } from '../../../../utils'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../ChangeSwitcher'
import { getEmptyScopesLabel, getRows } from './utils'

export const ScopeList = ({
  crossCheck,
  account,
}: {
  crossCheck: CrossCheckDetail
  account?: Account
}) => {
  const { data: clientMap, status: qs, fetchStatus: fs, refetch } = useQueryClientIdMap()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={clientMap}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view clients" />
      )}
      renderLoading={() => <Inner crossCheck={crossCheck} />}
      renderSuccess={() => (
        <Inner crossCheck={crossCheck} account={account} clientMap={clientMap} />
      )}
    />
  )
}

const Inner = (props: {
  account?: Account
  crossCheck: CrossCheckDetail
  clientMap?: Map<string, Client>
}) => {
  const { crossCheck, clientMap = new Map(), account } = props

  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])
  const { rows, counters } = useMemo(
    () =>
      getRows({
        crossCheck,
        clientMap,
        tabs,
        account,
      }),
    [crossCheck, clientMap, tabs, account],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState(defaultTab)
  const data = rows[tab]
  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Scopes</Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            tabs={tabs}
            currentTab={tab}
            counters={counters}
            onChange={setTab}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        loadingState={clientMap.size ? 'ready' : 'pending'}
        getRowState={getRowState}
        labelEmptyState={getEmptyScopesLabel(tab)}
      />
    </Box>
  )
}
