import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { makeMappedKeyDataHook } from 'queries/utils'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { ToxicAlertParams } from 'api/idave/toxic'

export const useQueryPermissions = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Permissions],
    queryFn: idaveApi.permissions.getPermissions,
    enabled: hasPermission(IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST),
    select: (response) => response.data || [],
  })
}

export const useQueryPermissionIdMap = makeMappedKeyDataHook(useQueryPermissions, 'id')

export const useQueryPermission = (permissionId: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Permission, permissionId],
    queryFn: () => idaveApi.permissions.getPermission(permissionId),
    select: (response) => response.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.PERMISSIONS_VIEW_DETAILS),
  })
}

export const useQueryToxicPairs = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.ToxicPairs],
    queryFn: idaveApi.toxic.getToxicPairs,
    select: (response) => response.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_VIEW_LIST),
  })
}

export const useQueryToxicPairsMap = makeMappedKeyDataHook(useQueryToxicPairs, 'id')

export const useQueryOrphanedPermissions = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.OrphanedPermissions],
    queryFn: idaveApi.permissions.getOrphanedPermissions,
    select: (response) => response.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.ORPHANED_PERMISSIONS_VIEW_LIST),
  })
}

export const useQueryToxicAlerts = (params?: ToxicAlertParams) => {
  const { hasPermission } = usePermissions()
  const toxicPermissionId = params?.toxicPermissionId
  const userId = params?.userId

  return useQuery({
    queryKey: [QueryKey.ToxicPairs, toxicPermissionId, userId],
    queryFn: () => idaveApi.toxic.getToxicAlerts({ toxicPermissionId, userId }),
    select: (response) => response.data,
    enabled: hasPermission(IDAVE_PERMISSIONS.TOXIC_PERMISSION_ALERTS_VIEW_LIST),
  })
}
