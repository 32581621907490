import { SAM_PERMISSIONS } from 'security'

export const EDIT = [
  SAM_PERMISSIONS.POLICIES_UPDATE,
  SAM_PERMISSIONS.POLICIES_UPDATE_WITH_CROSS_CHECK,
]

export const DELETE = [
  SAM_PERMISSIONS.POLICIES_DELETE,
  SAM_PERMISSIONS.POLICIES_DELETE_WITH_CROSS_CHECK,
]

export const CREATE = [
  SAM_PERMISSIONS.POLICIES_CREATE,
  SAM_PERMISSIONS.POLICIES_CREATE_WITH_CROSS_CHECK,
]

export const EDIT_RESOURCES = [
  SAM_PERMISSIONS.POLICIES_UPDATE_RESOURCES,
  SAM_PERMISSIONS.POLICIES_UPDATE_WITH_CROSS_CHECK,
]

export const EDIT_TRAININGS = EDIT
