import { useCallback, useState } from 'react'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { randomKey } from 'utils/common/randomKey'
import { getCrossCheckActionPermission } from 'view/CrossChecks/utils'
import { CommentInput } from './components/CommentInput'
import { useCrossCheckComment } from './useCrossCheckComment'
import { isFinished } from '../CrossCheckRules/utils'

type Props = {
  crossCheck?: CrossCheckDetail
  source: CrossCheckSystem
  commentRef: React.Ref<HTMLTextAreaElement>
}

export const CrossCheckComment = (props: Props) => {
  const { crossCheck, source, commentRef } = props
  const { hasPermission } = usePermissions()
  const [resetKey, setResetKey] = useState(randomKey())
  const { mutate, isLoading } = useCrossCheckComment({
    source,
    onSuccess: () => setResetKey(randomKey),
  })

  const submitComment = useCallback(
    (comment: string) => {
      mutate({ id: crossCheck?.id, comment })
    },
    [mutate, crossCheck?.id],
  )

  const crossCheckPermission = getCrossCheckActionPermission(source, 'COMMENT')
  const showInput =
    hasPermission(crossCheckPermission) && crossCheck && !isFinished(crossCheck)

  if (!showInput) {
    return null
  }

  return (
    <CommentInput
      crossCheck={crossCheck}
      key={resetKey}
      submitComment={submitComment}
      isLoading={isLoading}
      commentRef={commentRef}
    />
  )
}
