import { EntitySkeleton } from 'components/EntitySkeleton'
import { AccountTab } from 'view/Clients/Account/types'

type Props = {
  tab: AccountTab
}
export const AccountSkeleton = ({ tab }: Props) => {
  return (
    <EntitySkeleton
      variant={tab === 'permissions' ? 'table' : 'details'}
      hasActions={false}
    />
  )
}
