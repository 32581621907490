import { TableColumn, Action } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { DART_PERMISSIONS } from 'security'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { PermissionUtils } from 'services/permissions'
import { UserTableCell } from 'components/UserTableCell'
import { mapLimitationTypeToLabel, stringifyLimitationObject } from '../../utils'
import { UserRestriction } from '../../types'

type GetColumnsParams = {
  onDeleteClick: (id: string) => void
  permissionUtils: PermissionUtils
}
export const getColumns = (params: GetColumnsParams): TableColumn<UserRestriction>[] => {
  return BASE_COLUMNS.concat(getActionColumns(params))
}

const BASE_COLUMNS: TableColumn<UserRestriction>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (cellParams: { row: { original: UserRestriction } }) => {
      const { employeeId } = cellParams.row.original

      return <UserTableCell userId={employeeId} />
    },
    width: 2,
  },
  {
    Header: 'Restriction',
    accessor: (v) => mapLimitationTypeToLabel(v.type),
    id: 'type',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 2,
  },
  {
    Header: 'Justification',
    accessor: 'justification',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 3,
  },
  {
    Header: 'Customer ID',
    accessor: stringifyLimitationObject,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 3,
  },
]

const getActionColumns = ({
  onDeleteClick,
  permissionUtils: { hasSomePermissions },
}: GetColumnsParams): TableColumn<UserRestriction>[] => {
  if (
    !hasSomePermissions(
      DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
      DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
    )
  ) {
    return []
  }

  return [
    {
      Header: 'Action',
      id: 'action',
      Cell: ({ row }: { row: { original: UserRestriction }; value: string }) => (
        <PermissionsCheck
          somePermissions={[
            DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER,
            DART_PERMISSIONS.EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
          ]}
        >
          <Action
            onClick={(e) => {
              e.stopPropagation()
              onDeleteClick(row.original.id)
            }}
          >
            Delete
          </Action>
        </PermissionsCheck>
      ),
      align: 'center',
      width: 1,
    },
  ]
}
