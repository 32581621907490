import { useQueryPolicyUsers } from 'queries/sam/users'
import { EntitiesTable } from 'components/EntitiesTable'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { useColumns, SamPolicyEmployeesTable } from './SamPolicyEmployeesTable'

export const SamPolicyEmployees = (props: { id: string }) => {
  const { id } = props

  const { data: policyEmployees, status, refetch, fetchStatus } = useQueryPolicyUsers(id)

  const columns = useColumns(undefined)

  return (
    <QuerySwitch
      required={[{ fs: fetchStatus, qs: status }]}
      data={policyEmployees}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => <RequestErrorWidget action={refetch} />}
      renderLoading={() => (
        <EntitiesTable
          entitiesTypeLabel="Employees"
          pluralForms={['employee', 'employees']}
          columns={columns}
          data={[]}
          loadingState="pending"
        />
      )}
      renderSuccess={({ data }) =>
        data.length ? (
          <SamPolicyEmployeesTable policyEmployees={data} />
        ) : (
          <EmptyStatusWidget
            title="No users under this policy"
            imageCode="3D082"
            imageVersion="v2"
          />
        )
      }
    />
  )
}
