import { Role } from 'api/idave/roles'
import { Permission } from 'api/idave/permissions'
import { sortBy } from 'lodash'
import { pluralForm } from 'utils/string'
import { traverseRoleParents } from 'utils/role/traverseRoleParents'
import { RolePermission } from './types'

export const getToastText = (type: 'add' | 'delete', count: number) =>
  type === 'add'
    ? pluralForm(count, ['Permission added', 'Permissions added'])
    : pluralForm(count, ['Permission deleted', 'Permissions deleted'])

type GetPermissionRowsParams = {
  role: Role
  permissionMap: Map<string, Permission>
  roleMap: Map<string, Role>
}
export const getRolePermissions = ({
  role,
  permissionMap,
  roleMap,
}: GetPermissionRowsParams) => {
  const traversedRoles = traverseRoleParents({ roleId: role.id, roleMap })
  const ownPermissions = sortBy(getRoleSelfPermission({ role, permissionMap }), 'name')

  const inheritedPermissions = traversedRoles.reduce<RolePermission[]>(
    (permissionsRows, roleId) => {
      const roleItem = roleMap.get(roleId)
      const permissionItems: RolePermission[] = roleItem
        ? getRoleSelfPermission({
            permissionMap,
            role: roleItem,
          })
        : []

      return permissionsRows.concat(sortBy(permissionItems, 'name'))
    },
    [],
  )

  return ownPermissions.concat(inheritedPermissions)
}

const getRoleSelfPermission = (params: {
  role: Role
  permissionMap: Map<string, Permission>
}) => {
  const { role, permissionMap } = params

  return role?.permissions.map((permissionId) => {
    const permission = permissionMap.get(permissionId)
    return {
      id: `${permissionId}_${role.id}`,
      permissionId,
      name: permission?.name || permissionId,
      clientId: permission?.client || 'UNKNOWN CLIENT',
      ownerRoleId: role.id,
    }
  })
}
