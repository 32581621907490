import { isAxiosError } from 'axios'

const getErrorMessageText = (errorMsg?: string) =>
  errorMsg ? `Something went wrong: ${errorMsg}` : 'Something went wrong'

export const extractErrorMsg = (error: unknown) => {
  if (isAxiosError(error)) {
    const msg = error.response?.data.message || error.response?.data.detail
    return getErrorMessageText(msg)
  }

  const msg = error instanceof Error ? error.message : undefined
  return getErrorMessageText(msg)
}
