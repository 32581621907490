export const getToastText = (resourceIds: string[], type: 'add' | 'delete') => {
  if (resourceIds.length > 1) {
    return type === 'add'
      ? 'Resources successfully added'
      : 'Resources successfully removed'
  }

  return type === 'add' ? 'Resource successfully added' : 'Resource successfully removed'
}

export const getPopupText = (resourceIds: string[]) =>
  resourceIds.length > 1
    ? {
        message: 'Are you sure you want to remove selected resources?',
        title: 'Resource removal confirmation',
      }
    : {
        message: 'Are you sure you want to remove selected resources?',
        title: 'Resource removal confirmation',
      }
