import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { UserIds } from 'hooks/useCurrentUserIds'
import { PermissionUtils } from 'services/permissions'
import { isRecertification as isRecertificationCheck } from 'view/CrossChecks/CrossCheckList/utils'
import { getCrossCheckActionPermission } from 'view/CrossChecks/utils'
import { isFinished as getIsFinished } from '../../../CrossCheckRules/utils'

type CanDeclineParams = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
  userIds: UserIds
  permissionUtils: PermissionUtils
}

export const canDecline = ({
  source,
  crossCheck,
  userIds,
  permissionUtils,
}: CanDeclineParams) => {
  const requesterId = source === 'sam' ? userIds.peopleOps : userIds.idave

  const crossCheckPermission = getCrossCheckActionPermission(source, 'DECLINE')
  const isAllowed = permissionUtils.hasPermission(crossCheckPermission)
  const isRequester = requesterId === crossCheck.requester
  const isFinished = getIsFinished(crossCheck)

  const isRecertification = isRecertificationCheck(crossCheck.executableName)

  return isAllowed && isRequester && !isFinished && !isRecertification
}
