import { useEffect, useState } from 'react'

export const useInfoAutoOpen = ({
  openSideInfo,
  autoOpen,
}: {
  openSideInfo: () => void
  autoOpen?: boolean
}) => {
  const [initiallyOpen, setInitiallyOpen] = useState(false)

  useEffect(() => {
    if (autoOpen && !initiallyOpen) {
      setInitiallyOpen(true)
      openSideInfo()
    }
  }, [initiallyOpen, autoOpen, openSideInfo, setInitiallyOpen])
}
