import { RevolutersAvatar, RevolutersAvatarSizes } from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'

export const getAvatarUrl = (params: {
  user?: UserListItem
  type?: keyof RevolutersAvatarSizes
  avatars?: Map<string, RevolutersAvatar>
}) => {
  const { avatars, user, type = 'imageSmall' } = params
  if (!avatars || !user) {
    return undefined
  }
  const id = user.externalRef?.reference
  if (!id) {
    return undefined
  }

  return avatars.get(id)?.[type]
}

export const getEmployeeAvatarUrl = <T extends { id: string }>(params: {
  type?: keyof RevolutersAvatarSizes
  avatars?: Map<string, RevolutersAvatar>
  employee?: T
}) => {
  const { avatars, type = 'imageSmall', employee } = params
  if (!avatars || !employee) {
    return undefined
  }

  return avatars.get(employee.id)?.[type]
}
