import { Box, Subheader, Table } from '@revolut/ui-kit'
import { Role } from 'api/idave/roles'
import { useMemo } from 'react'
import { useQueryPermissionIdMap } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { mergeQueryStatuses } from 'utils/query'
import { COLUMNS } from './columns'
import { getPermissionRows } from './utils'

export const Permissions = (props: { role: Role }) => {
  const { role } = props
  const {
    data: permissionMap = new Map(),
    status: permissionsQS,
    fetchStatus: permissionsFS,
  } = useQueryPermissionIdMap()

  const {
    data: clientMap = new Map(),
    status: clientsQS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  const permissionRows = useMemo(
    () =>
      getPermissionRows({
        role,
        clientMap,
        permissionMap,
      }),
    [role, clientMap, permissionMap],
  )

  const loadingState = getLoadingState(
    mergeQueryStatuses(
      { qs: permissionsQS, fs: permissionsFS },
      { qs: clientsQS, fs: clientsFS },
    ),
    permissionRows.length,
  )

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Permissions</Subheader.Title>
      </Subheader>
      <Table
        columns={COLUMNS}
        data={permissionRows}
        loadingState={loadingState}
        labelEmptyState="No permissions for the role"
      />
    </Box>
  )
}
