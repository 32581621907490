import { Permission } from 'api/idave/permissions'
import { sortBy } from 'lodash'
import { notNullableMap } from 'utils/array'

export const getPermissionRows = (permissions: Permission[], clientId: string) =>
  sortBy(
    notNullableMap(permissions, ({ client, id, name, createdDate }) =>
      client === clientId
        ? {
            id,
            name,
            createdDate,
          }
        : undefined,
    ),
    'name',
  )
