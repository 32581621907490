import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { RolePayloadMVP } from 'api/idave/roles'
import { isAxiosError } from 'axios'
import { QueryKey } from 'helpers/configQuery'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useToasts } from 'hooks/useToasts'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'
import { PermissionIdPair } from 'view/Permissions/types'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useCrossCheck } from 'hooks/useCrossCheck'

export const useCreateRole = ({
  onErrorToxicPair,
  onSuccess,
}: {
  onSuccess: () => void
  onErrorToxicPair: (toxicPairs: PermissionIdPair[]) => void
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toCrosscheck, isCrossCheck } = useCrossCheck(
    IDAVE_PERMISSIONS.ROLES_CREATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_ROLE_CREATE_ACTION,
  )
  const { showSuccessToast } = useToasts()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  return useMutation({
    mutationFn: (newRole: RolePayloadMVP) => {
      return idaveApi.roles.addRole({
        payload: { ...newRole },
        crossCheck: isCrossCheck,
      })
    },
    onMutate: () => showLoadingPopup({ title: 'Creating...' }),
    onSuccess: (response) => {
      hideLoadingPopup()
      onSuccess()

      const crosscheckId = getCrossCheckIdFromResponse(response)
      if (isCrossCheck && crosscheckId) {
        return toCrosscheck(crosscheckId)
      }

      showSuccessToast('Role created')

      queryClient.resetQueries({
        queryKey: [QueryKey.Roles],
      })

      if ('id' in response.data) {
        const roleId = response.data.id
        return navigate(generatePath(Url.Role, { roleId }))
      }
      return navigate(Url.Roles)
    },
    onError: (err) => {
      hideLoadingPopup()
      if (isAxiosError(err) && err.response?.data.combinations) {
        onErrorToxicPair(err.response?.data.combinations)
      } else {
        showErrorPopup({
          title: 'Role creation failed',
          error: err,
        })
      }
    },
  })
}
