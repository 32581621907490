import { useCallback, useEffect, useState } from 'react'
import { useSideBox } from 'view/SideBox/SideBox'
import { AUDIT_LOG_TYPE } from 'view/SideBox/hooks/useSideboxNavigation/constants'
import { Body } from '.'
import { Title } from './components/Title'
import { Description } from './components/Description'
import { Avatar } from './components/Avatar'

export const useAuditSidebox = () => {
  const [eventId, setEventId] = useState<string>()

  const { openSide } = useSideBox()

  const onClose = useCallback(() => setEventId(undefined), [setEventId])

  useEffect(() => {
    if (!eventId) {
      return undefined
    }

    const queryParams = {
      type: AUDIT_LOG_TYPE,
      id: eventId,
    }

    return openSide({
      title: <Title eventId={eventId} />,
      description: <Description eventId={eventId} />,
      body: <Body eventId={eventId} />,
      avatar: <Avatar eventId={eventId} />,
      onClose,
      queryParams,
    })
  }, [eventId, openSide, onClose])

  return {
    openAuditLog: setEventId,
  }
}
