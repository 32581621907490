import { useController, useFormContext } from 'react-hook-form'
import { Fielded } from 'utils/types/fielded'
import { Cell, Checkbox, SwitchProps } from '@revolut/ui-kit'

type Props = Fielded<
  SwitchProps & {
    label: string
  }
>

export const FieldCheckbox = (props: Props) => {
  const { name, rules, label, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  return (
    <Cell use="label">
      <Checkbox {...field} {...rest} defaultChecked={field.value}>
        {label}
      </Checkbox>
    </Cell>
  )
}
