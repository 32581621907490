import { Entity } from 'api/idave/auditsLog'
import { DataMaps } from 'view/AuditLogs/types'
import { stringifyEntity } from 'view/AuditLogs/utils'
import { getEntityHref } from '../../utils'

export const getEntityRows = <T extends Entity>(
  entityIds: string[],
  entity: T,
  dataMaps: DataMaps,
) => {
  return entityIds
    .map((id) => {
      const value = { id, entity }
      const href = getEntityHref(value)
      const name = stringifyEntity({
        value,
        dataMaps,
        shortVersion: false,
      })

      return {
        href,
        name,
        id,
      }
    })
    .sort((a, b) => {
      if ((a.href && b.href) || (!a.href && !b.href)) {
        return 0
      }
      if (a.href && !b.href) {
        return -1
      }
      return 1
    })
}
