import axios from 'axios'

export type RecertificationPolicyMap = Record<string, string>
type RecertificationPolicyMapResponse = {
  data: RecertificationPolicyMap
}

export type Recertification = {
  id: string
  policyId: string
  eventName: string
}

export const recertification = {
  getRecertificationPolicyMap: () =>
    axios.get<RecertificationPolicyMapResponse>(
      '/sam-api/api/recertifications/policy-mapping',
    ),
  getRecertification: (id: string) =>
    axios.get<Recertification>(`/sam-api/api/recertifications/${id}`),
}
