import { CrossCheckDetail } from 'api/types/crossCheck'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQueryRoles } from 'queries/idave/roles'
import { Box, Subheader, Table } from '@revolut/ui-kit'
import { Role } from 'api/idave/roles'
import { useCallback, useMemo, useState } from 'react'
import { User } from 'api/idave/user'
import { useRolePreview } from 'components/previews/RolePreview'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { getColumns } from './columns'
import { makeGetChangeRowState } from '../../../../utils'
import { RoleRow } from './types'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../ChangeSwitcher'
import { getEmptyRoleLabel, getRoleList } from './utils'

export const RoleList = ({
  crossCheck,
  user,
}: {
  crossCheck: CrossCheckDetail
  user?: User
}) => {
  const { data: roles, status: qs, fetchStatus: fs, refetch } = useQueryRoles()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={roles}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view user roles" />
      )}
      renderLoading={() => <Inner crossCheck={crossCheck} user={user} />}
      renderSuccess={() => <Inner crossCheck={crossCheck} user={user} roles={roles} />}
    />
  )
}

const Inner = (props: { crossCheck: CrossCheckDetail; roles?: Role[]; user?: User }) => {
  const { roles, crossCheck, user } = props

  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])

  const { rows, counters } = useMemo(
    () =>
      getRoleList({
        crossCheck,
        roles,
        user,
        tabs,
      }),
    [crossCheck, roles, user, tabs],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState(defaultTab)
  const { openRoleSide } = useRolePreview()
  const onRowClick = useCallback(
    (row: RoleRow) => {
      openRoleSide(row.id)
    },
    [openRoleSide],
  )
  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])
  const data = rows[tab]
  const isLoading = !roles?.length

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Roles</Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            tabs={tabs}
            currentTab={tab}
            counters={counters}
            onChange={setTab}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        onRowClick={onRowClick}
        loadingState={isLoading ? 'pending' : 'ready'}
        getRowState={getRowState}
        labelEmptyState={getEmptyRoleLabel(tab)}
      />
    </Box>
  )
}
