import { RoleLink } from 'components/Links/RoleLink'
import { ClientLink } from 'components/Links/ClientLink'
import { PolicyLink } from 'components/Links/PolicyLink'
import { GroupName } from 'components/GroupName'
import { UserLink } from 'components/Links/UserLink'
import { RevolutersEmployeeTeam } from 'components/Links/RevolutersEmployeeTeam'
import { RevolutersEmployeeSpec } from 'components/Links/RevolutersEmployeeSpec'
import { EmployeeCompanyLink } from 'components/Links/EmployeeCompanyLink'
import { CustomerCompanyLink } from 'components/Links/CustomerCompanyLink'
import { AccountLink } from 'components/Links/AccountLink'
import { TowerComponentLink } from 'components/Links/TowerComponentLink'
import { DetailRow } from '../types'

export const getIdComponent = (row: DetailRow) => {
  switch (row.value.type) {
    case 'Role':
      return RoleLink
    case 'Client':
      return ClientLink
    case 'AccessPolicy':
      return PolicyLink
    case 'User':
      return UserLink
    case 'EmployeeTeam':
      return RevolutersEmployeeTeam
    case 'EmployeeSpecialisation':
      return RevolutersEmployeeSpec
    case 'EmployeeCompany':
      return EmployeeCompanyLink
    case 'CustomerCompany':
      return CustomerCompanyLink
    case 'TowerComponent':
      return (idProps: { id: string }) => <TowerComponentLink component={idProps.id} />
    case 'Group':
      return (idProps: { id: string }) => <GroupName id={idProps.id} />
    case 'ServiceAccount':
      return AccountLink

    default:
      return undefined
  }
}
