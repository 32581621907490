import { Box } from '@revolut/ui-kit'
import { useCallback, useState } from 'react'
import { randomKey } from 'utils/common/randomKey'
import { UsersTable, UsersTableProps } from './UsersTable'

export const UsersTableWrapper = (props: Omit<UsersTableProps, 'reset'>) => {
  const [resetKey, setResetKey] = useState(randomKey())

  const reset = useCallback(() => {
    setResetKey(randomKey())
  }, [setResetKey])

  return (
    <Box key={resetKey}>
      <UsersTable reset={reset} {...props} />
    </Box>
  )
}
