import { useQueryGroups } from 'queries/idave/groups'
import { Group, GroupType } from 'api/idave/groups'
import { mergeQueryStatuses } from 'utils/query'
import { useMemo } from 'react'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { IDAVE_PERMISSIONS } from 'security'
import { BaseSelect, BaseSelectWrapperProps } from './BaseSelect'

export type GroupSelectProps = {
  useGroupTypes?: GroupType[]
} & BaseSelectWrapperProps<Group>

const groupToOption = (group: Group) => ({
  id: group.id,
  value: group,
  key: group.id,
  label: group.externalRef?.reference
    ? `${group.name} (${group.externalRef?.reference})`
    : group.name,
})

export const GroupSelect = (props: GroupSelectProps) => {
  const { useGroupTypes, ...selectProps } = props

  const { data: groups = [], status: qs, fetchStatus: fs } = useQueryGroups()
  const queryResult = mergeQueryStatuses({ qs, fs })
  const typesMap = useMemo(() => toPresenceMap(useGroupTypes || []), [useGroupTypes])

  const items = useMemo(() => {
    return groups.filter((group) => typesMap[group.type])
  }, [typesMap, groups])

  return (
    <BaseSelect
      items={items}
      requiredPermission={IDAVE_PERMISSIONS.GROUPS_VIEW_LIST}
      queryResult={queryResult}
      itemToOption={groupToOption}
      {...selectProps}
    />
  )
}
