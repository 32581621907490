import {
  ArrayParams,
  CrossCheckDetail,
  Entity,
  EntityParam,
  StringParam,
} from 'api/types/crossCheck'
import { Token } from '@revolut/ui-kit'
import { CrossCheckValueTab, DiffItemStatus } from './types'

export const extractEntityArrayIds = <T extends Entity>(
  field?: ArrayParams<EntityParam<T>>,
) => field?.value.map((v) => v.value.id) || []

export const extractStringArrayValues = (field?: ArrayParams<StringParam>) =>
  field?.value.map(({ value }) => value) || []

export const makeGetChangeRowState =
  (tab: CrossCheckValueTab) => (params: { value: { status?: DiffItemStatus } }) => {
    const itemStatus = params.value.status

    if (!itemStatus || tab === 'current') {
      return {}
    }
    return {
      indicatorColor: itemStatus === 'added' ? Token.color.success : Token.color.red,
    }
  }

export const isDartCrossCheck = (crossCheck: CrossCheckDetail) =>
  [
    'DartEmployeeAddAccessModifiers',
    'DartEmployeeDeleteAccessModifiers',
    'DartEmployeeCompanyAddAccessModifiers',
    'DartEmployeeCompanyDeleteAccessModifiers',
    'RecertificationApprove',
  ].includes(crossCheck.executableName)
