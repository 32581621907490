import { MoreBar } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { useCallback } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getCrossCheckActionPermission } from 'view/CrossChecks/utils'
import { isFinished } from '../../CrossCheckRules/utils'

export const CommentAction = (props: {
  commentRef: React.RefObject<HTMLTextAreaElement>
  source: CrossCheckSystem
  crossCheck: CrossCheckDetail
}) => {
  const { hasPermission } = usePermissions()
  const { commentRef, source, crossCheck } = props

  const crossCheckPermission = getCrossCheckActionPermission(source, 'COMMENT')
  const allowed = hasPermission(crossCheckPermission) && !isFinished(crossCheck)

  const onClick = useCallback(() => {
    if (commentRef) {
      commentRef?.current?.scrollIntoView({ block: 'center' })
      commentRef?.current?.focus()
    }
  }, [commentRef])

  if (!allowed) {
    return null
  }

  return (
    <MoreBar.Action useIcon="16/CommentAdd" onClick={onClick}>
      Comment
    </MoreBar.Action>
  )
}
