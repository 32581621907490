import { isAllSettled } from 'utils/query'
import { useQueryOrgIdMap } from 'queries/idave/orgs'
import { useQueryPermissionIdMap } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useMemo } from 'react'
import { useQueryGroupIdMap } from 'queries/idave/groups'
import { AuditLogsTable } from './components/AuditLogsTable'

type AuditLogsListParams = {
  modelId?: string
}

export const AuditLogsList = ({ modelId }: AuditLogsListParams) => {
  const {
    data: userMap,
    status: userStatus,
    fetchStatus: userFetchStatus,
  } = useQueryUserIdMap()

  const {
    data: rolesMap,
    status: rolesStatus,
    fetchStatus: roleFetchStatus,
  } = useQueryRoleIdMap()

  const {
    data: clientsMap,
    status: clientsStatus,
    fetchStatus: clientFetchStatus,
  } = useQueryClientIdMap()

  const {
    data: permissionsMap,
    status: permissionsStatatus,
    fetchStatus: permissionFetchStatus,
  } = useQueryPermissionIdMap()

  const {
    data: organisationMap,
    status: orgStatus,
    fetchStatus: orgFetchStatus,
  } = useQueryOrgIdMap()

  const {
    data: groupMap,
    status: groupStatus,
    fetchStatus: groupFetchStatus,
  } = useQueryGroupIdMap()

  const isAllDataSettled = useMemo(
    () =>
      isAllSettled(
        { qs: userStatus, fs: userFetchStatus },
        { qs: rolesStatus, fs: roleFetchStatus },
        { qs: clientsStatus, fs: clientFetchStatus },
        { qs: permissionsStatatus, fs: permissionFetchStatus },
        { qs: orgStatus, fs: orgFetchStatus },
        { qs: groupStatus, fs: groupFetchStatus },
      ),
    [
      userStatus,
      rolesStatus,
      clientsStatus,
      permissionsStatatus,
      orgStatus,
      userFetchStatus,
      roleFetchStatus,
      clientFetchStatus,
      permissionFetchStatus,
      orgFetchStatus,
      groupStatus,
      groupFetchStatus,
    ],
  )

  return (
    <AuditLogsTable
      isAllDataSettled={isAllDataSettled}
      dataMaps={{
        userMap,
        rolesMap,
        clientsMap,
        permissionsMap,
        organisationMap,
        groupMap,
      }}
      modelId={modelId}
    />
  )
}
