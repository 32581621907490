import { CrossCheckDetail, CrossCheckState, CrossCheckSystem } from 'api/types/crossCheck'
import { DART_PERMISSIONS, IDAVE_PERMISSIONS, SAM_PERMISSIONS } from 'security'
import { PermissionUtils } from 'services/permissions'

export const canAssignReviewer = (
  source: CrossCheckSystem,
  crossCheck: CrossCheckDetail,
  { hasPermission }: PermissionUtils,
) => {
  const groups = getAssignableGroups(source, crossCheck)

  if (crossCheck.state !== CrossCheckState.AwaitingReview || !groups.length) {
    return false
  }

  switch (source) {
    case 'idave':
      return hasPermission(IDAVE_PERMISSIONS.CROSS_CHECKS_UPDATE_GROUPS_REVIEWERS)
    case 'sam':
      return hasPermission(SAM_PERMISSIONS.REVIEWER_GROUP_ADD_REVIEWERS)
    case 'dart':
      return hasPermission(DART_PERMISSIONS.CROSS_CHECKS_UPDATE_GROUPS_REVIEWERS)
    default:
      return false
  }
}

export const getAssignableGroups = (
  source: CrossCheckSystem,
  crossCheck: CrossCheckDetail,
) => {
  if (source === 'sam') {
    return crossCheck.reviewerGroups.filter((group) => !isSharedGroup(group.name))
  }

  return crossCheck.reviewerGroups
}

const SHARED_GROUPS = ['IAM']
const isSharedGroup = (groupName: string) => SHARED_GROUPS.includes(groupName)
