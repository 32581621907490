import { useFormContext, useController } from 'react-hook-form'

import { TextArea, TextAreaProps } from '@revolut/ui-kit'
import { Fielded } from 'utils/types/fielded'

export const FieldTextArea = (props: Fielded<TextAreaProps>) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  return <TextArea {...rest} {...field} />
}
