import { EntitySkeleton } from 'components/EntitySkeleton'
import { Box, TextSkeleton } from '@revolut/ui-kit'
import { CustomerCompanyTab } from '../CustomerCompany'

export const CustomerCompanySkeleton = (props: { tab: CustomerCompanyTab }) => {
  const variant = props.tab === 'approvers' ? 'details' : 'table'
  return (
    <EntitySkeleton
      variant={variant}
      hasActions={false}
      title={
        <Box ml="s-16">
          <TextSkeleton size={16} />
        </Box>
      }
    />
  )
}
