import { Client } from 'api/idave/clients'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { UrlRow } from './types'
import { extractStringArrayValues } from '../../../../utils'
import { CrossCheckValueTab } from '../../../../types'
import { getChangeRowsInfo } from '../../utils'

type GetUrlsInfoParams = {
  crossCheck: CrossCheckDetail
  client?: Client
  tabs: CrossCheckValueTab[]
}

export const getUrlRowsInfo = (params: GetUrlsInfoParams) => {
  const { crossCheck, client, tabs } = params
  const { executableParams } = crossCheck

  const current = client?.redirectUris || []
  const added = extractStringArrayValues(executableParams?.['New Redirection Uri'])
  const deleted = extractStringArrayValues(executableParams?.['Removed Redirection Uri'])
  const values = extractStringArrayValues(executableParams?.['Redirection Uri'])

  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
  })
}

type MakeGetUrlRowParams = {
  deletedHash?: Record<string, boolean>
  addedHash?: Record<string, boolean>
}

const makeMapValue =
  ({ deletedHash = {}, addedHash = {} }: MakeGetUrlRowParams) =>
  (value: string): UrlRow => {
    const removedStatus = deletedHash[value] ? 'removed' : undefined
    const addedStatus = addedHash[value] ? 'added' : undefined

    return {
      value,
      status: addedStatus || removedStatus,
    }
  }

export const getEmptyUrisLabel = (status: CrossCheckValueTab) =>
  status === 'requested'
    ? "There aren't added or deleted URIs"
    : 'No redirect URIs for the app'
