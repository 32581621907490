import { useCurrentUserId, useQueryUser } from 'queries/idave/users'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'hooks/useToasts'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useCallback } from 'react'
import { getName } from 'utils/string/getName'
import { useEditPopup } from 'hooks/useEditPopup'
import { PasswordChange } from 'api/idave/currentUser'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { UserChangePasswordPopup } from '../components/UserChangePasswordPopup'
import { useUserEdit } from './useUserEdit'
import { getUserActions } from '../utils'

export const useUserActions = ({ userId }: { userId: string }) => {
  const queryClient = useQueryClient()
  const { data: currentUserId } = useCurrentUserId()
  const isOwnProfile = currentUserId === userId

  const { data: user } = useQueryUser({ id: userId })
  const { showSuccessToast } = useToasts()
  const { setPopup, closePopup } = useConfirmationPopup()
  const { setPopup: setEditPopup, closePopup: closeEditPopup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const onUserEditClick = useUserEdit({ id: userId })
  const { mutate: mutateTerminate } = useMutation({
    mutationFn: () => {
      return idaveApi.user.terminateUser({ user_id: userId, state: 'TERMINATED' })
    },
    onMutate: () => showLoadingPopup({ title: 'Terminating...' }),
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      showSuccessToast('User terminated')
      queryClient.invalidateQueries({
        queryKey: [QueryKey.User, userId],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Users],
        refetchType: 'all',
      })
    },
    onError: (err) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({ title: 'Termination failed', error: err })
    },
  })

  const { mutate: mutateResetPassword } = useMutation({
    mutationFn: () => {
      return idaveApi.user.resetPassword({ id: userId })
    },
    onMutate: () => showLoadingPopup({ title: 'Resetting password...' }),
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      showSuccessToast('Password reset complete')
    },
    onError: (err) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({ title: 'Password reset failed', error: err })
    },
  })

  const { mutate: mutateActivateUser } = useMutation({
    mutationFn: () => {
      return idaveApi.user.activateUser({ user_id: userId, state: 'ACTIVE' })
    },
    onMutate: () => showLoadingPopup({ title: 'Activating...' }),
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      showSuccessToast('User activated')
      queryClient.invalidateQueries({
        queryKey: [QueryKey.User, userId],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Users],
        refetchType: 'all',
      })
    },
    onError: (err) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({ title: 'Activation failed', error: err })
    },
  })

  const { mutate: mutatePassword } = useMutation({
    mutationFn: (passwordChange: PasswordChange) => {
      return idaveApi.currentUser.changePassword(passwordChange)
    },
    onMutate: () => showLoadingPopup({ title: 'Updating password...' }),
    onSuccess: () => {
      hideLoadingPopup()
      closeEditPopup()
      showSuccessToast('Password changed')
    },
    onError: (error) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Password reset failed', error })
    },
  })

  const onActivateClick = useCallback(() => {
    setPopup({
      title: 'User activation confirmation',
      message: `Are you sure you want to activate ${user ? getName(user) : userId}?`,
      onConfirmClick: () => {
        mutateActivateUser()
      },
      confirmButtonText: 'Activate',
    })
  }, [setPopup, mutateActivateUser, user, userId])

  const onTerminateClick = useCallback(() => {
    setPopup({
      title: 'User termination confirmation',
      message: `Are you sure you want to terminate ${user ? getName(user) : userId}?`,
      onConfirmClick: () => {
        mutateTerminate()
      },
      confirmButtonText: 'Terminate',
    })
  }, [setPopup, mutateTerminate, user, userId])

  const onResetPasswordClick = useCallback(() => {
    setPopup({
      title: "User's password reset confirmation",
      message: `Are you sure you want to reset a password for ${
        user ? getName(user) : userId
      }?`,
      onConfirmClick: () => {
        mutateResetPassword()
      },
    })
  }, [setPopup, mutateResetPassword, user, userId])

  const onChangePasswordClick = useCallback(
    () =>
      setEditPopup({
        title: 'Change your password',
        content: (
          <UserChangePasswordPopup close={closeEditPopup} submit={mutatePassword} />
        ),
      }),
    [setEditPopup, closeEditPopup, mutatePassword],
  )

  const permissionUtils = usePermissions()

  if (!user) {
    return []
  }

  return getUserActions({
    permissionUtils,
    isOwnProfile,
    onActivateClick,
    onChangePasswordClick,
    onResetPasswordClick,
    onTerminateClick,
    onUserEditClick,
    userState: user.state,
  })
}
