export const isQueryParamsEqual = (
  paramsA: URLSearchParams,
  paramsB: URLSearchParams,
) => {
  // sort happens in place - prevent mutation
  const copyA = new URLSearchParams(paramsA.toString())
  const copyB = new URLSearchParams(paramsB.toString())

  copyA.sort()
  copyB.sort()

  return copyA.toString() === copyB.toString()
}
