import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { IDAVE_PERMISSIONS } from 'security'
import { AccountUpdatePayload } from 'api/idave/accounts'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useNavigate } from 'react-router'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { AccountTab } from '../types'

type Params = {
  accountId: string
  editTab?: AccountTab // tab to navigate back in case of direct edition
}

export const useEditAccount = ({ accountId, editTab = 'details' }: Params) => {
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_UPDATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_SERVICE_ACCOUNT_UPDATE_ACTION,
  )
  const navigate = useNavigate()

  const { mutate } = useMutation({
    mutationFn: ({
      authentication,
      clientId,
      id,
      permissionIds = [],
    }: Partial<AccountUpdatePayload>) => {
      if (!id) {
        return Promise.reject(new Error('Unknown account'))
      }

      if (!clientId) {
        return Promise.reject(new Error('Unknown client'))
      }

      const payload = {
        id,
        clientId,
        permissionIds,
        authentication: {
          gcpSA: authentication?.gcpSA,
        },
      }

      return idaveApi.accounts.updateAccount({
        payload,
        crossCheck: isCrossCheck,
      })
    },

    onMutate: () => showLoadingPopup({ title: 'Updating...' }),

    onSuccess: (response, { id }) => {
      hideLoadingPopup()
      const crosscheckId = getCrossCheckIdFromResponse(response)
      if (isCrossCheck && crosscheckId) {
        return toCrosscheck(crosscheckId)
      }
      queryClient.resetQueries({ queryKey: [QueryKey.Account, id] })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.AccountCustomization, id],
        refetchType: 'all',
      })
      showSuccessToast('Account updated')
      const tabUrl =
        editTab === 'permissions' ? Url.AccountPermissions : Url.AccountDetails
      return navigate(generatePath(tabUrl, { accountId }))
    },

    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Failed to update the account',
        error: err,
      })
    },
  })

  return mutate
}
