import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { HStack, MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { Role } from 'api/idave/roles'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { CREATE, DELETE, EDIT } from 'view/Roles/permissions'

export const RoleActions = (props: { role: Role }) => {
  const { role } = props
  const navigate = useNavigate()
  const { setPopup, closePopup } = useConfirmationPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const { mutate: removeRole } = useMutation({
    mutationFn: idaveApi.roles.removeRole,
    onSuccess: (_response, id) => {
      hideLoadingPopup()
      closePopup()
      navigate(Url.Roles)
      showSuccessToast('Role deleted')
      queryClient.invalidateQueries({
        queryKey: [QueryKey.RolesDeprecated],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Roles],
      })
      queryClient.removeQueries({ queryKey: [QueryKey.Role, id] })
    },
    onError: (error) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Role deletion failed', error })
      closePopup()
    },
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
  })

  const onDuplicateClick = useCallback(
    () => navigate(generatePath(Url.AddRoleDuplicate, { roleId: role.id })),
    [role, navigate],
  )

  const onEditClick = useCallback(
    () => navigate(generatePath(Url.EditRole, { roleId: role.id })),
    [role, navigate],
  )

  return (
    <HStack pb="s-4">
      <PermissionsCheck somePermissions={[...EDIT, ...CREATE, ...DELETE]}>
        <MoreBar>
          <PermissionsCheck somePermissions={EDIT}>
            <MoreBar.Action useIcon="Pencil" onClick={onEditClick}>
              Edit
            </MoreBar.Action>
          </PermissionsCheck>

          <PermissionsCheck somePermissions={CREATE}>
            <MoreBar.Action useIcon="Copy" onClick={onDuplicateClick}>
              Duplicate
            </MoreBar.Action>
          </PermissionsCheck>

          <PermissionsCheck somePermissions={DELETE}>
            <MoreBar.Action
              useIcon="Delete"
              variant="negative"
              onClick={() =>
                setPopup({
                  title: 'Delete role',
                  message: `Please to confirm to delete role ${role.name}`,
                  confirmButtonText: 'Delete',
                  onConfirmClick: () => removeRole(role.id),
                })
              }
            >
              Delete
            </MoreBar.Action>
          </PermissionsCheck>
        </MoreBar>
      </PermissionsCheck>
    </HStack>
  )
}
