import { createGlobalStyle } from 'styled-components'
import '@revolut/ui-kit/styles.css'
import { ErrorBoundary } from 'components/ErrorBoundry'
import { AppProvider } from 'helpers/Providers'
import { KitProvider } from 'helpers/Providers/KitProvider'
import { RootRoutes } from './view/Routes'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Basier Circle';
    font-display: swap;
    font-weight: 400;
    src: url('https://assets.revolut.com/media/fonts/BasierCircle-Regular.woff2');
  }
  @font-face {
    font-family: 'Basier Circle';
    font-display: swap;
    font-weight: 500;
    src: url('https://assets.revolut.com/media/fonts/BasierCircle-Medium.woff2');
  }
  @font-face {
    font-family: 'Basier Circle';
    font-display: swap;
    font-weight: 600;
    src: url('https://assets.revolut.com/media/fonts/BasierCircle-SemiBold.woff2');
  }
  @font-face {
    font-family: 'Basier Circle';
    font-display: swap;
    font-weight: 700;
    src: url('https://assets.revolut.com/media/fonts/BasierCircle-Bold.woff2');
  }
  :root {
    font-family: 'Basier Circle', -apple-system;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  * {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100vh;
  }
`

export const App = () => {
  return (
    <AppProvider>
      <UiBox />
      <GlobalStyle />
    </AppProvider>
  )
}

const UiBox = () => {
  return (
    <KitProvider>
      <ErrorBoundary>
        <RootRoutes />
      </ErrorBoundary>
    </KitProvider>
  )
}
