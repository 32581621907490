import { SelectOptionItemType } from '@revolut/ui-kit'
import { UserListItem } from 'api/idave/user'
import { notNullableMap } from 'utils/array'
import { getName } from 'utils/string/getName'
import { sortBy } from 'lodash'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { NamedUser } from './types'

export const getItems = (params: {
  users: UserListItem[]
  avatars?: Map<string, RevolutersAvatar>
  excludeIdsMap: Record<string, boolean>
  onlyActive?: boolean
  usePeopleOpsId?: boolean
}): NamedUser[] => {
  const { users, avatars, excludeIdsMap, onlyActive, usePeopleOpsId } = params

  return sortBy(
    notNullableMap(users, (user) => {
      const id = usePeopleOpsId ? user.externalRef?.reference : user.id
      const notActive = onlyActive && user.state !== 'ACTIVE'
      if (!id || excludeIdsMap[id] || notActive) {
        return undefined
      }

      return {
        ...user,
        name: getName(user),
        avatarUrl: getAvatarUrl({ user, avatars, type: 'imageMedium' }),
      }
    }),
    'name',
  )
}

export const peopleOpsItemToOption = (
  user: NamedUser,
): SelectOptionItemType<NamedUser> => {
  const name = getName(user)
  const peopleopsId = user.externalRef?.reference
  const label = peopleopsId ? `${name} (${peopleopsId})` : name

  return {
    key: user.id,
    label,
    value: user,
  }
}
