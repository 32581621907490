import { MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { QueryKey } from 'helpers/configQuery'
import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback, useEffect, useState } from 'react'
import { useCurrentUserIds } from 'hooks/useCurrentUserIds'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useToasts } from 'hooks/useToasts'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getCrossCheckApi } from 'view/CrossChecks/utils'
import { JustifyPopup } from './JustifyPopup'
import { canJustify } from './utils'

type Props = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
}

const POPUP_TITLE = 'Submit justification'

export const JustifyAction = (props: Props) => {
  const { crossCheck, source } = props

  const queryClient = useQueryClient()
  const { setPopup, closePopup, popup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()
  const { mutate } = useMutation({
    mutationFn: (params: { id: string; justification: string }) => {
      const { id, justification } = params
      const api = getCrossCheckApi(source)
      return api.justifyCrossCheck(id, {
        justification,
      })
    },
    onMutate: () => {
      showLoadingPopup({ title: 'Submitting justification...' })
    },
    onSuccess: () => {
      showSuccessToast('Crosscheck justified')
      hideLoadingPopup()
      queryClient.invalidateQueries([QueryKey.CrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.SamCrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.DartCrossCheckDetails, crossCheck?.id])
      closePopup()
    },
    onError: (err: Error) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Justification submission failed',
        error: err,
      })
    },
  })

  const submit = useCallback(
    (justification: string) => {
      if (justification) {
        mutate({ id: crossCheck.id, justification })
      }
    },
    [mutate, crossCheck.id],
  )

  const openJustifyPopup = useCallback(
    () =>
      setPopup({
        title: POPUP_TITLE,
        content: <JustifyPopup onCancel={closePopup} onSubmit={submit} />,
        isBottomSheet: true,
        headerVariant: 'bottom-sheet',
      }),
    [setPopup, closePopup, submit],
  )

  const { data: userIds } = useCurrentUserIds()
  const permissionUtils = usePermissions()
  const justificationAllowed = canJustify({
    source,
    crossCheck,
    ids: userIds,
    permissionUtils,
  })
  const [wasAutoOpened, setWasAutoOpened] = useState(false)

  useEffect(() => {
    if (justificationAllowed && !wasAutoOpened) {
      openJustifyPopup()
      setWasAutoOpened(true)
    }
  }, [openJustifyPopup, setWasAutoOpened, justificationAllowed, wasAutoOpened])

  // closing opened Justify popup after unmounting
  useEffect(
    () => () => {
      if (popup?.title === POPUP_TITLE) {
        closePopup()
      }
    },
    [popup, closePopup],
  )

  if (!justificationAllowed) {
    return null
  }

  return (
    <MoreBar.Action useIcon="Check" onClick={openJustifyPopup} variant="accent">
      Submit justification
    </MoreBar.Action>
  )
}
