import { GroupType } from 'api/idave/groups'
import { RULE_REQUIRED } from 'utils/form/rules'

export const GROUP_TYPES: GroupType[] = ['peopleops:team', 'perfectionist']

export const SCOPE_RULE = {
  ...RULE_REQUIRED,
  pattern: {
    value: /^[a-zA-Z0-9-._~]+$/,
    message:
      'Only alphanumerics (0-9, a-z, A-Z) and unreserved (-._~) characters are allowed',
  },
}
