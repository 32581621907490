import { DataMaps } from 'view/AuditLogs/types'
import { DataList } from './DataList'
import { getDataLists } from './utils'

type DataListsProps = {
  dataMaps: DataMaps
  data?: string
}

export const DataLists = ({ data, dataMaps }: DataListsProps) => {
  if (!data) {
    return null
  }
  const dataLists = getDataLists(data)

  return (
    <>
      {dataLists.map((dataList) => (
        <DataList key={dataList.title} dataList={dataList} dataMaps={dataMaps} />
      ))}
    </>
  )
}
