import { DataMaps } from 'view/AuditLogs/types'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryPermissionIdMap } from 'queries/idave/permissions'
import { useQueryOrgIdMap } from 'queries/idave/orgs'
import { useMemo } from 'react'
import { QuerySwitch } from 'components/QuerySwitch'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'

export const DataMapsProvider = ({
  renderComponent,
}: {
  renderComponent: (dataMaps: DataMaps) => JSX.Element
}) => {
  const {
    data: userMap,
    status: userStatus,
    fetchStatus: userFetchStatus,
  } = useQueryUserIdMap()

  const {
    data: rolesMap,
    status: rolesStatus,
    fetchStatus: roleFetchStatus,
  } = useQueryRoleIdMap()

  const {
    data: clientsMap,
    status: clientsStatus,
    fetchStatus: clientFetchStatus,
  } = useQueryClientIdMap()

  const {
    data: permissionsMap,
    status: permissionsStatatus,
    fetchStatus: permissionFetchStatus,
  } = useQueryPermissionIdMap()

  const {
    data: organisationMap,
    status: orgStatus,
    fetchStatus: orgFetchStatus,
  } = useQueryOrgIdMap()

  const dataMaps = useMemo(
    () => ({ userMap, rolesMap, clientsMap, permissionsMap, organisationMap }),
    [userMap, rolesMap, clientsMap, permissionsMap, organisationMap],
  )
  const mapsStatus = [
    { qs: userStatus, fs: userFetchStatus },
    { qs: rolesStatus, fs: roleFetchStatus },
    { qs: clientsStatus, fs: clientFetchStatus },
    { qs: permissionsStatatus, fs: permissionFetchStatus },
    { qs: orgStatus, fs: orgFetchStatus },
  ]
  return (
    <QuerySwitch
      required={mapsStatus}
      data={dataMaps}
      renderLoading={() => <DetailsGroupSkeleton />}
      renderSuccess={({ data }) => renderComponent(data)}
    />
  )
}
