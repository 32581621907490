import { Page } from 'components/HeaderActions/types'
import { InfoBlockName } from './infoContent'

export const CONTENT_PAGES: Record<Page, InfoBlockName[]> = {
  assignRoleRequest: ['role', 'roleAssignment'],
  userRoles: ['role', 'roleAssignment'],
  createClient: ['app', 'accessRequest'],
  updateClient: ['app', 'accessRequest'],
  createRole: ['role', 'rolePermissions', 'roleOwnership', 'roleName'],
  updateRole: ['role', 'rolePermissions', 'roleOwnership', 'roleName'],
  createPolicy: ['policy', 'policyTypes', 'policySeniority', 'policyApprovals'],
  updatePolicy: ['policy', 'policyTypes', 'policyApprovals'],
}
