import { Overlay, Token } from '@revolut/ui-kit'

export const ModalOverlay = (props: { children?: React.ReactElement }) => {
  const { children } = props

  return (
    <Overlay open bg={Token.color.layoutBackground}>
      {children}
    </Overlay>
  )
}
