import { Avatar, abbreviate } from '@revolut/ui-kit'
import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { MentionToken } from 'hooks/useTokenize/types'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'

export const mapTokensToUsers = (
  mentions: MentionToken[],
  emailUserMap?: Map<string, UserListItem>,
  avatars?: Map<string, RevolutersAvatar>,
) =>
  mentions.map(({ value }) => ({
    user: emailUserMap?.get(value),
    avatar: getAvatarUrl({
      user: emailUserMap?.get(value),
      avatars,
    }),
    email: value,
  }))

export const getUserProps = (user?: UserListItem, avatar?: string) => {
  return user
    ? {
        use: 'a' as const,
        target: '_blank',
        href: generatePath(Url.User, { userId: user.id }),
        useIcon: avatar ? (
          <Avatar
            size={20}
            uuid={user.id}
            label={abbreviate(getName(user))}
            image={avatar}
          />
        ) : undefined,
      }
    : {}
}
