import { Action, Box, Subheader, Text, VStack } from '@revolut/ui-kit'
import { Role } from 'api/idave/roles'
import { DetailsGroup } from 'components/DetailsGroup'
import { InternalLink } from 'components/Links/InternalLink'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { useQueryClients } from 'queries/idave/clients'
import { useQueryRoles } from 'queries/idave/roles'
import { useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { CopyableText } from 'components/CopyableText'
import { GroupName } from 'components/GroupName'
import { UserLink } from 'components/Links/UserLink'
import { shouldShowInheritanceTree } from './RoleInheritanceTree/utils'

type RoleDetailsInfoProps = { role: Role; openInheritanceTree: () => void }

export const RoleDetailsInfo = ({ role, openInheritanceTree }: RoleDetailsInfoProps) => {
  const { data: rolesMVP = [] } = useQueryRoles()
  const showInheritanceTree = shouldShowInheritanceTree(role.id, rolesMVP)

  const { data: clients = [] } = useQueryClients()
  const roleClient = role.client
  const client = useMemo(() => {
    return roleClient
      ? clients.find((c) => c.id === roleClient)?.name || roleClient
      : undefined
  }, [roleClient, clients])
  const parent = useMemo(
    () =>
      role.parent
        ? rolesMVP.find((i) => i.id === role.parent)?.name || role.parent
        : EMPTY_VALUE_FALLBACK,

    [role.parent, rolesMVP],
  )

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Details</Subheader.Title>
      </Subheader>

      <DetailsGroup
        hideEmpty
        rows={[
          ['Name', role.name],

          ['ID', <CopyableText value={role.id} key={role.id} />],

          [
            'Client',
            roleClient ? (
              <InternalLink href={generatePath(Url.Client, { clientId: roleClient })}>
                {client}
              </InternalLink>
            ) : undefined,
          ],

          ['Approver', role.approvers?.length && <UserLink id={role.approvers[0]} />],

          [
            'Owner group',
            role.ownerGroupId ? (
              <GroupName id={role.ownerGroupId} />
            ) : (
              EMPTY_VALUE_FALLBACK
            ),
          ],

          [
            'Parent role',
            <VStack space="s-12" align="end" key={parent}>
              <Text>{parent}</Text>
              {showInheritanceTree ? (
                <Action onClick={openInheritanceTree} useIcon="16/ListBullet">
                  Inheritance tree
                </Action>
              ) : null}
            </VStack>,
          ],
        ]}
      />
    </Box>
  )
}
