import { Box, Header, Input, TextSkeleton, VStack } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { useLinkNavigate } from 'hooks/useLinkNavigate'
import { times } from 'lodash'
import { generatePath } from 'react-router'
import { Url } from 'routing'

export const OrganisationEditSkeleton = (props: { organisationId: string }) => {
  const toOrg = useLinkNavigate(
    generatePath(Url.OrganisationDetails, { organisationId: props.organisationId }),
  )
  return (
    <>
      <Header variant="secondary">
        <Header.BackButton onClick={toOrg} />
        <Header.Title>
          <TextSkeleton size={13} />
        </Header.Title>
      </Header>
      <Box pt="s-12" maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <VStack space="s-24">
          {times(4, (idx) => (
            <Input ref={undefined} disabled key={idx} />
          ))}
        </VStack>
      </Box>
    </>
  )
}
