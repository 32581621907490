import { Client, ClientCustomization } from 'api/idave/clients'
import { VStack } from '@revolut/ui-kit'
import { ClientActions } from './components/ClientActions'
import { ClientInfo } from './components/ClientInfo'
import { ClientUrls } from './components/ClientUrls'
import { ClientDeprecatedRoles } from './components/ClientDeprecatedRoles'
import { CustomizationBanner } from './components/CustomizationBanner/CustomizationBanner'

export const ClientDetails = (props: {
  client: Client
  customization?: ClientCustomization
}) => {
  return (
    <>
      <ClientActions client={props.client} customization={props.customization} />
      <VStack space="s-16">
        <CustomizationBanner
          hasCustomization={!!props.customization}
          client={props.client}
        />
        <ClientInfo client={props.client} />
        <ClientUrls client={props.client} />
        <ClientDeprecatedRoles client={props.client} />
      </VStack>
    </>
  )
}
