import { Input, TextArea, VStack } from '@revolut/ui-kit'
import { useCallback, useState } from 'react'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { OrganisationEditFormState } from './types'

type OrganisationEditFormProps = {
  initialValue: OrganisationEditFormState
  isLoading: boolean
  onChange: (value: OrganisationEditFormState) => void
}

export const OrganisationEditForm = (props: OrganisationEditFormProps) => {
  const { isLoading, initialValue, onChange } = props

  const [showExternalRef, setShowExternalRef] = useState(false)

  const nameHandler = useCallback(
    (name: string) => onChange({ ...initialValue, name }),
    [initialValue, onChange],
  )
  const onNameChange = useInputStringChange(nameHandler)

  const codeHandler = useCallback(
    (code: string) => onChange({ ...initialValue, code }),
    [initialValue, onChange],
  )
  const onCodeChange = useInputStringChange(codeHandler)

  const descriptionHandler = useCallback(
    (description: string) => onChange({ ...initialValue, description }),
    [initialValue, onChange],
  )
  const onDescriptionChange = useInputStringChange(descriptionHandler)

  const originHandler = useCallback(
    (origin: string) => onChange({ ...initialValue, origin }),
    [initialValue, onChange],
  )
  const onOriginChange = useInputStringChange(originHandler)

  const referenceIdHandler = useCallback(
    (reference: string) => onChange({ ...initialValue, reference }),
    [initialValue, onChange],
  )
  const onReferenceIdChange = useInputStringChange(referenceIdHandler)

  const handleNameClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      if (event.altKey) {
        setShowExternalRef((v) => !v)
      }
    },
    [setShowExternalRef],
  )

  return (
    <VStack space="s-24">
      <Input
        value={initialValue.name}
        onChange={onNameChange}
        label="Name"
        disabled={isLoading}
        autoFocus
        onClick={handleNameClick}
      />

      <Input
        value={initialValue.code}
        onChange={onCodeChange}
        label="Code"
        disabled={isLoading}
      />

      <TextArea
        value={initialValue.description}
        onChange={onDescriptionChange}
        label="Description"
        disabled={isLoading}
      />

      {showExternalRef ? (
        <>
          <Input
            value={initialValue.origin}
            onChange={onOriginChange}
            label="External Origin (Don't fill this if you dont know what it is)"
            disabled={isLoading}
          />
          <Input
            value={initialValue.reference}
            onChange={onReferenceIdChange}
            label="External Reference (Don't fill this  if you dont know what it is)"
            disabled={isLoading}
          />
        </>
      ) : null}
    </VStack>
  )
}
