import { Input as BaseInput, InputProps, Box } from '@revolut/ui-kit'

/**
 * Same as kit output, but prevent layout shift when error is presence
 */
export const Input = ({ errorMessage, ...props }: InputProps) => {
  return (
    <Box height="s-56" mb="s-24">
      <BaseInput {...props} aria-invalid={!!errorMessage} errorMessage={errorMessage} />
    </Box>
  )
}
