import { Input, VStack } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

export const DetailFieldsSkeleton = () => {
  return (
    <VStack maxWidth={MAXIMAL_DETAILS_WIDTH} gap="s-24">
      <Input label="Client" pending disabled />
      <Input name="gcpSA" label="GCP Service agent (Optional)" pending disabled />
    </VStack>
  )
}
