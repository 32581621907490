import { SENIORITY } from 'constants/seniority'
import {
  MultiSelectInput,
  MultiSelectInputProps,
  SelectOptionItemType,
} from '@revolut/ui-kit'

const OPTIONS = Object.keys(SENIORITY).map((seniorityId) => ({
  key: SENIORITY[seniorityId],
  value: seniorityId,
  label: SENIORITY[seniorityId],
}))

type StringSelect = MultiSelectInputProps<string, SelectOptionItemType<string>>

type Props = Omit<StringSelect, 'options'>

export const SeniorityIdsSelect = (props: Props) => (
  <MultiSelectInput {...props} options={OPTIONS} valueAsTags />
)
