import { ActionButton } from '@revolut/ui-kit'
import { ActionResponse } from 'api/types/actionResponse'
import { AxiosError } from 'axios'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'hooks/useToasts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SAM_PERMISSIONS } from 'security'
import samApi from 'api/sam'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'

export const UserPoliciesReprovisionAction = (props: { userId: string }) => {
  const { userId } = props
  const queryClient = useQueryClient()

  const { showSuccessToast } = useToasts()
  const { setPopup, closePopup } = useConfirmationPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return samApi.policies.userProvision(userId)
    },
    onMutate: () => showLoadingPopup({ title: 'Policy re-provisioning...' }),
    onSuccess: () => {
      hideLoadingPopup()
      showSuccessToast('Policy re-provisioning complete')
      closePopup()
      queryClient.invalidateQueries([QueryKey.SamPolicies, { idaveUserId: userId }])
    },
    onError: (error: AxiosError<ActionResponse>) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Re-provisioning failed', error })
      closePopup()
    },
  })

  return (
    <PermissionsCheck permission={SAM_PERMISSIONS.USER_POLICIES_PROVISION}>
      <ActionButton
        key="reprovision"
        variant="primary"
        size="sm"
        useIcon="CalendarRecurring"
        pending={isLoading}
        onClick={() =>
          setPopup({
            title: 'Re-provision confirmation',
            message: 'Are sure you want to run policies re-provisioning?',
            onConfirmClick: mutate,
          })
        }
      >
        Re-provision policies
      </ActionButton>
    </PermissionsCheck>
  )
}
