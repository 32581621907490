import { HStack, Link } from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'
import { DotSeparatedText } from 'components/DotSeparatedText'

type ExternalTextLinkProps = {
  href: string
  text: string
  title?: string
  noSpace?: boolean
}

export const ExternalTextLink = (props: ExternalTextLinkProps) => {
  const { href, text, title, noSpace } = props
  return (
    <Link href={href} target="_blank" title={title || text} display="inline-block">
      <HStack align="center" space={noSpace ? undefined : 's-8'} width="fit-content">
        <DotSeparatedText text={text} />
        <LinkExternal size={16} />
      </HStack>
    </Link>
  )
}
