import { notNullable } from 'utils/common'
import { capitalizeFirst } from 'utils/string'
import { getDetailRow } from './utils'
import { DetailParams } from './types'
import { DetailRow } from '../../types'

export const getAccountInfo = (params: DetailParams): DetailRow[] => {
  const { entity, crossCheck } = params
  if (entity.type !== 'ServiceAccount') {
    return []
  }

  const source = params.crossCheck.executableParams.Source

  const requestedSource = source?.type === 'string' ? source.value : undefined
  const currentSource = entity.value?.source

  return [
    getDetailRow({
      crossCheck,
      title: 'Application',
      type: 'Client',
      current: entity.value?.clientId,
      requested: params.crossCheck.executableParams.Client?.value.id,
    }),
    getDetailRow({
      crossCheck,
      title: 'Auth ID',
      type: 'text',
      requested: params.crossCheck.executableParams.Authentication?.value.gcp,
      current: entity.value?.authentication.gcpSA,
    }),
    getDetailRow({
      crossCheck,
      title: 'Origin',
      type: 'text',
      current: currentSource && capitalizeFirst(currentSource),
      requested: requestedSource && capitalizeFirst(requestedSource),
    }),
  ].filter(notNullable)
}
