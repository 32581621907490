import { QueryFetchStatus, isAllSettled } from './isAllSettled'

export const waitForAllOptional = (...statuses: QueryFetchStatus[]) => {
  if (!isAllSettled(...statuses)) {
    return 'loading'
  }

  if (statuses.some((i) => i.qs === 'success')) {
    return 'success'
  }

  if (statuses.some(({ qs, fs }) => qs === 'loading' && fs === 'idle')) {
    return 'forbidden'
  }

  return 'error'
}
