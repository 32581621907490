import { Table, Text } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useCallback } from 'react'
import { useQueryGroupIdMap } from 'queries/idave/groups'
import { GroupOwners } from './GroupOwners'

export const GroupOwnersCell = ({ groupId }: { groupId?: string }) => {
  return (
    <Table.Cell>
      {groupId ? <GroupOwners groupId={groupId} /> : <Text>{EMPTY_VALUE_FALLBACK}</Text>}
    </Table.Cell>
  )
}

export const useGroupOwnersCellAccessor = () => {
  const { data: groupsMap = new Map() } = useQueryGroupIdMap()

  const { data: userMap } = useQueryUserIdMap()

  const accessor = useCallback(
    (groupId?: string) => {
      const group = groupId ? groupsMap.get(groupId) : undefined
      const user = group ? userMap?.get(group.ownerIds[0]) : undefined

      return user ? getName(user) : 'No owner'
    },
    [groupsMap, userMap],
  )

  return accessor
}
