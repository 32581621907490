import { useQueryRole } from 'queries/idave/roles'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { Role } from 'api/idave/roles'
import { VStack } from '@revolut/ui-kit'
import { EntityDescription } from 'components/EntityDescription'
import { Skeleton } from './components/Skeleton'
import { Policies } from './components/Policies'
import { Permissions } from './components/Permissions'
import { Details } from './components/Details'

type Props = {
  roleId: string
  userId?: string
}

export const Body = ({ roleId, userId }: Props) => {
  const { data: role, fetchStatus: fs, status: qs } = useQueryRole({ roleId })

  return (
    <QuerySwitch
      data={role}
      required={[{ fs, qs }]}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view roles" />
      )}
      renderLoading={() => <Skeleton userId={userId} />}
      renderSuccess={({ data }) => <Inner role={data} userId={userId} />}
    />
  )
}

type InnerProps = {
  role: Role
  userId?: string
}

const Inner = ({ role, userId }: InnerProps) => {
  return (
    <VStack space="s-16">
      <EntityDescription title="Description" description={role?.description} />
      <Details role={role} />
      <Policies roleId={role.id} userId={userId} />
      <Permissions role={role} />
    </VStack>
  )
}
