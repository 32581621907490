import { ActionResponse } from 'api/types/actionResponse'
import axios, { AxiosPromise } from 'axios'
import { splitPayloadHeader } from 'utils/api/splitBodyHeaders'
import { CrossCheckedPayload } from 'utils/types/crossChecked'

type AccountSource = 'idave' | 'automation'
export type AccountState = 'ACTIVE' | 'DELETED' | 'TERMINATED'
type AccountAuth = {
  gcpSA?: string
}

export type Account = {
  id: string
  clientId: string
  scope: string[] // client ids
  permissionIds: string[] // permission ids
  source: AccountSource
  state: AccountState
  createdDate: number
  updatedDate: number
  authentication: AccountAuth
}

export type AccountCustomization = {
  config: {
    authentication?: {
      custom: AccountAuth
      original: AccountAuth
    }
  }
}

export type AccountCreatePayload = Pick<
  Account,
  'clientId' | 'authentication' | 'permissionIds'
>
export type AccountUpdatePayload = Pick<
  Account,
  'id' | 'authentication' | 'permissionIds' | 'clientId'
>

const formatAccountPayload = <T extends AccountCreatePayload | AccountUpdatePayload>(
  payload: T,
): T => ({
  ...payload,
  authentication: {
    gcpSA: payload.authentication.gcpSA || undefined,
  },
})

const accounts = {
  getAccounts: (): AxiosPromise<Account[]> => axios.get('/api/service-accounts'),
  getAccount: (accountId: string): AxiosPromise<Account> =>
    axios.get(`/api/service-accounts/${accountId}`),

  deleteAccount: (params: CrossCheckedPayload<{ id: string }>) => {
    const {
      body: { id },
      headers,
    } = splitPayloadHeader(params)
    return axios.delete(`/api/service-accounts/${id}`, { headers })
  },

  addAccount: (params: CrossCheckedPayload<AccountCreatePayload>) => {
    const { body, headers } = splitPayloadHeader(params)
    return axios.post('/api/service-accounts', formatAccountPayload(body), { headers })
  },

  updateAccount: (params: CrossCheckedPayload<AccountUpdatePayload>) => {
    const {
      body: { id, ...payload },
      headers,
    } = splitPayloadHeader(params)
    return axios.put(`/api/service-accounts/${id}`, formatAccountPayload(payload), {
      headers,
    })
  },

  activateAccount: (params: CrossCheckedPayload<{ id: string }>) => {
    const {
      body: { id },
      headers,
    } = splitPayloadHeader(params)
    return axios.post(`/api/service-accounts/${id}/activate`, { headers })
  },

  terminateAccount: (params: CrossCheckedPayload<{ id: string }>) => {
    const {
      body: { id },
      headers,
    } = splitPayloadHeader(params)
    return axios.post(`/api/service-accounts/${id}/terminate`, { headers })
  },

  getAccountsCustomization: (): AxiosPromise<AccountCustomization[]> =>
    axios.get('/api/customizations/service-accounts'),

  getAccountCustomization: (id: string): AxiosPromise<AccountCustomization> =>
    axios.get(`/api/customizations/service-accounts/${id}`),

  resetAccountCustomization: (id: string): AxiosPromise<ActionResponse> =>
    axios.delete(`/api/customizations/service-accounts/${id}`),
}

export default accounts
