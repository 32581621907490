import { Table, TableColumn, Text } from '@revolut/ui-kit'
import { InternalLink } from 'components/Links/InternalLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { PolicyRow } from './types'

export const COLUMNS: TableColumn<PolicyRow>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (params: { row: { original: PolicyRow } }) => {
      const { id, name } = params.row.original
      return (
        <Table.Cell>
          <InternalLink href={generatePath(Url.SamPolicy, { policyId: id })}>
            {name}
          </InternalLink>
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (params: { row: { original: PolicyRow } }) => (
      <Table.StatusCell level={params.row.original.statusLevel}>
        <Text fontSize="14" fontWeight="500">
          {params.row.original.status}
        </Text>
      </Table.StatusCell>
    ),
  },
]
