import { IconName } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { PermissionUtils } from 'services/permissions'
import { isProduction } from 'utils/common'
import { isRecertification as isRecertificationCheck } from 'view/CrossChecks/CrossCheckList/utils'
import { getCrossCheckActionPermission } from 'view/CrossChecks/utils'

type CanExecuteParams = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
  permissionUtils: PermissionUtils
}

export const canExecute = ({ crossCheck, permissionUtils, source }: CanExecuteParams) => {
  const crossCheckPermission = getCrossCheckActionPermission(source, 'RETRY')
  const isAllowed = permissionUtils.hasPermission(crossCheckPermission)
  const isRecertification = isRecertificationCheck(crossCheck.executableName)

  return isAllowed && !isRecertification
}

type ButtonAppearance = {
  label: string
  iconName: IconName
}

const RETRY_APPEARANCE: ButtonAppearance = {
  label: 'Retry',
  iconName: 'Retry',
}
const FORCE_EXECUTE_APPEARANCE: ButtonAppearance = {
  label: 'Execute',
  iconName: 'Play',
}

export const getButtonAppearance = ({ source }: { source: CrossCheckSystem }) => {
  return source === 'dart' || isProduction() ? RETRY_APPEARANCE : FORCE_EXECUTE_APPEARANCE
}
