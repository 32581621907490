import { CrossCheckEntityType } from 'api/types/crossCheck'
import { DetailRow } from '../../types'
import { DetailParams } from './types'
import { CrossCheckEntity } from '../../../../types'

const getEntityRowTitle = (entityType: CrossCheckEntityType) => {
  const X_CHECK_TO_ROW_ENTITY_TITLE_MAP: Record<CrossCheckEntityType, string> = {
    Client: 'Application',
    Role: 'Role',
    AccessPolicy: 'Policy',
    User: 'User',
    CustomerCompany: 'Customer company',
    EmployeeCompany: 'Employee company',
    ServiceAccount: 'Account',
    Recertification: 'Recertification',
  }

  return X_CHECK_TO_ROW_ENTITY_TITLE_MAP[entityType] || entityType
}

const getEntityId = (entity?: CrossCheckEntity) => {
  switch (entity?.type) {
    case 'AccessPolicy':
      return entity.value?.policyId
    default:
      return entity?.value?.id
  }
}

export const getEntity = ({ entity }: DetailParams): DetailRow | undefined => {
  const id = getEntityId(entity)

  if (!id) {
    return undefined
  }

  // Entity value is immutable
  return {
    title: getEntityRowTitle(entity.type),
    value: {
      current: id,
      forecasted: id,
      requested: id,
      type: entity.type,
    },
    changeType: 'NoChange',
    isContext: true,
  }
}
