import { Box, Subheader } from '@revolut/ui-kit'
import { SamResource } from 'api/sam/resources'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { GroupName } from 'components/GroupName'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { getResourceLink } from 'utils/resource/getResourceLink'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'
import { capitalizeFirst } from 'utils/string'
import { ResourceLink } from 'components/Links/ResourceLink'
import { ResourceActions } from './components/ResourceActions'

type Props = {
  resource: SamResource
}
export const ResourceDetails = ({ resource }: Props) => {
  const resourceLink = getResourceLink(resource)
  return (
    <>
      <ResourceActions resource={resource} />
      <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>
        <DetailsGroup
          rows={[
            ['Name', resource.resourceName],
            ['Type', getResourceTypeLabel(resource.resourceType)],
            [
              'ID',
              <CopyableText value={resource.resourceId} key="id">
                {resource.resourceId}
              </CopyableText>,
            ],
            resourceLink && [
              capitalizeFirst(resourceLink.label),
              <ResourceLink resource={resource} key="link" />,
            ],
            [
              'Owner group',
              resource.ownerGroupId ? (
                <GroupName id={resource.ownerGroupId} key="owner" />
              ) : (
                EMPTY_VALUE_FALLBACK
              ),
            ],
            [
              'External id',
              <CopyableText value={resource.externalId} key="externalId">
                {resource.externalId}
              </CopyableText>,
            ],
          ]}
          hideEmpty
        />
      </Box>
    </>
  )
}
