import { CrossCheckDetail } from 'api/types/crossCheck'
import { MENTION_COMMAND, STATUS_COMMAND } from 'hooks/useLinkify/constants'
import { notNullable } from 'utils/common'
import { COMMENT, PROVIDE_INFO, REQUEST_INFO } from './constants'
import { Action } from './types'

export const getCurrentActions = (crossCheck: CrossCheckDetail) => {
  switch (crossCheck.status) {
    case 'MORE_INFO_REQUIRED':
      return [COMMENT, PROVIDE_INFO]
    case 'WAITING_FOR_APPROVAL':
    case 'WAITING_FOR_SUPPORT':
    default:
      return [COMMENT, REQUEST_INFO]
  }
}

export const getCommentRawValue = (value: string, action: Action, emails: string[]) => {
  const actionValue = action !== 'comment' ? `${STATUS_COMMAND}${action}` : undefined
  const emailValue = emails.length
    ? emails.map((email) => `${MENTION_COMMAND}?email=${email}`).join(' ')
    : undefined

  return [actionValue, emailValue, value].filter(notNullable).join(' ')
}
