import { useQuery } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { Optioned } from '../types'
import { isEnabled } from '../utils'

export const useQueryDartCrossChecksList = (
  params: {
    reviewer?: string
    requester?: string
  } = {},
) => {
  const { reviewer, requester } = params
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.DartCrossChecksList, requester, reviewer],
    queryFn: () => dartApi.crossChecks.getCrossChecksList({ reviewer, requester }),
    enabled: hasPermission(DART_PERMISSIONS.CROSS_CHECKS_VIEW_LIST),
    select: (response) => response.data,
  })
}

export const useQueryDartCrossCheckDetails = ({
  params: id,
  options,
}: Optioned<string>) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.DartCrossCheckDetails, id],
    queryFn: () => dartApi.crossChecks.getCrossCheckDetails(id),
    select: (response) => response.data,
    enabled: isEnabled(
      hasPermission(DART_PERMISSIONS.CROSS_CHECKS_VIEW_DETAILS),
      options,
    ),
  })
}
