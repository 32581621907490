import { CheckboxSelect, Table } from '@revolut/ui-kit'
import { useMemo } from 'react'
import { Row, ColumnInstance } from 'react-table'
import { valueToItems } from './utils'

export const SelectItemsFilter = <Entity extends object>(table: {
  preFilteredRows: Array<Row<Entity>>
  column: ColumnInstance<Entity>
}) => {
  const { preFilteredRows, column } = table
  const options = useMemo(() => {
    const unique = new Set<string>()

    preFilteredRows.forEach((row) => {
      const value = row.values[column.id]
      if (value) {
        const items = valueToItems(value)
        items.forEach((item) => unique.add(item))
      }
    })

    // already active filter items (e.g from search params)
    column.filterValue?.forEach((value: unknown) => {
      if (typeof value === 'string') {
        const items = valueToItems(value)
        items.forEach((item) => unique.add(item))
      }
    })

    return Array.from(unique)
      .sort((a, b) => {
        if (a === b) {
          return 0
        }
        return a.toLocaleString().includes(b.toLocaleString()) ? -1 : 1
      })
      .map((value) => ({
        key: value,
        label: value,
        value,
      }))
  }, [preFilteredRows, column])
  return (
    <Table.Filter column={column} aria-label="Open filter" aria-haspopup="listbox">
      {(filter) => (
        <CheckboxSelect
          type="confirm"
          {...filter.getCheckboxSelectProps({
            options,
            searchable: true,
            labelList: 'Select',
            labelApply: 'Apply',
            labelClear: 'Clear',
            labelNoResults: 'No results found',
          })}
        />
      )}
    </Table.Filter>
  )
}
