import axios from 'axios'
import { ActionResponse } from 'api/types/actionResponse'
import { CrossCheckedPayload } from 'utils/types/crossChecked'
import { splitPayloadHeader } from 'utils/api/splitBodyHeaders'
import { AccessModifier, AccessModifierLimited, AddEmployeeModifier } from './types'
import { EmployeeCompany } from './employeeCompanies'

export type EmployeeAccesses = {
  id: string
  accessModifiers: AccessModifier[]
}

export type EmployeeDetailsNotLimited = {
  id: string
  company?: EmployeeCompany
  accessModifiers: AccessModifier[]
}

type EmployeeDetailsLimited = Omit<EmployeeDetailsNotLimited, 'accessModifiers'> & {
  accessModifiers: AccessModifierLimited[]
}

export type EmployeeDetails = EmployeeDetailsNotLimited | EmployeeDetailsLimited

export const employee = {
  getEmployees: () => axios.get<EmployeeAccesses[]>('/dart-api/dart/employees'),

  getEmployeeDetails: <T = EmployeeDetails>(id: string) =>
    axios.get<T>(`/dart-api/dart/employees/details/${id}`),

  getCurrentEmployee: () =>
    axios.get<EmployeeAccesses>('/dart-api/dart/employees/current'),

  addModifiers: (params: CrossCheckedPayload<AddEmployeeModifier[]>) => {
    const { body, headers } = splitPayloadHeader(params)
    return axios.post<ActionResponse>(
      '/dart-api/dart/employees/access-modifiers/add',
      body,
      { headers },
    )
  },

  deleteModifiers: (params: CrossCheckedPayload<string[]>) => {
    const { body, headers } = splitPayloadHeader(params)
    return axios.post<ActionResponse>(
      `/dart-api/dart/employees/access-modifiers/delete`,
      body,
      { headers },
    )
  },
}
