import { HStack, Text, VStack } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { CrossCheckValueTab } from '../../../types'
import { DiffSuffix } from './DiffSuffix'
import { EntityValue } from '../types'

export const DetailEntity = (props: {
  tab: CrossCheckValueTab
  value: EntityValue
  IDComponent: React.FC<{ id: string }>
  isFinished: boolean
}) => {
  const { value, IDComponent, tab, isFinished } = props

  switch (tab) {
    case 'current':
    case 'forecasted': {
      const id = value[tab]
      return id ? <IDComponent id={id} /> : <Text>{EMPTY_VALUE_FALLBACK}</Text>
    }

    case 'requested':
    default: {
      const { requested, current } = value
      if (isFinished || requested === current) {
        return requested ? (
          <IDComponent id={requested} />
        ) : (
          <Text>{EMPTY_VALUE_FALLBACK}</Text>
        )
      }

      return (
        <VStack align="end">
          {requested && (
            <HStack space="s-6" align="center">
              <IDComponent id={requested} />
              {current && <DiffSuffix isNew />}
            </HStack>
          )}
          {current && (
            <HStack space="s-6" align="center">
              <IDComponent id={current} />
              <DiffSuffix />
            </HStack>
          )}
        </VStack>
      )
    }
  }
}
