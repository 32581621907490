import { Text, TextSkeleton } from '@revolut/ui-kit'
import { useQueryGroup } from 'queries/idave/groups'
import { mergeQueryStatuses } from 'utils/query'

type Props = {
  groupId: string
}

export const GroupTitle = ({ groupId }: Props) => {
  const { data, fetchStatus, status } = useQueryGroup(groupId)
  const queryStatus = mergeQueryStatuses({ fs: fetchStatus, qs: status })

  const name = data?.name || groupId

  return queryStatus === 'loading' ? <TextSkeleton size={12} /> : <Text>{name}</Text>
}
