import { Page } from '@revolut/ui-kit'
import { useQueryEmployeeMap } from 'queries/sam/users'
import {
  useQueryDepartmentMap,
  useQuerySpecMap,
  useQueryTeamMap,
} from 'queries/sam/revoluters'
import { useQueryUsers } from 'queries/idave/users'
import { IDAVE_PERMISSIONS } from 'security'
import { useCallback, useMemo } from 'react'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { EntitiesTable } from 'components/EntitiesTable'
import { isAllSettled, mergeQueryStatuses } from 'utils/query'
import { useLoadingToast } from 'hooks/useLoadingToast'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { PermissionsCheck, usePermissions } from '@revolut-internal/idave-web-auth'
import { NewUser } from './components/NewUser'
import { useColumns } from './columns'
import { mapUsers } from './utils'
import { User } from './types'

const LOADING_TIMEOUT = 2000
const SEARCH_TIMEOUT = 500

export const Users = () => {
  const {
    data: users = [],
    status: usersStatus,
    fetchStatus: userFetchStatus,
  } = useQueryUsers()
  const {
    data: employeesMap,
    status: employeesStatus,
    fetchStatus: employeeFetchStatus,
  } = useQueryEmployeeMap()

  const navigate = useNavigate()

  const {
    data: departmentsMap,
    status: revolutersStatus,
    fetchStatus: revolutersFetchStatus,
  } = useQueryDepartmentMap()
  const { data: specsMap } = useQuerySpecMap()
  const { data: teamsMap } = useQueryTeamMap()

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: users,
    timeout: SEARCH_TIMEOUT,
  })
  const userStatus = {
    qs: usersStatus,
    fs: userFetchStatus,
  }

  const statuses = [
    {
      qs: employeesStatus,
      fs: employeeFetchStatus,
    },
    {
      qs: revolutersStatus,
      fs: revolutersFetchStatus,
    },
  ]
  const isDataSettled = isAllSettled(...statuses, userStatus)

  useLoadingToast(
    [...statuses, userStatus],
    "Just a little longer, we're loading",
    LOADING_TIMEOUT,
  )
  const data = useMemo(
    () =>
      mapUsers({
        users: searched,
        departmentsMap,
        employeesMap,
        specsMap,
        teamsMap,
      }),
    [searched, departmentsMap, employeesMap, specsMap, teamsMap],
  )

  const { hasPermission } = usePermissions()

  const onClick = useCallback(
    ({ id }: User) => {
      if (hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_DETAILS)) {
        navigate(generatePath(Url.User, { userId: id }))
      }
    },
    [navigate, hasPermission],
  )

  const getRowLink = useCallback(
    ({ id }: User) => generatePath(Url.User, { userId: id }),
    [],
  )
  const columns = useColumns({ isDataSettled })

  const loadingState = getLoadingState(mergeQueryStatuses(userStatus), users.length)
  const dataLabels = useMemo(
    () => [
      {
        label: 'Active',
        value: users.filter(({ state }) => state === 'ACTIVE').length,
      },
    ],
    [users],
  )
  return (
    <>
      <Page.Header>Users</Page.Header>

      <Page.Main>
        <EntitiesTable
          isStickyHeader
          entitiesTypeLabel="Users"
          pluralForms={['user', 'users']}
          enableNavigation
          dataLabels={dataLabels}
          columns={columns}
          data={data}
          loadingState={loadingState}
          onSearchChange={setSearchValue}
          searchValue={searchValue}
          searchAutoFocus
          onRowClick={onClick}
          getRowLink={getRowLink}
          renderActions={() => (
            <PermissionsCheck permission={IDAVE_PERMISSIONS.USERS_CREATE}>
              <NewUser />
            </PermissionsCheck>
          )}
        />
      </Page.Main>
    </>
  )
}
