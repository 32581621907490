import { Client } from 'api/idave/clients'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQueryPermissionIdMap, useQueryPermissions } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { Permission } from 'api/idave/permissions'
import { Box, Subheader, Table } from '@revolut/ui-kit'
import { useCallback, useMemo, useState } from 'react'
import { usePermissionPreview } from 'components/previews/PermissionPreview'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { getEmptyPermissionsLabel, getPermissionRowsData } from './utils'
import { getColumns } from './columns'
import { makeGetChangeRowState } from '../../../../utils'
import { PermissionRow } from './types'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../ChangeSwitcher'
import { CrossCheckEntity } from '../../../../types'

export const PermissionList = ({
  crossCheck,
  permissionIds,
  entityType,
}: {
  crossCheck: CrossCheckDetail
  permissionIds?: string[]
  entityType: CrossCheckEntity['type']
}) => {
  const {
    data: permissions,
    status: qs,
    fetchStatus: fs,
    refetch,
  } = useQueryPermissions()
  const { data: permissionMap } = useQueryPermissionIdMap()
  const {
    data: clientMap,
    status: clientsQS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      optional={[{ qs: clientsQS, fs: clientsFS }]}
      data={permissions}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view permissions" />
      )}
      renderLoading={() => <Inner crossCheck={crossCheck} entityType={entityType} />}
      renderSuccess={() => (
        <Inner
          crossCheck={crossCheck}
          permissions={permissions}
          permissionMap={permissionMap}
          clientMap={clientMap}
          permissionIds={permissionIds}
          entityType={entityType}
        />
      )}
    />
  )
}

const Inner = (props: {
  permissions?: Permission[]
  permissionMap?: Map<string, Permission>
  crossCheck: CrossCheckDetail
  clientMap?: Map<string, Client>
  permissionIds?: string[]
  entityType: CrossCheckEntity['type']
}) => {
  const {
    permissions,
    crossCheck,
    clientMap = new Map(),
    permissionMap = new Map(),
    permissionIds,
    entityType,
  } = props

  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])
  const { rows, counters } = useMemo(
    () =>
      getPermissionRowsData({
        crossCheck,
        permissionMap,
        clientMap,
        permissionIds,
        tabs,
      }),
    [crossCheck, permissionMap, clientMap, permissionIds, tabs],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState(defaultTab)
  const { openPermissionSide } = usePermissionPreview()
  const onClick = useCallback(
    (row: PermissionRow) => {
      openPermissionSide(row.id)
    },
    [openPermissionSide],
  )
  const data = rows[tab]
  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Permissions</Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            tabs={tabs}
            currentTab={tab}
            counters={counters}
            onChange={setTab}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        loadingState={permissions ? 'ready' : 'pending'}
        getRowState={getRowState}
        labelEmptyState={getEmptyPermissionsLabel(tab, entityType)}
        onRowClick={onClick}
      />
    </Box>
  )
}
