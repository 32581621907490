import { textChain } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckExecutableName } from 'api/types/crossCheck'
import { isRecertification } from 'view/CrossChecks/CrossCheckList/utils'
import { CrossCheckValueTab } from '../../types'
import { isFinished as getIsFinished } from '../../../CrossCheckRules/utils'

export const CREATE_NAMES: CrossCheckExecutableName[] = [
  'RoleCreate',
  'ClientCreate',
  'CreatePolicy',
  'ServiceAccountCreate',
]
export const DELETE_NAMES: CrossCheckExecutableName[] = [
  'DeletePolicy',
  'ClientDelete',
  'ServiceAccountDelete',
]
export const UNCHANGED_DETAILS: CrossCheckExecutableName[] = [
  'RoleAssign',
  'ServiceAccountActivate',
  'ServiceAccountTerminate',
  'PermissionMatrixSynchronizer',
]
export const UNCHANGED_ATTRIBUTES: CrossCheckExecutableName[] = [
  'ServiceAccountActivate',
  'ServiceAccountTerminate',
  'PermissionMatrixSynchronizer',
]

export const ACCESS_MODIFIERS = [
  'DartEmployeeAddAccessModifiers',
  'DartEmployeeDeleteAccessModifiers',
  'DartEmployeeCompanyAddAccessModifiers',
  'DartEmployeeCompanyDeleteAccessModifiers',
]

export const isComparable = (crossCheck: CrossCheckDetail) => {
  return (
    !CREATE_NAMES.includes(crossCheck.executableName) &&
    !DELETE_NAMES.includes(crossCheck.executableName)
  )
}

export const getDefaultTab = (
  tabs: CrossCheckValueTab[],
  counters: Partial<Record<CrossCheckValueTab, number>>,
) => {
  return tabs.find((tabName) => counters[tabName]) || tabs[0] || 'requested'
}

export const getTabTitle = (params: {
  tab: CrossCheckValueTab
  count?: number
  isFinished: boolean
}) => {
  const { tab, count, isFinished } = params
  switch (tab) {
    case 'current':
      return textChain('Current', count)
    case 'forecasted':
      return textChain('Final', count)
    case 'requested':
    default:
      return textChain(isFinished ? 'Requested' : 'Changes', count)
  }
}

type SwitchTabParent = 'details' | 'attributes'
export const getSwitcherTabs = (
  crossCheck: CrossCheckDetail,
  parent: SwitchTabParent,
): CrossCheckValueTab[] => {
  if (UNCHANGED_DETAILS.includes(crossCheck.executableName) && parent === 'details') {
    return ['current']
  }

  if (
    UNCHANGED_ATTRIBUTES.includes(crossCheck.executableName) &&
    parent === 'attributes'
  ) {
    return ['current']
  }

  if (isRecertification(crossCheck.executableName)) {
    return ['current']
  }

  if (CREATE_NAMES.includes(crossCheck.executableName)) {
    return ['requested']
  }

  if (DELETE_NAMES.includes(crossCheck.executableName)) {
    return ['current']
  }

  if (ACCESS_MODIFIERS.includes(crossCheck.executableName) && parent === 'details') {
    return ['current']
  }

  return ['requested', getIsFinished(crossCheck) ? 'current' : 'forecasted']
}
