import { MoreBar } from '@revolut/ui-kit'
import { Client, ClientCustomization } from 'api/idave/clients'
import { useClientActions } from './hooks/useClientActions'

export const ClientActions = (props: {
  client: Client
  customization?: ClientCustomization
}) => {
  const { client, customization } = props
  const actions = useClientActions({
    client,
    customization,
  })

  if (!actions.length) {
    return null
  }

  return (
    <MoreBar>
      {actions.map((action) => (
        <MoreBar.Action
          useIcon={action.useIcon}
          onClick={action.onClick}
          variant={action.variant}
          key={action.label}
        >
          {action.label}
        </MoreBar.Action>
      ))}
    </MoreBar>
  )
}
