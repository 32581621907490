import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { EntitySkeleton } from 'components/EntitySkeleton'
import { RoleTab } from '../Role'

export const RoleSkeleton = (props: { tab: RoleTab }) => {
  const variant = props.tab === 'details' ? 'details' : 'table'
  const { hasSomePermissions } = usePermissions()
  const hasActions = hasSomePermissions(
    IDAVE_PERMISSIONS.ROLES_DELETE,
    IDAVE_PERMISSIONS.ROLES_UPDATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_ROLE_UPDATE_ACTION,
  )
  return <EntitySkeleton variant={variant} hasActions={hasActions} />
}
