import { Check } from '@revolut/icons'
import { Spinner, Toast } from '@revolut/ui-kit'
import { useToast } from '@revolut/ui-kit'
import { ReactNode } from 'react'

export type AppearanceTypes = 'error' | 'info' | 'success' | 'warning'

export interface Options {
  appearance?: AppearanceTypes
  autoDismiss?: boolean
  id?: string
  onDismiss?: (id: string) => void
  [key: string]: any
}

const SuccessToast = (props: { message: ReactNode }) => (
  <Toast>
    <Toast.Indicator>
      <Check />
    </Toast.Indicator>
    <Toast.Label>{props.message}</Toast.Label>
  </Toast>
)

const LoadingToast = (props: { message: ReactNode }) => (
  <Toast>
    <Toast.Indicator>
      <Spinner size={20} />
    </Toast.Indicator>
    <Toast.Label>{props.message}</Toast.Label>
  </Toast>
)

export const useToasts = () => {
  const { show, hide } = useToast()

  const showSuccessToast = (message: ReactNode) => {
    return show(<SuccessToast message={message} />, 'short')
  }

  const showLoadingToast = (message: ReactNode) => {
    return show(<LoadingToast message={message} />)
  }

  return {
    showSuccessToast,
    showLoadingToast,
    hideToast: hide,
  }
}
