import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEditPopup } from 'hooks/useEditPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'hooks/useToasts'
import { getCrossCheckApi } from 'view/CrossChecks/utils'
import { AssignParams } from './types'

export const useAssign = (params: {
  source: CrossCheckSystem
  crossCheck?: CrossCheckDetail
}) => {
  const { source, crossCheck } = params
  const { closePopup } = useEditPopup()
  const queryClient = useQueryClient()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()

  const { mutate } = useMutation({
    mutationFn: ({ groupId, reviewers, reason }: AssignParams) => {
      const api = getCrossCheckApi(source)
      return api.addReviewer(groupId, {
        reviewers,
        reason,
      })
    },
    onMutate: () => {
      showLoadingPopup({ title: 'Assigning...' })
    },
    onSuccess: () => {
      hideLoadingPopup()
      showSuccessToast('User assigned')
      queryClient.invalidateQueries([QueryKey.CrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.SamCrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.DartCrossCheckDetails, crossCheck?.id])
      closePopup()
    },
    onError: (err: Error) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Assignment failed', error: err })
    },
  })

  return {
    submit: mutate,
  }
}
