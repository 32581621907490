import { isAllSettled } from 'utils/query'
import {
  useQueryCustomerCompanyMap,
  useQueryEmployeeCompanyMap,
} from 'queries/dart/companies'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useMemo } from 'react'
import { AuditLogsTable } from './AuditLogsTable'

export const AuditLogsDataWrapper = ({ modelId }: { modelId?: string }) => {
  const {
    data: userMap,
    status: usersStatus,
    fetchStatus: usersFetchStatus,
  } = useQueryUserIdMap()

  const {
    data: employeeCompaniesMap,
    status: employeeCompaniesStatus,
    fetchStatus: employeeCompaniesFetchStatus,
  } = useQueryEmployeeCompanyMap()

  const {
    data: customerCompaniesMap,
    status: customerCompaniesStatus,
    fetchStatus: customerCompaniesFetchStatus,
  } = useQueryCustomerCompanyMap()

  const isAllDataSettled = useMemo(
    () =>
      isAllSettled(
        { qs: usersStatus, fs: usersFetchStatus },
        { qs: employeeCompaniesStatus, fs: employeeCompaniesFetchStatus },
        { qs: customerCompaniesStatus, fs: customerCompaniesFetchStatus },
      ),
    [
      usersStatus,
      usersFetchStatus,
      employeeCompaniesStatus,
      employeeCompaniesFetchStatus,

      customerCompaniesStatus,
      customerCompaniesFetchStatus,
    ],
  )

  return (
    <AuditLogsTable
      isAllDataSettled={isAllDataSettled}
      dataMaps={{
        userMap,
        employeeCompaniesMap,
        customerCompaniesMap,
      }}
      modelId={modelId}
    />
  )
}
