import { MoreBar } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { useCallback } from 'react'
import { IDAVE_PERMISSIONS } from 'security'
import { useNavigate } from 'react-router'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'

type Props = {
  permission: Permission
}
export const PermissionActions = ({ permission }: Props) => {
  const navigate = useNavigate()
  const onEditClick = useCallback(
    () => navigate(generatePath(Url.PermissionEdit, { permissionId: permission.id })),
    [navigate, permission.id],
  )

  return (
    <PermissionsCheck
      somePermissions={[IDAVE_PERMISSIONS.PERMISSIONS_UPDATE_DESCRIPTION]}
    >
      <MoreBar>
        <PermissionsCheck permission={IDAVE_PERMISSIONS.PERMISSIONS_UPDATE_DESCRIPTION}>
          <MoreBar.Action useIcon="Pencil" onClick={onEditClick}>
            Edit
          </MoreBar.Action>
        </PermissionsCheck>
      </MoreBar>
    </PermissionsCheck>
  )
}
