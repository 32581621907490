import { AvatarSkeleton, HStack, Table, TextSkeleton } from '@revolut/ui-kit'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { useQueryMappedAvatars } from 'queries/sam/users'
import { useQueryUserIdMap } from 'queries/idave/users'
import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { useCallback } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { UserLinkView } from './Links/common/UserLinkView'
import { QuerySwitch } from './QuerySwitch'

type UserTableCellProps = {
  userId: string
}

export const useUserTableCellAccessor = () => {
  const { data: userMap = new Map() } = useQueryUserIdMap()
  return (userId: string) => {
    const user = userMap.get(userId)
    return user ? getName(user) : userId
  }
}

export const UserTableCell = (props: UserTableCellProps) => {
  const { userId } = props
  const { data: avatarMap = new Map() } = useQueryMappedAvatars()
  const { data: userMap = new Map(), fetchStatus: fs, status: qs } = useQueryUserIdMap()

  const user = userMap.get(userId)
  const UserComponent = useCallback(
    () => <Inner userId={userId} avatars={avatarMap} user={user} />,
    [userId, avatarMap, user],
  )

  return (
    <Table.Cell>
      <QuerySwitch
        data
        required={[{ fs, qs }]}
        renderError={UserComponent}
        renderIdle={UserComponent}
        renderLoading={() => (
          <HStack space="s-8">
            <AvatarSkeleton size={24} />
            <TextSkeleton size={8} />
          </HStack>
        )}
        renderSuccess={UserComponent}
      />
    </Table.Cell>
  )
}

const Inner = (props: {
  user?: UserListItem
  avatars: Map<string, RevolutersAvatar>
  userId: string
}) => {
  const { user, avatars, userId } = props
  const avatarUrl = getAvatarUrl({ user, avatars })
  const name = user ? getName(user) : userId
  const state = user?.state

  return (
    <UserLinkView
      href={generatePath(Url.User, { userId })}
      id={userId}
      name={name}
      avatarUrl={avatarUrl}
      state={state}
    />
  )
}
