import { MoreBar } from '@revolut/ui-kit'
import { useCallback } from 'react'
import idaveApi from 'api/idave'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CreateUserPayload } from 'api/idave/user'
import { QueryKey } from 'helpers/configQuery'
import { ActionResponse } from 'api/types/actionResponse'
import { useEditPopup } from 'hooks/useEditPopup'
import { useToasts } from 'hooks/useToasts'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { NewUserForm } from './NewUserForm'

export const NewUser = () => {
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { setPopup, closePopup } = useEditPopup()
  const { showErrorPopup } = useErrorPopup()
  const { hideLoadingPopup, showLoadingPopup } = useLoadingPopup()
  const { mutate: create } = useMutation({
    mutationFn: (user: CreateUserPayload) => idaveApi.user.createUser(user),
    onMutate: () => {
      showLoadingPopup({ title: 'Adding user..' })
    },
    onSuccess: () => {
      hideLoadingPopup()
      showSuccessToast('User Added')
      closePopup()
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Users],
      })
    },
    onError: (error: AxiosError<ActionResponse>) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'User addition failed', error })
    },
  })

  const submit = useCallback((user: CreateUserPayload) => create(user), [create])
  const openEditPopup = useCallback(
    () =>
      setPopup({
        title: 'Create a new User',
        content: <NewUserForm submit={submit} />,
      }),
    [setPopup, submit],
  )

  return (
    <MoreBar.Action useIcon="Plus" onClick={openEditPopup}>
      New user
    </MoreBar.Action>
  )
}
