import { App } from 'App'
import { createBrowserRouter } from 'react-router-dom'
import { baseRoute, ErrorElement } from 'routing'

export const getRouter = () => {
  return createBrowserRouter([
    {
      path: baseRoute(),
      index: true,
      element: <App />,
      errorElement: (
        <div style={{ margin: '32px' }}>
          <ErrorElement />
        </div>
      ),
    },
  ])
}
