import { Tag } from '@revolut/ui-kit'
import { User } from 'api/idave/user'
import { capitalizeFirst } from 'utils/string'

export const ActivityBadge = (props: { state: User['state'] }) => {
  const color = props.state === 'ACTIVE' ? 'green' : 'red'
  return (
    <Tag variant="outlined" color={color}>
      {props?.state ? capitalizeFirst(props?.state) : 'Unknown'}
    </Tag>
  )
}
