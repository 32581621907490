import { captureException } from '@sentry/browser'
import { Handler, TextPart } from './types'

export const ACTION_TAG = '%action%'

type GetTextParts = {
  separator: string
  text: string
  clickHandlers: Handler[]
}

/**
 * Splits tagged text into tagged parts (action and common text) e.g
 *
 * "Some %action%action%action% text" turns into
 * [CommonText("Some "), ActionText("action", handler), CommonText(" text")]
 *
 */
export const getTextParts = ({ clickHandlers, separator, text }: GetTextParts) => {
  const splits = text.split(separator)

  return splits
    .reduce<TextPart[]>((acc, split, idx) => {
      const part = getTextPart(split, idx, clickHandlers, text)

      return acc.concat(part)
    }, [])
    .filter(({ value }) => value)
}

const exceptionHandler = (text: string) => () => {
  const err = new Error(`Action handler not found for text: ${text}`)

  captureException(err)
}

const getHandler = (handlers: Handler[], idx: number, text: string) => {
  const handlerIdx = (idx - 1) / 2
  return handlers[handlerIdx] || exceptionHandler(text)
}

/**
 * Returns TextPart based on assumption that indices of actions is even and common text's is odd
 */
const getTextPart = (
  split: string,
  idx: number,
  handlers: Handler[],
  originalText: string,
): TextPart => {
  const isEven = idx % 2 === 0

  return isEven
    ? {
        type: 'text',
        value: split,
      }
    : {
        type: 'actionText',
        value: split,
        onClick: getHandler(handlers, idx, originalText),
      }
}
