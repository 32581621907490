import axios from 'axios'

export type SamTaskPayload = {
  taskName: string
  args: string[]
}

export const tasks = {
  sendTask: (payload: SamTaskPayload) => axios.post<void>('/sam-api/api/tasks/', payload),
}
