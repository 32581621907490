import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { Optioned } from '../types'
import { isEnabled } from '../utils'

export const useQueryCrossChecksList = (
  params: {
    reviewer?: string
    requester?: string
  } = {},
) => {
  const { reviewer, requester } = params
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.CrossChecksList, requester, reviewer],
    queryFn: () => idaveApi.crossChecks.getCrossChecksList({ reviewer, requester }),
    enabled: hasPermission(IDAVE_PERMISSIONS.CROSS_CHECKS_VIEW_LIST),
    select: (response) => response.data,
  })
}

export const useQueryCrossCheckDetails = ({ params: id, options }: Optioned<string>) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.CrossCheckDetails, id],
    queryFn: () => idaveApi.crossChecks.getCrossCheckDetails(id),
    select: (response) => response.data,
    enabled: isEnabled(
      hasPermission(IDAVE_PERMISSIONS.CROSS_CHECKS_VIEW_DETAILS),
      options,
    ),
  })
}
