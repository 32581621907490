import { Box, Subheader, Table, TableColumn, VStack } from '@revolut/ui-kit'
import { EntityDescription } from 'components/EntityDescription'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { COLUMNS as ROLE_COLUMNS } from './Policies/components/RolePolicies/columns'
import { COLUMNS as USER_COLUMNS } from './Policies/components/UserPolicies/columns'

export const Skeleton = (props: { userId?: string }) => {
  const columns = (props.userId ? USER_COLUMNS : ROLE_COLUMNS) as TableColumn<any>[]
  return (
    <VStack space="s-16">
      <EntityDescription title="Description" isLoading />
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>

        <DetailsGroupSkeleton />
      </Box>

      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Permissions</Subheader.Title>
        </Subheader>
        <Table columns={columns} data={[]} loadingState="pending" />
      </Box>
    </VStack>
  )
}
