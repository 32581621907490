import { useQueryTeamMap } from 'queries/sam/revoluters'
import { Group } from 'api/idave/groups'
import { mergeQueryStatuses } from 'utils/query'
import { useQueryGroup } from 'queries/idave/groups'
import { QuerySwitch } from 'components/QuerySwitch'
import { ActionButtonSkeleton } from '@revolut/ui-kit'
import { ActionBar } from './components/ActionBar'

export const Actions = ({ groupId }: { groupId: string }) => {
  const { data, fetchStatus: fs, status: qs } = useQueryGroup(groupId)

  return (
    <QuerySwitch
      data={data}
      required={[{ fs, qs }]}
      renderIdle={() => null}
      renderError={() => null}
      renderLoading={() => <ActionButtonSkeleton />}
      renderSuccess={({ data: group }) => <Inner group={group} />}
    />
  )
}

const Inner = ({ group }: { group: Group }) => {
  const teamId =
    group.type === 'peopleops:team' ? group.externalRef?.reference : undefined
  const { data: teamMap, fetchStatus: fs, status: qs } = useQueryTeamMap()

  if (!teamId) {
    return null
  }

  return (
    <ActionBar
      teamId={teamId}
      isLoading={mergeQueryStatuses({ qs, fs }) === 'loading'}
      teams={teamMap}
    />
  )
}
