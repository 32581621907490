import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'
import { getName } from 'utils/string/getName'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { Row } from './types'

export const getRows = ({
  userIds,
  userMap,
  avatars,
}: {
  userIds?: string[]
  userMap: Map<string, UserListItem>
  avatars?: Map<string, RevolutersAvatar>
}): Row[] => {
  if (!userIds) {
    return []
  }

  return userIds.map((id) => {
    const user = userMap.get(id)

    return {
      id,
      name: user ? getName(user) : id,
      email: user?.email || EMPTY_VALUE_FALLBACK,
      avatarUrl: getAvatarUrl({ user, avatars }),
      state: user?.state,
    }
  })
}
