import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { useCallback, useState } from 'react'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { SamPolicyTrainings as SamPolicyTrainingsCommon } from 'view/Sam/components/SamPolicyTrainings'
import { EDIT_TRAININGS } from 'view/Sam/permissions'
import { randomKey } from 'utils/common/randomKey'
import { getPopupText } from './utils'
import { MandatoryTrainings } from './type'
import { useSetPolicyTrainings } from './useSetPolicyTrainings'

type SamPolicyTrainingProps = {
  id: string
  isActive: boolean
}

export const SamPolicyTrainings = ({ id, isActive }: SamPolicyTrainingProps) => {
  const { data: policy, status: policyStatus } = useQuerySamPolicy(id)
  const { hasPermission, hasSomePermissions } = usePermissions()

  const [resetKey, setResetKey] = useState(randomKey())
  const { setPopup, closePopup } = useConfirmationPopup()

  const updateKey = useCallback(() => setResetKey(randomKey()), [setResetKey])

  const onSuccess = useCallback(() => {
    updateKey()
    closePopup()
  }, [updateKey, closePopup])

  const { isSetting, setTrainings } = useSetPolicyTrainings({
    onSuccess,
    policy,
  })

  const updateTrainings = useCallback(
    (mandatoryTrainings: MandatoryTrainings) => {
      const currentCount = policy?.mandatoryTrainings?.length || 0
      const newCount = mandatoryTrainings?.length || 0
      const changedCount = Math.abs(currentCount - newCount)
      const type = currentCount < newCount ? 'add' : 'delete'

      if (type === 'add') {
        setTrainings({
          mandatoryTrainings,
          type: 'add',
          changedCount,
        })
      } else {
        setPopup({
          ...getPopupText(changedCount),
          onConfirmClick: () => {
            setTrainings({
              mandatoryTrainings,
              type: 'delete',
              changedCount,
            })
          },
        })
      }
    },
    [policy?.mandatoryTrainings, setTrainings, setPopup],
  )

  return (
    <SamPolicyTrainingsCommon
      key={resetKey}
      policyTrainigns={policy?.mandatoryTrainings || []}
      policyStatus={policyStatus}
      updateTrainings={updateTrainings}
      isActive={isActive}
      isPending={isSetting}
      policyName={policy?.policyName || id}
      showAddPermissions={
        hasSomePermissions(...EDIT_TRAININGS) &&
        hasPermission(SAM_PERMISSIONS.RESOURCES_VIEW_LIST)
      }
      showDeletePermissions={hasSomePermissions(...EDIT_TRAININGS)}
    />
  )
}
