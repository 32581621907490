import { IconButton, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

export const HeaderActionButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: ${Token.transition.background};
  width: 36px;
  height: 36px;

  color: ${Token.color.greyTone50};

  :hover {
    background-color: ${Token.color.greyTone10};
  }

  :active {
    color: ${Token.color.foreground};
  }
`
