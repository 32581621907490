import { Skeleton, VStack } from '@revolut/ui-kit'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

export const SamPolicyEditSkeleton = () => {
  return (
    <VStack space="s-24" maxWidth={MAXIMAL_DETAILS_WIDTH} pt="s-24">
      <Skeleton height="56px" />
      <Skeleton height="56px" />
      <Skeleton height="56px" />
      <Skeleton height="56px" />
      <Skeleton height="56px" />
    </VStack>
  )
}
