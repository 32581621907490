import { MoreBar } from '@revolut/ui-kit'
import { getRevolutersTeamLink } from 'components/Links/RevolutersTeamLink'
import { RevolutersTeam } from 'api/sam/revoluters'

export const ActionBar = (props: {
  isLoading?: boolean
  teamId?: string
  deprecatedTeamId?: string
  teams?: Map<string, RevolutersTeam>
}) => {
  const { isLoading, teamId, teams } = props

  return (
    <MoreBar>
      {teamId && (
        <MoreBar.Action
          use="a"
          target="_blank"
          href={getRevolutersTeamLink(teamId, 'talent')}
          useIcon="LinkExternal"
          pending={isLoading}
        >
          {teams?.get(teamId)?.name || teamId}
        </MoreBar.Action>
      )}
    </MoreBar>
  )
}
