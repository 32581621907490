import { toPresenceMap } from 'utils/array/toPresenceMap'
import { TableFilters, TableSortingRule } from '@revolut/ui-kit'
import { UseTableNavigationParams } from './types'
import { DESCENDING_SORT, FILTER_PREFIX, SORT_PREFIX } from './const'

export const extractSearchParamFilters = <Entity extends {}>(
  queryParams: URLSearchParams,
  table?: UseTableNavigationParams<Entity>['table'],
) => {
  const columnIds = table?.columns.map((column) => column.id) || []
  const columnIdsPresence = toPresenceMap(columnIds)

  const filters: TableFilters<Entity> = []

  queryParams.forEach((rawValue, key) => {
    const id = decodeFilterParamName(key)
    if (!id) {
      return
    }

    if (!columnIdsPresence[id]) {
      return
    }

    const value = decodeURIComponent(rawValue).split(',')
    filters.push({
      id,
      value,
    })
  })

  return filters
}

const decodeFilterParamName = (paramName: string) => {
  if (paramName.startsWith(FILTER_PREFIX)) {
    return paramName.replace(FILTER_PREFIX, '')
  }

  return undefined
}

const decodeSortingParamName = (paramName: string) => {
  if (paramName.startsWith(SORT_PREFIX)) {
    return paramName.replace(SORT_PREFIX, '')
  }

  return undefined
}

export const extractSortsParamFilters = <Entity extends {}>(
  queryParams: URLSearchParams,
  table?: UseTableNavigationParams<Entity>['table'],
) => {
  const columnIds =
    table?.columns.filter((column) => column.canSort).map((column) => column.id) || []
  const columnIdsPresence = toPresenceMap(columnIds)

  const sorts: TableSortingRule<Entity>[] = []

  queryParams.forEach((rawValue, key) => {
    const id = decodeSortingParamName(key)
    if (!id) {
      return
    }

    if (!columnIdsPresence[id]) {
      return
    }

    const value = decodeURIComponent(rawValue)
    sorts.push({
      id,
      desc: value === DESCENDING_SORT,
    })
  })

  return sorts
}
