import { ActionButton, Box, Group, Item, Subheader } from '@revolut/ui-kit'
import idaveApi from 'api/idave'
import { Client } from 'api/idave/clients'
import { DeprecatedRole } from 'api/idave/roles'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useToasts } from 'hooks/useToasts'
import { useQueryDeprecatedRoles } from 'queries/idave/roles'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'

export const ClientDeprecatedRoles = (props: { client: Client }) => {
  const { client } = props
  const { data: roles = {} } = useQueryDeprecatedRoles()
  const clientRoles = roles[client.name]

  return clientRoles?.length ? <Inner client={client} clientRoles={clientRoles} /> : null
}

const Inner = (props: { client: Client; clientRoles: DeprecatedRole[] }) => {
  const { clientRoles } = props
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { closePopup, setPopup } = useConfirmationPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { mutate: removeRole } = useMutation({
    mutationFn: idaveApi.roles.removeRole,
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      showSuccessToast('Role deleted')
      queryClient.invalidateQueries([QueryKey.Clients])
    },
    onError: (err) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({ title: 'Deletion failed', error: err })
    },
  })

  const onDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
      event.stopPropagation()
      setPopup({
        title: 'Role deleting confirmation',
        message: 'Are you sure you want to delete the role?',
        onConfirmClick: () => {
          removeRole(id)
        },
      })
    },
    [setPopup, removeRole],
  )

  return (
    <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
      <Subheader variant="nested">
        <Subheader.Title>Deprecated roles</Subheader.Title>
      </Subheader>

      <Group>
        {clientRoles.map((item) => (
          <Item
            key={item.roleId}
            href={generatePath(Url.Role, { roleId: item.roleId })}
            target="_blank"
            use="a"
          >
            <Item.Content>
              <Item.Title>{item.role}</Item.Title>
            </Item.Content>

            <PermissionsCheck permission={IDAVE_PERMISSIONS.ROLES_DELETE}>
              <Item.Side>
                <ActionButton
                  useIcon="Delete"
                  onClick={(e) => onDeleteClick(e, item.roleId)}
                  label="delete"
                />
              </Item.Side>
            </PermissionsCheck>
          </Item>
        ))}
      </Group>
    </Box>
  )
}
