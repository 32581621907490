import { LogAuthor } from 'components/LogAuthor'
import { VStack } from '@revolut/ui-kit'
import { LogInfo } from 'components/previews/AuditLogPreview/components/Body/components/LogInfo'
import { AuditLogData } from 'api/dart/auditLog'

import { getAuthor } from 'components/previews/AuditLogPreview/utils'
import { AuditLogAccessModifier } from './components/AuditLogAccessModifier'
import { AuditLogAccessModifiers } from './components/AuditLogAccessModifiers'
import { AuditLogDetails } from './components/AuditLogDetails'

export const AuditLogSide = ({ logItem }: { logItem: AuditLogData }) => (
  <VStack space="s-16">
    <AuditLogDetails log={logItem} />
    <AuditLogAccessModifier log={logItem} />
    <AuditLogAccessModifiers log={logItem} />
    <LogAuthor {...getAuthor(logItem.Author)} />
    <LogInfo log={logItem} />
  </VStack>
)
