import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'

export const getUserInfo = (
  userIds: string[],
  userMap: Map<string, UserListItem>,
  avatars?: Map<string, RevolutersAvatar>,
) =>
  userIds.map((id) => {
    const user = userMap.get(id)
    return {
      name: user ? getName(user) : id,
      email: user?.email,
      photoUrl: getAvatarUrl({ user, avatars }),
      id,
    }
  })
