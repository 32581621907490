import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import useLazyQuery from 'queries/useLazyQuery'
import { makeMappedKeyDataHook } from 'queries/utils'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'

export const useQueryAccounts = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Accounts],
    queryFn: idaveApi.accounts.getAccounts,
    enabled: hasPermission(IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_VIEW_LIST),
    select: (response) => response?.data,
  })
}
export const useQueryAccountMap = makeMappedKeyDataHook(useQueryAccounts, 'id')

export const useQueryAccount = (params: { accountId?: string }) => {
  const { hasPermission } = usePermissions()
  return useLazyQuery({
    params: params.accountId,
    queryKey: [QueryKey.Account, params.accountId],
    enabled: hasPermission(IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_VIEW_DETAILS),
    fetchFn: idaveApi.accounts.getAccount,
  })
}

export const useQueryAccountCustomization = (id?: string) => {
  const { hasPermission } = usePermissions()
  return useLazyQuery({
    params: id,
    queryKey: [QueryKey.AccountCustomization, id],
    fetchFn: idaveApi.accounts.getAccountCustomization,
    enabled: hasPermission(
      IDAVE_PERMISSIONS.CUSTOMIZATIONS_VIEW_SERVICE_ACCOUNTS_DETAILS,
    ),
  })
}

export const useQueryAccountsCustomization = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.AccountsCustomization],
    queryFn: idaveApi.accounts.getAccountsCustomization,
    enabled: hasPermission(IDAVE_PERMISSIONS.CUSTOMIZATIONS_VIEW_SERVICE_ACCOUNTS_LIST),
    select: (response) => response?.data,
  })
}
