import { useInfiniteQuery } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { UseAuditLogsParams } from 'queries/idave/logs'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getLogsNextPageParam } from '../utils'

export const useQueryDartAuditLogs = (params?: UseAuditLogsParams) => {
  const { hasPermission } = usePermissions()
  return useInfiniteQuery(
    [QueryKey.DartAuditLogs, params?.modelId, params?.limits?.from, params?.limits?.to],
    ({ pageParam }) => {
      const to = pageParam?.to || params?.limits?.to || undefined
      const modelId = params?.modelId
      return dartApi.auditLog.getLogs({ modelId, to })
    },
    {
      enabled: hasPermission(DART_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
      getNextPageParam: getLogsNextPageParam(params?.limits?.from),
    },
  )
}
