import { CrossCheckDetail } from 'api/types/crossCheck'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { EmployeeCompany } from 'api/dart/employeeCompanies'
import { getRows } from 'view/Dart/EmployeeCompany/components/CustomerCompanies/components/CustomerCompanyList/utils'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { capitalizeFirst } from 'utils/string'
import { CrossCheckValueTab } from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/types'
import { notNullable } from 'utils/common'
import { getChangeRowsInfo } from '../../../../utils'
import { CustomerCompanyRow } from './columns'
import { getAccessModifiersInfo } from '../../utils'

type GetAccessModifierRowsParams = {
  crossCheck: CrossCheckDetail
  entity?: EmployeeCompany
  customerCompaniesMap: Map<string, CustomerCompanyItem>
  tabs: CrossCheckValueTab[]
}

export const getAccessModifierRows = ({
  crossCheck,
  entity,
  customerCompaniesMap,
  tabs,
}: GetAccessModifierRowsParams) => {
  const { accessModifiers, actionType } = getAccessModifiersInfo(crossCheck)

  const ids = accessModifiers
    .map((v) => v.accessModifier.entityId?.id)
    .filter(notNullable)

  const rows = getRows(entity, Array.from(customerCompaniesMap.values()))

  const added = actionType === 'added' ? ids : []
  const deleted = actionType === 'removed' ? ids : []

  const values: string[] = []
  const current = rows.entities.filter((v) => v.accessModifierId).map((v) => v.id)

  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
    customerCompaniesMap,
  })
}

const makeMapValue =
  ({
    customerCompaniesMap,
    deletedHash = {},
    addedHash = {},
  }: {
    customerCompaniesMap: Map<string, CustomerCompanyItem>
    deletedHash?: Record<string, boolean>
    addedHash?: Record<string, boolean>
  }) =>
  (customerCompanyId: string): CustomerCompanyRow => {
    const customerCompany = customerCompaniesMap.get(customerCompanyId)

    const removedStatus = deletedHash[customerCompanyId] ? 'removed' : undefined
    const addedStatus = addedHash[customerCompanyId] ? 'added' : undefined

    return {
      id: customerCompanyId,
      name: customerCompany?.name || customerCompanyId,
      region: customerCompany?.region,
      serviceType: customerCompany
        ? capitalizeFirst(customerCompany?.serviceType)
        : undefined,
      status: addedStatus || removedStatus,
    }
  }

export const getEmptyTableLabel = (status: CrossCheckValueTab) =>
  status === 'requested'
    ? 'No added or deleted customer companies'
    : 'No customer company has access to the employee company'
