import { CrossCheckExecutableName } from 'api/types/crossCheck'
import { CrossCheckValueTab } from '../../../types'
import { DetailRow } from '../types'
import { isRowHidden } from './isRowHidden'

type IsRowHiddenParams = {
  rows: DetailRow[]
  tab: CrossCheckValueTab
  isFinished: boolean
  executableName: CrossCheckExecutableName
}
export const showRequestPlaceholder = ({
  rows,
  tab,
  isFinished,
  executableName,
}: IsRowHiddenParams) => {
  return (
    rows.every((row) => isRowHidden({ row, tab, isFinished, executableName })) &&
    tab === 'requested'
  )
}
