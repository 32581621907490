import { HStack, ProgressStep } from '@revolut/ui-kit'
import { UserListItem } from 'api/idave/user'
import {
  CrossCheckDetail,
  CrossCheckGroup as CrossCheckGroupType,
  CrossCheckSystem,
} from 'api/types/crossCheck'
import { useSideBox } from 'view/SideBox/SideBox'
import { useCallback, useMemo } from 'react'
import { useQueryEmployeePeopleOpsMap } from 'queries/sam/users'
import { useQueryTeamMap } from 'queries/sam/revoluters'
import { ActionTemplatedText } from 'components/ActionTemplatedText'
import { getGroupState } from './utils'
import { CrossCheckGroupReviewers } from '../CrossCheckGroupReviewers'
import { CrossCheckReviewersSide } from './CrossCheckReviewersSide'
import { AssignAction } from '../AssignAction'

export const CrossCheckGroup = (props: {
  userMap: Map<string, UserListItem>
  crossCheck: CrossCheckDetail
  group: CrossCheckGroupType
  source: CrossCheckSystem
}) => {
  const { userMap, crossCheck, group, source } = props
  const { state, stateColor, text } = getGroupState(group, crossCheck)
  const { data: employeeMap = new Map() } = useQueryEmployeePeopleOpsMap()
  const { data: teamMap = new Map() } = useQueryTeamMap()

  const { openSide } = useSideBox()
  const openGroupView = useCallback(
    () =>
      openSide({
        title: group.name,
        body: (
          <CrossCheckReviewersSide
            userMap={userMap}
            employeeMap={employeeMap}
            group={group}
            crossCheck={crossCheck}
            teamMap={teamMap}
          />
        ),
      }),
    [userMap, employeeMap, crossCheck, teamMap, group, openSide],
  )

  const handlers = useMemo(() => [openGroupView], [openGroupView])

  return (
    <ProgressStep state={state} indicatorColor={stateColor}>
      <ProgressStep.Title>{group.name}</ProgressStep.Title>
      <ProgressStep.Description>
        {text && <ActionTemplatedText text={text} clickHandlers={handlers} />}
      </ProgressStep.Description>

      <ProgressStep.Side>
        <HStack gap="s-12">
          <CrossCheckGroupReviewers
            userMap={userMap}
            group={group}
            onClick={openGroupView}
          />
          <AssignAction crossCheck={crossCheck} source={source} groupId={group.groupId} />
        </HStack>
      </ProgressStep.Side>
    </ProgressStep>
  )
}
