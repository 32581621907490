import { EntitySkeleton } from 'components/EntitySkeleton'
import { Box, TextSkeleton } from '@revolut/ui-kit'

export const EmployeeCompanySkeleton = () => (
  <Box mt="s-24">
    <EntitySkeleton
      variant="table"
      hasActions={false}
      title={
        <Box ml="s-16">
          <TextSkeleton size={16} />
        </Box>
      }
    />
  </Box>
)
