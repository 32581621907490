import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'

export const useEditGroup = (groupId: string, onSuccess?: () => void) => {
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()

  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: idaveApi.groups.editGroup,
    onMutate: () => showLoadingPopup({ title: 'Updating...' }),
    onSuccess: () => {
      hideLoadingPopup()
      queryClient.resetQueries({ queryKey: [QueryKey.Group, groupId] })
      showSuccessToast('Group updated')
      onSuccess?.()
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Failed to update the group',
        error: err,
      })
    },
  })

  return mutate
}
