import { useMemo, FC } from 'react'
import { TextSkeleton } from '@revolut/ui-kit'
import { useQueryCustomerCompanies } from 'queries/dart/companies'
import { QuerySwitch } from 'components/QuerySwitch'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { RegionText } from 'components/RegionText'
import { InternalLink } from './InternalLink'

const FallbackLink: FC<{ id: string; children?: React.ReactNode }> = ({
  id,
  children,
}) => (
  <InternalLink href={generatePath(Url.DartCustomerCompany, { companyId: id })}>
    {children || id}
  </InternalLink>
)

export const CustomerCompanyLink = ({ id }: { id: string }) => {
  const {
    data: customerCompanies = [],
    status,
    fetchStatus,
  } = useQueryCustomerCompanies()

  const customerCompany = useMemo(
    () => customerCompanies.find((item) => item.id === id),
    [customerCompanies, id],
  )

  return (
    <QuerySwitch
      data={customerCompany}
      required={[{ qs: status, fs: fetchStatus }]}
      renderLoading={() => <TextSkeleton size={14} />}
      renderError={() => <FallbackLink id={id} />}
      renderIdle={() => <FallbackLink id={id} />}
      renderSuccess={({ data }) => (
        <InternalLink
          href={generatePath(Url.DartCustomerCompany, { companyId: data.id })}
        >
          <RegionText id={data.id} title={data.name} region={data.region} />
        </InternalLink>
      )}
    />
  )
}
