import { Box, Button, Page, VStack } from '@revolut/ui-kit'
import { useCallback, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { FormProvider, useForm } from 'react-hook-form'
import { useHeaderActions } from 'components/HeaderActions'
import { ToxicPairPayload } from 'api/idave/toxic'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { QueryKey } from 'helpers/configQuery'
import { FieldInput } from 'components/fields/FieldInput'
import { FieldTextArea } from 'components/fields/FieldTextArea'
import { FieldSelectUser } from 'components/fields/FieldSelectUser'
import { RULE_REQUIRED } from 'utils/form/rules'
import { FieldPermissionSelect } from 'components/fields/FieldPermissionSelect/FieldPermissionSelect'
import { useCurrentUserId } from 'queries/idave/users'
import { QuerySwitch } from 'components/QuerySwitch'
import { ToxicPairCreateSkeleton } from './ToxicPairCreateSkeleton'

export const ToxicPairCreate = () => {
  const { data, fetchStatus: fs, status: qs } = useCurrentUserId()

  return (
    <QuerySwitch
      required={[{ fs, qs }]}
      data={data}
      renderLoading={() => <ToxicPairCreateSkeleton />}
      renderError={() => <Inner />}
      renderIdle={() => <Inner />}
      renderSuccess={({ data: currentUserId }) => <Inner userId={currentUserId} />}
    />
  )
}

const Inner = ({ userId }: { userId?: string }) => {
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const queryClient = useQueryClient()

  const toxicPairForm = useForm<ToxicPairPayload>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: {
      owner: userId,
    },
  })

  const navigate = useNavigate()
  const onBackClick = useCallback(() => navigate(Url.ToxicPairs), [navigate])
  const { mutate, isLoading } = useMutation({
    mutationFn: idaveApi.toxic.addToxicPair,
    onMutate: () => showLoadingPopup({ title: 'Creating...' }),
    onSuccess: ({ data }) => {
      hideLoadingPopup()
      queryClient.resetQueries({
        queryKey: [QueryKey.ToxicPairs],
        type: 'all',
      })
      if (data && 'id' in data) {
        navigate(generatePath(Url.ToxicPair, { toxicPairId: data.id }))
      } else {
        navigate(Url.ToxicPairs)
      }
    },
    onError: (error) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Toxic pair creation failed',
        error,
      })
    },
  })

  const onSubmit = useCallback(() => {
    const payload = toxicPairForm.getValues()
    mutate(payload)
  }, [toxicPairForm, mutate])

  const disabledSubmit = !toxicPairForm.formState.isValid

  const firstIdValue = toxicPairForm.watch('firstId')
  const excludeFirst = useMemo(() => (firstIdValue ? [firstIdValue] : []), [firstIdValue])
  const secondIdValue = toxicPairForm.watch('secondId')
  const excludeSecond = useMemo(
    () => (secondIdValue ? [secondIdValue] : []),
    [secondIdValue],
  )
  const HeaderActions = useHeaderActions()

  return (
    <>
      <Page.Header onBack={onBackClick} actions={HeaderActions && <HeaderActions />}>
        Create toxic pair
      </Page.Header>
      <Page.Main>
        <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
          <VStack space="s-16">
            <FormProvider {...toxicPairForm}>
              <FieldInput
                name="name"
                label="Toxic pair name"
                rules={RULE_REQUIRED}
                disabled={isLoading}
                autoFocus
              />
              <FieldPermissionSelect
                name="firstId"
                label="First permission"
                rules={RULE_REQUIRED}
                excludeIds={excludeSecond}
              />
              <FieldPermissionSelect
                name="secondId"
                label="Second permission"
                rules={RULE_REQUIRED}
                excludeIds={excludeFirst}
              />
              <FieldSelectUser name="owner" label="Owner" />
              <FieldTextArea
                name="justification"
                rows={3}
                rules={RULE_REQUIRED}
                label="Justification (with related ticket mention)..."
              />
            </FormProvider>
          </VStack>
        </Box>
        <Page.MainActions>
          <Button
            id="toxic-new-create"
            variant="primary"
            elevated
            disabled={disabledSubmit}
            onClick={onSubmit}
            pending={isLoading}
          >
            Create
          </Button>
        </Page.MainActions>
      </Page.Main>
    </>
  )
}
