import { Role } from 'api/idave/roles'

/**
 * @returns direct parent(key) - children(values) table
 * getRoleByParentTable(roles) => { parent: [children_1, children_2], children_1: [grand_children]}
 */
export const getRoleByParentTable = (roles: Role[]) => {
  return roles.reduce<{ [id: string]: string[] }>((mutableAcc, item) => {
    if (item.parent) {
      const siblings = (mutableAcc[item.parent] || []).concat(item.id)
      mutableAcc[item.parent] = siblings
    }

    return mutableAcc
  }, {})
}
