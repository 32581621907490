import { CrossCheckDetail } from 'api/types/crossCheck'
import { CustomerCompaniesOfEmployeeCompany } from './components/CustomerCompaniesOfEmployeeCompany'
import { EmployeesOfCustomerCompany } from './components/EmployeesOfCustomerCompany'
import { EmployeeCompaniesOfCustomerCompany } from './components/EmployeeCompaniesOfCustomerCompany'
import { CustomersOfEmployee } from './components/CustomersOfEmployee'
import { CustomerCompaniesOfEmployee } from './components/CustomerCompaniesOfEmployee'
import { getAccessModifiersInfo } from './utils'
import { CrossCheckEntity } from '../../../../types'

export const AccessModifierList = ({
  crossCheck,
  entity,
}: {
  crossCheck: CrossCheckDetail
  entity?: CrossCheckEntity
}) => {
  if (crossCheck.entityType === 'EmployeeCompany' && entity?.type === 'EmployeeCompany') {
    return (
      <CustomerCompaniesOfEmployeeCompany crossCheck={crossCheck} entity={entity.value} />
    )
  }

  if (crossCheck.entityType === 'CustomerCompany' && entity?.type === 'CustomerCompany') {
    if (
      crossCheck.executableName === 'DartEmployeeAddAccessModifiers' ||
      crossCheck.executableName === 'DartEmployeeDeleteAccessModifiers'
    ) {
      return <EmployeesOfCustomerCompany crossCheck={crossCheck} />
    }

    if (
      crossCheck.executableName === 'DartEmployeeCompanyAddAccessModifiers' ||
      crossCheck.executableName === 'DartEmployeeCompanyDeleteAccessModifiers'
    ) {
      return <EmployeeCompaniesOfCustomerCompany crossCheck={crossCheck} />
    }
  }

  if (crossCheck.entityType === 'User') {
    const { firstAccessModifier } = getAccessModifiersInfo(crossCheck)

    if (firstAccessModifier?.accessModifier.entityId?.type === 'CUSTOMER') {
      return <CustomersOfEmployee crossCheck={crossCheck} />
    }

    return <CustomerCompaniesOfEmployee crossCheck={crossCheck} />
  }

  if (crossCheck.entityType === 'Recertification') {
    return (
      <>
        <EmployeesOfCustomerCompany crossCheck={crossCheck} />
        <EmployeeCompaniesOfCustomerCompany crossCheck={crossCheck} />
      </>
    )
  }

  return null
}
