import { IDAVE_PERMISSIONS } from 'security'

export const CREATE = [
  IDAVE_PERMISSIONS.ROLES_CREATE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_ROLE_CREATE_ACTION,
]

export const EDIT = [
  IDAVE_PERMISSIONS.ROLES_UPDATE,
  IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_ROLE_UPDATE_ACTION,
]

export const DELETE = [IDAVE_PERMISSIONS.ROLES_DELETE]
