import { Text, Token } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { CrossCheckValueTab } from '../../../types'
import { ChangeType, TextValue } from '../types'

type DiffedTextProps = {
  tab: CrossCheckValueTab
  value: TextValue
  isFinished: boolean
  changeType: ChangeType
}
export const DiffedText = ({ tab, value, isFinished, changeType }: DiffedTextProps) => {
  switch (tab) {
    case 'current':
    case 'forecasted': {
      const itemValue = value[tab]

      return <Text data-testid={`${tab}-value`}>{itemValue || EMPTY_VALUE_FALLBACK}</Text>
    }
    case 'requested':
    default: {
      const { current, requested } = value

      if (isFinished) {
        return (
          <Text data-testid={`${tab}-value`}>{requested || EMPTY_VALUE_FALLBACK}</Text>
        )
      }

      const isNewValue =
        (changeType === 'Added' || changeType === 'Edited') && !isFinished

      return (
        <Text
          color={isNewValue ? Token.color.green : undefined}
          data-testid={`${tab}-value`}
        >
          {current && current !== requested && (
            <Text
              color={Token.color.greyTone20}
              textDecoration="line-through"
              data-testid={`${tab}-value-old`}
            >
              {current}
            </Text>
          )}
          <Text data-testid={`${tab}-value-new`}>{requested}</Text>
        </Text>
      )
    }
  }
}
