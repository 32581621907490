import { Box, Subheader, VStack } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { EntityDescription } from 'components/EntityDescription'
import { ClientLink } from 'components/Links/ClientLink'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'

type Props = {
  permission: Permission
}

export const PermissionInfo = ({ permission }: Props) => {
  return (
    <VStack space="s-16" maxWidth={MAXIMAL_DETAILS_WIDTH}>
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>
        <DetailsGroup
          rows={[
            ['Name', permission.name],
            [
              'Application',
              <ClientLink id={permission.client} key={permission.client} />,
            ],
            ['ID', <CopyableText value={permission.id} key={permission.id} />],
          ]}
        />
      </Box>
      <EntityDescription description={permission.description} />
    </VStack>
  )
}
