import { TableColumn } from '@revolut/ui-kit'
import { SamPolicy } from 'api/sam/policies'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryResourcePolicies } from 'queries/sam/policies'
import { useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { mergeQueryStatuses } from 'utils/query'
import { stringifySubjectType } from 'view/Sam/utils'

type Props = {
  resourceId: string
}

const getRowLink = ({ policyId }: SamPolicy) => generatePath(Url.SamPolicy, { policyId })

const COLUMNS: TableColumn<SamPolicy & { id: string }>[] = [
  {
    Header: 'Policy name',
    accessor: 'policyName',
  },
  {
    Header: 'Subject type',
    accessor: (value) => stringifySubjectType(value.subject.subjectType),
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]

export const ResourcePolicies = ({ resourceId }: Props) => {
  const {
    data: policies = [],
    fetchStatus: fs,
    status: qs,
  } = useQueryResourcePolicies(resourceId)

  const entities = useMemo(
    () => policies.map((policy) => ({ ...policy, id: policy.policyId })),

    [policies],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities,
  })
  const state = getLoadingState(mergeQueryStatuses({ qs, fs }), entities.length)

  return state === 'no-results' ? (
    <EmptyStatusWidget
      title="No policy associated with this resource"
      imageCode="3D055"
    />
  ) : (
    <EntitiesTable
      data={searched}
      totalCount={entities.length}
      entitiesTypeLabel="Policies"
      pluralForms={['policy', 'policies']}
      loadingState={state}
      columns={COLUMNS}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      getRowLink={getRowLink}
      searchAutoFocus
    />
  )
}
