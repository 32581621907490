import { CustomerCompanyItem } from 'api/dart/customerCompanies'

type GetFilteredCompanies = {
  companies: CustomerCompanyItem[]
  showSelected: boolean
  selectedHash: Record<string, boolean>
}

export const getFilteredCompanies = ({
  companies,
  showSelected,
  selectedHash,
}: GetFilteredCompanies) => {
  return companies.filter((company) => {
    const visibleBySelection = !showSelected || selectedHash[company.id]
    return visibleBySelection
  })
}
