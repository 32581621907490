import { MoreBar } from '@revolut/ui-kit'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useQueryPermissionIdMap, useQueryToxicPairs } from 'queries/idave/permissions'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useCallback, useMemo } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { EntitiesTable } from 'components/EntitiesTable'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { IDAVE_PERMISSIONS } from 'security'
import { getRows } from './utils'
import { useColumns } from './columns'

export const ToxicPairs = () => {
  const { data: userMap } = useQueryUserIdMap()
  const {
    data: permissionMap = new Map(),
    status: permissionQS,
    fetchStatus: permissionFS,
  } = useQueryPermissionIdMap()
  const {
    data: toxicPairs = [],
    status: toxicQS,
    fetchStatus: toxicFS,
  } = useQueryToxicPairs()
  const navigate = useNavigate()

  const loadingState = useMemo(
    () =>
      getLoadingState(
        mergeQueryStatuses(
          {
            qs: permissionQS,
            fs: permissionFS,
          },
          {
            qs: toxicQS,
            fs: toxicFS,
          },
        ),
        toxicPairs.length,
      ),
    [permissionQS, permissionFS, toxicFS, toxicQS, toxicPairs.length],
  )
  const entities = useMemo(
    () => getRows({ toxicPairs, permissionMap, userMap }),
    [toxicPairs, permissionMap, userMap],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities,
  })

  const columns = useColumns()
  const getRowLink = useCallback((params: { id: string }) => {
    return generatePath(Url.ToxicPair, { toxicPairId: params.id })
  }, [])
  const onRowClick = useCallback(
    (params: { id: string }) => {
      navigate(getRowLink(params))
    },
    [navigate, getRowLink],
  )

  const onToxicCreateClick = useCallback(() => navigate(Url.ToxicPairAdd), [navigate])

  return (
    <EntitiesTable
      enableNavigation
      entitiesTypeLabel="Toxic pairs"
      pluralForms={['toxic pair', 'toxic pairs']}
      totalCount={entities.length}
      loadingState={loadingState}
      data={searched}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      getRowLink={getRowLink}
      onRowClick={onRowClick}
      searchAutoFocus
      renderActions={() => (
        <PermissionsCheck permission={IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_CREATE}>
          <MoreBar.Action useIcon="Plus" onClick={onToxicCreateClick}>
            Create new
          </MoreBar.Action>
        </PermissionsCheck>
      )}
    />
  )
}
