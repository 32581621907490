import React from 'react'
import { QueryFetchStatus, waitForAll } from 'utils/query'
import { RequestErrorWidget } from './RequestErrorWidget'
import { NoAccessWidget } from './NoAccessWidget'

interface QuerySwitchProps<Data> {
  data?: Data
  required: QueryFetchStatus[]
  optional?: QueryFetchStatus[]
  renderSuccess: (params: { data: NonNullable<Data> }) => React.ReactNode
  renderIdle?: () => React.ReactNode
  renderLoading: () => React.ReactNode
  renderError?: () => React.ReactNode
  idleText?: string
}
export const QuerySwitch = <Data,>(props: QuerySwitchProps<Data>) => {
  const {
    required,
    optional,
    data,
    idleText,
    renderError = () => <RequestErrorWidget />,
    renderLoading,
    renderSuccess,
    renderIdle = () => <NoAccessWidget title={idleText} />,
  } = props

  const status = waitForAll(required, optional)
  switch (status) {
    case 'error':
      return <React.Fragment key="error">{renderError()}</React.Fragment>
    case 'forbidden':
      return <React.Fragment key="forbidden">{renderIdle()}</React.Fragment>
    case 'loading':
      return <React.Fragment key="loading">{renderLoading()}</React.Fragment>
    case 'success':
      return (
        <React.Fragment key="success">
          {data ? renderSuccess({ data }) : renderError()}
        </React.Fragment>
      )
    default:
      return null
  }
}
