import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { EntitySkeleton } from 'components/EntitySkeleton'
import { ClientTab } from '../types'

export const ClientSkeleton = (props: { clientId: string; tab: ClientTab }) => {
  const { hasSomePermissions } = usePermissions()
  const variant = props.tab === 'details' ? 'details' : 'table'
  const hasActions = hasSomePermissions(
    IDAVE_PERMISSIONS.CLIENTS_DELETE,
    IDAVE_PERMISSIONS.CLIENTS_UPDATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_CLIENT_UPDATE_ACTION,
  )
  return <EntitySkeleton variant={variant} hasActions={hasActions} />
}
