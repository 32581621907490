import { Box, Subheader } from '@revolut/ui-kit'
import { SAMLog } from 'api/sam/auditLogs'
import { DetailsGroup } from 'components/DetailsGroup'

export const SamLogDetails = (props: { log: SAMLog }) => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Log details</Subheader.Title>
      </Subheader>

      <DetailsGroup
        rows={[
          ['Log ID', props.log.id],
          ['Log action', props.log.action],
        ]}
      />
    </Box>
  )
}
