import { Calendar, Dropdown, FilterButton } from '@revolut/ui-kit'
import { useCallback, useMemo, useRef, useState } from 'react'
import { endOfToday } from 'date-fns'
import { getRangeLabel, getRange } from './utils'

export type DateFilterValue = { from: Date; to: Date } | null

export const CalendarFilter = (props: {
  value: DateFilterValue
  exceptFuture?: boolean
  onChange: (value: DateFilterValue) => void
}) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<DateFilterValue>(props.value)

  const update = useCallback(
    (newValue: DateFilterValue) => {
      const range = getRange(newValue)
      props.onChange(range)
      setValue(range)
    },
    [setValue, props],
  )

  const label = useMemo(() => getRangeLabel(value, new Date()), [value])
  const onButtonClick = useCallback(() => setOpen(true), [])
  const onClear = useMemo(
    () =>
      value
        ? () => {
            update(null)
            setOpen(false)
          }
        : undefined,
    [value, update, setOpen],
  )
  const onClose = useCallback(() => setOpen(false), [setOpen])

  return (
    <>
      <FilterButton
        useIcon="Calendar"
        ref={anchorRef}
        onClick={onButtonClick}
        onClear={onClear}
        active={!!value}
      >
        {label}
      </FilterButton>
      <Dropdown open={open} anchorRef={anchorRef} onClose={onClose}>
        <Calendar
          defaultValue={value}
          variant="range"
          labelButtonClear={value ? 'Clear' : undefined}
          labelEmptyValue="Select range"
          labelPrev="Previous"
          labelNext="Next"
          onChange={update}
          disabledDays={props.exceptFuture ? { after: endOfToday() } : undefined}
        />
      </Dropdown>
    </>
  )
}
