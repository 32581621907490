import { Permission } from 'api/idave/permissions'
import { Role } from 'api/idave/roles'
import { sortBy, uniq } from 'lodash'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { ToxicPair } from 'api/idave/toxic'
import { matchToxicPairsByPermissions } from 'utils/toxicPairs/matchToxicPairsByPermissions'

type GetAddingPermissionsParams = {
  role: Role
  permissions: Permission[]
}

export const getAddingPermissions = ({
  role,
  permissions,
}: GetAddingPermissionsParams) => {
  const rolePermissionPresence = toPresenceMap(role.permissions)

  return sortBy(
    permissions.filter((p) => !rolePermissionPresence[p.id]),
    'name',
  )
}

type GetToxicPairsParams = {
  selected: string[]
  permissions: string[]
  toxicPairs: ToxicPair[]
}
export const getToxicPairs = ({
  selected,
  permissions,
  toxicPairs,
}: GetToxicPairsParams) => {
  const allPermissions = uniq(permissions.concat(selected))
  const matchedToxicPairs = matchToxicPairsByPermissions(allPermissions, toxicPairs)

  const selectedMap = toPresenceMap(selected)
  return matchedToxicPairs.reduce<ToxicPair[]>((acc, pair) => {
    if (selectedMap[pair.firstPermission] || selectedMap[pair.secondPermission]) {
      return [...acc, pair]
    }
    return acc
  }, [])
}
