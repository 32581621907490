import { LogAuthor } from 'components/LogAuthor'
import { VStack } from '@revolut/ui-kit'
import { AuditLogData } from 'api/idave/auditsLog'
import { QuerySwitch } from 'components/QuerySwitch'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { useQueryAuditLog } from 'queries/idave/logs'
import { DataMaps } from 'view/AuditLogs/types'
import { getAuthor } from '../../utils'
import { LogInfo } from './components/LogInfo'
import { Permissions } from './components/Permissions'
import { Details } from './components/Details'
import { DataLists } from './components/DataLists'
import { DataMapsProvider } from './components/DataMapsProvider'

export const Body = ({ eventId }: { eventId: string }) => {
  const { data: logs, fetchStatus: fs, status: qs } = useQueryAuditLog({ eventId })
  const log = logs?.[0]

  return (
    <QuerySwitch
      data={log}
      required={[{ qs, fs }]}
      renderLoading={() => <DetailsGroupSkeleton />}
      idleText="Lack of audit logs view permission"
      renderSuccess={({ data }) => (
        <DataMapsProvider
          renderComponent={(dataMaps) => <Inner log={data} dataMaps={dataMaps} />}
        />
      )}
    />
  )
}

const Inner = ({ log, dataMaps }: { log: AuditLogData; dataMaps: DataMaps }) => {
  return (
    <VStack space="s-16">
      <Details log={log} dataMaps={dataMaps} />
      <Permissions
        title="Added permissions"
        permissionsIds={log['Added Permissions']?.value.map((i) => i.value.id)}
        dataMaps={dataMaps}
      />
      <Permissions
        title="Removed Permissions"
        permissionsIds={log['Removed Permissions']?.value.map((i) => i.value.id)}
        dataMaps={dataMaps}
      />
      <DataLists data={log.Data?.value} dataMaps={dataMaps} />
      <LogAuthor {...getAuthor(log.Author)} />
      <LogInfo log={log} />
    </VStack>
  )
}
