import { LinkifyIt, Match } from 'linkify-it'
import { PresenceMap } from 'utils/array/toPresenceMap'
import { notNullable } from 'utils/common'
import { Token } from '../types'
import { makeTextToken } from './makeTextToken'
import { matchToToken } from './matchToToken'

export const makeTokenize = (linkParser: LinkifyIt) => {
  return (text?: string) => {
    if (!text) {
      return []
    }
    const matches = linkParser.match(text)
    if (!matches) {
      return [makeTextToken({ text })]
    }

    const tokens = matchesToTokens(matches, text)

    return dropExcessiveTokens(tokens.filter(notNullable))
  }
}

const LINK_OPEN_BRACE = '('
const LINK_CLOSE_BRACE = ')'
const LINK_TEXT_OPEN_BRACE = '['
const LINK_TEXT_CLOSE_BRACE = ']'
const LINK_STACK = [
  LINK_TEXT_OPEN_BRACE,
  LINK_TEXT_CLOSE_BRACE,
  LINK_OPEN_BRACE,
  LINK_CLOSE_BRACE,
]

export const checkLinkedTextBraces = (text: string) => {
  return (
    text.split('').reduce(
      (stack, ch) => {
        if (ch === stack[0]) stack.shift()
        return stack
      },
      [...LINK_STACK],
    ).length === 0
  )
}

export const isDisplayTextLinkSchema = (matchSchema: string) =>
  matchSchema.startsWith('http')

export const getDisplayTextLinkTokens = ({
  text,
  lastIndex,
  match,
}: {
  text: string
  lastIndex: number
  match: Match
}) => {
  const linkOpenTextBraceIdx = text.indexOf(LINK_TEXT_OPEN_BRACE, lastIndex)

  const matchText = text.slice(linkOpenTextBraceIdx + 1, match.index - 2)

  const preText =
    lastIndex < linkOpenTextBraceIdx
      ? makeTextToken({ text, from: lastIndex, to: linkOpenTextBraceIdx })
      : undefined
  const matchToken = matchToToken(match, matchText)
  return {
    tokens: [preText, matchToken].filter(notNullable),
    lastIndex: match.lastIndex + 1,
  }
}

/**
 *  Returns tokens for:
 *  - text token before match
 *  - match token itself
 *    - w/ text replacing match value (e.g md-like link text [text](http://link.value))
 */
const extractMatchTokens = ({
  match,
  text,
  lastIndex,
}: {
  text: string
  lastIndex: number
  match: Match
}) => {
  const hasHeadText = match.index > lastIndex
  if (!hasHeadText) {
    return { tokens: [matchToToken(match)], lastIndex: match.lastIndex }
  }

  const processText = text.slice(lastIndex, match.lastIndex + 1)
  const hasValidTextLinkBraces = checkLinkedTextBraces(processText)

  if (!isDisplayTextLinkSchema(match.schema) || !hasValidTextLinkBraces) {
    return {
      tokens: [
        makeTextToken({
          text,
          from: lastIndex,
          to: match.index,
        }),
        matchToToken(match),
      ],
      lastIndex: match.lastIndex,
    }
  }

  return getDisplayTextLinkTokens({ text, lastIndex, match })
}

export const matchesToTokens = (matches: Match[], text: string) => {
  return matches.reduce<{
    tokens: Token[]
    lastIndex: number
  }>(
    ({ tokens, lastIndex }, match, idx) => {
      const result = [...tokens]
      const { tokens: newTokens, lastIndex: newLastIndex } = extractMatchTokens({
        match,
        text,
        lastIndex,
      })

      result.push(...newTokens)
      /**
       * last match can't be linked text so no need to overlook here
       */
      const isLastMatch = matches.length - 1 === idx
      const hasTailText = isLastMatch && newLastIndex + 1 < text.length
      const tailText = makeTextToken({
        text,
        from: newLastIndex,
      })

      if (hasTailText && tailText) {
        result.push(tailText)
      }

      return {
        tokens: result,
        lastIndex: newLastIndex,
      }
    },
    {
      tokens: [],
      lastIndex: 0,
    },
  ).tokens
}

const SINGLETON_COMMANDS: Array<Token['type']> = ['status_command']

const dropExcessiveTokens = (tokens: Token[]) => {
  const addedSingletons: PresenceMap = {}

  return tokens.filter((token) => {
    if (SINGLETON_COMMANDS.includes(token.type)) {
      const wasAdded = addedSingletons[token.type]
      addedSingletons[token.type] = true

      return !wasAdded
    }

    return true
  })
}
