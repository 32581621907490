import { Auth } from '@revolut-internal/idave-web-auth'
import { isProduction } from 'utils/common'

const IDAVE_CLIENT_ID = '269570c5-3d9e-406f-a989-5e76300bbb49'
const IDAVE_HOST = isProduction() ? 'https://id.revolut.com' : 'https://id.revolut.codes'

export const auth = new Auth({
  iDaveClientId: IDAVE_CLIENT_ID,
  iDaveHost: IDAVE_HOST,
  apiUrl: '/api',
  loginUrl: '/oauth2/code',
})
