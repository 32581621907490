import { Match } from 'linkify-it'
import { findMapped } from 'utils/array/findMapped'
import { matchPath } from 'react-router'
import {
  CommonPageType,
  EntityPageType,
  ExternalLink,
  InternalLink,
  Page,
} from '../types'
import { PATHS_TO_MATCH } from '../constants'

export const makeLinkToken = (
  match: Match,
  text?: string,
): ExternalLink | InternalLink => {
  const page = extractPage(match)
  return page
    ? {
        type: 'internal_link' as const,
        value: match.url,
        page,
        text,
      }
    : {
        type: 'external_link' as const,
        value: match.url,
        text,
      }
}

const extractPage = (match: Match): Page | undefined => {
  const url = new URL(match.url)

  if (url.host !== window.location.host) {
    return undefined
  }

  const matchedPath = findMapped(PATHS_TO_MATCH, (page) => {
    const { path } = page
    const matched = matchPath(
      {
        path,
        caseSensitive: true,
        end: false,
      },
      url.pathname,
    )

    return matched ? { match: matched, page } : undefined
  })

  if (matchedPath?.page) {
    const entityKey =
      'entityKey' in matchedPath.page ? matchedPath.page.entityKey : undefined
    const entityId = entityKey ? matchedPath.match.params[entityKey] : undefined
    const pageType = matchedPath.page.pageType

    return getPage(pageType, entityId)
  }

  return undefined
}

const getPage = (
  pageType: EntityPageType | CommonPageType,
  entityId?: string,
): Page | undefined => {
  switch (pageType) {
    case 'client':
    case 'customerCompany':
    case 'dartCrossCheck':
    case 'employeeCompany':
    case 'idaveCrossCheck':
    case 'organisation':
    case 'permission':
    case 'policy':
    case 'role':
    case 'samCrossCheck':
    case 'user':
      return entityId ? { type: pageType, entityId } : undefined

    case 'dartLogs':
    case 'employeeAccesses':
    case 'employeeRestrictions':
    case 'idaveLogs':
      return { type: pageType }
    default:
      return undefined
  }
}

export const __test_only = {
  extractPage,
}
