import { Box, Item, ItemSkeleton, TextSkeleton, VStack, Widget } from '@revolut/ui-kit'

const COMMENT_SIZES = [32, 48, 24]

export const CrossCheckEventSkeletons = () => {
  return (
    <VStack space="s-16">
      {COMMENT_SIZES.map((size, idx) => (
        <CrossCheckCommentSkeleton size={size} key={idx} />
      ))}
    </VStack>
  )
}

const CrossCheckCommentSkeleton = (props: { size: number }) => {
  return (
    <Widget>
      <ItemSkeleton>
        <ItemSkeleton.Avatar />
        <Item.Content>
          <ItemSkeleton.Title />
          <ItemSkeleton.Description />
        </Item.Content>
      </ItemSkeleton>
      <Box pl="s-16" pr="s-16" pb="s-16">
        <TextSkeleton size={props.size} />
      </Box>
    </Widget>
  )
}
