import { Avatar, HStack, Link } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { SystemRequester as TSystemRequester } from 'view/CrossChecks/types'

export const SystemRequester = (props: { system: TSystemRequester }) => {
  const path = generatePath(Url.Client, { clientId: props.system.id })

  return (
    <HStack space="s-8">
      <Avatar size={20} useIcon="Apps" />
      <Link href={path} target="_blank">
        {props.system.name}
      </Link>
    </HStack>
  )
}
