/* eslint-disable prettier/prettier */
export const SAM_PERMISSIONS = {
  AUDIT_VIEW_DETAILS: {
    endpoints: [
      'GET /audit/{entity_type}/{entity_id}'
    ],
    service: 'sam',
    value: 'audit.view.details'
  },
  AUDIT_VIEW_LIST: {
    endpoints: [
      'GET /audit/'
    ],
    service: 'sam',
    value: 'audit.view.list'
  },
  CROSS_CHECKS_COMMENT: {
    endpoints: [
      'POST /cross-checks-v2/{cross_check_id}/comment'
    ],
    service: 'sam',
    value: 'cross_checks.comment'
  },
  CROSS_CHECKS_DECLINE: {
    endpoints: [
      'POST /cross-checks-v2/{cross_check_id}/decline'
    ],
    service: 'sam',
    value: 'cross_checks.decline'
  },
  CROSS_CHECKS_JUSTIFY: {
    endpoints: [
      'POST /cross-checks-v2/{cross_check_id}/justify'
    ],
    service: 'sam',
    value: 'cross_checks.justify'
  },
  CROSS_CHECKS_RETRY: {
    endpoints: [
      'POST /cross-checks-v2/{cross_check_id}/retry'
    ],
    service: 'sam',
    value: 'cross_checks.retry'
  },
  CROSS_CHECKS_REVIEW: {
    endpoints: [
      'POST /cross-checks-v2/{cross_check_id}/review'
    ],
    service: 'sam',
    value: 'cross_checks.review'
  },
  CROSS_CHECKS_VIEW_DETAILS: {
    endpoints: [
      'GET /cross-checks-v2/{cross_check_id}'
    ],
    service: 'sam',
    value: 'cross_checks.view.details'
  },
  CROSS_CHECKS_VIEW_LIST: {
    endpoints: [
      'GET /cross-checks-v2/my'
    ],
    service: 'sam',
    value: 'cross_checks.view.list'
  },
  PERMISSIONS_VIEW_ACL_LIST: {
    endpoints: [
      'GET /permissions/acl'
    ],
    service: 'sam',
    value: 'permissions.view.acl.list'
  },
  POLICIES_CREATE: {
    endpoints: [
      'POST /policies/'
    ],
    service: 'sam',
    value: 'policies.create'
  },
  POLICIES_CREATE_WITH_CROSS_CHECK: {
    endpoints: [
      'POST /cross-checked-policies/'
    ],
    service: 'sam',
    value: 'policies.create.with_cross_check'
  },
  POLICIES_DELETE: {
    endpoints: [
      'DELETE /policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.delete'
  },
  POLICIES_DELETE_WITH_CROSS_CHECK: {
    endpoints: [
      'DELETE /cross-checked-policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.delete.with_cross_check'
  },
  POLICIES_UPDATE: {
    endpoints: [
      'PATCH /policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.update'
  },
  POLICIES_UPDATE_APPLY: {
    endpoints: [
      'POST /policies/{policy_id}/apply'
    ],
    service: 'sam',
    value: 'policies.update.apply'
  },
  POLICIES_UPDATE_RESOURCES: {
    endpoints: [
      'PUT /policies/{policy_id}/resources'
    ],
    service: 'sam',
    value: 'policies.update.resources'
  },
  POLICIES_UPDATE_WITH_CROSS_CHECK: {
    endpoints: [
      'PATCH /cross-checked-policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.update.with_cross_check'
  },
  POLICIES_VIEW_DETAILS: {
    endpoints: [
      'GET /policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.view.details'
  },
  POLICIES_VIEW_EMPLOYEES_LIST: {
    endpoints: [
      'GET /policies/{policy_id}/employees'
    ],
    service: 'sam',
    value: 'policies.view.employees.list'
  },
  POLICIES_VIEW_LIST: {
    endpoints: [
      'GET /policies/'
    ],
    service: 'sam',
    value: 'policies.view.list'
  },
  RECERTIFICATIONS_VIEW_DETAILS: {
    endpoints: [
      'GET /recertifications/{recertification_id}'
    ],
    service: 'sam',
    value: 'recertifications.view.details'
  },
  RECERTIFICATIONS_VIEW_LIST: {
    endpoints: [
      'GET /recertifications/'
    ],
    service: 'sam',
    value: 'recertifications.view.list'
  },
  RECERTIFICATIONS_VIEW_POLICY_MAPPING_LIST: {
    endpoints: [
      'GET /recertifications/policy-mapping'
    ],
    service: 'sam',
    value: 'recertifications.view.policy_mapping.list'
  },
  RESOURCES_SET_OWNERS: {
    endpoints: [
      'POST /resources/set-owners'
    ],
    service: 'sam',
    value: 'resources.set_owners'
  },
  RESOURCES_VIEW_DETAILS: {
    endpoints: [
      'GET /resources/{resource_id}'
    ],
    service: 'sam',
    value: 'resources.view.details'
  },
  RESOURCES_VIEW_LIST: {
    endpoints: [
      'GET /resources/'
    ],
    service: 'sam',
    value: 'resources.view.list'
  },
  REVIEWER_GROUP_ADD_REVIEWERS: {
    endpoints: [
      'POST /cross-checks-v2/groups/{reviewer_group_id}/reviewers'
    ],
    service: 'sam',
    value: 'reviewer_group.add_reviewers'
  },
  REVIEWER_GROUP_VIEW_DETAILS: {
    endpoints: [
      'GET /cross-checks-v2/groups/{reviewer_group_id}'
    ],
    service: 'sam',
    value: 'reviewer_group.view.details'
  },
  REVOLUTERS_STRUCTURE_VIEW_LIST: {
    endpoints: [
      'GET /revoluters-structure/'
    ],
    service: 'sam',
    value: 'revoluters_structure.view.list'
  },
  REVOLUTERS_VIEW_AVATARS_LIST: {
    endpoints: [
      'GET /revoluters/avatars'
    ],
    service: 'sam',
    value: 'revoluters.view.avatars.list'
  },
  REVOLUTERS_VIEW_EMPLOYEES_LIST: {
    endpoints: [
      'GET /revoluters/employees'
    ],
    service: 'sam',
    value: 'revoluters.view.employees.list'
  },
  REVOLUTERS_VIEW_STRUCTURE_LIST: {
    endpoints: [
      'GET /revoluters/structure'
    ],
    service: 'sam',
    value: 'revoluters.view.structure.list'
  },
  TASKS_CREATE: {
    endpoints: [
      'POST /tasks/'
    ],
    service: 'sam',
    value: 'tasks.create'
  },
  TRAININGS_VIEW_LIST: {
    endpoints: [
      'GET /trainings/'
    ],
    service: 'sam',
    value: 'trainings.view.list'
  },
  USER_POLICIES_PROVISION: {
    endpoints: [
      'POST /user-policies/{idave_user_id}/provision'
    ],
    service: 'sam',
    value: 'user_policies.provision'
  },
  USER_POLICIES_VIEW_DETAILS: {
    endpoints: [
      'GET /user-policies/{idave_user_id}'
    ],
    service: 'sam',
    value: 'user_policies.view.details'
  },
  WHOAMI_VIEW_DETAILS: {
    endpoints: [
      'GET /whoami'
    ],
    service: 'sam',
    value: 'whoami.view.details'
  }
} as const