import { ClientSelect, ClientSelectProps } from 'components/Selects/ClientSelect'
import { useController, useFormContext } from 'react-hook-form'
import { useOnChange } from 'utils/form/useOnChange'
import { Fielded } from 'utils/types/fielded'

type Props = Fielded<ClientSelectProps>

export const FieldClientSelect = ({ name, rules, ...rest }: Props) => {
  const { control } = useFormContext()
  const { field } = useController({
    name,
    control,
    rules,
  })

  const onChange = useOnChange(field.onChange)

  return (
    <ClientSelect
      {...rest}
      {...field}
      ref={undefined}
      itemId={field.value}
      onChange={onChange}
    />
  )
}
