import { Match } from 'linkify-it'
import { MENTION_COMMAND, STATUS_COMMAND } from 'hooks/useLinkify/constants'
import { extractMentionEmail, extractStatusCommand } from 'hooks/useLinkify/utils'
import { Token } from '../types'
import { makeTextToken } from './makeTextToken'
import { makeLinkToken } from './makeLinkToken'
import { makeStatusCommandToken } from './makeStatusCommandToken'
import { makeMention } from './makeMention'

export const matchToToken = (match: Match, textValue?: string): Token => {
  switch (match.schema) {
    case STATUS_COMMAND: {
      const value = extractStatusCommand(match)

      return value
        ? makeStatusCommandToken(value)
        : makeTextToken({ text: match.text, from: match.index, to: match.lastIndex })
    }

    case MENTION_COMMAND: {
      const value = extractMentionEmail(match)
      return value
        ? makeMention(value)
        : makeTextToken({ text: match.text, from: match.index, to: match.lastIndex })
    }

    case 'http:':
    case 'https:':
    case '//':
      return makeLinkToken(match, textValue)
    default:
      return makeTextToken({ text: match.text })
  }
}
