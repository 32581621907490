import { AxiosResponse } from 'axios'
import { UseQueryResult } from '@tanstack/react-query'
import { identity } from 'lodash'
import { mapify } from 'utils/array'
import { OuterOptions } from './types'

export const isEnabled = (allowed: boolean, options?: OuterOptions) => {
  const optionEnabled = typeof options?.enabled === 'boolean' ? options.enabled : true

  return optionEnabled && allowed
}

export const getLogsNextPageParam =
  (limitsFrom?: number) => (lastPage: AxiosResponse) => {
    const from = lastPage.headers['x-scanned-from']
    const to = lastPage.headers['x-scanned-to']
    const isEndOfScan = lastPage.headers['x-end-of-scan'] === 'true'

    if (isEndOfScan || from === to) {
      return undefined
    }

    if (limitsFrom && limitsFrom >= Number(from)) {
      return undefined
    }

    return {
      to: Number(from),
    }
  }

type DataMap<Data, Entity> = {
  data?: Data
  value?: Map<string, Entity>
}

export const makeMappedDataHook = <Data, Entity>(
  useDataHook: () => UseQueryResult<Data>,
  getEntities: (response: Data) => Entity[],
  matcher: (item: Entity) => string | undefined,
) => {
  let cacheStore: DataMap<Data, Entity> = {}

  return () => {
    const { data, ...query } = useDataHook()
    if (cacheStore.data !== data) {
      const entities = data ? getEntities(data) : []
      cacheStore = {
        data,
        value: mapify(entities, matcher),
      }
    }
    return { data: cacheStore.value, rawData: cacheStore.data, ...query }
  }
}

export const makeMappedKeyDataHook = <Entity>(
  useDataHook: () => UseQueryResult<Entity[]>,
  key: keyof Entity,
) => makeMappedDataHook<Entity[], Entity>(useDataHook, identity, (i) => String(i[key]))
