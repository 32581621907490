import { Account } from 'api/idave/accounts'
import { useCallback } from 'react'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { uniq } from 'lodash'
import { PermissionsList } from 'view/Clients/Account/components/PermissionWidget'
import { useEditAccount } from 'view/Clients/Account/hooks/useEditAccount'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { EDIT_ACCOUNT } from 'view/Clients/permissions'

type Props = {
  account: Account
}
export const Permissions = ({ account }: Props) => {
  const editAccount = useEditAccount({ accountId: account.id, editTab: 'permissions' })
  const { hasSomePermissions } = usePermissions()

  const addPermissions = useCallback(
    (newIds: string[]) => {
      const permissionIds = uniq([...(account.permissionIds || []), ...newIds])

      return editAccount({
        ...account,
        permissionIds,
      })
    },
    [editAccount, account],
  )

  const deletePermissions = useCallback(
    (deleteIds: string[]) => {
      const deleteMap = toPresenceMap(deleteIds)
      const permissionIds = account.permissionIds.filter((id) => !deleteMap[id])

      return editAccount({
        ...account,
        permissionIds,
      })
    },
    [editAccount, account],
  )

  return (
    <PermissionsList
      onAdd={addPermissions}
      onDelete={deletePermissions}
      showActions={hasSomePermissions(...EDIT_ACCOUNT)}
      permissionIds={account.permissionIds}
    />
  )
}
