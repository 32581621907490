import { Avatar, Checkbox, Item, textChain } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { VirtualItem } from 'react-virtual'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { getRegionFlagImageUrl } from 'utils/url/getRegionFlagImageUrl'
import { capitalizeFirst } from 'utils/string'

type CompanyListItemProps = {
  company: CustomerCompanyItem
  virtualItem: VirtualItem
  selectedHash: Record<string, boolean | undefined>
  disabled?: boolean
  onChange: (id: string) => void
}

export const CompanyListItem = ({
  company,
  virtualItem,
  selectedHash,
  disabled,
  onChange,
}: CompanyListItemProps) => {
  const onItemChange = useCallback(() => {
    onChange(company.id)
  }, [onChange, company.id])

  return (
    <Item use="label" ref={virtualItem.measureRef} style={getItemStyle(virtualItem)}>
      <Item.Prefix>
        <Checkbox
          aria-labelledby="item-title"
          aria-describedby="item-description"
          onChange={onItemChange}
          disabled={disabled}
          checked={!!selectedHash[company.id]}
          align="center"
        />
      </Item.Prefix>
      <Item.Avatar>
        <Avatar
          size={40}
          uuid={company.id}
          title={company.region}
          image={getRegionFlagImageUrl(company.region)}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{company.name}</Item.Title>
        <Item.Description>
          {textChain(company.region, capitalizeFirst(company.serviceType))}
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

const getItemStyle = (virtualItem: VirtualItem) => ({
  position: 'absolute' as const,
  top: 0,
  left: 0,
  width: '100%',
  minHeight: `${virtualItem.size}px`,
  transform: `translateY(${virtualItem.start}px)`,
})
