import { Box, Subheader } from '@revolut/ui-kit'
import { AuditLogData } from 'api/dart/auditLog'
import { DetailsGroup } from 'components/DetailsGroup'

import { UserLink } from 'components/Links/UserLink'
import { EmployeeCompanyLink } from 'components/Links/EmployeeCompanyLink'

export const AuditLogDetails = ({ log }: { log: AuditLogData }) => (
  <Box>
    <Subheader variant="nested">
      <Subheader.Title>Action details</Subheader.Title>
    </Subheader>

    <DetailsGroup
      rows={[
        ['Event', log.Event.value],

        [
          'Entity',
          log.Model.value.entity === 'DartEmployee' ? (
            <UserLink id={log.Model.value.id} />
          ) : (
            <EmployeeCompanyLink id={log.Model.value.id} />
          ),
        ],
      ]}
    />
  </Box>
)
