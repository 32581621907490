import { HOUR } from 'constants/time'
import { useCallback, useEffect, useMemo, useRef } from 'react'

export type ItemStoreConfig = {
  storage: Storage
  exclude: string[]
  timeout: number
}

export const useStorageItem = <T extends Object>(
  name?: string,
  config?: Partial<ItemStoreConfig>,
) => {
  const { storage, exclude, timeout } = useMemo(
    () => ({
      storage: config?.storage || window.localStorage,
      exclude: config?.exclude || [],
      timeout: config?.timeout || 4 * HOUR,
    }),

    [config],
  )
  // extracted data
  const mutableData = useRef<T | undefined>(undefined)

  // updates storage and data values
  const setItem = useCallback(
    (item: T) => {
      if (!name) {
        return
      }
      const itemKeys = Object.keys(item).filter(
        (key) => !exclude.includes(key),
      ) as (keyof T)[]

      const value = itemKeys.reduce<Record<string, unknown>>(
        (acc, key) => ({ ...acc, [key]: item[key] }),
        {},
      )

      const result = JSON.stringify({ ts: Date.now(), value })

      mutableData.current = value as T
      storage.setItem(name, result)
    },
    [exclude, storage, name],
  )

  // clears storage and data values
  const clearItem = useCallback(() => {
    mutableData.current = undefined
    if (!name) {
      return
    }
    storage.removeItem(name)
  }, [storage, name])

  // initial sync data set
  try {
    const item = name ? storage.getItem(name) : undefined
    const { ts, value } = item ? JSON.parse(item) : { ts: 0, value: undefined }
    const now = Date.now()
    if (!value || ts + timeout <= now) {
      mutableData.current = undefined
    } else {
      mutableData.current = value as T
    }
  } catch (_e) {
    // do nothing
  }

  // initial async data invalidation
  useEffect(() => {
    try {
      const now = Date.now()
      const storedData = name ? JSON.parse(storage.getItem(name) || '') : undefined

      const ts = storedData?.ts

      if (ts + timeout <= now) {
        clearItem()
      }
    } catch (_e) {
      // do nothing
    }
  }, [storage, name, timeout, clearItem])

  return {
    clearItem,
    setItem,
    item: mutableData.current,
  }
}
