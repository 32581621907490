export const getRegionFlagImageUrl = (region: string) => {
  /**
   * Fix for unsupported region code EEA
   * https://bitbucket.org/revolut/common/src/18f80e5aa3fa0e59af313c04e7050b8cbe23ff46/common-types/src/main/java/com/revolut/types/Region.java
   */
  const code = region === 'EEA' ? 'EU' : region

  return {
    default: `https://assets.revolut.com/assets/flags/${code}.png`,
    x2: `https://assets.revolut.com/assets/flags${code}@2x.png 2x`,
    x3: `https://assets.revolut.com/assets/flags/${code}@3x.png 3x`,
  }
}
