import { ServicePermission } from 'services/permissions'
import { QueryResult } from 'utils/query'

type GetErrorMessageParams<Data> = {
  itemMap: Map<string, Data>
  requiredPermission?: ServicePermission
  itemId?: string | null
  queryResult: QueryResult
}
export const getErrorMessage = <T>({
  queryResult,
  itemMap,
  requiredPermission,
  itemId,
}: GetErrorMessageParams<T>) => {
  switch (queryResult) {
    case 'error':
      return 'Data loading error'
    case 'forbidden':
      return requiredPermission
        ? `Permission "${requiredPermission.value}" is missing`
        : 'Required permission is missing'
    case 'success':
      return itemId && !itemMap.get(itemId)
        ? `There isn't any item with id ${itemId}`
        : undefined
    default:
    case 'loading':
      return undefined
  }
}
