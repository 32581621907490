import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { Group } from 'api/idave/groups'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'

export const Actions = ({ group }: { group: Group }) => {
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { showSuccessToast } = useToasts()
  const { hideLoadingPopup, showLoadingPopup } = useLoadingPopup()
  const { setPopup, closePopup } = useConfirmationPopup()
  const { showErrorPopup } = useErrorPopup()

  const { mutate: deleteGroup, isLoading: isDeleting } = useMutation({
    mutationFn: idaveApi.groups.deleteGroup,
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      navigate(Url.SystemGroups)
      showSuccessToast(`Group ${group.name} deleted`)
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Groups],
      })
    },
    onError: (error) => {
      hideLoadingPopup()
      showErrorPopup({
        title: `Group ${group.name} deletion failed`,
        error,
      })
      closePopup()
    },
  })

  const onDeleteClick = useCallback(() => {
    setPopup({
      title: `Delete group ${group.name}`,
      message: `Are you sure you to delete group ${group.name}?`,
      confirmButtonText: 'Delete',
      onConfirmClick: () => deleteGroup(group.id),
    })
  }, [deleteGroup, group.id, group.name, setPopup])

  const onEditClick = useCallback(
    () => navigate(generatePath(Url.SystemGroupEdit, { groupId: group.id })),
    [navigate, group.id],
  )

  return (
    <PermissionsCheck
      somePermissions={[IDAVE_PERMISSIONS.GROUPS_UPDATE, IDAVE_PERMISSIONS.GROUPS_DELETE]}
    >
      <MoreBar>
        <PermissionsCheck permission={IDAVE_PERMISSIONS.GROUPS_UPDATE}>
          <MoreBar.Action useIcon="Pencil" onClick={onEditClick}>
            Edit
          </MoreBar.Action>
        </PermissionsCheck>

        <PermissionsCheck permission={IDAVE_PERMISSIONS.GROUPS_DELETE}>
          <MoreBar.Action
            useIcon="Delete"
            onClick={onDeleteClick}
            disabled={isDeleting}
            pending={isDeleting}
            variant="negative"
          >
            Delete
          </MoreBar.Action>
        </PermissionsCheck>
      </MoreBar>
    </PermissionsCheck>
  )
}
