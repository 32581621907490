import { AddEmployeeModifier } from 'api/dart/types'

export const getAccessModifiers = (params: {
  employeeId?: string
  subjectId?: string
}): AddEmployeeModifier[] | undefined => {
  const { subjectId, employeeId } = params

  if (!subjectId || !employeeId) {
    return undefined
  }

  return [
    {
      employeeId,
      accessModifier: {
        type: 'ALLOWED',
        entityId: {
          id: subjectId,
          type: 'COMPANY',
        },
      },
    },
  ]
}
