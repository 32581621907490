import { Navigate } from 'react-router-dom'
import { useCurrentUserId } from 'queries/idave/users'
import { Spinner } from '@revolut/ui-kit'
import { QuerySwitch } from 'components/QuerySwitch'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { RequestErrorWidget } from 'components/RequestErrorWidget'

export const Home = () => {
  const { data, status, fetchStatus } = useCurrentUserId()

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={data}
      renderLoading={() => <Spinner />}
      renderIdle={() => <RequestErrorWidget />}
      renderSuccess={({ data: userId }) => (
        <Navigate to={generatePath(Url.User, { userId })} />
      )}
    />
  )
}
