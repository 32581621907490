import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { Role } from 'api/idave/roles'
import { QueryKey } from 'helpers/configQuery'
import { IDAVE_PERMISSIONS } from 'security'
import { useToasts } from 'hooks/useToasts'
import { getCrossCheckIdFromResponse } from 'helpers/utils'
import { useErrorToast } from 'hooks/useErrorToast'
import { isAxiosError } from 'axios'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { PermissionIdPair } from 'view/Permissions/types'
import { normalizeRole } from '../../utils'
import { getToastText } from './utils'

export type MutatePermissions = { permissions: string[]; type: 'add' | 'delete' }

export const useEditRolePermissions = (params: {
  role: Role
  onMutate: () => void
  onSuccess: () => void
  onError: (error: unknown) => void
  onErrorToxicPair: (toxicPairs: PermissionIdPair[]) => void
}) => {
  const { role, onMutate, onSuccess, onError, onErrorToxicPair } = params
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    IDAVE_PERMISSIONS.ROLES_UPDATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_ROLE_UPDATE_ACTION,
  )
  const errorHandler = useErrorToast()
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()

  return useMutation({
    mutationFn: (mutation: MutatePermissions) => {
      const { permissions } = mutation
      return idaveApi.roles.editRole({
        payload: {
          ...normalizeRole(role),
          permissions,
        },
        crossCheck: isCrossCheck,
      })
    },
    onMutate,
    onSuccess: (response, mutation) => {
      onSuccess()

      const crosscheckId = getCrossCheckIdFromResponse(response)
      if (isCrossCheck && crosscheckId) {
        toCrosscheck(crosscheckId)
      } else {
        showSuccessToast(getToastText(mutation.type, mutation.permissions.length))
        queryClient.invalidateQueries({
          queryKey: [QueryKey.Roles],
          refetchType: 'all',
        })
        queryClient.invalidateQueries({ queryKey: [QueryKey.Role, role.id] })
        queryClient.invalidateQueries({ queryKey: [QueryKey.AuditLogs, role.id] })
      }
    },
    onError: (err) => {
      onError(err)
      if (isAxiosError(err) && err.response?.data.combinations) {
        onErrorToxicPair(err.response?.data.combinations)
      } else {
        errorHandler(err)
      }
    },
  })
}
