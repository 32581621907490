import { xor } from 'utils/boolean/xor'
import { OrganisationData, EditOrganisationPayload } from 'api/idave/organisations'
import { OrganisationEditFormState } from './types'

export const isOrgValid = (org: OrganisationEditFormState) => {
  const { code, description, name, reference, origin } = org
  const externalRefValid = !xor(reference, origin)

  return !!code && !!description && !!name && externalRefValid
}

export const getOrgFormState = (org: OrganisationData): OrganisationEditFormState => {
  return {
    code: org.code,
    description: org.description,
    name: org.name,
    origin: org.externalRef?.origin,
    reference: org.externalRef?.reference,
  }
}

export const getOrgPayload = (
  orgForm: OrganisationEditFormState,
): EditOrganisationPayload => {
  const { code, description, name, origin, reference } = orgForm
  const externalRef =
    origin && reference
      ? {
          origin,
          reference,
        }
      : undefined

  return {
    code,
    description,
    name,
    externalRef,
  }
}
