import {
  Avatar,
  Caption,
  Ellipsis,
  HStack,
  SelectOptionItemType,
  Text,
  Token,
  VStack,
  abbreviate,
} from '@revolut/ui-kit'
import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'

export const makeRenderOption =
  (avatars?: Map<string, RevolutersAvatar>) =>
  (option: SelectOptionItemType<UserListItem>) =>
    (
      <HStack space="s-12" align="center">
        <Avatar
          uuid={option.value.id}
          label={abbreviate(getName(option.value))}
          image={getAvatarUrl({
            user: option.value,
            avatars,
            type: 'imageMedium',
          })}
        />
        <VStack>
          <Text use="p">{getName(option.value)}</Text>
          <Caption color={Token.color.greyTone50}>
            <Ellipsis>{option.value.email}</Ellipsis>
          </Caption>
        </VStack>
      </HStack>
    )

export const renderIcon = (
  user?: UserListItem,
  avatars?: Map<string, RevolutersAvatar>,
) =>
  user ? (
    <Avatar
      uuid={user.id}
      label={abbreviate(getName(user))}
      image={getAvatarUrl({ user, avatars })}
      size={24}
    />
  ) : (
    'Profile'
  )
