import { SamPolicySubject, SamPolicySubjectType } from 'api/sam/policies'
import { SamResource } from 'api/sam/resources'
import { toPresenceMap } from 'utils/array/toPresenceMap'

export const stringifySubjectType = (subjectType: SamPolicySubjectType) => {
  const mapping: Record<SamPolicySubjectType, string> = {
    [SamPolicySubjectType.Employee]: 'Employee',
    [SamPolicySubjectType.EmployeeType]: 'Employee type',
    [SamPolicySubjectType.Team]: 'Team',
    [SamPolicySubjectType.Specialisation]: 'Specialisation',
    [SamPolicySubjectType.Department]: 'Department',
  }

  return mapping[subjectType]
}

export const normalizeResources = (resourceIds: string[], resources: SamResource[]) => {
  const resourcePresence = toPresenceMap(resources.map((r) => r.resourceId))
  return resourceIds.filter((id) => resourcePresence[id])
}

export const getSpecIds = (subject: SamPolicySubject) => {
  if (
    subject.subjectType === SamPolicySubjectType.Team ||
    subject.subjectType === SamPolicySubjectType.Department ||
    subject.subjectType === SamPolicySubjectType.Specialisation
  ) {
    return subject.specialisationIds || []
  }

  return []
}

export const getSeniorityIds = (subject: SamPolicySubject) => {
  if (
    subject.subjectType === SamPolicySubjectType.Team ||
    subject.subjectType === SamPolicySubjectType.Department ||
    subject.subjectType === SamPolicySubjectType.Specialisation
  ) {
    return subject.seniorityIds || []
  }

  return []
}

export const getTeamIds = (subject: SamPolicySubject) => {
  if (subject.subjectType === SamPolicySubjectType.Team) {
    return subject.teamIds
  }

  return []
}

export const getEmployeeTypes = (subject: SamPolicySubject) => {
  if (
    subject.subjectType === SamPolicySubjectType.Team ||
    subject.subjectType === SamPolicySubjectType.Department ||
    subject.subjectType === SamPolicySubjectType.Specialisation ||
    subject.subjectType === SamPolicySubjectType.EmployeeType
  ) {
    return subject.employeeTypes
  }

  return []
}

export const getEmployeeIds = (subject: SamPolicySubject) => {
  if (subject.subjectType === SamPolicySubjectType.Employee) {
    return subject.employeeIds
  }

  return []
}
