import { useQueryEmployeeMap } from 'queries/sam/users'
import {
  useQueryDepartmentMap,
  useQuerySpecMap,
  useQueryTeamMap,
} from 'queries/sam/revoluters'
import { useCallback } from 'react'
import { QueryResult, mergeQueryStatuses } from 'utils/query'
import {
  RevolutersDepartment,
  RevolutersEmployee,
  RevolutersSpecialisation,
  RevolutersTeam,
} from 'api/sam/revoluters'
import { useLoadingToast } from './useLoadingToast'

type EmployeeRevoDescription = {
  team?: RevolutersTeam
  department?: RevolutersDepartment
  spec?: RevolutersSpecialisation
}

export type EmployeeRevoDescriptionGetter = (userId?: string) => EmployeeRevoDescription

export type UseEmployeeRevoDescriptionResult = {
  getEmployeeDescription: EmployeeRevoDescriptionGetter
  status: QueryResult
}

export const useGetEmployeeRevoDescription = (params?: { timeout?: number }) => {
  const {
    data: teamMap = new Map(),
    status: revolutersQS,
    fetchStatus: revolutersFS,
  } = useQueryTeamMap()

  const { data: departmentMap = new Map() } = useQueryDepartmentMap()
  const { data: specMap = new Map() } = useQuerySpecMap()
  const {
    data: employeeMap = new Map<string, RevolutersEmployee>(),
    status: employeesS,
    fetchStatus: employeesFS,
  } = useQueryEmployeeMap()
  const queryStatuses = [
    { qs: revolutersQS, fs: revolutersFS },
    { qs: employeesS, fs: employeesFS },
  ]

  useLoadingToast(queryStatuses, "Just a little longer, we're loading", params?.timeout)

  const getEmployeeDescription = useCallback(
    (userId?: string): EmployeeRevoDescription => {
      const employee = userId ? employeeMap.get(userId) : undefined
      if (!employee) {
        return {}
      }
      const { teamId, departmentId, specialisationId } = employee

      return {
        team: teamId ? teamMap.get(teamId) : undefined,
        department: departmentId ? departmentMap.get(departmentId) : undefined,
        spec: specialisationId ? specMap.get(specialisationId) : undefined,
      }
    },
    [employeeMap, teamMap, departmentMap, specMap],
  )

  return {
    status: mergeQueryStatuses(...queryStatuses),
    getEmployeeDescription,
  }
}
