import { Avatar, Item, Token } from '@revolut/ui-kit'
import { EntityListProps } from '../types'

export const EmptyEntityList = ({
  onAddClick,
  addActionLabel,
  addEnabled,
}: EntityListProps) => (
  <Item use="button" onClick={onAddClick} disabled={!addEnabled} inactive={!addEnabled}>
    <Item.Avatar>
      <Avatar useIcon="Plus" />
    </Item.Avatar>
    <Item.Content>
      <Item.Title color={Token.color.blue}>{addActionLabel}</Item.Title>
    </Item.Content>
  </Item>
)
