import { Box, Subheader, VStack } from '@revolut/ui-kit'
import { EntityDescription } from 'components/EntityDescription'
import { useQueryPermission } from 'queries/idave/permissions'
import { DetailsGroup } from 'components/DetailsGroup'
import { QuerySwitch } from 'components/QuerySwitch'
import { Permission } from 'api/idave/permissions'
import { ClientLink } from 'components/Links/ClientLink'
import { CopyableText } from 'components/CopyableText'
import { EntitySkeleton } from 'components/EntitySkeleton'

type Props = {
  permissionId: string
}

export const PermissionInfo = ({ permissionId }: Props) => {
  const {
    data: permission,
    fetchStatus: fs,
    status: qs,
  } = useQueryPermission(permissionId)

  return (
    <QuerySwitch
      data={permission}
      required={[{ qs, fs }]}
      renderLoading={() => (
        <EntitySkeleton hasActions={false} variant="details" title="Details" />
      )}
      renderSuccess={({ data }) => <Inner permission={data} />}
    />
  )
}

const Inner = ({ permission }: { permission: Permission }) => {
  return (
    <VStack space="s-8">
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>
        <DetailsGroup
          rows={[
            ['Name', permission.name],
            [
              'Application',
              <ClientLink id={permission.client} key={permission.client} />,
            ],
            ['ID', <CopyableText value={permission.id} key={permission.id} />],
          ]}
        />
      </Box>
      <EntityDescription description={permission.description} />
    </VStack>
  )
}
