import { SamPatchPolicyPayload, SamPolicy } from 'api/sam/policies'
import { uniq } from 'lodash'

export const getTrainingUpdateParams = (
  policyIds: string[],
  policyMap: Map<string, SamPolicy>,
  trainings: string[],
) => {
  return policyIds.reduce<{ policyId: string; data: SamPatchPolicyPayload }[]>(
    (acc, policyId) => {
      const policy = policyMap.get(policyId)
      if (!policy) {
        return acc
      }
      return [
        ...acc,
        {
          policyId,
          data: {
            policyName: policy.policyName,
            businessReason: policy.businessReason,
            mandatoryTrainings: uniq([
              ...(policy.mandatoryTrainings || []),
              ...trainings,
            ]),
          },
        },
      ]
    },
    [],
  )
}
