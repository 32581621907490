import { Box, ProgressSteps, Subheader, Widget } from '@revolut/ui-kit'
import { useQueryUserIdMap, useQueryUserPeopleOpsIdMap } from 'queries/idave/users'
import { isAllSettled } from 'utils/query'
import { UserListItem } from 'api/idave/user'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { CrossCheckApproversSkeleton } from './components/CrossCheckApproversSkeleton'
import { CrossCheckGroup } from './components/CrossCheckGroup'

type Props = {
  crossCheck?: CrossCheckDetail
  source: CrossCheckSystem
}

export const CrossCheckApprovers = (props: Props) => {
  const { crossCheck, source } = props
  const useUserMap = source === 'sam' ? useQueryUserPeopleOpsIdMap : useQueryUserIdMap
  const { data: userMap = new Map(), status: qs, fetchStatus: fs } = useUserMap()
  const isUserSettled = isAllSettled({ qs, fs })

  if (isUserSettled && crossCheck && !crossCheck.reviewerGroups.length) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Approval groups</Subheader.Title>
      </Subheader>
      {isUserSettled && crossCheck ? (
        <Inner crossCheck={crossCheck} userMap={userMap} source={source} />
      ) : (
        <CrossCheckApproversSkeleton />
      )}
    </Box>
  )
}

const Inner = ({
  crossCheck,
  userMap,
  source,
}: {
  crossCheck: CrossCheckDetail
  userMap: Map<string, UserListItem>
  source: CrossCheckSystem
}) => (
  <Widget>
    <ProgressSteps variant="vertical-compact">
      {crossCheck.reviewerGroups.map((group) => (
        <CrossCheckGroup
          group={group}
          userMap={userMap}
          crossCheck={crossCheck}
          key={group.groupId}
          source={source}
        />
      ))}
    </ProgressSteps>
  </Widget>
)
