import { useQuery } from '@tanstack/react-query'
import samApi from 'api/sam'
import { QueryKey } from 'helpers/configQuery'
import { makeMappedKeyDataHook } from 'queries/utils'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'

export const useQueryPolicies = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamPolicies],
    queryFn: () => samApi.policies.getPolicies(),
    enabled: hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_LIST),
    select: (response) => response.data?.data,
  })
}

export const useQueryResourcePolicies = (resourceId: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamPolicies, resourceId],
    queryFn: () => samApi.policies.getPolicies({ resourceId }),
    enabled: hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_LIST),
    select: (response) => response.data?.data,
  })
}

export const useQueryPolicyMap = makeMappedKeyDataHook(useQueryPolicies, 'policyId')

export const useQuerySamPolicy = (id?: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamPolicy, id],
    queryFn: () => {
      if (!id) {
        throw new Error('id is not set')
      }

      return samApi.policies.getPolicy(id)
    },
    select: (response) => response?.data,
    enabled: hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_DETAILS) && !!id,
  })
}
