import { Popup, Header, BottomSheet } from '@revolut/ui-kit'
import { EditPopupContextData, useEditPopup } from 'hooks/useEditPopup'
import React from 'react'

export const EditPopup = () => {
  const editPopup = useEditPopup()

  return <InnerPopup {...editPopup} />
}

const InnerPopup = (props: EditPopupContextData) => {
  const { popup, closePopup, scrollRef } = props
  if (!popup) {
    return null
  }

  const PopupElement = popup.isBottomSheet ? BottomSheet : Popup

  return (
    <PopupElement
      open
      onClose={closePopup}
      scrollRef={scrollRef}
      /**
       * because of focus fighting date input can't work properly
       * disabling focusTrap in this cases allows avoid it
       */
      focusTrap={popup.focusTrap}
      closeOnEsc={!!popup.focusTrap}
    >
      <Header variant={popup.headerVariant || 'item'}>
        <Header.CloseButton onClick={closePopup} />
        <Header.Title>{popup.title}</Header.Title>
      </Header>
      {React.cloneElement(popup.content)}
    </PopupElement>
  )
}
