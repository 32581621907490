import { IconButton, Tooltip, useTooltip } from '@revolut/ui-kit'

type ResetShrinkButtonProps = {
  onClick: () => void
  visible: boolean
}

export const ResetShrinkButton = ({ onClick, visible }: ResetShrinkButtonProps) => {
  const tooltip = useTooltip()

  return visible ? (
    <>
      <IconButton
        size={22}
        opacity={0.5}
        useIcon="ArrowRepeat"
        aria-label="Reset"
        onClick={onClick}
        {...tooltip.getAnchorProps()}
      />
      <Tooltip {...tooltip.getTargetProps()}>
        Reset table search, filters and sorting
      </Tooltip>
    </>
  ) : null
}
