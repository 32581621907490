import { Box, Group, ItemSkeleton, Subheader, TextSkeleton } from '@revolut/ui-kit'
import { times } from 'utils/array/times'

const SKELETONS = [
  {
    size: 16,
    userCount: 3,
  },
  {
    size: 7,
    userCount: 1,
  },
]

export const ParticipantListSkeleton = () => {
  return (
    <>
      {SKELETONS.map(({ size, userCount }, idx) => (
        <ParticipantListSkeletonItem key={idx} size={size} userCount={userCount} />
      ))}
    </>
  )
}

const ParticipantListSkeletonItem = (props: { size: number; userCount: number }) => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>
          <TextSkeleton size={props.size} />
        </Subheader.Title>
      </Subheader>
      <Group>
        {times(
          (idx) => (
            <ItemSkeleton key={idx} />
          ),
          props.userCount,
        )}
      </Group>
    </Box>
  )
}
