import { Client } from 'api/idave/clients'
import { Account } from 'api/idave/accounts'
import { capitalizeFirst } from 'utils/string'

type GetAccountRowsParams = {
  clientMap: Map<string, Client>
  accounts: Account[]
}
export const getAccountRows = ({ accounts, clientMap }: GetAccountRowsParams) => {
  return accounts.map(({ id, clientId, source, state }) => ({
    id,
    accountApp: clientMap.get(clientId)?.name || clientId,
    source: capitalizeFirst(source),
    state,
  }))
}
