import { QuerySwitch } from 'components/QuerySwitch'
import { useNavigate, generatePath } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'
import { TabBarLink } from 'components/TabBarLink'
import { Client } from 'api/idave/clients'
import { Account as TAccount } from 'api/idave/accounts'
import { TabBar, TextSkeleton, chain, Text, Token, Page } from '@revolut/ui-kit'
import { useQueryAccount, useQueryAccountCustomization } from 'queries/idave/accounts'
import { useQueryClient } from 'queries/idave/clients'
import { AccountTab } from 'view/Clients/Account/types'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { AccountSkeleton } from './components/Skeleton'
import { Details } from './components/Details'
import { Permissions } from './components/Permissions'
import { AccountAuditLog } from './components/AccountAuditLog'

type Props = {
  id: string
  tab: AccountTab
}

export const Account = ({ id, tab }: Props) => {
  const { data, fetchStatus: fs, status: qs } = useQueryAccount({ accountId: id })
  const {
    data: client,
    fetchStatus: clientFS,
    status: clientQS,
  } = useQueryClient(data?.clientId)
  const {
    data: customization,
    fetchStatus: customizationFS,
    status: customizationQS,
  } = useQueryAccountCustomization(id)

  return (
    <QuerySwitch
      required={[
        { qs, fs },
        { qs: clientQS, fs: clientFS },
      ]}
      optional={[
        {
          fs: customizationFS,
          qs: customizationQS,
        },
      ]}
      data={data}
      renderLoading={() => (
        <Outer id={id} client={client}>
          <AccountSkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data: account }) => (
        <Outer id={id} client={client} account={account}>
          {tab === 'details' && (
            <Details account={account} client={client} customization={customization} />
          )}
          {tab === 'permissions' && <Permissions account={account} />}
          {tab === 'auditLog' && <AccountAuditLog accountId={id} />}
        </Outer>
      )}
    />
  )
}

const Outer = ({
  id,
  client,
  children,
  account,
}: {
  id: string
  client?: Client
  account?: TAccount
  children: React.ReactNode
}) => {
  const navigate = useNavigate()

  return (
    <>
      <Page.Header onBack={() => navigate(Url.Accounts)}>
        {chain(
          client?.name || <TextSkeleton size={16} />,
          account?.state === 'TERMINATED' ? (
            <Text color={Token.color.red}>Terminated</Text>
          ) : undefined,
        )}
      </Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.CLIENTS_VIEW_DETAILS}>
            <TabBarLink to={generatePath(Url.AccountDetails, { accountId: id })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            everyPermission={[
              IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST,
              IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST,
            ]}
          >
            <TabBarLink to={generatePath(Url.AccountPermissions, { accountId: id })}>
              Permissions
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.AccountAuditLogs, { accountId: id })}>
              Audit log
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>{children}</Page.Main>
    </>
  )
}
