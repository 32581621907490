export const appendToQueryParams = (
  currentParams: URLSearchParams,
  values: Record<string, string | undefined>,
) => {
  const newParams = new URLSearchParams(currentParams.toString())
  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      newParams.set(key, value)
    }
  })

  return newParams
}
