import { Permission } from 'api/idave/permissions'
import { notNullableMap } from 'utils/array'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { Role } from 'api/idave/roles'
import { getRolePermissions } from 'view/Roles/Role/components/RolePermissions/utils'
import { sortBy } from 'lodash'
import { PermissionRow } from './types'

type GetRowsParams = {
  permissionIds: string[]
  permissionMap: Map<string, Permission>
  roleMap?: Map<string, Role>
  parent?: string
}

export const getRows = ({
  permissionMap,
  permissionIds,
  roleMap,
  parent,
}: GetRowsParams): PermissionRow[] => {
  const parentRole = parent ? roleMap?.get(parent) : undefined
  const inheritedPermissions =
    parentRole && roleMap
      ? getRolePermissions({
          role: parentRole,
          permissionMap,
          roleMap,
        })
      : []

  const currentRoleRows = notNullableMap<string, PermissionRow>(permissionIds, (id) => {
    const permission = permissionMap.get(id)
    if (!permission) {
      return { id, permissionId: id, name: id, clientId: EMPTY_VALUE_FALLBACK }
    }

    return {
      id: permission.id,
      permissionId: permission.id,
      name: permission.name,
      clientId: permission.client,
    }
  })

  return sortBy(currentRoleRows, 'name').concat(inheritedPermissions)
}
