import { Role } from 'api/idave/roles'

export type TraverseRoleParentsParams = {
  roleMap: Map<string, Role>
  roleId: string
}

/**
 * @returns list of role parents with the layer order
 * traverseRoleParents({roleId, roleMap}) => [..., grandGrandParent, grandParentRoleId, parentRoleId]
 */
export const traverseRoleParents = (params: TraverseRoleParentsParams) => {
  const { roleMap, roleId } = params
  const result: string[] = []
  let current: string | undefined = roleMap.get(roleId)?.parent

  while (current && !result.includes(current)) {
    result.unshift(current)
    current = roleMap.get(current)?.parent
  }

  return result
}
