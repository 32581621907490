import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { MoreBar } from '@revolut/ui-kit'
import { IDAVE_PERMISSIONS } from 'security'

type Props = {
  orphaned: boolean
  isLoading: boolean
  onClick: () => void
}
export const OrhapnedSwitcher = ({ orphaned, onClick, isLoading }: Props) => {
  return (
    <PermissionsCheck permission={IDAVE_PERMISSIONS.ORPHANED_PERMISSIONS_VIEW_LIST}>
      <MoreBar.Action
        useIcon={orphaned ? 'SwitchOn' : 'SwitchOff'}
        variant={orphaned ? 'accent' : 'primary'}
        onClick={onClick}
        disabled={isLoading}
        pending={isLoading}
      >
        Orphaned
      </MoreBar.Action>
    </PermissionsCheck>
  )
}
