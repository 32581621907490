import { useQueryGroups } from 'queries/idave/groups'
import { Flex, TextSkeleton } from '@revolut/ui-kit'
import { Group } from 'api/idave/groups'
import { UserLink } from 'components/Links/UserLink'
import { useMemo } from 'react'
import { QuerySwitch } from './QuerySwitch'

export const GroupOwners = ({ groupId }: { groupId: string }) => {
  const { data: groups = [], status: qs, fetchStatus: fs } = useQueryGroups()

  const group = useMemo(() => groups.find(({ id }) => id === groupId), [groupId, groups])

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={group}
      renderError={() => <GroupOwnersBase />}
      renderIdle={() => <GroupOwnersBase />}
      renderLoading={() => <TextSkeleton size={15} />}
      renderSuccess={({ data }) => <GroupOwnersBase group={data} />}
    />
  )
}

const GroupOwnersBase = ({ group }: { group?: Group }) => {
  return (
    <>
      {group?.ownerIds.length ? (
        <Flex gap="s-8" flexWrap="wrap" justifyContent="flex-end">
          {group.ownerIds.map((id) => (
            <UserLink key={id} id={id} />
          ))}
        </Flex>
      ) : (
        'No owner'
      )}
    </>
  )
}
