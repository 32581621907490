import { TOWER_URL } from 'constants/urls'
import { ExternalTextLink } from './ExternalTextLink'

type Props = {
  component: string
}

export const TowerComponentLink = ({ component }: Props) => {
  return (
    <ExternalTextLink text={component} href={`${TOWER_URL}/components/${component}`} />
  )
}
