import { Text } from '@revolut/ui-kit'
import { ExternalTextLink } from 'components/Links/ExternalTextLink'
import { Token } from 'hooks/useTokenize/types'
import { EntityTokenLink } from './EntityLinkToken'

export const TokenText = (props: { token: Token }) => {
  const { token } = props

  switch (token.type) {
    case 'text': {
      return <Text>{token.value}</Text>
    }
    case 'external_link': {
      return (
        <ExternalTextLink href={token.value} text={token.text || token.value} noSpace />
      )
    }

    case 'internal_link': {
      return <EntityTokenLink token={token} />
    }

    default:
      return null
  }
}
