import { TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { UserTableCell, useUserTableCellAccessor } from 'components/UserTableCell'
import { useMemo } from 'react'
import { UserRow } from './types'

export const useColumns = (params: { isLoading: boolean }): TableColumn<UserRow>[] => {
  const { isLoading } = params
  const getUserName = useUserTableCellAccessor()

  return useMemo(() => {
    const loadingFilter = isLoading
      ? undefined
      : {
          filter: 'includesValue' as const,
          Filter: SelectItemFilter,
        }

    const loadingCell = isLoading
      ? {
          Cell: () => <TextSkeleton size={16} />,
        }
      : undefined

    return [
      {
        Header: 'Name',
        id: 'name',
        accessor: ({ id }) => getUserName(id),
        Cell: (cell: { row: { original: UserRow } }) => (
          <UserTableCell userId={cell.row.original.id} />
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Department',
        accessor: ({ department }) => department || EMPTY_VALUE_FALLBACK,
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'Team',
        accessor: ({ team }) => team || EMPTY_VALUE_FALLBACK,
        ...loadingCell,
        ...loadingFilter,
      },
    ]
  }, [isLoading, getUserName])
}
