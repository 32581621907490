import '@revolut/ui-kit/styles.css'
import React from 'react'
import { useCallback } from 'react'
import { useMenu } from 'helpers/hooks/useMenu'
import { AppId, BackofficeProvider } from '@revolut-internal/backoffice-ui-provider'
import { Url } from 'routing'
import { useMenuAuth } from 'helpers/hooks/useMenuAuth'
import { useNavigate } from 'react-router'
import { auth } from 'services/auth'
import { RootProvider, TopNav } from '@revolut/ui-kit'
import { getCookieMode, ThemeMode } from 'utils/common/getCookieMode'

export const KitProvider = (props: { children?: React.ReactNode }) => {
  const Provider = auth.getUser() ? CustomizedProvider : EmptyRootProvider

  return <Provider mode={getCookieMode()}>{props.children}</Provider>
}

const EmptyRootProvider = (props: { children?: React.ReactNode; mode?: ThemeMode }) => {
  return (
    <RootProvider
      mode={props.mode}
      topNav={{
        title: '',
        appSwitcher: <></>,
        logo: <></>,
        profile: <></>,
      }}
    >
      {props.children}
    </RootProvider>
  )
}

const CustomizedProvider = (props: { children?: React.ReactNode; mode?: ThemeMode }) => {
  const menuItems = useMenu()
  const menuAuth = useMenuAuth()

  const navigate = useNavigate()
  const onProfileClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault()
      navigate(Url.Home)
    },
    [navigate],
  )

  return (
    <BackofficeProvider
      menu={<TopNav.Menu items={menuItems} />}
      appId={AppId.IDave}
      defaultRoute={Url.Home}
      auth={menuAuth}
      onProfileClick={onProfileClick}
    >
      {props.children}
    </BackofficeProvider>
  )
}
