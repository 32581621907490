import { Tab } from './FormMultiStepTabs'

export const isBackwardDisabled = <TabName extends string>(
  tabs: Tab<TabName>[],
  currentTab: TabName,
) => {
  return !getPrevTabIfPresents(tabs, currentTab)
}

export const isForwardDisabled = <TabName extends string>(
  tabs: Tab<TabName>[],
  checkedTabs: TabName[],
  currentTab: TabName,
) => {
  if (isLastTab(tabs, currentTab)) {
    return tabs.some(({ value }) => !checkedTabs.includes(value))
  }
  return !checkedTabs.includes(currentTab)
}

export const getActiveTabs = <TabName extends string>(
  tabs: Tab<TabName>[],
  checkedTabs: TabName[],
) => {
  // 1st is active
  // Nth is active if Nth-1 is checked
  return tabs.reduce<TabName[]>((activeTabs, { value }, idx) => {
    if (idx === 0) {
      return [value]
    }

    const previousTab = tabs[idx - 1]?.value

    if (checkedTabs.includes(previousTab)) {
      return [...activeTabs, value]
    }

    return activeTabs
  }, [])
}

export const isLastTab = <TabName extends string>(
  tabs: Tab<TabName>[],
  currentTab: TabName,
) => {
  return tabs[tabs.length - 1]?.value === currentTab
}

export const getNextTabIfActive = <TabName extends string>(
  tabs: Tab<TabName>[],
  activeTabs: TabName[],
  currentTab: TabName,
) => {
  const currentIdx = tabs.findIndex(({ value }) => value === currentTab)
  if (currentIdx === -1) {
    return undefined
  }

  const nextTab = tabs[currentIdx + 1]

  return nextTab && activeTabs.find((tab) => tab === nextTab.value)
}

export const getPrevTabIfPresents = <TabName extends string>(
  tabs: Tab<TabName>[],
  currentTab: TabName,
) => {
  const currentIdx = tabs.findIndex(({ value }) => value === currentTab)
  const prevTab = tabs[currentIdx - 1]

  return prevTab?.value
}

export const getCheckedTabs = <TabName extends string>(
  tabs: Tab<TabName>[],
  visitedTabs: TabName[],
  checkTabFilled: (tab: TabName) => boolean,
  checkTabValid: (tab: TabName) => boolean,
) =>
  tabs
    .map(({ value }) => value)
    .filter(
      (value) =>
        checkTabFilled(value) && checkTabValid(value) && visitedTabs.includes(value),
    )
