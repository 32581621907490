import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { useQueryOrphanedPermissions } from 'queries/idave/permissions'
import { useQueryPermissions } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { waitForAll } from 'utils/query'
import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { Permission } from './types'
import { COLUMNS } from './columns'
import { OrhapnedSwitcher } from './component/OrphanedSwitcher'
import { usePermissionList } from './usePermissionList'

export const PermissionList = () => {
  const navigate = useNavigate()
  const {
    data: permissions = [],
    status: permissionsS,
    fetchStatus: permissionFS,
  } = useQueryPermissions()
  const {
    data: clientMap = new Map(),
    status: clientS,
    fetchStatus: clientFS,
  } = useQueryClientIdMap()

  const {
    data: orphans = [],
    status: orphanedQS,
    fetchStatus: orphanedFS,
  } = useQueryOrphanedPermissions()

  const [showOrphaned, setShowOrphaned] = useState(false)
  const onOrphanedClick = useCallback(() => setShowOrphaned((v) => !v), [setShowOrphaned])
  const { data, entitiesCount, onSearchChange, searchValue } = usePermissionList({
    clientMap,
    orphans,
    permissions,
    showOrphaned,
  })

  const state = waitForAll(
    [{ qs: permissionsS, fs: permissionFS }],
    [
      { qs: clientS, fs: clientFS },
      {
        qs: orphanedQS,
        fs: orphanedFS,
      },
    ],
  )
  const loadingState = getLoadingState(state, entitiesCount)

  const onItemClick = useCallback(
    ({ id }: Permission) => {
      navigate(generatePath(Url.Permission, { permissionId: id }))
    },
    [navigate],
  )

  const getRowLink = useCallback(
    (permission: Permission) =>
      generatePath(Url.Permission, { permissionId: permission.id }),
    [],
  )

  return (
    <EntitiesTable
      enableNavigation
      data={data}
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      totalCount={entitiesCount}
      entitiesTypeLabel="Permissions"
      pluralForms={['permission', 'permissions']}
      columns={COLUMNS}
      loadingState={loadingState}
      onRowClick={onItemClick}
      searchAutoFocus
      getRowLink={getRowLink}
      renderActions={() => (
        <OrhapnedSwitcher
          orphaned={showOrphaned}
          onClick={onOrphanedClick}
          isLoading={state === 'loading'}
        />
      )}
    />
  )
}
