import { CrossCheckDetail } from 'api/types/crossCheck'
import { useQueryEmployeeDetails } from 'queries/dart/employees'
import { useQueryCustomerCompanyMap } from 'queries/dart/companies'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { useMemo, useState } from 'react'
import { Box, Subheader, Table } from '@revolut/ui-kit'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { makeGetChangeRowState } from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/utils'
import { EmployeeDetails } from 'api/dart/employee'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../../../ChangeSwitcher'
import { getAccessModifierRows, getEmptyTableLabel } from './utils'
import { getColumns } from './columns'

export const CustomerCompaniesOfEmployee = ({
  crossCheck,
}: {
  crossCheck: CrossCheckDetail
}) => {
  const {
    data: customerCompaniesMap,
    status: customerCompaniesS,
    fetchStatus: customerCompaniesFS,
  } = useQueryCustomerCompanyMap()

  const {
    data: employeeDetails,
    status: employeeDetailsS,
    fetchStatus: employeeDetailsFS,
  } = useQueryEmployeeDetails(crossCheck.entityId)

  return (
    <QuerySwitch
      required={[
        { qs: customerCompaniesS, fs: customerCompaniesFS },
        { qs: employeeDetailsS, fs: employeeDetailsFS },
      ]}
      data
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view this data" />
      )}
      renderLoading={() => <Inner crossCheck={crossCheck} />}
      renderSuccess={() => (
        <Inner
          crossCheck={crossCheck}
          customerCompaniesMap={customerCompaniesMap}
          employeeDetails={employeeDetails}
        />
      )}
    />
  )
}

const Inner = ({
  crossCheck,
  employeeDetails,
  customerCompaniesMap = new Map(),
}: {
  crossCheck: CrossCheckDetail
  employeeDetails?: EmployeeDetails
  customerCompaniesMap?: Map<string, CustomerCompanyItem>
}) => {
  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])

  const { rows, counters } = useMemo(
    () =>
      getAccessModifierRows({
        crossCheck,
        employeeDetails,
        customerCompaniesMap,
        tabs,
      }),
    [crossCheck, customerCompaniesMap, employeeDetails, tabs],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState(defaultTab)
  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])

  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])
  const data = rows[tab]
  const isLoading = !employeeDetails

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>
          Customer companies
        </Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            tabs={tabs}
            currentTab={tab}
            counters={counters}
            onChange={setTab}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        loadingState={isLoading ? 'pending' : 'ready'}
        getRowState={getRowState}
        labelEmptyState={getEmptyTableLabel(tab)}
      />
    </Box>
  )
}
