import { ActionResponse } from 'api/types/actionResponse'
import axios, { AxiosPromise } from 'axios'

export type Permission = {
  id: string
  name: string
  description?: string
  client: string
  createdDate: number
}

export type OrphanPermission = {
  permission: string
  createdDate: string
}

export type RoleByPermission = {
  id: string
  name: string
  parent: string
}

export type EditPermissionDescription = { description: string }

const permissions = {
  getPermissions: (): AxiosPromise<Permission[]> => axios.get(`/api/permissions`),

  getOrphanedPermissions: (): AxiosPromise<OrphanPermission[]> =>
    axios.get(`/api/orphaned-permissions`),

  getPermission: (permissionId: string): AxiosPromise<Permission> =>
    axios.get(`/api/permissions/${permissionId}`),

  getRolesByPermission: (permissionId: string): AxiosPromise<RoleByPermission[]> =>
    axios.get(`/api/permissions/${permissionId}/roles`),

  editDescription: (
    permissionId: string,
    data: EditPermissionDescription,
  ): AxiosPromise<ActionResponse> =>
    axios.patch(`/api/permissions/${permissionId}/description`, data),
}

export default permissions
