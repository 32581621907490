import { TableFilters } from '@revolut/ui-kit'
import { QueryResult } from 'utils/query'
import { pluralForm } from 'utils/string'

/**
 * Returns entities selected by selectedHash value
 */
export const filterSelectedEntities = <Entity extends { id: string }>(
  entities: readonly Entity[],
  selectedHash: Record<string, unknown>,
) => entities.filter((item) => selectedHash[item.id])

/**
 * Returns selected ids from selectedHash value
 */
export const filterActiveIds = (selectedHash: Record<string, unknown>) =>
  Object.keys(selectedHash).filter((id) => selectedHash[id])

/**
 * Returns selected values from selectedHash value
 */
export const filterActiveValues = <Entity,>(
  selectedHash: Record<string, Entity>,
): NonNullable<Entity>[] =>
  Object.values(selectedHash).filter((value): value is NonNullable<Entity> => !!value)

/**
 * Returns unselected entitie's ids
 */
export const filterInactiveIds = <Entity extends { id: string }>(
  entities: Entity[],
  selectedHash: Record<string, unknown>,
) => entities.filter((item) => !selectedHash[item.id]).map((i) => i.id)

/**
 * Defines is event will be processed as Open in new tab
 */
export const openInOtherTab = (event: React.MouseEvent) =>
  event.ctrlKey || event.metaKey || event.button === 1

/**
 * Opens new tab with link
 */
export const openLink = (link: string, inOtherTab: boolean) => {
  const handle = window.open(link, '_blank')
  if (inOtherTab) {
    handle?.blur()
    window.focus()
  }
}

/**
 * Splits array in already selected and available for select parts
 */
export const splitSelectedAvailable = <Entity extends { id: string }>(
  entities: Entity[],
  selectedIds: string[],
) =>
  entities.reduce<{
    selected: Entity[]
    available: Entity[]
  }>(
    (acc, entity) => {
      if (selectedIds.includes(entity.id)) {
        return {
          ...acc,
          selected: [...acc.selected, entity],
        }
      }

      return {
        ...acc,
        available: [...acc.available, entity],
      }
    },
    { selected: [], available: [] },
  )

export const getLoadingState = (loadingStatus: QueryResult, entitiesCount: number) => {
  switch (loadingStatus) {
    case 'loading':
      return 'pending'
    case 'success':
      return !entitiesCount ? 'no-results' : 'ready'
    default:
      return 'failed'
  }
}

export const isFiltered = <Entity extends {}>(filters?: TableFilters<Entity>) => {
  if (!filters?.length) {
    return false
  }

  return filters.some((filter) => filter.value.length)
}

type GetShowedCountParams = {
  count?: number
  pluralForms?: [string, string]
  entitiesTypeLabel: string
}

export const getShowedCountLabel = ({
  count,
  pluralForms,
  entitiesTypeLabel,
}: GetShowedCountParams) => {
  if (typeof count !== 'number' || !pluralForms?.length) {
    return entitiesTypeLabel
  }

  return pluralForm(
    count,
    // ts can't infer arr length from map
    pluralForms.map((entityLabelForm) => `Showing ${count} ${entityLabelForm}`) as [
      string,
      string,
    ],
  )
}
