import { CheckboxSelect, Table } from '@revolut/ui-kit'
import { useMemo } from 'react'
import { Row, ColumnInstance } from 'react-table'

export const SelectItemFilter = <Entity extends object>(table: {
  preFilteredRows: Array<Row<Entity>>
  column: ColumnInstance<Entity>
}) => {
  const { preFilteredRows, column } = table
  const options = useMemo(() => {
    const unique = new Set<string | number>()

    preFilteredRows.forEach(
      (row) => row.values[column.id] && unique.add(row.values[column.id]),
    )

    // already active filter items (e.g from search params)
    column.filterValue?.forEach((value: unknown) => {
      if (typeof value === 'string' || typeof value === 'number') {
        unique.add(value)
      }
    })

    return Array.from(unique)
      .sort((a, b) => {
        if (!isNaN(Number(a)) && !isNaN(Number(b))) {
          return Number(a) - Number(b)
        }

        return a.toLocaleString().localeCompare(b.toLocaleString())
      })
      .map((value) => ({
        key: value,
        label: value,
        value,
      }))
  }, [preFilteredRows, column])
  return (
    <Table.Filter column={column} aria-label="Open filter" aria-haspopup="listbox">
      {(filter) => (
        <CheckboxSelect
          type="confirm"
          {...filter.getCheckboxSelectProps({
            options,
            searchable: true,
            labelList: 'Select',
            labelApply: 'Apply',
            labelClear: 'Clear',
            labelNoResults: 'No results found',
          })}
        />
      )}
    </Table.Filter>
  )
}
