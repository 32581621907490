import { Avatar, Group, Item, ItemSkeleton } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { generatePath } from 'react-router'
import { Url } from 'routing'

export const ClientScopes = ({ clients }: { clients: string[] }) => {
  const { data, status: qs, fetchStatus: fs } = useQueryClientIdMap()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderLoading={() => (
        <Group>
          {clients.map((id) => (
            <AccountClientScope key={id} id={id} clientMap={data} loading />
          ))}
        </Group>
      )}
      renderSuccess={({ data: clientMap }) => (
        <Group>
          {clients.map((id) => (
            <AccountClientScope key={id} id={id} clientMap={clientMap} />
          ))}
        </Group>
      )}
    />
  )
}

const AccountClientScope = ({
  id,
  loading,
  clientMap,
}: {
  id: string
  loading?: boolean
  clientMap?: Map<string, Client>
}) => {
  const Scope = loading ? ItemSkeleton : Item

  return (
    <Scope
      key={id}
      use="a"
      href={generatePath(Url.Client, { clientId: id })}
      target="_blank"
    >
      <Scope.Avatar>
        <Avatar useIcon="Apps" />
      </Scope.Avatar>
      <Scope.Content>
        <Scope.Title>{clientMap?.get(id)?.name || id}</Scope.Title>
      </Scope.Content>
    </Scope>
  )
}
