import { UserListItem } from 'api/idave/user'
import { RevolutersEmployee, RevolutersTeam } from 'api/sam/revoluters'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getRevolutersTeamLink } from 'components/Links/RevolutersTeamLink'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'

export const getUserData = (params: {
  avatars?: Map<string, RevolutersAvatar>
  user?: UserListItem
  teams?: RevolutersTeam[]
  employees?: RevolutersEmployee[]
  requesterId: string
}) => {
  const { avatars, user, teams, requesterId, employees } = params

  if (!user) {
    return {
      name: requesterId,
      id: requesterId,
    }
  }

  const employee = employees?.find((e) => e.revolutersId === user.externalRef?.reference)
  const team = employee?.teamId ? teams?.find((t) => t.id === employee.teamId) : undefined

  return {
    name: user ? getName(user) : requesterId,
    photoUrl: getAvatarUrl({ user, avatars }),
    team: team?.name,
    teamHref: team ? getRevolutersTeamLink(team.id) : undefined,
    state: user.state,
  }
}
