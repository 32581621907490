import {
  Token,
  Status,
  StatusCellLevels,
  StatusCellProps,
  StatusProps,
} from '@revolut/ui-kit'

const LEVEL_COLORS = {
  [StatusCellLevels.SUCCESS]: Token.color.green,
  [StatusCellLevels.MODERATE]: Token.color.yellow,
  [StatusCellLevels.WARNING]: Token.color.orange,
  [StatusCellLevels.ALERT]: Token.color.red,
  [StatusCellLevels.DISABLED]: Token.color.grey50,
}

export const StatusText = ({
  level,
  children,
  ...rest
}: StatusProps & Pick<StatusCellProps, 'level'>) => (
  <Status color={LEVEL_COLORS[level]} {...rest}>
    {children}
  </Status>
)
