import { Box } from '@revolut/ui-kit'
import { captureException } from '@sentry/browser'
import { ErrorStatus } from 'components/ErrorStatus'
import { useEffect } from 'react'
import { useRouteError } from 'react-router'

export const ErrorElement = () => {
  const error = useRouteError()

  useEffect(() => {
    captureException(error || new Error('Unknown error'))
  }, [error])

  return (
    <Box pt="s-64" pl="s-32" pr="s-32">
      <ErrorStatus />
    </Box>
  )
}
