import { Token } from '../types'

type MakeTextTokenParams = {
  text: string
  from?: number
  to?: number
}
export const makeTextToken = ({ text, from, to }: MakeTextTokenParams): Token => ({
  type: 'text',
  value: text.slice(from, to),
})
