import { Text, TextSkeleton } from '@revolut/ui-kit'
import { mergeQueryStatuses } from 'utils/query'
import { useQueryToxicPairsMap } from 'queries/idave/permissions'
import { AlertDescriptor } from '../types'

type Props = {
  toxicAlertDescriptor: AlertDescriptor
}

export const Title = ({ toxicAlertDescriptor }: Props) => {
  const { toxicPermissionId } = toxicAlertDescriptor
  const { data: toxicPairs, fetchStatus: fs, status: qs } = useQueryToxicPairsMap()

  const status = mergeQueryStatuses({ fs, qs })
  const name = toxicPermissionId ? toxicPairs?.get(toxicPermissionId)?.name : undefined
  const text = `Alert for ${name || toxicPermissionId || 'unknown toxic pair'}`

  return status === 'loading' ? <TextSkeleton size={12} /> : <Text>{text}</Text>
}
