import { DetailsCell, Group } from '@revolut/ui-kit'
import { ReactNode } from 'react'
import { Pair } from 'utils/types/pairs'

type Content = ReactNode
export type DetailRow = Pair<string, Content> | undefined | null

export function DetailsGroup({
  rows,
  hideEmpty = false,
}: {
  rows: DetailRow[]
  hideEmpty?: boolean
}) {
  return (
    <Group>
      {rows.map((row, index) => {
        if (!row) return null

        const [label, content] = row

        if (!content && hideEmpty) {
          return null
        }

        return (
          <DetailsCell key={index}>
            <DetailsCell.Title>{label}</DetailsCell.Title>
            <DetailsCell.Content>{content}</DetailsCell.Content>
          </DetailsCell>
        )
      })}
    </Group>
  )
}
