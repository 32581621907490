import { SideboxOpenParams } from 'view/SideBox/types'
import { Action } from '../../types'
import { InfoAction } from './components/InfoAction'

type Props = {
  action: Action
  openSide: (values: SideboxOpenParams) => void
}
export const HeaderAction = ({ action, openSide }: Props) => {
  switch (action.type) {
    case 'info':
      return <InfoAction action={action} openSide={openSide} />
    default:
      return null
  }
}
