import { VStack } from '@revolut/ui-kit'
import { DetailRow } from './DetailsGroup'

export const vstackItems = <T,>(
  title: string,
  items: T[] | undefined | null,
  renderItem: (props: T) => React.ReactElement | null,
  fallBack?: string,
): DetailRow => {
  if (!items?.length) {
    return fallBack ? [title, fallBack] : null
  }

  return [
    title,
    <VStack space="s-4" align="end" key="items">
      {items.map(renderItem)}
    </VStack>,
  ]
}
