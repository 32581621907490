import { EntitiesTable } from 'components/EntitiesTable'
import { useQuerySamUser } from 'queries/sam/users'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { isAllSettled } from 'utils/query'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { Token, MoreBar } from '@revolut/ui-kit'
import { useCallback, useMemo, useState } from 'react'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { IDAVE_PERMISSIONS } from 'security'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { User } from 'api/idave/user'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useRolePreview } from 'components/previews/RolePreview'
import { PermissionsCheck, usePermissions } from '@revolut-internal/idave-web-auth'
import { getUserRoles } from './utils'
import { getColumns } from './columns'
import { Role } from './types'
import { UserRolesAdd } from './UserRolesAdd'

export const UserRoles = (props: { user: User }) => {
  const { user } = props
  const userId = user.id

  const {
    data: userDetails,
    status: userDetailsStatus,
    fetchStatus: userDetailsFetchStatus,
  } = useQuerySamUser({ params: userId })
  const [isAdding, setAdding] = useState(false)

  const {
    data: roleMap = new Map(),
    status: rolesMVPStatus,
    fetchStatus: rolesMVPFetchStatus,
  } = useQueryRoleIdMap()
  const queryStatuses = [
    {
      qs: rolesMVPStatus,
      fs: rolesMVPFetchStatus,
    },
    {
      qs: userDetailsStatus,
      fs: userDetailsFetchStatus,
    },
  ]
  const isSettled = isAllSettled(...queryStatuses)
  const roles = useMemo(
    () =>
      isSettled
        ? getUserRoles({
            user,
            roleMap,
            userDetails,
          })
        : [],
    [user, roleMap, isSettled, userDetails],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: roles,
  })

  const { openRoleSide } = useRolePreview()
  const onRoleClick = useCallback(
    (role: Role) => {
      openRoleSide(role.id, user.id)
    },
    [openRoleSide, user.id],
  )

  const openAdding = useCallback(() => {
    setAdding(true)
  }, [setAdding])
  const closeAdding = useCallback(() => setAdding(false), [setAdding])

  const { closePopup, setPopup } = useConfirmationPopup()

  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()

  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { mutate: removeRole } = useMutation({
    mutationFn: (roleId: string) => {
      return idaveApi.user.removeRole(userId, roleId)
    },
    onMutate: () => {
      showLoadingPopup({ title: 'Removing...' })
    },
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      showSuccessToast('Role removed')
      queryClient.invalidateQueries([QueryKey.User, userId])
    },
    onError: (error: unknown) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({
        title: 'Removing failed',
        error,
      })
    },
  })

  const { hasPermission } = usePermissions()
  const deleteHandler = useMemo(
    () =>
      hasPermission(IDAVE_PERMISSIONS.USERS_CLEAR_ROLES)
        ? (role: Role) => {
            setPopup({
              title: 'Role removing',
              message: `Do you want to remove role ${role.roleName} from this user?`,
              confirmButtonText: 'Remove',
              onConfirmClick: () => {
                removeRole(role.id)
              },
            })
          }
        : undefined,
    [setPopup, removeRole, hasPermission],
  )
  const columns = useMemo(() => getColumns(deleteHandler), [deleteHandler])
  const dataLabels = useMemo(
    () => [
      {
        value: roles.reduce((acc, r) => (r.assigned === 'Manually' ? acc + 1 : acc), 0),
        label: 'Manual',
        color: Token.color.greyTone20,
      },
      {
        value: roles.reduce((acc, r) => (r.expirationDate ? acc + 1 : acc), 0),
        label: 'Temporary',
        color: Token.color.greyTone20,
      },
    ],
    [roles],
  )

  const renderActions = useCallback(
    () => (
      <PermissionsCheck
        somePermissions={[
          IDAVE_PERMISSIONS.USERS_ASSIGN_ROLES,
          IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_ROLE_ASSIGN_ACTION,
        ]}
      >
        <MoreBar.Action useIcon="Plus" onClick={openAdding}>
          Add role
        </MoreBar.Action>
      </PermissionsCheck>
    ),
    [openAdding],
  )

  const status = isAllSettled(...queryStatuses) ? 'success' : 'loading'

  return (
    <>
      {isAdding && (
        <UserRolesAdd
          close={closeAdding}
          user={user}
          addedRoleIds={roles.map((r) => r.id)}
        />
      )}

      <EntitiesTable
        entitiesTypeLabel="Roles"
        pluralForms={['role', 'roles']}
        totalCount={roles.length}
        data={searched}
        onRowClick={onRoleClick}
        dataLabels={dataLabels}
        loadingState={getLoadingState(status, roles.length)}
        columns={columns}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        searchAutoFocus
        renderActions={renderActions}
      />
    </>
  )
}
