import { useQueryGroup } from 'queries/idave/groups'
import { TextButton, TextSkeleton } from '@revolut/ui-kit'
import { Group } from 'api/idave/groups'
import { useGroupPreview } from 'components/previews/GroupPreview/hook'
import { useCallback } from 'react'
import { QuerySwitch } from '../QuerySwitch'
import { GroupNameText } from './components/GroupNameText'

export const GroupName = (props: { id: string; deprecatedTeam?: string }) => {
  const { id } = props
  const { data: group, status: qs, fetchStatus: fs } = useQueryGroup(id)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={group}
      renderError={() => <Inner id={id} />}
      renderIdle={() => <Inner id={id} />}
      renderLoading={() => <TextSkeleton size={15} />}
      renderSuccess={({ data }) => <Inner id={id} group={data} />}
    />
  )
}

const Inner = (props: { id: string; group?: Group }) => {
  const { group, id } = props
  const { openGroupSide } = useGroupPreview()

  const onClick = useCallback(() => openGroupSide(id), [openGroupSide, id])

  return (
    <TextButton onClick={onClick}>
      <GroupNameText name={group?.name || id} state={group?.state} />
    </TextButton>
  )
}
