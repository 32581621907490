import { Avatar, Banner, Box, Token } from '@revolut/ui-kit'
import { ToxicAlert } from 'api/idave/toxic'
import { InternalLink } from 'components/Links/InternalLink'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryToxicAlerts } from 'queries/idave/permissions'
import { generatePath } from 'react-router'
import { Url } from 'routing'

type Props = {
  userId: string
  flexBasis: number
}
export const UserToxicAlertBanner = ({ userId, flexBasis }: Props) => {
  const { data: alerts, status, fetchStatus } = useQueryToxicAlerts({ userId })

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={alerts}
      renderLoading={() => null}
      renderError={() => null}
      renderIdle={() => null}
      renderSuccess={({ data }) => (
        <Inner userId={userId} alerts={data} flexBasis={flexBasis} />
      )}
    />
  )
}

const Inner = ({
  userId,
  alerts,
  flexBasis,
}: {
  userId: string
  alerts: ToxicAlert[]
  flexBasis: number
}) => {
  if (!alerts.length) {
    return null
  }

  return (
    <Box style={{ flexBasis: `${flexBasis}px` }}>
      <Banner>
        <Banner.Avatar>
          <Avatar useIcon="ExclamationTriangle" size={56} color={Token.color.red} />
        </Banner.Avatar>
        <Banner.Content>
          <Banner.Title>User has toxic permission pairs</Banner.Title>
          <Banner.Description>
            The user has pairs of permission from toxic permission pairs. Please check{' '}
            <InternalLink href={generatePath(Url.UserToxicAlerts, { userId })}>
              toxic alerts tab
            </InternalLink>{' '}
            and resolve it
          </Banner.Description>
        </Banner.Content>
      </Banner>
    </Box>
  )
}
