export type InfoBlock = {
  title: string
  text: string
}

export type InfoBlockName = keyof typeof INFO_BLOCK_CONTENT

export const INFO_BLOCK_CONTENT = {
  app: {
    title: 'Application',
    text: 'IDave Apps are internally developed applications or tools that expose their permissions set to IDave for access management.\n\nApplications are usually created and updated by developers.',
  },
  accessRequest: {
    title: 'Access request',
    text: 'If you’d like to request access to applications, you need to [assign a role](https://revolut.atlassian.net/wiki/spaces/ID/pages/3272214754/IDave+Cross-checks#Assign-a-role-to-an-individual-user-(temporary-access-only)) to a profile or [update a policy](https://revolut.atlassian.net/wiki/spaces/ID/pages/3272214754/iDave+Cross-checks+Guide#Update-an-existing-SAM-Policy). Creating or updating an app will not grant you any access.',
  },
  role: {
    title: 'Role',
    text: 'Roles are predefined sets of permissions or privileges assigned to individuals or groups within the organisation. These permissions typically span across various systems, applications or resources. They determine access levels and actions permitted to each role.',
  },
  roleAssignment: {
    title: 'Role assignment',
    text: 'Roles can be assigned directly to users profile only for a limited time (up to 3 months). This type of assignment should be chosen only if the role is needed temporarily, to complete a one-time task.\n\nIf access is needed permanently, a [creation](https://revolut.atlassian.net/wiki/spaces/ID/pages/3272214754/iDave+Cross-checks+Guide#Create-a-new-SAM-Policy) or [update](https://revolut.atlassian.net/wiki/spaces/ID/pages/3272214754/IDave+Cross-checks#Update-an-existing-SAM-Policy) of a role-based access policy should be requested instead.',
  },
  policy: {
    title: 'Policy',
    text: 'A policy is a collection of accesses that give its assigned users access to various systems.\n\nPolicies are linked to Revolut People directly. It ensures users’ access roles are updated automatically based on the user attributes, such as team or specialisation.',
  },
  policyTypes: {
    title: 'Types of policies',
    text: 'Team & specialisation policy is the most common type of policy. It is very specific and ensures that employees on a given position within the selected team are granted access automatically.\n\nAnother common type of policy is a team-based policy. It will provide access to all users from a selected team, regardless of their specialisation. \n\nFind more information on policy types [here](https://revolut.atlassian.net/wiki/spaces/ID/pages/3272214754/IDave+Cross-checks#Create-a-new-SAM-Policy). See how to locate the team and specialisation IDs [here](https://revolut.atlassian.net/wiki/spaces/ID/pages/3158279692/IDave+User+Authentication+and+Navigation#Finding-team-%26-specialisation-IDs).',
  },
  policySeniority: {
    title: 'Seniority',
    text: 'Leave the Seniority field empty, unless you’d like to restrict a policy to a single seniority.',
  },
  policyApprovals: {
    title: 'Approvals',
    text: 'Depending on the policy type requested, varying approvals will be requested. Standard approval process includes the IAM team, policy owner and all role owners to approve the request.\n\nPlease note that the IAM team is always the last approver for each request.',
  },
  rolePermissions: {
    title: 'Permissions',
    text: 'All permissions included in the new role should have a proper description including classification, according to [IDave permissions classification](https://revolut.atlassian.net/wiki/spaces/ISSD/pages/3120858834/IDave+permissions+classification).',
  },
  roleOwnership: {
    title: 'Ownership',
    text: 'A role [owner](https://revolut.atlassian.net/wiki/spaces/ISSD/pages/2507967978/IDave+role+ownership+FAQ) is responsible for its modifications, assignment approvals and recertification.\n\nThe owners are identified as teams and the team owners are responsible for alternative approvers assignments.',
  },
  roleName: {
    title: 'Name of the role',
    text: 'Please note that the role’s name should not follow machine-friendly convention. You can find the instructions on how to choose the role name [here](https://revolut.atlassian.net/wiki/spaces/ISSD/pages/2376532872/IDave+roles+and+permissions+management+FAQ#How-to-request-a-new-IDave-role-on-production%3F).',
  },
}
