import { Link } from '@revolut/ui-kit'
import React, { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export type InternalLinkProps = {
  href: string
  children: React.ReactNode
  title?: string
  target?: string
}

export const InternalLink = (props: InternalLinkProps) => {
  const { href, children, title, target } = props

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation(),
    [],
  )

  return (
    <Link to={href} use={RouterLink} title={title} onClick={onClick} target={target}>
      {children}
    </Link>
  )
}
