import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import { QueryKey } from 'helpers/configQuery'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useCallback } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { ServiceName, ServicePermission } from 'services/permissions'
import { useToasts } from './useToasts'

const getAppXCheckQueries = (app: ServiceName) => {
  switch (app) {
    case 'dart':
      return [QueryKey.DartCrossChecksList]
    case 'idave':
      return [QueryKey.CrossChecksList]
    case 'sam':
      return [QueryKey.SamCrossChecksList, QueryKey.SamMyCrossChecksList]
    default:
      return []
  }
}

export const useCrossCheck = (
  actionPermission: ServicePermission,
  xCheckPermission: ServicePermission,
) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { hasPermission } = usePermissions()
  const isCrossCheck = hasPermission(xCheckPermission) && !hasPermission(actionPermission)
  const isDirect = hasPermission(actionPermission)
  const actionSystem = actionPermission.service

  const toCrosscheck = useCallback(
    (crossCheckId: string) => {
      showSuccessToast('Crosscheck created')

      const queryKeys = getAppXCheckQueries(actionSystem)
      queryKeys.forEach((qKey) => queryClient.invalidateQueries([qKey]))

      return navigate(
        generatePath(Url.CrossCheck, { source: actionSystem, crossCheckId }),
      )
    },
    [showSuccessToast, navigate, actionSystem, queryClient],
  )

  return { toCrosscheck, isCrossCheck, isDirect }
}
