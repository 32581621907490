import { Table, TableColumn } from '@revolut/ui-kit'
import { CustomerCompanyLink } from 'components/Links/CustomerCompanyLink'
import { AccessModifier } from 'api/dart/types'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { formatDateTime } from 'utils/date'
import { capitalizeFirst } from 'utils/string'
import { typeToStatusCell } from './utils'

export const COLUMNS: TableColumn<AccessModifier>[] = [
  {
    Header: 'Entity',
    accessor: 'entityId',
    width: 200,
    Cell: (params) => {
      const { entityId } = params.row.original
      return (
        <Table.Cell>
          {entityId?.type === 'COMPANY' ? (
            <CustomerCompanyLink id={entityId.id} />
          ) : (
            entityId?.id
          )}
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 100,
    Cell: (params) => {
      return (
        <Table.StatusCell level={typeToStatusCell(params.value)}>
          {capitalizeFirst(params.value)}
        </Table.StatusCell>
      )
    },
  },
  {
    Header: 'Justification',
    accessor: 'justification',
  },
  {
    Header: 'Expiration date',
    accessor: 'expirationDate',
    width: 160,
    Cell: (params) => {
      const { expirationDate } = params.row.original
      return (
        <Table.Cell>
          {expirationDate ? formatDateTime(expirationDate) : EMPTY_VALUE_FALLBACK}
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Issuer',
    accessor: 'issuer',
    width: 80,
  },
]
