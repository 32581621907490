import { useQuery } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { EmployeeDetailsNotLimited } from 'api/dart/employee'
import { QueryKey } from 'helpers/configQuery'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'

export const useQueryEmployeeDetailsNotLimited = (id?: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.EmployeeDetailsNotLimited, id],
    queryFn: () => {
      if (!id) {
        throw new Error('id is not set')
      }
      return dartApi.employee.getEmployeeDetails<EmployeeDetailsNotLimited>(id)
    },
    enabled: Boolean(id) && hasPermission(DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS),
    select: (response) => response.data,
  })
}

export const useQueryEmployeeDetails = (id?: string) => {
  const { hasSomePermissions } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.EmployeeDetails, id],
    queryFn: () => {
      if (!id) {
        throw new Error('id is not set')
      }
      return dartApi.employee.getEmployeeDetails(id)
    },
    enabled:
      Boolean(id) &&
      hasSomePermissions(
        DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS,
        DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS_LIMITED,
      ),
    select: (response) => response.data,
  })
}

export const useQueryDirectRelations = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.DirectRelations],
    queryFn: dartApi.employee.getEmployees,
    select: (response) => response.data,
    enabled: hasPermission(DART_PERMISSIONS.EMPLOYEES_VIEW_LIST),
  })
}
