import { useCallback } from 'react'
import { useQueryGroupIdMap } from 'queries/idave/groups'

export const useGroupOwnerId = () => {
  const { data: groupMap = new Map() } = useQueryGroupIdMap()

  const getGroupOwnerId = useCallback(
    (groupId: string) => {
      const group = groupMap.get(groupId)
      return group ? group.ownerIds[0] : undefined
    },
    [groupMap],
  )

  return getGroupOwnerId
}
